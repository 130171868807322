import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2em auto;
  width: 60px;
  height: 60px;
  position: relative;

  & .spinner {
    width: 60px;
    height: 60px;
  }
`;

export default Wrapper;
