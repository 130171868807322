import {
  SET_ACTIVE_GAME_ID,
} from './constants';

export function setActiveGameId(gameId) {
  return {
    type: SET_ACTIVE_GAME_ID,
    gameId,
  };
}
