import { connect } from 'react-redux';
import withHandlers from 'recompose/withHandlers';
import compose from 'recompose/compose';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import { register } from '../../../redux/modules/forms/registrationForm/actions';
import {
  makeSelectRegistrationError,
} from '../../../redux/modules/forms/registrationForm/selectors';

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: ({ values, formik }) => dispatch(register({ values, formik })),
  };
}

const mapStateToProps = createStructuredSelector({
  serverErrorMessage: makeSelectRegistrationError(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
  withRouter,
  withHandlers({
    handleSubmit: props => (values, formik) => {
      const { onSubmitForm } = props;
      onSubmitForm({ values, formik });
    },
  }),
);

export default enhance;
