import find from 'lodash/find';
import get from 'lodash/get';

const getGameOptionValue = (optionName, gamesOptions, gameId) => {
  const gameOptions = get(find(gamesOptions, { id: gameId }), 'data');

  return get(find(gameOptions, { name: optionName }), 'value');
};

export default getGameOptionValue;
