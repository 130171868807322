function detectMobile() {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }

  return false;
}

function initOnWindowLeave({ setBeforeDemoLeavePopupOpened, cookies, cookieName }) {
  console.info('initOnWindowLeave');
  const isMobile = detectMobile();

  const iGetPdfFragment1 = cookies.get('i_get_pdf_fragment_1');

  // Если пользователь уже заполнял поле с email
  if (iGetPdfFragment1) {
    return;
  }

  let storage = null;

  try {
    sessionStorage.setItem('localStorageTest', 1);
    sessionStorage.removeItem('localStorageTest');
    storage = sessionStorage;
  } catch (e) {}

  if (storage === null) {
    try {
      localStorage.setItem('localStorageTest', 1);
      localStorage.removeItem('localStorageTest');
      storage = localStorage;
    } catch (e) {}
  }

  let pageLeaveEventRaised = false;
  if (storage) {
    pageLeaveEventRaised = storage.getItem('pageLeaveEventRaised');
  }

  // Если пользователу уже показывали попап с email
  if (pageLeaveEventRaised) {
    return;
  }

  if (isMobile) {
    setTimeout(() => {
      const cookieTriggerValue = cookies.get(cookieName);
      if (cookieTriggerValue) {
        setBeforeDemoLeavePopupOpened(true);
        cookies.remove(cookieName, { path: '/' });
      }
    }, 40 * 1000); // Через 40 секунд показываем попап
  } else {
    const htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.addEventListener('mouseout', (e) => {
      const cookieTriggerValue = cookies.get(cookieName);

      if (e.clientY > 10) {
        return;
      }

      if (cookieTriggerValue) {
        setBeforeDemoLeavePopupOpened(true);
        cookies.remove(cookieName, { path: '/' });
      }
    });
  }
}

export default initOnWindowLeave;
