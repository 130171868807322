import {
  NEW_GAME_REQUEST,
  NEW_GAME_REQUEST_FAIL,
  NEW_GAME_REQUEST_SUCCESS,
  GAME_INFO_REQUEST,
  GAME_INFO_REQUEST_SUCCESS,
  GAME_INFO_REQUEST_FAIL,
  CONTINUE_GAME_REQUEST,
  CONTINUE_GAME_REQUEST_SUCCESS,
  CONTINUE_GAME_REQUEST_FAIL,
  CONTINUE_GAME_FROM_SAVE_REQUEST,
  CONTINUE_GAME_FROM_SAVE_REQUEST_SUCCESS,
  CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL,
  UNCHECK_CONTINUE_GAME,
  NEXT_STEP_REQUEST,
  NEXT_STEP_REQUEST_SUCCESS,
  NEXT_STEP_REQUEST_FAIL,
  CHECK_ANSWER,
  GET_VARS_REQUEST,
  GET_VARS_REQUEST_SUCCESS,
  GET_VARS_REQUEST_FAIL,
  GET_KEYS_REQUEST,
  GET_KEYS_REQUEST_SUCCESS,
  GET_KEYS_REQUEST_FAIL,
  GET_TIPS_REQUEST,
  GET_TIPS_REQUEST_SUCCESS,
  GET_TIPS_REQUEST_FAIL,
  GET_HIGHLIGHTTIP_REQUEST,
  GET_HIGHLIGHTTIP_REQUEST_SUCCESS,
  GET_HIGHLIGHTTIP_REQUEST_FAIL,
  GET_FIFTY_FIFTY_REQUEST,
  GET_FIFTY_FIFTY_REQUEST_SUCCESS,
  GET_FIFTY_FIFTY_REQUEST_FAIL,
  GET_SAVE_REQUEST,
  GET_SAVE_REQUEST_SUCCESS,
  GET_SAVE_REQUEST_FAIL,
  GET_TIPS_PRICE_REQUEST,
  GET_TIPS_PRICE_REQUEST_SUCCESS,
  GET_TIPS_PRICE_REQUEST_FAIL,
  BUY_FIFTY_FIFTY_TIP_REQUEST,
  BUY_FIFTY_FIFTY_TIP_REQUEST_SUCCESS,
  BUY_FIFTY_FIFTY_TIP_REQUEST_FAIL,
  BUY_HIGHLIGHTTIP_TIP_REQUEST,
  BUY_HIGHLIGHTTIP_TIP_REQUEST_SUCCESS,
  BUY_HIGHLIGHTTIP_TIP_REQUEST_FAIL,
  BUY_CARD_KEY_REQUEST,
  BUY_CARD_KEY_REQUEST_SUCCESS,
  BUY_CARD_KEY_REQUEST_FAIL,
  GET_CARDS_ALL_REQUEST,
  GET_CARDS_ALL_REQUEST_SUCCESS,
  GET_CARDS_ALL_REQUEST_FAIL,
  GET_FINISH_VARS_REQUEST,
  GET_FINISH_VARS_REQUEST_SUCCESS,
  GET_FINISH_VARS_REQUEST_FAIL,
  CHANGE_STAVKA_STATE,
  CHANGE_SUPERGAME_STATE,
  CHANGE_IS_LOADING,
  CHANGE_IS_LOADING_CARDS,
  CHANGE_PRODUCT_PRICE,
  GET_SAVE_COUNT_REQUEST,
  GET_SAVE_COUNT_REQUEST_SUCCESS,
  GET_SAVE_COUNT_REQUEST_FAIL,
  BUY_SAVE_GAME_REQUEST,
  BUY_SAVE_GAME_REQUEST_SUCCESS,
  BUY_SAVE_GAME_REQUEST_FAIL,
  SET_IS_FIRST_GAME,
  GET_RECEIVED_KEYS_REQUEST,
  GET_RECEIVED_KEYS_REQUEST_SUCCESS,
  GET_RECEIVED_KEYS_REQUEST_FAIL,
  SET_IS_FIRST_GAME_TOUR_WAS_SHOWN,
  SET_USER_EMAIL_REQUEST,
  SET_USER_EMAIL_REQUEST_SUCCESS,
  SET_USER_EMAIL_REQUEST_FAIL, SET_USER_EMAIL_RESET,
  RESET_MY_GAME_STATE,
  START_NEW_SUPER_GAME,
  SET_SHOW_ACTION_BUTTON_ON_MOBILE,
  SET_ACTIVE_DEAL_STAGE,
  SET_DEAL_STAGE_INFO,
  SET_WIDGET_LIST,
} from './constants';

export function setUserEmailRequest(email) {
  return {
    type: SET_USER_EMAIL_REQUEST,
    email,
  };
}

export function resetMyGameState() {
  return {
    type: RESET_MY_GAME_STATE,
  };
}

export function setUserEmailRequestSuccess() {
  return {
    type: SET_USER_EMAIL_REQUEST_SUCCESS,
  };
}

export function setUserEmailRequestFail() {
  return {
    type: SET_USER_EMAIL_REQUEST_FAIL,
  };
}

export function setUserEmailReset() {
  return {
    type: SET_USER_EMAIL_RESET,
  };
}

export function getReceivedKeysRequest() {
  return {
    type: GET_RECEIVED_KEYS_REQUEST,
  };
}

export function getReceivedKeysRequestSuccess(payload) {
  return {
    type: GET_RECEIVED_KEYS_REQUEST_SUCCESS,
    payload,
  };
}

export function setShowActionButtonsOnMobile(showActionButtonsOnMobile) {
  return {
    type: SET_SHOW_ACTION_BUTTON_ON_MOBILE,
    payload: {
      showActionButtonsOnMobile,
    },
  };
}

export function getReceivedKeysRequestFail() {
  return {
    type: GET_RECEIVED_KEYS_REQUEST_FAIL,
  };
}

export function startNewGame() {
  return {
    type: NEW_GAME_REQUEST,
  };
}

export function startSuperGame() {
  return {
    type: START_NEW_SUPER_GAME,
  };
}

export function setIsFirstGame(isFirstGame) {
  return {
    type: SET_IS_FIRST_GAME,
    isFirstGame,
  };
}

export function setIsFirstGameTourWasShown(isFirstGameTourWasShown) {
  return {
    type: SET_IS_FIRST_GAME_TOUR_WAS_SHOWN,
    isFirstGameTourWasShown,
  };
}

export function newGameSuccess(sessionId) {
  return {
    type: NEW_GAME_REQUEST_SUCCESS,
    sessionId,
  };
}

export function newGameFail() {
  return {
    type: NEW_GAME_REQUEST_FAIL,
  };
}

export function continueGame() {
  return {
    type: CONTINUE_GAME_REQUEST,
  };
}

export function continueGameSuccess(sessionId) {
  return {
    type: CONTINUE_GAME_REQUEST_SUCCESS,
    sessionId,
  };
}

export function continueGameFail() {
  return {
    type: CONTINUE_GAME_REQUEST_FAIL,
  };
}

export function continueGameFromSave() {
  return {
    type: CONTINUE_GAME_FROM_SAVE_REQUEST,
  };
}

export function continueGameFromSaveSuccess(sessionId) {
  return {
    type: CONTINUE_GAME_FROM_SAVE_REQUEST_SUCCESS,
    sessionId,
  };
}

export function continueGameFromSaveFail() {
  return {
    type: CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL,
  };
}

export function uncheckContinueGame() {
  return {
    type: UNCHECK_CONTINUE_GAME,
  };
}

export function nextStep() {
  return {
    type: NEXT_STEP_REQUEST,
  };
}

export function nextStepSuccess(payload) {
  return {
    type: NEXT_STEP_REQUEST_SUCCESS,
    ...payload,
  };
}

export function nextStepFail() {
  return {
    type: NEXT_STEP_REQUEST_FAIL,
  };
}

export function checkAnswer(payload) {
  return {
    type: CHECK_ANSWER,
    ...payload,
  };
}

export function getVars() {
  return {
    type: GET_VARS_REQUEST,
  };
}

export function getVarsSuccess(payload) {
  return {
    type: GET_VARS_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getVarsFail() {
  return {
    type: GET_VARS_REQUEST_FAIL,
  };
}

export function getKeys() {
  return {
    type: GET_KEYS_REQUEST,
  };
}

export function getKeysSuccess(payload) {
  return {
    type: GET_KEYS_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getKeysFail() {
  return {
    type: GET_KEYS_REQUEST_FAIL,
  };
}

export function getTips() {
  return {
    type: GET_TIPS_REQUEST,
  };
}

export function getTipsSuccess(payload) {
  return {
    type: GET_TIPS_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getTipsFail() {
  return {
    type: GET_TIPS_REQUEST_FAIL,
  };
}

export function getHighlightTip() {
  return {
    type: GET_HIGHLIGHTTIP_REQUEST,
  };
}

export function getHighlightTipSuccess(payload) {
  return {
    type: GET_HIGHLIGHTTIP_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getHighlightTipFail() {
  return {
    type: GET_HIGHLIGHTTIP_REQUEST_FAIL,
  };
}

export function getFiftyFiftyTip() {
  return {
    type: GET_FIFTY_FIFTY_REQUEST,
  };
}

export function getFiftyFiftyTipSuccess(payload) {
  return {
    type: GET_FIFTY_FIFTY_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getFiftyFiftyTipFail() {
  return {
    type: GET_FIFTY_FIFTY_REQUEST_FAIL,
  };
}

export function getSaveTip() {
  return {
    type: GET_SAVE_REQUEST,
  };
}

export function getSaveTipSuccess() {
  return {
    type: GET_SAVE_REQUEST_SUCCESS,
  };
}

export function getSaveTipFail() {
  return {
    type: GET_SAVE_REQUEST_FAIL,
  };
}

export function getTipsPrice() {
  return {
    type: GET_TIPS_PRICE_REQUEST,
  };
}

export function getTipsPriceSuccess(payload) {
  return {
    type: GET_TIPS_PRICE_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getTipsPriceFail() {
  return {
    type: GET_TIPS_PRICE_REQUEST_FAIL,
  };
}

export function buyFiftyFiftyTip(payload) {
  return {
    type: BUY_FIFTY_FIFTY_TIP_REQUEST,
    payload,
  };
}

export function buyFiftyFiftyTipSuccess() {
  return {
    type: BUY_FIFTY_FIFTY_TIP_REQUEST_SUCCESS,
  };
}

export function buyFiftyFiftyTipFail() {
  return {
    type: BUY_FIFTY_FIFTY_TIP_REQUEST_FAIL,
  };
}

export function buyHighlightTip(payload) {
  return {
    type: BUY_HIGHLIGHTTIP_TIP_REQUEST,
    payload,
  };
}

export function buyHighlightTipSuccess() {
  return {
    type: BUY_HIGHLIGHTTIP_TIP_REQUEST_SUCCESS,
  };
}

export function buyHighlightTipFail() {
  return {
    type: BUY_HIGHLIGHTTIP_TIP_REQUEST_FAIL,
  };
}

export function getGameInfo() {
  return {
    type: GAME_INFO_REQUEST,
  };
}

export function getGameInfoSuccess(payload) {
  return {
    type: GAME_INFO_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getGameInfoFail() {
  return {
    type: GAME_INFO_REQUEST_FAIL,
  };
}

export function getCardsAll() {
  return {
    type: GET_CARDS_ALL_REQUEST,
  };
}

export function getCardsAllSuccess(payload) {
  return {
    type: GET_CARDS_ALL_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getCardsAllFail() {
  return {
    type: GET_CARDS_ALL_REQUEST_FAIL,
  };
}

export function getFinishVars() {
  return {
    type: GET_FINISH_VARS_REQUEST,
  };
}

export function getFinishVarsSuccess(payload) {
  return {
    type: GET_FINISH_VARS_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getFinishVarsFail() {
  return {
    type: GET_FINISH_VARS_REQUEST_FAIL,
  };
}

export function changeStavka(payload) {
  return {
    type: CHANGE_STAVKA_STATE,
    ...payload,
  };
}

export function getSaveCount() {
  return {
    type: GET_SAVE_COUNT_REQUEST,
  };
}

export function getSaveCountSuccess(payload) {
  return {
    type: GET_SAVE_COUNT_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getSaveCountFail() {
  return {
    type: GET_SAVE_COUNT_REQUEST_FAIL,
  };
}

export function buySaveGame(payload) {
  return {
    type: BUY_SAVE_GAME_REQUEST,
    payload,
  };
}

export function buySaveGameSuccess() {
  return {
    type: BUY_SAVE_GAME_REQUEST_SUCCESS,
  };
}

export function buySaveGameFail() {
  return {
    type: BUY_SAVE_GAME_REQUEST_FAIL,
  };
}

export function buyCardKey(payload) {
  return {
    type: BUY_CARD_KEY_REQUEST,
    ...payload,
  };
}

export function buyCardKeySuccess() {
  return {
    type: BUY_CARD_KEY_REQUEST_SUCCESS,
  };
}

export function buyCardKeyFail() {
  return {
    type: BUY_CARD_KEY_REQUEST_FAIL,
  };
}

export function changeSupergame(payload) {
  return {
    type: CHANGE_SUPERGAME_STATE,
    ...payload,
  };
}

export function changeIsLoading(payload) {
  return {
    type: CHANGE_IS_LOADING,
    payload,
  };
}

export function changeIsLoadingCards(payload) {
  return {
    type: CHANGE_IS_LOADING_CARDS,
    payload,
  };
}

export function changeProductPrice(payload) {
  return {
    type: CHANGE_PRODUCT_PRICE,
    ...payload,
  };
}

export function setStavkaState(payload) {
  return {
    type: CHANGE_STAVKA_STATE,
    ...payload,
  };
}

export function setActiveDealStage(stage) {
  return {
    type: SET_ACTIVE_DEAL_STAGE,
    stage,
  };
}

export function setActiveDealStageInfo(stagesInfo) {
  return {
    type: SET_DEAL_STAGE_INFO,
    stagesInfo,
  };
}

export function setWidgetList(widgetList, widgetDescriptions) {
  return {
    type: SET_WIDGET_LIST,
    widgetList,
    widgetDescriptions,
  };
}
