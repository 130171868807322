import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import setDisplayName from 'recompose/setDisplayName';
import { createStructuredSelector } from 'reselect';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
});

const withConnect = connect(mapStateToProps);

const enhance = compose(
  setDisplayName('BX_ACTION_Enhanced'),
  pure,
  withConnect,
);

export default enhance;
