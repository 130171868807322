import filter from 'lodash/filter';
import React from 'react';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import Typograf from 'typograf';
import LoadingIndicator from '../LoadingIndicator';
import Table from '../../containers/pages/RatingAllPage/includes/Table';
import RatingContent from '../RatingContent';


const tp = new Typograf({ locale: ['ru', 'en-US'] });

const renderRating = ({ myEmployeeId, fields, gameRating, isGameRatingLoading }) => {
  const tableHeader = map(
    filter(fields, field => !field.IsSystem),
    (item, index) => ({
      key: index + 1,
      width: item.Width,
      title: item.Name,
      tooltip: tp.execute(item.Hint),
    }),
  );

  let tableItems = [];

  if (!isNull(gameRating) && gameRating) {
    tableItems = gameRating
      .filter(item => !item.outTop)
      .map((item, index) => {


        const rowData = map(
          filter(fields, field => !field.IsSystem),
          (field, index) => ({
            key: index + 1,
            width: field.Width,
            value: item[field.FieldName],
            field,
          }),
        );

        return {
          key: index,
          outOfList: false,
          isMyRating: item.employeeid === myEmployeeId,
          isChampion: rowData.position === 0,
          items: rowData,
        };
      });
  }

  let gameRatingView = (
    <RatingContent>
      <LoadingIndicator />
    </RatingContent>
  );

  if (!isGameRatingLoading) {
    gameRatingView = (
      <RatingContent>
        <Table
          minWidth="1000px"
          header={tableHeader}
          items={tableItems}
          extendedRatingForEmployeeId={myEmployeeId}
        />
      </RatingContent>
    );
  }

  return gameRatingView;
};

const CompanySimpleRating = ({ isRatingLoading, gameRating, fields, myEmployeeId }) => {
  return renderRating({ fields, myEmployeeId, isGameRatingLoading: isRatingLoading, gameRating });
};

export default CompanySimpleRating;
