import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import isNull from 'lodash/isNull';
import { makeSelectApiUrl } from '../../modules/global/selectors';

import {
  GET_DEPARTMENT_LIST_REQUEST,
  GET_EMPLOYEES_LIST_REQUEST,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
} from '../../modules/employees/constants';
import {
  getDepartmentListSuccess,
  getDepartmentListFail,
  getEmployeesList,
  getEmployeesListSuccess,
  getEmployeesListFail,
  changeDepartmentCount,
  changeLoading,
  deleteDepartmentSuccess,
  deleteDepartmentFail,
  getDepartmentList,
  changeActiveTab,
  deleteEmployeeSuccess,
  deleteEmployeeFail,
} from '../../modules/employees/actions';
import {
  makeSelectDepartments,
  makeSelectEmployees,
  makeSelectDepartmentForDelete,
  makeSelectEmployeeForDelete,
} from '../../modules/employees/selectors';

import { apiRequest as createApiRequest } from '../../../utils/request';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* getDepartmentListSaga() {
    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/DepartmentGetAll/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const departments = request.data.result[0].DepartmentList.map(department => ({
        id: department.DepartmentID,
        name: department.Name,
        count: 0,
      }));

      yield put(getDepartmentListSuccess({
        departments,
      }));
      yield put(getEmployeesList());
    } catch (err) {
      yield put(getDepartmentListFail());
    }
  }

  function* getEmployeesListSaga() {
    const url = yield select(makeSelectApiUrl());
    const requestURLPart = `${url}/EmployeeGetAllByDepartment`;
    const departments = yield select(makeSelectDepartments());
    const departmentsWithCount = departments;

    try {
      for (let i = 0; i < departments.length; i += 1) {
        const currentEmployees = yield select(makeSelectEmployees());
        const requestURL = `${requestURLPart}/${departments[i].id}`;
        const request = yield call(apiRequest, requestURL);

        const employees = request.data.result[0].EmployeeList.map((employee) => {
          let rqRating = 0;
          if (employee.RQ > 0) {
            rqRating = Math.round(employee.RQ * 100) / 100;
          }

          return ({
            id: employee.EmployeeID,
            departmentId: departments[i].id,
            name: employee.Name,
            surname: employee.Surname,
            patronymic: employee.MiddleName,
            lastSessionFinishDateTime: employee.LastSessionFinishDateTime,
            email: employee.Email,
            photo: employee.PhotoUrl,
            rq: rqRating,
            acl: {
              canSeeCompanyRating: employee.IsShowRating,
              canSeeCompanyExtendedRating: employee.IsShowRatingExt,
              canViewAllCompanyEmployee: employee.IsCompanyEmployeeSelect,
              canEditAllCompanyEmployee: employee.IsCompanyEmployeeEdit,
              canViewOwnDepartmentEmployee: employee.IsDepartmentEmployeeSelect,
              canEditOwnDepartmentEmployee: employee.IsDepartmentEmployeeEdit,
              isWorkInvoice: employee.IsWorkInvoice,
            },
            statusCode: employee.AccountStatus,
            statusText: employee.AccountStatusStr,
          });
        });

        departmentsWithCount[i].count = employees.length;

        let result = employees;
        if (!isNull(currentEmployees)) {
          result = employees.concat(currentEmployees);
        }

        yield put(getEmployeesListSuccess({
          employees: result,
        }));
      }

      yield put(changeDepartmentCount({
        departments: departmentsWithCount,
      }));
    } catch (err) {
      yield put(getEmployeesListFail());
    } finally {
      yield put(changeLoading({
        isLoading: false,
      }));
    }
  }

  function* deleteDepartmentSaga() {
    const url = yield select(makeSelectApiUrl());
    const departmentId = yield select(makeSelectDepartmentForDelete());
    const requestURL = `${url}/DepartmentDelete/${encodeURIComponent(departmentId)}/`;

    try {
      yield call(apiRequest, requestURL);

      yield put(deleteDepartmentSuccess());
    } catch (err) {
      yield put(deleteDepartmentFail());
    } finally {
      yield put(getDepartmentList());
      yield put(changeActiveTab({
        activeTab: 'tabItem_2',
      }));
    }
  }

  function* deleteEmployeeSaga() {
    const url = yield select(makeSelectApiUrl());
    const employeeId = yield select(makeSelectEmployeeForDelete());
    const requestURL = `${url}/EmployeeDelete/${encodeURIComponent(employeeId)}/`;

    try {
      yield call(apiRequest, requestURL);

      yield put(deleteEmployeeSuccess());
    } catch (err) {
      yield put(deleteEmployeeFail());
    } finally {
      yield put(getDepartmentList());
      yield put(changeActiveTab({
        activeTab: 'tabItem_1',
      }));
    }
  }

  function* employeeSaga() {
    yield takeLeading(GET_DEPARTMENT_LIST_REQUEST, getDepartmentListSaga);
    yield takeLeading(GET_EMPLOYEES_LIST_REQUEST, getEmployeesListSaga);
    yield takeLeading(DELETE_DEPARTMENT_REQUEST, deleteDepartmentSaga);
    yield takeLeading(DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga);
  }

  return employeeSaga;
}

export default build;
