import { fromJS } from 'immutable';

import {
  GET_PERSONAL_GAME_HEAD_RATING,
  GET_PERSONAL_GAME_HEAD_RATING_FAIL,
  GET_PERSONAL_GAME_HEAD_RATING_SUCCESS,

  GET_COMMON_GAME_RATING,
  GET_COMMON_GAME_RATING_FAIL,
  GET_COMMON_GAME_RATING_SUCCESS,
  GET_COMPANY_GAME_HEAD_RATING,
  GET_COMPANY_GAME_HEAD_RATING_SUCCESS,
  GET_COMPANY_GAME_HEAD_RATING_FAIL,
  GET_COMPANY_GAME_RATING,
  GET_COMPANY_GAME_RATING_SUCCESS,
  GET_COMPANY_GAME_RATING_FAIL,

  GET_EXTENDED_EMPLOYEE_RATING,
  GET_EXTENDED_EMPLOYEE_RATING_SUCCESS,
  GET_EXTENDED_EMPLOYEE_RATING_FAIL,

  SET_ACTIVE_RATING_TAB,
} from './constants';

export const emptyData = {
  pending: true,
  fail: false,
  success: false,
  data: null,
  fields: null,
};

const initialState = fromJS({
  personalGameHeadRating: {},
  commonGameRating: {},

  companyGameHeadRating: {},
  companyGameRating: {},

  extendedEmployeeRating: {},

  activeRatingTab: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_RATING_TAB: {
      const { activeRatingTab } = action.payload;

      return state.set('activeRatingTab', activeRatingTab);
    }
    case GET_PERSONAL_GAME_HEAD_RATING: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        pending: true,
        fail: false,
        success: false,
        data: null,
        fields: null,
      });

      return state.setIn(['personalGameHeadRating', gameId], ratingForGame);
    }
    case GET_PERSONAL_GAME_HEAD_RATING_SUCCESS: {
      const { gameId, data, fields } = action.payload;

      const ratingForGame = fromJS({
        pending: false,
        fail: false,
        success: true,
        data,
        fields,
      });

      return state.setIn(['personalGameHeadRating', gameId], ratingForGame);
    }
    case GET_PERSONAL_GAME_HEAD_RATING_FAIL: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        ...emptyData,
        pending: false,
        fail: true,
        success: false,
      });

      return state.setIn(['personalGameHeadRating', gameId], ratingForGame);
    }

    case GET_COMMON_GAME_RATING: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        pending: true,
        fail: false,
        success: false,
        data: null,
        fields: null,
      });

      return state.setIn(['commonGameRating', gameId], ratingForGame);
    }
    case GET_COMMON_GAME_RATING_SUCCESS: {
      const { gameId, data, fields } = action.payload;

      const ratingForGame = fromJS({
        pending: false,
        fail: false,
        success: true,
        data,
        fields,
      });

      return state.setIn(['commonGameRating', gameId], ratingForGame);
    }
    case GET_COMMON_GAME_RATING_FAIL: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        ...emptyData,
        pending: false,
        fail: true,
        success: false,
      });

      return state.setIn(['commonGameRating', gameId], ratingForGame);
    }

    case GET_COMPANY_GAME_HEAD_RATING: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        pending: true,
        fail: false,
        success: false,
        data: null,
        fields: null,
      });

      return state.setIn(['companyGameHeadRating', gameId], ratingForGame);
    }
    case GET_COMPANY_GAME_HEAD_RATING_SUCCESS: {
      const { gameId, data, fields } = action.payload;

      const ratingForGame = fromJS({
        pending: false,
        fail: false,
        success: true,
        data,
        fields,
      });

      return state.setIn(['companyGameHeadRating', gameId], ratingForGame);
    }
    case GET_COMPANY_GAME_HEAD_RATING_FAIL: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        ...emptyData,
        pending: false,
        fail: true,
        success: false,
      });

      return state.setIn(['companyGameHeadRating', gameId], ratingForGame);
    }

    case GET_COMPANY_GAME_RATING: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        pending: true,
        fail: false,
        success: false,
        data: null,
        fields: null,
      });

      return state.setIn(['companyGameRating', gameId], ratingForGame);
    }
    case GET_COMPANY_GAME_RATING_SUCCESS: {
      const { gameId, data, fields } = action.payload;

      const ratingForGame = fromJS({
        pending: false,
        fail: false,
        success: true,
        data,
        fields,
      });

      return state.setIn(['companyGameRating', gameId], ratingForGame);
    }
    case GET_COMPANY_GAME_RATING_FAIL: {
      const { gameId } = action.payload;

      const ratingForGame = fromJS({
        ...emptyData,
        pending: false,
        fail: true,
        success: false,
      });

      return state.setIn(['companyGameRating', gameId], ratingForGame);
    }

    case GET_EXTENDED_EMPLOYEE_RATING: {
      const { gameId, employeeId } = action.payload;

      const ratingForEmployee = fromJS({
        pending: true,
        fail: false,
        success: false,
        data: null,
        fields: null,
      });

      return state.setIn(['extendedEmployeeRating', gameId, employeeId], ratingForEmployee);
    }
    case GET_EXTENDED_EMPLOYEE_RATING_SUCCESS: {
      const { gameId, employeeId, data, fields } = action.payload;

      const ratingForEmployee = fromJS({
        pending: false,
        fail: false,
        success: true,
        data,
        fields,
      });

      return state.setIn(['extendedEmployeeRating', gameId, employeeId], ratingForEmployee);
    }
    case GET_EXTENDED_EMPLOYEE_RATING_FAIL: {
      const { gameId, employeeId } = action.payload;

      const ratingForEmployee = fromJS({
        ...emptyData,
        pending: false,
        fail: true,
        success: false,
      });

      return state.setIn(['extendedEmployeeRating', gameId, employeeId], ratingForEmployee);
    }
    default:
      return state;
  }
}

export default reducer;
