import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';

const Wrapper = styled.div`
  color: #808085;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin-right: 15px;
  position: relative;
  width: ${props => `calc(100% * ${props.width - 15}/1170)`};

  &:last-child {
    margin-left: auto;
    margin-right: 0;
    width: ${props => `calc(100% * ${props.width}/1170)`};
  }
`;

const TooltipAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Tooltip = styled(TooltipAnimation)`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  color: #1C1C1C;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: -63px;
  top: 31px;
  line-height: 15px;
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  transition: opacity .3s ease-out;
  width: ${props => (props.isBig ? '240px' : '140px')};

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: ${props => (props.isBig ? '-62px' : '-12px')};
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: ${props => (props.isBig ? '-63px' : '-13px')};
  }
`;

class RowHeader extends React.PureComponent {
  state = {
    isOpened: false,
  };

  toggleTooltip = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  render() {
    const { tooltip } = this.props;

    return (
      <Wrapper
        onMouseEnter={this.toggleTooltip}
        onMouseLeave={this.toggleTooltip}
        width={this.props.width}
      >
        {this.props.children}
        <PoseGroup>
          {tooltip && this.state.isOpened && (
            <Tooltip
              key="tooltip"
              isBig
            >
              {tooltip}
            </Tooltip>
          )}
        </PoseGroup>
      </Wrapper>
    );
  }
}

RowHeader.propTypes = {
  children: PropTypes.string,
  tooltip: PropTypes.string,
  width: PropTypes.number,
};

export default RowHeader;
