import React from 'react';
import Content from '../../../../components/Content/index';
import Section from '../../../../components/Section/index';
import H1 from './includes/H1';
import Text from './includes/Text';
import TextBlock from './includes/TextBlock';
import TextWrapper from './includes/TextWrapper';

const Organization = () => (
  <Section>
    <Content>
      <TextWrapper>
        <TextBlock>
          <H1>Контактная информация организации</H1>
          <Text>
            <p>Индивидуальный предприниматель Меликсетян Сергей Сейранович</p>
            <p>ОГРНИП 307565830400370 ИНН 561604624301</p>
            <p>Юридический адрес: 141435, Московская обл.,</p>
            <p>г. Химки, Микрорайон Новогорск, ул. Заречная, д. 7, корп. 1, кв. 7</p>
            <p>Почтовый адрес: 630004 г. Новосибирск, ул. Вокзальная магистраль, 1/1, офис 608.</p>

            <p>Реквизиты банка:</p>
            <p>р/с 40802810323250000110</p>
            <p>в филиал «Новосибирский» ОАО «Альфа-Банк» г. Новосибирск</p>
            <p>к/с 30101810600000000774</p>
            <p>БИК 045004774</p>
          </Text>
        </TextBlock>
      </TextWrapper>
    </Content>
  </Section>
);

export default Organization;
