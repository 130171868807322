import {
  PASSWORD_RESTORE_REQUEST,
  PASSWORD_RESTORE_REQUEST_FAIL,
  PASSWORD_RESTORE_REQUEST_SUCCESS,
  PASSWORD_RESTORE_RESET_FORM,
  PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST,
  PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_FAIL,
  PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_SUCCESS,
} from './constants';

export function sendPasswordRestoreRequest(payload) {
  return {
    type: PASSWORD_RESTORE_REQUEST,
    ...payload,
  };
}

export function sendPasswordRestoreRequestSuccess() {
  return {
    type: PASSWORD_RESTORE_REQUEST_SUCCESS,
  };
}

export function sendPasswordRestoreRequestFail() {
  return {
    type: PASSWORD_RESTORE_REQUEST_FAIL,
  };
}

export function resetSetPasswordFormState() {
  return {
    type: PASSWORD_RESTORE_RESET_FORM,
  };
}

export function validateToken(payload) {
  return {
    type: PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST,
    ...payload,
  };
}

export function validateTokenSuccess(isValidToken) {
  return {
    type: PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_SUCCESS,
    isValidToken,
  };
}

export function validateTokenFail() {
  return {
    type: PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_FAIL,
  };
}
