import {
  CHANGE_LEFT_MENU_STATE,
  CHANGE_TOP_MENU_STATE,
  CHANGE_POPUP_STATE,
  CHANGE_DROPZONE_STATE,
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_IS_FAIL,
  CHANGE_RESET_LOGIN,
  CHANGE_RESET_IS_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_REQUEST,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAIL,
  LOGOUT,
  SHOW_LOADING,
  HIDE_LOADING,
  RESET_RESET_PASSWORD_STATE,
  CHANGE_RIGHT_GAME_MENU_STATE,
  START_TRIAL_GAME,
  START_TRIAL_GAME_SUCCESS,
  START_TRIAL_GAME_FAIL,
  SET_ANALYTIC_PARAM_FOR_BACKEND,
} from './constants';

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function setAnalyticParamForBackend(values) {
  return {
    type: SET_ANALYTIC_PARAM_FOR_BACKEND,
    payload: values,
  };
}

export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    ...payload,
  };
}

export function loginFail({ errorMessage, errorCode }) {
  return {
    type: LOGIN_FAIL,
    payload: {
      errorMessage,
      errorCode,
    },
  };
}

export function resetRequest() {
  return {
    type: RESET_REQUEST,
  };
}

export function resetSuccess() {
  return {
    type: RESET_REQUEST_SUCCESS,
  };
}

export function resetFail() {
  return {
    type: RESET_REQUEST_FAIL,
  };
}

export function changeIsFail(payload) {
  return {
    type: CHANGE_IS_FAIL,
    ...payload,
  };
}

export function changeLeftMenuState(state) {
  return {
    type: CHANGE_LEFT_MENU_STATE,
    state,
  };
}

export function changeTopMenuState(state) {
  return {
    type: CHANGE_TOP_MENU_STATE,
    state,
  };
}

export function changeRightGameMenuState(state) {
  return {
    type: CHANGE_RIGHT_GAME_MENU_STATE,
    state,
  };
}

export function changePopupState(state) {
  return {
    type: CHANGE_POPUP_STATE,
    state,
  };
}

export function changeDropzoneState(state) {
  return {
    type: CHANGE_DROPZONE_STATE,
    state,
  };
}

export function resetResetPasswordState() {
  return {
    type: RESET_RESET_PASSWORD_STATE,
  };
}

export function changeLogin(payload) {
  return {
    type: CHANGE_LOGIN,
    ...payload,
  };
}

export function changePassword(payload) {
  return {
    type: CHANGE_PASSWORD,
    ...payload,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function showSpinner() {
  return {
    type: SHOW_LOADING,
  };
}

export function hideSpinner() {
  return {
    type: HIDE_LOADING,
  };
}

export function changeResetLogin(payload) {
  return {
    type: CHANGE_RESET_LOGIN,
    ...payload,
  };
}

export function changeResetIsFail(payload) {
  return {
    type: CHANGE_RESET_IS_FAIL,
    ...payload,
  };
}

export function startTrialGame(
  {
    email, gameSysName, name, phone, formik,
  },
) {
  return {
    type: START_TRIAL_GAME,
    payload: {
      email,
      gameSysName,
      name,
      phone,
      formik,
    },
  };
}

export function startTrialGameSuccess(activeGameId) {
  return {
    type: START_TRIAL_GAME_SUCCESS,
    activeGameId,
  };
}

export function startTrialGameFail() {
  return {
    type: START_TRIAL_GAME_FAIL,
  };
}
