import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Owl from '../../../components/Owl/includes/DesktopOwl/index';
import MobileOwl from '../../../components/Owl/includes/MobileOwl';
import OwlStepContent from '../../../components/Owl/includes/OwlStepContent';
import OwlTextContent from '../../../components/Owl/includes/OwlTextContent';
import { SIMPLE_TEXT_CONTENT, STEP_CONTENT } from './constats';
import getOwlContentType from './getOwlContentType';
import './owl-animation.css';

class OwlContainer extends React.Component {
  constructor(props) {
    super(props);

    if (props.text && !props.currentMessageShown) {
      props.setOwlVisible(true);
      const contentType = getOwlContentType(props.text);
      if (contentType === STEP_CONTENT) {
        props.setOwlInCenter(true);
      } else {
        props.setOwlInCenter(false);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.text && nextProps.text !== this.props.text) {
      this.props.setOwlVisible(true);
      this.props.setOwlCurrentMessageShown(false);
      const contentType = getOwlContentType(nextProps.text);
      if (contentType === STEP_CONTENT) {
        this.props.setOwlInCenter(true);
      } else {
        this.props.setOwlInCenter(false);
      }
    }
  }

  renderContent = () => {
    const {
      text,
      small,
    } = this.props;

    if (text !== '') {
      const contentType = getOwlContentType(text);
      let Wrapper = Owl;
      if (small) {
        Wrapper = MobileOwl;
      }

      return (
        <Wrapper
          renderContent={(props) => {
            if (contentType === SIMPLE_TEXT_CONTENT) {
              return (
                <OwlTextContent
                  button={this.props.button}
                  header={this.props.header}
                  owlData={text}
                  small={small}
                  setOwlVisible={this.props.setOwlVisible}
                  setOwlCurrentMessageShown={this.props.setOwlCurrentMessageShown}
                  setSpotlightVisible={this.props.setSpotlightVisible}
                  {...props}
                />
              );
            }

            if (contentType === STEP_CONTENT) {
              return (
                <OwlStepContent
                  stepInfo={text}
                  small={small}
                  {...props}
                />
              );
            }

            return null;
          }}
        />
      );
    }

    return <span />;
  };

  render() {
    const { text, isVisible } = this.props;

    return (
      <CSSTransition
        in={isVisible && Boolean(text) && text !== ''}
        timeout={300}
        classNames="owl-animation"
      >
        {this.renderContent()}
      </CSSTransition>
    );
  }
}

OwlContainer.propTypes = {
  createChannel: PropTypes.func.isRequired,
  setSpotlightVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  small: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

OwlContainer.defaultProps = {
  isVisible: false,
};

export default OwlContainer;
