import React from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import filter from 'lodash/filter';
import get from 'lodash/get';
import animateScrollTo from 'animated-scroll-to';
import Typograf from 'typograf';
import styled from 'styled-components';

import LoadingIndicator from '../../../components/LoadingIndicator';
import RatingContent from '../../../components/RatingContent';
import Section from '../../../components/Section';
import SimplePageBase from '../../PageBase/SimplePageBase';
import Table from './includes/Table';
import TabsContent from './includes/TabsContent';
import Tabs from '../../../components/Tabs';
import TabItem from '../../../components/Tabs/Tab';
import TabContent from './includes/TabContent';
import TabBody from './includes/TabBody';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const NoRatingData = styled.div`
    padding: 40px;
    font-size: 16px;
    text-align: center;
    width: 100%;
`;

class RatingAllPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tabsContentRef = React.createRef();
  }

  state = {
    scrollToUser: null,
  };

  componentWillReceiveProps(nextProps) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const scrollToUserId = urlParams.get('userId');
    if (
      scrollToUserId
      && this.props.isCommonRatingLoading
      && nextProps.isCommonRatingLoading !== this.props.isCommonRatingLoading
    ) {
      this.setState({
        scrollToUser: scrollToUserId,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollToUser } = this.state;
    if (scrollToUser && !prevState.scrollToUser) {
      const element = document.getElementById(`user-in-common-rating-${scrollToUser}`);
      if (element) {
        const offset = this.getScrollToHeight(element);
        const options = {
          element: this.tabsContentRef.current,
        };
        animateScrollTo(offset, options);
      }
    }
  }

  getScrollToHeight = (el) => {
    const elOffset = el.offsetTop - 131;
    const elHeight = el.clientHeight;

    const windowHeight = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

    if (elHeight < windowHeight) {
      return elOffset - ((windowHeight / 2) - (elHeight / 2));
    }

    return elOffset;
  };

  toggleActiveTab = (event) => {
    this.props.onTabChange(event.target.id);
  };

  renderGameRating = () => {
    const { fields } = this.props;

    const tableHeader = map(
      filter(fields, field => !field.IsSystem),
      (item, index) => ({
        key: index + 1,
        width: item.Width,
        title: item.Name,
        tooltip: tp.execute(item.Hint),
      }),
    );

    let tableItems = [];

    if (!isNull(this.props.gameRating) && this.props.gameRating) {
      tableItems = this.props.gameRating
        .filter(item => !item.outTop)
        .map((item, index) => {
          const rowData = map(
            filter(fields, field => !field.IsSystem),
            (field, index) => ({
              key: index + 1,
              width: field.Width,
              value: item[field.FieldName],
              field,
            }),
          );

          return {
            key: index,
            outOfList: false,
            isMyRating: item.userid === this.props.profile.userID,
            isChampion: rowData.position === 0,
            items: rowData,
          };
        });
    }

    const outRatingItem = get(filter(this.props.gameRating, (item => item.outTop)), '[0]');

    if (outRatingItem) {
      const myRowData = map(
        filter(fields, field => !field.IsSystem),
        (field, index) => ({
          key: index + 1,
          width: field.Width,
          value: outRatingItem[field.FieldName],
          field,
        }),
      );

      const myPlace = {
        key: 'out-of-rating',
        outOfList: true,
        isMyRating: myRowData.userid === this.props.profile.userID,
        isChampion: false,
        items: myRowData,
      };

      tableItems.push(myPlace);
    }

    let gameRating = (
      <RatingContent>
        <LoadingIndicator />
      </RatingContent>
    );

    if (!this.props.isGameRatingLoading) {
      gameRating = (
        <RatingContent>
          <Table
            minWidth="1000px"
            header={tableHeader}
            items={tableItems}
          />
        </RatingContent>
      );
    }

    return gameRating;
  };

  render() {
    const { fail } = this.props;

    return (
      <SimplePageBase title="Рейтинг в мире">
        <Section>
          <Tabs sticky={60}>
            {map(this.props.gameIdForTabs, item => (
              <TabItem
                key={item.id}
                id={item.id}
                active={this.props.activeTab === item.id}
                onClick={this.toggleActiveTab}
              >
                {tp.execute(item.name)}
              </TabItem>
            ))}
          </Tabs>
          <TabsContent ref={this.tabsContentRef}>
            {this.props.isCommonRatingLoading && (
              <TabContent>
                <TabBody>
                  <RatingContent>
                    <LoadingIndicator />
                  </RatingContent>
                </TabBody>
              </TabContent>
            )}
            {!this.props.isCommonRatingLoading && (
              <TabContent>
                <TabBody>
                  {!fail && this.props.isCommonRatingLoading && (
                    <RatingContent>
                      <LoadingIndicator />
                    </RatingContent>
                  )}
                  {!fail && !this.props.isCommonRatingLoading && this.renderGameRating()}
                  {fail && (
                    <RatingContent>
                      <NoRatingData>
                        👻 При получении рейтинга по этой игре произошла ошибка.
                      </NoRatingData>
                    </RatingContent>
                  )}
                </TabBody>
              </TabContent>
            )}
          </TabsContent>
        </Section>
      </SimplePageBase>
    );
  }
}

RatingAllPage.propTypes = {
  activeGameId: PropTypes.string,
  activeTab: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isCommonRatingLoading: PropTypes.bool,
  gameRating: PropTypes.array,
  isGameRatingLoading: PropTypes.bool,
  fail: PropTypes.bool,
};

export default RatingAllPage;
