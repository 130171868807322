import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import styled from 'styled-components';

import canUseWebP from '../../utils/getCanSupportWebP';
import getFormattedFieldData from '../../utils/getFormattedFieldData';
import Info from '../Info';

import Button from '../useThis/Button';
import ButtonWrapper from './includes/ButtonWrapper';
import ContentWrapper from './includes/ContentWrapper';
import GameBackground from './includes/GameBackground';
import Stat from './includes/Stat';
import H1 from './includes/H1';
import H2 from './includes/H2';
import Text from './includes/Text';
import TitleWrapper from './includes/TitleWrapper';
import TextWrapper from './includes/TextWrapper';

const ButtonWrapper2 = styled.div`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
`;

const Dash = styled.span`
  position: relative;
  top: -1px;
`;

const ButtonWrapper3 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const ButtonWrapper4 = styled(ButtonWrapper2)`
  margin-right: 20px;
  @media (max-width: 520px) {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    > div {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

const ButtonWidthWrapper = styled.div`
  width: auto;
  min-width: 250px;
`;

const ButtonWidthWrapperBuy = styled.div`
  width: auto;
  min-width: 250px;
`;

class GameInfo extends React.PureComponent {
  onMoreClick = () => {
    const { promoURL } = this.props;
    window.location = promoURL;
  };

  renderButtonsIfGameAllowed = () => {
    const {
      isFirstGame, canContinue, onStartFirstGame, isTrialGame,
      onStartNewGame, onContinueGame, onStartNewSuperGame,
      championConspectus,
      superGameAvailable,
      showPresettingScreen,
    } = this.props;

    if (!isTrialGame && !showPresettingScreen && !superGameAvailable) {
      if (!canContinue) {
        return (
          <ButtonWrapper2 marginBottom={20} marginRight={0}>
            <ButtonWidthWrapper>
              <Button noMargins allWidth onClick={onStartFirstGame}>Начать игру</Button>
            </ButtonWidthWrapper>
          </ButtonWrapper2>
        );
      }

      if (canContinue) {
        return (
          <>
            <ButtonWrapper4 marginBottom={20}>
              <ButtonWidthWrapper>
                <Button isWhite noMargins allWidth onClick={onStartNewGame}>Начать заново</Button>
              </ButtonWidthWrapper>
            </ButtonWrapper4>
            <ButtonWrapper2 marginBottom={20} marginRight={0}>
              <ButtonWidthWrapper>
                <Button noMargins allWidth onClick={onContinueGame}>Продолжить</Button>
              </ButtonWidthWrapper>
            </ButtonWrapper2>
          </>
        );
      }
    }

    if (isTrialGame) {
      return (
        <>
          <ButtonWidthWrapper>
            <Button noMargins allWidth onClick={onStartFirstGame}>Начать демо-игру</Button>
          </ButtonWidthWrapper>
          <ButtonWrapper3 marginBottom={0} marginRight={20}>
            <ButtonWidthWrapperBuy>
              <Button
                noMargins
                isWhite
                allWidth
                onClick={this.onMoreClick}
              >
                {this.props.previewButtonText}
              </Button>
            </ButtonWidthWrapperBuy>
          </ButtonWrapper3>
        </>
      );
    }

    if (showPresettingScreen) {
      if (isFirstGame) {
        return (
          <ButtonWidthWrapper>
            <Button noMargins allWidth onClick={onStartFirstGame}>Начать игру</Button>
          </ButtonWidthWrapper>
        );
      }

      if (!canContinue) {
        return (
          <ButtonWrapper2 marginBottom={20} marginRight={0}>
            <ButtonWidthWrapper>
              <Button noMargins allWidth onClick={onStartNewGame}>Начать заново</Button>
            </ButtonWidthWrapper>
          </ButtonWrapper2>
        );
      }

      if (canContinue) {
        return (
          <>
            <ButtonWrapper4 marginBottom={20}>
              <ButtonWidthWrapper>
                <Button isWhite noMargins allWidth onClick={onStartNewGame}>Начать заново</Button>
              </ButtonWidthWrapper>
            </ButtonWrapper4>
            <ButtonWrapper2 marginBottom={20} marginRight={0}>
              <ButtonWidthWrapper>
                <Button noMargins allWidth onClick={onContinueGame}>Продолжить</Button>
              </ButtonWidthWrapper>
            </ButtonWrapper2>
          </>
        );
      }
    }

    if (superGameAvailable) {
      const buttonName = championConspectus ? 'Конспект чемпиона' : 'Начать супер-игру';

      if (!canContinue) {
        return (
          <ButtonWrapper2 marginBottom={20} marginRight={0}>
            <ButtonWidthWrapper>
              <Button noMargins allWidth onClick={onStartNewSuperGame}>{buttonName}</Button>
            </ButtonWidthWrapper>
          </ButtonWrapper2>
        );
      }

      if (canContinue) {
        return (
          <>
            <ButtonWrapper4 marginBottom={20}>
              <ButtonWidthWrapper>
                <Button isWhite noMargins allWidth onClick={onStartNewSuperGame}>{buttonName}</Button>
              </ButtonWidthWrapper>
            </ButtonWrapper4>
            <ButtonWrapper2 marginBottom={20} marginRight={0}>
              <ButtonWidthWrapper>
                <Button noMargins allWidth onClick={onContinueGame}>Продолжить</Button>
              </ButtonWidthWrapper>
            </ButtonWrapper2>
          </>
        );
      }
    }

    return null;
  };

  render() {
    const { small } = this.props;

    const { data, fields } = this.props;

    const { currentGameInfo } = this.props;
    const name = get(currentGameInfo, 'Name');
    const description = get(currentGameInfo, 'OptionsValues.shortSlogan', '');

    const gameOptionsValues = get(currentGameInfo, 'OptionsValues');

    const isCanUseWebP = canUseWebP();
    const darkImageWebP = gameOptionsValues.darkImageWebP;
    const darkImageSimple = gameOptionsValues.darkImage;
    const darkImage = isCanUseWebP && darkImageWebP ? darkImageWebP : darkImageSimple;

    return (
      <GameBackground small={small} image={darkImage}>
        <TitleWrapper>
          <H1>{name}</H1>
          <H2>{description}</H2>
        </TitleWrapper>
        <ContentWrapper
          flexWrap={small && 'wrap'}
          justify={small && 'center'}
        >
          {
            fields && fields
              .filter(field => !field.IsSystem)
              .map((field) => (
                <Stat
                  wide
                  key={field.FieldName}
                  number={getFormattedFieldData({ value: data[field.FieldName], fieldType: field.ColumnType })}
                  text={
                    <>
                      {field.Name}
                      {field.Hint && (
                        <Info
                          textColor="#232323"
                          isBig
                          text={field.Hint}
                        />
                      )}
                    </>
                  }
                />
              ))
          }

        </ContentWrapper>
        <ContentWrapper>
          <TextWrapper>
            {this.props.name && (
              <Text bold textAlign="center">
                {`Привет, ${this.props.name}!`}
              </Text>
            )}
            {!this.props.gameAllowed && this.props.promoPreviewText && (
              <Text textAlign="center">
                {this.props.promoPreviewText}
              </Text>
            )}
          </TextWrapper>
        </ContentWrapper>
        <ButtonWrapper
          center={this.props.isFirstGame || !this.props.canContinue}
          flexWrap="wrap"
          justify="center"
        >
          {isNull(this.props.gameAllowed) && (
            <ButtonWrapper2 marginBottom={20}>
              <ButtonWidthWrapper>
                <Button
                  allWidth
                  disabled
                  onClick={() => {
                  }}
                >
                  Загрузка...
                </Button>
              </ButtonWidthWrapper>
            </ButtonWrapper2>
          )}

          {!isNull(this.props.gameAllowed) && !this.props.gameAllowed && (
            <>
              <ButtonWrapper3 marginBottom={0} marginRight={20}>
                <ButtonWidthWrapperBuy>
                  <Button
                    allWidth
                    noMargins
                    onClick={this.props.onGameBuy}
                    disabled={this.props.buyPending}
                  >
                    {this.props.buyPending && 'Загрузка...'}
                    {!this.props.buyPending && 'Купить'}
                  </Button>
                </ButtonWidthWrapperBuy>
              </ButtonWrapper3>
              {this.props.previewButtonText && (
                <ButtonWrapper3 marginBottom={0} marginRight={20}>
                  <ButtonWidthWrapperBuy>
                    <Button
                      noMargins
                      isWhite
                      allWidth
                      onClick={this.onMoreClick}
                    >
                      {this.props.previewButtonText}
                    </Button>
                  </ButtonWidthWrapperBuy>
                </ButtonWrapper3>
              )}
            </>
          )}

          {this.props.gameAllowed && this.renderButtonsIfGameAllowed()}
        </ButtonWrapper>
      </GameBackground>
    );
  }
}

GameInfo.propTypes = {
  companyName: PropTypes.string,
  gameAllowed: PropTypes.bool,
  canContinue: PropTypes.bool,
  isTrialGame: PropTypes.bool,
  activeGameId: PropTypes.string,
  isFirstGame: PropTypes.bool,
  small: PropTypes.bool,
  name: PropTypes.string,
  stat: PropTypes.object,
  stage: PropTypes.number,
  onStartNewGame: PropTypes.func,
  onStartNewSuperGame: PropTypes.func,
  onStartFirstGame: PropTypes.func,
  setBuyGamePopupOpened: PropTypes.func,
  onContinueGame: PropTypes.func,
  trackBuyGameButtonClicked: PropTypes.func,
  onGameBuy: PropTypes.func,
  setBuyGameGuestPopupOpened: PropTypes.func.isRequired,
  isGuest: PropTypes.bool.isRequired,
};

export default GameInfo;
