import { fromJS } from 'immutable';
import {
  GET_GAME_STAGE_SUCCESS,
} from './constants';

const initialState = fromJS({
  ownedStage: 0,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GAME_STAGE_SUCCESS:
      return state
        .set('ownedStage', action.ownedStage);
    default:
      return state;
  }
}

export default reducer;
