import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import isFinite from 'lodash/isFinite';
import posed, { PoseGroup } from 'react-pose';
import { noun } from 'plural-ru';

import KeyGrey from '../../../assets/key.svg';
import KeyWhite from '../../../assets/white-key.svg';

const KeysWrapper = styled.div`
  background-image: url(${props => props.bg});
  background-size: contain;
  cursor: pointer;
  height: 20px;
  ${props => !props.noMargins && 'margin: auto 50px auto 20px;'}
  position: relative;
  width: ${props => (props.desktop ? 40 : 20)}px;
  user-select: none;
`;

const KeysCount = styled.div`
  background-color: ${props => (props.moreZore ? '#D91E47' : '#808085')};
  border-radius: 50%;
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: -10px;
  width: 20px;
`;

const Inner = styled.div`
  display: block;
  height: 20px;
  width: 20px;
`;

const TooltipAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150, delay: 100 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Tooltip = styled(TooltipAnimation)`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  color: #1C1C1C;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: -60px;
  line-height: 15px;
  top: 38px;
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  transition: opacity .3s ease-out;
  z-index: 1;
  width: ${props => (props.isBig ? '240px' : '140px')};

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: ${props => (props.isBig ? '-62px' : '-12px')};
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: ${props => (props.isBig ? '-63px' : '-13px')};
  }
`;

const ADD_KEY_ANIMATION_DURATION = 1000;

const KeyAddAnimation = posed.div({
  enter: {
    y: 20,
    opacity: 1,
    transition: {
      opacity: {
        type: 'keyframes',
        values: [0, 0.3, 0.9, 1, 0.9, 0.3, 0],
        duration: ADD_KEY_ANIMATION_DURATION,
      },
      y: {
        type: 'keyframes',
        values: [20, -20],
        duration: ADD_KEY_ANIMATION_DURATION,
      },
    },
  },
  exit: {
    y: -20,
    opacity: 0,
  },
});

const KeyAdd = styled(KeyAddAnimation)`
  color: #578E1F;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  right: -35px;
  top: -5px;
`;

class Keys extends React.PureComponent {
  static defaultProps = {
    desktop: false,
    keysCount: 0,
    white: false,
  };

  static propTypes = {
    desktop: PropTypes.bool,
    keysCount: PropTypes.number,
    white: PropTypes.bool,
  };

  state = {
    isOpened: false,
    keyDelta: 0,
    keysCount: null,
  };

  componentWillReceiveProps(nextProps) {
    const { keysCount } = this.props;

    if (isFinite(this.props.keysCount) && isFinite(nextProps.keysCount) && nextProps.keysCount !== keysCount) {
      this.setState({
        keyDelta: Math.abs(nextProps.keysCount - keysCount),
      });
    }
  }

  toggleTooltip = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  resetDelta = (pose) => {
    setTimeout(() => {
      if (pose === 'enter') {
        this.setState({
          keyDelta: 0,
        });
      }
    }, ADD_KEY_ANIMATION_DURATION);
  };

  render() {
    const { keysCount, desktop } = this.props;
    const { isOpened, keyDelta } = this.state;

    const plural = noun(keysCount, 'ключ', 'ключа', 'ключей');

    const tooltip = `В этой игре вы нашли ${keysCount} ${plural}. Завершите игру, что бы они открыли доступ к книгам в библиотеке.`;

    return (
      <KeysWrapper
        desktop={desktop}
        bg={this.props.white ? KeyWhite : KeyGrey}
        noMargins={this.props.white}
        className={classNames([
          !desktop && 'tour-step-keys-mobile',
          desktop && 'tour-step-keys-desktop',
        ])}
      >
        <Inner
          onMouseEnter={this.toggleTooltip}
          onMouseLeave={this.toggleTooltip}
        >
          <KeysCount moreZore={keysCount && keysCount > 0}>
            {keysCount}
          </KeysCount>
          <PoseGroup>
            {keyDelta && (
              <KeyAdd key="keyDelta" onPoseComplete={this.resetDelta}>
                {`+${keyDelta}`}
              </KeyAdd>
            )}
          </PoseGroup>
        </Inner>
        <PoseGroup>
          {tooltip && isOpened && (
            <Tooltip key="tooltip">
              {tooltip}
            </Tooltip>
          )}
        </PoseGroup>
      </KeysWrapper>
    );
  }
}

export default Keys;
