import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;
  position: relative;
`;

const Icon = styled.div`
  color: ${props => (props.white ? '#ffffff' : '#3D3F4A')};
  cursor: pointer;
  font-family: 'Merriweather', serif;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
`;

const TooltipAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150, delay: 100 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Tooltip = styled(TooltipAnimation)`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  color: #1C1C1C;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: -60px;
  line-height: 15px;
  top: 38px;
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  transition: opacity .3s ease-out;
  z-index: 1;
  width: ${props => (props.isBig ? '240px' : '140px')};

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: ${props => (props.isBig ? '-62px' : '-12px')};
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: ${props => (props.isBig ? '-63px' : '-13px')};
  }
`;

class Stavka extends React.PureComponent {
  state = {
    isOpened: false,
  };

  toggleTooltip = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  render() {
    const {
      text, tooltip, white,
    } = this.props;

    const { isOpened } = this.state;

    return (
      <InfoWrapper>
        <Icon
          white={white}
          onMouseEnter={this.toggleTooltip}
          onMouseLeave={this.toggleTooltip}
        >
          {text}
        </Icon>
        <PoseGroup>
          {tooltip && isOpened && (
            <Tooltip
              key="tooltip"
              isBig={this.props.isBig}
            >
              {tooltip}
            </Tooltip>
          )}
        </PoseGroup>
      </InfoWrapper>
    );
  }
}

Stavka.propTypes = {
  isBig: PropTypes.bool,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  white: PropTypes.bool,
};

Stavka.defaultProps = {
  white: false,
};

export default Stavka;
