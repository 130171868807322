import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import pure from 'recompose/pure';
import { Link } from 'react-router-dom';

import URL_CONSTANTS from '../../../../../urlConstants';

const Container = pure(styled.div`
  width: 100%;
  background: #60B89C;
  text-align: center;
  position: fixed;
  
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`);

const Text = pure(styled(Link)`
  color: white;
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  } 
`);

class GoToFullVersion extends React.PureComponent {
  static defaultProps = {
    isTrialGame: false,
  };

  static propTypes = {
    isTrialGame: PropTypes.bool,
    trackClickGetFullAccessLink: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.trackClickGetFullAccessLink();
  };

  render() {
    if (!this.props.isTrialGame) {
      return null;
    }

    return (
      <Container>
        <Text to={URL_CONSTANTS.URLS.GET_FULL_ACCESS} onClick={this.onClick}>Перейти на полную версию</Text>
      </Container>
    );
  }
}

export default GoToFullVersion;
