import styled from 'styled-components';

const Line = styled.div`
  border-bottom: 1px solid #DEDEDE;
  border-top: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-around;
  margin-top: 36px;
  flex-wrap: wrap;
  padding: 0 10px;
`;

export default Line;
