import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import styled from 'styled-components';
import { useMedia } from 'the-platform';
import { device } from '../../utils/mediaQueries';
import ClientStatus from './includes/ClientStatus';
import MyStatus from './includes/MyStatus';
import ContractRange from './includes/ContractRange';
import SomeStatus from './includes/SomeStatus';

const GameStatusWrapper = styled.div`
  align-items: center;
  display: flex;
  ${props => !props.small && 'margin: 0 auto;'}
`;

const ContractRangeWrapper = styled.div`
  min-width: 180px;
  @media (max-width: 400px) {
    min-width: auto;
    width: 150px;
  }
`;

const GameStatus = ({ handleWidgetClick, widgetList }) => {
  const small = useMedia(device.mobileL);

  return (
    <GameStatusWrapper small={small} className="tour-step-mood">
      {
        widgetList && map(filter(widgetList, widget => widget.Position === 'wpLeft'), widget => (
          <SomeStatus
            key={widget.GameWidgetID}
            onClick={handleWidgetClick(widget.GameWidgetID)}
            trendValue={widget.TrendValue}
            imageURL={widget.ImageURL}
            hint={widget.Hint}
            arrow={widget.state}
          />
        ))
      }

      <ContractRangeWrapper>
        <ContractRange />
      </ContractRangeWrapper>

      {
        widgetList && map(filter(widgetList, widget => widget.Position === 'wpRight'), widget => (
          <SomeStatus
            key={widget.GameWidgetID}
            onClick={handleWidgetClick(widget.GameWidgetID)}
            trendValue={widget.TrendValue}
            imageURL={widget.ImageURL}
            hint={widget.Hint}
            arrow={widget.state}
          />
        ))
      }
    </GameStatusWrapper>
  );
};

export default GameStatus;
