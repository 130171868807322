import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { withFormik } from 'formik';
import withHandlers from 'recompose/withHandlers';
import { getBookFragment as getBookFragmentAction } from '../../redux/modules/getBookFragment/actions';
import GetBookPartToEmailValidateSchema from './GetBookPartToEmailValidateSchema';

const mapDispatchToProps = dispatch => ({
  getBookFragment: ({ email, formik }) => dispatch(getBookFragmentAction({ email, formik })),
});

const withConnect = connect(null, mapDispatchToProps);

const withOnStartTrialGame = withHandlers({
  onGetBookFragment: props => (
    {
      email, formik,
    },
  ) => {
    props.getBookFragment({
      email,
      formik,
    });
  },
});

const withFormFormik = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: GetBookPartToEmailValidateSchema,
  handleSubmit: (values, { props, ...formik }) => {
    const email = values.email.trim().toLowerCase();

    props.onGetBookFragment({
      email,
      formik,
    });
  },
  displayName: 'GetBookPartToEmailForm',
});

const enhance = compose(
  pure,
  withConnect,
  withOnStartTrialGame,
  withFormFormik,
);

export default enhance;
