import PropTypes from 'prop-types';
import React from 'react';
import { useMedia } from 'the-platform';
import MVKPromoBuyPopup from '../../containers/pages/Promo/common/MVKPromoBuyPopup';
import { device } from '../../utils/mediaQueries';

const BuyGameGuestPopup = ({ onGameBuy, opened, onClose }) => {
  const small = useMedia(device.tablet);

  return (
    <MVKPromoBuyPopup
      small={small}
      open={opened}
      onClose={onClose}
      onSubmit={onGameBuy}
    />
  );
};

BuyGameGuestPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onGameBuy: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default BuyGameGuestPopup;
