import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NormalText from '../../../../components/Text';
import SmallPopup from '../../../../components/SmallPopup';

const PopupText = styled(NormalText)`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

class GameInvoiceCreatedPopup extends React.PureComponent {
  onSubmit = () => {
    this.props.onClose();
  };

  render() {
    return (
      <SmallPopup
        small={this.props.small}
        title="Заказ успешно оформлен"
        cancelText={null}
        pending={false}
        submitText="Ок"
        isActive={this.props.open}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
      >
        <PopupText>
          <div>
            Для оплаты игры с вами свяжется наш менеджер.
          </div>
        </PopupText>
      </SmallPopup>
    );
  }
}

GameInvoiceCreatedPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default GameInvoiceCreatedPopup;
