import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Coin from '../Coin';
import Info from '../Info';

const Balance = styled.div`
  align-self: center;
  color: #808085;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  ${props => !props.small && 'margin-right: auto;'}
  
  @media (max-width: 400px) {
    margin: 0 auto;
  }
`;

const StatusItem = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
`;

const Number = styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 63px;
  margin: 0 10px;
  white-space: nowrap;
`;

const Text = styled.div`
  align-items: center;
  color: #1C1C1C;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 17px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 14px;
  margin: 3px 0;
  ${props => props.noWrap && 'white-space: nowrap;'}
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

class BalanceInPopup extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <Balance small={this.props.small}>
        <StatusItem>
          <Text>
            <NoWrap>
              Баланс
            </NoWrap>
          </Text>
          <Number>{this.props.balance}</Number>
          <Text>
            <Coin />
            {/* // TODO: HARDCODE_TYPE_2 Описание игровых рублей зашито в код */}
            <Info
              text="Игровые рубли можно потратить на&nbsp;методички, сохранения и&nbsp;подсказки. Заработать можно совершая продажи."
            />
          </Text>
        </StatusItem>
      </Balance>
    );
  }
}

BalanceInPopup.propTypes = {
  balance: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  small: PropTypes.bool,
};

export default BalanceInPopup;
