import find from 'lodash/find';

/* eslint-disable react/jsx-no-bind */
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import pure from 'recompose/pure';
import withProps from 'recompose/withProps';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import posed, { PoseGroup } from 'react-pose';
import animateScrollTo from 'animated-scroll-to';
import { withCookies } from 'react-cookie';
import ThreeDostsSVG from '../../../assets/mobile_menu/3dots.svg';
import CrossSVG from '../../../assets/mobile_menu/cross.svg';

import { makeSelectActiveGameId } from '../../../redux/modules/activeGame/selectors';
import { trackOpenLibraryPopupInGame } from '../../../redux/modules/analytics/actions';

import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { refreshSlideContent } from '../../../redux/modules/debug/actions';
import { makeSelectGames, makeSelectGamesShowcases } from '../../../redux/modules/games/selectors';
import { setActiveCardGroupId, setActiveCardId, setShowLibraryPopup } from '../../../redux/modules/library/actions';
import scrollLock from '../../../utils/scrollLock';

import BuyCardPopup from '../GameOverviewPage/includes/BuyCardPopup';
import { makeSelectLeftMenuState, makeSelectRightGameMenuIsOpened } from '../../../redux/modules/global/selectors';
import { makeSelectCanDebug, makeSelectProfile } from '../../../redux/modules/profile/selectors';

import Stages from '../../../components/Stages';
import Tips from '../../../components/Tips';
import Popup from '../../../components/TipPopup';

import LibraryInPopup from '../../LibraryInPopup';
import ActionScreen from './includes/ActionScreen';

import {
  makeSelectCards,
  makeSelectHighlightTip,
  makeSelectFiftyFiftyTip,
  makeSelectSaveTip,
  makeSelectSuperGame,
  makeSelectStage,
  makeSelectCardKeyProductPrice,
  makeSelectCanContinue,
  makeSelectIsGameInitialized,
  makeSelectLastBuyCard,
  makeSelectIsFirstGame,
  makeSelectIsFirstGameTourWasShown,
  makeSelectFinalCupImage,
  makeSelectFinalCupImageTexts,
  makeSelectSession, makeSelectShowActionButtonsOnMobile, makeSelectStagesInfo,
} from '../../../redux/modules/myGame/selectors';

import {
  buyCardKey,
  nextStep,
  checkAnswer,
  getHighlightTip,
  getFiftyFiftyTip,
  getSaveTip,
  buyFiftyFiftyTip,
  buyHighlightTip,
  buySaveGame,
  continueGame,
  continueGameFromSave,
  setShowActionButtonsOnMobile,
} from '../../../redux/modules/myGame/actions';

import TipBuyBody from './includes/TipBuyBody';

import Content from './includes/styled/Content';

const tipPlural = ['подсказку', 'подсказки', 'подсказок'];
const savePlural = ['сохранение', 'сохранения', 'сохранений'];
const cashPlural = ['рубль', 'рубля', 'рублей'];

const CrossIcon = pure(styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  background: url(${CrossSVG}) no-repeat;
  background-size: 21px 21px;
  background-position: center;
  transition: all .15s ease-out;
  will-change: transform;
`);

const DotsIcon = pure(styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  background: url(${ThreeDostsSVG}) no-repeat;
  background-size: 26px 6px;
  background-position: center;
  transition: all .15s ease-out;
  will-change: transform;
`);

const ButtonsWrapperAnimation = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

const StagesAnimation = posed.div({
  hidden: {
    x: -200,
    height: '100%',
  },
  visible: {
    x: -5,
    height: '100%',
  },
});

const ButtonsWrapper = styled(ButtonsWrapperAnimation)`
  display: block;
  background: transparent; 
  ${props => props.opened && props.small && `
    background: rgba(0, 0, 0, 0.15);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    transition: background .3s ease-out;
  `}
`;

const ButtonsWrapperToggleButton = styled.div`
  background-color: #35BB9A;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.40);
  bottom: 15px;
  right: 15px;
  z-index: 5;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  position: fixed;
  transition: all .3s ease-out;
  
  &.wrapper-hidden .on-game-mobile-toggle-button-icon-dots {
    transform: rotate(0) scale(1);
  }
   
  &.wrapper-shown .on-game-mobile-toggle-button-icon-dots {
    transform: rotate(180deg) scale(0);
  }
  
  &.wrapper-hidden .on-game-mobile-toggle-button-icon-cross {
    transform: rotate(-180deg) scale(0);
  }
  
  &.wrapper-shown .on-game-mobile-toggle-button-icon-cross {
    transform: rotate(0) scale(1);
  }
`;

class GameContent extends React.PureComponent {
  state = {
    showLibraryPopup: false,
    activeCardId: null,
    cardIdForBuy: null,
    keyCountForBuy: null,
    showHighlightPopup: false,
    showFiftyFiftyPopup: false,
    showSavePopup: false,
    showBuyCardPopup: false,
  };

  componentDidMount() {
    const {
      isGameInitialized, continueGame, getNextStep, continueGameFromLastSave,
    } = this.props;

    const urlParams = new URLSearchParams(this.props.location.search);
    const action = urlParams.get('action');

    if (!isGameInitialized && (!action || action !== 'loadSaveGame')) {
      continueGame();
      getNextStep();
    } else if (!isGameInitialized && action && action === 'loadSaveGame') {
      continueGameFromLastSave();
    } else {
      // Начало новой игры.
      getNextStep();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastBuyCard && prevProps.lastBuyCard !== this.props.lastBuyCard) {
      this.props.setActiveCardId(this.props.lastBuyCard);
      this.props.setShowLibraryPopup(true);
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    const action = urlParams.get('action');

    if (!prevProps.isGameInitialized && this.props.isGameInitialized && action === 'loadSaveGame') {
      this.props.history.push(this.props.location.pathname);
    }
  }

  componentWillUnmount() {
    if (this.props.showBuyCardPopup) {
      scrollLock.off();
    }
  }

  getHighlightTip = count => () => {
    const { highlightTip: { price } } = this.props;

    const cashValue = this.getCashValue();

    if (cashValue < price * count) {
      return;
    }

    this.toggleHightlightPopup();
    this.props.buyHighlightTip(count);
  };

  getFiftyFiftyTip = count => () => {
    const { fiftyFiftyTip: { price } } = this.props;

    const cashValue = this.getCashValue();

    if (cashValue < price * count) {
      return;
    }

    this.toggleFiftyFiftyPopup();
    this.props.buyFiftyFiftyTip(count);
  };

  getSaveGame = count => () => {
    const { saveTip: { price } } = this.props;

    const cashValue = this.getCashValue();

    if (cashValue < price * count) {
      return;
    }

    this.toggleSavePopup();
    this.props.buySaveGame(count);
  };

  toggleHightlightPopup = () => {
    if (this.props.highlightTip.count > 0) {
      this.props.getHighlightTip();
    } else {
      const { showHighlightPopup } = this.state;
      this.setState({
        showHighlightPopup: !showHighlightPopup,
      });
    }
  };

  toggleFiftyFiftyPopup = () => {
    if (this.props.fiftyFiftyTip.count > 0) {
      if (!this.props.fiftyFiftyTip.used) {
        this.props.getFiftyFiftyTip();
      }
    } else {
      this.setState({
        showFiftyFiftyPopup: !this.state.showFiftyFiftyPopup,
      });
    }
  };

  toggleSavePopup = () => {
    if (this.props.saveTip.count > 0) {
      this.props.getSaveTip();
    } else {
      const { showSavePopup } = this.state;
      this.setState({
        showSavePopup: !showSavePopup,
      });
    }
  };

  toggleButtonWrapper = () => {
    this.props.setShowActionButtonsOnMobile(!this.props.showButtonWrapper);
  };

  onButtonsWrapperClick = () => {
    if (this.props.showButtonWrapper) {
      this.props.setShowActionButtonsOnMobile(false);
    }
  };

  // TODO: Функция также встречается в ./includes/Answers
  checkAnswer = (id) => {
    if (this.props.methodType === 'One') {
      this.props.checkAnswer({
        answers: this.props.answers.map(answer => ({
          id: answer.id,
          text: answer.text,
          isActive: answer.id === id,
          additional: answer.id === id,
        })),
        sendAnswers: true,
      });

      animateScrollTo(0);
    } else if (this.props.methodType === 'Two') {
      const answerOrders = this.props.answers.map(
        answer => (isUndefined(answer.additional) ? 0 : answer.additional),
      );

      const maxAnswerOrder = Math.max.apply(null, answerOrders);

      const newAnswers = this.props.answers.map((answer) => {
        if (id === answer.id) {
          return {
            id: answer.id,
            text: answer.text,
            isActive: !answer.isActive,
            additional: answer.isActive ? 0 : maxAnswerOrder + 1,
          };
        }

        return {
          id: answer.id,
          text: answer.text,
          isActive: answer.isActive,
          additional: isUndefined(answer.additional) ? 0 : answer.additional,
        };
      });

      const compareAnswers = (answerA, answerB) => {
        if (answerA.additional === 0 && answerB.additional !== 0) {
          return 1;
        }
        if (answerB.additional === 0 && answerA.additional !== 0) {
          return -1;
        }

        return answerA.additional - answerB.additional;
      };

      const newAnswersOrders = newAnswers.map(
        answer => (isUndefined(answer.additional) ? 0 : answer.additional),
      );

      this.props.checkAnswer({
        answers: newAnswers.sort(compareAnswers),
        sendAnswers: Math.min.apply(null, newAnswersOrders) !== 0,
      });
    }
  };

  closeLibraryPopup = () => {
    this.props.setShowLibraryPopup(false);
  };

  showLibraryPopup = ({ GroupCardID, CardID }) => {
    if (GroupCardID) {
      this.props.setActiveCardGroupId(GroupCardID);
    }

    if (CardID) {
      this.props.setActiveCardId(CardID);
    }

    this.props.setShowLibraryPopup(true);
    this.props.trackOpenLibraryPopupInGame();
  };

  showBuyPopup = (keyCount, cardId, image) => {
    scrollLock.on();

    this.setState({
      showBuyCardPopup: true,
      keyCountForBuy: keyCount,
      cardIdForBuy: cardId,
      buyCardImage: image,
    });
  };

  closeBuyPopup = () => {
    scrollLock.off();

    this.setState({
      showBuyCardPopup: false,
      keyCountForBuy: null,
      cardIdForBuy: null,
      buyCardImage: null,
    });
  };

  buyCard = () => {
    this.props.onBuyCard({
      count: this.state.keyCountForBuy,
      cardIdForBuy: this.state.cardIdForBuy,
    });
  };

  getCashValue = () => {
    const currentGameInfo = find(this.props.allGames, { GameID: this.props.activeGameId }, false);
    const virtualCashKey = get(currentGameInfo, 'OptionsValues.cashSysName', null);

    const cash = find(this.props.profile.virtualCash, { name: virtualCashKey });
    return cash ? cash.balance : 0;
  };

  render() {
    const currentGameInfo = find(this.props.allGames, { GameID: this.props.activeGameId }, false);
    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    const hideSaveGameTip = this.props.superGame.isEnabled
      || this.props.championConspectus || !this.props.saveTip || !this.props.saveTip.id;

    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;

    const cashValue = this.getCashValue();

    const { cardKeyPrice, libraryCards } = this.props;

    const hasCards = libraryCards && !!libraryCards.length;

    return (
      <Content isGame small={small} isTrialGame={isTrialGame}>
        <ActionScreen />
        <PoseGroup>
          <ButtonsWrapper
            key="ButtonsWrapper"
            small={small}
            opened={this.props.showButtonWrapper}
            onClick={this.onButtonsWrapperClick}
          >
            {small && (
              <StagesAnimation
                key="StagesAnimation"
                initialPose="hidden"
                pose={this.props.showButtonWrapper ? 'visible' : 'hidden'}
              >
                <Stages
                  onClick={this.showLibraryPopup}
                  activeStage={parseInt(this.props.stage, 10)}
                  stagesInfo={this.props.stagesInfo}
                />
              </StagesAnimation>
            )}
            {!small && (
              <Stages
                onClick={this.showLibraryPopup}
                activeStage={parseInt(this.props.stage, 10)}
                stagesInfo={this.props.stagesInfo}
              />
            )}
            <Tips
              isTrialGame={isTrialGame}
              visible={!small || (small && this.props.showButtonWrapper)}
              highlightTipCount={this.props.highlightTip.count}
              fiftyFiftyTipCount={this.props.fiftyFiftyTip.count}
              saveCount={this.props.saveTip.count}
              canDebug={this.props.canDebug}
              onDebugTipClick={this.props.onDebugTipClick}
              showHighlightTip={this.props.highlightTip.showTip && !this.props.superGame.isEnabled}
              showFiftyFiftyTip={this.props.fiftyFiftyTip.showTip && !this.props.superGame.isEnabled}
              small={small}
              hasLibrary={hasCards}
              showSave={!hideSaveGameTip}
              highlightClick={this.toggleHightlightPopup}
              fiftyFiftyClick={this.toggleFiftyFiftyPopup}
              saveClick={this.toggleSavePopup}
              libraryClick={this.showLibraryPopup}
            />
          </ButtonsWrapper>
        </PoseGroup>

        {small && !this.props.rightGameMenuIsOpened && (
          <ButtonsWrapperToggleButton
            className={classNames([
              'on-game-mobile-toggle-button',
              this.props.showButtonWrapper && 'wrapper-shown',
              !this.props.showButtonWrapper && 'wrapper-hidden',
            ])}
            onClick={this.toggleButtonWrapper}
            wrapperShown={this.props.showButtonWrapper}
          >
            <DotsIcon className="on-game-mobile-toggle-button-icon-dots" />
            <CrossIcon className="on-game-mobile-toggle-button-icon-cross" />
          </ButtonsWrapperToggleButton>
        )}

        <Popup
          withoutPadding={small}
          title="Купить подсказку"
          isActive={this.state.showHighlightPopup}
          onClose={this.toggleHightlightPopup}
          type="highlight"
          cancelText={!small ? 'Отмена' : null}
          balance={cashValue.toLocaleString()}
        >
          <TipBuyBody
            key="highlightBody"
            tipPlural={tipPlural}
            cashPlural={cashPlural}
            balance={cashValue}
            price={this.props.highlightTip.price}
            onBuy={this.getHighlightTip}
          />
        </Popup>

        <Popup
          withoutPadding={small}
          title="Купить подсказку"
          isActive={this.state.showFiftyFiftyPopup}
          onClose={this.toggleFiftyFiftyPopup}
          type="fiftyFifty"
          cancelText={!small ? 'Отмена' : null}
          balance={cashValue.toLocaleString()}
        >
          <TipBuyBody
            key="fiftyFiftyBody"
            tipPlural={tipPlural}
            cashPlural={cashPlural}
            balance={cashValue}
            price={this.props.fiftyFiftyTip.price}
            onBuy={this.getFiftyFiftyTip}
          />
        </Popup>

        <Popup
          withoutPadding={small}
          title="Купить сохранение"
          isActive={this.state.showSavePopup}
          onClose={this.toggleSavePopup}
          type="save"
          cancelText={!small ? 'Отмена' : null}
          balance={cashValue}
        >
          <TipBuyBody
            key="saveGameBody"
            tipPlural={savePlural}
            cashPlural={cashPlural}
            balance={cashValue}
            price={this.props.saveTip.price}
            onBuy={this.getSaveGame}
          />
        </Popup>

        <LibraryInPopup
          small={small}
          onClose={this.closeLibraryPopup}
          onBuyCard={this.showBuyPopup}
        />

        {this.state.keyCountForBuy !== null && (
          <BuyCardPopup
            small={small}
            open={this.state.showBuyCardPopup}
            onClose={this.closeBuyPopup}
            onSubmit={this.buyCard}
            price={cardKeyPrice.price}
            keyCountForBuy={this.state.keyCountForBuy}
            cash={cashValue}
            cardImage={this.state.buyCardImage}
          />
        )}
      </Content>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveCardId: value => dispatch(setActiveCardId(value)),
    setActiveCardGroupId: value => dispatch(setActiveCardGroupId(value)),
    setShowLibraryPopup: value => dispatch(setShowLibraryPopup(value)),
    onBuyCard: card => dispatch(buyCardKey(card)),
    buySaveGame: count => dispatch(buySaveGame(count)),
    buyFiftyFiftyTip: count => dispatch(buyFiftyFiftyTip(count)),
    buyHighlightTip: count => dispatch(buyHighlightTip(count)),
    getNextStep: () => dispatch(nextStep()),
    checkAnswer: answer => dispatch(checkAnswer(answer)),
    getHighlightTip: () => dispatch(getHighlightTip()),
    getFiftyFiftyTip: () => dispatch(getFiftyFiftyTip()),
    getSaveTip: () => dispatch(getSaveTip()),
    continueGame: () => dispatch(continueGame()),
    continueGameFromLastSave: () => dispatch(continueGameFromSave()),
    trackOpenLibraryPopupInGame: () => dispatch(trackOpenLibraryPopupInGame()),
    setShowActionButtonsOnMobile: showButtonWrapper => dispatch(setShowActionButtonsOnMobile(showButtonWrapper)),
    onDebugTipClick: () => dispatch(refreshSlideContent()),
  };
}

const mapStateToProps = createStructuredSelector({
  canDebug: makeSelectCanDebug(),
  showButtonWrapper: makeSelectShowActionButtonsOnMobile(),
  gameSessionId: makeSelectSession(),
  leftMenuState: makeSelectLeftMenuState(),
  activeGameId: makeSelectActiveGameId(),
  libraryCards: makeSelectCards(),
  canContinue: makeSelectCanContinue(),
  highlightTip: makeSelectHighlightTip(),
  fiftyFiftyTip: makeSelectFiftyFiftyTip(),
  saveTip: makeSelectSaveTip(),
  superGame: makeSelectSuperGame(),
  profile: makeSelectProfile(),
  cardKeyPrice: makeSelectCardKeyProductPrice(),
  stage: makeSelectStage(),
  stagesInfo: makeSelectStagesInfo(),
  isFirstGame: makeSelectIsFirstGame(),
  isFirstGameTourWasShown: makeSelectIsFirstGameTourWasShown(),
  isGameInitialized: makeSelectIsGameInitialized(),
  lastBuyCard: makeSelectLastBuyCard(),
  browser: makeSelectBrowser(),
  rightGameMenuIsOpened: makeSelectRightGameMenuIsOpened(),
  finalCupImage: makeSelectFinalCupImage(),
  finalCupImageTexts: makeSelectFinalCupImageTexts(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withAllGamesProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

export default compose(
  withCookies,
  withRouter,
  withConnect,
  withAllGamesProps,
)(GameContent);
