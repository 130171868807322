import isNull from 'lodash/isNull';
import { connect } from 'react-redux';
import withProps from 'recompose/withProps';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import compose from 'recompose/compose';

import { createStructuredSelector } from 'reselect';
import {
  makeSelectFinalCupImage,
  makeSelectImage,
  makeSelectQuestion,
} from '../../../../../redux/modules/myGame/selectors';

import FinishCupImage from '../FinishCupImage';
import Image from '../Image';
import LifeBoy from '../LifeBoy';
import SimpleQuestion from '../SimpleQuestion';
import BuyGameQuestion from '../BuyGameQuestion';

const mapStateToProps = createStructuredSelector({
  question: makeSelectQuestion(),
  image: makeSelectImage(),
  finalCupImage: makeSelectFinalCupImage(),
});

const withConnect = connect(mapStateToProps, null);

const SIMPLE_QUESTION_ACTION_TYPE = 'ActionScreen/SIMPLE_QUESTION_ACTION_TYPE';
const IMAGE_ACTION_TYPE = 'ActionScreen/IMAGE_ACTION_TYPE';
const FINAL_CUP_IMAGE_ACTION_TYPE = 'ActionScreen/FINAL_CUP_IMAGE_ACTION_TYPE';
const BUY_GAME_QUESTION_ACTION_TYPE = 'ActionScreen/BUY_GAME_QUESTION_ACTION_TYPE';
const DEMO_LIFEBOY_ACTION_TYPE = 'ActionScreen/DEMO_LIFEBOY_ACTION_TYPE';
const UNKNOWN_ACTION_TYPE = 'ActionScreen/UNKNOWN_ACTION_TYPE';

const getActionType = ({ question, image, finalCupImage }) => {
  if (!isNull(question) && isNull(image) && isNull(finalCupImage)) {
    if (question.indexOf('purchase_window') !== -1) {
      return BUY_GAME_QUESTION_ACTION_TYPE;
    }

    if (question.toLowerCase().indexOf('lifebuoy_window') !== -1) {
      return DEMO_LIFEBOY_ACTION_TYPE;
    }

    return SIMPLE_QUESTION_ACTION_TYPE;
  }

  if (!isNull(image)) {
    return IMAGE_ACTION_TYPE;
  }

  if (!isNull(finalCupImage)) {
    return FINAL_CUP_IMAGE_ACTION_TYPE;
  }

  return UNKNOWN_ACTION_TYPE;
};

const withOwnProps = withProps((props) => {
  const actionType = getActionType(props);

  return {
    actionType,
  };
});

const renderSimpleQuestion = actionTypeMatch => branch(
  actionTypeMatch,
  renderComponent(SimpleQuestion),
);

const renderImage = actionTypeMatch => branch(
  actionTypeMatch,
  renderComponent(Image),
);

const renderFinishCupImage = actionTypeMatch => branch(
  actionTypeMatch,
  renderComponent(FinishCupImage),
);

const renderBuyGameQuestion = actionTypeMatch => branch(
  actionTypeMatch,
  renderComponent(BuyGameQuestion),
);

const renderLifeBoyQuestion = actionTypeMatch => branch(
  actionTypeMatch,
  renderComponent(LifeBoy),
);

const enhance = compose(
  withConnect,
  withOwnProps,
  renderSimpleQuestion(props => props.actionType === SIMPLE_QUESTION_ACTION_TYPE),
  renderImage(props => props.actionType === IMAGE_ACTION_TYPE),
  renderFinishCupImage(props => props.actionType === FINAL_CUP_IMAGE_ACTION_TYPE),
  renderBuyGameQuestion(props => props.actionType === BUY_GAME_QUESTION_ACTION_TYPE),
  renderLifeBoyQuestion(props => props.actionType === DEMO_LIFEBOY_ACTION_TYPE),
);

export default enhance;
