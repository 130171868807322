import React from 'react';
import { Button } from '../../../components/Popup';
import Input from '../../../components/Input';
import Form from '../templates/SingleForm/Form';
import FormLine from '../templates/SingleForm/FormLine';
import Control from '../templates/SingleForm/Control';
import Label from '../templates/SingleForm/Label';

const AccountDetailsForm = () => (
  <Form>
    <FormLine>
      <Label htmlFor="name">Наименование</Label>
      <Control>
        <Input type="text" id="name" />
      </Control>
    </FormLine>
    <FormLine>
      <Label htmlFor="inn">ИНН</Label>
      <Control>
        <Input type="text" id="inn" />
      </Control>
    </FormLine>
    <FormLine>
      <Label htmlFor="kpp">КПП</Label>
      <Control>
        <Input type="text" id="kpp" />
      </Control>
    </FormLine>
    <FormLine>
      <Label htmlFor="ogrn">ОГРН</Label>
      <Control>
        <Input type="text" id="ogrn" />
      </Control>
    </FormLine>
    <FormLine>
      <Label htmlFor="bic">БИК</Label>
      <Control>
        <Input type="text" id="bic" />
      </Control>
    </FormLine>
    <FormLine>
      <Label htmlFor="ks">К/С</Label>
      <Control>
        <Input type="text" id="ks" />
      </Control>
    </FormLine>
    <FormLine>
      <Label htmlFor="rs">Р/С</Label>
      <Control>
        <Input type="text" id="rs" />
      </Control>
    </FormLine>
    <FormLine>
      <Label />
      <Control>
        <Button allWidth>Сохранить</Button>
      </Control>
    </FormLine>
  </Form>
);

export default AccountDetailsForm;
