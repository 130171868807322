import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import {
  call, select, put, takeEvery, all, delay,
} from 'redux-saga/effects';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import { apiRequest as createApiRequest } from '../../../utils/request';
import { makeSelectActiveGameId } from '../../modules/activeGame/selectors';
import {
  webteamRegistrationEvent,
  webteamStartGameEvent,
  webteamTrialGameCompletedEvent,
} from '../../modules/analytics/actions';
import {
  TRACK_BUY_GAME_BUTTON_CLICKED,
  TRACK_BUY_GAME_FAIL,
  TRACK_OPEN_LIBRARY_IN_GAME_OVERVIEW,
  TRACK_OPEN_LIBRARY_IN_GAME,
  WEBTEAM_REGISTRATION,
  WEBTEAM_START_GAME,
  WEBTEAM_TRIAL_COMPLETED,
  TRACK_START_DEMO_BUTTON_CLICK,
  TRACK_CLICK_GET_FULL_ACCESS_LINK,
  TRACK_VIDEO_CLICK,
  TRACK_I_AM_INTERESTED_BUTTON_CLICK,
  TRACK_REDIRECT_USER_TO_PAYMENT_GATE,
  TRACK_ERROR_WHEN_BUY_GAME_AS_GUEST,
  TRACK_DEMO_COMEBACK_FORM_SUBMIT,
} from '../../modules/analytics/constants';
import { makeSelectGames, makeSelectGamesShowcases, makeSelectShowcaseList } from '../../modules/games/selectors';
import { makeSelectGamesOptions } from '../../modules/gamesOptions/selectors';
import { logout } from '../../modules/global/actions';
import {
  SET_ANALYTIC_PARAM_FOR_BACKEND,
  START_TRIAL_GAME_SUCCESS,
} from '../../modules/global/constants';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import { GET_FINISH_VARS_REQUEST, NEW_GAME_REQUEST_SUCCESS } from '../../modules/myGame/constants';
import {
  makeSelectSession,
  makeSelectStavka,
  makeSelectSuperGame,
} from '../../modules/myGame/selectors';
import { makeSelectProfile } from '../../modules/profile/selectors';

import { FINISH_TUTORIAL } from '../../modules/tutorial/constants';
import { setStartTutorialTime, setFinishTutorialTime } from '../../modules/tutorial/actions';
import { makeSelectTutorialFinishTime, makeSelectTutorialStartTime } from '../../modules/tutorial/selectors';

function build({ amplitude, cookies }) {
  const apiRequest = createApiRequest(cookies, false);

  function* trackTutorialTimeSaga(action) {
    const startTime = yield select(makeSelectTutorialStartTime());
    const finishTime = yield select(makeSelectTutorialFinishTime());

    if (!startTime || !finishTime) {
      return;
    }

    const timeInTutorial = finishTime - startTime;

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const booster = yield select(makeSelectStavka());
    const isSuperGame = yield select(makeSelectSuperGame());

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;
    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }
    const { payload: { stepGroupNumber } } = action;

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        timeInTutorial,
        gameId,
        gameName,
        booster,
        isTrialGame,
        stepGroupNumber,
        isSuperGame: isSuperGame.isEnabled,
        userId,
        isEmployee,
        userEmail,
        employeeId,
        employeeEmail,
        ...company,
      };

      yield call(amplitude.trackTutorialIsFinished, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    } finally {
      yield put(setStartTutorialTime(null));
      yield put(setFinishTutorialTime(null));
    }
  }

  function* trackBuyGameButtonClickedSaga(action) {
    const { payload: { showcaseId, where, inGame } } = action;

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const showcaseList = yield select(makeSelectShowcaseList());

    const showcaseInfo = find(showcaseList, { ShowcaseID: showcaseId }, false);
    const price = get(showcaseInfo, 'Price', 0);
    const currencyId = get(showcaseInfo, 'CurrencyID', null);
    const currencyName = get(showcaseInfo, 'CurrencyName', null);

    let gameProps = {
      booster: null,
      superGame: null,
      isSupergame: null,
    };

    if (inGame) {
      const booster = yield select(makeSelectStavka());
      const superGame = yield select(makeSelectSuperGame());
      const isSupergame = superGame.isEnabled;

      gameProps = {
        booster,
        superGame,
        isSupergame,
      };
    }

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        gameId,
        gameName,
        where,
        showcaseId,
        price,
        userId,
        isEmployee,
        currencyId,
        currencyName,
        isTrialGame,
        userEmail,
        employeeId,
        employeeEmail,
        ...gameProps,
        ...company,
      };

      yield call(amplitude.trackBuyGameButtonClick, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackClickGetFullAccessLink() {
    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSupergame = superGame.isEnabled;

    const gameProps = {
      booster,
      superGame,
      isSupergame,
    };

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        userId,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        isEmployee,
        userEmail,
        employeeEmail,
        ...gameProps,
        ...company,
      };

      yield call(amplitude.trackClickGetFullAccessLink, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackBuyGameFail() {
    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    try {
      const data = {
        userId,
        isEmployee,
        employeeId,
        userEmail,
        employeeEmail,
        ...company,
      };

      yield call(amplitude.trackBuyGameFail, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackVideoClick(action) {
    const { payload: { where } } = action;

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSupergame = superGame.isEnabled;

    const gameProps = {
      booster,
      superGame,
      isSupergame,
    };

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        userId,
        where,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        isEmployee,
        userEmail,
        employeeEmail,
        ...gameProps,
        ...company,
      };

      yield call(amplitude.trackVideoClick, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackIAmInterestedButtonClick(action) {
    // const { payload: { where } } = action;
    const where = null;

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSupergame = superGame.isEnabled;

    const gameProps = {
      booster,
      superGame,
      isSupergame,
    };

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        userId,
        where,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        isEmployee,
        userEmail,
        employeeEmail,
        ...gameProps,
        ...company,
      };

      yield call(amplitude.trackIAmInterestedButtonClick, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackStartNewGame() {
    yield delay(500);
    const profile = yield select(makeSelectProfile());

    const gameSessionId = yield select(makeSelectSession());
    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const company = {
      companyId: null,
      companyName: null,
    };

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());
    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSupergame = superGame.isEnabled;

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    const url = yield select(makeSelectApiUrl());

    const gameLaunchCount = 0;
    let firstGameReadom = null;
    try {
      const requestUrlVars = `${url}/VariableGetAllForGame2User/${encodeURIComponent(gameId)}`;

      const [SingleVar] = yield all([
        call(apiRequest, requestUrlVars),
      ]);

      const nameOfFirstGameInReadomForUser = get(SingleVar, 'data.result[0].Value');
      if (nameOfFirstGameInReadomForUser) {
        firstGameReadom = nameOfFirstGameInReadomForUser;
      }
    } catch (err) {
    }

    try {
      const data = {
        userId,
        isEmployee,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        booster,
        isSupergame,
        userEmail,
        employeeEmail,
        gameLaunchCount,
        firstGameReadom,
        gameSessionId,
        ...company,
      };

      yield call(amplitude.trackStartNewGame, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackOpenLibraryPopupInOverview(action) {
    const { payload: { cardId } } = action;
    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const company = {
      companyId: null,
      companyName: null,
    };

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());
    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        cardId,
        userEmail,
        employeeEmail,
        userId,
        isEmployee,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        ...company,
      };

      yield call(amplitude.trackOpenLibraryPopupInOverview, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackStartDemoButtonClick(action) {
    const { payload: { place } } = action;

    try {
      const data = {
        place,
      };

      yield call(amplitude.trackStartDemoButtonClick, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackOpenLibraryPopupInGame() {
    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const company = {
      companyId: null,
      companyName: null,
    };

    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());
    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSuperGame = superGame.isEnabled;

    try {
      const data = {
        userId,
        userEmail,
        employeeEmail,
        isEmployee,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        isSuperGame,
        booster,
        ...company,
      };

      yield call(amplitude.trackOpenLibraryPopupInGame, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* webteamRegistrationSaga() {
    const clickId = cookies.get('clickId');

    if (!clickId) {
      return;
    }

    yield put(webteamRegistrationEvent(clickId));
    yield put(webteamStartGameEvent(clickId));
  }

  function* webteamFinishGameSaga() {
    const clickId = cookies.get('clickId');

    if (!clickId) {
      return;
    }

    yield put(webteamTrialGameCompletedEvent(clickId));
  }

  function* trackForWebteam(action) {
    const { payload: { clickId } } = action;

    try {
      let url = '';
      switch (action.type) {
        case WEBTEAM_REGISTRATION:
          url = `https://ewttrack.com/gback_mail.php?cnv_id=${clickId}&action=registration`;
          yield call(apiRequest, url);
          break;
        case WEBTEAM_START_GAME:
          url = `https://ewttrack.com/gback_mail.php?cnv_id=${clickId}&action=start`;
          yield call(apiRequest, url);
          break;
        case WEBTEAM_TRIAL_COMPLETED:
          url = `https://ewttrack.com/gback_mail.php?cnv_id=${clickId}&action=trial`;
          yield call(apiRequest, url);
          break;
        default:
          console.error('Неизвестное событие');
      }
    } catch (e) {
      console.error('webteam event track error', e);
    }
  }

  function* sendSingleAnalyticParamForBackend({ key, value }) {
    const url = yield select(makeSelectApiUrl());

    const requestURL = `${url}/SetAnalyticParam/${encodeURIComponent(key)}/${encodeURIComponent(value)}`;

    try {
      yield call(apiRequest, requestURL);
    } catch (err) {
    }
  }

  function* isLoginedSaga() {
    const url = yield select(makeSelectApiUrl());

    const requestIsLoginedURL = `${url}/IsLogined`;

    try {
      const result = yield call(apiRequest, requestIsLoginedURL);
      if (!result) {
        return;
      }

      const isLogined = get(result, 'data.result[0]');
      const isAuthenticated = isUserAuthenticated(cookies);

      if (!isLogined && isAuthenticated) {
        yield put(logout());
        return;
      }

      const guestSessionID = cookies.get('guestSessionID');

      if (!isAuthenticated && !guestSessionID) {
        const sessionID = result.headers.pragma;

        let sessIndx = sessionID.indexOf('dssession=');
        if (sessIndx > -1) {
          let commaIndx = sessionID.indexOf(',', sessIndx);
          commaIndx = commaIndx < 0 ? sessionID.length : commaIndx;
          sessIndx += 10;
          const sessionId = sessionID.substr(sessIndx, (commaIndx - sessIndx));

          let expiresIndx = sessionID.indexOf('dssessionexpires=');
          if (expiresIndx > -1) {
            commaIndx = sessionID.indexOf(',', expiresIndx);
            commaIndx = commaIndx < 0 ? sessionID.length : commaIndx;
            expiresIndx += 17;

            const cookieMaxAge = (parseInt(sessionID.substr(expiresIndx, (commaIndx - expiresIndx)), 10) / 1000);
            cookies.set('guestSessionID', sessionId, { maxAge: cookieMaxAge, path: '/' });
            cookies.set('guestSessionExpires', cookieMaxAge, { path: '/' });
          }
        }
      }

      if (isAuthenticated) {
        cookies.remove('guestSessionID', { path: '/' });
        cookies.remove('guestSessionExpires', { path: '/' });
      }
    } catch (err) {
    }
  }

  function* setAnalyticParamForBackendSaga(action) {
    const { payload: values } = action;

    if (!values) {
      return;
    }

    // Нужно чтобы получить id сессии и уже в ней асинхронно вызвать все запросы на установку параметров аналитики
    yield call(isLoginedSaga);

    yield all(map(values, ((value, key) => call(sendSingleAnalyticParamForBackend, { value, key }))));
  }

  function* trackRedirectUserToPaymentGate() {
    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSupergame = superGame.isEnabled;

    const gameProps = {
      booster,
      superGame,
      isSupergame,
    };

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        userId,
        employeeId,
        gameId,
        gameName,
        isTrialGame,
        isEmployee,
        userEmail,
        employeeEmail,
        ...gameProps,
        ...company,
      };

      yield call(amplitude.trackRedirectUserToPaymentGate, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackErrorWhenBuyGameAsGuest(action) {
    const { payload: { errorMessage } } = action;
    const gameId = yield select(makeSelectActiveGameId());
    const gameList = yield select(makeSelectGames());

    const currentGameInfo = find(gameList, { GameID: gameId }, false);
    const gameName = get(currentGameInfo, 'Name', null);

    const profile = yield select(makeSelectProfile());

    const isEmployee = profile.isEmployer;
    const userId = profile.userID;
    const userEmail = profile.userEmail;
    const employeeId = isEmployee ? profile.employeeID : null;
    const employeeEmail = isEmployee ? profile.employeeEmail : null;

    const booster = yield select(makeSelectStavka());
    const superGame = yield select(makeSelectSuperGame());
    const isSupergame = superGame.isEnabled;

    const gameProps = {
      booster,
      superGame,
      isSupergame,
    };

    const company = {
      companyId: null,
      companyName: null,
    };

    if (isEmployee) {
      company.companyName = profile.company;
      company.companyId = profile.companyId;
    }

    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    try {
      const data = {
        userId,
        employeeId,
        errorMessage,
        gameId,
        gameName,
        isTrialGame,
        isEmployee,
        userEmail,
        employeeEmail,
        ...gameProps,
        ...company,
      };

      yield call(amplitude.trackErrorWhenBuyGameAsGuest, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* trackDemoComebackFormSubmitSaga(action) {
    const { email } = action;

    try {
      const data = {
        inputEmail: email,
      };

      yield call(amplitude.trackDemoComebackFormSubmit, data);
    } catch (e) {
      console.error('Ошибка сбора аналитики.');
    }
  }

  function* sagas() {
    yield takeEvery(FINISH_TUTORIAL, trackTutorialTimeSaga);
    yield takeEvery(NEW_GAME_REQUEST_SUCCESS, trackStartNewGame);
    // yield takeEvery(START_TRIAL_GAME_SUCCESS, webteamRegistrationSaga);
    // yield takeEvery(GET_FINISH_VARS_REQUEST, webteamFinishGameSaga);
    yield takeEvery(TRACK_BUY_GAME_FAIL, trackBuyGameFail);
    yield takeEvery(TRACK_OPEN_LIBRARY_IN_GAME_OVERVIEW, trackOpenLibraryPopupInOverview);
    yield takeEvery(TRACK_OPEN_LIBRARY_IN_GAME, trackOpenLibraryPopupInGame);
    // yield takeEvery(WEBTEAM_REGISTRATION, trackForWebteam);
    // yield takeEvery(WEBTEAM_START_GAME, trackForWebteam);
    // yield takeEvery(WEBTEAM_TRIAL_COMPLETED, trackForWebteam);
    yield takeEvery(TRACK_VIDEO_CLICK, trackVideoClick);
    yield takeEvery(TRACK_I_AM_INTERESTED_BUTTON_CLICK, trackIAmInterestedButtonClick);
    yield takeEvery(TRACK_START_DEMO_BUTTON_CLICK, trackStartDemoButtonClick);
    yield takeEvery(TRACK_CLICK_GET_FULL_ACCESS_LINK, trackClickGetFullAccessLink);

    yield takeEvery(TRACK_REDIRECT_USER_TO_PAYMENT_GATE, trackRedirectUserToPaymentGate);
    yield takeEvery(TRACK_ERROR_WHEN_BUY_GAME_AS_GUEST, trackErrorWhenBuyGameAsGuest);

    yield takeEvery(TRACK_BUY_GAME_BUTTON_CLICKED, trackBuyGameButtonClickedSaga);
    yield takeEvery(SET_ANALYTIC_PARAM_FOR_BACKEND, setAnalyticParamForBackendSaga);
    yield takeEvery(TRACK_DEMO_COMEBACK_FORM_SUBMIT, trackDemoComebackFormSubmitSaga);
  }

  return sagas;
}

export default build;
