import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import { getExtendedEmployeeGameRating } from '../../redux/modules/rating/actions';
import {
  makeSelectActiveRatingTab,
  makeSelectExtendedEmployeeRatingForActiveGame,
} from '../../redux/modules/rating/selectors';

const mapStateToProps = createStructuredSelector({
  extendedEmployeeRating: makeSelectExtendedEmployeeRatingForActiveGame(),
});

const mapDispatchToProps = dispatch => ({
  loadDetailRatingForEmployee: ({ employeeId, gameId }) => dispatch(getExtendedEmployeeGameRating({
    employeeId,
    gameId,
  })),
});

const mapStateToProps2 = createStructuredSelector({
  activeGameId: makeSelectActiveRatingTab(),
});

const withConnect2 = connect(mapStateToProps2);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withActiveGameProps = withProps((props) => {
  const { activeGameId } = props;

  return {
    activeGameId,
  };
});

const withOwnProps = withProps((props) => {
  const { data, fields, pending } = props.extendedEmployeeRating;

  return {
    ratingForOneGameEmployee: data,
    fields,
    isLoadingEmployeeRating: pending,
  };
});

const enhance = compose(
  withConnect2,
  withActiveGameProps,
  withConnect,
  lifecycle({
    componentDidMount() {
      const { loadDetailRatingForEmployee, employeeId, activeGameId } = this.props;
      loadDetailRatingForEmployee({ employeeId, gameId: activeGameId });
    },
  }),
  withOwnProps,
);

export default enhance;
