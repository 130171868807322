import { fromJS } from 'immutable';

import {
  SET_NETWORK_OFFLINE,
  SET_REQUEST_FAIL,
} from './constants';

const initialState = fromJS({
  networkOffline: false,
  requestFail: false,
});

function errorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NETWORK_OFFLINE:
      return state
        .set('networkOffline', action.isOffline);
    case SET_REQUEST_FAIL:
      return state
        .set('requestFail', action.isRequestFail);
    default:
      return state;
  }
}

export default errorReducer;
