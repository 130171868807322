import IndexPage from '../pages/IndexPage';

import LoginPage from '../pages/LoginPage';
import LicencePage from '../pages/Info/LicencePage';
import InviteSetPasswordPage from '../pages/InviteSetPasswordPage';
import EmployeeSetUserEmailPage from '../pages/EmployeeSetUserEmailPage';
import ResetPage from '../pages/ResetPage';
import RestorePasswordPage from '../pages/RestorePasswordPage';
import OrganizationPage from '../pages/Info/OrganizationPage';
import PolicyPage from '../pages/Info/PolicyPage';
import ProcessingPersonalDataPage from '../pages/Info/ProcessingPersonalDataPage';
import RegistrationPage from '../pages/RegistrationPage';
import UserActivationPage from '../pages/UserActivationPage';
import SuccessPaymentPage from '../pages/SuccessPaymentPage';

import ProfilePage from '../pages/HomePage';
import PaymentMethodsPage from '../pages/PaymentMethodsPage';
import PaymentPage from '../pages/PaymentPage';
import ChangeAccountDetailsPage from '../pages/ChangeAccountDetailsPage';
import GameOverviewPage from '../pages/GameOverviewPage';
import GamePage from '../pages/GamePage';
import GameFinishPage from '../pages/SuccessPage';
import RatingCompanyPage from '../pages/RatingCompanyPage';
import RatingAllPage from '../pages/RatingAllPage';
import AboutPage from '../pages/Info/AboutPage';
import EmployeesPage from '../pages/EmployeesPage';
import GamesPage from '../pages/GamesPage';
import PurchaseTermsPage from '../pages/Info/PurchaseTermsPage';
import StartTrialGamePage from '../pages/StartTrialGamePage';
import FailPaymentPage from '../pages/FailPaymentPage';
import GetFullAccessPage from '../pages/GetFullAccessPage';
import DemoFinishPage from '../pages/DemoFinishPage';

export default {
  IndexPage,
  LoginPage,
  LicencePage,
  InviteSetPasswordPage,
  EmployeeSetUserEmailPage,
  ResetPage,
  RestorePasswordPage,
  OrganizationPage,
  PolicyPage,
  ProcessingPersonalDataPage,
  RegistrationPage,
  UserActivationPage,
  SuccessPaymentPage,
  FailPaymentPage,
  ProfilePage,
  PaymentMethodsPage,
  PaymentPage,
  ChangeAccountDetailsPage,
  GameOverviewPage,
  GamePage,
  GameFinishPage,
  RatingCompanyPage,
  RatingAllPage,
  AboutPage,
  EmployeesPage,
  GamesPage,
  PurchaseTermsPage,
  StartTrialGamePage,
  GetFullAccessPage,
  DemoFinishPage,
};
