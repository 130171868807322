import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setOwlInCenter } from '../../../../redux/modules/owl/actions';
import { makeSelectOwlInCenter, makeSelectOwlVisible } from '../../../../redux/modules/owl/selectors';

const mapDispatchToProps = dispatch => ({
  setOwlInCenter: owlInCenter => dispatch(setOwlInCenter(owlInCenter)),
});

const mapStateToProps = createStructuredSelector({
  owlInCenter: makeSelectOwlInCenter(),
  isVisible: makeSelectOwlVisible(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
);

export default enhance;
