import each from 'lodash/each';
import amplitude from 'amplitude-js/amplitude';
import Cookies from 'universal-cookie';
import getUTMQueryParams from '../getUTMQueryParams';
import getAppVersion from '../getAppVersion';
import getDeviceId from '../getDeviceId';

const cookies = new Cookies();

const TOKEN = process.env.REACT_APP_AMPLITUDE_TOKEN;

const logAndTrack = (logger, ampInstance) => ({
  track: (events) => {
    each(events, (event) => {
      try {
        if (event.user_id) {
          ampInstance.setUserId(event.user_id);
        }

        if (event.app_version) {
          ampInstance.setVersionName(event.app_version);
        }

        if (event.device_id) {
          ampInstance.setDeviceId(event.device_id);
        }

        if (event.user_properties) {
          ampInstance.setUserProperties(event.user_properties);
        }

        if (event.event_type && event.event_properties) {
          ampInstance.logEvent(event.event_type, event.event_properties);
        }

        if (event.event_type && !event.event_properties) {
          ampInstance.logEvent(event.event_type);
        }
      } catch (e) {
        console.error('Error while send amplitude data');
        console.log('error', e);
      }
    });
    logger.track(events);
  },
});

// eslint-disable-next-line no-shadow
const getCommonFields = cookies => ({
  device_id: getDeviceId(cookies),
  app_version: getAppVersion(),
});

class Amplitude {
  constructor(cookiesInstance) {
    this.cookies = cookiesInstance;
    this.client = null;

    let ampInstance = null;
    amplitude.getInstance().init(TOKEN, null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      deviceId: getDeviceId(cookiesInstance),
    }, (client) => {
      ampInstance = client;
      this.client = client;

      // Populate the object to be sent to Amplitude
      const utmParams = getUTMQueryParams(document.location.search);
      const propertiesToReport = {};
      for (const param in utmParams) {
        propertiesToReport[`utm_${param}`] = utmParams[param];
      }

      if (Object.getOwnPropertyNames(propertiesToReport).length !== 0) {
        client.setUserProperties(propertiesToReport);
      }

      client.logEvent('visit', {
        landing_url: window.location.href,
        landing_path: window.location.origin + window.location.pathname,
        ...propertiesToReport,
      });

      this.instance = logAndTrack(this.justLogEvent, ampInstance);
    });
  }

  getParamsForBackend = () => {
    const amplitudeSessionId = this.client.getSessionId();

    return {
      session_id: amplitudeSessionId,
      device_id: getDeviceId(cookies),
    };
  };

  justLogEvent = {
    track: (event) => {
      console.groupCollapsed(`Track event ${event[0].event_type}`);
      each(event, (item, index) => {
        console.info(`event[${index}]`, item);
      });
      console.groupEnd();
    },
  };

  trackStartDemoButtonClick = ({ place }) => {
    const event = [
      {
        event_type: 'start_trial_button_click',
        event_properties: {
          place,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackTutorialIsFinished = (
    {
      timeInTutorial,
      gameId,
      gameName,
      booster,
      isSuperGame,
      isTrialGame,
      userId,
      isEmployee,
      companyId,
      companyName,
      stepGroupNumber,
      employeeId,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'tutorial_step_finish',
        user_id: userId,
        event_properties: {
          time_in_tutorial: timeInTutorial,
          game_id: gameId,
          game_name: gameName,
          booster,
          step_group_number: stepGroupNumber,
          is_trial_game: isTrialGame ? 'isTrialGame' : 'isNotTrialGame',
          is_super_game: isSuperGame ? 'isSuperGame' : 'isNotSuperGame',
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackBuyGameFail = (
    {
      userId,
      isEmployee,
      companyId,
      companyName,
      employeeId,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'buy_game_fail',
        user_id: userId,
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackStartNewGame = (
    {
      userId,
      employeeId,
      gameId,
      gameName,
      isTrialGame,
      booster,
      isSupergame,
      isEmployee,
      companyId,
      companyName,
      userEmail,
      employeeEmail,
      gameLaunchCount,
      firstGameReadom,
      gameSessionId,
    },
  ) => {
    const event = [
      {
        event_type: 'new_game_start',
        user_id: userId,
        event_properties: {
          game_id: gameId,
          game_name: gameName,
          booster,
          game_session_id: gameSessionId,
          is_trial_game: isTrialGame ? 'isTrialGame' : 'isNotTrialGame',
          is_super_game: isSupergame ? 'isSuperGame' : 'isNotSuperGame',
          full_game_launch_count: gameLaunchCount,
          first_game_readom: firstGameReadom,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackOpenLibraryPopupInOverview = (
    {
      userId,
      employeeId,
      gameId,
      gameName,
      isTrialGame,
      isEmployee,
      companyId,
      companyName,
      cardId,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'open_library_in_overview',
        user_id: userId,
        event_properties: {
          game_id: gameId,
          game_name: gameName,
          is_trial_game: isTrialGame ? 'isTrialGame' : 'isNotTrialGame',
          card_id: cardId,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackOpenLibraryPopupInGame = (
    {
      userId,
      employeeId,
      gameId,
      gameName,
      isTrialGame,
      isEmployee,
      companyId,
      companyName,
      booster,
      isSuperGame,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'open_library_in_game',
        user_id: userId,
        event_properties: {
          game_id: gameId,
          game_name: gameName,
          is_trial_game: isTrialGame ? 'isTrialGame' : 'isNotTrialGame',
          is_super_game: isSuperGame ? 'isSuperGame' : 'isNotSuperGame',
          booster,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackClickGetFullAccessLink = (
    {
      userId,
      employeeId,
      gameId,
      gameName,
      isTrialGame,
      isEmployee,
      companyId,
      companyName,
      booster,
      isSuperGame,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'click_get_full_access_link',
        user_id: userId,
        event_properties: {
          game_id: gameId,
          game_name: gameName,
          is_trial_game: isTrialGame ? 'isTrialGame' : 'isNotTrialGame',
          is_super_game: isSuperGame ? 'isSuperGame' : 'isNotSuperGame',
          booster,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackVideoClick = (
    {
      userId,
      employeeId,
      isEmployee,
      companyId,
      companyName,
      userEmail,
      employeeEmail,
      where,
    },
  ) => {
    const event = [
      {
        event_type: 'landing_V4_play_video',
        user_id: userId,
        event_properties: {
          where,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackIAmInterestedButtonClick = (
    {
      userId,
      employeeId,
      isEmployee,
      companyId,
      companyName,
      userEmail,
      employeeEmail,
      where,
    },
  ) => {
    const event = [
      {
        event_type: 'i_am_interested_button_click',
        user_id: userId,
        event_properties: {
          where,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackBuyGameButtonClick = (
    {
      where,
      userId,
      employeeId,
      isEmployee,
      companyId,
      companyName,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'click_button_buy_game',
        user_id: userId,
        event_properties: {
          where,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackRedirectUserToPaymentGate = (
    {
      userId,
      employeeId,
      isEmployee,
      companyId,
      companyName,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'redirect_user_to_payment_game',
        user_id: userId,
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackErrorWhenBuyGameAsGuest = (
    {
      errorMessage,
      userId,
      employeeId,
      isEmployee,
      companyId,
      companyName,
      userEmail,
      employeeEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'error_when_buy_game_as_guest',
        user_id: userId,
        event_properties: {
          errorMessage,
        },
        user_properties: {
          employee_id: employeeId,
          b_type: isEmployee ? 'b2b' : 'b2c',
          company_id: companyId,
          company_name: companyName,
          user_email: userEmail,
          employee_email: employeeEmail,
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };

  trackDemoComebackFormSubmit = (
    {
      inputEmail,
    },
  ) => {
    const event = [
      {
        event_type: 'demo_comeback_form_submit',
        event_properties: {
          landing_url: window.location.href,
          landing_path: window.location.origin + window.location.pathname,
          input_email: inputEmail,
          text: 'Получите в подарок бесплатный фрагмент книги из библиотеки крупных продаж',
        },
        ...getCommonFields(this.cookies),
      },
    ];

    this.instance.track(event);
  };
}

export default Amplitude;
