import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';

import './style.scss';
import './less-1200-style.scss';
import './less-768-style.scss';
import './less-600-style.scss';
import Logo from '../Logo';
import StartTrialGameWithEmailForm from '../StartTrialGameWithEmailForm';
import Video from './includes/Video';
import URL_CONSTANTS from '../../urlConstants';

const Slider = ({ children }) => {
  const params = {
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
  };

  return (
    <Swiper {...params}>
      {React.Children.toArray(children)}
    </Swiper>
  );
};

const PromoPageV2 = (
  {
    onDemoStart, isFinishPage, isGetFullAccessPage,
    trackBuyGameButtonClicked, trackStartDemoButtonClick,
    setBuyGameGuestPopupOpened, onDemoStartWithEmail, startTrialWithEmail,
  },
) => {
  let where = 'promo-page';

  if (isFinishPage) {
    where = 'demo-game-finish';
  }

  if (isGetFullAccessPage) {
    where = 'get-full-access-page';
  }

  const onDemoAccessClickTop = () => {
    trackStartDemoButtonClick('top');
    if (startTrialWithEmail) {
      onDemoStartWithEmail();
    } else {
      onDemoStart();
    }
  };

  const onDemoAccessClickBottom = () => {
    trackStartDemoButtonClick('bottom');
    if (startTrialWithEmail) {
      onDemoStartWithEmail();
    } else {
      onDemoStart();
    }
  };

  const onBuyButtonClick = () => {
    trackBuyGameButtonClicked('million-trial', where, false);
    setBuyGameGuestPopupOpened(true);
  };

  const onFullVersionClick = () => {
    onBuyButtonClick();
  };

  const isFinal = isFinishPage || isGetFullAccessPage;

  return (
    <div id="promo_v2">
      <div className="promo">
        <div className="promo__container">
          <div className=" promo__header__fixed">
            <div className="promo__header">
              <div className="promo__header__logo">
                <Logo />
              </div>
              {isGetFullAccessPage && (
                <Link
                  className="promo__header__login promo__header__login--120"
                  to={`${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/million`}
                >
                  вернутся в демо
                </Link>
              )}
              {!isGetFullAccessPage && (
                <Link
                  className="promo__header__login promo__header__login--120"
                  to={`${URL_CONSTANTS.URLS.LOGIN}`}
                >
                  войти
                </Link>
              )}
            </div>
          </div>
          <div className="promo__video">
            <Video where={where} />
          </div>
          <section className="promo__section1">
            <h1>Миллионы&nbsp;в&nbsp;твоём&nbsp;кармане</h1>
            <h4>уникальный тренажер продаж</h4>
            <h3>
              игра, которая поможет освоить
              <br />
              полную технологию крупных продаж
            </h3>
          </section>

          <div className="other-section">
            {isFinal && (
              <section className="promo__section100">
                <button type="button" className="promo__button promo__button--green" onClick={onFullVersionClick}>
                  ПОЛУЧИТЬ ПОЛНУЮ ВЕРСИЮ
                </button>
              </section>
            )}

            {!isFinal && (
              <section className="promo__section2">
                <div className="benefit1_cols benefit1_col--0">
                  <div className="promo__buttons">
                    <div className="promo__buttons__header">Начать сейчас</div>
                    {/*<button type="button" className="promo__button promo__button--green" onClick={onDemoAccessClickTop}>*/}
                      {/*ДЕМО ВЕРСИЯ*/}
                    {/*</button>*/}
                    {/*<div className="promo__buttons__or">или</div>*/}
                    <button type="button" className="promo__button promo__button--green" onClick={onFullVersionClick}>
                      ПОЛНАЯ ВЕРСИЯ
                    </button>
                  </div>
                </div>
                <div className="benefit1_cols benefit1_cols--2">
                  <div className="benefit1_col benefit1_col--1">
                    <div className="benefit1_col__image benefit1_col__image--1" />
                    <div className="benefit1_col__header">&gt; 15&nbsp;000 человек</div>
                    <div className="benefit1_col__text">
                      из&nbsp;разных странах освоили технологию крупных продаж через тренажер продаж.
                      Сейчас они продают как никогда больше.
                    </div>
                  </div>
                  <div className="benefit1_col benefit1_col--2">
                    <div className="benefit1_col__image benefit1_col__image--2" />
                    <div className="benefit1_col__header">100% эксклюзив</div>
                    <div className="benefit1_col__text">
                      Тренажер продаж полностью основан на&nbsp;реальном опыте
                      лучших в&nbsp;крупных продажах. Это уникальная возможность пройти
                      путь профессионального продавца от&nbsp;первой встречи
                      до&nbsp;подписания крупного контракта.
                    </div>
                  </div>
                  <div className="benefit1_col benefit1_col--3">
                    <div className="promo__buttons">
                      <div className="promo__buttons__header">Начать сейчас</div>
                      {/*<button*/}
                        {/*type="button"*/}
                        {/*className="promo__button promo__button--green"*/}
                        {/*onClick={onDemoAccessClickTop}*/}
                      {/*>*/}
                        {/*ДЕМО ВЕРСИЯ*/}
                      {/*</button>*/}
                      {/*<div className="promo__buttons__or">или</div>*/}
                      <button type="button" className="promo__button promo__button--green" onClick={onFullVersionClick}>
                        ПОЛНАЯ ВЕРСИЯ
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section
              className={classNames([
                'promo__section3',
                isFinal && 'isFinal',
              ])}
            >
              <h3>ДОСТИЖЕНИЯ И&nbsp;ОТЗЫВЫ НАШИХ КЛИЕНТОВ</h3>

              <div className="feedback_items">
                <Slider>
                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---1',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">Милихин Александр</div>
                          <div className="feedback_item__person__position">
                            Управляющий группы компаний&nbsp;
                            <a href="http://www.cpz-nk.ru/" target="_blank" rel="noreferrer noopener">cpz-nk.ru</a>
                          </div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        Игра отличная. Во-первых, сразу видны стратегии сотрудников. Кто-то упирается и&nbsp;шесть часов
                        долбит
                        пока не&nbsp;продаст. Кто-то на&nbsp;13&nbsp;мин. зашел и&nbsp;исчез. Все (включая меня) сначала
                        хотят
                        проскочить нахаляву и&nbsp;только после пары часов безуспешных нажиманий на&nbsp;кнопки
                        некоторые
                        начинают
                        читать теорию, некоторые до&nbsp;сих пор жмут на&nbsp;кнопки с&nbsp;тем&nbsp;же результатом.
                        Во-вторых,
                        отсутствие подсказок&nbsp;&mdash; это прекрасно. Потому что это заставляет включать мозг.
                        А&nbsp;раздражение от&nbsp;неправильных действий и&nbsp;бесконечного возврата на&nbsp;линию
                        старта
                        улучшает запоминание в&nbsp;разы. И&nbsp;в-третьих, у&nbsp;меня уже у&nbsp;коммерческого
                        директора
                        одной
                        из&nbsp;компаний случилась сделка только благодаря новому подходу. По&nbsp;её&nbsp;словам прямо
                        ломать
                        себя пришлось, чтобы не&nbsp;начать обсуждать цену. В&nbsp;итоге сделала все как с&nbsp;Дмитрием
                        Сергеичем
                        (по телефону) и&nbsp;клиент купил товар в&nbsp;два раза более дорогой, чем первоначально выбрал
                        на&nbsp;сайте. Теперь коммерческий мучается, потому что продавать по-новому непривычно&nbsp;и,
                        поэтому
                        трудно, а&nbsp;по&nbsp;старому уже не&nbsp;хочется. Почувствовала вкус этого подхода.
                      </div>
                    </div>
                  </div>

                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---2',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">Завалина Анна</div>
                          <div className="feedback_item__person__position">
                            Руководитель отдела продаж агентства недвижимости
                          </div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        Один из&nbsp;самых запоминающихся моментов игры, который помогает мне в&nbsp;работе, это блок
                        установления
                        контакта с&nbsp;клиентом. Я&nbsp;знаю точно, если доверия нет&nbsp;&mdash; сделка никогда
                        не&nbsp;состоится. С&nbsp;одной женщиной я&nbsp;очень долго не&nbsp;могла наладить контакт,
                        но&nbsp;после
                        прохождения игры, при очередной встрече с&nbsp;ней я&nbsp;применила новые инструменты:
                        комплимент
                        и&nbsp;техника малого разговора. И, о&nbsp;чудо! Наша дальнейшая работа сложилась как никогда
                        лучше...
                        Кроме этого, применяя теорию и&nbsp;практику, я&nbsp;стала руководителем отдела продаж
                        и&nbsp;вывела
                        свой
                        офис в&nbsp;прибыль за&nbsp;рекордные сроки, который сейчас входит в&nbsp;топ-3 сети АН.
                      </div>
                    </div>
                  </div>

                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---3',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">Иванова Евгения</div>
                          <div className="feedback_item__person__position">Руководитель отдела развития</div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        Начну сразу с&nbsp;результатов: после прохождения игры увеличила вовлеченность сотрудников
                        в&nbsp;проектную работу с&nbsp;20&nbsp;человек до&nbsp;150, личные доходы выросли на&nbsp;50%,
                        укрепила
                        отношения с&nbsp;родными и&nbsp;близкими. Технология крупных продаж применима даже в&nbsp;жизни,
                        не&nbsp;обязательно только в&nbsp;работе. Торгуюсь и&nbsp;получаю скидки там, где моим знакомым
                        это
                        кажется невозможным.
                      </div>
                    </div>
                  </div>

                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---4',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">Селантьев Дмитрий</div>
                          <div className="feedback_item__person__position">
                            Тренер ОФП олимпийской сборной и&nbsp;сборной России по&nbsp;сноубордкроссу
                          </div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        Круто, что после прохождения тренинга по&nbsp;продажам, можно играть
                        в&nbsp;&laquo;Миллионы&nbsp;в&nbsp;твоём&nbsp;кармане&raquo; и&nbsp;оттачивать
                        полученный материал. А&nbsp;потом, когда ко&nbsp;мне приходят реальные клиенты, я&nbsp;уже
                        осознанно
                        выстраиваю работу с&nbsp;ними по&nbsp;методике крупных продаж. Теперь понятно, почему
                        не&nbsp;нужно
                        обсуждать цену, дорого/недорого... Для начала, необходимо знать свой продукт досконально,
                        стремиться
                        понять клиента и&nbsp;предложить&nbsp;то, что ему действительно нужно. И&nbsp;это работает!
                      </div>
                    </div>
                  </div>

                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---5',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">
                            Илюшин Вячеслав
                          </div>
                          <div className="feedback_item__person__position">
                            Заместитель директора&nbsp;
                            <a href="https://raidex.ru/" target="_blank" rel="noreferrer noopener">
                              raidex.ru
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        ...игра плавно перетекает в&nbsp;жизнь. По&nbsp;этой игре можно выстраивать взаимоотношения
                        с&nbsp;людьми. Не обязательно им&nbsp;что-то продавать, а&nbsp;просто взаимодействовать,
                        контактировать,
                        находить понимание. Теперь проще добиваться того, чего я&nbsp;хочу от&nbsp;этого человека,
                        и&nbsp;сохранив, при этом, хорошие отношения. По прошествию времени, все инструменты стали
                        неотъемлемой
                        частью жизни. Ты&nbsp;просто знаешь, что так делать правильно и&nbsp;делаешь!
                      </div>
                    </div>
                  </div>

                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---6',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">
                            Королев Александр
                          </div>
                          <div className="feedback_item__person__position">
                            Руководитель проектов
                          </div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        Квест полезен не&nbsp;только для закрепления знаний, полученных на&nbsp;тренингах,
                        но&nbsp;и&nbsp;тем,
                        что с&nbsp;его помощью человек может сам оценить свой текущий уровень в&nbsp;продажах
                        и&nbsp;переговорах. Для кого-то прохождение квеста может стать инструкцией по&nbsp;выходу
                        на&nbsp;хорошую сделку. Я&nbsp;рассматривал данный квест, как шаблон, на&nbsp;основе которого
                        можно
                        разработать квесты, как для коллег, которые занимаются развитием сети дистрибуции, так и квест
                        для
                        сотрудников, партнеров, кто непосредственно совершает крупные сделки с&nbsp;клиентами.
                      </div>
                    </div>
                  </div>

                  <div className="slide-item-root">
                    <div
                      className={classNames([
                        'feedback_item feedback_item---7',
                      ])}
                    >
                      <div className="feedback_item__person">
                        <div className="feedback_item__person__avatar" />
                        <div className="feedback_item__person__title">
                          <div className="feedback_item__person__name">
                            Иванов Роман
                          </div>
                          <div className="feedback_item__person__position">
                            директор торгово-монтажной компании
                          </div>
                        </div>
                      </div>
                      <div className="feedback_items__message">
                        Как-то раз спалил жену за&nbsp;компьютером. Сидит, играет, а&nbsp;для неё это сильно
                        удивительно.
                        Со&nbsp;словами: &laquo;А ну, подвинься, сейчас мужик разберется!&raquo;, я&nbsp;сам залип
                        на&nbsp;3&nbsp;часа. Играл, вылетал, потом играл снова, страшно бесился, потому что, мне
                        казалось,
                        я&nbsp;делал все правильно. В&nbsp;итоге, перечитал все методички к игре, разобрался, забрал
                        свои
                        3&nbsp;миллиона, сразу внедрил в&nbsp;компании некоторые инструменты и&nbsp;конверсия
                        увеличилась
                        на&nbsp;30%, а&nbsp;средний чек&nbsp;&mdash; на&nbsp;20%. Сейчас по&nbsp;этой методике учусь
                        работать
                        с&nbsp;b2b-сегментом. Буду делиться дальнейшими результатами.
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </section>

            {!isFinal && (
              <section className="promo__section4">
                <div className="promo__cards-1">
                  <div className="promo__card promo__card--1">
                    <div className="promo__card__image" />
                    <div className="promo__card__text">
                      Профессиональный алгоритм и&nbsp;логика выявления потребностей
                    </div>
                  </div>

                  <div className="promo__card promo__card--2">
                    <div className="promo__card__image" />
                    <div className="promo__card__text">Формула продающего коммерческого предложения</div>
                  </div>

                  <div className="promo__card promo__card--3">
                    <div className="promo__card__image" />
                    <div className="promo__card__text">Эффективная презентация для клиента</div>
                  </div>
                </div>
              </section>
            )}

            {!isFinal && (
              <section className="promo__section5">
                <div className="promo_owl">
                  <div className="promo_owl__header">
                    Продавай и&nbsp;зарабатывай как никогда больше
                  </div>
                  <div className="promo_owl__message">
                    Опыт лучших продавцов мы&nbsp;превратили в&nbsp;профессиональный тренажер продаж, который послужит
                    вам наставником. Тренируясь, ты&nbsp;формируешь новую модель поведения. Это уникальная возможность
                    пройти путь профессионального продавца от&nbsp;первой встречи до&nbsp;подписания крупного
                    контракта.
                    Легко формируй навыки в&nbsp;безопасной среде, наращивай компетенции и&nbsp;переноси полученный
                    опыт
                    в&nbsp;реальную жизнь.
                  </div>
                </div>
              </section>
            )}

            {!isFinal && startTrialWithEmail && (
              <div className="promo__inlineForm">
                <div className="promo__inlineForm__bg">
                  <div className="promo__inlineForm__content">
                    <h4 className="promo__inlineForm__megaText">
                      Получить доступ к&nbsp;самой полной профессиональной модели продаж в&nbsp;России
                    </h4>
                    {/*<div className="promo__inlineForm__content__form">*/}
                      {/*<StartTrialGameWithEmailForm />*/}
                    {/*</div>*/}
                    <center>
                      <button
                        type="button"
                        className="promo__button promo__button--green"
                        onClick={onFullVersionClick}
                      >
                        ПОЛУЧИТЬ ДОСТУП
                      </button>
                    </center>
                    <br/>
                  </div>
                </div>
              </div>
            )}

            <section className="promo__section6">
              <div className="promo_features">
                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">01</div>
                  <div className="promo_feature__card__main-text">Три режима тренировки</div>
                  <div className="promo_feature__card__mind">...пройди разные уровни сложности...</div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">02</div>
                  <div className="promo_feature__card__main-text">
                    Эффект как от&nbsp;высококлассного международного тренинга
                  </div>
                  <div className="promo_feature__card__mind">
                    ...создан на&nbsp;основе опыта лучших продавцов.
                    <br />
                    Все совпадения не&nbsp;случайны...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">03</div>
                  <div className="promo_feature__card__main-text">2500 уникальных итогов сделки</div>
                  <div className="promo_feature__card__mind">...в зависимости от&nbsp;вашего выбора...</div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">04</div>
                  <div className="promo_feature__card__main-text">Система мгновенной обратной связи и&nbsp;подсказок
                  </div>
                  <div className="promo_feature__card__mind">
                    ...сразу понятно, что и как делать - виден прогресс,
                    потенциальная сумма
                    <br />
                    сделки
                    и&nbsp;настроение
                    клиента...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">05</div>
                  <div className="promo_feature__card__main-text">Личная библиотека</div>
                  <div className="promo_feature__card__mind">
                    {' '}
                    ...40 исчерпывающих тем о&nbsp;технологии крупных продаж...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">06</div>
                  <div className="promo_feature__card__main-text">Секреты продаж</div>
                  <div className="promo_feature__card__mind">
                    {' '}
                    ...40 лайфхаков от&nbsp;лучших коучей по&nbsp;крупным
                    продажам...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">07</div>
                  <div className="promo_feature__card__main-text">Огромная реиграбельность</div>
                  <div className="promo_feature__card__mind">
                    {' '}
                    ...всегда интересно переиграть.
                    Легкий подход в&nbsp;обучении
                    через личный опыт...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">08</div>
                  <div className="promo_feature__card__main-text">Самая полная модель продаж в&nbsp;мире</div>
                  <div className="promo_feature__card__mind">
                    {' '}
                    ...старая модель, новая модель и&nbsp;крупные продажи...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">09</div>
                  <div className="promo_feature__card__main-text">Международный рейтинг игроков крупных продаж</div>
                  <div className="promo_feature__card__mind">
                    ...с нами играют&nbsp;
                    <div className="promo_feature__card__flags" />
                    ...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">10</div>
                  <div className="promo_feature__card__main-text">Практические рекомендации по&nbsp;ходу тренировки
                  </div>
                  <div className="promo_feature__card__mind">
                    {' '}
                    ...узнаешь, как действовать лучше в процессе обучения и
                    реальной практике...
                  </div>
                </div>

                <div className="promo_feature__card">
                  <div className="promo_feature__card__number">11</div>
                  <div className="promo_feature__card__main-text">Конспект чемпионов крупных продаж</div>
                  <div className="promo_feature__card__mind">
                    {' '}
                    ...подготовься к&nbsp;реальной встрече
                    за&nbsp;5&nbsp;минут...
                  </div>
                </div>
              </div>
            </section>

            <section
              className={classNames([
                'promo__section7',
                isFinal && 'isFinal',
              ])}
            >
              <div className="promo_utp1">
                  <h2>
                    Тренируйся и&nbsp;продавай здесь,
                    <br />
                    продавай и&nbsp;зарабатывай в&nbsp;жизни
                  </h2>

                {!isFinal && (
                  <button
                    type="button"
                    className="promo__button promo__button--green"
                    onClick={onFullVersionClick}
                  >
                    НАЧАТЬ СЕЙЧАС
                  </button>
                )}

                {isFinal && (
                  <button type="button" className="promo__button promo__button--green" onClick={onFullVersionClick}>
                    ПОЛУЧИТЬ ПОЛНУЮ ВЕРСИЮ
                  </button>
                )}
                {!isFinal && (
                  <p>
                    Познакомиться со&nbsp;всеми возможностями профессионального
                    инструмента&nbsp;&mdash; тренажера продаж &laquo;Миллионы&nbsp;в&nbsp;твоём&nbsp;кармане&raquo;
                  </p>
                )}
              </div>
            </section>
            {!isFinal && (
              <section className="promo__section8">
                <h3>О проекте</h3>
                <div className="content">
                  <div className="digits">
                    <div className="item">
                      <div>5 лет</div>
                      <div>успешной работы</div>
                    </div>
                    <div className="item">
                      <div>15 000+</div>
                      <div>человек уже в клубе пользователей Readom</div>
                    </div>
                    <div className="item">
                      <div>100+</div>
                      <div>интервью экспертов при создании игры</div>
                    </div>
                    <div className="item">
                      <div>15</div>
                      <div>
                        человек трудятся над созданием и успешной работой Цифрового производственного объединения Readom
                      </div>
                    </div>
                    <div className="item">
                      <div>2 500</div>
                      <div>уникальных итогов сделки</div>
                    </div>
                    <div className="item">
                      <div>12</div>
                      <div>часов игры самый длинный игровой сеанс после покупки</div>
                    </div>
                    <div className="item">
                      <div>1.5</div>
                      <div>часа самый быстрый результат по освоению полной методики игроком</div>
                    </div>
                    <div className="item">
                      <div>8 часов</div>
                      <div>средний результат по освоению полной методики</div>
                    </div>
                    <div className="item">
                      <div>18 игр</div>
                      <div>в среднем играет каждый участник клуба Readom</div>
                    </div>
                    <div className="item">
                      <div>250</div>
                      <div>готовых профессиональных речевых модулей для работы с клиентами</div>
                    </div>
                    <div className="item">
                      <div>70</div>
                      <div>уникальных рекомендаций в процессе обучения, как сделать лучше</div>
                    </div>
                    <div className="item">
                      <div>35</div>
                      <div>математических моделей рассчитывают, на сколько успешно был сыгран сеанс</div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
        <footer>
          <div className="footer__content">
            <div className="footer_l_col">
              <div className="footer__logo">
                <div className="footer__logo__image" />
                <div className="footer__logo__sub">Цифровое производственное объединение</div>
              </div>
              <span className="footer__copy">© Readom 2014&nbsp;&mdash;&nbsp;2019</span>
            </div>
            <div className="footer_c_col">
              <div className="footer__header">Контакты:</div>
              <a href="mailto:captain@readom.pro">captain@readom.pro</a>
              <a href="tel:88005559822">8-800-555-98-22 </a>
            </div>
            <div className="footer_r_col">
              <Link className="footer__link" to={URL_CONSTANTS.URLS.LICENCE}>лицензионное соглашение</Link>
              <Link className="footer__link" to={URL_CONSTANTS.URLS.POLICY}>конфиденциальность</Link>
              <Link className="footer__link" to={URL_CONSTANTS.URLS.PAYMENT_METHODS}>способы оплаты</Link>
              <Link className="footer__link" to={URL_CONSTANTS.URLS.ORGANIZATION}>организация</Link>
              <div className="footer__payment__logo" />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

PromoPageV2.defaultProps = {
  isAboutBook: true,
  isFinishPage: false,
  isGetFullAccessPage: false,
  startTrialWithEmail: true,
};

PromoPageV2.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  isAboutBook: PropTypes.bool,
  isFinishPage: PropTypes.bool,
  isGetFullAccessPage: PropTypes.bool,
  onDemoStart: PropTypes.func.isRequired,
  onDemoStartWithEmail: PropTypes.func.isRequired,
  setBuyGameGuestPopupOpened: PropTypes.func.isRequired,
  startTrialWithEmail: PropTypes.bool,
  trackBuyGameButtonClicked: PropTypes.func.isRequired,
  trackStartDemoButtonClick: PropTypes.func.isRequired,
};

export default PromoPageV2;
