/* eslint-disable max-len,import/prefer-default-export */
/* eslint no-useless-escape: 0 */

export const validator = {
  required: value => (
    value.length !== 0
  ),
  number: value => (
    value !== false
    && /[0-9]{6}/.test(value)
  ),
  name: value => (
    value !== false
    && /[A-ЯЁ]{1}[а-яA-ЯЁё]+/.test(value)
  ),
  surname: value => (
    value !== false
    && /[A-ЯЁ]{1}[а-яA-ЯЁё]+/.test(value)
  ),
  patronymic: value => (
    value === false
    || value.length === 0
    || /[A-ЯЁ]{1}[а-яA-ЯЁё]+/.test(value)
  ),
  email: value => (
    value !== false
    && /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value) // eslint-disable-line no-use-before-define
  ),
};
