import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isString from 'lodash/isString';

const selectEmployees = state => state.get('employees');

const makeSelectDepartments = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('departments'),
);

const sortEmployees = employees => employees;

const makeSelectEmployees = () => createSelector(
  selectEmployees,
  employeesState => sortEmployees(employeesState.get('employees')),
);

const makeSelectLoading = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('isLoading'),
);

const makeSelectNewDepartmentName = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('newDepartmentName'),
);

const makeSelectNewDepartmentLoading = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('newDepartmentLoading'),
);

const makeSelectActiveTab = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('activeTab'),
);

const makeSelectDepartmentForDelete = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('departmentForDelete'),
);

const makeSelectDepartmentDeletePopup = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('deleteDepartmentPopupIsOpened'),
);

const makeSelectDepartmentForEdit = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('departmentForEdit').toJS(),
);

const makeSelectDepartmentEditPopup = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('editDepartmentPopupIsOpened'),
);

const makeSelectEmployeeAddPopup = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('addEmployeePopupIsOpened'),
);

const makeSelectEmployeeEditPopup = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('editEmployeePopupIsOpened'),
);

const makeSelectEmployeeDeletePopup = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('deleteEmployeePopupIsOpened'),
);

const makeSelectEmployeeForDelete = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('employeeForDelete'),
);

const makeSelectEmployeeForEdit = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('employeeForEdit').toJS(),
);

const makeSelectEmployeeFilterValues = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('employeeFilter').toJS(),
);

const makeSelectEmployeeFilterKeyword = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('employeeFilter').get('keyword'),
);

const makeSelectEmployeeFilterDepartmentId = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('employeeFilter').get('departmentId'),
);

const makeSelectEmployeeFilterStatus = () => createSelector(
  selectEmployees,
  employeesState => employeesState.get('employeeFilter').get('status'),
);

const makeSelectFilteredEmployees = () => createSelector(
  selectEmployees,
  (employeesState) => {
    let employees = sortEmployees(employeesState.get('employees'));
    const filterValues = employeesState.get('employeeFilter').toJS();
    const departmentId = get(filterValues, 'departmentId.value');
    if (departmentId) {
      employees = filter(employees, employee => employee.departmentId === departmentId);
    }

    let keyword = get(filterValues, 'keyword', '');
    if (isString(keyword)) {
      keyword = keyword.trim().toLowerCase();
    }

    if (keyword) {
      employees = filter(employees, employee => employee.email.trim().toLowerCase().indexOf(keyword) !== -1
        || employee.name.trim().toLowerCase().indexOf(keyword) !== -1
        || employee.patronymic.trim().toLowerCase().indexOf(keyword) !== -1
        || employee.surname.trim().toLowerCase().indexOf(keyword) !== -1);
    }

    const status = get(filterValues, 'status.value');
    if (status) {
      employees = filter(employees, employee => employee.statusCode === status);
    }

    return employees;
  },
);

export {
  makeSelectEmployeeFilterStatus,
  makeSelectEmployeeFilterValues,
  makeSelectDepartments,
  makeSelectEmployees,
  makeSelectLoading,
  makeSelectNewDepartmentName,
  makeSelectNewDepartmentLoading,
  makeSelectActiveTab,
  makeSelectDepartmentForDelete,
  makeSelectDepartmentDeletePopup,
  makeSelectDepartmentForEdit,
  makeSelectDepartmentEditPopup,
  makeSelectEmployeeAddPopup,
  makeSelectEmployeeEditPopup,
  makeSelectEmployeeDeletePopup,
  makeSelectEmployeeForDelete,
  makeSelectEmployeeForEdit,
  makeSelectFilteredEmployees,
  makeSelectEmployeeFilterKeyword,
  makeSelectEmployeeFilterDepartmentId,
};
