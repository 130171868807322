import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectBuyGamePopupOpened = () => createSelector(
  storeSlice,
  state => state.get('buyGamePopupOpened'),
);

const makeSelectBuyGameStages36PopupOpened = () => createSelector(
  storeSlice,
  state => state.get('buyGameStages36PopupOpened'),
);

const makeSelectBuyGameGuestPopupOpened = () => createSelector(
  storeSlice,
  state => state.get('buyGameGuestPopupOpened'),
);

const makeSelectStartTrialGameWithEmailPopupOpened = () => createSelector(
  storeSlice,
  state => state.get('startTrialGameWithEmailPopupOpened'),
);

const makeSelectStartTrialGameWithPhonePopupOpened = () => createSelector(
  storeSlice,
  state => state.get('startTrialGameWithPhonePopupOpened'),
);

const makeSelectBeforeDemoLeavePopupOpened = () => createSelector(
  storeSlice,
  state => state.get('beforeDemoLeavePopupOpened'),
);

const makeSelectThxPopupOpened = () => createSelector(
  storeSlice,
  state => state.get('thxPopupOpened'),
);

export {
  makeSelectBuyGamePopupOpened,
  makeSelectBuyGameStages36PopupOpened,
  makeSelectBuyGameGuestPopupOpened,
  makeSelectStartTrialGameWithEmailPopupOpened,
  makeSelectStartTrialGameWithPhonePopupOpened,
  makeSelectBeforeDemoLeavePopupOpened,
  makeSelectThxPopupOpened,
};
