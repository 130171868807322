import { fromJS } from 'immutable';
import {
  SET_STARTED_TRIAL_GAME_SESSION_ID,
} from './constants';

const initialState = fromJS({
  startedTrialGameSessionId: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STARTED_TRIAL_GAME_SESSION_ID:
      return state
        .set('startedTrialGameSessionId', action.startedTrialGameSessionId);
    default:
      return state;
  }
}

export default reducer;
