import styled from 'styled-components';

const H2 = styled.h2`
  color: ${props => (props.grey ? '#808085' : '#1C1C1C')};
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  line-height: 19px;
  margin: 0;
  text-align: ${props => (props.textAlign ? props.textAlign : 'inherit')};
`;

export default H2;
