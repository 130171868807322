import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typograf from 'typograf';
import styled, { createGlobalStyle } from 'styled-components';

import AnswerWrapper from './AnswerWrapper';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const GlobalStyle = createGlobalStyle`
  .answerMethodTwo.firstActive:not(.lastActive) {
    border-radius: 10px 10px 0 0;
  }  
  
  .answerMethodTwo.middleActive {
    border-radius: 0;
  }
  
  .answerMethodTwo.lastActive:not(.firstActive) {
    border-radius: 0 0 10px 10px;
  }
  
  .answerMethodTwo.firstActive:not(.lastActive),
  .answerMethodTwo.middleActive {
    &:after {
      width: 100%;
      content: '';
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: inherit;
      z-index: 1;
      margin: 0 auto;
    }
    
    &:before {
      width: calc(100% - 20px);
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 10px;
      background: rgba(0,0,0,.07);
      z-index: 2;
      margin: 0 auto;
    }
  }
`;

const Index = styled.div`
  padding-right: 10px;
`;

const Text = styled.div`
  width: 100%;
`;

class Answer extends React.PureComponent {
  handleClick = () => {
    setTimeout(() => {
      if (this.props.crossedOut) {
        return;
      }

      this.props.checkAnswer(this.props.id);
    }, 150);
  };

  render() {
    const {
      activeIndex, firstActive, middleActive, lastActive, answerMethodTwo,
    } = this.props;

    return (
      <React.Fragment>
        <GlobalStyle />
        <AnswerWrapper
          className={classNames([
            'pressable-button',
            answerMethodTwo && 'answerMethodTwo',
            firstActive && 'firstActive',
            middleActive && 'middleActive',
            lastActive && 'lastActive',
          ])}
          notPressable={this.props.crossedOut}
          onClick={this.handleClick}
          isActive={this.props.isActive && !answerMethodTwo}
          crossedOut={this.props.crossedOut}
        >
          {activeIndex && (
            <Index>
              {`${activeIndex})`}
            </Index>
          )}
          <Text dangerouslySetInnerHTML={{ __html: tp.execute(this.props.text) }} />
        </AnswerWrapper>
      </React.Fragment>
    );
  }
}

Answer.propTypes = {
  checkAnswer: PropTypes.func.isRequired,
  crossedOut: PropTypes.bool,
  answerMethodTwo: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  text: PropTypes.string.isRequired,
  activeIndex: PropTypes.number,
  firstActive: PropTypes.bool,
  middleActive: PropTypes.bool,
  lastActive: PropTypes.bool,
};

Answer.defaultProps = {
  answerMethodTwo: false,
  activeIndex: null,
  crossedOut: false,
  isActive: false,
  firstActive: false,
  middleActive: false,
  lastActive: false,
};

export default Answer;
