import compose from 'recompose/compose';
import pure from 'recompose/pure';
import setDisplayName from 'recompose/setDisplayName';

const enhance = compose(
  setDisplayName('GamesPage_Enhanced'),
  pure,
);

export default enhance;
