import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  margin-top: ${(props) => {
    if (props.small && !props.isGame) {
      return '60px';
    }

    if (props.isGame) {
      if (props.small) {
        return '60px';
      }

      return '71px';
    }

    return '0';
  }};
`;

export default Main;
