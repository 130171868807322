import {
  call, takeLeading, delay,
} from 'redux-saga/effects';
import get from 'lodash/get';
import querystring from 'querystring';
import passwordHash from '../../../../utils/passwordHash';
import { apiRequest as createApiRequest } from '../../../../utils/request';
import { REGISTER_REQUEST } from '../../../modules/forms/registrationForm/constants';

function build({ cookies }) {
  const apiRequestGuest = createApiRequest(cookies, false);

  function* registrationSaga({ payload }) {
    const { values, formik: { setFieldError, setStatus, setSubmitting } } = payload;

    const requestURL = `${process.env.REACT_APP_API_URL}/register`;

    const data = {
      fieldLogin: values.email,
      fieldPassword: passwordHash(values.password),
      fieldPassword2: passwordHash(values.passwordConfirm),
      fieldStatus: 1,
    };

    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: querystring.stringify(data),
    };

    try {
      setStatus({ submitSucceeded: undefined });
      setSubmitting(true);
      yield delay(500);
      const result = yield call(apiRequestGuest, requestURL, config);

      // TODO: HARDCODE_TYPE_2 Успех регистрации определяется по наличию в сообщении текста
      const isSuccess = String(get(result, 'data', null)).indexOf('Пользователь успешно зарегистирован') !== -1;
      if (isSuccess) {
        setStatus({ submitSucceeded: true });
      } else {
        const errorMessage = get(result, 'data', null);
        // TODO: HARDCODE_TYPE_2 Тип ошибки определяется по тексту ошибки
        if (errorMessage === 'Указанный логин уже существует.') {
          setFieldError('email', errorMessage);
        } else {
          throw new Error('Unknown error while user register.');
        }
      }
    } catch (err) {
      // TODO: HARDCODE_TYPE_2 Текст сообщения об ошибке зашит в код
      setStatus({
        submitSucceeded: false,
        globalError: 'Ошибка. Что-то пошло не так. Попробуйте зарегестироваться позже или обратитесь в техническую поддерку Readom trainer@readom.pro.',
      });
    } finally {
      setSubmitting(false);
    }
  }

  function* saga() {
    yield takeLeading(REGISTER_REQUEST, registrationSaga);
  }

  return saga;
}

export default build;
