import {
  call, select, put, takeLeading,
} from 'redux-saga/effects';
import { apiRequest as createApiRequest } from '../../../utils/request';

import { resetSuccess, resetFail } from '../../modules/global/actions';
import { makeSelectApiUrl, makeSelectResetLogin } from '../../modules/global/selectors';
import { RESET_REQUEST } from '../../modules/global/constants';

function build(cookies) {
  const apiRequest = createApiRequest(cookies, false);

  function* resetSaga() {
    const url = yield select(makeSelectApiUrl());
    const email = yield select(makeSelectResetLogin());

    const requestURL = `${url}/SendRequestRemindPassword/${encodeURIComponent(email)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      if (request.data.result[0].Result !== 'fail') {
        yield put(resetSuccess());
      } else {
        yield put(resetFail());
      }
    } catch (e) {
      yield put(resetFail());
    }
  }

  function* resetPasswordSaga() {
    yield takeLeading(RESET_REQUEST, resetSaga);
  }

  return resetPasswordSaga;
}

export default build;
