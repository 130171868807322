import React from 'react';

import Content from '../../../../components/Content/index';
import H2 from '../../../../components/H2/index';
import Section from '../../../../components/Section/index';
import H1 from './includes/H1';
import Text from './includes/Text';
import TextBlock from './includes/TextBlock';
import TextWrapper from './includes/TextWrapper';

const Licence = () => (
  <Section>
    <Content>
      <TextWrapper>
        <TextBlock>
          <H1>Политика обработки персональных данных</H1>
          <H2>Общие положения</H2>
          <Text>
            Политика в&nbsp;отношении обработки персональных данных- (далее&nbsp;&mdash; Политика) определяет нашу
            позицию и&nbsp;намерения
            в&nbsp;области обработки и&nbsp;защиты персональных данных, с&nbsp;целью соблюдения и&nbsp;защиты прав
            и&nbsp;свобод каждого человека
            и, в&nbsp;особенности, права на&nbsp;неприкосновенность частной жизни, личную и&nbsp;семейную тайну, защиту
            своей чести и
            доброго имени.
            Политика неукоснительно исполняется руководителями и&nbsp;работниками всех структурных подразделений
            и&nbsp;филиалов.
            Действие Политики распространяется на&nbsp;все персональные данные субъектов, обрабатываемые
            с&nbsp;применением
            средств автоматизации и&nbsp;без применения таких средств.
          </Text>
          <H2>Какую информацию мы собираем?</H2>
          <Text>
            Мы&nbsp;сохраняем идентифицируемую информацию, поступающую от&nbsp;вас, разными способами, включая данные,
            предоставляемые при покупке в&nbsp;игр (и прочих товаров) и&nbsp;других взаимодействиях
            с&nbsp;сайтом.
            Такие данные могут включать: имя, фамилию, отчество адрес электронной почты, номер телефона. В&nbsp;момент
            сбора информации
            мы&nbsp;уведомляем вас, при каких обстоятельствах ваши данные могут быть сохранены нами до&nbsp;момента
            их&nbsp;удаления из
            наших баз по&nbsp;вашему требованию информация в&nbsp;любой момент может быть удалена. Информация
            о&nbsp;посетителях сайта
            (IP-адрес, имя домена, тип браузера и&nbsp;операционная система, дата и&nbsp;время посещения и&nbsp;т.п.)
            собирается и
            сохраняется в&nbsp;целях ведения статистики посещений. Эта информация является общедоступной, таким образом,
            мы
            не&nbsp;несем ответственности за&nbsp;ее&nbsp;разглашение.
            Обработка и&nbsp;обеспечение безопасности персональных данных в&nbsp;нашей компании осуществляется
            в&nbsp;соответствии с
            требованиями Конституции Российской Федерации, Федерального закона
            N&deg;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo;,
            подзаконных актов, других определяющих случаи и&nbsp;особенности обработки персональных данных федеральных
            законов Российской Федерации, руководящих и&nbsp;методических документов ФСТЭК России и&nbsp;ФСБ России.
          </Text>
          <H2>Что мы делаем с полученной информацией?</H2>
          <Text>
            Эти данные могут быть использованы в&nbsp;маркетинговых целях, для сообщения вам о&nbsp;вашей победе
            в&nbsp;конкурсе и
            выигранных вами призах; для рассылки вам новостей и&nbsp;информации об&nbsp;акциях, новинках и&nbsp;наших
            услугах.
            Во&nbsp;время регистрации вам будет предложено выбрать, хотите&nbsp;ли вы&nbsp;получать такую информацию.
            Полученные данные в&nbsp;обезличенном виде могут быть использованы для лучшего понимания потребностей наших
            покупателей и&nbsp;улучшения качества обслуживания.
            Ваше согласие на&nbsp;получение рассылок от&nbsp;нашей компании является добровольным, и&nbsp;вы&nbsp;можете
            в&nbsp;любое время
            изменить свой выбор или отказаться от&nbsp;дальнейшего получения вышеуказанной информации.
            Мы&nbsp;обрабатываем персональные данные только при наличии хотя&nbsp;бы одного из&nbsp;следующих условий:
            <ul>
              <li>
                обработка персональных данных осуществляется с&nbsp;согласия субъекта персональных данных
                на&nbsp;обработку его персональных данных;
              </li>
              <li>
                обработка персональных данных необходима для достижения целей, предусмотренных законом, для
                осуществления и&nbsp;выполнения возложенных законодательством Российской Федерации на&nbsp;оператора
                функций, полномочий и&nbsp;обязанностей;
              </li>
              <li>
                обработка персональных данных необходима для исполнения договора, стороной которого либо
                выгодоприобретателем или поручителем по&nbsp;которому является субъект персональных данных, а&nbsp;также
                для
                заключения договора по&nbsp;инициативе субъекта персональных данных или договора, по&nbsp;которому
                субъект
                персональных данных будет являться выгодоприобретателем или поручителем;
              </li>
              <li>
                обработка персональных данных необходима для осуществления прав и&nbsp;законных интересов нашей компании
                или третьих лиц либо для достижения общественно значимых целей при условии, что при этом
                не&nbsp;нарушаются
                права и&nbsp;свободы субъекта персональных данных;
              </li>
              <li>
                осуществляется обработка персональных данных, доступ неограниченного круга лиц к&nbsp;которым
                предоставлен
                субъектом персональных данных либо по&nbsp;его просьбе;
              </li>
              <li>
                осуществляется обработка персональных данных, подлежащих опубликованию или обязательном к&nbsp;раскрытию
                в соответствии с&nbsp;федеральным законом.
              </li>
            </ul>
          </Text>
          <H2>Обращения пользователей</H2>
          <Text>
            Пользователи вправе направлять Администрации Сайта свои запросы, в&nbsp;том числе запросы относительно
            использования их&nbsp;персональных данных в&nbsp;форме электронного документа, подписанного
            квалифицированной
            электронной подписью в&nbsp;соответствии с&nbsp;законодательством Российской Федерации, на&nbsp;e-mail:
            captain@readom.pro
            Администрация Сайта обязуется рассмотреть и&nbsp;направить ответ на&nbsp;поступивший запрос пользователя
            в&nbsp;течение 30
            дней с&nbsp;момента поступления обращения.
            Вся корреспонденция, полученная Администрацией Сайта от&nbsp;пользователей (обращения в&nbsp;письменной или
            электронной форме), относится к&nbsp;информации ограниченного доступа и&nbsp;не&nbsp;разглашается без
            письменного согласия
            Пользователя. Персональные данные и&nbsp;иная информация о&nbsp;Пользователе, направившем запрос,
            не&nbsp;могут быть без
            специального согласия Пользователя использованы иначе, как для ответа по&nbsp;теме полученного запроса или в
            случаях, прямо предусмотренных законодательством.
          </Text>
          <H2>Использование файлов cookie</H2>
          <Text>
            Как многие веб-сайты, сайты мы применяем
            такие технологии, как cookie и веб-маяки (web beacons), которые
            позволяют нам облегчить Ваше пребывание на нашем веб-сайте,
            сделать его более эффективным и более ценным, предоставив Вам
            настроенное под Вас окружение и узнав Вас при повторном
            посещении нашего веб-сайта.
            Вы можете просматривать некоторую часть веб-сайта readom.pro , не принимая cookie, однако, некоторые
            функции сайта могут быть утрачены в результате дезактивации
            cookie на Вашем компьютере. Для других веб-страниц
            readom.pro, в частности, тех, для посещения которых
            требуются имя пользователя (login) и пароль, cookie необходимы,
            такие страницы нельзя использовать, если Вы дезактивировали
            cookie в своем браузере.
            Мы можем также использовать настройку ссылок
            или другие подобные технологии для отслеживания ссылок на
            электронные адреса, по которым Вы щелкаете. Мы можем
            сопоставить эту информацию с Вашими персональными данными
            для того, чтобы предоставить Вам по электронной почте более
            ориентированные на Вас сообщения или информацию о покупках
            или услугах. Каждое сообщение, направляемое по электронной
            почте, включает ссылку для отказа от подписки, позволяющую Вам
            прекратить доставку сообщений такого рода.

            Файлы cookie, используемые на наших веб-сайтах, подразделяются
            на категории. Мы используем следующие категории на наших вебсайтах и прочих онлайн-сервисах:
            <ul>
              <li>
                Необходимые файлы cookie.Эти файлы cookie необходимы для
                просмотра наших веб-сайтов и использования их функций. Без них
                невозможно обеспечить работу таких сервисов, как корзина покупок
                и интернет-оплата.
              </li>

              <li>
                Эксплуатационные файлы cookie. Эти файлы cookie собирают
                информацию об использовании веб-сайтов, например о наиболее
                часто посещаемых страницах. Такие данные могут быть
                использованы для оптимизации наших веб-сайтов и упрощения
                навигации. Эти файлы cookie также используются нашими
                аффилированными лицами для того, чтобы определить, перешли ли
                вы на наш веб-сайт с сайта аффилированных лиц воспользовались ли
                вы нашими сервисами и купили ли вы наши продукты в результате
                посещения веб-сайта, в том числе — какие именно продукты и
                сервисы вы купили. Вся информация, собранная с их помощью,
                предназначена для статистических целей и остается анонимной.
              </li>
              <li>
                Функциональные файлы «cookie». Эти файлы cookie позволяют
                нашим веб-сайтам запомнить сделанный вами выбор при просмотре
                сайта. Например, файл cookie может запомнить ваше
                местонахождение, что позволит нам отобразить наш веб-сайт на
                языке вашей страны. Эти файлы также могут использоваться для
                запоминания таких настроек, как размер и шрифт текста, а также
                других настраиваемых параметров сайта. Эти файлы также могут
                использоваться для отслеживания рекомендуемых продуктов во
                избежание повторения. Информация, предоставляемая такими
                файлами cookie, не позволяет вас идентифицировать. Они не
                предназначены для отслеживания вашей работы на веб-сайтах, не
                имеющих отношение к readom.pro.
              </li>
            </ul>

            Если Вы предпочитаете не получать cookie при просмотре нашего
            веб-сайта или через электронные сообщения формата HTML, Вы
            можете настроить Ваш браузер таким образом, чтобы он
            предупреждал Вас, прежде чем принять cookie, либо блокировать
            cookie, когда Ваш браузер предупреждает Вас о наличии cookie. Вы
            также можете отказаться от принятия всех cookie, отключив их в
            Вашем браузере.
          </Text>

          <H1>Контактная информация организации</H1>
          <Text>
            <p>Индивидуальный предприниматель Меликсетян Сергей Сейранович</p>
            <p>ОГРНИП 307565830400370 ИНН 561604624301</p>
            <p>Юридический адрес: 141435, Московская обл.,</p>
            <p>г. Химки, Микрорайон Новогорск, ул. Заречная, д. 7, корп. 1, кв. 7</p>
            <p>Почтовый адрес: 630004 г. Новосибирск, ул. Вокзальная магистраль, 1/1, офис 608.</p>

            <p>Реквизиты банка:</p>
            <p>р/с 40802810323250000110</p>
            <p>в филиал «Новосибирский» ОАО «Альфа-Банк» г. Новосибирск</p>
            <p>к/с 30101810600000000774</p>
            <p>БИК 045004774</p>
          </Text>
        </TextBlock>
      </TextWrapper>
    </Content>
  </Section>
);

export default Licence;
