import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import Typograf from 'typograf';
import Button from '../../../useThis/Button';
import ButtonText from '../styled/ButtonText';

import Text from '../styled/Text';
import Header from '../styled/Header';
import Buttons from '../styled/Buttons';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

class OwlTextContent extends React.PureComponent {
  static defaultProps = {
    header: null,
    button: null,
    text: null,
  };

  static propTypes = {
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    header: PropTypes.string,
    setOwlVisible: PropTypes.func.isRequired,
    setSpotlightVisible: PropTypes.func.isRequired,
    setOwlCurrentMessageShown: PropTypes.func.isRequired,
    text: PropTypes.string,
    small: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    if (props.small && props.text) {
      props.setSpotlightVisible(true);
    }
  }

  componentWillUnmount() {
    this.props.setSpotlightVisible(false);
  }

  onButtonClick = () => {
    this.props.setOwlVisible(false);
    this.props.setOwlCurrentMessageShown(true);
    this.props.setSpotlightVisible(false);
  };

  renderButton = () => {
    let buttonText = 'Ок';

    const { button } = this.props;

    if (button && button.text && isString(button.text)) {
      buttonText = button.text;
    }

    return (
      <Buttons>
        <Button
          noMargins
          isWhite
          onClick={this.onButtonClick}
        >
          <ButtonText>
            {buttonText}
          </ButtonText>
        </Button>
      </Buttons>
    );
  };

  render() {
    const {
      header, text,
    } = this.props;

    return (
      <React.Fragment>
        {header && (
          <Header>{header}</Header>
        )}
        {text && (
          <Text dangerouslySetInnerHTML={{ __html: tp.execute(text) }} />
        )}
        {this.renderButton()}
      </React.Fragment>
    );
  }
}

export default OwlTextContent;
