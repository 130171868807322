import React from 'react';
import styled from 'styled-components';
import pure from 'recompose/pure';
import { onFeedBackButtonClick } from '../FabChat/actions';

import BgImage from './mail.svg';

const SupportWrapper = pure(styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;
`);

const Icon = pure(styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-size: ${props => (props.backgroundSize ? props.backgroundSize : 'contain')};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 7px;
  top: -5px;
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10), 0 0 2px 0 rgba(0,0,0,0.10);
`);

const SupportFloatButton = () => (
  <SupportWrapper>
    <Icon backgroundImage={BgImage} onClick={onFeedBackButtonClick} className="tour-step-11" />
  </SupportWrapper>
);

export default SupportFloatButton;
