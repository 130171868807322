import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IoIosChatbubbles } from 'react-icons/io';
import styled from 'styled-components';
import pure from 'recompose/pure';

import CloseIconSvg from '../../../assets/fabChat/close.svg';
import SupportColorSvg from '../../../assets/fabChat/support-color.svg';
import FeedbackSvg from '../../../assets/fabChat/feedback.svg';
import WhatsAppColorSvg from '../../../assets/fabChat/whatstapp-color.svg';
import { onFeedBackButtonClick, onOnlineHelpButtonClick, onWhatsAppButtonClick } from '../actions';

import './style.css';

const BubbleIcon = pure(styled(IoIosChatbubbles)`
  width: 30px;
  height: 30px;
  color: #fff;
`);

const CloseIcon = pure(styled.div`
  width: 30px;
  height: 30px;
  color: #fff;
  background: url(${CloseIconSvg}) no-repeat;
  background-size: 20px 20px;
  background-position: center;
`);

const Icon = styled.div`
  background-size: cover;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SupportIcon = styled(Icon)`
  background-image: url(${SupportColorSvg});
`;

const FeedbackIcon = styled(Icon)`
  background-image: url(${FeedbackSvg});
`;

const WhatsAppColorIcon = styled(Icon)`
  background-image: url(${WhatsAppColorSvg});
`;

const FabChatMobile = ({ isAuthenticated, profile }) => {
  const [opened, setOpened] = useState(false);
  const node = useRef();

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const onActionClick = () => setOpened(!opened);

  const { name, surname, email } = profile;

  return (
    <div
      ref={node}
      className={classNames([
        'fabMobile',
        opened ? 'opened' : 'closed',
      ])}
    >
      <div
        className="actions"
        onClick={onActionClick}
      >
        {false && isAuthenticated && <WhatsAppColorIcon onClick={onWhatsAppButtonClick} />}
        <FeedbackIcon onClick={onFeedBackButtonClick({ name, surname, email })} />
        <SupportIcon onClick={onOnlineHelpButtonClick} />
      </div>
      <BubbleIcon className="BubbleIcon" onClick={onActionClick} />
      <CloseIcon className="CloseIcon" onClick={onActionClick} />
    </div>
  );
};

FabChatMobile.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default FabChatMobile;
