import { eventChannel } from 'redux-saga';
import {
  call, put, takeLatest, take, cancel,
} from 'redux-saga/effects';
import isString from 'lodash/isString';
import { LOGOUT } from '../../modules/global/constants';
import { receiveOwlMessage, setOwlCurrentMessageShown } from '../../modules/owl/actions';
import { START_OWL_LISTEN } from '../../modules/owl/constants';
import { setVisible as setSpotLightVisible } from '../../modules/spotlight/actions';

function externalSourceChannel() {
  const onEvent = emit => (e) => {
    // import { END } from 'redux-saga';
    // // Example: If some condition is met, close the channel (returns the unsubscribe function)
    // if (data.someValue === 'someOtherValue') {
    //   emit(END);
    // }

    if (isString(e.detail.owlMessage)) {
      emit(e.detail.owlMessage);
    }
  };

  return eventChannel((emit) => {
    window.addEventListener('OWL_MESSAGE', onEvent(emit), false);

    // This subscriber function must return an unsubscribe function
    return () => {
      window.removeEventListener('OWL_MESSAGE', onEvent(emit), false);
    };
  });
}

function onLocationPopStateChannel() {
  const onEvent = emit => () => {
    emit('');
  };

  return eventChannel((emit) => {
    window.onpopstate = () => {
      onEvent(emit)();
    };

    return () => {
      window.onpopstate = null;
    };
  });
}

function* externalSourceListener() {
  const chan = yield call(externalSourceChannel);
  try {
    while (true) {
      const pushedData = yield take(chan);
      yield put(receiveOwlMessage(pushedData));
    }
  } catch (error) {
    console.error('Что-то не то с совой');
    // yield put(someActionHandler.failure(error));
  }
}

function* popStateOwlListener() {
  const chan = yield call(onLocationPopStateChannel);
  try {
    while (true) {
      const pushedData = yield take(chan);
      yield put(receiveOwlMessage(pushedData));
      yield put(setSpotLightVisible(false));
      yield put(setOwlCurrentMessageShown(true));
    }
  } catch (error) {
    console.error('Что-то не то с совой');
  }
}

function* saga() {
  const watcher1 = yield takeLatest(START_OWL_LISTEN, externalSourceListener);
  const watcher2 = yield takeLatest(START_OWL_LISTEN, popStateOwlListener);

  // Suspend execution until LOGOUT
  yield take(LOGOUT);
  yield cancel(watcher1);
  yield cancel(watcher2);
}

export default saga;
