/* eslint-disable react/jsx-no-bind */
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import isNull from 'lodash/isNull';
import isFunction from 'lodash/isFunction';
import { withRouter } from 'react-router';
import TableCellData from '../../../../components/TableCellData';

import UserPhoto from '../../../../components/UserPhoto';
import RowHeader from './RowHeader';

import URL_CONSTANTS from '../../../../urlConstants';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  min-width: ${props => props.minWidth}
`;

const TableRootWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const getBgColor = (props) => {
  if (props.isMyRating) {
    if (isFunction(props.onClick)) {
      return darken(0.05, '#EBEBED');
    }

    return '#EBEBED';
  }

  if (isFunction(props.onClick)) {
    return darken(0.05, '#FFF');
  }

  return 'none';
};

const Line = styled.div`
  align-items: center;
  background-color: ${props => (props.isMyRating ? '#EBEBED' : 'none')};
  cursor: ${props => (isFunction(props.onClick) ? 'pointer' : 'default')};
  border-bottom: 1px solid #E6E6E6;
  border-left: ${props => (props.isMyRating ? '1px solid #FFFFFF' : 'none')};
  border-right: ${props => (props.isMyRating ? '1px solid #FFFFFF' : 'none')};
  display: flex;
  height: 60px;
  padding: 30px;
  width: 100%;
  transition: 0.2s background-color ease-in-out; 

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  &:hover {
    background-color: ${props => getBgColor(props)}
`;

const Row = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  margin-right: 15px;
  width: ${props => `calc(100% * ${props.width - 15}/1170)`};

  &:last-child {
    margin-left: auto;
    margin-right: 0;
    width: ${props => `calc(100% * ${props.width}/1170)`};
  }
`;

class Table extends React.PureComponent {
  onLineClick(userId) {
    this.props.history.push(`${URL_CONSTANTS.URLS.COMMON_RATING}?userId=${userId}`);
  }

  render() {
    let tableItems = <div />;

    // tableItems = this.props.items.map(line => (
    //   <Line key={line.key} isMyRating={line.isMyRating} onClick={this.onLineClick.bind(this, line.key)}>
    //     {
    //       line.items.map((item) => {
    //         if (item.image === true) {
    //           let photo = null;
    //
    //           if (item.photo) {
    //             photo = item.photo;
    //           }
    //
    //           return (
    //             <Row key={item.key} width={item.width}>
    //               <UserPhoto
    //                 inRating
    //                 src={photo}
    //                 name={item.name}
    //                 surname={item.surname}
    //                 alt={`${item.name} ${item.surname}`}
    //               />
    //             </Row>
    //           );
    //         }
    //
    //         return <Row key={item.key} width={item.width}>{item.title}</Row>;
    //       })
    //     }
    //   </Line>
    // ));

    if (!isNull(this.props.items)) {
      tableItems = this.props.items.map(line => (
        <Line
          key={line.key}
          onClick={this.onLineClick.bind(this, line.key)}
          isMyRating={line.isMyRating}
        >
          {map(line.items, item => (
            <Row
              key={item.key}
              width={item.width}
            >
              <TableCellData
                field={item.field}
                value={item.value}
              />
            </Row>
          ))}
        </Line>
      ));
    }

    return (
      <TableRootWrapper>
        <TableWrapper minWidth={this.props.minWidth}>
          <Line>
            {this.props.header.map(headerItem => (
              <RowHeader
                key={headerItem.key}
                width={headerItem.width}
                tooltip={headerItem.tooltip}
              >
                {headerItem.title}
              </RowHeader>
            ))}
          </Line>
          {tableItems}
        </TableWrapper>
      </TableRootWrapper>
    );
  }
}

Table.propTypes = {
  header: PropTypes.array,
  items: PropTypes.array,
};

export default withRouter(Table);
