import {
  TRACK_BUY_GAME_BUTTON_CLICKED,
  TRACK_BUY_GAME_FAIL,
  TRACK_OPEN_LIBRARY_IN_GAME_OVERVIEW,
  TRACK_OPEN_LIBRARY_IN_GAME,
  WEBTEAM_REGISTRATION,
  WEBTEAM_START_GAME,
  WEBTEAM_TRIAL_COMPLETED,
  TRACK_START_DEMO_BUTTON_CLICK,
  TRACK_CLICK_GET_FULL_ACCESS_LINK,
  TRACK_VIDEO_CLICK,
  TRACK_I_AM_INTERESTED_BUTTON_CLICK,
  TRACK_ERROR_WHEN_BUY_GAME_AS_GUEST,
  TRACK_REDIRECT_USER_TO_PAYMENT_GATE, TRACK_DEMO_COMEBACK_FORM_SUBMIT,
} from './constants';

export function trackBuyGameButtonClicked(showcaseId, where, inGame) {
  return {
    type: TRACK_BUY_GAME_BUTTON_CLICKED,
    payload: {
      showcaseId,
      where,
      inGame,
    },
  };
}

export function trackBuyGameFail() {
  return {
    type: TRACK_BUY_GAME_FAIL,
  };
}

export function trackRedirectUserToPaymentGate() {
  return {
    type: TRACK_REDIRECT_USER_TO_PAYMENT_GATE,
  };
}

export function trackErrorWhenBuyGameAsGuest(errorMessage) {
  return {
    type: TRACK_ERROR_WHEN_BUY_GAME_AS_GUEST,
    payload: {
      errorMessage,
    },
  };
}

export function trackIAmInterestedButtonClick() {
  return {
    type: TRACK_I_AM_INTERESTED_BUTTON_CLICK,
  };
}

export function trackVideoClick(where) {
  return {
    type: TRACK_VIDEO_CLICK,
    payload: {
      where,
    },
  };
}

export function trackOpenLibraryPopupInOverview(cardId) {
  return {
    type: TRACK_OPEN_LIBRARY_IN_GAME_OVERVIEW,
    payload: {
      cardId,
    },
  };
}

export function trackOpenLibraryPopupInGame() {
  return {
    type: TRACK_OPEN_LIBRARY_IN_GAME,
  };
}

export function webteamRegistrationEvent(clickId) {
  return {
    type: WEBTEAM_REGISTRATION,
    payload: {
      clickId,
    },
  };
}

export function webteamStartGameEvent(clickId) {
  return {
    type: WEBTEAM_START_GAME,
    payload: {
      clickId,
    },
  };
}

export function webteamTrialGameCompletedEvent(clickId) {
  return {
    type: WEBTEAM_TRIAL_COMPLETED,
    payload: {
      clickId,
    },
  };
}

export function trackStartDemoButtonClick(place) {
  return {
    type: TRACK_START_DEMO_BUTTON_CLICK,
    payload: {
      place,
    },
  };
}

export function trackClickGetFullAccessLink() {
  return {
    type: TRACK_CLICK_GET_FULL_ACCESS_LINK,
  };
}

export function trackDemoComebackFormSubmit(email) {
  return {
    type: TRACK_DEMO_COMEBACK_FORM_SUBMIT,
    email,
  };
}
