import React from 'react';
import { Link } from 'react-router-dom';

import Content from '../../../../components/Content/index';
import Section from '../../../../components/Section/index';
import H1 from './includes/H1';
import Text from './includes/Text';
import TextBlock from './includes/TextBlock';
import TextWrapper from './includes/TextWrapper';
import URL_CONSTANTS from '../../../../urlConstants';

const PurchaseTermsPage = () => (
  <Section>
    <Content>
      <TextWrapper>
        <TextBlock>
          <H1>Условия покупки игр</H1>
          <Text>
            Доступ к игре предоставляется сроком на 1 год. Доступ до игр осуществляется через персональные аккаунты.
            Покупая любую игру на сайте readom.pro вы соглашаетесь с <Link to={URL_CONSTANTS.URLS.LICENCE}>лицензионным соглашением</Link>,
            а так же даете согласие на <Link to={URL_CONSTANTS.URLS.PROCESSING_PERSONAL_DATA}>обработку персональных данных</Link>.
          </Text>
          <Text>
            По вопросам, связанным с исполнением Соглашения, просьба обращаться по адресу местонахождения
            Лицензиара: Российская Федерация, 141435, Московская обл., г. Химки, Микрорайон Новогорск, ул.
            Заречная, д. 7, корп. 1, кв. 7. Почтовый адрес: 630004 г. Новосибирск, ул. Вокзальная магистраль, 1/1,
            офис 608.
          </Text>
        </TextBlock>
      </TextWrapper>
    </Content>
  </Section>
);

export default PurchaseTermsPage;
