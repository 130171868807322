import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import {
  BUY_CARD_KEY_REQUEST,
} from '../../modules/myGame/constants';

import { getCashInfo } from '../../modules/profile/actions';

import {
  getCardsAll,
  buyCardKeySuccess,
  buyCardKeyFail,
} from '../../modules/myGame/actions';
import {
  makeSelectKeysCountForBuy,
  makeSelectCardKeyProductPrice,
  makeSelectCardIdForBuy,
} from '../../modules/myGame/selectors';

import { apiRequest as createApiRequest } from '../../../utils/request';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* buyCardKeySaga() {
    const url = yield select(makeSelectApiUrl());
    const keysCount = yield select(makeSelectKeysCountForBuy());
    const price = yield select(makeSelectCardKeyProductPrice());
    const cardID = yield select(makeSelectCardIdForBuy());
    const requestURL = `${url}/BuyCardKey/${encodeURIComponent(cardID)}/${encodeURIComponent(keysCount)}/${encodeURIComponent(price.id)}`;

    try {
      yield call(apiRequest, requestURL);

      yield put(buyCardKeySuccess());
    } catch (err) {
      yield put(buyCardKeyFail());
    } finally {
      yield put(getCashInfo());
      yield put(getCardsAll());
    }
  }

  function* saga() {
    yield takeEvery(BUY_CARD_KEY_REQUEST, buyCardKeySaga);
  }

  return saga;
}

export default build;
