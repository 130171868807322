import axios from 'axios';
// import Cookies from 'universal-cookie';
// import get from 'lodash/get';
// import amplitude from 'amplitude-js/amplitude';
// import getAppVersion from './getAppVersion';
// import getDeviceId from './getDeviceId';

// const cookies = new Cookies();
// const TOKEN = process.env.REACT_APP_AMPLITUDE_TOKEN_AJAX;
//
// amplitude.getInstance().init(TOKEN, null, {
//   includeReferrer: true,
//   includeUtm: true,
//   includeGclid: true,
//   batchEvents: true,
//   eventUploadPeriodMillis: 1000,
//   deviceId: getDeviceId(cookies),
// });
// amplitude.setUserId(cookies.get('userID'));

axios.interceptors.request.use((config) => {
  config.metadata = {
    startTime: new Date(),
  };

  return config;
}, error => Promise.reject(error));

// const getUrlParts = (url) => {
//   const baseUrl = `${process.env.REACT_APP_API_URL}/datasnap/rest/TReadom/`;
//
//   const s = url.replace(baseUrl, '');
//   const n = s.indexOf('/');
//   const methodName = s.substring(0, n !== -1 ? n : s.length);
//   const params = s.replace(methodName, '');
//
//   return {
//     methodName,
//     params,
//   };
// };

// const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
// const effectiveType = get(connection, 'effectiveType', '?');

// const collectData = (response) => {
//   response.config.metadata.endTime = new Date();
//   const duration = response.config.metadata.endTime - response.config.metadata.startTime;
//
//   const urlParts = getUrlParts(response.config.url);
//
//   const plainErrorMessage = get(response, 'data.error');
//   let errorMessage = null;
//   if (plainErrorMessage) {
//     errorMessage = plainErrorMessage;
//   }
//
//   const isFail = get(response, 'data.result[0].Result', null) === 'fail';
//
//   if (isFail) {
//     errorMessage = get(response, 'data.result[0].Message', null);
//   }
//
//   const data = {
//     url: response.config.url,
//     params: urlParts.params,
//     time: duration,
//     status: response.status,
//     methodName: urlParts.methodName,
//     effectiveType,
//     errorMessage,
//   };
//
//   // amplitude.getInstance().options.apiEndpoint = 'readom.pro/track/';
//   // amplitude.logEvent('ajax_request_v2', data);
// };

axios.interceptors.response.use((response) => {
  // try {
  //   collectData(response);
  // } catch (e) {
  //   console.error('collectData error');
  // }

  return response;
}, (error) => {
  // if (error.response) {
  //   try {
  //     collectData(error.response);
  //   } catch (e) {
  //     console.error('collectData error');
  //   }
  // }

  return Promise.reject(error);
});

export default axios;
