import * as Yup from 'yup';
import isString from 'lodash/isString';

const PASSWORD_LENGTH = 5;

const RegistrationFormValidateSchema = Yup.object().shape({
  email: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .email('Убедитесь, что поле заполнено верно')
    .required('Поле обязательно для заполнения'),
  password: Yup
    .string()
    .typeError('Укажите пароль')
    .test('length', 'Поле обязательно для заполнения', val => isString(val) && val.length > 0)
    .test('length', 'Пароль должен быть минимум 5 символов', val => isString(val) && val.length >= PASSWORD_LENGTH)
    .required('Поле обязательно для заполнения'),
  passwordConfirm: Yup
    .string()
    .typeError('Введите пароль')
    .test('length', 'Поле обязательно для заполнения', val => isString(val) && val.length > 0)
    .test('length', 'Пароль должен быть минимум 5 символов', val => isString(val) && val.length >= PASSWORD_LENGTH)
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required('Поле обязательно для заполнения'),
  personalProcessingDataAgreement: Yup
    .boolean()
    .typeError('Для регистрации необходимо дать своё согласие на обработку персональных данных')
    .test('marked', 'Для регистрации необходимо дать своё согласие на обработку персональных данных', val => !!val)
    .required('Для регистрации необходимо дать своё согласие на обработку персональных данных'),
});

export default RegistrationFormValidateSchema;
