import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies, Cookies } from 'react-cookie';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import StickyBox from 'react-sticky-box';
import get from 'lodash/get';
import BurgerMenuIcon from '../../../components/BurgerMenuIcon';
import Button from '../../../components/useThis/Button';

import {
  makeSelectTopMenuState,
  makeSelectLocation,
  makeSelectLeftMenuState,
} from '../../../redux/modules/global/selectors';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';
import {
  changeLeftMenuState,
  changeTopMenuState,
  logout,
} from '../../../redux/modules/global/actions';

import BackButton from '../../../components/BackButton';
import Logo from '../../../components/Logo';
import TopMenu from '../../../components/TopMenu';
import UserPhoto from '../../../components/UserPhoto';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import { size } from '../../../utils/mediaQueries';
import GoBackWrapper from './GoBackWrapper';
import H2 from './H2';
import { showFeedBackPopup } from '../../../redux/modules/feedBackForm/actions';
import URL_CONSTANTS from '../../../urlConstants';

const HeaderWrapperStyle = `
  background-color: #343642;
  border-bottom: 1px solid #5B5D66;
  display: flex;
  height: 71px;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 20;
`;

const MobileHeader = styled(StickyBox)`
  ${HeaderWrapperStyle}
  @media (min-width: ${size.toggleMenu}) {
    visibility: hidden;
    display: none;
  }
`;

const DesktopHeader = styled.div`
  ${HeaderWrapperStyle}
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  
  @media (max-width: ${size.toggleMenu}) {
    visibility: hidden;
    display: none;
  }
`;

const BurgerMenuIconWrapper = styled.div`
  position: relative;
  top: 0;
  left: -10px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const LoginButton = () => (
  <LoginButtonWrapper>
    <StyledLink to={URL_CONSTANTS.URLS.LOGIN}>
      <Button component="div">Войти</Button>
    </StyledLink>
  </LoginButtonWrapper>
);

const AuthHeaderItems = (
  {
    pathname, profile, handleTopMenuState, onExit, onFeedBackClick, topMenuState, closeTopMenu,
  },
) => (
  <>
    <UserPhoto
      inHeader
      src={profile.photo}
      name={profile.name}
      surname={profile.surname}
      alt={`${profile.name} ${profile.surname}`}
      handleTopMenuState={handleTopMenuState}
      nickname={profile.email}
    />
    <TopMenu
      pathname={pathname}
      isActive={topMenuState}
      onFeedBackClick={onFeedBackClick}
      toggleTopMenuState={handleTopMenuState}
      closeTopMenu={closeTopMenu}
      onExit={onExit}
    />
  </>
);

class Header extends React.PureComponent {
  handleTopMenuState = () => {
    this.props.onTopMenuClick(!this.props.topMenuState);
    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;

    if (small) {
      this.props.onLeftMenuClick(false);
    }
  };

  closeTopMenu = () => {
    this.props.onTopMenuClick(false);
  };

  burgerMenuIconClick = () => {
    const { leftMenuState, onLeftMenuClick } = this.props;

    onLeftMenuClick(!leftMenuState);
  };

  render() {
    const { cookies } = this.props;
    const isAuthenticated = isUserAuthenticated(cookies);
    const { routeState } = this.props;
    const pathname = get(routeState, 'pathname', '/');

    let goBack = <div />;

    if (pathname === '/payment') {
      goBack = (
        <GoBackWrapper>
          <BackButton to="/">Назад</BackButton>
          <H2>
            Платежная информация
          </H2>
        </GoBackWrapper>
      );
    } else if (pathname === '/change-account-details') {
      goBack = (
        <GoBackWrapper>
          <BackButton to="/payment">Назад</BackButton>
          <H2>
            Изменить реквизиты счета
          </H2>
        </GoBackWrapper>
      );
    } else if (
      (/\/games\/.*/.test(pathname) && !/\/games\/overview\/.*/.test(pathname))
      || /\/finish.*/.test(pathname)
      || /\/login.*/.test(pathname)
      || /\/reset.*/.test(pathname)
      || /\/promo-mvk.*/.test(pathname)
      || /\/start-demo-game.*/.test(pathname)
      || /\/invite.*/.test(pathname)
      || /\/registration.*/.test(pathname)
      || /\/payment\/success.*/.test(pathname)
      || /\/payment\/fail.*/.test(pathname)
      || /\/registration.*/.test(pathname)
      || (pathname === '/' && !isAuthenticated)
      || /\/user-activation.*/.test(pathname)
      || /\/get-full-access.*/.test(pathname)
      || /\/demo-finis.*/.test(pathname)
    ) {
      return null;
    }

    return (
      <>
        <DesktopHeader>
          <Logo />
          {goBack}
          {isAuthenticated && (
            <AuthHeaderItems
              profile={this.props.profile}
              pathname={pathname}
              handleTopMenuState={this.handleTopMenuState}
              closeTopMenu={this.closeTopMenu}
              onExit={this.props.logout}
              onFeedBackClick={this.props.onFeedBackClick}
              topMenuState={this.props.topMenuState}
            />
          )}
          {!isAuthenticated && <LoginButton />}
        </DesktopHeader>
        <MobileHeader>
          {isAuthenticated && (
            <BurgerMenuIconWrapper onClick={this.burgerMenuIconClick}>
              <BurgerMenuIcon open={this.props.leftMenuState} />
            </BurgerMenuIconWrapper>
          )}
          {goBack}
          {isAuthenticated && (
            <AuthHeaderItems
              profile={this.props.profile}
              pathname={pathname}
              handleTopMenuState={this.handleTopMenuState}
              closeTopMenu={this.closeTopMenu}
              onExit={this.props.logout}
              onFeedBackClick={this.props.onFeedBackClick}
              topMenuState={this.props.topMenuState}
            />
          )}
          {!isAuthenticated && <LoginButton />}
        </MobileHeader>
      </>
    );
  }
}

Header.propTypes = {
  topMenuState: PropTypes.bool,
  routeState: PropTypes.object,
  profile: PropTypes.object,
  onFeedBackClick: PropTypes.func,
  onTopMenuClick: PropTypes.func,
  logout: PropTypes.func,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

Header.defaultProps = {
  browser: {
    is: {
      extraSmall: false,
      small: false,
    },
  },
};

export function mapDispatchToProps(dispatch) {
  return {
    onTopMenuClick: newMenuState => dispatch(changeTopMenuState(newMenuState)),
    logout: () => dispatch(logout()),
    onFeedBackClick: () => dispatch(showFeedBackPopup()),
    onLeftMenuClick: newMenuState => dispatch(changeLeftMenuState(newMenuState)),
  };
}

const mapStateToProps = createStructuredSelector({
  routeState: makeSelectLocation(),
  topMenuState: makeSelectTopMenuState(),
  profile: makeSelectProfile(),
  leftMenuState: makeSelectLeftMenuState(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withCookies,
)(Header);
