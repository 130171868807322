import React from 'react';
import styled from 'styled-components';
import pure from 'recompose/pure';
import { darken } from 'polished';

import networkCloudIcon from './network-cloud.svg';

const NetworkCloud = pure(styled.div`
  display: inline-block;
  width: 200px;
  height: 200px;
  background: url(${networkCloudIcon}) no-repeat;
  background-size: contain;
`);

const NetworkCloudRoot = pure(styled.div`
  display: flex;
  justify-content: center;
`);

const Root = pure(styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`);

const TextRoot = pure(styled.div`
  margin-top: 25px;
`);

const Text = pure(styled.div`
  font-size: 24px;
`);

const Button = pure(styled.button`
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 27.5px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  
  &:hover {
      background-color: ${darken(0.1, '#35BB9A')};
      border-color: ${darken(0.1, '#35BB9A')};
    };
`);

const NETWORK_CHECK_INTERVAL = 1000; // раз в секунду проверяем может интернет восстановился

class Offline extends React.PureComponent {
  state = {
    networkCheckTickInterval: null,
  };

  componentDidMount() {
    const networkCheckTickInterval = setInterval(this.checkNetwork, NETWORK_CHECK_INTERVAL);
    this.setState({
      networkCheckTickInterval,
    });
  }

  componentWillUnmount() {
    const { networkCheckTickInterval } = this.state;
    if (networkCheckTickInterval) {
      clearInterval(networkCheckTickInterval);
    }

    this.setState({
      networkCheckTickInterval: null,
    });
  }

  checkNetwork = () => {
    if (navigator.onLine) {
      window.location.reload();
    }
  };

  pageReload = () => {
    window.location.reload();
  };

  render() {
    return (
      <Root>
        <NetworkCloudRoot>
          <NetworkCloud />
        </NetworkCloudRoot>
        <TextRoot>
          <Text>
            Пропало интернет соединение.
          </Text>
          <Text>
            Проверьте подключение и обновите страницу.
          </Text>
          <br />
          <Button onClick={this.pageReload}>
            Обновить страницу
          </Button>
        </TextRoot>
      </Root>
    );
  }
}

export default Offline;
