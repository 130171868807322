import styled from 'styled-components';

const FinishRecomendationRoot = styled.div`
  margin: 0 auto;
  font-family: 'Neucha', sans-serif;
  font-size: 16px;
  font-weight: 300;
  background: #343642;
  border-radius: 35px;
  color: #FFFFFF;
  min-width: 300px;
  max-width: 600px;
`;

export default FinishRecomendationRoot;
