import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledButtonLink = styled(Link)`
  align-items: center;
  border: 1px solid rgba(0, 0, 0, .25);
  border-radius: 25px;
  box-sizing: border-box;
  color: #1C1C1C;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 35px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: border-color .3s ease-out;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:hover {
    border-color: #000000;
  }
`;

export default StyledButtonLink;
