import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledImg = styled.img`
  &.avatar {
    cursor: pointer;
    height: 40px;
    width: 40px;
  }
`;

const Img = props => (
  <StyledImg onClick={props.onClick} className={props.className} src={props.src} alt={props.alt} />
);

Img.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default Img;
