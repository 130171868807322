import { fromJS } from 'immutable';
import {
  REGISTER_REQUEST,
  REGISTER_REQUEST_FAIL,
  REGISTER_REQUEST_SET_ERROR,
  REGISTER_REQUEST_SUCCESS,
} from './constants';

const initialState = fromJS({
  error: null,
  pending: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return state
        .set('pending', true);
    case REGISTER_REQUEST_SUCCESS:
      return state
        .set('error', null)
        .set('pending', false);
    case REGISTER_REQUEST_SET_ERROR:
      return state
        .set('error', action.error)
        .set('pending', false);
    case REGISTER_REQUEST_FAIL:
      return state
        .set('error', null)
        .set('pending', false);
    default:
      return state;
  }
}

export default reducer;
