import PropTypes from 'prop-types';
import React from 'react';

import {
  Field,
} from 'formik';

import FormItem from '../../containers/forms/templates/PopupForm/FormItem';
import renderInput from '../inputs/renderInput';
import renderSelect from '../inputs/renderSelect';

import FormWrapper from './FormWrapper';
import FormLine from './FormLine';

const EmployeeForm = (
  {
    isSubmitting,
    departmentsList,
  },
) => (
  <FormWrapper>
    <FormLine>
      <FormItem>
        <Field
          name="email"
          type="text"
          required
          placeholder="E-mail"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
      <FormItem>
        <Field
          name="surname"
          type="text"
          required
          placeholder="Фамилия"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
    <FormLine>
      <FormItem>
        <Field
          name="name"
          type="text"
          required
          placeholder="Имя"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
      <FormItem>
        <Field
          name="patronymic"
          type="text"
          placeholder="Отчество"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
    <FormLine>
      <FormItem>
        <Field
          name="departmentId"
          required
          options={departmentsList}
          isSearchable={false}
          isClearable={false}
          floatingLabel="Команда"
          placeholder="Выберите команду"
          component={renderSelect}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
  </FormWrapper>
);

EmployeeForm.propTypes = {
  departmentsList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  isSubmitting: PropTypes.bool.isRequired,
};

EmployeeForm.defaultProps = {
  departmentsList: null,
};

export default EmployeeForm;
