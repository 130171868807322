import { SIMPLE_TEXT_CONTENT, STEP_CONTENT } from './constats';

const getOwlContentType = (text) => {
  if (text.indexOf('<STEPGROUP>') !== -1) {
    return STEP_CONTENT;
  }

  return SIMPLE_TEXT_CONTENT;
};

export default getOwlContentType;
