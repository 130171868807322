import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useMedia } from 'the-platform';
import { device } from '../../../utils/mediaQueries';

import Main from '../../../components/Main';

const PublicPageBase = ({ title, children }) => {
  const small = useMedia(device.tablet);

  return (
    <Main small={small}>
      {title && (
        <Helmet
          title={title}
        />
      )}
      {children}
    </Main>
  );
};

PublicPageBase.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PublicPageBase.defaultProps = {
  title: null,
};

export default PublicPageBase;
