import {
  SET_MESSAGE,
  SET_IMAGE,
  SEND_FEED_BACK,
  SEND_FEED_BACK_SUCCESS,
  SEND_FEED_BACK_FAIL,
  SHOW_POPUP,
  SET_GAME_SESSION_ID,
  HIDE_POPUP,
} from './constants';

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message,
  };
}

export function setImage(image) {
  return {
    type: SET_IMAGE,
    image,
  };
}

export function setGameSessionId(gameSessionId) {
  return {
    type: SET_GAME_SESSION_ID,
    gameSessionId,
  };
}

export function sendFeedBack() {
  return {
    type: SEND_FEED_BACK,
  };
}

export function sendFeedBackSuccess() {
  return {
    type: SEND_FEED_BACK_SUCCESS,
  };
}

export function sendFeedBackFail() {
  return {
    type: SEND_FEED_BACK_FAIL,
  };
}

export function showFeedBackPopup() {
  return {
    type: SHOW_POPUP,
  };
}

export function hideFeedBackPopup() {
  return {
    type: HIDE_POPUP,
  };
}
