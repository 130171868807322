export const STORE_NAME = 'clickId';

export const GET_CLICK_ID_REQUEST = 'readom/clickId/CLICK_ID_REQUEST';
export const GET_CLICK_ID_REQUEST_SUCCESS = 'readom/clickId/CLICK_ID_REQUEST_SUCCESS';
export const GET_CLICK_ID_REQUEST_FAIL = 'readom/clickId/CLICK_ID_REQUEST_FAIL';

export const SET_CLICK_ID = 'readom/clickId/SET_CLICK_ID';
export const CHECK_CLICK_ID = 'readom/clickId/CHECK_CLICK_ID';
export const SET_CLICK_ID_REQUEST = 'readom/clickId/SET_CLICK_ID_REQUEST';
export const SET_CLICK_ID_REQUEST_SUCCESS = 'readom/clickId/SET_CLICK_ID_REQUEST_SUCCESS';
export const SET_CLICK_ID_REQUEST_FAIL = 'readom/clickId/SET_CLICK_ID_REQUEST_FAIL';
