import styled from 'styled-components';

const Text = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  margin-top: ${props => (props.marginTop)}px;
`;

export default Text;
