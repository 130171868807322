import styled from 'styled-components';

const Dialog = styled.div`
  user-select: none;
  background: #343642;
  border-radius: 35px;
  color: #FFFFFF;
  font-family: 'Neucha', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  padding: 30px;
  position: relative;
  transform: translate3d(-50%, -100%, 0);
  transition: opacity .3s ease;
  width: 290px;
  z-index: 10;
  left: 46px;
  bottom: 22px;
  & input {
    user-select: all;
  }
  
  ${props => props.owlInCenter && `
    width: 480px;
  `}
`;

export default Dialog;
