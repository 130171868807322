import {
  USER_BLOCKED,
  CREDENTIAL_INVALID,
  NEED_SET_PASSWORD,
  UNKNOWN_ERROR,
  NOT_ACTIVATED,
} from './errorCodes';

const getErrorMessageByErrorCode = (errorCode) => {
  switch (errorCode) {
    case USER_BLOCKED:
      return 'Аккаунт заблокирован. Свяжитесь с нами для уточнения причины блокировки trainer@readom.pro.';
    case CREDENTIAL_INVALID:
      return 'Логин и/или пароль не правильные';
    case NEED_SET_PASSWORD:
      return 'В этот аккаунт ни разу не выполнялся вход. Для активации аккаунта мы отправили письмо со специальной ссылкой. Проверьте свою почту на наличие писем от trainer@mail.readom.pro.';
    case NOT_ACTIVATED:
      return 'В этот аккаунт ни разу не выполнялся вход. Для активации аккаунта мы отправили письмо со специальной ссылкой. Проверьте свою почту на наличие писем от trainer@mail.readom.pro.';
    case UNKNOWN_ERROR:
      return 'В данный момент на сайте ведутся технические работы. Некоторое время сайт будет недоступен. Пожалуйста, зайдите немного позже.';
    default:
      return null;
  }
};

export default getErrorMessageByErrorCode;
