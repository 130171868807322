import filter from 'lodash/filter';
import React from 'react';
import map from 'lodash/map';

import TableCellData from '../../TableCellData';

import AdditionalLine from './AdditionalLine';
import AdditionalRow from './AdditionalRow';

const ExtendedRatingRowData = ({ rating, fields }) => (
  <>
    {map(rating, item => (
      <AdditionalLine>
        {map(
          filter(fields, field => !field.IsSystem),
          field => (
            <AdditionalRow>
              <TableCellData value={item[field.FieldName]} field={field} />
            </AdditionalRow>
          ),
        )}
      </AdditionalLine>
    ))}
    {/*<AdditionalRow>*/}
    {/*  {item.FinishDateTime}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {(isNumber(item.SumDeal) || isString(item.SumDeal)) && item.SumDeal.toLocaleString()}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.Recommendations}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.SetContact >= 14 && <Yes />}*/}
    {/*  {item.SetContact < 14 && <Yes />}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.SetNeed === 1 && <Yes />}*/}
    {/*  {item.SetNeed <= 0 && <Yes />}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.SetPresentation >= 1 && <Yes />}*/}
    {/*  {item.SetPresentation <= 0 && <No />}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.SetKP >= 1 && <Yes />}*/}
    {/*  {item.SetKP <= 0 && <No />}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.Acceleration === 1 && <Yes />}*/}
    {/*  {item.Acceleration <= 0 && <No />}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.SetContact2 === 1 && <Yes />}*/}
    {/*  {item.SetContact2 !== 1 && <No />}*/}
    {/*</AdditionalRow>*/}
    {/*<AdditionalRow>*/}
    {/*  {item.DivideProductPrice === 1 && <Yes />}*/}
    {/*  {item.DivideProductPrice === 0 && <No />}*/}
    {/*</AdditionalRow>*/}
  </>
);

export default ExtendedRatingRowData;
