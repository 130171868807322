import styled from 'styled-components';

const FormColumnLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 0;
`;

export default FormColumnLine;
