import { createSelector } from 'reselect';

const state = state => state;

const makeSelectGameIsLoaded = () => createSelector(
  state,
  (state) => {
    const gameState = state.get('myGame');

    const condition2 = !!gameState.get('answers');

    return condition2;
  },
);

export {
  makeSelectGameIsLoaded,
};
