import { createSelector } from 'reselect';

const selectGlobal = state => state.get('owl');

const makeSelectChannelId = () => createSelector(
  selectGlobal,
  globalState => globalState.get('id'),
);

const makeSelectText = () => createSelector(
  selectGlobal,
  globalState => globalState.get('text'),
);

const makeSelectResult = () => createSelector(
  selectGlobal,
  globalState => globalState.get('result'),
);

const makeSelectOwlVisible = () => createSelector(
  selectGlobal,
  globalState => globalState.get('isVisible'),
);

const makeSelectOwlInCenter = () => createSelector(
  selectGlobal,
  globalState => globalState.get('owlInCenter'),
);

const makeSelectOwlCurrentMessageShown = () => createSelector(
  selectGlobal,
  globalState => globalState.get('currentMessageShown'),
);

export {
  makeSelectOwlCurrentMessageShown,
  makeSelectOwlInCenter,
  makeSelectOwlVisible,
  makeSelectChannelId,
  makeSelectText,
  makeSelectResult,
};
