import styled from 'styled-components';

const Text = styled.span`
  font-family: 'Merriweather', serif;
  font-size: ${props => (props.inHeader ? '14px' : '50px')};
  font-weight: bold;
  color: #FFFFFF;
`;

export default Text;
