import {
  SET_BUY_GAME_POPUP_OPENED,
  SET_BUY_GAME_STAGES_3_6_POPUP_OPENED,
  SET_BUY_GAME_GUEST_POPUP_OPENED,
  SET_START_TRIAL_GAME_WITH_EMAIL_POPUP_OPENED,
  SET_BEFORE_DEMO_LEAVE_POPUP_OPENED,
  SET_THX_POPUP_OPENED,
  SET_START_TRIAL_GAME_WITH_PHONE_POPUP_OPENED,
} from './constants';

export function setBuyGamePopupOpened(opened) {
  return {
    type: SET_BUY_GAME_POPUP_OPENED,
    opened,
  };
}

export function setBuyGameStages36PopupOpened(opened) {
  return {
    type: SET_BUY_GAME_STAGES_3_6_POPUP_OPENED,
    opened,
  };
}

export function setBuyGameGuestPopupOpened(opened) {
  return {
    type: SET_BUY_GAME_GUEST_POPUP_OPENED,
    opened,
  };
}

export function setStartTrialGameWithEmailPopupOpened(opened) {
  return {
    type: SET_START_TRIAL_GAME_WITH_EMAIL_POPUP_OPENED,
    opened,
  };
}

export function setStartTrialGameWithPhonePopupOpened(opened) {
  return {
    type: SET_START_TRIAL_GAME_WITH_PHONE_POPUP_OPENED,
    opened,
  };
}

export function setBeforeDemoLeavePopupOpened(opened) {
  return {
    type: SET_BEFORE_DEMO_LEAVE_POPUP_OPENED,
    opened,
  };
}

export function setThxPopupOpened(opened) {
  return {
    type: SET_THX_POPUP_OPENED,
    opened,
  };
}
