import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectLeftMenuState } from '../../../redux/modules/global/selectors';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';
import Content from '../../../components/Content';
import PageTitle from '../../../components/PageTitle';
import AccountDetailsForm from '../../forms/AccountDetailsForm';
import { changeLeftMenuState } from '../../../redux/modules/global/actions';
import SimplePageBase from '../../PageBase/SimplePageBase';

const PageWrapper = styled.div`
  width: 100%;
`;

class ChangeAccountDetailsPage extends React.PureComponent {
  render() {
    return (
      <SimplePageBase title="Платежная информация">
        <Content>
          <PageWrapper>
            <PageTitle>Расчетный счет *2649</PageTitle>
            <AccountDetailsForm />
          </PageWrapper>
        </Content>
      </SimplePageBase>
    );
  }
}

ChangeAccountDetailsPage.propTypes = {
  leftMenuState: PropTypes.bool,
  onLeftMenuClick: PropTypes.func,
  profile: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLeftMenuClick: newMenuState => dispatch(changeLeftMenuState(newMenuState)),
  };
}

const mapStateToProps = createStructuredSelector({
  leftMenuState: makeSelectLeftMenuState(),
  profile: makeSelectProfile(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(ChangeAccountDetailsPage);
