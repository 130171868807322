import {
  START_TUTORIAL,
  FINISH_TUTORIAL,
} from './constants';

export function setStartTutorialTime(startTime) {
  return {
    type: START_TUTORIAL,
    payload: {
      startTime,
    },
  };
}

export function setFinishTutorialTime(finishTime, stepGroupNumber) {
  return {
    type: FINISH_TUTORIAL,
    payload: {
      finishTime,
      stepGroupNumber,
    },
  };
}
