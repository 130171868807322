import styled from 'styled-components';

const PopupLine = styled.div`
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-between;
  min-height: 95px;
  position: relative;
`;

export default PopupLine;
