import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NormalText from '../../Text';
import SmallPopup from '../../SmallPopup';

const PopupText = styled(NormalText)`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

class GameOnDevelopPopup extends React.PureComponent {
  onSubmit = () => {
    this.props.onClose();
  };

  render() {
    const { gameName } = this.props;
    const mailToLink = `${process.env.REACT_APP_BETA_ACCCESS_EMAIL_LINK}${gameName}`;

    return (
      <SmallPopup
        small={this.props.small}
        title="Игра находится в разработке"
        cancelText={null}
        pending={false}
        submitText="Ок"
        isActive={this.props.open}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
      >
        <PopupText>
          <div>
            <span>
              Мы создаем новую игру <b>{gameName}</b>.
            </span>
            <br />
            <br />
            <span>
              Если вы хотите участвовать в бета-тестирования игры &mdash;
            </span>
            <span>{' '}</span>
            <a href={mailToLink}>напишите нам</a>.
          </div>
        </PopupText>
      </SmallPopup>
    );
  }
}

GameOnDevelopPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  gameName: PropTypes.string.isRequired,
  small: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default GameOnDevelopPopup;
