import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { withCookies } from 'react-cookie';
import { createStructuredSelector } from 'reselect';
import withHandlers from 'recompose/withHandlers';

import withEnterTransition from '../../enhancers/withEnterTransition';
import { buyGame } from '../../redux/modules/guestGameBuy/actions';
import { setBuyGameGuestPopupOpened } from '../../redux/modules/popups/actions';
import { makeSelectBuyGameGuestPopupOpened } from '../../redux/modules/popups/selectors';
import scrollLock from '../../utils/scrollLock';

const mapStateToProps = createStructuredSelector({
  opened: makeSelectBuyGameGuestPopupOpened(),
});

const mapDispatchToProps = dispatch => ({
  setPopupOpened: opened => dispatch(setBuyGameGuestPopupOpened(opened)),
  buyGameAction: ({ values, formik }) => dispatch(buyGame({ values, formik })),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentWillReceiveProps(nextProps) {
    if (!this.props.opened && nextProps.opened) {
      scrollLock.on();
    }

    if (this.props.opened && !nextProps.opened) {
      scrollLock.off();
    }
  },
});

const withOnClose = withHandlers({
  onClose: props => () => {
    props.setPopupOpened(false);
  },
});

const withOnGameBuy = withHandlers({
  onGameBuy: props => (
    {
      email, name, surname, formik,
    },
  ) => {
    const values = { email, name, surname };
    props.buyGameAction({ values, formik });
  },
});

const enhance = compose(
  withCookies,
  withConnect,
  withLifecycle,
  withOnClose,
  withOnGameBuy,
  withEnterTransition,
);

export default enhance;
