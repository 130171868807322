import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import lifecycle from 'recompose/lifecycle';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import withHandlers from 'recompose/withHandlers';

import withEnterTransition from '../../enhancers/withEnterTransition';
import { setStartTrialGameWithPhonePopupOpened } from '../../redux/modules/popups/actions';
import { makeSelectStartTrialGameWithPhonePopupOpened } from '../../redux/modules/popups/selectors';
import scrollLock from '../../utils/scrollLock';

const mapStateToProps = createStructuredSelector({
  opened: makeSelectStartTrialGameWithPhonePopupOpened(),
});

const mapDispatchToProps = dispatch => ({
  setPopupOpened: opened => dispatch(setStartTrialGameWithPhonePopupOpened(opened)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentWillReceiveProps(nextProps) {
    if (!this.props.opened && nextProps.opened) {
      scrollLock.on();
    }

    if (this.props.opened && !nextProps.opened) {
      scrollLock.off();
    }
  },
});

const withOnClose = withHandlers({
  onClose: props => () => {
    props.setPopupOpened(false);
  },
});

// TODO: Зашито название игры
const withOwnProps = withProps(() => ({
  title: <span>Миллионы в&nbsp;твоём кармане</span>,
  subtitle: '',
}));

const enhance = compose(
  pure,
  withOwnProps,
  withConnect,
  withLifecycle,
  withOnClose,
  withEnterTransition,
);

export default enhance;
