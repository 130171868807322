import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useMedia } from 'the-platform';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { changeLeftMenuState } from '../../../redux/modules/global/actions';
import { makeSelectLeftMenuState } from '../../../redux/modules/global/selectors';
import {
  makeSelectProfileIsLoading,
  makeSelectProfile,
  makeSelectProfileAcl,
} from '../../../redux/modules/profile/selectors';
import Main from '../../../components/Main';
import LeftMenu from '../../../components/LeftMenu';
import { device } from '../../../utils/mediaQueries';

const SimplePageBase = (
  {
    onLeftMenuClick, leftMenuState, title, acl, profile, isLoading, children,
  },
) => {
  const toggleLeftMenu = () => {
    onLeftMenuClick(!leftMenuState);
  };

  const small = !useMedia(device.toggleMenu);

  const isEmployeerAdmin = acl.canViewAllCompanyEmployee
    || acl.canEditAllCompanyEmployee
    || acl.canViewOwnDepartmentEmployee
    || acl.canEditOwnDepartmentEmployee;

  return (
    <Main small={small}>
      {title && (
        <Helmet
          title={title}
        />
      )}
      <LeftMenu
        canViewRating={acl.canSeeCompanyExtendedRating || acl.canSeeCompanyRating}
        isEmployer={profile.isEmployer}
        isEmployerAdmin={isEmployeerAdmin}
        isOpened={leftMenuState}
        toggleLeftMenu={toggleLeftMenu}
        isLoading={isLoading}
      />
      {children}
    </Main>
  );
};

SimplePageBase.propTypes = {
  acl: PropTypes.shape({
    canViewAllCompanyEmployee: PropTypes.bool.isRequired,
    canEditAllCompanyEmployee: PropTypes.bool.isRequired,
    canViewOwnDepartmentEmployee: PropTypes.bool.isRequired,
    canEditOwnDepartmentEmployee: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  leftMenuState: PropTypes.bool.isRequired,
  onLeftMenuClick: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    isEmployer: PropTypes.bool.isRequired,
  }),
  title: PropTypes.string,
};

SimplePageBase.defaultProps = {
  profile: {
    isEmployer: false,
  },
  title: null,
  children: null,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLeftMenuClick: newMenuState => dispatch(changeLeftMenuState(newMenuState)),
  };
}

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  leftMenuState: makeSelectLeftMenuState(),
  isLoading: makeSelectProfileIsLoading(),
  browser: makeSelectBrowser(),
  acl: makeSelectProfileAcl(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(SimplePageBase);
