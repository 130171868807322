import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import {
  EDIT_DEPARTMENT_REQUEST,
} from '../../../modules/employees/constants';

import { makeSelectApiUrl } from '../../../modules/global/selectors';
import {
  makeSelectDepartmentForEdit,
} from '../../../modules/employees/selectors';

import {
  getDepartmentList,
  changeActiveTab,
  changeEditDepartmentPopupState,
  editDepartmentSuccess,
  editDepartmentFail,
} from '../../../modules/employees/actions';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* editDepartmentSaga() {
    const url = yield select(makeSelectApiUrl());
    const department = yield select(makeSelectDepartmentForEdit());

    const requestURL = `${url}/DepartmentEdit/${encodeURIComponent(department.id)}/${encodeURIComponent(department.name)}/`;

    try {
      yield call(apiRequest, requestURL);

      yield put(editDepartmentSuccess());
    } catch (err) {
      yield put(editDepartmentFail());
    } finally {
      yield put(changeEditDepartmentPopupState(false));
      yield put(getDepartmentList());
      yield put(changeActiveTab({
        activeTab: 'tabItem_2',
      }));
    }
  }

  function* saga() {
    yield takeLeading(EDIT_DEPARTMENT_REQUEST, editDepartmentSaga);
  }

  return saga;
}

export default build;
