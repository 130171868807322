import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';
import posed from 'react-pose';
import buttonPoseAnimation from '../../globalConstants';

const ButtonPressedAnimation = posed(Link)(buttonPoseAnimation);

const StyledButtonLink = styled(ButtonPressedAnimation)`
  align-items: center;
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 25px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  justify-content: center;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:hover {
    background-color: ${darken(0.1, '#35BB9A')};
    border-color: ${darken(0.1, '#35BB9A')};
  }
`;

export default StyledButtonLink;
