import isNull from 'lodash/isNull';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withProps from 'recompose/withProps';
import withHandlers from 'recompose/withHandlers';
import { withCookies } from 'react-cookie';
import { createStructuredSelector } from 'reselect';
import { setActiveGameId } from '../../../../redux/modules/activeGame/actions';
import { makeSelectActiveGameId } from '../../../../redux/modules/activeGame/selectors';
import { trackBuyGameButtonClicked } from '../../../../redux/modules/analytics/actions';
import { setActiveCardId, setShowLibraryPopup } from '../../../../redux/modules/library/actions';
import { getCardsAll, setShowActionButtonsOnMobile, startNewGame } from '../../../../redux/modules/myGame/actions';
import { setOwlVisible, setOwlCurrentMessageShown, receiveOwlMessage } from '../../../../redux/modules/owl/actions';
import { makeSelectOwlVisible } from '../../../../redux/modules/owl/selectors';
import { setBuyGamePopupOpened } from '../../../../redux/modules/popups/actions';
import { setTarget, setVisible } from '../../../../redux/modules/spotlight/actions';
import { setFinishTutorialTime, setStartTutorialTime } from '../../../../redux/modules/tutorial/actions';

const mapDispatchToProps = dispatch => ({
  setSpotlightTarget: target => dispatch(setTarget(target)),
  startNewGame: () => dispatch(startNewGame()),
  setActiveGameId: gameId => dispatch(setActiveGameId(gameId)),
  setSpotlightVisible: visible => dispatch(setVisible(visible)),
  setOwlVisible: visible => dispatch(setOwlVisible(visible)),
  setOwlCurrentMessageShown: shown => dispatch(setOwlCurrentMessageShown(shown)),
  setFinishTutorialTime: (time, stepGroupNumber) => dispatch(setFinishTutorialTime(time, stepGroupNumber)),
  setStartTutorialTime: time => dispatch(setStartTutorialTime(time)),
  setShowActionButtonsOnMobile: showButtonWrapper => dispatch(setShowActionButtonsOnMobile(showButtonWrapper)),
  trackBuyGameButtonClicked: (showcaseId, where, inGame) => dispatch(trackBuyGameButtonClicked(showcaseId, where, inGame)),
  setBuyGamePopupOpened: opened => dispatch(setBuyGamePopupOpened(opened)),
  getCardsAll: () => dispatch(getCardsAll()),
  setActiveCardId: value => dispatch(setActiveCardId(value)),
  setShowLibraryPopup: value => dispatch(setShowLibraryPopup(value)),
  receiveOwlMessage: text => dispatch(receiveOwlMessage(text)),
});

const mapStateToProps = createStructuredSelector({
  isVisible: makeSelectOwlVisible(),
  activeGameId: makeSelectActiveGameId(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
  withHandlers({
    onGameBuy: props => () => {
      props.setBuyGamePopupOpened(true);
    },
  }),
  withProps((props) => {
    const { stepInfo } = props;
    if (!isNull(stepInfo)) {
      const html = document.createElement('html');
      html.innerHTML = stepInfo;
      const stepGroupNumberElement = html.getElementsByTagName('STEPGROUPNUMBER');
      const stepGroupNumber = stepGroupNumberElement[0].innerHTML;

      const stepElements = html.getElementsByTagName('STEP');

      let buyGame = null;
      const buyGameButton = html.getElementsByTagName('BUTTONBUYGAME');
      if (buyGameButton && buyGameButton[0]) {
        let buyGameText = 'Купить';

        if (buyGameButton[0] && buyGameButton[0].innerHTML) {
          buyGameText = buyGameButton[0].innerHTML;
        }

        buyGame = {
          text: buyGameText,
        };
      }

      const stepElementsCount = stepElements.length;
      const steps = [];
      for (let i = 0; i < stepElementsCount; i++) {
        const currentStepElement = stepElements[i];
        const textElement = currentStepElement.getElementsByTagName('TEXT');
        const buttonTextElement = currentStepElement.getElementsByTagName('BUTTON');

        steps.push({
          text: textElement && textElement[0].innerHTML,
          buttonText: buttonTextElement && buttonTextElement[0].innerHTML,
          buyGame,
        });
      }

      return {
        stepGroupNumber,
        steps,
      };
    }

    return {
      stepElements: null,
      steps: null,
    };
  }),
  withCookies,
);

export default enhance;
