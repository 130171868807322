import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import posed, { PoseGroup } from 'react-pose';
import urlapi from 'url';
import find from 'lodash/find';
import Typograf from 'typograf';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const domain = process.env.REACT_APP_DOMAIN;
const protocol = process.env.REACT_APP_PROTOCOL;
const prefix = `${protocol}://${domain}`;
const apiUrl = process.env.REACT_APP_API_URL;

const Wrapper = styled.div`
  background-color: #000000;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 21;
`;

const Bg = styled.div`
  background-image: url(${props => props.image});
  background-position: ${props => (props.bgPosition ? props.bgPosition : 'center')};
  background-size: cover;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  
  transition: 1s opacity ease-out;
  width: 100vw;
  z-index: 10;

  &:before {
    background-image: linear-gradient(rgba(0, 0, 25, .7), rgba(0, 0, 0, 0));
    content: '';
    display: block;
    height: 50vh;
    position: absolute;
    width: 100vw;
  }

  &:after {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .7));
    bottom: 0;
    content: '';
    display: block;
    height: 50vh;
    position: absolute;
    width: 100vw;
  }
`;

const Question = styled.div`
  color: #FFFFFF;
  font-family: 'Merriweather', serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin: 0 auto 30px;
  padding: 15px 25px;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 1);
  width: 790px;
  z-index: 100;
  user-select: none;
  padding-right: 40px;

  & p {
    margin-top: 0;
  }
  
  @media (max-width: 790px) {
    width: calc(100vw - 40px);
    margin: 0;
    font-size: 20px;
    line-height: normal;
    padding: 15px 0;
    max-width: 500px;
    margin-bottom: 20px;
    
    background-color: rgba(0,0,0, 0.3);
    border-radius: 25px;
    padding: 15px 25px;
    
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
  
  @media (min-width: 790px) {
    padding-right: 200px;
  }
`;

const Img = styled.img`
  display: none;
`;

const Help = styled.div`
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 101;
  color: #FFFFFF;
  font-family: 'Merriweather', serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  user-select: none;
  
  @media (max-width: 790px) {
    display: none;
  }
  
  @media (max-width: 1024px) {
    width: 140px;
  }
`;

const QuestionWrapperAnimation = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    delay: 300,
    transition: {
      y: {
        duration: 500,
      },
      opacity: {
        duration: 300,
      },
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      y: {
        duration: 500,
      },
      opacity: {
        duration: 300,
      },
    },
  },
});

const QuestionWrapper = styled(QuestionWrapperAnimation)`
  bottom: 0;
  left: 40px;
  position: absolute;
  z-index: 101;
  
  @media (max-width: 790px) {
    left: 0;
  }
  
  @media (max-width: 1024px) {
    left: 20px;
  }
`;

// TODO: HARDCODE Зашиты позиции фона на всю страницу
const bgPositions = [
  {
    path: `${apiUrl}/image?namesys=1.jpg`,
    position: '89% center',
  },
  {
    path: `${apiUrl}/image?namesys=2.jpg`,
    position: '39% center',
  },
  {
    path: `${apiUrl}/image?namesys=3.jpg`,
    position: '48% center',
  },
  {
    path: `${apiUrl}/image?namesys=4.jpg`,
    position: '50% center',
  },
  {
    path: `${apiUrl}/image?namesys=6.jpg`,
    position: '70% center',
  },
  {
    path: `${apiUrl}/image?namesys=7.jpg`,
    position: '70% center',
  },
];

class Image extends React.PureComponent {
  state = {
    imageLoaded: false,
    clicked: false,
  };

  onImageClick = () => {
    const { answers, checkAnswer } = this.props;
    const answer = answers[0];
    this.setState({
      clicked: true,
    });
    checkAnswer(answer.id);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.sendAnswerPending && !this.props.sendAnswerPending) {
      this.setState({
        clicked: false,
      });
    }
  };

  loadImage = () => {
    this.setState({
      imageLoaded: true,
    });
  };

  getBgPosition = () => {
    const { src } = this.props;
    const url = urlapi.parse(src);

    if (url.path) {
      const item = find(bgPositions, { path: `${prefix}${url.path}` });
      if (item) {
        return item.position;
      }
    }

    return null;
  };

  render() {
    const bgPosition = this.getBgPosition();

    const { imageLoaded, clicked } = this.state;
    const { src, sendAnswerPending, question } = this.props;

    return (
      <Wrapper>
        <Transition
          in={imageLoaded}
          timeout={0}
        >
          {status => (
            <Bg
              className={`fade fade-${status}`}
              image={src}
              onClick={this.onImageClick}
              bgPosition={bgPosition}
            >
              <PoseGroup>
                {(!sendAnswerPending && !clicked) && (
                  <QuestionWrapper key={question}>
                    <Question
                      dangerouslySetInnerHTML={{ __html: tp.execute(question) }}
                    />
                  </QuestionWrapper>
                )}
              </PoseGroup>
              <Help>
                кликните для продолжения
              </Help>
              <Img
                src={src}
                onLoad={this.loadImage}
              />
            </Bg>
          )}
        </Transition>
      </Wrapper>
    );
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  checkAnswer: PropTypes.func.isRequired,
  answers: PropTypes.array,
};

export default Image;
