import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';

import Dropzone from '../Dropzone';
import EditWrapper from './Edit';
import EmptyImage from './EmptyImage';
import MainWrapper from './MainWrapper';
import Nickname from './Nickname';
import Text from './Text';
import UserPicWrapper from './UserPicWrapper';
import UserPhotoImg from './UserPhotoImg';

class UserPhoto extends React.PureComponent {
  static defaultProps = {
    small: false,
  };

  static propTypes = {
    small: PropTypes.bool,
  };

  render() {
    const { small } = this.props;
    let text = '??';
    try {
      text = toUpper(`${this.props.name.charAt(0)}${this.props.surname.charAt(0)}`);
    } catch (e) {}

    let result = (
      <EmptyImage>
        <Text>{text}</Text>
        <EditWrapper
          onClick={this.props.toggleDropzone}
        />
        <Dropzone
          isActive={this.props.isActiveDragPopup}
          onClose={this.props.toggleDropzone}
          onDrop={this.props.onDrop}
        />
      </EmptyImage>
    );

    if (this.props.inHeader === true) {
      result = (
        <UserPicWrapper onClick={this.props.handleTopMenuState} className="user-pic-header">
          {!small && (<Nickname inGame={this.props.inGame} className="user-pic-header">{this.props.nickname}</Nickname>)}
          <EmptyImage
            className="user-pic-header"
            inHeader
          >
            <Text className="user-pic-header" inHeader>{text}</Text>
          </EmptyImage>
        </UserPicWrapper>
      );
    } else if (this.props.inRating === true) {
      result = (
        <EmptyImage
          inHeader
          onClick={this.props.handleTopMenuState}
        >
          <Text inHeader>{text}</Text>
        </EmptyImage>
      );
    }

    if (!isEmpty(this.props.src)) {
      if (this.props.inHeader === true) {
        result = (
          <UserPicWrapper onClick={this.props.handleTopMenuState} className="user-pic-header">
            {!small && (<Nickname inGame={this.props.inGame} className="user-pic-header">{this.props.nickname}</Nickname>)}
            <UserPhotoImg
              className="avatar user-pic-header"
              src={this.props.src}
              alt={this.props.alt}
            />
          </UserPicWrapper>
        );
      } else if (this.props.inRating === true) {
        result = (
          <UserPhotoImg
            onClick={this.props.handleTopMenuState}
            className="avatar"
            src={this.props.src}
            alt={this.props.alt}
          />
        );
      } else {
        result = (
          <MainWrapper>
            <UserPhotoImg src={this.props.src} alt={this.props.alt} />
            <EditWrapper
              onClick={this.props.toggleDropzone}
            />
            <Dropzone
              isActive={this.props.isActiveDragPopup}
              onClose={this.props.toggleDropzone}
              onDrop={this.props.onDrop}
            />
          </MainWrapper>
        );
      }
    }

    return result;
  }
}

UserPhoto.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  nickname: PropTypes.string,
  handleTopMenuState: PropTypes.func,
  inGame: PropTypes.bool,
  inHeader: PropTypes.bool,
  inRating: PropTypes.bool,
  isActiveDragPopup: PropTypes.bool,
  toggleDropzone: PropTypes.func,
  onDrop: PropTypes.func,
};

export default UserPhoto;
