import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import React from 'react';

const Component = ({ component, ...rest }) => {
  const Button = styled(component)`
  background-color: #35BB9A;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: none;
  border-radius: 25px;
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  letter-spacing: 1px;
  margin-left: ${props => (props.allWidth || props.noMargins ? '0' : '15px')};
  outline: none;
  padding: 0 25px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .15s ease-out;
  width: ${props => (props.allWidth ? '100%' : 'inherit')};
  user-select: none;
  
  &:hover:not(:disabled) {
    background-color: ${darken(0.1, '#35BB9A')};
    border: ${darken(0.1, '#35BB9A')};
  }
  
  ${props => props.isAlternative && `
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .25);
    color: #1C1C1C;
    
    &:hover:not(:disabled) {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 1);
    }
  `}
  
  ${props => props.isWhite && `
    background-color: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, .25);
    color: #F5F5F5;
    
    &:hover:not(:disabled) {
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 1);
    }
  `}
`;

  return (
    <Button
      {...rest}
      className={[
        classNames(rest.className),
        'pressable-button',
      ]}
    />
  );
};

Component.defaultProps = {
  component: 'button',
  isAlternative: false,
  isWhite: false,
  disabled: false,
  allWidth: false,
  noMargins: false,
};

Component.propTypes = {
  isAlternative: PropTypes.bool,
  isWhite: PropTypes.bool,
  allWidth: PropTypes.bool,
  noMargins: PropTypes.bool,
  disabled: PropTypes.bool,
  component: PropTypes.string,
};

export default Component;
