import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import iOS from './isIOS';

const getScrollLockValue = () => parseInt(document.querySelector('html').dataset.scrollLockValue, 10) || 0;

const setScrollLockValue = (value) => {
  document.querySelector('html').dataset.scrollLockValue = value;
};

const upScrollLockValue = () => {
  const currentVal = getScrollLockValue();
  setScrollLockValue(currentVal + 1);
};

const downScrollLockValue = () => {
  const currentVal = getScrollLockValue();
  if (currentVal === 0) {
    return;
  }
  setScrollLockValue(currentVal - 1);
};

const scrollLock = {
  on: () => {
    upScrollLockValue();
    if (iOS()) {
      document.querySelector('body').classList.add('no-scroll');
      document.querySelector('html').classList.add('no-scroll');
    } else {
      disableBodyScroll();
    }
  },
  off: () => {
    downScrollLockValue();
    if (getScrollLockValue() === 0) {
      if (iOS()) {
        document.querySelector('body').classList.remove('no-scroll');
        document.querySelector('html').classList.remove('no-scroll');
      } else {
        enableBodyScroll();
      }
    }
  },
};

export default scrollLock;
