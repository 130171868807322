import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import lifecycle from 'recompose/lifecycle';
import setDisplayName from 'recompose/setDisplayName';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { trackBuyGameButtonClicked, trackStartDemoButtonClick } from '../../redux/modules/analytics/actions';
import {
  setBeforeDemoLeavePopupOpened,
  setBuyGameGuestPopupOpened,
  setStartTrialGameWithPhonePopupOpened,
} from '../../redux/modules/popups/actions';
import URL_CONSTANTS from '../../urlConstants';
import initOnWindowLeave from '../../utils/initOnWindowLeave';

const mapDispatchToProps = dispatch => ({
  trackBuyGameButtonClicked: (showcaseId, where, inGame) => dispatch(trackBuyGameButtonClicked(showcaseId, where, inGame)),
  setBuyGameGuestPopupOpened: opened => dispatch(setBuyGameGuestPopupOpened(opened)),
  trackStartDemoButtonClick: place => dispatch(trackStartDemoButtonClick(place)),
  openStartTrialGameWithEmailPopup: () => dispatch(setStartTrialGameWithPhonePopupOpened(true)),
  setBeforeDemoLeavePopupOpened: opened => dispatch(setBeforeDemoLeavePopupOpened(opened)),
});

const withConnect = connect(null, mapDispatchToProps);

const withOnDemoStartWithoutEmail = withHandlers({
  onDemoStart: props => () => {
    props.history.push(`${URL_CONSTANTS.PREFIXES.START_TRIAL_GAME}/million`);
  },
});

const withOnDemoStartDemoWithEmail = withHandlers({
  onDemoStartWithEmail: props => () => {
    props.openStartTrialGameWithEmailPopup();
  },
});

const enhance = compose(
  pure,
  setDisplayName('PromoPageV2Component_Enhanced'),
  withCookies,
  withConnect,
  withRouter,
  withOnDemoStartWithoutEmail,
  withOnDemoStartDemoWithEmail,
  lifecycle({
    componentDidMount() {
      // initOnWindowLeave({
      //   setBeforeDemoLeavePopupOpened: this.props.setBeforeDemoLeavePopupOpened,
      //   cookies: this.props.cookies,
      //   cookieName: 'showBeforeLeavePopupInGetFullVersionPage',
      // });
    },
  }),
);

export default enhance;
