import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled, { createGlobalStyle } from 'styled-components';
import Observer from '@researchgate/react-intersection-observer';
import find from 'lodash/find';
import get from 'lodash/get';
import StickyBox from 'react-sticky-box';
import ReactDOM from 'react-dom';
import posed, { PoseGroup } from 'react-pose';
import Book from '../../../../components/Book';
import Coin from '../../../../components/Coin';
import { setActiveLibraryLink } from '../../../../redux/modules/library/actions';
import { addCardToQueue, sendRequest } from '../../../../redux/modules/markCardAsViewed/actions';
import scrollLock from '../../../../utils/scrollLock';
import RatingPlus from '../RatingPrus';

import CloseIcon from './CloseIcon';

import {
  Img,
  Name,
  Part,
  PopupBodyHeader,
  PopupText,
  SingleBook,
  Title,
} from '../../../pages/GamePage/includes/styled/PopupStyled';

import Button from '../../../../components/useThis/Button';

const PopupRoot = styled.div`
  padding: 15px 0;
`;

const StickyHeader = styled(StickyBox)`
  z-index: 3;
`;

const PopupInnerGroupAnimation = posed.div({
  showed: {
    opacity: 1,
    delay: 0,
  },
  hidden: {
    opacity: 0,
  },
  transition: {
    y: { type: 'linear', duration: 600 },
    default: { ease: 'linear', duration: 300 },
  },
});

const PopupWrapper = styled.div`
  display: flex;
  left: 0;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  overflow-x: hidden;
  z-index: ${props => (props.open ? '20' : '-1')};
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, .5);
  left: 0;
  height: 100vh;
  opacity: 0.99;
  position: fixed;
  top: 0;
  width: ${props => (props.scrollbarWidth && props.scrollbarWidth > 0 ? `calc(100vw - ${props.scrollbarWidth}px)` : '100vw')};
`;

const PopupInner = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 0;
  margin: auto;
  position: relative;
  width: 100vw;
  max-width: 860px;
  min-height: 1px;
  margin-bottom: 40px;
  margin-top: ${props => (props.small ? '0' : '15px')};
  padding-bottom: 60px;
`;

const PopupHeader = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  flex-direction: column;
  height: ${props => (props.small ? '110px' : '150px')};
  justify-content: center;
  margin: ${props => (props.small ? '0 20px' : '0 130px')};
  position: ${props => props.position};
  top: 0;
  width: calc(100vw - 40px);
  max-width: 860px;
`;

const PopupSubtitle = styled.div`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
`;

const PopupTitle = styled.h1`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: ${props => (props.small ? '20px' : '30px')};
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
`;

const PopupBody = styled.div`
  border-bottom: 1px solid #DEDEDE;
  margin: ${props => (props.small ? '0 20px' : '0 130px')};
  z-index: 1;
`;

const BuyCardItemButtonWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-top: 10px;
`;

const PopupHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
`;

const PopupInnerGroup = styled(PopupInnerGroupAnimation)`
  margin: 0 auto;
  z-index: 50;
`;

const ImgInHeader = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  height: 100px;
  width: 82px;
  margin-left: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row; 
`;

const PopupLifeHackTitle = styled(PopupTitle)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 80px;
`;

const LifeHackTitle = styled(Title)`
  width: 100%;
`;

const LifeHackPopupBodyHeader = styled(PopupBodyHeader)`
  margin: 20px 0;
`;

const LifeHackHr = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #DEDEDE;
  margin-top: 30px;
`;

const GlobalStyle = createGlobalStyle`
  .library-content {
    font-family: 'Merriweather', serif;
    hyphens: auto;

    & p {
      text-align: justify;
      margin-bottom: 20px;
    }
    
    & ul {
      text-align: justify;
    }
    
    & li {
      text-align: justify;
    }
    
    & b {
      font-weight: bold;
      font-size: large;
    }
    
    & h3 {
      text-align: center;
    }
    
    & li {
        padding-bottom: 10px;
    }
  }
`;

const scrollbarWidthCalculate = () => 0;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Space = styled.div`
  width: 10px;
`;

const emptyId = '{00000000-0000-0000-0000-000000000000}';

class LibraryPopup extends React.PureComponent {
  state = {
    scrollbarWidth: 0,
    lastBuyCardAnimation: null,
  };

  cardGroupRefs = [];

  cardRefs = [];

  componentDidMount() {
    if (this.props.open) {
      scrollLock.on();
    }

    this.setState({
      scrollbarWidth: scrollbarWidthCalculate(),
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      scrollLock.on();
      const node = ReactDOM.findDOMNode(this.popupWrapperNode);
      if (node) {
        node.focus();
      }

      const { stage, stagesInfo } = this.props;

      if (stage) {
        const currentStageInfo = find(stagesInfo, { key: stage });

        if (currentStageInfo.GroupCardID && currentStageInfo.GroupCardID !== emptyId) {
          this.scrollToCardGroup(currentStageInfo.GroupCardID);
        } else if (currentStageInfo.CardID && currentStageInfo.CardID !== emptyId) {
          this.scrollToCard(currentStageInfo.CardID);
        }
      }

      if (this.props.activeLibraryLink) {
        this.scrollToLink(this.props.activeLibraryLink);
      } else if (this.props.activeCardId) {
        this.scrollToCard(this.props.activeCardId);
      } else if (this.props.activeCardGroupId) {
        this.scrollToCardGroup(this.props.activeCardGroupId);
      }
    }

    if (prevProps.open && !this.props.open) {
      scrollLock.off();
    }
  }

  componentWillUnmount() {
    if (this.props.open) {
      scrollLock.off();
    }
  }

  scrollToCardGroup = (cardGroupId) => {
    if (this.cardGroupRefs[cardGroupId]) {
      const cardGroupNode = ReactDOM.findDOMNode(this.cardGroupRefs[cardGroupId]);
      if (cardGroupNode) {
        cardGroupNode.scrollIntoView();
      }
    }
  };

  scrollToCard = (cardId) => {
    if (this.cardRefs[cardId]) {
      const cardNode = ReactDOM.findDOMNode(this.cardRefs[cardId]);
      if (cardNode) {
        cardNode.scrollIntoView();
        const { small } = this.props;
        const delta = small ? 110 : 180;
        this.popupWrapperNode.scrollTop = this.popupWrapperNode.scrollTop - delta;
      }
    }
  };

  scrollToLink = (link) => {
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView(true);
      const { small } = this.props;
      const delta = small ? 110 : 180;
      setTimeout(() => {
        this.popupWrapperNode.scrollTop = this.popupWrapperNode.scrollTop - delta;
      }, 100);

      this.props.setActiveLibraryLink(null);
    }
  };

  onClickOutside = (event) => {
    const { id } = event.target;
    if (id === 'popup-overlay') {
      this.props.onClose();
    }
  };

  buyCard = (keyCountForBuy, cardIdForBuy, image) => () => {
    this.props.onBuyCard(keyCountForBuy, cardIdForBuy, image);
  };

  onLastBuyCardAnimationCompleted = () => {
    this.setState({
      lastBuyCardAnimation: this.props.lastBuyCard,
    });
  };

  handleChangeObserver = (cardId, isWatch) => (event, unobserve) => {
    if (isWatch) {
      unobserve();
      return;
    }

    if (event.isIntersecting) {
      const { addCardToMarkAsViewedQueue, sendMasrAsViewedRequest } = this.props;

      addCardToMarkAsViewedQueue(cardId);
      sendMasrAsViewedRequest();
      unobserve();
    }
  };

  render() {
    const { open, libraryCards, small } = this.props;

    return (
      <PopupRoot>
        <PopupWrapper
          tabIndex="0"
          open={open}
          ref={(node) => {
            this.popupWrapperNode = node;
          }}
        >
          <GlobalStyle />
          <PopupInnerGroup pose={open ? 'showed' : 'hidden'}>
            {libraryCards.map((cardGroup) => {
              const { isLifeHack = false } = cardGroup;
              let lifeHackImage = null;

              if (isLifeHack) {
                lifeHackImage = get(find(cardGroup.CardList, { Name: '1' }), 'ImageUrl', null);
              }

              return (
                <PopupInner
                  small={small}
                  key={cardGroup.GroupCardID}
                  ref={(re) => {
                    this.cardGroupRefs[cardGroup.GroupCardID] = re;
                  }}
                >
                  <StickyHeader offsetTop={-1} offsetBottom={200}>
                    <PopupHeaderWrapper>
                      <PopupHeader small={small}>
                        {!isLifeHack && (
                          <React.Fragment>
                            {cardGroup.Title1 && (
                              <PopupSubtitle>
                                {cardGroup.Title1}
                              </PopupSubtitle>
                            )}
                            <PopupTitle small={small}>
                              {cardGroup.Title2}
                            </PopupTitle>
                          </React.Fragment>
                        )}
                        {isLifeHack && (
                          <FlexRow>
                            <ImgInHeader image={lifeHackImage} />
                            <PopupLifeHackTitle>
                              {cardGroup.Title2}
                            </PopupLifeHackTitle>
                          </FlexRow>
                        )}
                        <CloseIcon onClose={this.props.onClose} small={small} />
                      </PopupHeader>
                    </PopupHeaderWrapper>
                  </StickyHeader>
                  <PopupBody small={small}>
                    {cardGroup.CardList.map((card) => {
                      if (isLifeHack) {
                        const available = card.KeyHas >= card.KeyCount;

                        return (
                          <SingleBook
                            key={card.CardID}
                            ref={(re) => {
                              this.cardRefs[card.CardID] = re;
                            }}
                          >
                            <LifeHackPopupBodyHeader>
                              <LifeHackTitle>
                                <Part>
                                  {card.Name}
                                </Part>
                              </LifeHackTitle>
                            </LifeHackPopupBodyHeader>
                            {available && (
                              <PopupText
                                className="library-content"
                                dangerouslySetInnerHTML={{ __html: card.Question }}
                              />
                            )}
                            {!available && (
                              <PopupText>
                                <i>Еще не доступен. Продолжайте играть и он откроется автоматически.</i>
                              </PopupText>
                            )}
                            <LifeHackHr />
                          </SingleBook>
                        );
                      }
                      const available = card.KeyHas >= card.KeyCount || this.props.lastBuyCard === card.CardID;

                      const doAnimation = this.props.lastBuyCard === card.CardID
                        && this.state.lastBuyCardAnimation !== card.CardID && card.Question;

                      if (available) {
                        return (
                          <SingleBook
                            key={card.CardID}
                            ref={(re) => {
                              this.cardRefs[card.CardID] = re;
                            }}
                          >
                            <PopupBodyHeader small={small}>
                              <PoseGroup>
                                {doAnimation && (
                                  <RatingPlus
                                    key={`RatingPlus-${card.CardID}-${Math.random()}`}
                                    onPoseComplete={this.onLastBuyCardAnimationCompleted}
                                  >
                                    + к рейтингу
                                  </RatingPlus>
                                )}
                              </PoseGroup>
                              <Img image={card.ImageUrl} />
                              <Title small={small}>
                                <Part>
                                  {card.Title1}
                                </Part>
                                <Name small={small}>
                                  {card.Title2}
                                </Name>
                              </Title>
                            </PopupBodyHeader>
                            <Observer onChange={this.handleChangeObserver(card.CardID, card.IsWatch)}>
                              <PopupText
                                className="library-content"
                                dangerouslySetInnerHTML={{ __html: card.Question }}
                              />
                            </Observer>
                          </SingleBook>
                        );
                      }

                      const cost = (this.props.price * (card.KeyCount - card.KeyHas)).toLocaleString();

                      return (
                        <SingleBook
                          key={card.CardID}
                          ref={(re) => {
                            this.cardRefs[card.CardID] = re;
                          }}
                        >
                          <PopupBodyHeader withBuyButton small={small}>
                            <Book
                              inPopup
                              backgroundImage={card.ImageUrl}
                              isActive={card.KeyHas >= card.KeyCount || card.CardID === this.props.lastBuyCard}
                              keyCount={card.KeyCount}
                              keyHas={card.KeyHas}
                            />
                            <Title small={small}>
                              <Part>
                                {card.Title1}
                              </Part>
                              <Name small={small}>{card.Title2}</Name>
                            </Title>
                          </PopupBodyHeader>
                          <BuyCardItemButtonWrapper>
                            <Button
                              noMargins
                              onClick={this.buyCard(
                                card.KeyCount - card.KeyHas,
                                card.CardID,
                                card.ImageUrl,
                              )}
                            >
                              <Flex>
                                {`Открыть за ${cost}`}
                                <Space />
                                <Coin size={20} />
                              </Flex>
                            </Button>
                          </BuyCardItemButtonWrapper>
                        </SingleBook>
                      );
                    })}
                  </PopupBody>
                </PopupInner>
              );
            })}
          </PopupInnerGroup>
          <Overlay
            onClick={this.onClickOutside}
            id="popup-overlay"
            scrollbarWidth={this.state.scrollbarWidth}
          />
        </PopupWrapper>
      </PopupRoot>
    );
  }
}

LibraryPopup.propTypes = {
  price: PropTypes.any,
  libraryCards: PropTypes.any,
  lastBuyCard: PropTypes.any,
  stagesInfo: PropTypes.any,
  open: PropTypes.bool,
  small: PropTypes.bool,
  onClose: PropTypes.func,
  scrollToCard: PropTypes.string,
  onBuyCard: PropTypes.func,
  activeCardId: PropTypes.string,
  activeCardGroupId: PropTypes.string,
  activeLibraryLink: PropTypes.string,
  setActiveLibraryLink: PropTypes.func,
  stage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

function mapDispatchToProps(dispatch) {
  return {
    addCardToMarkAsViewedQueue: cardId => dispatch(addCardToQueue(cardId)),
    sendMasrAsViewedRequest: () => dispatch(sendRequest()),
    setActiveLibraryLink: value => dispatch(setActiveLibraryLink(value)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
)(LibraryPopup);
