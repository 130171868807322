import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withCookies, Cookies } from 'react-cookie';

import { Route } from 'react-router';

import URL_CONSTANTS from '../../../urlConstants';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import AppWrapper from '../../App/AppWrapper';
import GamesPage from '../GamesPage';
import PromoPageV2 from '../PromoPageV2';

const IndexPage = ({ cookies }) => {
  const isAuthenticated = isUserAuthenticated(cookies);

  if (isAuthenticated) {
    return (
      <AppWrapper>
        <Route
          exact
          path={URL_CONSTANTS.URLS.GAMES_STORE}
          component={GamesPage}
        />
      </AppWrapper>
    );
  }

  return (
    <Route
      exact
      path={URL_CONSTANTS.URLS.PROMO}
      component={PromoPageV2}
    />
  );
};

IndexPage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default compose(
  withCookies,
)(IndexPage);
