import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import crossSVG from './dark-cross.svg';

const CloseIconWrapper = styled.button`
  cursor: pointer;
  opacity: .8;
  outline: none;
  padding: 10px;
  position: absolute;
  right: 33px;
  background: url(${crossSVG}) no-repeat;
  top: 33px;
  transition: opacity .3s ease-out;
  background-size: contain;
  width: 30px;
  height: 30px;
  z-index: 9999;

  &:hover {
    opacity: 1;
  }
`;

const CloseIcon = props => (
  <CloseIconWrapper onClick={props.onClose} small={props.small} />
);

CloseIcon.propTypes = {
  onClose: PropTypes.func,
  small: PropTypes.bool,
};

export default CloseIcon;
