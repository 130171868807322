import { PASSWORD_INPUT_NAME } from '../../../../redux/modules/forms/employeeSetUserEmailForm/constants';

const validate = (values) => {
  const errors = {};

  if (!values[PASSWORD_INPUT_NAME] || values[PASSWORD_INPUT_NAME].length === 0) {
    errors[PASSWORD_INPUT_NAME] = 'Укажите пароль';
  }

  return errors;
};

export default validate;
