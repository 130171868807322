import find from 'lodash/find';
import get from 'lodash/get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import { setActiveGameId } from '../../../redux/modules/activeGame/actions';
import { makeSelectActiveGameId } from '../../../redux/modules/activeGame/selectors';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { buyShowCase } from '../../../redux/modules/games/actions';
import {
  makeSelectBuyShowCasePending, makeSelectCartCheckoutSuccess,
  makeSelectGames, makeSelectGamesShowcases, makeSelectGetGamesPending, makeSelectInvoices,
  makeSelectShowcaseList,
} from '../../../redux/modules/games/selectors';
import { changeLeftMenuState } from '../../../redux/modules/global/actions';
import { makeSelectLeftMenuState } from '../../../redux/modules/global/selectors';
import {
  buyCardKey,
  changeStavka,
  changeSupergame,
  getGameInfo, setUserEmailRequest, setUserEmailReset,
  startNewGame, startSuperGame,
} from '../../../redux/modules/myGame/actions';
import {
  makeSelectCanContinue,
  makeSelectCardBuyPending, makeSelectCards, makeSelectIsFirstGame,
  makeSelectIsLoading, makeSelectIsLoadingCards,
  makeSelectLastBuyCard, makeSelectCardKeyProductPrice, makeSelectSaveTip,
  makeSelectSetUserEmail,
  makeSelectSetUserEmailPending,
  makeSelectSetUserEmailSuccess, makeSelectStage, makeSelectSuperGame,
} from '../../../redux/modules/myGame/selectors';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';
import { makeSelectCommonGameRatingForActiveGame } from '../../../redux/modules/rating/selectors';

const mapDispatchToProps = dispatch => ({
  onLeftMenuClick: newMenuState => dispatch(changeLeftMenuState(newMenuState)),
  onRenderPage: () => dispatch(getGameInfo()),
  onStartNewGameClick: () => dispatch(startNewGame()),
  onChangeStavka: newStavkaState => dispatch(changeStavka(newStavkaState)),
  onChangeSupergame: newSupergameState => dispatch(changeSupergame(newSupergameState)),
  onBuyCard: card => dispatch(buyCardKey(card)),
  buyShowCase: (showcaseId, nonFree) => dispatch(buyShowCase(showcaseId, nonFree)),
  setUserEmail: email => dispatch(setUserEmailRequest(email)),
  setUserEmailReset: () => dispatch(setUserEmailReset()),
  onStartNewSuperGame: () => dispatch(startSuperGame()),
  setActiveGameId: gameId => dispatch(setActiveGameId(gameId)),
});

const mapStateToProps = createStructuredSelector({
  activeGameId: makeSelectActiveGameId(),
  leftMenuState: makeSelectLeftMenuState(),
  profile: makeSelectProfile(),
  canContinue: makeSelectCanContinue(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
  cardBuyPending: makeSelectCardBuyPending(),
  browser: makeSelectBrowser(),
  showcaseList: makeSelectShowcaseList(),
  buyPending: makeSelectBuyShowCasePending(),
  getGamesPending: makeSelectGetGamesPending(),
  cartCheckoutSuccess: makeSelectCartCheckoutSuccess(),
  invoices: makeSelectInvoices(),
  setUserEmailPending: makeSelectSetUserEmailPending(),
  setUserEmailSuccess: makeSelectSetUserEmailSuccess(),
  setUserEmailValue: makeSelectSetUserEmail(),
  lastBuyCard: makeSelectLastBuyCard(),
  libraryCards: makeSelectCards(),
  saveTip: makeSelectSaveTip(),
  superGame: makeSelectSuperGame(),
  stage: makeSelectStage(),
  isLoadingCards: makeSelectIsLoadingCards(),
  cardKeyPrice: makeSelectCardKeyProductPrice(),
  commonRatingForActiveGame: makeSelectCommonGameRatingForActiveGame(),
  isFirstGame: makeSelectIsFirstGame(),
  isLoading: makeSelectIsLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withOwnProps = withProps((props) => {
  const { activeGameId, allGames } = props;
  const { data, fields, fail, pending, success } = props.commonRatingForActiveGame;

  const currentGameInfo = find(allGames, { GameID: activeGameId }, false);

  const gameOptionsValues = get(currentGameInfo, 'OptionsValues');

  const championConspectus = get(gameOptionsValues, 'championConspectus');
  const superGameAvailable = get(gameOptionsValues, 'superGameAvailable');
  // const showPresettingScreen = get(gameOptionsValues, 'showPresettingScreen');

  return {
    rating: data,
    ratingFields: fields,
    ratingLoadFail: fail,
    ratingPending: pending,
    ratingLoaded: success,
    championConspectus,
    superGameAvailable,
  };
});

const withAllGamesProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const enhance = compose(
  withConnect,
  withAllGamesProps,
  withOwnProps,
);

export default enhance;
