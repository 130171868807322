import { connect } from 'react-redux';

import compose from 'recompose/compose';
import { createStructuredSelector } from 'reselect';
import {
  createChannel as createChannelAction,
  setOwlVisible,
  setOwlInCenter,
  setOwlCurrentMessageShown,
} from '../../../redux/modules/owl/actions';
import {
  makeSelectChannelId,
  makeSelectOwlCurrentMessageShown,
  makeSelectOwlVisible,
  makeSelectText,
} from '../../../redux/modules/owl/selectors';
import { setVisible } from '../../../redux/modules/spotlight/actions';

function mapDispatchToProps(dispatch) {
  return {
    createChannel: () => dispatch(createChannelAction()),
    setOwlVisible: visible => dispatch(setOwlVisible(visible)),
    setOwlInCenter: owlInCenter => dispatch(setOwlInCenter(owlInCenter)),
    setOwlCurrentMessageShown: shown => dispatch(setOwlCurrentMessageShown(shown)),
    setSpotlightVisible: visible => dispatch(setVisible(visible)),
  };
}

const mapStateToProps = createStructuredSelector({
  id: makeSelectChannelId(),
  text: makeSelectText(),
  isVisible: makeSelectOwlVisible(),
  currentMessageShown: makeSelectOwlCurrentMessageShown(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
);

export default enhance;
