import styled from 'styled-components';

const H2 = styled.h2`
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
`;

export default H2;
