import { createSelector } from 'reselect';

const selectProfile = state => state.get('profile');

const makeSelectProfile = () => createSelector(
  selectProfile,
  homeState => homeState.get('profile').toJS(),
);

const makeSelectProfileAcl = () => createSelector(
  selectProfile,
  homeState => homeState.get('profile').get('acl').toJS(),
);

const makeSelectProfileIsLoading = () => createSelector(
  selectProfile,
  homeState => homeState.get('profile').get('isLoading'),
);

const makeSelectCanDebug = () => createSelector(
  selectProfile,
  homeState => homeState.get('profile').get('canDebug'),
);

export {
  makeSelectProfileAcl,
  selectProfile,
  makeSelectProfile,
  makeSelectProfileIsLoading,
  makeSelectCanDebug,
};
