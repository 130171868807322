import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useMedia } from 'the-platform';
import { device } from '../../utils/mediaQueries';
import Coin from '../Coin';

import H2 from '../H2';
import Info from '../Info';
// import RedButton from '../RedButton';
import Button from '../useThis/Button';

const ShortGameWrapper = styled.section`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'space-between')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
`;

const ShortTitle = styled(({ small, ...rest }) => <Link {...rest} />)`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  ${props => (props.small && `
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  `)}
  
  &:hover {
    text-decoration: underline;
    text-decoration-color: #343641;
  }
`;

const Icon = styled.div`
  background-image: url(${props => props.image});
  height: 100px;
  width: 100px;
  display: inline-block;
  background-size: cover;
  ${props => (props.small && 'margin-bottom: 10px;')}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  ${props => (!props.small && 'width: 230px;')}
`;

const MaxWinWrapper = styled.div`
  min-width: 300px;
  padding-bottom: 19px;
`;

const Root = styled.div`
  box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 1px 0 1px rgba(0,0,0,.05), 0 4px 6px 0 rgba(0,0,0,.1);
  padding: 15px;
  border-radius: 15px;
  background: #FCFCFC;
  margin-top: 30px;
`;

const Number = styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: ${props => (props.small ? '40' : '50')}px;
  font-weight: bold;
  line-height: 63px;
  margin: 0;
  text-align: center;
`;

const Text = styled.div`
  align-items: center;
  color: #1C1C1C;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 14px;
  margin: 3px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'flex-end')};
  margin-top: 20px;
  ${props => (props.fullWidth && 'width: 100%;')};
`;

const StatusItem = styled.div`
  min-width: 300px;
  text-align: center;
  // width: ${props => (props.fullWidth ? '100%' : 'calc(100% / 3)')};
`;

const ExpiredWrapper = pure(styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
`);

const ShortGame = (
  {
    gameOverviewUrl, maxsumma, cash, image, name, description, canContinue,
    onContinue, onStartNewGame, licenseExpired,
  },
) => {
  const isExtraSmall = useMedia(device.tablet);
  const isLaptopL = useMedia(device.laptopL);

  return (
    <Root>
      <ShortGameWrapper flexWrap={isLaptopL && 'wrap'} justify={isLaptopL && 'center'}>
        <ShortTitle small={isLaptopL} to={gameOverviewUrl}>
          <Icon image={image} small={isExtraSmall} />
          <TitleWrapper small={isExtraSmall}>
            <H2 bold dangerouslySetInnerHTML={{ __html: name }} />
            <H2 grey>{description}</H2>
          </TitleWrapper>
        </ShortTitle>
        <StatusItem fullWidth={isLaptopL}>
          <Number>{cash}</Number>
          <Text>
            <Coin />
            <Info
              text="Игровые рубли, их&nbsp;можно потратить на&nbsp;доступ к&nbsp;теории, во&nbsp;время игры на&nbsp;подсказки, сохранения. Заработать можно совершая продажи."
            />
          </Text>
        </StatusItem>
        <MaxWinWrapper>
          <Number small={isLaptopL}>
            {maxsumma.toLocaleString()}
          </Number>
          <Text>
            Ваша максимальная сделка
          </Text>
        </MaxWinWrapper>
        {canContinue && !licenseExpired && (
          <ButtonWrapper justify={isLaptopL && 'center'} fullWidth={isLaptopL}>
            <Button onClick={onContinue}>Продолжить игру</Button>
          </ButtonWrapper>
        )}
        {!canContinue && !licenseExpired && (
          <ButtonWrapper justify={isLaptopL && 'center'} fullWidth={isLaptopL}>
            <Button onClick={onStartNewGame}>Открыть игру</Button>
          </ButtonWrapper>
        )}
        {licenseExpired && (
          <ExpiredWrapper>
            <ButtonWrapper justify={isLaptopL && 'center'} fullWidth={isLaptopL}>
              <Button onClick={onStartNewGame}>Купить доступ</Button>
            </ButtonWrapper>
          </ExpiredWrapper>
        )}
      </ShortGameWrapper>
    </Root>
  );
};

ShortGame.propTypes = {
  canContinue: PropTypes.bool.isRequired,
  cash: PropTypes.string.isRequired,
  description: PropTypes.any,
  gameOverviewUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  maxsumma: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onStartNewGame: PropTypes.func.isRequired,
  licenseExpired: PropTypes.bool.isRequired,
};

ShortGame.defaultProps = {
  description: '',
};

export default withRouter(ShortGame);
