import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import Spinner from 'react-spinkit';

import Button from '../useThis/Button/index';

import Error from '../Error/index';
import renderInput from '../../FormikForms/inputs/renderInput';

const FormLineInput = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5px;
    width: 100%;
`;

const SpinnerWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & .spinner {
    width: 40px;
    height: 40px;
  }
`;

const ButtonContainer = styled.div`
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  position: relative;
  width: 100%;
`;

const MemoButton = React.memo(Button);

const customRenderInput = ({ field, form: { errors, touched, submitCount }, ...props }) => {
  const onChange = baseOnChange => (event) => {
    event.target.value = event.target.value.trim();
    baseOnChange(event);
  };

  const modifProps = {
    ...props,
    onChange: onChange(field.onChange),
  };

  return renderInput({ field, form: { errors, touched, submitCount }, ...modifProps });
};

const GetBookPartToEmailForm = (
  {
    values,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    status,
  },
) => (
    <>
      {status && status.serverErrorMessage && (
        <Error>
          {status.serverErrorMessage}
        </Error>
      )}
      <FormLineInput>
        <Field
          name="email"
          type="text"
          required
          disabled={isSubmitting}
          placeholder="E-mail"
          component={customRenderInput}
        />
      </FormLineInput>

      <ButtonContainer>
        {isSubmitting && (
          <SpinnerWrapper>
            <Spinner
              name="circle"
              fadeIn="none"
              className="spinner"
              color="#fff"
            />
          </SpinnerWrapper>
        )}
        <MemoButton allWidth type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          Получить бесплатно
        </MemoButton>
      </ButtonContainer>
    </>
);

export default GetBookPartToEmailForm;
