import React from 'react';
import pure from 'recompose/pure';
import styled, { createGlobalStyle } from 'styled-components';

const ON_AUTOFILL_START_ANIMATION_NAME = 'onAutoFillStart';
const ON_AUTOFILL_CANCEL_ANIMATION_NAME = 'onAutoFillCancel';

const TextInput = styled.input`
  border: 1px solid rgba(0, 0, 0, .25);
  border-color: ${props => (props.isValid ? 'rgba(0, 0, 0, .25)' : '#A01836')};
  border-radius: 5px;
  color: #1C1C1C;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  height: 50px;
  outline: none;
  padding: 0 10px;
  transition: border-color .3s ease-out;
  width: ${props => (props.inForm ? '260px' : '100%')};

  &::placeholder {
    color: #808085;
    opacity: 1;
  }

  &:focus {
    outline:none;
    border-color: #000000;
  }
  
  &:disabled {
    border: 1px dotted rgba(0, 0, 0, 0.25);
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  ${props => props.isFilled && `
    & ~ label {
      color: #1C1C1C;
      transform: translate(10px, -8px);
      font-size: 12px;
      background: white;
      padding: 0 10px;
    }
  `}
  
   &:focus ~ label {
    color: #1C1C1C;
    transform: translate(10px, -8px);
    font-size: 12px;
    background: white;
    padding: 0 10px;
  }
  
  &:focus ~ label {
    color: #1C1C1C;
    transform: translate(10px, -8px);
    font-size: 12px;
    background: white;
    padding: 0 10px;
  }
  
  &:-webkit-autofill {
    animation-name: ${ON_AUTOFILL_START_ANIMATION_NAME};
    transition: background-color 50000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    animation-name: ${ON_AUTOFILL_CANCEL_ANIMATION_NAME};
  }
`;

const InputKeyFramesStyles = pure(createGlobalStyle`
  @keyframes ${ON_AUTOFILL_START_ANIMATION_NAME} { from {} }
  @keyframes ${ON_AUTOFILL_CANCEL_ANIMATION_NAME} { from {} }
`);

const Group = styled.div`
  position: relative;
  width: 100%;
`;

const Root = styled.div`
  padding: 6px 0 0 0;
`;

const Label = styled.label`
  font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 15px;
  color: #808085;
  transform: translate(10px, 15px);
  display: block;
  left: 0;
  margin: 0;
  top: 0;
  padding: 0;
  position: absolute;
  transition: all 0.2s;
  pointer-events: none;
  user-select: none;
`;

const Required = styled.div`
  color: #da4949;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
`;

const RequiredWithLabel = styled.span`
    color: #da4949;
    padding-left: 0.2em;
`;

class Input extends React.PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  state = {
    isAutoFilled: false,
  };

  componentDidMount() {
    if (this.inputRef.current.addEventListener) {
      this.inputRef.current.addEventListener('animationstart', this.onAnimationStart);
    }
  }

  // eslint-disable-next-line consistent-return
  onAnimationStart = (event) => {
    if (event.animationName === ON_AUTOFILL_START_ANIMATION_NAME) {
      return this.onAutoFillStart();
    }

    if (event.animationName === ON_AUTOFILL_CANCEL_ANIMATION_NAME) {
      return this.onAutoFillCancel();
    }
  };

  onAutoFillStart = () => {
    this.setIsAutoFilled(true);
  };

  onAutoFillCancel = () => {
    this.setIsAutoFilled(false);
  };

  setIsAutoFilled = (isAutoFilled) => {
    this.setState({
      isAutoFilled,
    });
  };

  render() {
    const { placeholder, required, ...props } = this.props;

    const { value } = props;
    const { isAutoFilled } = this.state;

    return (
      <Root>
        <InputKeyFramesStyles />
        <Group>
          <TextInput
            {...props}
            ref={this.inputRef}
            placeholder=" "
            isFilled={!!value || isAutoFilled}
          />
          {placeholder && (
            <Label>
              {placeholder}
              {required && (
                <RequiredWithLabel>*</RequiredWithLabel>
              )}
            </Label>
          )}
          {!placeholder && required && (
            <Required>*</Required>
          )}
        </Group>
      </Root>
    );
  }
}

export default Input;
