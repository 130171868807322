import { fromJS } from 'immutable';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import {
  SET_PREV_RATING,
} from './constants';

let preDefinedData = null;
if (typeof localStorage !== 'undefined') {
  preDefinedData = localStorage.getItem('prevRatingForAllGame');
}

let initial = {};

if (!isUndefined(preDefinedData) && isString(preDefinedData)) {
  const data = JSON.parse(preDefinedData);
  if (!isUndefined(data) && !isEmpty(data)) {
    initial = data;
  }
}

const initialState = fromJS({
  prevRatingForAllGame: initial,
});

function prevCompanyGameRating(state = initialState, action) {
  switch (action.type) {
    case SET_PREV_RATING: {
      const { gameId, employeeId, ...rest } = action.payload;

      const newState = state
        .set('prevRatingForAllGame', fromJS({
          gameId,
          employeeId,
          ...rest,
        }));

      localStorage.setItem('prevRatingForAllGame', JSON.stringify(newState.get('prevRatingForAllGame')));

      return newState;
    }
    default:
      return state;
  }
}

export default prevCompanyGameRating;
