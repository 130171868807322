import { fromJS } from 'immutable';

import {
  SET_IMAGE,
  SET_MESSAGE,
  SHOW_POPUP,
  HIDE_POPUP,
  SEND_FEED_BACK,
  SEND_FEED_BACK_SUCCESS,
  SEND_FEED_BACK_FAIL,
  SET_GAME_SESSION_ID,
} from './constants';

const initialState = fromJS({
  message: '',
  image: '',
  gameSessionId: null,
  showPopup: false,
  pending: false,
  success: null,
  fail: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return state
        .set('message', action.message);
    case SET_IMAGE:
      return state
        .set('image', action.image);
    case SET_GAME_SESSION_ID:
      return state
        .set('gameSessionId', action.gameSessionId);
    case SEND_FEED_BACK:
      return state
        .set('pending', true)
        .set('success', null)
        .set('fail', null);
    case SEND_FEED_BACK_SUCCESS:
      return initialState;
    case SEND_FEED_BACK_FAIL:
      return state
        .set('pending', false)
        .set('success', false)
        .set('fail', false);
    case SHOW_POPUP:
      return state
        .set('showPopup', true);
    case HIDE_POPUP:
      return state
        .set('showPopup', false)
        .set('gameSessionId', null);
    default:
      return state;
  }
}

export default reducer;
