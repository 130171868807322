import styled from 'styled-components';

const Tab = styled.div`
  border-bottom: ${props => (props.active ? '10px solid #32CAA3' : 'none')};
  color: ${props => (props.active ? '#FFFFFF' : 'rgba(255, 255, 255, .5)')};
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  height: 60px;
  letter-spacing: .92px;
  line-height: initial;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 20px;
  min-width: 300px;
 
  margin-right: 40px;
  text-transform: uppercase;
  transition: color .3s ease-out;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #FFFFFF;
  }
`;

export default Tab;
