import PropTypes from 'prop-types';
import React from 'react';
import pure from 'recompose/pure';
import styled, { createGlobalStyle } from 'styled-components';
import Typograf from 'typograf';
import Answer from '../../../../../components/Answer';
import AnswerWrapper from '../styled/AnswerWrapper';
import Answers from '../Answers';
import Question from '../styled/Question';

const Q1 = pure(styled.div`
  display: flex;
  justify-content: center;
`);

const Q2 = pure(styled.div`
  max-width: 650px;
  flex: 1;
`);

const TextGlobalStyle = createGlobalStyle`
  html {
    overflow-x: auto;
    overflow-y: scroll;
  }
`;

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const AdditionalWrapper = styled.div`
  width: 100%;
  min-height: 73px;
  padding: 0 0 5px 0;
`;

const Additional = styled.div`
  position: relative;
  top: -27px;
`;

const Main = styled.div`
  position: relative;
  top: -5px;
`;

const BuyGameQuestion = props => (
  <>
    <TextGlobalStyle />
    <Q1>
      <Q2>
        <Question dangerouslySetInnerHTML={{ __html: tp.execute(props.text) }} />
      </Q2>
    </Q1>

    <AnswerWrapper>
      {!props.sendAnswerPending && (
        <AdditionalWrapper>
          <Answer
            crossedOut={false}
            text="Купить"
            id="answer-buy"
            isActive={false}
            checkAnswer={props.onBuyButtonClick}
          />
        </AdditionalWrapper>
      )}
      <Main className="main-game-buy">
        <Answers />
      </Main>
      {!props.sendAnswerPending && (
        <Additional>
          <AdditionalWrapper>
            <Answer
              crossedOut={false}
              text="Задать вопрос"
              id="answer-help"
              isActive={false}
              checkAnswer={props.onHelpClick}
            />
          </AdditionalWrapper>
        </Additional>
      )}
    </AnswerWrapper>
  </>
);

BuyGameQuestion.propTypes = {
  onBuyButtonClick: PropTypes.func.isRequired,
  onHelpClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default BuyGameQuestion;
