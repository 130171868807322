import { fromJS } from 'immutable';
import { device } from '../../../utils/mediaQueries';

import {
  CHANGE_LEFT_MENU_STATE,
  CHANGE_TOP_MENU_STATE,
  CHANGE_POPUP_STATE,
  CHANGE_DROPZONE_STATE,
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_IS_FAIL,
  CHANGE_RESET_LOGIN,
  CHANGE_RESET_IS_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_REQUEST,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAIL,
  SHOW_LOADING,
  HIDE_LOADING,
  LOGIN_FAIL,
  RESET_RESET_PASSWORD_STATE,
  CHANGE_RIGHT_GAME_MENU_STATE,
  START_TRIAL_GAME,
  START_TRIAL_GAME_FAIL,
  START_TRIAL_GAME_SUCCESS,
} from './constants';

const apiUrl = `${process.env.REACT_APP_API_URL}/datasnap/rest/TReadom`;

let leftMenuIsOpened = false;
if (typeof window !== 'undefined') {
  leftMenuIsOpened = !window.matchMedia(device.mobileL).matches;
}

// The initial state of the App
const initialState = fromJS({
  leftMenuIsOpened,
  rightGameMenuIsOpened: false,
  topMenuIsOpened: false,
  popupIsOpened: false,
  dropzoneIsOpened: false,
  userSession: {
    id: false,
    isEmployee: false,
    isFail: false,
  },
  apiUrl,
  spinnerIsShowed: false,
  login: false,
  password: false,
  loginErrorMessage: null,
  loginErrorCode: null,
  loginPending: false,
  resetLogin: null,
  resetWasSend: false,
  resetLoading: false,
  startTrialPending: false,
  startTrialFail: false,
  startTrialSuccess: false,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case START_TRIAL_GAME:
      return state
        .set('startTrialPending', true)
        .set('startTrialFail', false)
        .set('startTrialSuccess', false);
    case START_TRIAL_GAME_FAIL:
      return state
        .set('startTrialPending', false)
        .set('startTrialFail', true)
        .set('startTrialSuccess', false);
    case START_TRIAL_GAME_SUCCESS:
      return state
        .set('startTrialPending', false)
        .set('startTrialFail', false)
        .set('startTrialSuccess', true);
    case LOGIN_REQUEST:
      return state
        .set('loginPending', true)
        .set('loginErrorMessage', null)
        .set('loginErrorCode', null)
        .setIn(['userSession', 'id'], false)
        .setIn(['userSession', 'isEmployee'], false)
        .setIn(['userSession', 'isFail'], false);
    case LOGIN_SUCCESS:
      return state
        .set('loginPending', false)
        .set('password', null)
        .set('loginErrorMessage', null)
        .set('loginErrorCode', null)
        .setIn(['userSession', 'id'], action.id)
        .setIn(['userSession', 'isEmployee'], action.isEmployee);
    case LOGIN_FAIL:
      return state
        .set('loginPending', false)
        .set('loginErrorMessage', action.payload.errorMessage)
        .set('loginErrorCode', action.payload.errorCode)
        .setIn(['userSession', 'id'], false)
        .setIn(['userSession', 'isEmployee'], false)
        .setIn(['userSession', 'isFail'], true);
    case RESET_REQUEST:
      return state
        .set('resetWasSend', false)
        .set('resetLoading', true)
        .setIn(['userSession', 'isFail'], false);
    case RESET_REQUEST_SUCCESS:
      return state
        .set('resetWasSend', true)
        .set('resetLoading', false);
    case RESET_RESET_PASSWORD_STATE:
      return state
        .set('resetWasSend', false)
        .set('resetLogin', null);
    case RESET_REQUEST_FAIL:
      return state
        .set('resetWasSend', false)
        .set('resetLoading', false)
        .setIn(['userSession', 'isFail'], true);
    case CHANGE_LEFT_MENU_STATE:
      return state
        .set('leftMenuIsOpened', action.state);
    case CHANGE_RIGHT_GAME_MENU_STATE:
      return state
        .set('rightGameMenuIsOpened', action.state);
    case CHANGE_TOP_MENU_STATE:
      return state
        .set('topMenuIsOpened', action.state);
    case CHANGE_POPUP_STATE:
      return state
        .set('popupIsOpened', action.state);
    case CHANGE_DROPZONE_STATE:
      return state
        .set('dropzoneIsOpened', action.state);
    case CHANGE_LOGIN:
      return state
        .set('login', action.login)
        .set('loginErrorMessage', null)
        .set('loginErrorCode', null);
    case CHANGE_PASSWORD:
      return state
        .set('password', action.password)
        .set('loginErrorMessage', null)
        .set('loginErrorCode', null);
    case CHANGE_IS_FAIL:
      return state
        .setIn(['userSession', 'isFail'], action.isFail);
    case CHANGE_RESET_LOGIN:
      return state
        .set('resetLogin', action.login);
    case CHANGE_RESET_IS_FAIL:
      return state
        .setIn(['userSession', 'isFail'], action.isFail);
    case LOGOUT:
      return state;
    case SHOW_LOADING:
      return state
        .set('spinnerIsShowed', true);
    case HIDE_LOADING:
      return state
        .set('spinnerIsShowed', false);
    default:
      return state;
  }
}

export default appReducer;
