import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import React from 'react';
import StickyBox from 'react-sticky-box';
import styled from 'styled-components';
import { useMedia } from 'the-platform';
import map from 'lodash/map';
import ExtendedRatingRow from '../../../../components/ExtendedRatingRow';
import TableCellData from '../../../../components/TableCellData';

import { device } from '../../../../utils/mediaQueries';
import RowHeader from './RowHeader';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  ${props => props.minWidth && props.small && `min-width: ${props.minWidth};`}
`;

const Line = styled.div`
  align-items: center;
  ${props => (props.clickable && 'cursor: pointer;')}
  border-bottom: 1px solid #E6E6E6;
  border-left: ${props => (props.isMyRating ? '1px solid #FFFFFF' : 'none')};
  border-right: ${props => (props.isMyRating ? '1px solid #FFFFFF' : 'none')};
  display: flex;
  height: 60px;
  padding: 30px;
  width: 100%;
  position: relative;
  
  ${props => props.outOfList && `
    margin-top: 60px;
    
    &:before {
      content: 'Совершайте продажи в игре и открывайте методички, чтобы подняться в рейтинге!';
      display: block;
      width: calc(100% + 2px);
      height: 60px;
      position: absolute;
      top: -60px;
      left: -1px;
      text-align: center;
      background: #EBEBED;
      line-height: 60px;
      font-weight: bold;
      font-family: 'Merriweather',serif;
      font-size: 16px;
    }
  `}
  
  ${props => props.isChampion && '/* background-color: #82c682; */'}

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
`;

const Row = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  margin-right: 15px;
  width: ${props => `calc(100% * ${props.width - 15}/1170)`};

  &:last-child {
    margin-left: auto;
    margin-right: 0;
    width: ${props => `calc(100% * ${props.width}/1170)`};
  }
`;

const StickyBoxLine = styled(StickyBox)`
  background-color: #fff;
  z-index: 1;
`;

const Table = (props) => {
  let tableItems = <div />;

  const small = useMedia(device.toggleMenu);

  const doToggleAdditionalLine = employeeId => () => {
    const { toggleAdditionalLine } = props;
    if (isFunction(toggleAdditionalLine)) {
      toggleAdditionalLine(employeeId);
    }
  };

  const { extendedRatingForEmployeeId, toggleAdditionalLine } = props;

  if (!isNull(props.items)) {
    tableItems = props.items.map(line => (
      <div>
        <Line
          key={line.key}
          outOfList={line.outOfList}
          isMyRating={line.isMyRating}
          id={`user-in-common-rating-${line.key}`}
          isChampion={line.isChampion}
          clickable={isFunction(toggleAdditionalLine)}
          onClick={doToggleAdditionalLine(line.key)}
        >
          {map(line.items, item => (
            <Row
              key={item.key}
              width={item.width}
            >
              <TableCellData
                field={item.field}
                value={item.value}
              />
            </Row>
          ))}
        </Line>
        {line.key === extendedRatingForEmployeeId && (
          <ExtendedRatingRow employeeId={extendedRatingForEmployeeId} />
        )}
      </div>
    ));
  }

  const StickyBoxLineProps = {};
  if (!small) {
    StickyBoxLineProps.offsetTop = 120;
  }

  const Wrapper = small ? React.Fragment : StickyBoxLine;

  return (
    <TableWrapper minWidth={props.minWidth} small={small}>
      <Wrapper {...StickyBoxLineProps}>
        <Line>
          {props.header.map(headerItem => (
            <RowHeader
              key={headerItem.key}
              width={headerItem.width}
              tooltip={headerItem.tooltip}
            >
              {headerItem.title}
            </RowHeader>
          ))}
        </Line>
      </Wrapper>
      {tableItems}
    </TableWrapper>
  );
};

Table.propTypes = {
  header: PropTypes.array,
  items: PropTypes.array,
  small: PropTypes.bool,
};

export default Table;
