export const CREATE_CHANNEL_REQUEST = 'readom/Owl/CREATE_CHANNEL_REQUEST';
export const CREATE_CHANNEL_SUCCESS = 'readom/Owl/CREATE_CHANNEL_SUCCESS';
export const CREATE_CHANNEL_FAIL = 'readom/Owl/CREATE_CHANNEL_FAIL';

export const CHANNEL_TEXT_REQUEST = 'readom/Owl/CHANNEL_TEXT_REQUEST';
export const CHANNEL_TEXT_SUCCESS = 'readom/Owl/CHANNEL_TEXT_SUCCESS';
export const CHANNEL_TEXT_FAIL = 'readom/Owl/CHANNEL_TEXT_FAIL';
export const SET_OWL_VISIBLE = 'readom/Owl/SET_OWL_VISIBLE';
export const SET_OWL_IN_CENTER = 'readom/Owl/SET_OWL_IN_CENTER';
export const SET_OWL_CURRENT_MESSAGE_SHOWN = 'readom/Owl/SET_OWL_CURRENT_MESSAGE_SHOWN';

export const START_OWL_LISTEN = 'readom/Owl/START_OWL_LISTEN';
export const RECEIVE_OWL_MESSAGE = 'readom/Owl/RECEIVE_OWL_MESSAGE';
