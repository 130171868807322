import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import get from 'lodash/get';
import { createStructuredSelector } from 'reselect';
import { onOnlineHelpButtonClick } from '../../../../../components/FabChat/actions';
import { makeSelectOwnedGameStage } from '../../../../../redux/modules/buyGame/selectors';
import { checkAnswer } from '../../../../../redux/modules/myGame/actions';
import {
  makeSelectAnswers,
  makeSelectFinalCupImage,
  makeSelectImage,
  makeSelectQuestion, makeSelectSendAnswerPending,
} from '../../../../../redux/modules/myGame/selectors';
import { setBuyGamePopupOpened } from '../../../../../redux/modules/popups/actions';
import decodeHtmlEntities from '../../../../../utils/decodeHtmlEntities';

const mapStateToProps = createStructuredSelector({
  question: makeSelectQuestion(),
  answers: makeSelectAnswers(),
  image: makeSelectImage(),
  finalCupImage: makeSelectFinalCupImage(),
  sendAnswerPending: makeSelectSendAnswerPending(),
  ownedStage: makeSelectOwnedGameStage(),
});

const mapDispatchToProps = dispatch => ({
  setBuyGamePopupOpened: opened => dispatch(setBuyGamePopupOpened(opened)),
  sendAnswer: answer => dispatch(checkAnswer(answer)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentDidMount() {
    if (this.props.ownedStage >= this.props.purchaseWindow) {
      const answerId = get(this.props.answers, '[0].id');
      this.props.sendAnswer({
        answers: this.props.answers.map(answer => ({
          id: answer.id,
          text: answer.text,
          isActive: answer.id === answerId,
          additional: answer.id === answerId,
        })),
        sendAnswers: true,
      });
    } else {
      this.props.setBuyGamePopupOpened(true);
    }
  },
});

const withOwnProps = withProps((props) => {
  const { question } = props;
  const html = document.createElement('html');
  html.innerHTML = decodeHtmlEntities(question);
  const textElements = html.getElementsByTagName('TEXT');
  const text = textElements[0].innerHTML;

  const purchaseWindowElements = html.getElementsByTagName('purchase_window');
  const purchaseWindow = parseInt(purchaseWindowElements[0].innerHTML, 10);
  return {
    text,
    purchaseWindow,
  };
});

const withOwnHandlers = withHandlers({
  onBuyButtonClick: props => () => {
    props.setBuyGamePopupOpened(true);
  },
  onHelpClick: props => () => {
    onOnlineHelpButtonClick();
  },
});

const enhance = compose(
  withConnect,
  withOwnProps,
  withOwnHandlers,
  withLifecycle,
);

export default enhance;
