export const TRACK_BUY_GAME_BUTTON_CLICKED = 'readom/Analytics/TRACK_BUY_GAME_BUTTON_CLICKED';

export const TRACK_ERROR_WHEN_BUY_GAME_AS_GUEST = 'readom/Analytics/TRACK_ERROR_WHEN_BUY_GAME_AS_GUEST';
export const TRACK_REDIRECT_USER_TO_PAYMENT_GATE = 'readom/Analytics/TRACK_REDIRECT_USER_TO_PAYMENT_GATE';

export const TRACK_BUY_GAME_FAIL = 'readom/Analytics/TRACK_BUY_GAME_FAIL';
export const TRACK_OPEN_LIBRARY_IN_GAME_OVERVIEW = 'readom/Analytics/TRACK_OPEN_LIBRARY_IN_GAME_OVERVIEW';
export const TRACK_OPEN_LIBRARY_IN_GAME = 'readom/Analytics/TRACK_OPEN_LIBRARY_IN_GAME';
export const TRACK_START_DEMO_BUTTON_CLICK = 'readom/Analytics/TRACK_START_DEMO_BUTTON_CLICK';
export const TRACK_CLICK_GET_FULL_ACCESS_LINK = 'readom/Analytics/TRACK_CLICK_GET_FULL_ACCESS_LINK';
export const TRACK_VIDEO_CLICK = 'readom/Analytics/TRACK_VIDEO_CLICK';
export const TRACK_I_AM_INTERESTED_BUTTON_CLICK = 'readom/Analytics/TRACK_I_AM_INTERESTED_BUTTON_CLICK';
export const TRACK_DEMO_COMEBACK_FORM_SUBMIT = 'readom/Analytics/TRACK_DEMO_COMEBACK_FORM_SUBMIT';


export const WEBTEAM_REGISTRATION = 'readom/WEBTEAM/WEBTEAM_REGISTRATION';
export const WEBTEAM_START_GAME = 'readom/WEBTEAM/WEBTEAM_START_GAME';
export const WEBTEAM_TRIAL_COMPLETED = 'readom/WEBTEAM/WEBTEAM_TRIAL_COMPLETED';
