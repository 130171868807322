import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCurrentGameProgress,
  makeSelectGameProgressList,
} from '../../../../redux/modules/myGame/selectors';


const mapStateToProps = createStructuredSelector({
  currentProgress: makeSelectCurrentGameProgress(),
  progressList: makeSelectGameProgressList(),
});

const withConnect = connect(mapStateToProps);

const enhance = compose(
  withConnect,
);

export default enhance;
