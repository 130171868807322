import PropTypes from 'prop-types';
import React from 'react';
import isUndefined from 'lodash/isUndefined';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import pure from 'recompose/pure';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import { Base64 } from 'js-base64';
import posed, { PoseGroup } from 'react-pose';
import RatingPlus from '../../containers/LibraryInPopup/includes/RatingPrus';
import ActiveKey from '../Book/activeKey.svg';
import InactiveKey from '../Book/inactiveKey.svg';
import NewIconSvg from '../../assets/icons/new.svg';

const Root = pure(styled.div`
  width: 100%;
`);

const Cards = pure(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`);

const getDelta = (receiveKeyId, data) => {
  const receivedKey = find(data.receivedKeys, { id: receiveKeyId });
  const card = find(data.cards, { cardId: receivedKey.toCardId });
  if (isUndefined(card)) {
    return { deltaX: 0, deltaY: 0 };
  }
  const toKey = find(card.keys, { id: receivedKey.toKeyId });

  const keyOnBookRef = toKey.keyOnBookRef.current;
  const rect1 = keyOnBookRef.getBoundingClientRect();
  const x1 = rect1.x + Math.ceil(rect1.width / 2) - 17;
  const y1 = rect1.y;

  const receivedKeyRef = receivedKey.receivedKeyRef.current;
  if (!receivedKeyRef) {
    return 0;
  }
  const rect2 = receivedKeyRef.getBoundingClientRect();
  const x2 = rect2.x;
  const y2 = rect2.y;

  const deltaX = x2 - x1;
  const deltaY = y2 - y1;

  return { deltaX, deltaY };
};

const InactiveWrapperAnimations = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 300 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 300 },
  },
});

const NewIconAnimation = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 300 },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: { duration: 300 },
  },
});

const ReceivedKeysAnimation = posed.div({
  hidden: {
    opacity: 0,
  },
  still: {
    delay: 300,
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
  },
  move: {
    x: ({ receiveKeyId, data }) => {
      const { deltaX } = getDelta(receiveKeyId, data);

      return -deltaX;
    },
    y: ({ receiveKeyId, data }) => {
      const { deltaY } = getDelta(receiveKeyId, data);

      return -deltaY - 24;
    },
    scale: 0.6,
    transition: {
      ease: 'easeOut',
      delay: 300,
      duration: 800,
    },
  },
  finish: {
    delay: 200,
    duration: 500,
    opacity: 0,
    applyAtEnd: { display: 'none' },
  },
});

const ReceivedKeys = pure(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 37px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 20px;
  z-index: 3;
`);

const ReceivedKeysRoot = pure(styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
`);

const ReceivedKey = styled.div`
  background-image: url(${ActiveKey});
  background-position: center;
  background-size: 23px 33px;
  height: 37px;
  width: 44px;
  position: absolute;
  left: 0;
  top: 0;
`;

const InactiveWrapper = styled(InactiveWrapperAnimations)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-right: 16px;
`;

const CardAnimation = posed.div({
  hoverable: ({ opened }) => opened,
  init: {
    scale: 1,
    y: 0,
  },
  hover: {
    scale: 1.1,
  },
});

const Card = styled(CardAnimation)`
  width: 150px;
  height: 170px;
  background-size: contain;
  position: relative;
  transition: 0.5s opacity;
  cursor: pointer;
  
  &:after {
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: contain;
    bottom: 0;
    content: "";
    left: 0;
    opacity: ${({ opened }) => (opened ? 1 : 0.5)};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

const KeysWrapper = styled.div`
  background-color: #EBEBED;
  border-radius: 15px;
  display: flex;
  height: 30px;
  position: relative;
  z-index: 2;
  top: -18px;
`;

const Key = styled.div`
  background-color: ${props => (props.active ? '#31A018' : '#EBEBED')};
  background-image: url(${props => (props.active ? ActiveKey : InactiveKey)});
  background-position: center;
  transition: 0.5s background-color;
  background-size: 12px 33px;
  border-right: 1px solid #EBEBED;
  width: 22px;

  &:first-child {
    border-radius: 15px 0 0 15px;
  }

  &:last-child {
    border-radius: 0 15px 15px 0;
  }

  &:only-child {
    border-radius: 15px;
    width: 42px;
  }
`;

const NewIcon = styled(NewIconAnimation)`
  background-image: url(${NewIconSvg});
  width: 40px;
  height: 40px;
  position: absolute;
  top: -5px;
  left: 90px;
  background-size: contain;
  z-index: 3;
`;

class OpenedCards extends React.PureComponent {
  static propTypes = {
    keyRef: PropTypes.object.isRequired,
  };

  state = {
    startKeyAnimation: false,
    finishKeyAnimation: false,
    showKeys: false,
    keyRefTop: 0,
    keyRefLeft: 0,
  };

  componentDidMount() {
    const { keyRef } = this.props;

    const keyRect = keyRef.current.getBoundingClientRect();
    const top = keyRect.y + Math.ceil(keyRect.height / 2) - Math.ceil(37 / 2);
    const left = keyRect.x + Math.ceil(keyRect.width / 2) - Math.ceil(44 / 2);

    this.setState({
      startKeyAnimation: false,
      showKeys: true,
      keyRefTop: top,
      keyRefLeft: left,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showKeys && this.state.showKeys) {
      this.setState({
        startKeyAnimation: true,
      });
    }
  }

  onCardClick(cardId) {
    window.location.href = `${this.props.gameUrl}?cardId=${Base64.encode(cardId)}`;

    return false;
  }

  onReceivedKeyPoseComplete = (pose) => {
    if (pose === 'move') {
      this.setState({
        startKeyAnimation: false,
        finishKeyAnimation: true,
      });
    }
  };

  getKeyPose = () => {
    const { startKeyAnimation, finishKeyAnimation, showKeys } = this.state;

    if (!showKeys) {
      return 'hidden';
    }

    if (startKeyAnimation) {
      return 'move';
    }

    if (finishKeyAnimation) {
      return 'finish';
    }

    return 'still';
  };

  render() {
    const { data } = this.props;
    const { cards, receivedKeys } = data;
    const { keyRef } = this.props;
    const { finishKeyAnimation } = this.state;
    const { keyRefTop, keyRefLeft } = this.state;

    return (
      <Root>
        <ReceivedKeysRoot top={keyRefTop} left={keyRefLeft}>
          <ReceivedKeys>
            <PoseGroup>
              {map(receivedKeys, receivedKey => (
                <ReceivedKeysAnimation
                  keyRef={keyRef}
                  key={receivedKey.id}
                  receiveKeyId={receivedKey.id}
                  data={data}
                  pose={this.getKeyPose(receivedKey.id)}
                  onPoseComplete={this.onReceivedKeyPoseComplete}
                >
                  <ReceivedKey
                    ref={receivedKey.receivedKeyRef}
                  />
                </ReceivedKeysAnimation>
              ))}
            </PoseGroup>
          </ReceivedKeys>
        </ReceivedKeysRoot>
        <Cards>
          {map(cards, (card) => {
            const openedKeysCardCount = reduce(card.keys, (count, item) => {
              if (item.willOpen || item.open) {
                return count + 1;
              }

              return count;
            }, 0);

            const newCard = reduce(card.keys, (count, item) => {
              if (item.willOpen) {
                return count + 1;
              }

              return count;
            }, 0) > 0;

            const totalKeysCount = card.keys && card.keys.length;
            const opened = openedKeysCardCount === totalKeysCount && finishKeyAnimation;

            if (!card.image) {
              return null;
            }

            return (
              <Card
                key={card.cardId}
                imageUrl={card.image}
                opened={opened}
                onClick={this.onCardClick.bind(this, card.cardId)}
              >
                <PoseGroup>
                  {!opened && (
                    <InactiveWrapper key="InactiveWrapper">
                      <KeysWrapper>
                        {map(card.keys, ({
                          id, open, willOpen, keyOnBookRef,
                        }) => (
                          <Key
                            key={id}
                            active={open || (willOpen && finishKeyAnimation)}
                            ref={keyOnBookRef}
                          />
                        ))}
                      </KeysWrapper>
                    </InactiveWrapper>
                  )}
                  {opened && newCard && (<NewIcon key="NewIcon" />)}
                  {opened && newCard && (
                    <RatingPlus
                      key={`RatingPlus-${card.cardId}}`}
                    >
                      + к рейтингу
                    </RatingPlus>
                  )}
                </PoseGroup>
              </Card>
            );
          })}
        </Cards>
      </Root>
    );
  }
}

export default withRouter(OpenedCards);
