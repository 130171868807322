import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import pure from 'recompose/pure';
import animateScrollTo from 'animated-scroll-to';

import LoadingIndicator from '../../../../components/LoadingIndicator';

import {
  makeSelectSendAnswerPending,
  makeSelectAnswers,
  makeSelectMethodType,
} from '../../../../redux/modules/myGame/selectors';

import Answer from '../../../../components/Answer';

import {
  checkAnswer,
} from '../../../../redux/modules/myGame/actions';

const AnswerWrapperAnimation = posed.div({
  enter: {
    opacity: 1,
    scale: 1,
    transition: { duration: 300 },
  },
  exit: {
    opacity: props => (props.isSelected ? 1 : 0),
    scale: props => (props.isSelected ? 1.03 : 1),
    transition: { duration: 300 },
  },
  initialPose: 'enter',
});

const AnswerWrapper = pure(styled(AnswerWrapperAnimation)`
  padding: 5px 0;
  position: relative;
  
  ${props => (props.answerMethodTwo && 'padding: 0;')}
  ${props => (props.lastAnswerMethodTwo && 'padding-bottom: 40px;')}
`);

const MainWrapper = pure(styled.div`
  width: 100%;
  min-height: 100px;
  background-color: ${props => (props.loading ? 'red' : 'transparent')};
`);

const compareAnswers = (answerA, answerB) => {
  if (answerA.additional === 0 && answerB.additional !== 0) {
    return 1;
  }
  if (answerB.additional === 0 && answerA.additional !== 0) {
    return -1;
  }

  return answerA.additional - answerB.additional;
};

class Answers extends React.PureComponent {
  state = {
    clicked: false,
    salt: Math.random(),
    sendAnswers: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sendAnswerPending && !this.props.sendAnswerPending) {
      this.setState({
        clicked: false,
        salt: Math.random(),
        selectedAnswer: null,
      });
    }

    if (
      !this.props.sendAnswerPending
      && this.props.methodType === 'One'
      && !isEqual(this.props.answers, prevProps.answers)
    ) {
      const activeAnswer = find(this.props.answers, { isActive: true });
      if (activeAnswer) {
        const element = document.getElementById(`answer-${activeAnswer.id}`);
        if (element) {
          const offset = this.getScrollToHeight(element);
          animateScrollTo(offset);
        }
      }
    }
  }

  getScrollToHeight = (el) => {
    const elOffset = el.offsetTop + 90;
    const elHeight = el.clientHeight;

    const windowHeight = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

    if (elHeight < windowHeight) {
      return elOffset - ((windowHeight / 2) - (elHeight / 2));
    }

    return elOffset;
  };

  onAnswerClick = (id) => {
    this.setState({
      selectedAnswer: id,
    }, () => {
      this.checkAnswer(this.state.selectedAnswer);
    });
  };

  // TODO: Функция также встречается в ./../index.js
  checkAnswer = (id) => {
    if (this.props.methodType === 'One') {
      this.setState({
        clicked: true,
      }, () => {
        this.props.checkAnswer({
          answers: this.props.answers.map(answer => ({
            id: answer.id,
            text: answer.text,
            isActive: answer.id === id,
            additional: answer.id === id,
          })),
          sendAnswers: true,
        });
      });
    } else if (this.props.methodType === 'Two' || this.props.methodType === 'TwoEx') {
      const answerOrders = this.props.answers.map(answer => (isUndefined(answer.additional) ? 0 : answer.additional));

      const maxAnswerOrder = Math.max.apply(null, answerOrders);

      const newAnswers = this.props.answers.map((answer) => {
        if (id === answer.id) {
          return {
            id: answer.id,
            text: answer.text,
            isActive: !answer.isActive,
            additional: answer.isActive ? 0 : maxAnswerOrder + 1,
          };
        }

        return {
          id: answer.id,
          text: answer.text,
          isActive: answer.isActive,
          additional: isUndefined(answer.additional) ? 0 : answer.additional,
        };
      });

      const newAnswersOrders = newAnswers.map(
        answer => (isUndefined(answer.additional) ? 0 : answer.additional),
      );

      this.setState({
        clicked: Math.min.apply(null, newAnswersOrders) !== 0,
      }, () => {
        this.props.checkAnswer({
          answers: newAnswers.sort(compareAnswers),
          sendAnswers: Math.min.apply(null, newAnswersOrders) !== 0,
        });
      });
    }
  };

  render() {
    const { answers } = this.props;

    const activeCount = reduce(answers, (count, answer) => {
      if (answer.isActive) {
        count += 1;
      }

      return count;
    }, 0);

    let currentActiveIndex = 0;

    return (
      <MainWrapper>
        {this.state.clicked && <LoadingIndicator />}
        {!this.state.clicked && this.props.answers && (
          <PoseGroup>
            {this.props.methodType === 'One' && (
              answers.map(answer => (
                <AnswerWrapper
                  id={`answer-${answer.id}`}
                  key={`${this.state.salt}-${answer.id}`}
                  isSelected={this.state.selectedAnswer === answer.id}
                >
                  <Answer
                    crossedOut={answer.tipFiftyFifty === 'wrong' || answer.tipHighlight === 'wrong'}
                    text={answer.text}
                    key={answer.id}
                    id={answer.id}
                    isActive={answer.isActive}
                    checkAnswer={this.onAnswerClick}
                  />
                </AnswerWrapper>
              ))
            )}
            {(this.props.methodType === 'Two' || this.props.methodType === 'TwoEx') && activeCount > 0 && (
              filter(answers, answer => answer.isActive).map((answer) => {
                currentActiveIndex += 1;

                return (
                  <AnswerWrapper
                    answerMethodTwo
                    lastAnswerMethodTwo={currentActiveIndex === activeCount}
                    id={`answer-${answer.id}`}
                    key={`${this.state.salt}-${answer.id}`}
                    isSelected={this.state.selectedAnswer === answer.id}
                  >
                    <Answer
                      crossedOut={answer.tipFiftyFifty === 'wrong' || answer.tipHighlight === 'wrong'}
                      text={answer.text}
                      key={answer.id}
                      id={answer.id}
                      activeIndex={currentActiveIndex}
                      activeCount={activeCount}
                      answerMethodTwo
                      firstActive={currentActiveIndex === 1}
                      middleActive={(
                        currentActiveIndex !== 1
                        && currentActiveIndex !== activeCount
                        && currentActiveIndex + 1 > currentActiveIndex
                      )}
                      lastActive={currentActiveIndex === activeCount}
                      isActive={answer.isActive}
                      checkAnswer={this.onAnswerClick}
                    />
                  </AnswerWrapper>
                );
              })
            )}
            {(this.props.methodType === 'Two' || this.props.methodType === 'TwoEx') && (
              filter(answers, answer => !answer.isActive).map(answer => (
                <AnswerWrapper
                  id={`answer-${answer.id}`}
                  key={`${this.state.salt}-${answer.id}`}
                  isSelected={this.state.selectedAnswer === answer.id}
                >
                  <Answer
                    crossedOut={answer.tipFiftyFifty === 'wrong' || answer.tipHighlight === 'wrong'}
                    text={answer.text}
                    key={answer.id}
                    id={answer.id}
                    checkAnswer={this.onAnswerClick}
                  />
                </AnswerWrapper>
              ))
            )}
          </PoseGroup>
        )}
      </MainWrapper>
    );
  }
}

Answers.propTypes = {
  answers: PropTypes.array,
  methodType: PropTypes.string,
};

export function mapDispatchToProps(dispatch) {
  return {
    checkAnswer: answer => dispatch(checkAnswer(answer)),
  };
}

const mapStateToProps = createStructuredSelector({
  answers: makeSelectAnswers(),
  sendAnswerPending: makeSelectSendAnswerPending(),
  methodType: makeSelectMethodType(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Answers);
