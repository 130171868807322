import styled from 'styled-components';

const UserPhotoImg = styled.div`
  border-radius: 50%;
  display: block;
  height: 148px;
  width: 148px;
  background: url("${props => props.src}") no-repeat;
  background-position: center;
  background-size: cover;

  &.avatar {
    cursor: pointer;
    height: 40px;
    width: 40px;
  }
`;

export default UserPhotoImg;
