import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pure from 'recompose/pure';

import Spinner from 'react-spinkit';
import scrollLock from '../../utils/scrollLock';
import NormalPopup from '../BigPopup/PopupWrapper';

const Popup = pure(styled(NormalPopup)`
  display: flex;
  background-color: ${props => (props.solidBg ? 'rgba(33, 34, 42, 1)' : 'rgba(33, 34, 42, .98)')};
  align-items: center;
  justify-content: center;

  & .spinner {
    height: 60px;
    width: 60px;
  }
`);

class Loader extends React.PureComponent {
  componentDidMount() {
    if (this.props.isActive) {
      scrollLock.on();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      scrollLock.on();
    }

    if (this.props.isActive && !nextProps.isActive) {
      scrollLock.off();
    }
  }

  componentWillUnmount() {
    if (this.props.isActive) {
      scrollLock.off();
    }
  }

  render() {
    return (
      <Popup isActive={this.props.isActive} solidBg={this.props.solidBg}>
        <Spinner
          name="circle"
          fadeIn="none"
          className="spinner"
          color="white"
        />
      </Popup>
    );
  }
}

Loader.propTypes = {
  isActive: PropTypes.bool,
  solidBg: PropTypes.bool,
};

Loader.defaultProps = {
  isActive: false,
  solidBg: false,
};

export default Loader;
