import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'space-between')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
  flex-direction: ${props => (props.direction ? props.direction : 'initial')};
  // ${props => (!props.wrap && 'width: 375px;')}
  margin: 0 auto;
  min-height: 50px;
  
  @media (max-width: 375px) {
    width: 300px;
  }
`;

export default ButtonWrapper;
