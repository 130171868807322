import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

const Root = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 0;
  margin: auto;
  position: relative;
  width: 595px;
  z-index: 10;
`;

class PopupInner extends React.PureComponent {
  static defaultProps = {
    children: null,
  };

  static propTypes = {
    children: PropTypes.node,
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleClickOutside = () => {
    const { isActive, onClose } = this.props;

    if (isActive) {
      onClose();
    }
  };

  render() {
    return (
      <Root>
        {this.props.children}
      </Root>
    );
  }
}

export default onClickOutside(PopupInner);
