import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import parseQuestNextStep from '../../../utils/RequestDataParse/parseQuestNextStep';
import { makeSelectActiveGameId } from '../../modules/activeGame/selectors';
import { refreshSlideContentFail, refreshSlideContentSuccess } from '../../modules/debug/actions';
import { REFRESH_SLIDE_CONTENT } from '../../modules/debug/constants';
import { makeSelectGameSessionId } from '../../modules/feedBackForm/selectors';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import { apiRequest as createApiRequest } from '../../../utils/request';
import { nextStepSuccess } from '../../modules/myGame/actions';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* refreshSlideContentSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const sessionId = yield select(makeSelectGameSessionId());

    try {
      const request = `${url}/QuestReloadStep/${encodeURIComponent(gameId)}/${encodeURIComponent(sessionId)}`;
      const result = yield call(apiRequest, request);

      const requestData = parseQuestNextStep({ request: result });
      yield put(nextStepSuccess(requestData));

      yield put(refreshSlideContentSuccess());
    } catch (err) {
      console.error(err);
      yield put(refreshSlideContentFail());
    }
  }

  function* saga() {
    yield takeEvery(REFRESH_SLIDE_CONTENT, refreshSlideContentSaga);
  }

  return saga;
}

export default build;
