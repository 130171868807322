import filter from 'lodash/filter';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNull from 'lodash/isNull';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import find from 'lodash/find';
import { Helmet } from 'react-helmet';
import Typograf from 'typograf';
import PassageStage from '../../../components/GameInfo/includes/PassageStage';
import Content from '../../../components/Content';
import Section from '../../../components/Section';
import GameInfo from '../../../components/GameInfo';
import Info from '../../../components/Info';
import H2 from '../../../components/H2';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Popup from '../../../components/SmallPopup';
import RatingContent from '../../../components/RatingContent';
import SaveSwitcher from '../../../components/SaveSwitcher';
import Switcher from '../../../components/Switcher';
import Text from '../../../components/Text';
import URL_CONSTANTS from '../../../urlConstants';
import SimplePageBase from '../../PageBase/SimplePageBase';

import BuyGamePopup from './includes/BuyGamePopup';
import GameWasBuyPopup from './includes/GameWasBuyPopup';
import GameInvoiceCreatedPopup from './includes/GameInvoiceCreatedPopup';
import SetUserEmailPopup from './includes/SetUserEmailPopup';
import SetUserEmailSuccessPopup from './includes/SetUserEmailSuccessPopup';

import Label from './includes/Label';
import Library from './includes/Library';
import Note from './includes/Note';
import PopupLine from './includes/PopupLine';
import Table from './includes/Table';
import Tooltip from './includes/Tooltip';

const NoData = styled.div`
    padding: 40px;
    font-size: 16px;
    text-align: center;
    width: 100%;
`;

const tp = new Typograf({ locale: ['ru', 'en-US'] });

class GameOverviewPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const activeGameShortName = props.match.params.gameShortName;
    const activeGameId = this.getGameId({ games: props.allGames, activeGameShortName });
    if (activeGameId) {
      props.setActiveGameId(activeGameId);
    }
  }

  state = {
    continueGame: false,
    isOpenedTooltipContinue: false,
    isOpenedTooltipSuper: false,
    newGamePopupActive: false,
    superGame: false,
    switcherReverse: false,
    switcherActiveState: 0,
    switcherStates: [0, 1, 2, 3],
    switcherLabels: [null, 'x2', 'x3', 'x5'],
    buyGamePopupShown: false,
    setUserEmailPopupShown: false,
    activeShowCase: null,
    gameWasBuyPopupShown: false,
    gameInvoiceCreatedPopupShown: false,
    gameInvoiceInfoPopupShown: false,
    setShowUserEmailSuccessPopupShown: false,
  };

  componentDidMount() {
    this.props.onRenderPage();
  }

  componentWillReceiveProps(nextProps) {
    const currentGameInfo = find(this.props.allGames, { GameID: this.props.activeGameId }, false);
    const gameAllowed = get(currentGameInfo, 'HasPermission', null);

    if (!this.props.cartCheckoutSuccess && nextProps.cartCheckoutSuccess) {
      if (gameAllowed) {
        this.showGameWasBuyPopup();
      } else {
        this.showGameInvoiceCreatedPopup();
      }
    }

    if (!this.props.setUserEmailSuccess && nextProps.setUserEmailSuccess) {
      this.hideSetUserEmailPopup();
      this.showSetUserEmailSuccessPopup();
    }
  }

  getGameId = ({ activeGameShortName, games = [] }) => {
    if (activeGameShortName) {
      const currentGameMeta = find(games, { NameSys: activeGameShortName });

      if (currentGameMeta && currentGameMeta.GameID) {
        return currentGameMeta.GameID;
      }

      return null;
    }

    return null;
  };

  getCashValue = () => {
    const currentGameInfo = find(this.props.allGames, { GameID: this.props.activeGameId }, false);
    const virtualCashKey = get(currentGameInfo, 'OptionsValues.cashSysName', null);

    const cash = find(this.props.profile.virtualCash, { name: virtualCashKey });
    return cash ? cash.balance : 0;
  };

  showSetUserEmailPopup = () => {
    this.setState({
      setUserEmailPopupShown: true,
    });
  };

  hideSetUserEmailPopup = () => {
    this.setState({
      setUserEmailPopupShown: false,
    }, () => {
      this.props.setUserEmailReset();
    });
  };

  showSetUserEmailSuccessPopup = () => {
    this.setState({
      setShowUserEmailSuccessPopupShown: true,
    });
  };

  hideSetUserEmailSuccessPopup = () => {
    this.setState({
      setShowUserEmailSuccessPopupShown: false,
    });
  };

  showGameInvoiceInfoPopup = () => {
    this.setState({
      gameInvoiceInfoPopupShown: true,
    });
  };

  hideGameInvoiceInfoPopup = () => {
    this.setState({
      gameInvoiceInfoPopupShown: false,
    });
  };

  showGameWasBuyPopup = () => {
    this.setState({
      gameWasBuyPopupShown: true,
    });
  };

  hideGameWasBuyPopup = () => {
    this.setState({
      gameWasBuyPopupShown: false,
    });
  };

  showGameInvoiceCreatedPopup = () => {
    this.setState({
      gameInvoiceCreatedPopupShown: true,
    });
  };

  hideGameInvoiceCreatedPopup = () => {
    this.setState({
      gameInvoiceCreatedPopupShown: false,
    });
  };

  showBuyGamePopup = (showcaseInfo) => {
    this.setState({
      buyGamePopupShown: true,
      activeShowCase: showcaseInfo,
    });
  };

  hideBuyGamePopup = () => {
    this.setState({
      buyGamePopupShown: false,
      activeShowCase: null,
    });
  };

  buyCard = (count, cardIdForBuy) => {
    this.props.onBuyCard({
      count,
      cardIdForBuy,
    });
  };

  isEnoughMoneyForBoost = (boostStep) => {
    const BOSTER_COST = 3000;
    const myCash = this.getCashValue();
    const cost = boostStep * BOSTER_COST;

    return myCash >= cost;
  };

  toggleSwitcher = () => {
    let newState = 0;

    if (!this.isEnoughMoneyForBoost(1)) {
      return;
    }

    if (!this.state.switcherReverse) {
      newState = this.state.switcherActiveState + 1;

      let switcherReverse = false;

      if (newState === this.state.switcherStates.length - 1) {
        switcherReverse = true;
      }

      if (!this.isEnoughMoneyForBoost(newState + 1)) {
        switcherReverse = true;
      }

      this.setState({
        switcherActiveState: newState,
        switcherReverse,
      });
    } else {
      newState = this.state.switcherActiveState - 1;

      this.setState({
        switcherActiveState: newState,
        switcherReverse: this.state.switcherActiveState !== 1,
      });
    }

    if (newState !== 0) {
      this.setState({
        superGame: false,
        continueGame: false,
      });

      this.props.onChangeSupergame({
        superGame: false,
      });
    }

    this.props.onChangeStavka({
      stavka: newState,
    });
  };

  toggleContinueGame = () => {
    if (this.props.saveTip.lastSaveId !== null) {
      const newContinueGame = !this.state.continueGame;
      this.setState({
        continueGame: newContinueGame,
      });

      if (newContinueGame === true) {
        this.setState({
          superGame: false,
          switcherActiveState: 0,
          switcherReverse: false,
        });

        this.props.onChangeSupergame({
          superGame: false,
        });

        this.props.onChangeStavka({
          stavka: 0,
        });
      }
    } else {
      this.setState({
        isOpenedTooltipContinue: true,
      });

      setTimeout(() => {
        this.setState({
          isOpenedTooltipContinue: false,
        });
      }, 1500);
    }
  };

  toggleSuperGame = () => {
    if (this.props.superGame.isAvailable) {
      const newState = !this.state.superGame;
      this.setState({
        superGame: newState,
      });

      this.props.onChangeSupergame({
        superGame: newState,
      });

      if (newState === true) {
        this.setState({
          continueGame: false,
          switcherActiveState: 0,
          switcherReverse: false,
        });

        this.props.onChangeStavka({
          stavka: 0,
        });
      }
    } else {
      this.setState({
        isOpenedTooltipSuper: true,
      });

      setTimeout(() => {
        this.setState({
          isOpenedTooltipSuper: false,
        });
      }, 1500);
    }
  };

  toggleNewGamePopup = () => {
    this.setState({
      newGamePopupActive: !this.state.newGamePopupActive,
    });
  };

  startNewGameClick = () => {
    this.setState({
      newGamePopupActive: false,
    });

    if (this.state.continueGame) {
      const { allGames, activeGameId } = this.props;
      const activeGameMeta = find(allGames, { GameID: activeGameId });

      const playUrl = `${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/${activeGameMeta.NameSys}`;

      this.props.history.push(`${playUrl}?action=loadSaveGame`);
    } else {
      this.props.onStartNewGameClick();
    }
  };

  onGameBuy = () => {
    const { allGames, activeGameId } = this.props;
    const currentGameInfo = find(allGames, { GameID: activeGameId }, false);

    const showCaseId = currentGameInfo.ShowcaseID;
    const price = currentGameInfo.Price;

    if (!this.props.profile.userEmail) {
      this.showSetUserEmailPopup();
    } else {
      const nonFree = price !== 0;
      this.props.buyShowCase(showCaseId, nonFree);
    }
  };

  onNonFreeGameBuy = (showCaseId) => {
    this.props.buyShowCase(showCaseId);
    this.hideBuyGamePopup();
    this.showGameInvoiceInfoPopup();
  };

  renderRating = () => {
    let iAmInTop100 = false;

    const { ratingFields } = this.props;

    const tableHeader = map(
      filter(ratingFields, field => !field.IsSystem),
      (item, index) => ({
        key: index + 1,
        width: item.Width,
        title: item.Name,
        tooltip: tp.execute(item.Hint),
      }),
    );

    let tableItems = [];

    if (!isNull(this.props.rating) && this.props.rating) {
      let myId = -1;

      this.props.rating.map((item, key) => {
        if (item.userid === this.props.profile.userID) {
          myId = key;
          iAmInTop100 = !item.outTop;
        }

        return item;
      });

      let rating = [];
      rating[myId] = this.props.rating[myId];
      if (iAmInTop100) {
        rating[myId - 1] = this.props.rating[myId - 1];
        rating[myId + 1] = this.props.rating[myId + 1];
      }

      if (myId + 1 === this.props.rating.length) {
        rating = [];
        if (iAmInTop100) {
          rating[myId - 2] = this.props.rating[myId - 2];
          rating[myId - 1] = this.props.rating[myId - 1];
        }
        rating[myId] = this.props.rating[myId];
      } else if (myId === 0) {
        rating = [];
        rating[0] = this.props.rating[0];
        if (iAmInTop100) {
          rating[1] = this.props.rating[1];
          rating[2] = this.props.rating[2];
        }
      }

      tableItems = rating
        .map((item, index) => {
          const rowData = map(
            filter(ratingFields, field => !field.IsSystem),
            (field, index) => ({
              key: index + 1,
              width: field.Width,
              value: item[field.FieldName],
              field,
            }),
          );

          return {
            key: index,
            outOfList: false,
            isMyRating: item.userid === this.props.profile.userID,
            isChampion: rowData.position === 0,
            items: rowData,
          };
        });
    }

    const { allGames, activeGameId } = this.props;

    const currentGameInfo = find(allGames, { GameID: activeGameId }, false);
    const gameAllowed = get(currentGameInfo, 'HasPermission', null);

    return (
      <>
        {!gameAllowed && (
          <H2 bold textAlign="center">
            После приобретения полной версии игры тут будет рейтинг.
          </H2>
        )}

        {gameAllowed && (tableItems && tableItems.length > 0) && (
          <>
            <H2 bold textAlign="center">
              {this.props.championConspectus && 'Все как в жизни: каждый выигрывает со своим результатом.'}
              {!this.props.championConspectus && (
                <>
                  {iAmInTop100 && 'Осталось чуть-чуть чтобы подняться в рейтинге!'}
                  {!iAmInTop100 && 'Совершайте продажи в игре и открывайте методички, чтобы подняться в рейтинге!'}
                </>
              )}
            </H2>
            <Table
              minWidth="1000px"
              header={tableHeader}
              items={tableItems}
            />
          </>
        )}
        {gameAllowed && (!tableItems || tableItems.length === 0) && (
          <H2 bold textAlign="center">
            Сыграйте вашу первую игру чтобы появится в рейтинге
          </H2>
        )}
      </>
    );
  };

  onContinueGameClick = () => {
    const { allGames, activeGameId } = this.props;
    const activeGameMeta = find(allGames, { GameID: activeGameId });

    const playUrl = `${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/${activeGameMeta.NameSys}`;

    this.props.history.push(playUrl);

    return false;
  };

  onSetUserEmailPopupSubmit = (email) => {
    this.props.setUserEmail(email);
  };

  render() {
    const { leftMenuState } = this.props;

    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;

    let content = (
      <Section>
        <LoadingIndicator />
      </Section>
    );

    let library = null;
    const { libraryCards } = this.props;
    if (libraryCards && libraryCards.length) {
      const totalCardsCount = reduce(libraryCards, (sum, item) => sum + item.AllCount, 0);
      const openedCardsCount = reduce(libraryCards, (sum, item) => sum + item.OpenCount, 0);

      const cashValue = this.getCashValue();

      library = (
        <Library
          small={small}
          cards={this.props.libraryCards}
          cash={cashValue}
          totalCardsCount={totalCardsCount}
          openedCardsCount={openedCardsCount}
          cardKeyPrice={this.props.cardKeyPrice.price}
          cardBuyPending={this.props.cardBuyPending}
          lastBuyCard={this.props.lastBuyCard}
          onBuyCard={this.buyCard}
        />
      );
    }

    const { allGames, activeGameId } = this.props;
    const currentGameInfo = find(allGames, { GameID: activeGameId }, false);

    const gameName = get(currentGameInfo, 'Name');

    const { ratingPending, ratingLoaded, ratingLoadFail } = this.props;

    if (!this.props.isLoading) {
      content = (
        <Section grey>
          {gameName && (
            <Helmet title={gameName} />
          )}
          <GameInfo
            onStartNewGame={this.toggleNewGamePopup}
            onContinueGame={this.onContinueGameClick}
            onGameBuy={this.onGameBuy}
            small={small}
          />
          <PassageStage dark />
          {!ratingLoadFail && (
            <Content>
              <RatingContent noFlex>
                {ratingPending && <LoadingIndicator />}
                {ratingLoadFail && (
                  <NoData>
                    👻 При получении рейтинга по этой игре произошла ошибка.
                  </NoData>
                )}
                {ratingLoaded && this.renderRating()}
              </RatingContent>
            </Content>
          )}
          {library && (
            <Content>
              {library}
            </Content>
          )}
          <BuyGamePopup
            small={small}
            pending={this.props.buyPending || this.props.getGamesPending}
            open={this.state.buyGamePopupShown}
            showCase={this.state.activeShowCase}
            onClose={this.hideBuyGamePopup}
            onSubmit={this.onNonFreeGameBuy}
          />
          <SetUserEmailPopup
            small={small}
            pending={this.props.setUserEmailPending}
            open={this.state.setUserEmailPopupShown}
            onClose={this.hideSetUserEmailPopup}
            onSubmit={this.onSetUserEmailPopupSubmit}
          />
          <GameWasBuyPopup
            small={small}
            open={this.state.gameWasBuyPopupShown}
            onClose={this.hideGameWasBuyPopup}
          />
          <GameInvoiceCreatedPopup
            small={small}
            open={this.state.gameInvoiceCreatedPopupShown}
            onClose={this.hideGameInvoiceCreatedPopup}
          />
          <SetUserEmailSuccessPopup
            small={small}
            email={this.props.setUserEmailValue}
            open={this.state.setShowUserEmailSuccessPopupShown}
            onClose={this.hideSetUserEmailSuccessPopup}
          />
          <Popup
            small={small}
            title="Начать игру заново"
            isActive={this.state.newGamePopupActive}
            cancelText="Отмена"
            submitText="Начать"
            onSubmit={this.startNewGameClick}
            onClose={this.toggleNewGamePopup}
            withoutPadding
            withoutBorder
            left={leftMenuState ? 120 : 30}
          >
            <PopupLine>
              <Label
                enabled={this.props.saveTip.lastSaveId !== null}
                onClick={this.toggleContinueGame}
              >
                Продолжить с последнего сохранения
                <Tooltip
                  isBig
                  isOpened={this.state.isOpenedTooltipContinue}
                >
                  У вас нет сохранений
                </Tooltip>
              </Label>
              <SaveSwitcher
                className="equal-insurer"
                disabled={this.props.saveTip.lastSaveId === null}
                onClick={this.toggleContinueGame}
                on={this.state.continueGame}
              />
            </PopupLine>
            <PopupLine>
              <Label
                enabled={this.isEnoughMoneyForBoost(1)}
                onClick={this.toggleSwitcher}
              >
                Ставка на себя
                <Info
                  isBig
                  text="Сделай ставку на себя и увеличь свою комиссию от продажи в момент заключенной сделки."
                />
                <Note>3 000 рублей</Note>
              </Label>
              <Switcher
                disabled={!this.isEnoughMoneyForBoost(1)}
                activeState={this.state.switcherActiveState}
                labels={this.state.switcherLabels}
                onClick={this.toggleSwitcher}
                states={this.state.switcherStates}
              />
            </PopupLine>
            <PopupLine>
              <Text>
                Внимание! Если вы начнете новую игру, результаты последнего не&nbsp;сохраненного сеанса и заработанные
                ключи пропадут
              </Text>
            </PopupLine>
          </Popup>
        </Section>
      );
    }

    return (
      <SimplePageBase title="Моя игра">
        {content}
      </SimplePageBase>
    );
  }
}

GameOverviewPage.propTypes = {
  canContinue: PropTypes.bool,
  ratingPending: PropTypes.bool,
  ratingLoadFail: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFirstGame: PropTypes.bool,
  leftMenuState: PropTypes.bool,
  libraryCards: PropTypes.any,
  onBuyCard: PropTypes.func,
  onLeftMenuClick: PropTypes.func,
  onStartNewGameClick: PropTypes.func,
  onContinueGameClick: PropTypes.func,
  onChangeSupergame: PropTypes.func,
  onRenderPage: PropTypes.func,
  onChangeStavka: PropTypes.func,
  cardKeyPrice: PropTypes.object,
  profile: PropTypes.object,
  saveTip: PropTypes.object,
  superGame: PropTypes.object,
  stage: PropTypes.number,
  rating: PropTypes.array,
};

export default GameOverviewPage;
