import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { createGlobalStyle } from 'styled-components';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { setBeforeDemoLeavePopupOpened } from '../../../redux/modules/popups/actions';
import GameContent from './GameContent';
import GameHeader from '../../other/GameHeader';
import Main from '../../../components/Main';
import Section from '../../../components/Section';

import GamePageWrapper from './includes/GamePageWrapper';
import GameEvents from '../../../components/GameEvents';
import GoToFullVersion from './includes/GoToFullVersion';

const GlobalStyle = createGlobalStyle`
  html, body, #app {
    background-color: #F7F7F7 !important;
  }
`;

class GamePage extends React.PureComponent {
  render() {
    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;

    return (
      <GamePageWrapper>
        <GlobalStyle />
        <GameHeader />
        <GameEvents />
        <Main isGame small={small}>
          <Helmet title="Моя игра" />
          <GoToFullVersion />
          <Section hasLeftMenu={false}>
            <GameContent />
          </Section>
        </Main>
      </GamePageWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  browser: makeSelectBrowser(),
});

const mapDispatchToProps = dispatch => ({
  setBeforeDemoLeavePopupOpened: opened => dispatch(setBeforeDemoLeavePopupOpened(opened)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withCookies
)(GamePage);
