import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import isNull from 'lodash/isNull';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import AnimatedNumber from 'react-animated-number';
import Typograf from 'typograf';
import Info from '../../../components/Info';
import SaveSwitcher from '../../../components/SaveSwitcher';
import Popup from '../../../components/SmallPopup';
import Switcher from '../../../components/Switcher';
import { FLOAT_COLUMN_TYPE, INTEGER_COLUMN_TYPE } from '../../../reportColumnTypes';
import URL_CONSTANTS from '../../../urlConstants';
import OpenedCards from '../../../components/OpenedCards';
import Section from '../../../components/Section';
import Main from '../../../components/Main';
import ColoredLogo from '../../../components/SmallLogo';
import Button from '../../../components/useThis/Button';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Label from '../GameOverviewPage/includes/Label';
import Note from '../GameOverviewPage/includes/Note';
import PopupLine from '../GameOverviewPage/includes/PopupLine';
import Tooltip from '../GameOverviewPage/includes/Tooltip';

import Body from './includes/Body';
import DemoFinishPage from '../DemoFinishPage';
import FinishRecomendationRoot from './includes/FinishRecomendationRoot';
import FinishRecomendationStatusText from './includes/FinishRecomendationStatusText';
import FinishRecomendationTitle from './includes/FinishRecomendationTitle';
import Line from './includes/Line';
import LineItem from './includes/LineItem';
import Person from './includes/Person';
import FinishTitle from './includes/FinishTitle';
import FinishStatusText from './includes/FinishStatusText';
import Result from './includes/Result';
import Text from './includes/Text';

const GlobalStyle = createGlobalStyle`
  #app {
    background-color: #FFFFFF;
  }
`;

const Number = styled.div`
  white-space: nowrap;
`;

const FinishRecomendationWrapper = styled.div`
  padding: 0 15px;
`;

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const SuccessPage = (props) => {
  const keyRef = React.createRef();

  const {
    reportPending: loading,
    finishSuccess,
    isTrialGame,
    report,
  } = props;

  let content = <LoadingIndicator />;

  const getCashValue = () => {
    const { allGames } = props;

    const currentGameInfo = find(allGames, { GameID: props.activeGameId }, false);
    const virtualCashKey = get(currentGameInfo, 'OptionsValues.cashSysName', null);

    const cash = find(props.profile.virtualCash, { name: virtualCashKey });
    return cash ? cash.balance : 0;
  };

  const [newGamePopupActive, setNewGamePopupActive] = useState(false);
  const [isOpenedTooltipContinue, setIsOpenedTooltipContinue] = useState(false);
  const [continueGame, setContinueGame] = useState(false);
  const [switcherReverse, setSwitcherReverse] = useState(false);
  const [switcherActiveState, setSwitcherActiveState] = useState(0);

  const onContinue = () => {
    setNewGamePopupActive(true);
  };

  const toGameOverview = () => {
    window.location.reload();
    if (props.gameUrl) {
      window.location.href = props.gameUrl;
    } else {
      window.location.href = URL_CONSTANTS.URLS.GAMES_STORE;
    }
  };

  const {
    dataTopOne,
    fieldsTopOne,
    dataTopTwo,
    fieldsTopTwo,
    dataBottomOne,
    fieldsBottomOne,
    dataBottomTwo,
    fieldsBottomTwo,
  } = report;

  if (!isNull(loading) && !loading) {
    if (finishSuccess) {
      content = (
        <div>
          {!isTrialGame && fieldsTopOne && fieldsTopOne.length > 0 && (
            <>
              {map(fieldsTopOne, field => (
                <>
                  <Result>
                    <AnimatedNumber
                      component={Number}
                      value={dataTopOne[field.FieldName]}
                      style={{
                        transition: '1s ease-out',
                        minWidth: String(dataTopOne[field.FieldName]).length * 50,
                        transitionProperty: 'background-color, color, opacity',
                      }}
                      stepPrecision={0}
                      duration={1000}
                      formatValue={n => n.toLocaleString()}
                    />
                  </Result>
                  <Text marginTop={-5}>{field.Name}</Text>
                </>
              ))}
            </>
          )}
          {!isTrialGame && fieldsTopTwo && fieldsTopTwo.length > 0 && (
            <Line>
              {map(fieldsTopTwo, field => {
                if (field.FieldName === 'givecardkey') {
                  return (
                    <LineItem key={field.FieldName}>
                      <div ref={keyRef}>{dataTopTwo[field.FieldName]}</div>
                      <Text marginTop={3}>{field.Name}</Text>
                    </LineItem>
                  );
                }

                if (field.ColumnType === FLOAT_COLUMN_TYPE || field.ColumnType === INTEGER_COLUMN_TYPE) {
                  return (
                    <LineItem>
                      <AnimatedNumber
                        component={Number}
                        value={dataTopTwo[field.FieldName]}
                        style={{
                          transition: '1s ease-out',
                          minWidth: String(dataTopTwo[field.FieldName]).length * 42,
                          transitionProperty: 'background-color, color, opacity',
                        }}
                        stepPrecision={0}
                        duration={2000}
                        formatValue={n => n.toLocaleString()}
                      />
                      <Text marginTop={3}>{field.Name}</Text>
                    </LineItem>
                  );
                } else {
                  return (
                    <LineItem>
                      <span>{dataTopTwo[field.FieldName]}</span>
                      <Text marginTop={3}>{field.Name}</Text>
                    </LineItem>
                  );
                }
              })}
            </Line>
          )}

          {!isTrialGame && dataTopTwo && dataTopTwo.givecardkey > 0 && (
            <>
              <br />
              <OpenedCards
                keyRef={keyRef}
                gameUrl={props.gameUrl}
                data={props.receivedKeysData}
              />
            </>
          )}

          {!isTrialGame && fieldsBottomOne && fieldsBottomOne.length > 0 && (
            <>
              <br />
              <FinishRecomendationWrapper>
                <FinishRecomendationRoot>
                  <FinishRecomendationTitle>
                    Рекомендация
                  </FinishRecomendationTitle>
                  <FinishRecomendationStatusText>
                    {map(fieldsBottomOne, field => (
                      <span key={field.FieldName}>
                        {tp.execute(dataBottomOne[field.FieldName])}
                      </span>
                    ))}
                  </FinishRecomendationStatusText>
                </FinishRecomendationRoot>
              </FinishRecomendationWrapper>
            </>
          )}

          {!isTrialGame && fieldsBottomTwo && fieldsBottomTwo.length > 0 && (
            <Person>
              {dataBottomTwo.statustitle && (
                <FinishTitle dangerouslySetInnerHTML={{ __html: tp.execute(dataBottomTwo.statustitle) }} />
              )}
              {dataBottomTwo.status && (
                <FinishStatusText dangerouslySetInnerHTML={{ __html: tp.execute(dataBottomTwo.status) }} />
              )}
            </Person>
          )}

          {!isTrialGame && props.showPresettingScreen && (
            <>
              <br />
              <Button onClick={onContinue}>
                Сыграть еще раз
              </Button>
            </>
          )}
          {!isTrialGame && props.superGameAvailable && (
            <>
              <br />
              <Button onClick={props.onStartNewSuperGame}>
                Сыграть еще раз
              </Button>
            </>
          )}
          {!isTrialGame && !props.showPresettingScreen && !props.superGameAvailable && (
            <>
              <br />
              <Button onClick={toGameOverview}>
                Продолжить
              </Button>
            </>
          )}
          {isTrialGame && (
            <>
              <DemoFinishPage />
            </>
          )}
        </div>
      );
    } else {
      console.error('Извините, в этой ветке игры, игра сломалась, мы автоматически получим сообщение об этом и исправим это в самое ближайшее время.');
      window.location.reload();
      window.location.href = props.gameUrl;
      return false;
    }
  }

  const small = props.browser.is.extraSmall || props.browser.is.small;

  const startNewGameClick = () => {
    setNewGamePopupActive(false);

    if (continueGame) {
      const allGames = [...props.games, ...props.gamesShowcases];

      const { activeGameId } = props;
      const activeGameMeta = find(allGames, { GameID: activeGameId });

      const playUrl = `${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/${activeGameMeta.NameSys}`;

      props.history.push(`${playUrl}?action=loadSaveGame`);
    } else {
      props.onStartNewGameClick();
    }
  };

  const toggleContinueGame = () => {
    if (props.saveTip.lastSaveId !== null) {
      const newContinueGame = !continueGame;
      setContinueGame(newContinueGame);

      if (newContinueGame === true) {
        setSwitcherReverse(false);
        setSwitcherActiveState(0);

        props.onChangeSupergame({
          superGame: false,
        });

        props.onChangeStavka({
          stavka: 0,
        });
      }
    } else {
      setIsOpenedTooltipContinue(true);

      setTimeout(() => {
        setIsOpenedTooltipContinue(false);
      }, 3000);
    }
  };

  const isEnoughMoneyForBoost = (boostStep) => {
    const BOSTER_COST = 3000;
    const myCash = getCashValue();
    const cost = boostStep * BOSTER_COST;

    return myCash >= cost;
  };

  const switcherStates = [0, 1, 2, 3];
  const switcherLabels = [null, 'x2', 'x3', 'x5'];

  const toggleSwitcher = () => {
    let newState = 0;

    if (!isEnoughMoneyForBoost(1)) {
      return;
    }

    if (!switcherReverse) {
      newState = switcherActiveState + 1;

      let switcherReverseNew = false;

      if (newState === switcherStates.length - 1) {
        switcherReverseNew = true;
      }

      if (!isEnoughMoneyForBoost(newState + 1)) {
        switcherReverseNew = true;
      }

      setSwitcherActiveState(newState);
      setSwitcherReverse(switcherReverseNew);
    } else {
      newState = switcherActiveState - 1;

      setSwitcherActiveState(newState);
      setSwitcherReverse(switcherActiveState !== 1);
    }

    if (newState !== 0) {
      setContinueGame(false);

      props.onChangeSupergame({
        superGame: false,
      });
    }

    props.onChangeStavka({
      stavka: newState,
    });
  };

  // eslint-disable-next-line react/destructuring-assignment
  let link = props.gameUrl;

  if (!link) {
    link = URL_CONSTANTS.URLS.GAMES_STORE;
  }

  return (
    <Main>
      <GlobalStyle />
      <Helmet title="Игра закончена" />
      <Section withPadding hasLeftMenu={false}>
        <Link to={link}>
          <ColoredLogo
            text={props.gameName}
          />
        </Link>
        <Body>
          {content}
        </Body>
        <Popup
          small={small}
          title="Начать игру заново"
          isActive={newGamePopupActive}
          cancelText="Отмена"
          submitText="Начать"
          onSubmit={startNewGameClick}
          onClose={() => {
            setNewGamePopupActive(false);
          }}
          withoutPadding
          withoutBorder
          left={0}
        >
          <PopupLine>
            <Label
              enabled={props.saveTip.lastSaveId !== null}
              onClick={toggleContinueGame}
            >
              Продолжить с последнего сохранения
              <Tooltip
                isBig
                isOpened={isOpenedTooltipContinue}
              >
                У вас нет сохранений
              </Tooltip>
            </Label>
            <SaveSwitcher
              className="equal-insurer"
              disabled={props.saveTip.lastSaveId === null}
              onClick={toggleContinueGame}
              on={continueGame}
            />
          </PopupLine>
          <PopupLine>
            <Label
              enabled={isEnoughMoneyForBoost(1)}
              onClick={toggleSwitcher}
            >
              Ставка на себя
              <Info
                isBig
                text="Сделай ставку на себя и увеличь свою комиссию от продажи в момент заключенной сделки."
              />
              <Note>3 000 рублей</Note>
            </Label>
            <Switcher
              disabled={!isEnoughMoneyForBoost(1)}
              activeState={switcherActiveState}
              labels={switcherLabels}
              onClick={toggleSwitcher}
              states={switcherStates}
            />
          </PopupLine>
          <PopupLine>
            <Text>
              Внимание! Если вы начнете новую игру, результаты последнего не&nbsp;сохраненного сеанса и заработанные
              ключи пропадут
            </Text>
          </PopupLine>
        </Popup>
      </Section>
    </Main>
  );
};

SuccessPage.defaultProps = {
  finishPaid: null,
  finishRecommendation: null,
  finishStatus: null,
  finishSuccess: true,
  finishSum: null,
  finishTitle: null,
  gameName: null,
  gameUrl: URL_CONSTANTS.URLS.GAMES_STORE,
  keysCount: 0,
  loading: true,
  percentInfo: null,
  percentOfBestSeller: null,
};

SuccessPage.propTypes = {
  finishPaid: PropTypes.number,
  finishRecommendation: PropTypes.string,
  finishStatus: PropTypes.string,
  finishSuccess: PropTypes.bool,
  finishSum: PropTypes.number,
  finishTitle: PropTypes.string,
  gameName: PropTypes.string,
  gameUrl: PropTypes.string,
  keysCount: PropTypes.number,
  loading: PropTypes.bool,
  onGameBuy: PropTypes.func.isRequired,
  percentInfo: PropTypes.object,
  percentOfBestSeller: PropTypes.number,
  receivedKeysData: PropTypes.any,
  stavkaState: PropTypes.number.isRequired,
};

export default SuccessPage;
