import PropTypes from 'prop-types';
import React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { hideSpinner, showSpinner, startTrialGame as startTrialGameAction } from '../../../redux/modules/global/actions';
import { makeSelectStartTrialPending } from '../../../redux/modules/global/selectors';

class StartTrialGamePage extends React.Component {
  static propTypes = {
    hideSpinner: PropTypes.func.isRequired,
    showSpinner: PropTypes.func.isRequired,
    startTrialGame: PropTypes.func.isRequired,
  };

  componentWillMount() {
    // eslint-disable-next-line react/prop-types
    const { cookies } = this.props;

    const maxAge = 60 * 60 * 24 * 365;
    cookies.set('showBeforeLeavePopupInDemoGame', 1, { maxAge, path: '/' });
    cookies.set('showBeforeLeavePopupInGetFullVersionPage', 1, { maxAge, path: '/' });
  }

  componentDidMount() {
    this.props.showSpinner();
    this.onTrialGameStart();
  }

  componentWillUnmount() {
    this.props.hideSpinner();
  }

  onTrialGameStart = () => {
    const { startTrialGame } = this.props;
    const {
      // eslint-disable-next-line react/prop-types
      email: _email, name: _name, phone: _phone, gameSysName: _gameSysName,
      // eslint-disable-next-line react/prop-types
    } = this.props.match.params;

    const email = _email ? decodeURIComponent(_email) : '';
    const name = _name ? decodeURIComponent(_name) : '';
    const phone = _phone ? decodeURIComponent(_phone) : '';
    const gameSysName = _gameSysName ? decodeURIComponent(_gameSysName) : '';

    startTrialGame({
      email, gameSysName, name, phone,
    });
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = createStructuredSelector({
  pending: makeSelectStartTrialPending(),
});

const mapDispatchToProps = dispatch => ({
  startTrialGame: (
    {
      email, gameSysName, name, phone,
    },
  ) => dispatch(startTrialGameAction(
    {
      email, gameSysName, name, phone,
    },
  )),
  showSpinner: () => dispatch(showSpinner()),
  hideSpinner: () => dispatch(hideSpinner()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withCookies,
)(StartTrialGamePage);
