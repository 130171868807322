import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import { validator } from '../../../utils/validation';

import Error from '../../../components/Error';
import FormLine from '../templates/SingleForm/FormLine';
import Input from '../../../components/Input';
import Label from '../templates/SingleForm/Label';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Popup from '../../../components/Popup';

import {
  changeDepartmentForEdit,
  editDepartment,
} from '../../../redux/modules/employees/actions';
import {
  makeSelectDepartmentForEdit,
  makeSelectNewDepartmentLoading,
  makeSelectDepartmentEditPopup,
} from '../../../redux/modules/employees/selectors';

class DepartmentForm extends React.PureComponent {
  state = {
    name: isNull(this.props.department.name) ? '' : this.props.department.name,
    nameValid: true,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: isNull(nextProps.department.name) ? '' : nextProps.department.name,
    });
  }

  changeName = (e) => {
    this.setState({
      name: e.target.value,
      nameValid: true,
    });
  };

  handlerSubmitForm = () => {
    if (!validator.required(this.state.name)) {
      this.setState({
        name: '',
        nameValid: false,
      });
    } else {
      this.props.onChangeName({
        id: this.props.department.id,
        name: this.state.name,
      });

      this.props.onSubmitForm();
    }
  };

  cancelSubmitForm = () => {
    this.setState({
      name: isNull(this.props.department.name) ? '' : this.props.department.name,
      nameValid: true,
    });

    this.props.onClose();
  };

  render() {
    const nameError = this.state.nameValid ? '' : 'Поле обязательно для заполнения';

    let body = <LoadingIndicator />;
    if (!this.props.isLoading) {
      body = (
        <div>
          <FormLine>
            <Label align="left" marginRight="0" width="100%">
              Введите название команды
            </Label>
          </FormLine>
          <FormLine display="block">
            <Input
              isValid={this.state.nameValid}
              type="text"
              onChange={this.changeName}
              value={this.state.name}
              placeholder="Отдел"
            />
            <Error>{nameError}</Error>
          </FormLine>
        </div>
      );
    }

    return (
      <Popup
        isActive={this.props.popupState}
        onClose={this.cancelSubmitForm}
        onSubmit={this.handlerSubmitForm}
        title="Изменить команду"
      >
        {body}
      </Popup>
    );
  }
}

DepartmentForm.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeName: PropTypes.func,
  onSubmitForm: PropTypes.func,
  popupState: PropTypes.bool,
};

export function mapDispatchToProps(dispatch) {
  return {
    onChangeName: result => dispatch(changeDepartmentForEdit(result)),
    onSubmitForm: () => dispatch(editDepartment()),
  };
}

const mapStateToProps = createStructuredSelector({
  popupState: makeSelectDepartmentEditPopup(),
  department: makeSelectDepartmentForEdit(),
  isLoading: makeSelectNewDepartmentLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(DepartmentForm);
