import isNull from 'lodash/isNull';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

const enhance = compose(
  withProps((props) => {
    const { owlData } = props;
    if (!isNull(owlData)) {
      const html = document.createElement('html');
      html.innerHTML = owlData;
      let text = '';
      const textElement = html.getElementsByTagName('TEXT');
      if (textElement && textElement[0]) {
        text = textElement[0].innerHTML;
      }

      let buttonText = 'Ok';
      const buttonTextElement = html.getElementsByTagName('BUTTON');
      if (buttonTextElement && buttonTextElement[0]) {
        buttonText = buttonTextElement[0].innerHTML;
      }

      let header = null;
      const headerElement = html.getElementsByTagName('HEADER');
      if (headerElement && headerElement[0] && headerElement[0].innerHTML) {
        header = headerElement[0].innerHTML;
      }

      return {
        text,
        header,
        button: {
          text: buttonText,
        },
      };
    }

    return {
      text: null,
      header: null,
      button: {
        text: 'Ok',
      },
    };
  }),
);

export default enhance;
