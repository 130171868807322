import find from 'lodash/find';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import get from 'lodash/get';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';

import { makeSelectActiveGameId } from '../../redux/modules/activeGame/selectors';
import {
  makeSelectBuyShowCasePending,
  makeSelectGames, makeSelectGamesShowcases,
  makeSelectGetGamesPending,
} from '../../redux/modules/games/selectors';
import { startNewGame, startSuperGame } from '../../redux/modules/myGame/actions';
import {
  makeSelectCanContinue,
  makeSelectIsFirstGame,
  makeSelectStage,
} from '../../redux/modules/myGame/selectors';
import { makeSelectProfile } from '../../redux/modules/profile/selectors';
import { getPersonalGameHeadRating as getPersonalGameHeadRatingAction } from '../../redux/modules/rating/actions';
import { makeSelectPersonalGameHeadRatingForActiveGame } from '../../redux/modules/rating/selectors';
import { trackBuyGameButtonClicked } from '../../redux/modules/analytics/actions';
import { setBuyGameGuestPopupOpened, setBuyGamePopupOpened } from '../../redux/modules/popups/actions';

const mapDispatchToProps = dispatch => ({
  trackBuyGameButtonClicked: (showcaseId, where, inGame) => (
    dispatch(trackBuyGameButtonClicked(showcaseId, where, inGame))
  ),
  setBuyGamePopupOpened: opened => dispatch(setBuyGamePopupOpened(opened)),
  onStartNewSuperGame: () => dispatch(startSuperGame()),
  onStartNewGameClick: () => dispatch(startNewGame()),
  getPersonalGameHeadRating: gameId => dispatch(getPersonalGameHeadRatingAction({ gameId })),
  setBuyGameGuestPopupOpened: opened => dispatch(setBuyGameGuestPopupOpened(opened)),
});

const mapStateToProps1 = createStructuredSelector({
  activeGameId: makeSelectActiveGameId(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
  profile: makeSelectProfile(),
  buyPending: makeSelectBuyShowCasePending(),
  getGamesPending: makeSelectGetGamesPending(),
  canContinue: makeSelectCanContinue(),
  stage: makeSelectStage(),
  isFirstGame: makeSelectIsFirstGame(),
});

const mapStateToProps2 = createStructuredSelector({
  personalGameHeadRating: makeSelectPersonalGameHeadRatingForActiveGame(),
});

const withConnect1 = connect(mapStateToProps1, mapDispatchToProps);
const withConnect2 = connect(mapStateToProps2, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentWillMount() {
    const { getPersonalGameHeadRating, activeGameId } = this.props;
    getPersonalGameHeadRating(activeGameId);
  },
});

const withCustomHandlers = withHandlers({
  onStartFirstGame: props => () => {
    props.onStartNewGameClick();
  },
});

const withAllGamesProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const withCustomProps = withProps((props) => {
  const currentGameInfo = find(props.allGames, { GameID: props.activeGameId }, false);
  const gameAllowed = get(currentGameInfo, 'HasPermission', null);
  const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false) && !gameAllowed;
  const { company: companyName, name } = props.profile;

  const buyPending = props.buyPending || props.getGamesPending;

  const data = get(props.personalGameHeadRating, 'data', false);
  const fields = get(props.personalGameHeadRating, 'fields', false);

  const gameOptionsValues = get(currentGameInfo, 'OptionsValues');

  const championConspectus = !!get(gameOptionsValues, 'championConspectus');
  const superGameAvailable = !!get(gameOptionsValues, 'superGameAvailable');
  const showPresettingScreen = !!get(gameOptionsValues, 'showPresettingScreen');

  return {
    currentGameInfo,
    gameAllowed,
    isTrialGame,
    companyName,
    name,
    buyPending,
    data,
    fields,
    championConspectus,
    superGameAvailable,
    showPresettingScreen,
  };
});

const withBuyProps = withProps((props) => {
  const currentGameInfo = find(props.allGames, { GameID: props.activeGameId }, false);

  const gameOptionsValues = get(currentGameInfo, 'OptionsValues');

  const promoPreviewText = get(gameOptionsValues, 'PromoPreviewText');
  const promoURL = get(gameOptionsValues, 'PromoURL');
  const previewButtonText = get(gameOptionsValues, 'PreviewButtonText');

  return {
    promoPreviewText,
    promoURL,
    previewButtonText,
  };
});

const withIsGuestProps = withProps((props) => {
  let isGuest = false;

  // TODO: HARDCODE_TYPE_2 зашито определениея что пользователь гость по начилию у него в e-mail строки @demo.demo
  const email = get(props.profile, 'userEmail', null);

  if (email && email.indexOf('@demo.demo') !== -1) {
    isGuest = true;
  }

  return {
    isGuest,
  };
});

const enhance = compose(
  withConnect1,
  withConnect2,
  withLifecycle,
  withAllGamesProps,
  withCustomProps,
  withCustomHandlers,
  withIsGuestProps,
  withBuyProps,
);

export default enhance;
