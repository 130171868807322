import styled from 'styled-components';
import Bg from '../../../../assets/owl.png';
import DialogBg from '../images/text-after.svg';

const OwlImage = styled.div`
  background-image: url(${Bg});
  background-size: contain;
  bottom: 10px;
  height: 191px;
  position: fixed;
  right: 180px;
  width: 102px;
  z-index: 25;
  pointer-events: auto;
  
  ${props => props.owlInCenter && `
    position: fixed;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  `}
  
  &::after {
    background-image: url(${DialogBg});
    bottom: -55px;
    content: '';
    display: block;
    height: 55px;
    position: absolute;
    right: -30px;
    width: 55px;
    top: -30px;
  }
`;

export default OwlImage;
