import { createSelector } from 'reselect';

const browserState = state => state.get('browser');

const makeSelectBrowser = () => createSelector(
  browserState,
  state => state,
);

export {
  makeSelectBrowser,
};
