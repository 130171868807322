import React from 'react';

import Content from '../../../../components/Content/index';
import H2 from '../../../../components/H2/index';
import Section from '../../../../components/Section/index';
import H1 from './includes/H1';
import Text from './includes/Text';
import TextBlock from './includes/TextBlock';
import TextWrapper from './includes/TextWrapper';

const ProcessingPersonalData = () => (
  <Section>
    <Content>
      <TextWrapper>
        <TextBlock>
          <div>
            <p className="p1"><span className="s1"><strong>Политика в&nbsp;отношении обработки персональных данных</strong></span></p>
            <p className="p2"><span className="s1">1. Общие положения</span></p>
            <p className="p3"><span className="s1">Настоящая политика обработки персональных данных составлена в&nbsp;соответствии с&nbsp;требованиями Федерального закона от&nbsp;27.07.2006. &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; и&nbsp;определяет порядок обработки персональных данных и&nbsp;меры по&nbsp;обеспечению безопасности персональных данных ИП&nbsp;Меликсетян Сергей Сейранович (далее&nbsp;&mdash; Оператор).</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей деятельности соблюдение прав и&nbsp;свобод человека и&nbsp;гражданина при обработке его персональных данных, в&nbsp;том числе защиты прав на&nbsp;неприкосновенность частной жизни, личную и&nbsp;семейную тайну.</span></li>
              <li className="li4"><span className="s2">Настоящая политика Оператора в&nbsp;отношении обработки персональных данных (далее&nbsp;&mdash; Политика) применяется ко&nbsp;всей информации, которую Оператор может получить о&nbsp;посетителях веб-сайта https://readom.pro.</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">2. Основные понятия, используемые в&nbsp;Политике</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Автоматизированная обработка персональных данных&nbsp;&mdash; обработка персональных данных с&nbsp;помощью средств вычислительной техники;</span></li>
              <li className="li4"><span className="s2">Блокирование персональных данных&nbsp;&mdash; временное прекращение обработки персональных данных (за&nbsp;исключением случаев, если обработка необходима для уточнения персональных данных);</span></li>
              <li className="li4"><span className="s2">Веб-сайт&nbsp;&mdash; совокупность графических и&nbsp;информационных материалов, а&nbsp;также программ для ЭВМ и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети интернет по&nbsp;сетевому адресу https://readom.pro;</span></li>
              <li className="li4"><span className="s2">Информационная система персональных данных&nbsp;&mdash; совокупность содержащихся в&nbsp;базах данных персональных данных, и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий и&nbsp;технических средств;</span></li>
              <li className="li4"><span className="s2">Обезличивание персональных данных&nbsp;&mdash; действия, в&nbsp;результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</span></li>
              <li className="li4"><span className="s2">Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий (операций), совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств с&nbsp;персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</span></li>
              <li className="li4"><span className="s2">Оператор&nbsp;&mdash; государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с&nbsp;другими лицами организующие&nbsp;и (или) осуществляющие обработку персональных данных, а&nbsp;также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с&nbsp;персональными данными;</span></li>
              <li className="li4"><span className="s2">Персональные данные&nbsp;&mdash; любая информация, относящаяся прямо или косвенно к&nbsp;определенному или определяемому Пользователю веб-сайта https://readom.pro;</span></li>
              <li className="li4"><span className="s2">Пользователь&nbsp;&mdash; любой посетитель веб-сайта https://readom.pro;</span></li>
              <li className="li4">10.<span className="s2">Предоставление персональных данных&nbsp;&mdash; действия, направленные на&nbsp;раскрытие персональных данных определенному лицу или определенному кругу лиц;</span></li>
              <li className="li4">11.<span className="s2">Распространение персональных данных&nbsp;&mdash; любые действия, направленные на&nbsp;раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на&nbsp;ознакомление с&nbsp;персональными данными неограниченного круга лиц, в&nbsp;том числе обнародование персональных данных в&nbsp;средствах массовой информации, размещение в&nbsp;информационно-телекоммуникационных сетях или предоставление доступа к&nbsp;персональным данным каким-либо иным способом;</span></li>
              <li className="li4">12.<span className="s2">Трансграничная передача персональных данных&nbsp;&mdash; передача персональных данных на&nbsp;территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</span></li>
              <li className="li4">13.<span className="s2">Уничтожение персональных данных&nbsp;&mdash; любые действия, в&nbsp;результате которых персональные данные уничтожаются безвозвратно с&nbsp;невозможностью дальнейшего восстановления содержания персональных данных в&nbsp;информационной системе персональных данных&nbsp;и (или) результате которых уничтожаются материальные носители персональных данных.</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">3. Оператор может обрабатывать следующие персональные данные Пользователя</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Фамилия, имя, отчество;</span></li>
              <li className="li4"><span className="s2">Электронный адрес;</span></li>
              <li className="li4"><span className="s2">Номера телефонов;</span></li>
              <li className="li4"><span className="s2">Также на&nbsp;сайте происходит сбор и&nbsp;обработка обезличенных данных о&nbsp;посетителях (в&nbsp;т.ч. файлов &laquo;cookie&raquo;) с&nbsp;помощью сервисов интернет-статистики (Яндекс Метрика и&nbsp;Гугл Аналитика и&nbsp;других).</span></li>
              <li className="li4"><span className="s2">Вышеперечисленные данные далее по&nbsp;тексту Политики объединены общим понятием Персональные данные.</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">4. Цели обработки персональных данных</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Цель обработки персональных данных Пользователя&nbsp;&mdash; информирование Пользователя посредством отправки электронных писем; предоставление доступа Пользователю к&nbsp;сервисам, информации и/или материалам, содержащимся на&nbsp;веб-сайте.</span></li>
              <li className="li4"><span className="s2">Также Оператор имеет право направлять Пользователю уведомления о&nbsp;новых продуктах и&nbsp;услугах, специальных предложениях и&nbsp;различных событиях. Пользователь всегда может отказаться от&nbsp;получения информационных сообщений, направив Оператору письмо на&nbsp;адрес электронной почты captain@readom.pro с&nbsp;пометкой &laquo;Отказ от&nbsp;уведомлениях о&nbsp;новых продуктах и&nbsp;услугах и&nbsp;специальных предложениях&raquo;, либо перейдите по&nbsp;ссылке, указанной в&nbsp;нижней части любого нашего письма.</span></li>
              <li className="li4"><span className="s2">Обезличенные данные Пользователей, собираемые с&nbsp;помощью сервисов интернет-статистики, служат для сбора информации о&nbsp;действиях Пользователей на&nbsp;сайте, улучшения качества сайта и&nbsp;его содержания.</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">5. Правовые основания обработки персональных данных</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Оператор обрабатывает персональные данные Пользователя только в&nbsp;случае их&nbsp;заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на&nbsp;сайте <a href="https://readom.pro"><span className="s3">https://readom.pro</span></a> и&nbsp;его поддоменах. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с&nbsp;данной Политикой.</span></li>
              <li className="li4"><span className="s2">Оператор обрабатывает обезличенные данные о&nbsp;Пользователе в&nbsp;случае, если это разрешено в&nbsp;настройках браузера Пользователя (включено сохранение файлов &laquo;cookie&raquo; и&nbsp;использование технологии JavaScript).</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">6. Порядок сбора, хранения, передачи и&nbsp;других видов обработки персональных данных</span></p>
            <p className="p3"><span className="s1">Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и&nbsp;технических мер, необходимых для выполнения в&nbsp;полном объеме требований действующего законодательства в&nbsp;области защиты персональных данных.</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Оператор обеспечивает сохранность персональных данных и&nbsp;принимает все возможные меры, исключающие доступ к&nbsp;персональным данным неуполномоченных лиц.</span></li>
              <li className="li4"><span className="s2">Персональные данные Пользователя никогда, ни&nbsp;при каких условиях не&nbsp;будут переданы третьим лицам, за&nbsp;исключением случаев, связанных с&nbsp;исполнением действующего законодательства.</span></li>
              <li className="li4"><span className="s2">В&nbsp;случае выявления неточностей в&nbsp;персональных данных, Пользователь может актуализировать их&nbsp;самостоятельно в&nbsp;личном кабинете.</span></li>
              <li className="li4"><span className="s2">Срок обработки персональных данных является неограниченным. Пользователь может в&nbsp;любой момент отозвать свое согласие на&nbsp;обработку персональных данных, направив Оператору уведомление посредством электронной почты на&nbsp;электронный адрес Оператора captain@readom.pro с&nbsp;пометкой &laquo;Отзыв согласия на&nbsp;обработку персональных данных&raquo;.</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">7. Трансграничная передача персональных данных</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных обязан убедиться в&nbsp;том, что иностранным государством, на&nbsp;территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</span></li>
              <li className="li4"><span className="s2">Трансграничная передача персональных данных на&nbsp;территории иностранных государств, не&nbsp;отвечающих вышеуказанным требованиям, может осуществляться только в&nbsp;случае наличия согласия в&nbsp;письменной форме субъекта персональных данных на&nbsp;трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.</span></li>
            </ol>
            <p className="p5"><span className="s1"> </span></p>
            <p className="p2"><span className="s1">8. Заключительные положения</span></p>
            <ol className="ol1">
              <li className="li4"><span className="s2">Пользователь может получить любые разъяснения по&nbsp;интересующим вопросам, касающимся обработки его персональных данных, обратившись к&nbsp;Оператору с&nbsp;помощью электронной почты captain@readom.pro.</span></li>
              <li className="li4"><span className="s2">В&nbsp;данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до&nbsp;замены ее&nbsp;новой версией.</span></li>
              <li className="li4"><span className="s2">Актуальная версия Политики в&nbsp;свободном доступе расположена в&nbsp;сети Интернет по&nbsp;адресу https://readom.pro/processing-personal-data.</span></li>
            </ol>
          </div>

          <H1>Контактная информация организации</H1>
          <Text>
            <p>Индивидуальный предприниматель Меликсетян Сергей Сейранович</p>
            <p>ОГРНИП 307565830400370 ИНН 561604624301</p>
            <p>Юридический адрес: 141435, Московская обл.,</p>
            <p>г. Химки, Микрорайон Новогорск, ул. Заречная, д. 7, корп. 1, кв. 7</p>
            <p>Почтовый адрес: 630004 г. Новосибирск, ул. Вокзальная магистраль, 1/1, офис 608.</p>

            <p>Реквизиты банка:</p>
            <p>р/с 40802810323250000110</p>
            <p>в филиал «Новосибирский» ОАО «Альфа-Банк» г. Новосибирск</p>
            <p>к/с 30101810600000000774</p>
            <p>БИК 045004774</p>
          </Text>
        </TextBlock>
      </TextWrapper>
    </Content>
  </Section>
);

export default ProcessingPersonalData;
