import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components';
import Content from '../../../Content';
import H2 from '../../../H2';
import Stage from './Stage';
import YesSVG from '../../../../assets/square-mark/yes.svg';
import NoSVG from '../../../../assets/square-mark/no.svg';
import UnknownSVG from '../../../../assets/square-mark/unknown.svg';

const SimpleStageText = ({ text }) => {
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

const StageTextWithMark = ({ text, markText, success }) => {
  let imgSrc = UnknownSVG;
  if (success === 1) {
    imgSrc = YesSVG;
  }

  if (success === 0) {
    imgSrc = NoSVG;
  }

  return (
    <span>
      <span dangerouslySetInnerHTML={{ __html: text }} /><br />
      <img src={imgSrc} alt="" />{' '}<span dangerouslySetInnerHTML={{ __html: markText }} />
    </span>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1420px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  
  @media (max-width: 1420px) {
    justify-content: center;
    margin-bottom: 15px;
  }
`;

const StageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ProgressHeader = styled.div`
  padding-bottom: 30px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const PassageStage = ({ dark, currentProgress, progressList }) => {
  if (!progressList || !progressList.length) {
    return null;
  }

  let number = 0;

  return (
    <Content>
      <Flex>
        <ProgressHeader>
          <H2 bold textAlign="center">Ваш игровой прогресс</H2>
        </ProgressHeader>
        <Root>
          <StageContainer>
            {map(progressList, (item) => {
              number += 1;

              const hasDetail = item.Detail.length > 0;

              if (!hasDetail) {
                return (
                  <Stage
                    isNextStep={currentProgress === number}
                    dark={dark}
                    key={number}
                    text={<SimpleStageText text={item.Title} />}
                    number={number}
                    completed={currentProgress > number}
                  />
                );
              }

              const detail = item.Detail[0];

              let isSuccess = null;

              if (currentProgress > number) {
                isSuccess = detail.Value;
              }

              return (
                <Stage
                  isNextStep={currentProgress === number}
                  dark={dark}
                  key={number}
                  text={<StageTextWithMark text={item.Title} success={isSuccess} markText={detail.Title} />}
                  number={number}
                  completed={currentProgress > number}
                />
              );
            })}
          </StageContainer>
        </Root>
      </Flex>
    </Content>
  );
};

PassageStage.propTypes = {
  dark: PropTypes.bool,
};

PassageStage.defaultProps = {
  dark: false,
};

export default PassageStage;
