import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import { darken } from 'polished';

import ActiveKey from './activeKey.svg';
import BuyBtn from './buy_button.svg';
import InactiveKey from './inactiveKey.svg';

const WrapperAnimation = posed.div({
  hoverable: true,
  init: {
    scale: 1,
    y: 0,
  },
  hover: {
    scale: 1.1,
  },
});

const Wrapper = styled(WrapperAnimation)`
  cursor: pointer;
  display: flex;
  position: relative;
  transition: opacity .3s ease-out;
  ${props => (!props.small && `
    height: calc((220px * 350) / 260);
    margin-right: 50px;
    margin-top: 25px;
    width: 220px;
  `)}
  ${props => (props.small && `
    height: calc((220px * 350) / 260);
    margin-right: 0;
    margin-top: 0;
    width: 220px;
  `)}
  
  ${props => (props.inPopup && `
    height: calc((220px * 350) / 290);
    margin-right: 0;
    margin-top: 0;
    width: 220px;
  `)}

  &:after {
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    bottom: 0;
    content: "";
    left: 0;
    opacity: ${props => (props.isActive ? '1' : '.5')};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    
    ${props => (props.inPopup && `
      background-position: 5px 0px;
      height: 265px;
      background-size: contain;
      z-index: 0;
  `)}
  }

  &:hover {
    & .addKey {
      background-color: ${darken(0.1, '#35BB9A')};
    }
  }
`;

const InactiveWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const NewIconAnimation = posed.div({
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    delay: 2000,
  },
});

const NewIcon = styled(NewIconAnimation)`
  z-index: 2;
  background-color: #D7244B;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, .2) 0 1px 1px 0;
  user-select: none;
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  right: -6px;
  text-align: center;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 38px;
`;

const KeysWrapper = styled.div`
  background-color: #EBEBED;
  border-radius: 25px;
  display: flex;
  height: 50px;
  position: relative;
  z-index: 2;
`;

const Key = styled.div`
  background-color: ${props => (props.active ? '#31A018' : '#EBEBED')};
  background-image: url(${props => (props.active ? ActiveKey : InactiveKey)});
  background-position: center;
  background-size: 23px 33px;
  border-right: 1px solid #EBEBED;
  width: 44px;

  &:first-child {
    border-radius: 25px 0 0 25px;
  }

  &:last-child {
    border-radius: 0 25px 25px 0;
  }

  &:only-child {
    border-radius: 25px;
    width: 70px;
  }
`;

const AddKey = styled.div`
  background-color: #35BB9A;
  background-image: url(${BuyBtn});
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  margin-top: 15px;
  position: relative;
  transition: background .3s ease-out;
  width: 50px;
  z-index: 2;
`;

class Book extends React.PureComponent {
  onBookClick = () => {
    if (this.props.inPopup) {
      return;
    }

    if (this.props.isActive === true) {
      this.props.onClickActive(this.props.groupId, this.props.id, this.props.backgroundImage);
    } else {
      this.props.onClickInactive((this.props.keyCount - this.props.keyHas), this.props.id, this.props.backgroundImage);
    }
  };

  renderKeys = () => {
    const {
      keyCount,
      keyHas,
    } = this.props;
    const keysArray = [];

    for (let i = 0; i < keyCount; i += 1) {
      if (i < keyHas) {
        keysArray[i] = 1;
      } else {
        keysArray[i] = 0;
      }
    }

    let i = 0;
    return keysArray.map((key) => {
      const result = (
        <Key
          key={i}
          active={key === 1}
        />
      );
      i += 1;

      return result;
    });
  };

  render() {
    const { small, inPopup } = this.props;

    return (
      <Wrapper
        id={`big-card-book-${this.props.id}`}
        small={small}
        backgroundImage={this.props.backgroundImage}
        isActive={this.props.isActive}
        onClick={this.onBookClick}
        inPopup={inPopup}
      >
        <PoseGroup>
          {this.props.isActive && this.props.isNew && (
            <NewIcon title="Вам стала доступна новая карточка" key="NewIcon">NEW</NewIcon>
          )}
        </PoseGroup>
        {!this.props.isActive && (
          <InactiveWrapper>
            <KeysWrapper>
              {this.renderKeys()}
            </KeysWrapper>
            {!inPopup && (
              <AddKey
                className="addKey"
              />
            )}
          </InactiveWrapper>
        )}
      </Wrapper>
    );
  }
}

Book.propTypes = {
  backgroundImage: PropTypes.string,
  groupId: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  inPopup: PropTypes.bool,
  isNew: PropTypes.bool,
  keyCount: PropTypes.number,
  keyHas: PropTypes.number,
  onClickActive: PropTypes.func,
  onClickInactive: PropTypes.func,
};

Book.defaultProps = {
  inPopup: false,
  isNew: false,
  backgroundImage: '',
};

export default Book;
