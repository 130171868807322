import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import dateAndTime from 'date-and-time';
import {
  call, put, select, takeEvery, all, delay,
} from 'redux-saga/effects';
import { makeSelectActiveGameId } from '../../modules/activeGame/selectors';
import { getGameStageSuccess, getGameStageFail } from '../../modules/buyGame/actions';
import { makeSelectOwnedGameStage } from '../../modules/buyGame/selectors';
import { makeSelectGames, makeSelectGamesShowcases } from '../../modules/games/selectors';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import {
  BUY_GAME_STAGE_1,
  BUY_GAME_STAGE_2,
  BUY_GAME_STAGE_3_6,
  BUY_GAME_STAGE_SO,
  GET_GAME_STAGE,
} from '../../modules/buyGame/constants';

import { apiRequest as createApiRequest } from '../../../utils/request';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* getGameStage() {
    const gameId = yield select(makeSelectActiveGameId());

    try {
      let games = yield select(makeSelectGames());
      let gamesShowcases = yield select(makeSelectGamesShowcases());

      // TODO: HOTFIX надо переделать чтобы без datapolling работало
      while (!games || !gamesShowcases) {
        yield delay(100);

        games = yield select(makeSelectGames());
        gamesShowcases = yield select(makeSelectGamesShowcases());
      }

      const allGames = [...games, ...gamesShowcases];
      const currentGame = find(allGames, { GameID: gameId });
      const ownedGameStage = get(currentGame, 'OptionsValues.ownedGameStage');

      yield put(getGameStageSuccess(ownedGameStage));
    } catch (err) {
      yield put(getGameStageFail());
    } finally {
      yield put(getGameStageFail());
    }
  }

  function* clearCart() {
    const url = yield select(makeSelectApiUrl());
    const cartResultUrl = `${url}/RecycleInvoiceGetForUser/`;

    try {
      const cartResult = yield call(apiRequest, cartResultUrl);

      const cart = get(cartResult, 'data.result[0].Recycle.InvoiceItemList');

      if (cart.length) {
        const clearCartRequests = map(cart, (item) => {
          const removeItemFromCartUrl = `${url}/RecycleItemDelete/${encodeURIComponent(item.InvoiceItemID)}`;
          return call(apiRequest, removeItemFromCartUrl);
        });

        yield all(clearCartRequests);
      }
    } catch (err) {
      alert('Ошибка формирования заказа. Обновите страницу и повторите снова.');
    }
  }

  function* getShowcases() {
    const url = yield select(makeSelectApiUrl());
    const showcaseResultUrl = `${url}/ShowcaseGetAllForUser/`;

    try {
      const showcaseResult = yield call(apiRequest, showcaseResultUrl);
      return get(showcaseResult, 'data.result[0].ShowcaseList');
    } catch (err) {
      alert('Ошибка получения корзины. Обновите страницу и повторите снова.');
    }

    return null;
  }

  function* buyStage1Saga() {
    // TODO: HARDCODE_TYPE_2 зашито системное наименование продукта игры МВК
    const pr1 = 'million_base';
    const pr2 = 'million_1_stage';

    yield getGameStage();

    const ownedGameStage = yield select(makeSelectOwnedGameStage());
    if (ownedGameStage >= 1) {
      return;
    }

    yield clearCart();

    const showcases = yield getShowcases();
    const url = yield select(makeSelectApiUrl());

    const showcaseItems = [];

    const id1 = get(find(showcases, { ProductNameSys: pr1 }), 'ShowcaseID');
    const id2 = get(find(showcases, { ProductNameSys: pr2 }), 'ShowcaseID');

    showcaseItems.push(id1);
    showcaseItems.push(id2);

    const addToCart = map(showcaseItems, (showcaseId) => {
      const addUrl = `${url}/RecycleItemAddForUser/${encodeURIComponent(showcaseId)}`;
      return call(apiRequest, addUrl);
    });

    yield all(addToCart);

    const makeInvoiceUrl = `${url}/InvoiceMakeForUser`;
    yield call(apiRequest, makeInvoiceUrl);

    const getInvoiceUrl = `${url}/InvoiceGetAllForUser`;
    const getInvoiceResult = yield call(apiRequest, getInvoiceUrl);

    const invoices = get(getInvoiceResult, 'data.result[0].InvoiceList');
    if (invoices) {
      const pendingInvoices = filter(invoices, invoice => get(invoice, 'InvoiceStatus') === 2);

      const pendingInvoicesWithUTC = sortBy(map(pendingInvoices, (item) => {
        const date = dateAndTime.parse(item.RecordDateCreate, 'DD.MM.YYYY HH:mm:ss');

        return {
          date,
          timestamp: date.getTime(),
          ...item,
        };
      }), ['timestamp']);

      const lastInvoice = last(pendingInvoicesWithUTC);

      window.location.href = lastInvoice.URLPayment;
    }
  }

  function* buyStage2Saga() {
    // TODO: HARDCODE_TYPE_2 зашито системное наименование продукта игры МВК
    const pr1 = 'million_2_stage';
    const url = yield select(makeSelectApiUrl());

    const ownedGameStage = yield select(makeSelectOwnedGameStage());
    if (ownedGameStage >= 2) {
      return;
    }

    try {
      const showcases = yield getShowcases();
      const showcaseId = get(find(showcases, { ProductNameSys: pr1 }), 'ShowcaseID');
      const buyRequestURL = `${url}/BuyShowcase/${encodeURIComponent(showcaseId)}/`;

      const buyRequestResult = yield call(apiRequest, buyRequestURL);
      const isSuccess = get(buyRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while add buy showcase');
      }

      const URLPayment = get(buyRequestResult, 'data.result[0].URLPayment', null);

      window.location.href = URLPayment;
      return;
    } catch (err) {
      console.error(`Ошибка при покупке ${pr1}`);
    }
  }

  function* buyStage36Saga() {
    // TODO: HARDCODE_TYPE_2 зашито системное наименование продукта игры МВК
    const pr1 = 'million_3_6_stages';
    yield getGameStage();

    const ownedGameStage = yield select(makeSelectOwnedGameStage());
    if (ownedGameStage >= 3) {
      return;
    }

    const url = yield select(makeSelectApiUrl());

    try {
      const showcases = yield getShowcases();
      const showcaseId = get(find(showcases, { ProductNameSys: pr1 }), 'ShowcaseID');
      const buyRequestURL = `${url}/BuyShowcase/${encodeURIComponent(showcaseId)}/`;

      const buyRequestResult = yield call(apiRequest, buyRequestURL);
      const isSuccess = get(buyRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while add buy showcase');
      }

      const URLPayment = get(buyRequestResult, 'data.result[0].URLPayment', null);

      window.location.href = URLPayment;
      return;
    } catch (err) {
      console.error(`Ошибка при покупке ${pr1}`);
    }
  }

  function* buyStageSOSaga() {
    // TODO: HARDCODE_TYPE_2 зашито системное наименование продукта игры МВК
    const pr1 = 'million_discount_80';
    const pr2 = 'million_discount_80_stages';

    yield getGameStage();

    const ownedGameStage = yield select(makeSelectOwnedGameStage());
    if (ownedGameStage >= 1) {
      return;
    }

    yield clearCart();

    const showcases = yield getShowcases();
    const url = yield select(makeSelectApiUrl());

    const showcaseItems = [];

    const id1 = get(find(showcases, { ProductNameSys: pr1 }), 'ShowcaseID');
    const id2 = get(find(showcases, { ProductNameSys: pr2 }), 'ShowcaseID');

    showcaseItems.push(id1);
    showcaseItems.push(id2);

    const addToCart = map(showcaseItems, (showcaseId) => {
      const addUrl = `${url}/RecycleItemAddForUser/${encodeURIComponent(showcaseId)}`;
      return call(apiRequest, addUrl);
    });

    yield all(addToCart);

    const makeInvoiceUrl = `${url}/InvoiceMakeForUser`;
    yield call(apiRequest, makeInvoiceUrl);

    const getInvoiceUrl = `${url}/InvoiceGetAllForUser`;
    const getInvoiceResult = yield call(apiRequest, getInvoiceUrl);

    const invoices = get(getInvoiceResult, 'data.result[0].InvoiceList');
    if (invoices) {
      const pendingInvoices = filter(invoices, invoice => get(invoice, 'InvoiceStatus') === 2);

      const pendingInvoicesWithUTC = sortBy(map(pendingInvoices, (item) => {
        const date = dateAndTime.parse(item.RecordDateCreate, 'DD.MM.YYYY HH:mm:ss');

        return {
          date,
          timestamp: date.getTime(),
          ...item,
        };
      }), ['timestamp']);

      const lastInvoice = last(pendingInvoicesWithUTC);

      window.location.href = lastInvoice.URLPayment;
    }
  }

  function* saga() {
    yield takeEvery(GET_GAME_STAGE, getGameStage);
    yield takeEvery(BUY_GAME_STAGE_1, buyStage1Saga);
    yield takeEvery(BUY_GAME_STAGE_2, buyStage2Saga);
    yield takeEvery(BUY_GAME_STAGE_3_6, buyStage36Saga);
    yield takeEvery(BUY_GAME_STAGE_SO, buyStageSOSaga);
  }

  return saga;
}

export default build;
