import {
  SET_PREV_RATING,
} from './constants';

export function setPrevRating(payload) {
  return {
    type: SET_PREV_RATING,
    payload,
  };
}
