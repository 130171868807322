import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import pure from 'recompose/pure';
import { withCookies } from 'react-cookie';
import { createStructuredSelector } from 'reselect';
import { useMedia } from 'the-platform';

import { makeSelectLeftMenuState } from '../../redux/modules/global/selectors';
import isUserAuthenticated from '../../utils/isUserAuthenticated';
import { device } from '../../utils/mediaQueries';

const mapStateToProps = createStructuredSelector({
  leftMenuState: makeSelectLeftMenuState(),
});

const withConnect = connect(mapStateToProps, null);

const withOwnProps = withProps((props) => {
  const small = useMedia(device.toggleMenu);
  const isAuthenticated = isUserAuthenticated(props.cookies);

  let leftMenuPadding = 0;

  if (!small && isAuthenticated) {
    leftMenuPadding = 65;

    if (props.leftMenuState) {
      leftMenuPadding = 240;
    }
  }

  return ({
    isAuthenticated,
    small,
    leftMenuPadding,
  });
});

const enhance = compose(
  pure,
  withCookies,
  withConnect,
  withOwnProps,
);

export default enhance;
