import React from 'react';
import { ErrorMessage } from 'formik';
import Error from '../../components/Error';
import Input from '../../components/Input';

const renderInput = ({ field, form: { errors, touched, submitCount }, ...props }) => {
  const isInvalid = errors[field.name] && (touched[field.name] || submitCount > 0);

  return (
    <>
      <Input
        isValid={!isInvalid}
        {...field}
        {...props}

      />
      <Error>
        <ErrorMessage name={field.name} />
      </Error>
    </>
  );
};

export default renderInput;
