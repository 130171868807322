import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '../styled/Dialog';
import OwlImage from '../styled/OwlImage';
import OwlWrapper from '../styled/OwlWrapper';

class DesktopOwl extends React.PureComponent {
  static propTypes = {
    renderContent: PropTypes.func.isRequired,
    owlInCenter: PropTypes.bool.isRequired,
  };

  render() {
    const { owlInCenter } = this.props;

    return (
      <OwlWrapper owlInCenter={owlInCenter}>
        <OwlImage owlInCenter={owlInCenter}>
          <Dialog owlInCenter={owlInCenter}>
            {this.props.renderContent({
              // setOwlInCenter: this.setOwlInCenter,
            })}
          </Dialog>
        </OwlImage>
      </OwlWrapper>
    );
  }
}

export default DesktopOwl;
