import { fromJS } from 'immutable';
import {
  SET_GAMES_OPTIONS,
  SET_GAMES_OPTIONS_FOR_SHOWCASE_GAMES,
} from './constants';

const initialState = fromJS({
  options: null,
  optionsShowcase: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GAMES_OPTIONS:
      return state
        .set('options', action.payload.gamesOptions);
    case SET_GAMES_OPTIONS_FOR_SHOWCASE_GAMES:
      return state
        .set('optionsShowcase', action.payload.gamesOptions);
    default:
      return state;
  }
}

export default reducer;
