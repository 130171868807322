import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PoseGroup } from 'react-pose';

import RefreshImage from '../../assets/tips/refresh.svg';
import { makeSelectSuccessStateRefreshContent } from '../../redux/modules/debug/selectors';

import { Img, Event } from './components';

class HighlightTipChangeCountEvent extends React.PureComponent {
  static defaultProps = {
    tipCount: null,
  };

  static propTypes = {
    tipCount: PropTypes.number,
  };

  state = {
    updated: false,
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.isSuccess && nextProps.isSuccess) {
      this.setState({
        updated: true,
      });
    }
  }

  reset = (pose) => {
    if (pose === 'enter') {
      this.setState({
        updated: false,
      });
    }
  };

  render() {
    const { updated } = this.state;

    return (
      <PoseGroup>
        {updated && (
          <Event
            key="screenContentUpdate"
            onPoseComplete={this.reset}
          >
            <Img
              src={RefreshImage}
              width={40}
              height={20}
              alt=""
            />
            <span>Данные обновлены</span>
          </Event>
        )}
      </PoseGroup>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isSuccess: makeSelectSuccessStateRefreshContent(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(HighlightTipChangeCountEvent);
