import styled from 'styled-components';

const Text = styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-weight: ${props => (props.bold ? 700 : 400)};
  font-size: 15px;
  line-height: 25px;
  margin: 25px 0;
  text-align: justify;
`;

export default Text;
