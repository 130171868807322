import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import React from 'react';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import styled from 'styled-components';
import Typograf from 'typograf';

import RatingContent from '../RatingContent';
import Table from '../../containers/pages/RatingAllPage/includes/Table';

const CustomRatingContent = styled(RatingContent)`
  @media (max-width: 1500px) {
    overflow: auto;
  }
`;

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const CompanyDirectorRating = (
  {
    toggleOpenedEmployee, isLoadingRating,
    ratingForOneGame, openedEmployee, myEmployeeId,
    fields,
  },
) => {
  if (isLoadingRating || !ratingForOneGame) {
    return <RatingContent />;
  }

  let extendedRatingForEmployeeId = null;

  const gameRating = ratingForOneGame;

  const tableHeader = map(
    filter(fields, field => !field.IsSystem),
    (item, index) => ({
      key: index + 1,
      width: item.Width,
      title: item.Name,
      tooltip: tp.execute(item.Hint),
    }),
  );

  let tableItems = [];

  if (!isNull(gameRating) && gameRating) {
    tableItems = gameRating
      .filter(item => !item.outTop)
      .map((item, index) => {
        if (item.employeeid === openedEmployee) {
          extendedRatingForEmployeeId = item.employeeid;
        }

        const rowData = map(
          filter(fields, field => !field.IsSystem),
          (field, index) => ({
            key: index + 1,
            width: field.Width,
            value: item[field.FieldName],
            field,
          }),
        );

        return {
          key: item.employeeid,
          outOfList: false,
          isMyRating: item.employeeid === myEmployeeId,
          isChampion: rowData.position === 0,
          onClick: toggleOpenedEmployee,
          items: rowData,
        };
      });
  }

  return (
    <CustomRatingContent>
      <Table
        minWidth="1400px"
        header={tableHeader}
        items={tableItems}
        extendedRatingForEmployeeId={extendedRatingForEmployeeId}
        toggleAdditionalLine={toggleOpenedEmployee}
      />
    </CustomRatingContent>
  );
};

CompanyDirectorRating.propTypes = {
  isLoadingRating: PropTypes.bool.isRequired,
  myEmployeeId: PropTypes.string.isRequired,
  openedEmployee: PropTypes.string,
  ratingForOneGame: PropTypes.array,
  toggleOpenedEmployee: PropTypes.func.isRequired,
};

CompanyDirectorRating.defaultProps = {
  openedEmployee: null,
  ratingForOneGame: null,
};

export default CompanyDirectorRating;
