import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import pure from 'recompose/pure';
import styled from 'styled-components';
import {
  Formik, Field,
} from 'formik';
import Error from '../../../../../components/Error';
import renderCheckBox from '../../../../../FormikForms/inputs/renderCheckBox';
import renderInput from '../../../../../FormikForms/inputs/renderInput';
import URL_CONSTANTS from '../../../../../urlConstants';
import Popup from '../../Popup';
import PopupForm from '../../../../forms/templates/PopupForm/Form';
import GameCard from '../../PromoPage/includes/Card';
import MillonImageNormal from '../../../../../assets/gameCover/million/normal.jpg';
import GuestGameBuyValidateSchema from './GuestGameBuyValidateSchema';

const PopupText = styled.div`
  display: ${props => (props.br ? 'block' : 'inline')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};;
  font-size: 15px;
  line-height: ${props => (props.minHeight ? '17px' : '20px')};
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

const FormLineInput = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5px;
  & > div {
    width: 100%;
  }
`;

const FormLineCheckBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5px;
  & > div {
    width: 100%;
  }
`;

const NoWrap = pure(styled.span`
  word-break: keep-all;
  white-space: nowrap;
`);

const P = pure(styled.p`
  padding: 10px;
`);

const CenterFlex = pure(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`);

const StyledLink = pure(styled(Link)`
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  color: #808085;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  margin: 5px auto;
  padding: 0;
  transition: all .3s ease-out;
  text-decoration: none;

  &:hover, &:active {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #1C1C1C;
  }
`);

const FormikBuyGamePopup = (
  {
    values,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    status,
    ...rest
  },
) => (
  <Popup
    small={rest.small}
    title="Получить доступ"
    pending={rest.pending}
    submit="Получить доступ"
    isActive={rest.open}
    onClose={rest.onClose}
    onSubmit={handleSubmit}
  >
    <PopupText>
      <div>
        <CenterFlex>
          <GameCard
            image={MillonImageNormal}
            name="Миллионы в&nbsp;твоём кармане"
            description="Самая полная профессиональная модель продаж в России."
          />
        </CenterFlex>
        <P>
          После оплаты на&nbsp;указанный вами e-mail придет письмо с&nbsp;приглашением.
        </P>
        {status && status.serverErrorMessage && (
        <Error>
          {status.serverErrorMessage}
        </Error>
        )}
        <PopupForm>
          <FormLineInput>
            <Field
              name="surname"
              type="text"
              required
              disabled={isSubmitting}
              placeholder="Ваша фамилия"
              component={renderInput}
            />
          </FormLineInput>
          <FormLineInput>
            <Field
              name="name"
              type="text"
              required
              disabled={isSubmitting}
              placeholder="Ваше имя"
              component={renderInput}
            />
          </FormLineInput>
          <FormLineInput>
            <Field
              name="email"
              type="text"
              required
              disabled={isSubmitting}
              placeholder="E-mail"
              component={renderInput}
            />
          </FormLineInput>

          <br />
          <FormLineCheckBox>
            <Field
              name="personalProcessingDataAgreement"
              required
              title={(
                <span>
                    Я согласен на обработку
                  {' '}
                  <StyledLink to={URL_CONSTANTS.URLS.PROCESSING_PERSONAL_DATA} target="_blank">
                        персональных данных
                    </StyledLink>
                </span>
                )}
              component={renderCheckBox}
              disabled={isSubmitting}
            />
          </FormLineCheckBox>
          <FormLineCheckBox>
            <Field
              name="gameLicenceAgreement"
              required
              title={(
                <span>
                      Я согласен с
                  {' '}
                  <StyledLink to={URL_CONSTANTS.URLS.LICENCE} target="_blank">
                      лицензионным соглашением
                    </StyledLink>
                </span>
                )}
              component={renderCheckBox}
              disabled={isSubmitting}
            />
          </FormLineCheckBox>
        </PopupForm>
      </div>
    </PopupText>
  </Popup>
);

const initialValues = {
  name: '',
  email: '',
  surname: '',
  personalProcessingDataAgreement: true,
  gameLicenceAgreement: true,
};

class MVKPromoBuyPopup extends React.PureComponent {
  onSubmit = (values, formik) => {
    // Получаем ссылку и перенаправляем пользователя
    const email = values.email.trim().toLowerCase();
    const name = values.name.trim();
    const surname = values.surname.trim();

    this.props.onSubmit({
      email, name, surname, formik,
    });
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={GuestGameBuyValidateSchema}
        onSubmit={this.onSubmit}
      >
        {props => (
          <FormikBuyGamePopup
            {...props}
            pending={props.isSubmitting}
            small={this.props.small}
            open={this.props.open}
            onClose={this.props.onClose}
          />
        )}
      </Formik>
    );
  }
}

MVKPromoBuyPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MVKPromoBuyPopup;
