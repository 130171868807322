import styled from 'styled-components';

const CardHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  color: #1C1C1C;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  justify-content: space-between;
  padding: 15px;
`;

export default CardHeader;
