import React from 'react';
import { Route } from 'react-router-dom';
import ActiveGameDependedContainer from './includes/ActiveGameDependedContainer';

class ActiveGameDependedRoute extends React.PureComponent {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => (
          <ActiveGameDependedContainer>
            <Component {...props} />
          </ActiveGameDependedContainer>
        )}
      />
    );
  }
}

export default ActiveGameDependedRoute;
