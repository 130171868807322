import React from 'react';
import pure from 'recompose/pure';
import styled, { createGlobalStyle } from 'styled-components';
import { Base64 } from 'js-base64';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Success from '../../../components/RoundMark/Success';
import Button from '../../../components/useThis/Button';
import DelayedRedirect from '../../route/DealyedRedirect';
import axios from '../../../utils/customAxios';
import LogoIcon from './logo.svg';

import URL_CONSTANTS from '../../../urlConstants';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  margin: 0;
  margin: auto;
  min-height: 100vh;
  padding: 20px 0;
  overflow: hidden;
  width: 100%;
`;

const Logo = styled.div`
  background-image: url(${LogoIcon});
  background-position: center;
  background-size: 202px 139px;
  cursor: pointer;
  height: 139px;
  margin: 0 auto;
  width: 202px;
`;

const Title = styled.div`
  color: #1B1B1B;
  font-size: 30px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 46px;
  text-align: center;
  
  @media (max-width: 400px) {
    margin-top: 20px;
    font-size: 23px;
    padding: 0 10px;
  }
`;

const GlobalStyle = createGlobalStyle`
  #app {
    background-color: #FFFFFF !important;
  }
`;

const SuccessWrapper = pure(styled.div`
  display: flex;
  justify-content: center;
`);

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const REDIRECT_TIMEOUT = 5;

class UserActivationPage extends React.Component {
  state = {
    pending: true,
    success: null,
  };

  handleRedirect = () => {
    this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
  };

  sendRequest = () => {
    const { token } = this.props.match.params;

    const tokenEncoded = Base64.decode(token);
    const requestURL = `${process.env.REACT_APP_API_URL}/useractivation?id=${tokenEncoded}`;

    axios(requestURL).then((result) => {
      if (result.data.indexOf('Указанный аккаунт уже активирован.') !== -1) {
        this.props.history.push(URL_CONSTANTS.URLS.LOGIN);

        return;
      }

      if (result.data.indexOf('Пользователь успешно активирован') !== -1) {
        this.setState({
          pending: false,
          success: true,
        });
      }
    }).catch(() => {
      this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
    });
  };

  componentDidMount() {
    this.sendRequest();
  }

  render() {
    const { pending, success } = this.state;

    return (
      <Wrapper>
        <div>
          <GlobalStyle />
          <Logo onClick={this.handleRedirect} />
          {pending && (
            <>
              <Title>
                Активация аккаунта
              </Title>
              <LoadingIndicator />
            </>
          )}
          {success && (
            <>
              <Title>
                Аккаунт активирован
              </Title>
              <SuccessWrapper>
                <Success />
              </SuccessWrapper>
              <DelayedRedirect to={URL_CONSTANTS.URLS.LOGIN} delay={REDIRECT_TIMEOUT} />
              <ButtonWrapper>
                <Button onClick={this.handleRedirect}>
                  Войти в аккаунт
                </Button>
              </ButtonWrapper>
            </>
          )}
        </div>
      </Wrapper>
    );
  }
}

export default UserActivationPage;
