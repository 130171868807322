import isNull from 'lodash/isNull';
import map from 'lodash/map';
import { connect } from 'react-redux';

import withHandlers from 'recompose/withHandlers';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import { addEmployee } from '../../../../redux/modules/forms/addEmployeeForm/actions';
import {
  makeSelectDepartments,
  makeSelectEmployeeAddPopup,
} from '../../../../redux/modules/employees/selectors';

import {
  makeSelectAddEmployeeError,
  makeSelectAddEmployeePending,
} from '../../../../redux/modules/forms/addEmployeeForm/selectors';

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: ({ values, formik }) => dispatch(addEmployee({ values, formik })),
  };
}

const mapStateToProps = createStructuredSelector({
  popupState: makeSelectEmployeeAddPopup(),
  departments: makeSelectDepartments(),
  serverErrorMessage: makeSelectAddEmployeeError(),
  pending: makeSelectAddEmployeePending(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
  withProps((props) => {
    let departmentsList = [];
    const { departments } = props;
    if (!isNull(departments)) {
      departmentsList = map(departments, department => ({
        value: department.id,
        label: department.name,
      }));
    }

    return { departmentsList };
  }),
  withHandlers({
    handleSubmit: props => (values, formik) => {
      const { onSubmitForm } = props;
      onSubmitForm({ values, formik });
    },
  }),
);

export default enhance;
