import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { device } from '../../utils/mediaQueries';
import UserPhoto from '../UserPhoto';

import EditIcon from './edit.svg';
import DeleteIcon from './delete.svg';

const Wrapper = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-top: 15px;
  margin-right: 15px;
  min-width: 320px;
  width: 400px;
  
  @media (max-width: 790px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    margin-top: 0;
    max-width: 400px;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const HeaderBody = styled.div`
  margin-left: 15px;
  margin-top: 6px;
  width: calc(100% - 55px);
`;

const Title = styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
`;

const RQ = styled.p`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Post = styled.p`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
`;

const SimpleBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px 15px 15px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftBody = styled.div`
  width: 40%;
`;

const RightBody = styled.div`
  text-align: right;
`;

const Text = styled.div`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 25px;
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: flex-end;
  padding-top: 25px;
  
  @media ${device.tablet} {
    padding-top: 40px;
    padding-bottom: 10px;
  }
`;

const Edit = styled.a`
  background-image: url(${EditIcon});
  background-size: cover;
  cursor: pointer;
  height: 15px;
  display: inline-block;
  margin-right: 15px;
  transition: opacity .3s ease-out;
  width: 15px;

  &:hover {
    opacity: .75;
  }
  
  @media ${device.tablet} {
    background-size: 20px;
    width: 50px;
    height: 50px;
    background-position: center;
    background-color: #f5f5f5;
    border-radius: 100%;
  }
`;

const Delete = styled.a`
  background-image: url(${DeleteIcon});
  background-size: cover;
  cursor: pointer;
  height: 15px;
  display: inline-block;
  transition: opacity .3s ease-out;
  width: 11px;

  &:hover {
    opacity: .75;
  }
  
  @media ${device.tablet} {
    background-size: 20px;
    width: 50px;
    height: 50px;
    background-position: center;
    background-color: #f5f5f5;
    border-radius: 100%;
  }
`;

const highlightStyle = {
  backgroundColor: '#ffeb3b',
};

class EmployeeCard extends React.PureComponent {
  delete = () => {
    this.props.onDelete(this.props.id);
  };

  edit = () => {
    this.props.onEdit({
      id: this.props.id,
      name: this.props.name,
      surname: this.props.surname,
      patronymic: this.props.patronymic,
      email: this.props.email,
    });
  };

  renderFullName = () => {
    const surname = (
      <Highlighter
        highlightStyle={highlightStyle}
        searchWords={[this.props.searchKeyword]}
        autoEscape
        textToHighlight={this.props.surname}
      />
    );

    const name = (
      <Highlighter
        highlightStyle={highlightStyle}
        searchWords={[this.props.searchKeyword]}
        autoEscape
        textToHighlight={this.props.name}
      />
    );

    const patronymic = (
      <Highlighter
        highlightStyle={highlightStyle}
        searchWords={[this.props.searchKeyword]}
        autoEscape
        textToHighlight={this.props.patronymic}
      />
    );

    return (
      <React.Fragment>
        {surname}
        {' '}
        {name}
        {' '}
        {patronymic}
      </React.Fragment>
    );
  };

  render() {
    let photo = null;
    if (this.props.photo) {
      photo = this.props.photo;
    }

    return (
      <Wrapper>
        <Header>
          <UserPhoto
            inRating
            src={photo}
            name={this.props.name}
            surname={this.props.surname}
            alt={`${this.props.name} ${this.props.surname}`}
          />
          <HeaderBody>
            <Title>
              {this.renderFullName()}
            </Title>
            <RQ>{`RQ ${this.props.rq}`}</RQ>
            <Post>{this.props.department}</Post>
          </HeaderBody>
        </Header>
        <Body>
          <LeftBody>
            <Text>E-mail</Text>
            <Text>Последний визит</Text>
          </LeftBody>
          <RightBody>
            <Text>
              <Highlighter
                highlightStyle={highlightStyle}
                searchWords={[this.props.searchKeyword]}
                autoEscape
                textToHighlight={this.props.email}
              />
            </Text>
            <Text>{this.props.lastSessionFinishDateTime ? this.props.lastSessionFinishDateTime : '-'}</Text>
          </RightBody>
        </Body>
        <SimpleBody>
          <div>
            <Text>
              <FlexRow>
                <div>
                  Статус
                </div>
                <div>
                  {this.props.statusText}
                </div>
              </FlexRow>
              <FlexRow>
                <div>
                  Доступ к расширенному рейтингу
                </div>
                <div>
                  {this.props.canSeeCompanyExtendedRating ? 'да' : 'нет'}
                </div>
              </FlexRow>
            </Text>
            <LeftBody />
            <RightBody>
              <Buttons>
                {this.props.isEditable && (
                  <Edit
                    onClick={this.edit}
                  />
                )}
                {this.props.isDeletable && (
                  <Delete
                    onClick={this.delete}
                  />
                )}
              </Buttons>
            </RightBody>
          </div>
        </SimpleBody>
        <SimpleBody />
      </Wrapper>
    );
  }
}

EmployeeCard.defaultProps = {
  isEditable: true,
  isDeletable: true,
  lastSessionFinishDateTime: null,
  photo: null,
  rq: null,
  canSeeCompanyExtendedRating: false,
};

EmployeeCard.propTypes = {
  id: PropTypes.string,
  isEditable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  canSeeCompanyExtendedRating: PropTypes.bool,
  department: PropTypes.string,
  searchKeyword: PropTypes.string,
  lastSessionFinishDateTime: PropTypes.string,
  statusText: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  patronymic: PropTypes.string,
  email: PropTypes.string,
  photo: PropTypes.string,
  rq: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default EmployeeCard;
