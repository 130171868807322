import {
  GET_COMMON_GAME_RATING,
  GET_COMMON_GAME_RATING_FAIL,
  GET_COMMON_GAME_RATING_SUCCESS,
  GET_COMPANY_GAME_HEAD_RATING,
  GET_COMPANY_GAME_HEAD_RATING_FAIL,
  GET_COMPANY_GAME_HEAD_RATING_SUCCESS,
  GET_COMPANY_GAME_RATING,
  GET_COMPANY_GAME_RATING_FAIL,
  GET_COMPANY_GAME_RATING_SUCCESS,
  GET_PERSONAL_GAME_HEAD_RATING,
  GET_PERSONAL_GAME_HEAD_RATING_FAIL,
  GET_PERSONAL_GAME_HEAD_RATING_SUCCESS,
  GET_EXTENDED_EMPLOYEE_RATING,
  GET_EXTENDED_EMPLOYEE_RATING_FAIL,
  GET_EXTENDED_EMPLOYEE_RATING_SUCCESS, SET_ACTIVE_RATING_TAB,
} from './constants';

export function getPersonalGameHeadRating({ gameId }) {
  return {
    type: GET_PERSONAL_GAME_HEAD_RATING,
    payload: {
      gameId,
    },
  };
}

export function getPersonalGameHeadRatingSuccess({ gameId, data, fields }) {
  return {
    type: GET_PERSONAL_GAME_HEAD_RATING_SUCCESS,
    payload: {
      gameId,
      data,
      fields,
    },
  };
}

export function getPersonalGameHeadRatingFail({ gameId }) {
  return {
    type: GET_PERSONAL_GAME_HEAD_RATING_FAIL,
    payload: {
      gameId,
    },
  };
}

export function getCommonGameHeadRating({ gameId }) {
  return {
    type: GET_COMMON_GAME_RATING,
    payload: {
      gameId,
    },
  };
}

export function getCommonGameRatingSuccess({ gameId, data, fields }) {
  return {
    type: GET_COMMON_GAME_RATING_SUCCESS,
    payload: {
      gameId,
      data,
      fields,
    },
  };
}

export function getCommonGameRatingFail({ gameId }) {
  return {
    type: GET_COMMON_GAME_RATING_FAIL,
    payload: {
      gameId,
    },
  };
}

export function getCompanyGameHeadRating({ gameId }) {
  return {
    type: GET_COMPANY_GAME_HEAD_RATING,
    payload: {
      gameId,
    },
  };
}

export function getCompanyGameHeadRatingSuccess({ gameId, data, fields }) {
  return {
    type: GET_COMPANY_GAME_HEAD_RATING_SUCCESS,
    payload: {
      gameId,
      data,
      fields,
    },
  };
}

export function getCompanyGameHeadRatingFail({ gameId }) {
  return {
    type: GET_COMPANY_GAME_HEAD_RATING_FAIL,
    payload: {
      gameId,
    },
  };
}

export function getCompanyGameRating({ gameId, isDirector = false }) {
  return {
    type: GET_COMPANY_GAME_RATING,
    payload: {
      gameId,
      isDirector,
    },
  };
}

export function getCompanyGameRatingSuccess({ gameId, data, fields }) {
  return {
    type: GET_COMPANY_GAME_RATING_SUCCESS,
    payload: {
      gameId,
      data,
      fields,
    },
  };
}

export function getCompanyGameRatingFail({ gameId }) {
  return {
    type: GET_COMPANY_GAME_RATING_FAIL,
    payload: {
      gameId,
    },
  };
}

export function getExtendedEmployeeGameRating({ gameId, employeeId }) {
  return {
    type: GET_EXTENDED_EMPLOYEE_RATING,
    payload: {
      gameId,
      employeeId,
    },
  };
}

export function getExtendedEmployeeGameRatingSuccess({ gameId, employeeId, data, fields }) {
  return {
    type: GET_EXTENDED_EMPLOYEE_RATING_SUCCESS,
    payload: {
      gameId,
      employeeId,
      data,
      fields,
    },
  };
}

export function getExtendedEmployeeGameRatingFail({ gameId, employeeId }) {
  return {
    type: GET_EXTENDED_EMPLOYEE_RATING_FAIL,
    payload: {
      gameId,
      employeeId,
    },
  };
}

export function setActiveRatingTab({ activeRatingTab }) {
  return {
    type: SET_ACTIVE_RATING_TAB,
    payload: {
      activeRatingTab,
    },
  };
}
