import { fromJS } from 'immutable';

import {
  START_TUTORIAL,
  FINISH_TUTORIAL,
} from './constants';

const initialState = fromJS({
  startTime: null,
  finishTime: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case START_TUTORIAL:
      return state
        .set('startTime', action.payload.startTime);
    case FINISH_TUTORIAL:
      return state
        .set('finishTime', action.payload.finishTime);
    default:
      return state;
  }
}

export default reducer;
