import React from 'react';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import AddEmployeeForm from '../../forms/EmployeeForm/Add';

import { makeSelectPopupState } from '../../../redux/modules/global/selectors';
import { makeSelectProfile, makeSelectProfileAcl } from '../../../redux/modules/profile/selectors';
import DepartmentForm from '../../forms/DepartmentForm/add';
import EditDepartmentForm from '../../forms/DepartmentForm/edit';

import EditEmployeeForm from '../../forms/EmployeeForm/edit';
import LoadingIndicator from '../../../components/LoadingIndicator';
import DepartmentCard from '../../../components/DepartmentCard';
import EmployeeCard from '../../../components/EmployeeCard';
import Popup from '../../../components/Popup';
import Tabs from '../../../components/Tabs';
import TabItem from '../../../components/Tabs/Tab';
import Section from '../../../components/Section';

import { changeLeftMenuState, changePopupState } from '../../../redux/modules/global/actions';
import SimplePageBase from '../../PageBase/SimplePageBase';
import Filter from './Filter';

import {
  makeSelectDepartments,
  makeSelectLoading,
  makeSelectActiveTab,
  makeSelectDepartmentDeletePopup,
  makeSelectDepartmentEditPopup,
  makeSelectEmployeeAddPopup,
  makeSelectEmployeeDeletePopup,
  makeSelectEmployeeEditPopup,
  makeSelectFilteredEmployees,
  makeSelectEmployees,
  makeSelectEmployeeFilterKeyword, makeSelectEmployeeFilterDepartmentId, makeSelectEmployeeFilterStatus,
} from '../../../redux/modules/employees/selectors';

import {
  getDepartmentList,
  changeActiveTab,
  deleteDepartment,
  changeDeleteDepartmentPopupState,
  changeDepartmentForEdit,
  changeEditDepartmentPopupState,
  changeAddEmployeePopupState,
  changeDeleteEmployeePopupState,
  changeEditEmployeePopupState,
  deleteEmployee,
  changeEmployeeForEdit,
} from '../../../redux/modules/employees/actions';

import AddButton from './AddButton';
import DepartmentIcon from './addDepartmentIcon.svg';
import EmployeeIcon from './addEmployeeIcon.svg';
import TabsContent from './TabsContent';
import TabContent from './TabContent';
import TabHeader from './TabHeader';
import TabBody from './TabBody';
import PopupText from './PopupText';

const TabBodyEmployee = styled(TabBody)`
  justify-content: center;
`;

const TabHeaderEmployee = styled(TabHeader)`
  height: auto;
  min-height: 130px;
  padding: 0;
  align-items: flex-start;
`;

const CountDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`;

class EmployeesPage extends React.PureComponent {
  state = {
    departmentIdForDelete: null,
    employeeIdForDelete: null,
  };

  componentWillMount() {
    this.props.onLoad();
  }

  toggleActiveTab = (event) => {
    this.props.onTabChange({
      activeTab: event.target.id,
    });
  };

  togglePopup = () => {
    this.props.onPopupOpen(!this.props.popupState);
  };

  toggleDeleteDepartmentPopup = (departmentId) => {
    if (isString(departmentId)) {
      this.setState({
        departmentIdForDelete: departmentId,
      });
    }

    this.props.onDepartmentDeletePopup(!this.props.deleteDepartmentPopupState);
  };

  deleteDepartment = () => {
    const departmentId = this.state.departmentIdForDelete;

    this.props.onDepartmentDelete({
      departmentId,
    });

    this.toggleDeleteDepartmentPopup();
  };

  toggleEditDepartmentPopup = (departmentId, departmentName) => {
    if (isString(departmentId)) {
      this.props.onDepartmentEdit({
        id: departmentId,
        name: departmentName,
      });
    }

    this.props.onDepartmentEditPopup(!this.props.editDepartmentPopupState);
  };

  toggleAddEmployeePopup = () => {
    this.props.onEmployeeAddPopup(!this.props.addEmployeePopupState);
  };

  toggleEditEmployeePopup = (employee) => {
    if (!isNull(employee) && !isUndefined(employee)) {
      this.props.onEmployeeEdit({
        id: employee.id,
        name: employee.name,
        surname: employee.surname,
        patronymic: employee.patronymic,
        email: employee.email,
      });
    }

    this.props.onEmployeeEditPopup(!this.props.editEmployeePopupState);
  };

  toggleDeleteEmployeePopup = (employeeId) => {
    if (isString(employeeId)) {
      this.setState({
        employeeIdForDelete: employeeId,
      });
    }

    this.props.onEmployeeDeletePopup(!this.props.deleteEmployeePopupState);
  };

  deleteEmployee = () => {
    const employeeId = this.state.employeeIdForDelete;

    this.props.onEmployeeDelete({
      employeeId,
    });

    this.toggleDeleteEmployeePopup();
  };

  renderEmployeesCards = () => {
    if (!isEmpty(this.props.filteredEmployeesList)) {
      return this.props.filteredEmployeesList.map((employee) => {
        const isEditable = this.props.acl.canEditAllCompanyEmployee || this.props.acl.canEditOwnDepartmentEmployee;
        const isDeletable = this.props.acl.canEditAllCompanyEmployee || this.props.acl.canEditOwnDepartmentEmployee;

        return (
          <EmployeeCard
            searchKeyword={this.props.filterKeyword}
            id={employee.id}
            canSeeCompanyExtendedRating={employee.canSeeCompanyExtendedRating}
            lastSessionFinishDateTime={employee.lastSessionFinishDateTime}
            key={employee.id}
            statusText={employee.statusText}
            photo={employee.photo}
            rq={employee.rq}
            department={get(find(this.props.departmentsList, { id: employee.departmentId }), 'name', '')}
            name={employee.name}
            surname={employee.surname}
            patronymic={employee.patronymic}
            email={employee.email}
            onEdit={this.toggleEditEmployeePopup}
            onDelete={this.toggleDeleteEmployeePopup}
            isEditable={isEditable}
            isDeletable={isDeletable}
          />
        );
      });
    }

    return <div />;
  };

  renderCountDescription = () => {
    if (
      !isEmpty(this.props.filterKeyword)
      || !isEmpty(this.props.filterDepartmentId)
      || !isEmpty(this.props.filterStatus)
    ) {
      const allCount = this.props.allEmployeesList.length;
      if (allCount === 0) {
        return null;
      }

      const findCount = this.props.filteredEmployeesList.length;

      if (findCount === 0) {
        return <CountDescription><span>По указанным фильтрам ничего не найдено</span></CountDescription>;
      }

      return (
        <CountDescription>
          <span>
            {`${noun(findCount, 'Найден', 'Найдено', 'Найдено')} ${findCount} из ${allCount}`}
          </span>
        </CountDescription>
      );
    }

    return null;
  };

  renderDepartmentsCards = () => {
    if (!isNull(this.props.departmentsList)) {
      const isEditable = this.props.acl.canEditAllCompanyEmployee;
      const isDeletable = this.props.acl.canEditAllCompanyEmployee;

      return this.props.departmentsList.map(department => (
        <DepartmentCard
          key={department.id}
          id={department.id}
          name={department.name}
          count={department.count}
          onEdit={this.toggleEditDepartmentPopup}
          onDelete={this.toggleDeleteDepartmentPopup}
          isEditable={isEditable}
          isDeletable={isDeletable}
        />
      ));
    }

    return <div />;
  };

  render() {
    let employees = <LoadingIndicator />;
    let departments = <LoadingIndicator />;
    let filterDescription = null;

    if (!this.props.isLoading) {
      employees = this.renderEmployeesCards();
      departments = this.renderDepartmentsCards();
      filterDescription = this.renderCountDescription();
    }

    return (
      <SimplePageBase title="Мои сотрудники">
        <Section>
          <Tabs>
            <TabItem
              id="tabItem_1"
              active={this.props.activeTab === 'tabItem_1'}
              onClick={this.toggleActiveTab}
            >
              сотрудники
            </TabItem>
            <TabItem
              id="tabItem_2"
              active={this.props.activeTab === 'tabItem_2'}
              onClick={this.toggleActiveTab}
            >
              команды
            </TabItem>
          </Tabs>
          <TabsContent>
            <CSSTransition
              mountOnEnter
              in={this.props.activeTab === 'tabItem_1'}
              timeout={{
                enter: 500,
                exit: 500,
              }}
              classNames="slideLeft"
            >
              <TabContent>
                <TabHeaderEmployee>
                  <Filter
                    renderAddButton={() => {
                      if (!this.props.acl.canEditAllCompanyEmployee) {
                        return false;
                      }

                      return (
                        <AddButton
                          background={EmployeeIcon}
                          onClick={this.toggleAddEmployeePopup}
                        />
                      );
                    }}
                  />
                </TabHeaderEmployee>
                {filterDescription}
                <TabBodyEmployee>
                  {employees}
                </TabBodyEmployee>
              </TabContent>
            </CSSTransition>
            <CSSTransition
              mountOnEnter
              in={this.props.activeTab === 'tabItem_2'}
              timeout={{
                enter: 500,
                exit: 500,
              }}
              classNames="slideRight"
            >
              <TabContent>
                <TabHeader>
                  {this.props.acl.canEditAllCompanyEmployee && (
                    <AddButton
                      background={DepartmentIcon}
                      onClick={this.togglePopup}
                    />
                  )}
                </TabHeader>
                <TabBody>
                  {departments}
                </TabBody>
              </TabContent>
            </CSSTransition>
            <DepartmentForm
              onClose={this.togglePopup}
            />
            <EditDepartmentForm
              onClose={this.toggleEditDepartmentPopup}
            />
            <AddEmployeeForm
              onClose={this.toggleAddEmployeePopup}
            />
            <EditEmployeeForm
              onClose={this.toggleEditEmployeePopup}
            />
            <Popup
              isActive={this.props.deleteDepartmentPopupState}
              onClose={this.toggleDeleteDepartmentPopup}
              onSubmit={this.deleteDepartment}
              title="Удалить команду"
              submit="Удалить"
            >
              <PopupText>
                Вы действительно хотите удалить данную команду?
              </PopupText>
            </Popup>
            <Popup
              isActive={this.props.deleteEmployeePopupState}
              onClose={this.toggleDeleteEmployeePopup}
              onSubmit={this.deleteEmployee}
              title="Удалить сотрудника"
              submit="Удалить"
            >
              <PopupText>
                Вы действительно хотите удалить данного сотрудника?
              </PopupText>
            </Popup>
          </TabsContent>
        </Section>
      </SimplePageBase>
    );
  }
}

EmployeesPage.propTypes = {
  activeTab: PropTypes.string,
  leftMenuState: PropTypes.bool,
  onLeftMenuClick: PropTypes.func,
  onLoad: PropTypes.func,
  onPopupOpen: PropTypes.func,
  onTabChange: PropTypes.func,
  onDepartmentDelete: PropTypes.func,
  onDepartmentDeletePopup: PropTypes.func,
  onDepartmentEdit: PropTypes.func,
  onDepartmentEditPopup: PropTypes.func,
  onEmployeeAddPopup: PropTypes.func,
  onEmployeeDeletePopup: PropTypes.func,
  onEmployeeDelete: PropTypes.func,
  onEmployeeEdit: PropTypes.func,
  onEmployeeEditPopup: PropTypes.func,
  profile: PropTypes.object,
  filteredEmployeesList: PropTypes.array,
  allEmployeesList: PropTypes.array,
  departmentsList: PropTypes.array,
  isLoading: PropTypes.bool,
  popupState: PropTypes.bool,
  deleteDepartmentPopupState: PropTypes.bool,
  editDepartmentPopupState: PropTypes.bool,
  addEmployeePopupState: PropTypes.bool,
  deleteEmployeePopupState: PropTypes.bool,
  editEmployeePopupState: PropTypes.bool,
  filterKeyword: PropTypes.string,
  filterDepartmentId: PropTypes.string,
  filterStatus: PropTypes.any,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLeftMenuClick: newMenuState => dispatch(changeLeftMenuState(newMenuState)),
    onLoad: () => dispatch(getDepartmentList()),
    onPopupOpen: newPopupState => dispatch(changePopupState(newPopupState)),
    onTabChange: activeTab => dispatch(changeActiveTab(activeTab)),
    onDepartmentDelete: department => dispatch(deleteDepartment(department)),
    onDepartmentDeletePopup: state => dispatch(changeDeleteDepartmentPopupState(state)),
    onDepartmentEdit: department => dispatch(changeDepartmentForEdit(department)),
    onDepartmentEditPopup: state => dispatch(changeEditDepartmentPopupState(state)),
    onEmployeeAddPopup: state => dispatch(changeAddEmployeePopupState(state)),
    onEmployeeDeletePopup: state => dispatch(changeDeleteEmployeePopupState(state)),
    onEmployeeDelete: employee => dispatch(deleteEmployee(employee)),
    onEmployeeEdit: employee => dispatch(changeEmployeeForEdit(employee)),
    onEmployeeEditPopup: state => dispatch(changeEditEmployeePopupState(state)),
  };
}

const mapStateToProps = createStructuredSelector({
  activeTab: makeSelectActiveTab(),
  profile: makeSelectProfile(),
  popupState: makeSelectPopupState(),
  filterKeyword: makeSelectEmployeeFilterKeyword(),
  filterDepartmentId: makeSelectEmployeeFilterDepartmentId(),
  filterStatus: makeSelectEmployeeFilterStatus(),
  allEmployeesList: makeSelectEmployees(),
  filteredEmployeesList: makeSelectFilteredEmployees(),
  departmentsList: makeSelectDepartments(),
  isLoading: makeSelectLoading(),
  acl: makeSelectProfileAcl(),
  deleteDepartmentPopupState: makeSelectDepartmentDeletePopup(),
  editDepartmentPopupState: makeSelectDepartmentEditPopup(),
  addEmployeePopupState: makeSelectEmployeeAddPopup(),
  deleteEmployeePopupState: makeSelectEmployeeDeletePopup(),
  editEmployeePopupState: makeSelectEmployeeEditPopup(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(EmployeesPage);
