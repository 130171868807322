import PropTypes from 'prop-types';
import React from 'react';

import { Fab, Action } from 'react-tiny-fab';
import { IoIosChatbubbles } from 'react-icons/io';
import styled from 'styled-components';

import SupportColorSvg from '../../../assets/fabChat/support-color.svg';
import FeedbackSvg from '../../../assets/fabChat/feedback.svg';
import WhatsAppColorSvg from '../../../assets/fabChat/whatstapp-color.svg';

import 'react-tiny-fab/dist/styles.min.css';
import './style.css';
import { onFeedBackButtonClick, onOnlineHelpButtonClick, onWhatsAppButtonClick } from '../actions';

const option = {
  position: {
    bottom: -8,
    right: -8,
  },
  event: 'hover',
  actionButtonStyles: {
    backgroundColor: '#35BB9A',
  },
};

const MainIcon = styled(IoIosChatbubbles)`
  width: 30px;
  height: 30px;
`;

const Icon = styled.div`
  background-size: cover;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SupportIcon = styled(Icon)`
  background-image: url(${SupportColorSvg});
`;

const FeedbackIcon = styled(Icon)`
  background-image: url(${FeedbackSvg});
`;

const WhatsAppColorIcon = styled(Icon)`
  background-image: url(${WhatsAppColorSvg});
`;

class FabChatDesktop extends React.PureComponent {
  getActions = () => {
    const { isAuthenticated, profile } = this.props;

    const { name, surname, email } = profile;

    const actions = [
      <Action
        key="supportChat"
        style={option.actionButtonStyles}
        text="Техническая поддержка"
        onClick={onOnlineHelpButtonClick}
      >
        <SupportIcon />
      </Action>,
      <Action
        key="feedback"
        style={option.actionButtonStyles}
        text="Оставить отзыв"
        onClick={onFeedBackButtonClick({ name, surname, email })}
      >
        <FeedbackIcon />
      </Action>,
    ];

    if (false && isAuthenticated) { // Скрыли чат whatsAppChat пока что
      actions.push(
        <Action
          key="whatsAppChat"
          style={option.actionButtonStyles}
          text="Чат игроков в WhatsApp"
          onClick={onWhatsAppButtonClick}
        >
          <WhatsAppColorIcon />
        </Action>,
      );
    }

    return actions;
  };

  render() {
    return (
      <Fab
        icon={<MainIcon />}
        position={option.position}
        event={option.event}
      >
        {this.getActions()}
      </Fab>
    );
  }
}

FabChatDesktop.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default FabChatDesktop;
