/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import uuid from 'uuid/v4';
import Controls from './Controls.json';
import 'video.js/dist/video-js.css';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.playerId = `video-player-${uuid()}`;
    this.player = {};
  }

  componentDidMount() {
    this.initPlayer(this.props);
    this.initPlayerEvents(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setControlsVisibility(this.player, nextProps.hideControls);
    if (this.props.src !== nextProps.src) {
      this.initPlayer(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose();
  }

  setControlsVisibility(player, hiddenControls) {
    Object.keys(Controls).map((x) => {
      player.controlBar[Controls[x]].show();
    });
    hiddenControls.map((x) => {
      player.controlBar[Controls[x]].hide();
    });
  }

  initPlayer(props) {
    const playerOptions = this.generatePlayerOptions(props);
    this.player = videojs(document.querySelector(`#${this.playerId}`), playerOptions);
    this.player.src(props.src);
    this.player.poster(props.poster);
    this.setControlsVisibility(this.player, props.hideControls);
  }

  generatePlayerOptions(props) {
    const playerOptions = {};
    playerOptions.controls = props.controls;
    playerOptions.autoplay = props.autoplay;
    playerOptions.preload = props.preload;
    playerOptions.width = props.width;
    playerOptions.height = props.height;
    playerOptions.bigPlayButton = props.bigPlayButton;
    playerOptions.resizeManager = props.resizeManager;
    const hidePlaybackRates = props.hidePlaybackRates || props.hideControls.includes('playbackrates');
    if (!hidePlaybackRates) playerOptions.playbackRates = props.playbackRates;
    return playerOptions;
  }

  initPlayerEvents(props) {
    let currentTime = 0;
    let previousTime = 0;
    let position = 0;

    this.player.ready(() => {
      try {
        props.onReady(this.player);
      } catch (e) {}
      window.player = this.player;
    });
    this.player.on('play', () => {
      props.onPlay(this.player);
    });
    this.player.on('pause', () => {
      props.onPause(this.player);
    });
    this.player.on('timeupdate', (e) => {
      props.onTimeUpdate(this.player.currentTime());
      previousTime = currentTime;
      currentTime = this.player.currentTime();
      if (previousTime < currentTime) {
        position = previousTime;
        previousTime = currentTime;
      }
    });
    this.player.on('seeking', () => {
      this.player.off('timeupdate', () => {
      });
      this.player.one('seeked', () => {
      });
      props.onSeeking(this.player.currentTime());
    });

    this.player.on('seeked', () => {
      const completeTime = Math.floor(this.player.currentTime());
      props.onSeeked(position, completeTime);
    });
    this.player.on('ended', () => {
      props.onEnd();
    });
  }

  render() {
    return (
      <video
        id={this.playerId}
        data-setup={this.props.dataSetup}
        playsInline={this.props.playsinline}
        loop={this.props.loop}
        muted={this.props.muted}
        className={`video-js ${this.props.bigPlayButtonCentered ? 'vjs-big-play-centered' : ''} ${this.props.className}`}
      />
    );
  }
}

VideoPlayer.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
  preload: PropTypes.oneOf(['auto', 'none', 'metadata']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideControls: PropTypes.arrayOf(PropTypes.string),
  bigPlayButton: PropTypes.bool,
  bigPlayButtonCentered: PropTypes.bool,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onSeeking: PropTypes.func,
  onSeeked: PropTypes.func,
  onEnd: PropTypes.func,
  playbackRates: PropTypes.arrayOf(PropTypes.number),
  hidePlaybackRates: PropTypes.bool,
  className: PropTypes.string,
  dataSetup: PropTypes.string,
  playsinline: PropTypes.bool,
  resizeManager: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  src: '',
  poster: '',
  loop: false,
  playsinline: false,
  muted: false,
  controls: true,
  autoplay: false,
  dataSetup: '{}',
  preload: 'auto',
  playbackRates: [0.5, 1, 1.5, 2],
  hidePlaybackRates: false,
  className: '',
  hideControls: [],
  bigPlayButton: true,
  bigPlayButtonCentered: true,
  onReady: () => {
  },
  onPlay: () => {
  },
  onPause: () => {
  },
  onTimeUpdate: () => {
  },
  onSeeking: () => {
  },
  onSeeked: () => {
  },
  onEnd: () => {
  },
};

export default VideoPlayer;
