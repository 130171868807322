import * as Yup from 'yup';

const EmployeeFormValidateSchema = Yup.object().shape({
  email: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .email('Убедитесь, что поле заполнено верно')
    .required('Поле обязательно для заполнения'),
  name: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .test('length', 'Поле обязательно для заполнения', val => !val || val.toString().length > 0)
    .required('Поле обязательно для заполнения'),
  surname: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .test('length', 'Поле обязательно для заполнения', val => !val || val.toString().length > 0)
    .required('Поле обязательно для заполнения'),
  patronymic: Yup
    .string(),
  departmentId: Yup
    .string()
    .typeError('Укажите команду')
    .test('length', 'Укажите команду', val => !val || val.toString().length > 0)
    .required('Укажите команду'),
});

export default EmployeeFormValidateSchema;
