import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import pure from 'recompose/pure';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import GameOnDevelopPopup from './includes/GameOnDevelopPopup';
import InDevelopSVG from '../../assets/in-develop.svg';

const Root = pure(styled.div`
  display: block;
  text-decoration: none;
  width: 285px;
  min-width: 285px;;
  height: 370px;
  min-height: 370px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 16px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  transform: perspective(1px) scale(1);
  transition: 0.3s transform;
  user-select: none;

  &:hover {
    transform: perspective(1px) scale(1.02);
  }
  cursor: pointer;

`);

const Image = pure(styled.div`
  background-image: url(${props => props.src});
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
`);

const TextBlock = pure(styled.div`
  padding: 13px 15px;
  display: flex;
  flex-direction: column;
  height: 220px;
`);

const Name = pure(styled.div`
  font-size: 20px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  line-height: 22px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`);

const Description = pure(styled.div`
  line-height: 15px;
  font-size: 14px;
  color: #1c1c1cba;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`);

const Footer = pure(styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`);

const InDevelopImage = pure(styled.div`
  background: url(${InDevelopSVG}) no-repeat;
  width: 156px;
  height: 38px;
  position: absolute;
  top: -2px;
  right: -4px;
  background-size: 156px 38px;
`);

class GameCard extends React.PureComponent {
  static defaultProps = {
    children: null,
    description: null,
    betaAccess: false,
    isTrialAccess: false,
    ownedGame: false,
    inDevelop: false,
    serie: null,
    image: null,
  };

  static propTypes = {
    children: PropTypes.any,
    description: PropTypes.any,
    serie: PropTypes.string,
    betaAccess: PropTypes.bool,
    inDevelop: PropTypes.bool,
    url: PropTypes.string.isRequired,
    image: PropTypes.string,
    gameId: PropTypes.any,
    name: PropTypes.string.isRequired,
    isTrialAccess: PropTypes.bool,
    ownedGame: PropTypes.bool,
  };

  state = {
    gameOnDevelopPopupShown: false,
  };

  showGameOnDevelopPopup = () => {
    this.setState({
      gameOnDevelopPopupShown: true,
    });
  };

  hideGameOnDevelopPopup = () => {
    this.setState({
      gameOnDevelopPopupShown: false,
    });
  };

  handleClick = () => {
    const {
      url, history, betaAccess, inDevelop,
    } = this.props;

    if (inDevelop && !betaAccess) {
      // Игра не доступна еще, показываем заглушку.
      this.showGameOnDevelopPopup();
      return;
    }

    history.push(url);
  };

  render() {
    const {
      name, description, image, ownedGame, serie, inDevelop, gameId,
    } = this.props;

    const { gameOnDevelopPopupShown } = this.state;

    // TODO: HARDCODE Для демо через сову связано с src/components/Owl/includes/OwlStepContent/OwlStepContent.js:132
    return (
      <>
        {inDevelop && (
          <GameOnDevelopPopup
            open={gameOnDevelopPopupShown}
            gameName={name}
            onClose={this.hideGameOnDevelopPopup}
          />
        )}
        <Root
          onClick={this.handleClick}
          id={`game-card-${gameId.replace('{', '').replace('}', '')}`}
        >
          {inDevelop && (
            <InDevelopImage />
          )}
          <Image src={image} />
          <TextBlock>
            <Name>{name}</Name>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            {ownedGame && (
              <Footer>
                {this.props.children}
              </Footer>
            )}
          </TextBlock>
        </Root>
      </>
    );
  }
}

export default withRouter(GameCard);
