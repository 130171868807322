import styled from 'styled-components';
import posed from 'react-pose';

import buttonStyles from './buttonStyles';
import buttonPoseAnimation from '../../globalConstants';

const ButtonPressedAnimation = posed.button(buttonPoseAnimation);

const StyledButton = styled(ButtonPressedAnimation)`${buttonStyles}`;

export default StyledButton;
