import compose from 'recompose/compose';
import { connect } from 'react-redux';
import lifecycle from 'recompose/lifecycle';
import { createStructuredSelector } from 'reselect';
import keydown, { Keys } from 'react-keydown';
import {
  makeSelectActiveCardGroupId,
  makeSelectActiveCardId, makeSelectActiveLibraryLink,
  makeSelectShowLibraryPopup,
} from '../../redux/modules/library/selectors';
import { getCardsAll } from '../../redux/modules/myGame/actions';

import {
  makeSelectCards,
  makeSelectLastBuyCard,
  makeSelectCardKeyProductPrice,
  makeSelectStage, makeSelectStagesInfo,
} from '../../redux/modules/myGame/selectors';

const { ESC } = Keys;

const mapDispatchToProps = dispatch => ({
  getCardsAll: () => dispatch(getCardsAll()),
});

const mapStateToProps = createStructuredSelector({
  libraryCards: makeSelectCards(),
  stage: makeSelectStage(),
  stagesInfo: makeSelectStagesInfo(),
  price: makeSelectCardKeyProductPrice(),
  lastBuyCard: makeSelectLastBuyCard(),
  activeCardId: makeSelectActiveCardId(),
  open: makeSelectShowLibraryPopup(),
  activeCardGroupId: makeSelectActiveCardGroupId(),
  activeLibraryLink: makeSelectActiveLibraryLink(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withKeyDown = keydown(ESC);

const withLifecycle = lifecycle({
  componentDidMount() {
    this.props.getCardsAll();
  },
});

const enhance = compose(
  withConnect,
  withKeyDown,
  withLifecycle,
);

export default enhance;
