import styled from 'styled-components';
import pure from 'recompose/pure';

const Flex = pure(styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'initial')};
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`);

export default Flex;
