import PropTypes from 'prop-types';
import React from 'react';
import pure from 'recompose/pure';
import styled from 'styled-components';

const Root = pure(styled.div`
  
`);

const Text = pure(styled.div`
  margin-top: 6px;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Merriweather',serif;
`);

const Annotation = pure(styled.div`
  font-size: 13px;
  color: #808085;
  line-height: 22px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`);


class MillonFooter extends React.PureComponent {
  static defaultProps = {
    gameAccessExpired: false,
  };

  static propTypes = {
    gameAccessExpired: PropTypes.bool,
    maxSumma: PropTypes.number.isRequired,
    openedCardsPercent: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]).isRequired,
  };

  render() {
    const { maxSumma, dealMaxSum, openedCardsPercent } = this.props;

    return (
      <Root>
        <Text>
          {`${maxSumma.toLocaleString()} из ${dealMaxSum.toLocaleString()}`}
        </Text>
        <Annotation>
          Заработано
        </Annotation>
        <Text>
          {`${openedCardsPercent} %`}
        </Text>
        <Annotation>
          Открыто знаний
        </Annotation>
      </Root>
    );
  }
}

export default MillonFooter;
