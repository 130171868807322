import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FileUploader from 'react-dropzone';
import CloseIcon from '../BigPopup/CloseIcon';
import NormalPopup from '../BigPopup/PopupWrapper';

const DropZonePopup = styled(NormalPopup)`
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;

  & .fileuploader {
    background: #ffffff;
    border: 5px dashed #3DBA9A;
    cursor: pointer;
    padding: 100px;
  }
`;

const Text = styled.p`
  color: #1B1B1B;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
`;

class Dropzone extends React.PureComponent {
  componentDidMount() {
    if (this.props.isActive) {
      document.body.classList.add('no-scroll');
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
    } else {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive) {
      document.body.classList.add('no-scroll');
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
    } else {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('mousedown', this.handleClickOutsidePopup);
  }

  onDrop = (files) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = () => {
      this.props.onDrop(reader.result);
    };
  };

  handleClickOutsidePopup = (e) => {
    if (!ReactDOM.findDOMNode(this.popupInner).contains(e.target)) { // eslint-disable-line react/no-find-dom-node
      this.props.onClose();
    }
  };

  render() {
    return (
      <DropZonePopup isActive={this.props.isActive}>
        <CloseIcon onClose={this.props.onClose} />
        <FileUploader
          accept="image/jpeg, image/png"
          ref={(node) => { this.popupInner = node; }}
          className="fileuploader"
          onDrop={this.onDrop}
        >
          <Text>
            Перетащите файлы сюда или кликните для выбора
          </Text>
        </FileUploader>
      </DropZonePopup>
    );
  }
}

Dropzone.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  onDrop: PropTypes.func,
};

export default Dropzone;
