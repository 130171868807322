import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import each from 'lodash/each';
import keys from 'lodash/keys';
import get from 'lodash/get';
import { createStructuredSelector } from 'reselect';
import { getGameList, getGamesShowcases } from '../../../redux/modules/games/actions';
import { makeSelectGames, makeSelectGamesShowcases } from '../../../redux/modules/games/selectors';
import { getCommonGameHeadRating, setActiveRatingTab } from '../../../redux/modules/rating/actions';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';
import {
  makeSelectActiveRatingTab,
  makeSelectCommonGameRatingForActiveGame,
} from '../../../redux/modules/rating/selectors';

const mapDispatchToProps = dispatch => ({
  onLoad: ({ gameId }) => dispatch(getCommonGameHeadRating({ gameId })),
  getGames: () => dispatch(getGameList()),
  getGamesShowcases: () => dispatch(getGamesShowcases()),
  onTabChange: activeRatingTab => dispatch(setActiveRatingTab({ activeRatingTab })),
});

const mapStateToProps1 = createStructuredSelector({
  activeTab: makeSelectActiveRatingTab(),
  profile: makeSelectProfile(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
});

const mapStateToProps2 = createStructuredSelector({
  commonRatingForActiveGame: makeSelectCommonGameRatingForActiveGame(),
});

const withConnect1 = connect(mapStateToProps1, mapDispatchToProps);
const withConnect2 = connect(mapStateToProps2);

const withCustomProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const withActiveGameProps = withProps((props) => {
  const { games } = props;

  const gameIdForTabs = {};

  each(games, (item) => {
    const viewName = get(item, 'OptionsValues.worldRatingViewName');
    const userViewName = get(item, 'OptionsValues.worldRatingUserViewName');

    const hasRating = viewName && userViewName;

    if (hasRating) {
      gameIdForTabs[item.GameID] = {
        id: item.GameID,
        name: item.Name,
      };
    }
  });

  let activeGameId = null;
  if (keys(gameIdForTabs).length) {
    activeGameId = get(gameIdForTabs[props.activeTab], 'id');

    if (!activeGameId) {
      const firstGameInListKey = keys(gameIdForTabs)[0];
      if (firstGameInListKey) {
        activeGameId = gameIdForTabs[firstGameInListKey].id;
      }
    }

    if (activeGameId) {
      props.onTabChange(activeGameId);
    }
  }

  return {
    activeGameId,
    gameIdForTabs,
  };
});

const withLifecycle = lifecycle({
  componentDidMount() {
    if (!this.props.allGames) {
      this.props.getGames();
      this.props.getGamesShowcases();
    }

    if (this.props.allGames && this.props.activeTab) {
      this.props.onLoad({ gameId: this.props.activeTab });
    }

    if (this.props.allGames && !this.props.activeTab && this.props.activeGameId) {
      this.props.onTabChange(this.props.activeGameId);
      this.props.onLoad({ gameId: this.props.activeGameId });
    }
  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.activeGameId && this.props.activeGameId !== nextProps.activeGameId) {
      this.props.onLoad({ gameId: nextProps.activeGameId });
    }
  },
});

const withOwnProps = withProps((props) => {
  const { data = [], pending, fields, fail } = props.commonRatingForActiveGame;

  const { allGames, activeGameId } = props;

  return {
    gameRating: data,
    fields,
    fail,
    isCommonRatingLoading: pending || !allGames || !activeGameId,
  };
});

const enhance = compose(
  withConnect1,
  withCustomProps,
  withActiveGameProps,
  withConnect2,
  withOwnProps,
  withLifecycle,
);

export default enhance;
