import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pure from 'recompose/pure';
import posed from 'react-pose';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Keys from '../../containers/other/GameHeader/Keys';
import Stavka from '../../containers/other/GameHeader/Stavka';
import scrollLock from '../../utils/scrollLock';

import LogoImage from './logo.svg';
import ProfileIcon from '../TopMenu/my-profile.svg';
import MessageIcon from '../TopMenu/message.svg';
import LogoutIcon from '../TopMenu/logout.svg';

import URL_CONSTANTS from '../../urlConstants';

const OverlayAnimation = posed.div({
  hidden: {
    opacity: 0,
  },
  showed: {
    opacity: 1,
  },
});

const RootAnimation = posed.div({
  hidden: {
    x: 290,
    transition: {
      x: { ease: 'linear', duration: 75 },
    },
  },
  showed: {
    x: 0,
    transition: {
      x: { ease: 'linear', duration: 125 },
    },
  },
});

const Overlay = pure(styled(OverlayAnimation)`
  background-color: rgba(0, 0, 0, .5);
  left: 0;
  height: 100vh;
  opacity: 0.99;
  position: fixed;
  top: 0;
  width: ${props => (props.scrollbarWidth && props.scrollbarWidth > 0 ? `calc(100vw - ${props.scrollbarWidth}px)` : '100vw')};
`);

const Root = pure(styled(RootAnimation)`
  height: 100vh;
  width: 290px;
  background: #343642;
  z-index: 5;
  position: fixed;
  right: 0;
  top: 0;
  padding: 15px 20px 20px 20px;
`);

const Logo = pure(styled.div`
  background-image: url(${LogoImage});
  background-size: contain;
  height: 30px;
  width: 30px;
  margin-right: 15px;
`);

const LogoRoot = pure(styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 17px;
  flex-direction: row;
`);

const StateRoot = pure(styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`);

const GameName = pure(styled.div`
  color: #fff;
  padding-top: 1px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
`);

const KeysWrapper = pure(styled.div`
  position: relative;
  top: 17px;
`);

const StavkaWrapper = pure(styled.div`
  position: relative;
  top: 9px;
`);

const Line = pure(styled.div`
  width: 100%;
  height: 1px;
  background: #23242B;
`);

const VLine = pure(styled.div`
  height: 30px;
  margin-top: 10px;
  width: 1px;
  background: #23242B;
`);

const ItemWrapper = pure(styled.div`
  width: 100%;
  margin-top: 20px;
`);

const Item = pure(styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  color: #fff;
  padding-right: 10px;
`);

const Icon = styled.img``;

const GameLink = styled(({ ...rest }) => <Link {...rest} />)`
  text-decoration: none;
`;

class RightGameMenu extends React.PureComponent {
  static defaultProps = {
    keysCount: 0,
    stavka: false,
  };

  static propTypes = {
    stavka: PropTypes.number,
    keysCount: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      scrollLock.on();
    }

    if (prevProps.open && !this.props.open) {
      scrollLock.off();
    }
  }

  componentWillUnmount() {
    if (this.props.open) {
      scrollLock.off();
    }
  }

  onProfileClick = () => {
    this.props.onClose();
    this.props.history.push(URL_CONSTANTS.URLS.PROFILE);
  };

  onFeedBackClick = () => {
    this.props.onClose();
    this.props.onFeedBackClick();
  };

  onExitClick = () => {
    this.props.onClose();
    this.props.history.push(URL_CONSTANTS.URLS.INDEX_PAGE);
  };

  render() {
    const {
      open, onClose, stavka, keysCount,
    } = this.props;

    let stavkaTooltip = 'Активирован бустер “двойная ставка на себя”. То есть… текст';
    if (stavka && stavka > 0) {
      stavkaTooltip = 'Бустер не используется. Активировать его можно в начале новой игры.';
    }

    return (
      <React.Fragment>
        <Root pose={open ? 'showed' : 'hidden'}>
          <GameLink to={URL_CONSTANTS.URLS.GAMES_STORE}>
            <LogoRoot>
              <Logo />
              <GameName>Миллионы&nbsp;в&nbsp;твоём&nbsp;кармане</GameName>
            </LogoRoot>
          </GameLink>
          <Line />
          <StateRoot>
            {stavka && (
              <React.Fragment>
                <StavkaWrapper>
                  <Stavka
                    white
                    text={`x${stavka}`}
                    tooltip={stavkaTooltip}
                  />
                </StavkaWrapper>
                <VLine />
              </React.Fragment>
            )}
            <KeysWrapper>
              <Keys
                white
                keysCount={keysCount}
              />
            </KeysWrapper>
          </StateRoot>
          <Line />
          <ItemWrapper>
            <Item onClick={this.onProfileClick}>
              Мой профиль
              <Icon src={ProfileIcon} alt="Мой профиль" />
            </Item>
            <Item onClick={this.onFeedBackClick}>
              Обратная связь
              <Icon src={MessageIcon} alt="Обратная связь" />
            </Item>
            <Item onClick={this.onExitClick}>
              Выход
              <Icon src={LogoutIcon} alt="Выход" />
            </Item>
          </ItemWrapper>
        </Root>
        {open && <Overlay onClick={onClose} pose={open ? 'showed' : 'hidden'} />}
      </React.Fragment>
    );
  }
}

export default withRouter(RightGameMenu);
