import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import pure from 'recompose/pure';

import URL_CONSTANTS from '../../../urlConstants';

import { validator } from '../../../utils/validation';
import LoadingIndicator from '../../../components/LoadingIndicator';

import {
  changeResetLogin,
  changeResetIsFail,
  resetRequest,
  resetResetPasswordState,
} from '../../../redux/modules/global/actions';
import {
  makeSelectIsFail,
  makeSelectResetWasSend,
  makeSelectResetWasLoading,
  makeSelectResetLogin,
} from '../../../redux/modules/global/selectors';

import NormalInput from '../../../components/Input';
import LogoIcon from './logo.svg';

const Link = pure(styled.button`
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  color: #808085;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  margin: 5px auto;
  padding: 0;
  transition: all .3s ease-out;

  &:hover, &:active {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #1C1C1C;
  }
`);

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  margin: 0;
  margin: auto;
  min-height: 100vh;
  padding: 20px 0;
  overflow: hidden;
  width: 100%;
`;

const Form = styled.form``;

const Logo = styled.div`
  background-image: url(${LogoIcon});
  background-position: center;
  background-size: 202px 139px;
  cursor: pointer;
  height: 139px;
  margin: 0 auto;
  width: 202px;
`;

const FormInner = styled.div`
  margin: 35px auto 0;
  width: 300px;
  
  @media (max-width: 400px) {
    margin: 15px auto 0;
  }
`;

const FormLine = styled.div`
  display: ${props => (props.noDisplay ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 0;
  width: 300px;

  &:last-child {
    margin-top: 25px;
  }
`;

const Button = styled.button`
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 27.5px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:hover {
    background-color: ${darken(0.1, '#35BB9A')};
    border-color: ${darken(0.1, '#35BB9A')};
  }
`;

const A = styled.a`
  color: #41addd;

  &:hover {
    color: #6cc0e5;
  }
`;

const Input = styled(NormalInput)`
  border-color: ${props => (props.isValid ? 'rgba(0, 0, 0, .25)' : '#A01836')};
`;

const Error = styled.p`
  color: #A01836;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 25px;
`;

const Title = styled.div`
  color: #1B1B1B;
  font-size: 30px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 46px;
  text-align: center;
  
  @media (max-width: 400px) {
    margin-top: 20px;
    font-size: 23px;
    padding: 0 10px;
  }
`;

const Text = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #1B1B1B;
  letter-spacing: 0;
  line-height: 20px;
  margin: 21px auto 0;
  text-align: center;
  max-width: 500px;
  
  @media (max-width: 400px) {
    width: auto;
    padding: 0 10px;
    margin: 10px auto 0;
  }
`;

const GlobalStyle = createGlobalStyle`
  #app {
    background-color: #FFFFFF !important;
  }
`;

class ResetPage extends React.PureComponent {
  state = {
    email: '',
    emailValid: true,
  };

  componentDidMount() {
    this.props.changeIsFail({
      isFail: false,
    });
  }

  changeEmail = (event) => {
    this.setState({
      email: event.target.value,
      emailValid: true,
    });

    this.props.changeLogin({
      login: event.target.value,
    });

    if (this.props.isFail === true) {
      this.props.changeIsFail({
        isFail: false,
      });
    }
  };

  handleForm = (event) => {
    event.preventDefault();

    const {
      email,
    } = this.state;

    let isValid = true;

    if (!validator.email(email)) {
      this.setState({
        emailValid: false,
      });

      this.props.changeLogin({
        login: '',
      });

      isValid = false;
    }

    if (isValid) {
      this.props.resetRequest();
    }

    return false;
  };

  handleRedirect = () => {
    this.props.resetResetPasswordState();
    this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
  };

  backToLoginPage = () => {
    this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
  };

  render() {
    let emailError = this.state.emailValid ? '' : 'Убедитесь, что поле заполнено верно';

    if (this.props.isFail) {
      emailError = 'Пользователь с указанным e-mail не найден.';
    }

    let content = (
      <div>
        <Text>
          Введите адрес электронной почты, на{'\u00A0'}которую мы{'\u00A0'}вышлем инструкцию по{'\u00A0'}восстановлению пароля
        </Text>
        {this.props.isFail && (
          <Text>
            Если вы забыли e-mail используемый в{'\u00A0'}игре, то{'\u00A0'}свяжитесь с{'\u00A0'}технической поддержкой
            {' '}
            <A href="mailto:trainer@readom.pro">trainer@readom.pro</A>
.
          </Text>
        )}
        <FormInner>
          <FormLine>
            <Input
              isValid={this.state.emailValid}
              onChange={this.changeEmail}
              placeholder="E-mail"
              type="text"
              value={this.state.email}
            />
            <Error>
              {emailError}
            </Error>
          </FormLine>
          <br />
          <FormLine>
            <Button type="submit">восстановить пароль</Button>
          </FormLine>
          <FormLine>
            <Link type="button" onClick={this.backToLoginPage}>вернуться к форме входа</Link>
          </FormLine>
        </FormInner>
      </div>
    );

    if (this.props.resetWasLoading) {
      content = (
        <div>
          <Text>
            <LoadingIndicator />
          </Text>
        </div>
      );
    } else if (this.props.resetWasSend) {
      content = (
        <div>
          <Text>
            Инструкции по восставновлению пароля отправлены на
            {' '}
            {this.props.resetLogin}
          </Text>
          <FormInner>
            <FormLine>
              <Button type="button" onClick={this.handleRedirect}>ОК</Button>
            </FormLine>
          </FormInner>
        </div>
      );
    }

    return (
      <Wrapper>
        <GlobalStyle />
        <Form onSubmit={this.handleForm}>
          <Logo onClick={this.handleRedirect} />
          <Title>
            Восстановление пароля
          </Title>
          {content}
        </Form>
      </Wrapper>
    );
  }
}

ResetPage.propTypes = {
  changeIsFail: PropTypes.func,
  changeLogin: PropTypes.func,
  resetRequest: PropTypes.func,
  isFail: PropTypes.bool,
  resetWasSend: PropTypes.bool,
  resetWasLoading: PropTypes.bool,
  resetLogin: PropTypes.string,
  history: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    changeLogin: login => dispatch(changeResetLogin(login)),
    changeIsFail: isFail => dispatch(changeResetIsFail(isFail)),
    resetRequest: () => dispatch(resetRequest()),
    resetResetPasswordState: () => dispatch(resetResetPasswordState()),
  };
}

const mapStateToProps = createStructuredSelector({
  isFail: makeSelectIsFail(),
  resetWasSend: makeSelectResetWasSend(),
  resetWasLoading: makeSelectResetWasLoading(),
  resetLogin: makeSelectResetLogin(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(ResetPage);
