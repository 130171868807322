import { css } from 'styled-components';
import { darken } from 'polished';

const buttonStyles = css`
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 25px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:hover {
    background-color: ${darken(0.1, '#35BB9A')};
    border-color: ${darken(0.1, '#35BB9A')};
  }
`;

export default buttonStyles;
