import React from 'react';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import map from 'lodash/map';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { STORE_NAME } from '../rating/constants';
import { STORE_NAME as ACTIVE_GAME_STORE_NAME } from '../activeGame/constants';
import { emptyData } from '../rating/reducer';

const selectGame = state => state.get('myGame');

const makeSelectGameId = () => createSelector(
  selectGame,
  gameState => gameState.get('id'),
);

const makeSelectSession = () => createSelector(
  selectGame,
  gameState => gameState.get('sessionId'),
);

const makeSelectQuestion = () => createSelector(
  selectGame,
  gameState => gameState.get('question'),
);

const makeSelectIsGameInitialized = () => createSelector(
  selectGame,
  gameState => gameState.get('initialized'),
);

const makeSelectIsFirstGame = () => createSelector(
  selectGame,
  gameState => gameState.get('isFirstGame'),
);

const makeSelectIsFirstGameTourWasShown = () => createSelector(
  selectGame,
  gameState => gameState.get('isFirstGameTourWasShown'),
);

const makeSelectImage = () => createSelector(
  selectGame,
  gameState => gameState.get('image'),
);

const makeSelectAnswers = () => createSelector(
  selectGame,
  gameState => gameState.get('answers'),
);

const makeSelectSendAnswerPending = () => createSelector(
  selectGame,
  gameState => gameState.get('sendAnswerPending'),
);

const makeSelectSendAnswers = () => createSelector(
  selectGame,
  gameState => gameState.get('sendAnswers'),
);

const makeSelectBlockInstanceId = () => createSelector(
  selectGame,
  gameState => gameState.get('blockInstanceId'),
);

const makeSelectMethodType = () => createSelector(
  selectGame,
  gameState => gameState.get('methodType'),
);

const makeSelectVariables = () => createSelector(
  selectGame,
  gameState => gameState.get('variables'),
);

const makeSelectMyMoodStatus = () => createSelector(
  selectGame,
  (gameState) => {
    const vars = gameState.get('variables');

    return get(vars, 'myMood', null);
  },
);

const makeSelectClientMoodStatus = () => createSelector(
  selectGame,
  (gameState) => {
    const vars = gameState.get('variables');

    return get(vars, 'clientMood', null);
  },
);

const makeSelectContractFrom = () => createSelector(
  selectGame,
  (gameState) => {
    const vars = gameState.get('variables');

    return get(vars, 'contractFrom', null);
  },
);

const makeSelectContractTo = () => createSelector(
  selectGame,
  (gameState) => {
    const vars = gameState.get('variables');

    return get(vars, 'contractTo', null);
  },
);

const makeSelectQuestionName = () => createSelector(
  selectGame,
  gameState => gameState.get('questionName'),
);

const makeSelectIsContinueGame = () => createSelector(
  selectGame,
  gameState => gameState.get('isContinueGame'),
);

const makeSelectKeysCount = () => createSelector(
  selectGame,
  gameState => gameState.get('keysCount'),
);

const makeSelectHighlightTip = () => createSelector(
  selectGame,
  gameState => gameState.get('highlightTip').toJS(),
);

const makeSelectFiftyFiftyTip = () => createSelector(
  selectGame,
  gameState => gameState.get('fiftyFiftyTip').toJS(),
);

const makeSelectSaveTip = () => createSelector(
  selectGame,
  gameState => gameState.get('saveTip').toJS(),
);

const makeSelectHighlightTipCount = () => createSelector(
  selectGame,
  gameState => gameState.get('highlightTip').get('count'),
);

const makeSelectFiftyFiftyTipCount = () => createSelector(
  selectGame,
  gameState => gameState.get('fiftyFiftyTip').get('count'),
);

const makeSelectSaveTipCount = () => createSelector(
  selectGame,
  gameState => gameState.get('saveTip').get('count'),
);

const makeSelectStats = () => createSelector(
  selectGame,
  gameState => gameState.get('stats').toJS(),
);

const makePercentInfo = () => createSelector(
  selectGame,
  (gameState) => {
    const stats = gameState.get('stats');

    return {
      percent: stats.get('percent'),
      percentBoosterFromCompanyEnabled: stats.get('percentBoosterFromCompanyEnabled'),
      percentBoosterFromCompanyValue: stats.get('percentBoosterFromCompanyValue'),
    };
  },
);

const makeSelectCanContinue = () => createSelector(
  selectGame,
  gameState => gameState.get('canContinue'),
);

const makeSelectCards = () => createSelector(
  selectGame,
  gameState => gameState.get('cards'),
);

const makeSelectIsAllCardsOpened = () => createSelector(
  selectGame,
  gameState => gameState.get('openCardsCount') === gameState.get('allCardsCount'),
);

const makeSelectOpenCardsCount = () => createSelector(
  selectGame,
  gameState => gameState.get('openCardsCount'),
);

const makeSelectPercentOfBestSeller = () => createSelector(
  selectGame,
  gameState => gameState.get('percentOfBestSeller'),
);

const makeSelectAllCardsCount = () => createSelector(
  selectGame,
  gameState => gameState.get('allCardsCount'),
);

const makeSelectFinishSum = () => createSelector(
  selectGame,
  gameState => gameState.get('finishSum'),
);

const makeSelectFinishPaid = () => createSelector(
  selectGame,
  gameState => gameState.get('finishPaid'),
);

const makeSelectFinishTitle = () => createSelector(
  selectGame,
  gameState => gameState.get('finishTitle'),
);

const makeSelectFinishStatus = () => createSelector(
  selectGame,
  gameState => gameState.get('finishStatus'),
);

const makeSelectFinishLoading = () => createSelector(
  selectGame,
  gameState => gameState.get('finishLoading'),
);

const makeSelectFinishSuccess = () => createSelector(
  selectGame,
  gameState => gameState.get('finishSuccess'),
);

const makeSelectReceivedKeysData = () => createSelector(
  selectGame,
  (gameState) => {
    const data = {
      receivedKeys: [],
      cards: [],
    };

    const userKeySession = gameState.get('receivedKeys').get('userKeySession');
    const cardList = gameState.get('receivedKeys').get('cardList');

    data.receivedKeys = map(userKeySession, (receivedKey, index) => ({
      id: `receive-key-${index}`,
      toKeyId: receivedKey.CardKeyID,
      toCardId: receivedKey.CardID,
      receivedKeyRef: React.createRef(),
    }));

    data.cards = map(cardList, (card) => {
      if (!card.Image) {
        // Таким образом исключаются лайвхаки
        return false;
      }

      return ({
        cardId: card.CardID,
        image: card.Image,
        keys: reverse(sortBy(map(card.CardKeyList, (cardKey) => {
          const willOpen = !!find(data.receivedKeys, { toKeyId: cardKey.CardKeyID }, false);

          return ({
            id: cardKey.CardKeyID,
            open: !willOpen && cardKey.HasKey,
            willOpen: !!find(data.receivedKeys, { toKeyId: cardKey.CardKeyID }, false),
            keyOnBookRef: React.createRef(),
          });
        }), ['willOpen', 'open'])),
      });
    });

    return data;
  },
);

const makeSelectClientMoodDirection = () => createSelector(
  selectGame,
  gameState => gameState.get('clientMoodDirection'),
);

const makeSelectMyMoodAbsoluteValue = () => createSelector(
  selectGame,
  gameState => gameState.get('myMoodAbsoluteValue'),
);

const makeSelectClientMoodAbsoluteValue = () => createSelector(
  selectGame,
  gameState => gameState.get('clientMoodAbsoluteValue'),
);

const makeSelectMyMoodValue = () => createSelector(
  selectGame,
  gameState => gameState.get('myMoodValue'),
);

const makeSelectClientMoodValue = () => createSelector(
  selectGame,
  gameState => gameState.get('clientMoodValue'),
);

const makeSelectMyMoodDirection = () => createSelector(
  selectGame,
  gameState => gameState.get('myMoodDirection'),
);

const makeSelectStavka = () => createSelector(
  selectGame,
  gameState => gameState.get('stavkaState'),
);

const makeSelectSuperGame = () => createSelector(
  selectGame,
  gameState => gameState.get('superGame').toJS(),
);

const makeSelectStage = () => createSelector(
  selectGame,
  gameState => gameState.get('stage'),
);

const makeSelectStagesInfo = () => createSelector(
  selectGame,
  gameState => gameState.get('stagesInfo'),
);

const makeSelectIsLoading = () => createSelector(
  selectGame,
  gameState => gameState.get('isLoading'),
);

const makeSelectKeysCountForBuy = () => createSelector(
  selectGame,
  gameState => gameState.get('keysCountForBuy'),
);

const makeSelectCardKeyProductPrice = () => createSelector(
  selectGame,
  gameState => gameState.get('productPrice').toJS(),
);

const makeSelectIsLoadingCards = () => createSelector(
  selectGame,
  gameState => gameState.get('isLoadingCards'),
);

const makeSelectCardIdForBuy = () => createSelector(
  selectGame,
  gameState => gameState.get('cardIdForBuy'),
);

const makeSelectRating = () => createSelector(
  selectGame,
  gameState => gameState.get('rating'),
);

const makeSelectLastBuyCard = () => createSelector(
  selectGame,
  gameState => gameState.get('lastBuyCard'),
);

const makeSelectCardBuyPending = () => createSelector(
  selectGame,
  gameState => gameState.get('cardBuyPending'),
);

const makeSelectSetUserEmail = () => createSelector(
  selectGame,
  gameState => gameState.get('setUserEmail'),
);

const makeSelectSetUserEmailPending = () => createSelector(
  selectGame,
  gameState => gameState.get('setUserEmailPending'),
);

const makeSelectSetUserEmailSuccess = () => createSelector(
  selectGame,
  gameState => gameState.get('setUserEmailSuccess'),
);

const makeSelectFinishRecommendation = () => createSelector(
  selectGame,
  gameState => gameState.get('finishRecommendation'),
);

const makeSelectFinalCupImage = () => createSelector(
  selectGame,
  gameState => gameState.get('finalCupImage'),
);

const makeSelectFinalCupImageTexts = () => createSelector(
  selectGame,
  gameState => gameState.get('finalCupImageTexts'),
);

const activeGameRes = state => (
  state.get(ACTIVE_GAME_STORE_NAME).get('gameId')
);

const makeSelectShowActionButtonsOnMobile = () => createSelector(
  selectGame,
  gameState => gameState.get('showActionButtonsOnMobile'),
);

const makeSelectCurrentGameProgress = () => createSelector(
  selectGame,
  gameState => gameState.get('currentGameProgress'),
);

const makeSelectGameProgressList = () => createSelector(
  selectGame,
  gameState => gameState.get('gameProgressList'),
);

const makeSelectWidgetList = () => createSelector(
  selectGame,
  gameState => gameState.get('widgetList'),
);

const makeSelectWidgetDescriptions = () => createSelector(
  selectGame,
  gameState => gameState.get('widgetDescriptions'),
);

export {
  makeSelectWidgetList,
  makeSelectWidgetDescriptions,
  makeSelectCurrentGameProgress,
  makeSelectGameProgressList,
  makeSelectShowActionButtonsOnMobile,
  makeSelectPercentOfBestSeller,
  makeSelectFinalCupImage,
  makeSelectFinalCupImageTexts,
  makeSelectSetUserEmail,
  makeSelectSetUserEmailPending,
  makeSelectSetUserEmailSuccess,
  makeSelectLastBuyCard,
  makeSelectCardBuyPending,
  makeSelectSession,
  makeSelectQuestion,
  makeSelectImage,
  makeSelectAnswers,
  makeSelectSendAnswers,
  makeSelectBlockInstanceId,
  makeSelectMethodType,
  makeSelectGameId,
  makeSelectVariables,
  makeSelectQuestionName,
  makeSelectIsContinueGame,
  makeSelectKeysCount,
  makeSelectHighlightTip,
  makeSelectFiftyFiftyTip,
  makeSelectSaveTip,
  makeSelectCanContinue,
  makeSelectCards,
  makeSelectAllCardsCount,
  makeSelectOpenCardsCount,
  makeSelectReceivedKeysData,
  makeSelectFinishSum,
  makeSelectFinishPaid,
  makeSelectFinishLoading,
  makeSelectFinishSuccess,
  makeSelectMyMoodDirection,
  makeSelectMyMoodValue,
  makeSelectMyMoodStatus,
  makeSelectClientMoodDirection,
  makeSelectClientMoodStatus,
  makeSelectClientMoodValue,
  makeSelectStavka,
  makeSelectStats,
  makeSelectSuperGame,
  makeSelectStage,
  makeSelectIsLoading,
  makeSelectIsLoadingCards,
  makeSelectKeysCountForBuy,
  makeSelectCardKeyProductPrice,
  makeSelectCardIdForBuy,
  makeSelectRating,
  makeSelectIsFirstGame,
  makeSelectIsGameInitialized,
  makeSelectSendAnswerPending,
  makeSelectIsAllCardsOpened,
  makeSelectIsFirstGameTourWasShown,
  makeSelectFinishTitle,
  makeSelectFinishStatus,
  makeSelectFinishRecommendation,
  makeSelectHighlightTipCount,
  makeSelectFiftyFiftyTipCount,
  makeSelectSaveTipCount,
  makeSelectContractFrom,
  makeSelectContractTo,
  makeSelectMyMoodAbsoluteValue,
  makeSelectClientMoodAbsoluteValue,
  makePercentInfo,
  makeSelectStagesInfo,
};
