import styled from 'styled-components';

const Text = styled.p`
  color: #ffffff;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  line-height: 20px;
  margin: 10px 0;
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

export default Text;
