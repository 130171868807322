import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import Face from './Face';

class MobileOwl extends React.PureComponent {
  static propTypes = {
    renderContent: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Face>
        <Dialog>
          {this.props.renderContent({})}
        </Dialog>
      </Face>
    );
  }
}

export default MobileOwl;
