import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import { getCardsAll } from '../../modules/myGame/actions';
import { apiRequest as createApiRequest } from '../../../utils/request';
import { SEND_REQUEST, SEND_REQUEST_ITERATE } from '../../modules/markCardAsViewed/constants';
import { sendRequestIterateSuccess, sendRequestIterate, sendRequest } from '../../modules/markCardAsViewed/actions';
import { makeSelectQueue } from '../../modules/markCardAsViewed/selectors';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* iterateQueue() {
    const url = yield select(makeSelectApiUrl());
    const queue = yield select(makeSelectQueue());
    if (!queue.length) {
      return;
    }

    const cardId = queue[0];
    const requestURL = `${url}/CardSetWatch/${encodeURIComponent(cardId)}`;

    try {
      yield call(apiRequest, requestURL);
      yield put(sendRequestIterateSuccess(cardId));
      yield put(sendRequest());
    } catch (err) {
    }
  }

  function* markCardAsViewed() {
    const queue = yield select(makeSelectQueue());
    if (!queue.length) {
      yield put(getCardsAll());
    }

    yield put(sendRequestIterate());
  }

  function* markCardAsViewedSaga() {
    yield takeLeading(SEND_REQUEST, markCardAsViewed);
    yield takeLeading(SEND_REQUEST_ITERATE, iterateQueue);
  }

  return markCardAsViewedSaga;
}

export default build;
