import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TimeRoot = styled.div`
   white-space: nowrap;
`;

const H = styled.span`
  font-size: 12px;
  padding-left: 2px;
`;

const M = styled.span`
  font-size: 12px;
  padding-left: 2px;
`;

const S = styled.span`
  font-size: 12px;
  padding-left: 2px;
`;

const getTimeFromDuration = (_duration) => {
  const duration = parseInt(_duration, 10);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - (hours * 3600)) / 60);
  const seconds = duration - (hours * 3600) - (minutes * 60);

  return {
    hours,
    minutes,
    seconds,
  };
};

class TimeDuration extends React.PureComponent {
  static defaultProps = {
    hideSeconds: false,
  };

  static propTypes = {
    duration: PropTypes.number.isRequired,
    hideSeconds: PropTypes.bool,
  };

  render() {
    const { duration, hideSeconds } = this.props;

    const { hours, minutes, seconds } = getTimeFromDuration(duration);

    return (
      <TimeRoot>
        {hours > 0 && (
          <span>
            {hours}
            <H>ч</H>
          </span>
        )}
        {' '}
        <span>
          {minutes}
          <M>мин</M>
        </span>
        {!hideSeconds && (
          <React.Fragment>
            {' '}
            <span>
              {seconds}
              <S>cек</S>
            </span>
          </React.Fragment>
        )}
      </TimeRoot>
    );
  }
}

export default TimeDuration;
