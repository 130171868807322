import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);


const makeSelectFinishPageReport = () => createSelector(
  storeSlice,
  store => ({
    dataTopOne: store.get('dataTopOne'),
    fieldsTopOne: store.get('fieldsTopOne'),

    dataTopTwo: store.get('dataTopTwo'),
    fieldsTopTwo: store.get('fieldsTopTwo'),

    dataBottomOne: store.get('dataBottomOne'),
    fieldsBottomOne: store.get('fieldsBottomOne'),

    dataBottomTwo: store.get('dataBottomTwo'),
    fieldsBottomTwo: store.get('fieldsBottomTwo'),
  }),
);

const makeSelectFinishPageReportPending = () => createSelector(
  state => state.get(STORE_NAME),
  store => store.get('pending'),
);

const makeSelectFinishPageReportFail = () => createSelector(
  state => state.get(STORE_NAME),
  store => store.get('fail'),
);

export {
  makeSelectFinishPageReport,
  makeSelectFinishPageReportPending,
  makeSelectFinishPageReportFail,
};
