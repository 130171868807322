import styled from 'styled-components';

const H1 = styled.h1`
  font-family: 'Merriweather', serif;
  font-size: 30px;
  font-weight: bold;
  color: ${props => (props.color ? props.color : '#1C1C1C')};
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 9px;
  margin-top: 12px;
  max-width: ${props => (props.noMaxWidth ? 'none' : '315px')};
  text-align: ${props => (props.align ? props.align : 'left')};
  ${props => (props.fullWidth && 'width: 100%;')}
`;

export default H1;
