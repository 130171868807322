import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withProps from 'recompose/withProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { createStructuredSelector } from 'reselect';
import { makeSelectProfile } from '../../redux/modules/profile/selectors';
import { getCompanyGameRating } from '../../redux/modules/rating/actions';
import {
  makeSelectActiveRatingTab,
  makeSelectCompanyGameRatingForActiveGame,
} from '../../redux/modules/rating/selectors';

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  companyRatingForActiveGame: makeSelectCompanyGameRatingForActiveGame(),
});

const mapStateToProps2 = createStructuredSelector({
  activeGameId: makeSelectActiveRatingTab(),
});

const withConnect2 = connect(mapStateToProps2);

const mapDispatchToProps = dispatch => ({
  onLoad: ({ gameId }) => dispatch(getCompanyGameRating({ gameId, isDirector: false })),
});

const withActiveGameProps = withProps((props) => {
  const { activeGameId } = props;

  return {
    activeGameId,
    gameId: activeGameId,
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentDidMount() {
    const { onLoad, activeGameId } = this.props;
    onLoad({ gameId: activeGameId });
  },
});

const withOwnProps2 = withProps((props) => {
  const { data = [], pending, fields, fail } = props.companyRatingForActiveGame;
  const { profile } = props;
  const { employeeID: myEmployeeId } = profile;
  const { activeGameId } = props;

  return {
    myEmployeeId,
    gameRating: data,
    fields,
    fail,
    isRatingLoading: pending || !activeGameId,
  };
});

const withCustomHandlers = withHandlers({
  toggleOpenedEmployee: props => (employeeId) => {
    const { openedEmployee, setOpenedEmployee } = props;

    if (employeeId === openedEmployee) {
      setOpenedEmployee(null);
    } else {
      setOpenedEmployee(employeeId);
    }
  },
});

const enhance = compose(
  withState('openedEmployee', 'setOpenedEmployee', null),
  withConnect2,
  withActiveGameProps,
  withConnect,
  withLifecycle,
  withCustomHandlers,
  withOwnProps2,
);

export default enhance;
