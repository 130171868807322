import styled from 'styled-components';

const TabHeader = styled.div`
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0 1px 0 0 #DEDEDE;
  display: flex;
  justify-content: flex-end;
  height: 70px;
  padding: 0 15px;
  width: 100%;
`;

export default TabHeader;
