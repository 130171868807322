import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BalanceInPopup from '../../../../components/BalanceInPopup';
import Coin from '../../../../components/Coin';
import NormalText from '../../../../components/Text';
import SmallPopup from '../../../../components/SmallPopup';

const PopupText = styled(NormalText)`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

const CardImg = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  height: 285px;
  width: 220px;
  min-height: 285px;
  min-width: 220px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Space = styled.div`
  width: 10px;
`;

class BuyCardPopup extends React.PureComponent {
  renderBalanceInFooter = () => {
    if (this.props.cash || this.props.cash === 0) {
      return <BalanceInPopup balance={this.props.cash} small={this.props.small} />;
    }

    return null;
  };

  onSubmit = () => {
    this.props.onSubmit();
    this.props.onClose();
  };

  render() {
    const totalCost = this.props.price * this.props.keyCountForBuy;
    const balance = this.props.cash;

    const enoughMoney = totalCost <= balance;

    const submitText = (
      <Flex>
        {`Открыть за ${totalCost}`}
        <Space />
        <Coin size={20} />
      </Flex>
    );

    return (
      <SmallPopup
        small={this.props.small}
        title="Открыть карточку"
        cancelText={enoughMoney ? null : 'Закрыть'}
        pending={this.props.pending}
        submitText={enoughMoney ? submitText : null}
        isActive={this.props.open}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
        footer={this.renderBalanceInFooter()}
      >
        <PopupText>
          {enoughMoney && (
            <React.Fragment>
              <div>
                <Card>
                  <CardImg image={this.props.cardImage} />
                </Card>
              </div>
            </React.Fragment>
          )}
          {!enoughMoney && (
            <React.Fragment>
              <Card>
                <CardImg image={this.props.cardImage} />
              </Card>
              <Text>
                Недостаточно средств для покупки карточки, необходимо
                {' '}
                <b>{totalCost.toLocaleString()}</b>
                {' '}
                <Coin size={25} />
              </Text>
            </React.Fragment>
          )}
        </PopupText>
      </SmallPopup>
    );
  }
}

BuyCardPopup.propTypes = {
  cash: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  keyCountForBuy: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  small: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default BuyCardPopup;
