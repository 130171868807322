export const STORE_NAME = 'rating';

export const GET_PERSONAL_GAME_HEAD_RATING = 'readom/rating/GET_PERSONAL_GAME_HEAD_RATING';
export const GET_PERSONAL_GAME_HEAD_RATING_SUCCESS = 'readom/rating/GET_PERSONAL_GAME_HEAD_RATING_SUCCESS';
export const GET_PERSONAL_GAME_HEAD_RATING_FAIL = 'readom/rating/GET_PERSONAL_GAME_HEAD_RATING_FAIL';

export const GET_COMMON_GAME_RATING = 'readom/rating/GET_COMMON_GAME_RATING';
export const GET_COMMON_GAME_RATING_SUCCESS = 'readom/rating/GET_COMMON_GAME_RATING_SUCCESS';
export const GET_COMMON_GAME_RATING_FAIL = 'readom/rating/GET_COMMON_GAME_RATING_FAIL';

export const GET_COMPANY_GAME_HEAD_RATING = 'readom/rating/GET_COMPANY_GAME_HEAD_RATING';
export const GET_COMPANY_GAME_HEAD_RATING_SUCCESS = 'readom/rating/GET_COMPANY_GAME_HEAD_RATING_SUCCESS';
export const GET_COMPANY_GAME_HEAD_RATING_FAIL = 'readom/rating/GET_COMPANY_GAME_HEAD_RATING_FAIL';

export const GET_COMPANY_GAME_RATING = 'readom/rating/GET_COMPANY_GAME_RATING';
export const GET_COMPANY_GAME_RATING_SUCCESS = 'readom/rating/GET_COMPANY_GAME_RATING_SUCCESS';
export const GET_COMPANY_GAME_RATING_FAIL = 'readom/rating/GET_COMPANY_GAME_RATING_FAIL';

export const GET_EXTENDED_EMPLOYEE_RATING = 'readom/rating/GET_EXTENDED_EMPLOYEE_RATING';
export const GET_EXTENDED_EMPLOYEE_RATING_SUCCESS = 'readom/rating/GET_EXTENDED_EMPLOYEE_RATING_SUCCESS';
export const GET_EXTENDED_EMPLOYEE_RATING_FAIL = 'readom/rating/GET_EXTENDED_EMPLOYEE_RATING_FAIL';

export const SET_ACTIVE_RATING_TAB = 'readom/rating/SET_ACTIVE_RATING_TAB';
