import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import pure from 'recompose/pure';
import { Link } from 'react-router-dom';
import { makeSelectBrowser } from '../../../../redux/modules/browser/selectors';

import SimplePageBase from '../../../PageBase/SimplePageBase/index';

import Content from '../../../../components/Content/index';
import Section from '../../../../components/Section';

import BigText from './BigText';
import Flex from './Flex';
import H1 from './H1';
import PhoneBlock from './PhoneBlock';
import PhoneText from './PhoneText';
import PhonesWrapper from './PhonesWrapper';
import Text from './Text';
import TextWrapper from './TextWrapper';
import TextBlock from './TextBlock';

const SomeLink = pure(styled.a`
  color: inherit;
  text-decoration: none;
`);

class AboutPage extends React.PureComponent {
  render() {
    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;

    return (
      <SimplePageBase title="О проекте">
        <Section>
          <Content>
            <TextWrapper>
              <TextBlock>
                <H1>Мы — авторы своих результатов</H1>
                <Text>
                  Не&nbsp;секрет, что всё большую часть свободного времени мы&nbsp;проводим с&nbsp;мобильным телефоном
                  в&nbsp;руке. Да
                  что там, скажем прямо: мы&nbsp;стали информационными &laquo;наркоманами&raquo; и&nbsp;в&nbsp;свободное
                  время без информации уже
                  просто не&nbsp;можем.
                </Text>

                <Text>
                  Но&nbsp;ответьте честно: насколько время, которое вы&nbsp;проводите с&nbsp;мобильным телефоном
                  в&nbsp;руке, проводится
                  с&nbsp;пользой? По&nbsp;статистике основной режим&nbsp;&mdash; это общение и&nbsp;обмен информацией.
                  Второе место занимает
                  знакомство с&nbsp;новостями. Третье&nbsp;&mdash; досуг: приколы, игры, просмотр видео и&nbsp;так
                  далее. Возникает
                  закономерный вопрос: а&nbsp;мы&nbsp;действительно люди бизнеса? Сколько времени мы&nbsp;можем себе
                  позволить тратить
                  на&nbsp;общение, новости и&nbsp;досуг? А&nbsp;в&nbsp;кризис? А&nbsp;в&nbsp;эпоху нестабильности?
                </Text>
              </TextBlock>
              <TextBlock>
                <BigText small={small}>
                  Мы&nbsp;создали продукт, позволяющий проводить время в&nbsp;мобильном телефоне с&nbsp;пользой:
                  повышать свою
                  квалификацию, с&nbsp;бешеной скоростью догонять уходящее время и&nbsp;создавать себе компетенции,
                  адекватные
                  требованиям современной эпохи.
                </BigText>
              </TextBlock>
              <TextBlock>
                <Text>Знакомьтесь: мобильный тренер Readom&nbsp;&mdash; вы&nbsp;учитесь в&nbsp;любую свободную
                  минуту.</Text>

                <Text>
                  Мобильный тренер Readom&nbsp;&mdash; уникальный для русскоязычного бизнес-сообщества образовательный
                  проект
                  по&nbsp;созданию мобильных тренингов. С&nbsp;нашим тренером в&nbsp;любой момент вы&nbsp;можете попасть
                  в&nbsp;виртуальный мир
                  продаж, управления, переговоров, где действуют те&nbsp;же законы, что и&nbsp;в&nbsp;жизни. Обучение
                  происходит в
                  состоянии глубокого погружения в&nbsp;захватывающее приключение, что позволяет получать не&nbsp;только
                  новые
                  знания и&nbsp;навыки, но&nbsp;и&nbsp;удовольствие.
                </Text>

                <Text>
                  Мы&nbsp;обязуемся учить вас тем передовым технологиям, которые работают не&nbsp;только сегодня,
                  но&nbsp;и&nbsp;будут
                  работать в&nbsp;будущем. Для этого мы&nbsp;берём интервью, изучаем истории успешных людей, создаём
                  модель их
                  бесценного опыта и&nbsp;вводим в&nbsp;продукт, благодаря которому вы&nbsp;можете улучшать своё
                  мышление и&nbsp;менять свои
                  стратегии в&nbsp;бизнесе в&nbsp;любую свободную минуту.
                </Text>
              </TextBlock>
              <TextBlock>
                <Text bold>Что дает вам Readom?</Text>
                <Text>&mdash;&nbsp;&laquo;Мобильный тренер&raquo;&nbsp;&mdash; им&nbsp;можно пользоваться многократно
                  в&nbsp;любое удобное время, с&nbsp;любого доступного устройства: компьютера, планшета или
                  смартфона.</Text>

                <Text>&mdash;&nbsp;Помогает нарабатывать профессиональные управленческие и&nbsp;сбытовые навыки.</Text>

                <Text>&mdash;&nbsp;Экономит деньги, силы и&nbsp;время.</Text>

                <Text>&mdash;&nbsp;Базируется на&nbsp;реальных событиях бизнеса, взятых из&nbsp;живых интервью.</Text>

                <Text>&mdash;&nbsp;Внутри каждого тренинга вплетено множество историй с&nbsp;разными развязками, каждая
                  из&nbsp;которых удивляет и&nbsp;обучает.</Text>

                <Text>&mdash;&nbsp;Даёт обратную связь по&nbsp;всем вашим действиям, показывает, какие ошибки были
                  совершены и&nbsp;как их&nbsp;исправить.</Text>

                <Text>&mdash;&nbsp;В&nbsp;каждом тренинге имеется рейтинг и&nbsp;возможность сравнивать свои результаты
                  с&nbsp;результатами других участников из&nbsp;разных стран.</Text>

                <Text>&mdash;&nbsp;Для корпоративных клиентов, наряду с&nbsp;общим, имеются внутрикорпоративные
                  рейтинги.</Text>

                <Text>&mdash;&nbsp;Имеет возможность создавать индивидуальные тренинги под конкретные задачи
                  и&nbsp;продукты.</Text>

                <Text>&mdash;&nbsp;Первый мобильный тренинг нашего тренера Readom&nbsp;&mdash; &laquo;Миллионы&nbsp;в&nbsp;твоём&nbsp;кармане&raquo;&nbsp;&mdash; посвящен крупным продажам.</Text>
                <Text>
                  <Link to="/licence">Лицензионное соглашение Readom</Link>
                </Text>
              </TextBlock>
            </TextWrapper>
          </Content>
          <Content>
            <Flex flexDirection={small && 'column'}>
              <H1>Контакты</H1>

              <PhonesWrapper small={small}>
                <PhoneBlock>
                  <PhoneText>
                    Дмитрий Блаженнов
                  </PhoneText>
                </PhoneBlock>
                <PhoneBlock>
                  <PhoneText>
                    <SomeLink href="tel:+79232472288">+7-923-247-22-88</SomeLink>
                  </PhoneText>
                </PhoneBlock>
                <PhoneBlock>
                  <PhoneText>
                    <SomeLink href="mailto:captain@readom.pro">captain@readom.pro</SomeLink>
                  </PhoneText>
                </PhoneBlock>
              </PhonesWrapper>
            </Flex>
          </Content>
        </Section>
      </SimplePageBase>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  browser: makeSelectBrowser(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(AboutPage);
