const decodeHtmlEntities = (function () {
  if (typeof document === 'undefined') {
    return null;
  }

  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str) {
    let string = str;
    if (string && typeof string === 'string') {
      // strip script/html tags
      string = string.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      string = string.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = string;
      string = element.textContent;
      element.textContent = '';
    }

    return string;
  }

  return decodeHTMLEntities;
}());

export default decodeHtmlEntities;
