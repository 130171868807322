import { Base64 } from 'js-base64';
import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect/lib/index';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { withRouter } from 'react-router-dom';
import pure from 'recompose/pure';

import Book from '../../../../components/Book';
import NormalH1 from '../../../../components/H1';
import NormalH2 from '../../../../components/H2';
import NormalText from '../../../../components/Text';
import { makeSelectActiveGameId } from '../../../../redux/modules/activeGame/selectors';
import { trackOpenLibraryPopupInOverview } from '../../../../redux/modules/analytics/actions';
import { makeSelectGames, makeSelectGamesShowcases } from '../../../../redux/modules/games/selectors';
import { setActiveCardGroupId, setActiveCardId, setShowLibraryPopup } from '../../../../redux/modules/library/actions';
import { makeSelectImage, makeSelectShowActionButtonsOnMobile } from '../../../../redux/modules/myGame/selectors';
import { makeSelectText } from '../../../../redux/modules/owl/selectors';
import scrollLock from '../../../../utils/scrollLock';
import LibraryInPopup from '../../../LibraryInPopup';
import BuyCardPopup from './BuyCardPopup';

const Wrapper = pure(styled.div`
  width: 100%;
`);

const Header = pure(styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`);

const H1 = pure(styled(NormalH1)`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 0;
`);

const H2 = pure(styled(NormalH2)`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin: ${props => (props.small ? '15px 0 20px 0' : '35px 0 20px 0')};
  text-align: center;
`);

const Text = pure(styled(NormalText)`
  color: #808085;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
`);

const Body = pure(styled.div`
  display: block;
`);

const Group = pure(styled.div`
  width: 100%;
`);

const GroupHeader = pure(styled.div`
  border-bottom: 1px solid #DEDEDE;
`);

const GroupBody = pure(styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`);

class Library extends React.PureComponent {
  state = {
    showBuyCardPopup: false,
    keyCountForBuy: null,
    cardIdForBuy: null,
    buyCardImage: null,
    buyCardName: null,
    buyCardTitle: null,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const cardIdParam = urlParams.get('cardId');
    let cardId = null;
    if (cardIdParam) {
      cardId = Base64.decode(cardIdParam);

      const element = document.getElementById(`big-card-book-${cardId}`);
      if (element && element.scrollIntoView) {
        element.scrollIntoView();
        window.scrollBy(0, -80);
      }

      this.props.setShowLibraryPopup(true);
      this.props.setActiveCardId(this.props.lastBuyCard);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.lastBuyCard && prevProps.lastBuyCard !== this.props.lastBuyCard) {
      this.setState({
        showBuyCardPopup: false,
      });

      this.props.setShowLibraryPopup(true);
      this.props.setActiveCardId(this.props.lastBuyCard);
      this.props.setActiveCardGroupId(null);
    }
  };

  showBuyPopup = (keyCount, cardId, image) => {
    scrollLock.on();

    this.setState({
      showBuyCardPopup: true,
      keyCountForBuy: keyCount,
      cardIdForBuy: cardId,
      buyCardImage: image,
      buyCardName: '',
      buyCardTitle: '',
    });
  };

  closeBuyPopup = () => {
    scrollLock.off();

    this.setState({
      showBuyCardPopup: false,
      keyCountForBuy: null,
      cardIdForBuy: null,
      buyCardImage: null,
      buyCardName: null,
      buyCardTitle: null,
    });
  };

  buyCard = () => {
    this.props.onBuyCard(this.state.keyCountForBuy, this.state.cardIdForBuy);
  };

  renderBooks = (cardGroups) => {
    if (!isEmpty(cardGroups)) {
      if (cardGroups.isLifeHack) {
        const lifeHackBook = cardGroups.CardList[0];
        const lifeHackImage = get(cardGroups.CardList[0], 'ImageUrl', null);
        const bookCoverImageUrl = lifeHackImage;

        return (
          <GroupBody key="life-hack">
            <Book
              small={this.props.small}
              backgroundImage={bookCoverImageUrl}
              id={lifeHackBook.CardID}
              groupId={cardGroups.GroupCardID}
              isActive
              onClickActive={this.openLibraryPopup}
              onClickInactive={this.showBuyPopup}
            />
          </GroupBody>
        );
      }

      return (
        <GroupBody key="books">
          {cardGroups.CardList.map((card) => {
            let bookCoverImageUrl = null;
            if (card.ImageUrl) {
              bookCoverImageUrl = card.ImageUrl;
            }

            return (
              <Book
                small={this.props.small}
                key={card.CardID}
                isNew={!card.IsWatch}
                backgroundImage={bookCoverImageUrl}
                id={card.CardID}
                groupId={cardGroups.GroupCardID}
                isActive={card.KeyHas >= card.KeyCount || card.CardID === this.props.lastBuyCard}
                keyCount={card.KeyCount}
                keyHas={card.KeyHas}
                onClickActive={this.openLibraryPopup}
                onClickInactive={this.showBuyPopup}
              />
            );
          })}
        </GroupBody>
      );
    }

    return <div />;
  };

  renderGroups = () => {
    const cardGroups = this.props.cards;
    const { small } = this.props;

    if (!isEmpty(cardGroups)) {
      return cardGroups.map((cards) => {
        return (
          <Group key={cards.GroupCardID}>
            <GroupHeader>
              <H2 small={small}>
                {`${cards.Title2} (${cards.OpenCount}/${cards.AllCount})`}
              </H2>
            </GroupHeader>
            {this.renderBooks(cards)}
          </Group>
        );
      });
    }

    return <div />;
  };

  closeLibraryPopup = () => {
    this.props.setShowLibraryPopup(false);
    this.props.setActiveCardId(null);
  };

  openLibraryPopup = (cardGroupId, cardID) => {
    this.props.setShowLibraryPopup(true);
    this.props.setActiveCardId(cardID);
    this.props.setActiveCardGroupId(cardGroupId);
    this.props.trackOpenLibraryPopupInOverview(cardID);
  };

  render() {
    const {
      totalCardsCount,
      openedCardsCount,
      games = [],
      gamesShowcases = [],
      activeGameId,
    } = this.props;
    const allGames = [...games, ...gamesShowcases];

    const currentGameInfo = find(allGames, { GameID: activeGameId }, false);
    const gameOptionsValues = get(currentGameInfo, 'OptionsValues');
    const libraryTitle = get(gameOptionsValues, 'libraryTitle', '');

    let openedPercent = 0;
    if (totalCardsCount > 0) {
      openedPercent = Math.ceil((openedCardsCount * 100) / totalCardsCount);
    }

    return (
      <Wrapper>
        <Header>
          <H1 align="center" noMaxWidth>{libraryTitle}</H1>
          <Text>
            {`Открыто: ${openedPercent.toString().replace('.', ',')}%`}
          </Text>
        </Header>
        <Body>
        {this.renderGroups()}
        <LibraryInPopup
          small={this.props.small}
          onClose={this.closeLibraryPopup}
          onBuyCard={this.showBuyPopup}
        />
        </Body>
        {this.state.keyCountForBuy !== null && (
          <BuyCardPopup
            small={this.props.small}
            open={this.state.showBuyCardPopup}
            onClose={this.closeBuyPopup}
            onSubmit={this.buyCard}
            price={this.props.cardKeyPrice}
            keyCountForBuy={this.state.keyCountForBuy}
            pending={this.props.cardBuyPending}
            cash={this.props.cash}
            cardImage={this.state.buyCardImage}
          />
        )}
      </Wrapper>
    );
  }
}

Library.propTypes = {
  cash: PropTypes.number,
  cards: PropTypes.any,
  totalCardsCount: PropTypes.number,
  openedCardsCount: PropTypes.number,
  cardKeyPrice: PropTypes.number.isRequired,
  onBuyCard: PropTypes.func,
  setActiveCardGroupId: PropTypes.func.isRequired,
  cardBuyPending: PropTypes.bool,
  small: PropTypes.bool,
  lastBuyCard: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  activeGameId: makeSelectActiveGameId(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
});

const mapDispatchToProps = dispatch => ({
  trackOpenLibraryPopupInOverview: cardId => dispatch(trackOpenLibraryPopupInOverview(cardId)),
  setActiveCardId: value => dispatch(setActiveCardId(value)),
  setActiveCardGroupId: value => dispatch(setActiveCardGroupId(value)),
  setShowLibraryPopup: value => dispatch(setShowLibraryPopup(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withRouter,
)(Library);
