import {
  REFRESH_SLIDE_CONTENT, REFRESH_SLIDE_CONTENT_FAIL, REFRESH_SLIDE_CONTENT_SUCCESS,
} from './constants';

export function refreshSlideContent() {
  return {
    type: REFRESH_SLIDE_CONTENT,
  };
}

export function refreshSlideContentSuccess() {
  return {
    type: REFRESH_SLIDE_CONTENT_SUCCESS,
  };
}

export function refreshSlideContentFail() {
  return {
    type: REFRESH_SLIDE_CONTENT_FAIL,
  };
}
