import React from 'react';
import PropTypes from 'prop-types';

import BgImage from './fiftyFifty.svg';
import Tip from './Tip';

class FiftyFiftyTip extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <Tip
        onClick={this.props.onClick}
        backgroundImage={BgImage}
        small={this.props.small}
      >
        {this.props.children}
      </Tip>
    );
  }
}

FiftyFiftyTip.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FiftyFiftyTip;
