import React from 'react';
import pure from 'recompose/pure';
import styled, { createGlobalStyle } from 'styled-components';
import Typograf from 'typograf';
import AnswerWrapper from '../styled/AnswerWrapper';
import Answers from '../Answers';
import Question from '../styled/Question';

const Q1 = pure(styled.div`
  display: flex;
  justify-content: center;
`);

const Q2 = pure(styled.div`
  max-width: 650px;
  flex: 1;
`);

const TextGlobalStyle = createGlobalStyle`
  html {
    overflow-x: auto;
    overflow-y: scroll;
  }
`;

const Obuchalka = styled(Question)`
  margin-bottom: 20px;
  
  border-left: 4px solid #bbbbbb;
  padding-left: 10px;
  
  b {
    display: block;
  }
`;

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const SimpleQuestion = ({ question, obuchalkaText, obuchalkaTarget }) => (
  <>
    <TextGlobalStyle />
    <Q1>
      <Q2 className="tour-step-1">
        {(obuchalkaTarget || obuchalkaText) && (
          <Obuchalka>
            {obuchalkaTarget && (
              <b dangerouslySetInnerHTML={{ __html: tp.execute(obuchalkaTarget) }} />
            )}
            {obuchalkaText && (
              <i dangerouslySetInnerHTML={{ __html: tp.execute(obuchalkaText) }} />
            )}
          </Obuchalka>
        )}
        <Question dangerouslySetInnerHTML={{ __html: tp.execute(question) }} />
      </Q2>
    </Q1>

    <AnswerWrapper className="tour-step-2">
      <Answers />
    </AnswerWrapper>
  </>
);

export default SimpleQuestion;
