import React from 'react';
import pure from 'recompose/pure';
import styled, { createGlobalStyle } from 'styled-components';
import Typograf from 'typograf';
import AnswerWrapper from '../styled/AnswerWrapper';
import Answers from '../Answers';
import owlDesktopPNG from '../../../../../assets/owl.png';
import DialogBg from './text-after.svg';
import owlMobilePNG from '../../../../../assets/owl-mobile.png';

const Q1 = pure(styled.div`
  display: flex;
  justify-content: center;
`);

const Q2 = pure(styled.div`
  max-width: 650px;
  flex: 1;
`);

const OwlImage = pure(styled.div`
  background: url(${owlDesktopPNG}) no-repeat;
  width: 102px;
  height: 191px;
  background-size: contain;
  
  @media (max-width: 650px) {
    background: url(${owlMobilePNG}) no-repeat;
    width: 50px;
    height: 50px;
    background-size: contain;
  }
`);

const OwlLifeBoy = pure(styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
`);

const OwlDialog = pure(styled.div`
  user-select: none;
  background: #343642;
  border-radius: 35px;
  color: #FFFFFF;
  font-family: 'Neucha', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  padding: 30px;
  position: relative;
  transition: opacity .3s ease;
  width: 100%;
  margin-left: 30px;
  flex: 1;
  & input {
    user-select: all;
  }
  
  &::before {
    background-image: url(${DialogBg});
    content: '';
    display: block;
    height: 55px;
    position: absolute;
    left: -35px;
    width: 55px;
    top: 60px;
    transform: rotate(90deg);
    
    @media (max-width: 650px) {
      top: 23px;
      transform: rotate(90deg);
      left: -30px;
    }
  }
`);

const TextGlobalStyle = createGlobalStyle`
  html {
    overflow-x: auto;
    overflow-y: scroll;
  }
`;

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const LifeBoy = ({ owlMessage }) => (
  <>
    <TextGlobalStyle />
    <Q1>
      <Q2>
        <OwlLifeBoy>
          <OwlImage />
          <OwlDialog dangerouslySetInnerHTML={{ __html: tp.execute(owlMessage) }} />
        </OwlLifeBoy>
      </Q2>
    </Q1>

    <AnswerWrapper>
      <Answers />
    </AnswerWrapper>
  </>
);

export default LifeBoy;
