import styled from 'styled-components';

const OwlWrapper = styled.div`
  height: 100%;
  width: 100%;
  z-index: 25;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  ${props => props.owlInCenter && `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export default OwlWrapper;
