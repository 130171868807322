import styled from 'styled-components';
import { darken } from 'polished';

const AddButton = styled.div`
  background-color: #3CC9A4;
  background-image: url(${props => props.background});
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  transition: background-color .3s ease-out;
  width: 50px;
  min-width: 50px;

  &:hover {
    background-color: ${darken(0.1, '#3CC9A4')};
  }
`;

export default AddButton;
