import { createSelector } from 'reselect';

const State = state => state.get('prevCompanyGameRating');

const makeSelectPrevRating = () => createSelector(
  State,
  state => state.get('prevRatingForAllGame').toJS(),
);

export {
  makeSelectPrevRating,
};
