import styled from 'styled-components';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import CoinSVG from '../../assets/coin.svg';

const Coin = pure(styled.span`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: url(${CoinSVG});
  background-position: center;
  background-size: ${props => props.size}px ${props => props.size}px;
`);

Coin.propTypes = {
  size: PropTypes.number,
};

Coin.defaultProps = {
  size: 40,
};

export default Coin;
