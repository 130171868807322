import styled from 'styled-components';

const FinishStatusText = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 30px;
  max-width: 620px;
  margin: ${props => (props.marginBottom ? `16px auto ${props.marginBottom}px` : '16px auto 0')};
  text-align: left;
  padding: 0 30px;
`;

export default FinishStatusText;
