import posed from 'react-pose';
import styled from 'styled-components';

const ANIMATION_DURATION = 1500;

const EventAnimation = posed.div({
  enter: {
    y: 20,
    opacity: 1,
    transition: {
      opacity: {
        type: 'keyframes',
        values: [0, 0.6, 0.9, 1, 0.9, 0.6, 0],
        duration: ANIMATION_DURATION,
      },
      y: {
        type: 'keyframes',
        values: [20, -20],
        duration: ANIMATION_DURATION,
      },
    },
  },
  exit: {
    y: -20,
    opacity: 0,
  },
});

export const EventWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 6;
  pointer-events: none;
`;

export const Img = styled.img`
  margin-right: 10px;
  ${props => (props.state === 'down' && 'transform: rotate(180deg);')}
`;

export const Event = styled(EventAnimation)`
  display: block;
  background: #343642;
  padding: 15px 30px 15px 20px;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10), 0 0 2px 0 rgba(0,0,0,0.10);
  border-radius: 9px;
  min-width: 300px;
  margin-bottom: 5px;
`;

export const KeyEventOwl = styled.div`
  display: block;
  background: #343642;
  padding: 15px 30px 15px 20px;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10), 0 0 2px 0 rgba(0,0,0,0.10);
  border-radius: 9px;
  min-width: 300px;
  margin-bottom: 5px;
  z-index: 26;
`;
