import PropTypes from 'prop-types';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import Countdown from 'react-countdown-now';

const renderer = props => ({ seconds, completed }) => {
  if (completed) {
    return <Redirect {...props} />;
  }

  return (
    <span>
      {`Вы будете автоматически перенаправлены через ${seconds} сек.`}
    </span>
  );
};

class DelayedRedirect extends React.Component {
  static propTypes = {
    delay: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const { delay } = props;
    this.date = Date.now() + delay * 1000;
  }

  render() {
    const { delay, ...props } = this.props;

    return (
      <Countdown
        date={this.date}
        renderer={renderer(props)}
      />
    );
  }
}

export default DelayedRedirect;
