import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import withProps from 'recompose/withProps';
import setDisplayName from 'recompose/setDisplayName';
import { createStructuredSelector } from 'reselect';
import { getGameList, getGamesShowcases } from '../../redux/modules/games/actions';
import {
  makeSelectGames, makeSelectGamesData, makeSelectGamesShowcases, makeSelectGamesShowcasesPending,
  makeSelectGetGamesPending,
} from '../../redux/modules/games/selectors';
import URL_CONSTANTS from '../../urlConstants';
import canUseWebP from '../../utils/getCanSupportWebP';

const withLifecycle = lifecycle({
  componentWillMount() {
    this.props.getGameList();
    this.props.getGamesShowcases();
  },
});

const makeGameData = ({ game, gamesData }) => {
  const gameId = game.GameID;

  const description = get(game, 'OptionsValues.shortSlogan', '');
  const name = get(game, 'Name');

  const isCanUseWebP = canUseWebP();
  const imageWebP = get(game, 'OptionsValues.coverWebP', null);
  const imageSimple = get(game, 'OptionsValues.cover', null);
  const image = isCanUseWebP && imageWebP ? imageWebP : imageSimple;

  const gameShortName = get(game, 'NameSys');
  const inDevelop = get(game, 'IsDebug');

  let cardsOpenedPercent = 0;
  const cardHas = get(game, 'CardHas');
  const cardCount = get(game, 'CardCount');

  if (cardCount !== 0) {
    cardsOpenedPercent = Math.ceil((cardHas / cardCount) * 100 * 100) / 100;
  }

  const url = `${URL_CONSTANTS.PREFIXES.GAME_OVERVIEW}/${gameShortName}`;

  const gameData = get(find(gamesData, { id: game.GameID }), 'data');

  const dealMaxSum = get(gameData, 'dealMaxSum') || 0;
  const maxSumma = get(gameData, 'maxSumma') || 0;
  const betaAccess = get(gameData, 'betaAccess') || false;

  return {
    gameId,
    betaAccess,
    ownedGame: true,
    serie: '',
    inDevelop,
    name,
    image,
    description,
    url,
    maxSumma,
    dealMaxSum,
    cardsOpenedPercent,
  };
};

const withGameProps = withProps((props) => {
  const {
    games, gamesData, gamesShowcases,
  } = props;

  const myGamesData = map(games, (game) => {
    const data = makeGameData({ game, gamesData });

    return {
      ...data,
      ownedGame: true,
      gameAccessExpired: false,
    };
  });

  const shopGamesData = map(gamesShowcases, (game) => {
    const data = makeGameData({ game, gamesData });

    return {
      ...data,
      ownedGame: false,
      gameAccessExpired: false,
    };
  });

  const haveOwnGames = myGamesData.length > 0;

  return {
    haveOwnGames,
    myGamesData,
    shopGamesData,
  };
});

const mapDispatchToProps = dispatch => ({
  getGameList: () => dispatch(getGameList()),
  getGamesShowcases: () => dispatch(getGamesShowcases()),
});

const mapStateToProps = createStructuredSelector({
  games: makeSelectGames(),
  getGamesPending: makeSelectGetGamesPending(),
  gamesShowcases: makeSelectGamesShowcases(),
  gamesShowcasesPending: makeSelectGamesShowcasesPending(),
  gamesData: makeSelectGamesData(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withCustomProps = withProps((props) => {
  const { getGamesPending, gamesShowcasesPending } = props;

  const pending = getGamesPending || gamesShowcasesPending;

  return {
    pending,
  };
});

const enhance = compose(
  setDisplayName('GamesPageContent_Enhanced'),
  pure,
  withConnect,
  withCustomProps,
  withLifecycle,
  withGameProps,
);

export default enhance;
