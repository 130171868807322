import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import without from 'lodash/without';
import find from 'lodash/find';
import {
  call, put, select, takeLeading, all,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import { makeSelectApiUrl } from '../../modules/global/selectors';
import { apiRequest as createApiRequest } from '../../../utils/request';
import { getGameList } from '../../modules/games/actions';

import {
  PROFILE_REQUEST,
  PROFILE_CASH_REQUEST,
  USER_PHOTO_REQUEST,
} from '../../modules/profile/constants';
import {
  profileInfoSuccess,
  profileInfoFail,
  getCashInfo,
  getCashInfoSuccess,
  getCashInfoFail,
  setUserPhotoSuccess,
  setUserPhotoFail,
} from '../../modules/profile/actions';
import { makeSelectProfile } from '../../modules/profile/selectors';

function build(cookies) {
  function* getProfile() {
    const apiRequest = yield createApiRequest(cookies);

    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/ProfileInfo/`;
    const requestIsEmployerURL = `${url}/IsLoginedAsEmployee/`;
    const requestEmployeeOptionsURL = `${url}/EmployeeOptionGetSelf/`;

    try {
      const [request, requestIsEmployer] = yield all([
        call(apiRequest, requestURL),
        call(apiRequest, requestIsEmployerURL),
      ]);

      let isWorkInvoice = false;

      let canSeeCompanyRating = false;
      let canSeeCompanyExtendedRating = false;
      let canViewAllCompanyEmployee = false;
      let canEditAllCompanyEmployee = false;
      let canViewOwnDepartmentEmployee = false;
      let canEditOwnDepartmentEmployee = false;
      let showMeInRating = true;

      const IsLoginedAsEmployee = requestIsEmployer.data.result[0] === true;

      if (IsLoginedAsEmployee === true) {
        const requestEmployeeOptions = yield call(apiRequest, requestEmployeeOptionsURL);
        const employeeOptData = get(requestEmployeeOptions, 'data.result[0]', null);

        isWorkInvoice = get(employeeOptData, 'IsWorkInvoice');
        canViewAllCompanyEmployee = get(employeeOptData, 'IsCompanyEmployeeSelect');
        canEditAllCompanyEmployee = get(employeeOptData, 'IsCompanyEmployeeEdit');

        canViewOwnDepartmentEmployee = get(employeeOptData, 'IsDepartmentEmployeeSelect');
        canEditOwnDepartmentEmployee = get(employeeOptData, 'IsDepartmentEmployeeEdit');
        canSeeCompanyRating = get(employeeOptData, 'IsShowRating');
        canSeeCompanyExtendedRating = get(employeeOptData, 'IsShowRatingExt');
        showMeInRating = !get(request, 'data.result[0].Employee.HideInRating', false);
      }

      const rqRaw = get(request, 'data.result[0].Rating.RQ', 0);
      const rqRating = Math.round(rqRaw * 100) / 100;

      let canDebug = false;
      const isDebug = get(request, 'data.result[0].User.IsDebug', false);

      if (isDebug) {
        canDebug = true;
      }

      const email = IsLoginedAsEmployee ? get(request, 'data.result[0].Employee.Email', '') : get(request, 'data.result[0].User.Email', '');

      let firstPhone = null;

      const contacts = get(request, 'data.result[0].User.Contacts', null);
      if (contacts) {
        const tmp = filter(contacts, item => item.NameSys === 'cell_phone');
        if (tmp && tmp.length) {
          firstPhone = get(tmp[0], 'Contact');
        }
      }

      const userMainData = {
        name: request.data.result[0].User.Name,
        surname: request.data.result[0].User.SurName,
        middlename: request.data.result[0].User.MiddleName,
        email,
        firstPhone,
      };

      // TODO: HARDCODE_TYPE_2 Если в email есть @demo.demo то на фронте его имя будет Гость
      if (email.indexOf('@demo.demo') !== -1) {
        userMainData.email = 'Гость';
      }

      // TODO: HARDCODE_TYPE_2
      if (userMainData.surname.indexOf('demo_game_') !== -1) {
        userMainData.surname = '';
      }

      // TODO: HARDCODE_TYPE_2
      if (userMainData.middlename.indexOf('demo_game_') !== -1) {
        userMainData.middlename = '';
      }

      // TODO: HARDCODE_TYPE_2
      if (userMainData.name.indexOf('demo_game_') !== -1) {
        userMainData.name = '';
      }

      const company = get(request, 'data.result[0].Employee.CompanyName', '');
      const userEmail = get(request, 'data.result[0].User.Email', '');
      const employeeEmail = get(request, 'data.result[0].Employee.Email', '');

      const userId = request.data.result[0].User.UserID;

      const employeeId = get(request, 'data.result[0].Employee.EmployeeID', null);

      const identityId = IsLoginedAsEmployee ? employeeId : userId;

      const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID;

      if (LOG_ROCKET_APP_ID && identityId) {
        LogRocket.identify(identityId, {
          name: `${userMainData.name} ${userMainData.surname} ${userMainData.middlename}`,
          email: userMainData.email,

          employeeEmail,
          company,
          employeeId,
          isEmployer: IsLoginedAsEmployee,
        });
      }

      const profile = {
        id: request.data.result[0].User.UserID,
        EmployeeID: employeeId,
        ...userMainData,
        canDebug,
        canSeeCompanyRating,
        currency: request.data.result[0].User.CurrencyID,
        isEmployer: IsLoginedAsEmployee,
        isWorkInvoice,
        // TODO: FIXME
        // maxsumma: parseInt(get(requestVariables, 'data.result[0].maxsumma.Value', 0), 10),
        maxsumma: 0,
        // TODO: FIXME
        // canContinue: requestGameInfo.data.result[0].CanContinue,
        canContinue: false,
        company,
        companyId: get(request, 'data.result[0].Employee.CompanyID', ''),
        department: get(request, 'data.result[0].Employee.DepartmentName', ''),
        employeeEmail,
        userEmail,
        rqRating,
        showMeInRating,
        acl: {
          canSeeCompanyRating,
          canSeeCompanyExtendedRating,
          canViewAllCompanyEmployee,
          canEditAllCompanyEmployee,
          canViewOwnDepartmentEmployee,
          canEditOwnDepartmentEmployee,
          isWorkInvoice,
        },
      };

      if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
        Sentry.configureScope((scope) => {
          scope.setTag('isEmployer', String(IsLoginedAsEmployee));
          if (IsLoginedAsEmployee) {
            scope.setTag('company', company);
            scope.setTag('employeeEmail', employeeEmail);
          }
          scope.setTag('userEmail', userEmail);
        });
      }

      profile.photo = request.data.result[0].User.PhotoUrl;
      yield put(getGameList());
      yield put(profileInfoSuccess(profile));
      yield put(getCashInfo());
    } catch (err) {
      console.error(err);
      yield put(profileInfoFail());
    }
  }

  function* setUserPhotoSaga() {
    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/UserPhoto/`;
    const profile = yield select(makeSelectProfile());

    try {
      const params = {
        method: 'post',
        data: {
          Value: profile.photo,
        },
      };
      const apiRequest = yield createApiRequest(cookies);

      yield call(apiRequest, requestURL, params);
      yield put(setUserPhotoSuccess());
    } catch (err) {
      yield put(setUserPhotoFail());
    }
  }

  function* getCashSaga() {
    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/CurrencyCashFlow/`;
    const apiRequest = yield createApiRequest(cookies);

    try {
      const request = yield call(apiRequest, requestURL);

      const data = request.data.result[0].CurrencyCashFlowList;

      // TODO: HADRDCODE системное наименование ридомов зашито в код
      const readomsCash = find(data, { CurrencyNameSys: 'ridoms' });

      const virtualCash = map(without(data, readomsCash), item => ({
        name: item.CurrencyNameSys,
        balance: item.Balance,
      }));

      yield put(getCashInfoSuccess({
        readoms: data[0].Balance,
        cash: data[1].Balance,
        virtualCash,
      }));
    } catch (err) {
      yield put(getCashInfoFail());
    }
  }

  function* profileSaga() {
    yield takeLeading(PROFILE_REQUEST, getProfile);
    yield takeLeading(PROFILE_CASH_REQUEST, getCashSaga);
    yield takeLeading(USER_PHOTO_REQUEST, setUserPhotoSaga);
  }

  return profileSaga;
}

export default build;
