import styled from 'styled-components';
import NormalH1 from '../../H1';

const H1 = styled(NormalH1)`
  color: #ffffff;
  margin-top: 0;
  text-align: center;
  max-width: inherit;
`;

export default H1;
