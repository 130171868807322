import styled from 'styled-components';
import { device } from '../../../../utils/mediaQueries';

const FormItem = styled.div`
  width: calc((100% - 15px) / 2);
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export default FormItem;
