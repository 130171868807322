import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CloseIconWrapper = styled.button`
  cursor: pointer;
  opacity: .5;
  outline: none;
  padding: 10px;
  transition: opacity .3s ease-out;

  &:hover {
    opacity: 1;
  }
`;

class CloseIcon extends React.PureComponent {
  render() {
    const { props } = this;

    return (
      <CloseIconWrapper onClick={props.onClose}>
        <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            transform="translate(-534.000000, -496.000000)"
            strokeLinecap="round"
          >
            <g
              transform="translate(535.000000, 497.000000)"
              strokeWidth="2"
              style={{
                stroke: '#808085',
              }}
            >
              <path d="M0,0 L14.1421356,14.1421356" />
              <path d="M14.1421356,0 L0,14.1421356" />
            </g>
          </g>
        </svg>
      </CloseIconWrapper>
    );
  }
}

CloseIcon.propTypes = {
  onClose: PropTypes.func,
};

export default CloseIcon;
