import styled from 'styled-components';
import pure from 'recompose/pure';

const ContentWrapper = pure(styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'space-around')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
`);

export default ContentWrapper;
