import { createSelector } from 'reselect';

const selectFeedBackForm = state => state.get('feedBackForm');

const makeSelectFeedBackForm = () => createSelector(
  selectFeedBackForm,
  feedBackFormState => feedBackFormState.toJS(),
);

const makeSelectFeedBackFormMessage = () => createSelector(
  selectFeedBackForm,
  feedBackFormState => feedBackFormState.get('message'),
);

const makeSelectFeedBackFormPending = () => createSelector(
  selectFeedBackForm,
  feedBackFormState => feedBackFormState.get('pending'),
);

const selectGame = state => state.get('myGame');

const makeSelectGameSessionId = () => createSelector(
  selectGame,
  gameState => gameState.get('sessionId'),
);

export {
  makeSelectFeedBackForm,
  makeSelectFeedBackFormMessage,
  makeSelectFeedBackFormPending,
  makeSelectGameSessionId,
};
