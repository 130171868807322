import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import isString from 'lodash/isString';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectQuestion,
} from '../../../../../redux/modules/myGame/selectors';

const mapStateToProps = createStructuredSelector({
  rawQuestion: makeSelectQuestion(),
});

const withQuestionProps = withProps(({ rawQuestion }) => {
  if (isString(rawQuestion) && rawQuestion.indexOf('<obuchalka>') !== -1) {
    const html = document.createElement('div');
    // html.innerHTML = decodeHtmlEntities(rawQuestion);
    html.innerHTML = rawQuestion;
    const targetElements = html.getElementsByTagName('target');
    const target = targetElements[0].innerHTML;

    const textElements = html.getElementsByTagName('text');
    const text = textElements[0].innerHTML;

    const obuchalkaNode = html.getElementsByTagName('obuchalka')[0];

    obuchalkaNode.parentNode.removeChild(obuchalkaNode);

    return {
      question: html.innerHTML,
      obuchalkaText: text,
      obuchalkaTarget: target,
    };
  }

  return {
    question: rawQuestion,
    obuchalkaText: '',
    obuchalkaTarget: '',
  };
});

const withConnect = connect(mapStateToProps);

const enhance = compose(
  withConnect,
  withQuestionProps,
);

export default enhance;
