import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { trackVideoClick } from '../../../../redux/modules/analytics/actions';

const mapDispatchToProps = dispatch => ({
  trackVideoClick: where => dispatch(trackVideoClick(where)),
});

const withConnect = connect(null, mapDispatchToProps);

const enhance = compose(
  withConnect,
);

export default enhance;
