import styled from 'styled-components';

const Nickname = styled.span`
  color: ${props => (props.inGame ? '#1C1C1C' : '#FFFFFF')};
  font-size: 13px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  line-height: 20px;
  padding: 0 15px;
`;

export default Nickname;
