import * as Yup from 'yup';

const StartTrialGameWithEmailValidateSchema = Yup.object().shape({
  email: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .email('Убедитесь, что поле заполнено верно')
    .required('Поле обязательно для заполнения'),
});

export default StartTrialGameWithEmailValidateSchema;
