import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pure from 'recompose/pure';

const Root = pure(styled.section`
  background-color: ${props => (props.grey ? '#EBEBED' : 'none')};
  padding: ${props => (props.withPadding ? '30px 0' : '0')};
  ${props => (props.hasLeftMenu && props.leftMenuPadding ? `width: calc(100% - ${props.leftMenuPadding}px);` : 'width: 100%;')}
`);

const Section = (
  {
    withPadding, grey, leftMenuPadding, hasLeftMenu, ...rest
  },
) => (
  <Root
    withPadding={withPadding}
    grey={grey}
    leftMenuPadding={leftMenuPadding}
    hasLeftMenu={hasLeftMenu}
    {...rest}
  />
);

Section.propTypes = {
  grey: PropTypes.bool,
  withPadding: PropTypes.bool,
  hasLeftMenu: PropTypes.bool,
};

Section.defaultProps = {
  grey: false,
  withPadding: false,
  hasLeftMenu: true,
};

export default Section;
