import dateAndTime from 'date-and-time';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import escape from 'lodash/escape';
import indexOf from 'lodash/indexOf';
import replace from 'lodash/replace';
import isNaN from 'lodash/isNaN';
import map from 'lodash/map';
import last from 'lodash/last';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import {
  call, put, select, takeLeading, all, delay, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isString from 'lodash/isString';
import find from 'lodash/find';
import {
  OPTION_GAME_BOOSTER_BET,
  OPTION_GAME_BOOSTER_COMPANY_ENABLED,
  OPTION_GAME_CLIENT_MOOD,
  OPTION_GAME_CLIENT_MOOD_ARROW,
  OPTION_GAME_DEAL_CORRIDOR_FROM,
  OPTION_GAME_DEAL_CORRIDOR_TO,
  OPTION_GAME_FINISH_SUCCESS,
  OPTION_GAME_MY_MOOD,
  OPTION_GAME_MY_MOOD_ARROW,
  OPTION_GAME_PAID,
  OPTION_GAME_PASS_PERCENT,
  OPTION_GAME_PERCENT_FROM_DEAL,
  OPTION_GAME_RECOMMENDATION,
  OPTION_GAME_STATUS_HEADER,
  OPTION_GAME_STATUS_TEXT,
  OPTION_GAME_SUM,
  OPTION_GAME_SUPER_GAME,
} from '../../../gameOptionsConstants';
import { BOOSTER_FROM_COMPANY_VALUE } from '../../../gameConstants';
import { ORDER_PENDING_STATUS } from '../../../orderStatusConstants';
import getGameOptionValue from '../../../utils/getGameOptionValue';
import { apiRequest as createApiRequest } from '../../../utils/request';
import parseQuestNextStep from '../../../utils/RequestDataParse/parseQuestNextStep';
import { setActiveGameId } from '../../modules/activeGame/actions';

import { makeSelectActiveGameId } from '../../modules/activeGame/selectors';
import { makeSelectGameSessionId } from '../../modules/feedBackForm/selectors';
import {
  addShowCaseToCartFail,
  addShowCaseToCartSuccess,
  buyShowCaseFail,
  buyShowCaseFromDemoFail,
  buyShowCaseFromDemoSuccess,
  buyShowCaseSuccess,
  cartCheckout, cartCheckoutFail, cartCheckoutSuccess,
  getCart,
  getCartFail,
  getCartSuccess,
  getGameList,
  getGameListFail,
  getGameListSuccess, getGamesShowcasesFail, getGamesShowcasesSuccess,
  getInvoices,
  getInvoicesFail,
  getInvoicesSuccess,
} from '../../modules/games/actions';
import {
  ADD_SHOWCASE_TO_CART,
  ADD_SHOWCASE_TO_CART_SUCCESS, BUY_SHOWCASE, BUY_SHOWCASE_FROM_DEMO,
  CART_CHECKOUT,
  CART_CHECKOUT_SUCCESS,
  GET_ALL_GAMES_LIST,
  GET_CART, GET_GAMES_SHOWCASE,
  GET_INVOICES,
} from '../../modules/games/constants';
import {
  makeSelectAddShowCase,
  makeSelectBuyShowCaseFromDemoId,
  makeSelectBuyShowCaseId, makeSelectGames, makeSelectGamesShowcases,
} from '../../modules/games/selectors';
import { setGamesOptions, setGamesOptionsForShowcaseGames } from '../../modules/gamesOptions/actions';
import { makeSelectGamesOptions } from '../../modules/gamesOptions/selectors';
import {
  hideSpinner, showSpinner, startTrialGameFail, startTrialGameSuccess,
} from '../../modules/global/actions';
import { START_TRIAL_GAME } from '../../modules/global/constants';
import {
  changeIsLoading,
  changeIsLoadingCards,
  changeProductPrice,
  changeStavka,
  changeSupergame,
  continueGameFail,
  continueGameSuccess,
  getCardsAll,
  getCardsAllFail,
  getCardsAllSuccess,
  getGameInfo,
  nextStep,
  nextStepSuccess,
  nextStepFail,
  getVarsSuccess,
  getVarsFail,
  uncheckContinueGame,
  getKeysSuccess,
  getKeysFail,
  getTips,
  getTipsSuccess,
  getTipsFail,
  getHighlightTipSuccess,
  getHighlightTipFail,
  getFiftyFiftyTipSuccess,
  getFiftyFiftyTipFail,
  getTipsPriceSuccess,
  getTipsPriceFail,
  buyFiftyFiftyTipSuccess,
  buyFiftyFiftyTipFail,
  buyHighlightTipSuccess,
  buyHighlightTipFail,
  buySaveGameSuccess,
  buySaveGameFail,
  getFinishVarsSuccess,
  getFinishVarsFail,
  getSaveCount,
  getSaveCountSuccess,
  getSaveCountFail,
  setStavkaState,
  getGameInfoSuccess,
  getGameInfoFail,
  setIsFirstGame,
  getSaveTipSuccess,
  getSaveTipFail,
  newGameSuccess,
  newGameFail, setActiveDealStage, setActiveDealStageInfo, setWidgetList,
} from '../../modules/myGame/actions';

import { makeSelectApiUrl } from '../../modules/global/selectors';
import {
  makeSelectSession,
  makeSelectAnswers,
  makeSelectBlockInstanceId,
  makeSelectMethodType,
  makeSelectIsContinueGame,
  makeSelectSendAnswers,
  makeSelectHighlightTip,
  makeSelectFiftyFiftyTip,
  makeSelectSaveTip,
  makeSelectMyMoodAbsoluteValue,
  makeSelectClientMoodAbsoluteValue,
  makeSelectStavka,
  makeSelectSuperGame, makeSelectIsFirstGame, makeSelectWidgetList,
} from '../../modules/myGame/selectors';
import { receiveOwlMessage } from '../../modules/owl/actions';
import { setStartTrialGameWithEmailPopupOpened } from '../../modules/popups/actions';

import { getCashInfo, getProfileInfo } from '../../modules/profile/actions';

import URL_CONSTANTS from '../../../urlConstants';

import {
  NEXT_STEP_REQUEST,
  CHECK_ANSWER,
  GET_VARS_REQUEST,
  GET_KEYS_REQUEST,
  GET_TIPS_REQUEST,
  GET_HIGHLIGHTTIP_REQUEST,
  GET_FIFTY_FIFTY_REQUEST,
  GET_TIPS_PRICE_REQUEST,
  BUY_FIFTY_FIFTY_TIP_REQUEST,
  BUY_HIGHLIGHTTIP_TIP_REQUEST,
  GET_SAVE_COUNT_REQUEST,
  BUY_SAVE_GAME_REQUEST,
  CONTINUE_GAME_REQUEST,
  CONTINUE_GAME_REQUEST_FAIL,
  GAME_INFO_REQUEST,
  GET_CARDS_ALL_REQUEST,
  GET_SAVE_REQUEST,
  NEW_GAME_REQUEST,
  START_NEW_SUPER_GAME,
  NEXT_STEP_REQUEST_SUCCESS,
} from '../../modules/myGame/constants';
import {
  getCommonGameHeadRating,
  getCommonGameRatingFail,
} from '../../modules/rating/actions';
import { setStartedTrialGameSessionId } from '../../modules/trialGame/actions';
import { makeSelectStartedTrialGameSessionId } from '../../modules/trialGame/selectors';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);
  const apiRequestGuest = createApiRequest(cookies, false);

  function* getOpenedCardPercent(gameId) {
    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/CardsGetInfo/${gameId}`;

    const result = yield call(apiRequest, requestURL);
    const cardsInfo = get(result, 'data.result[0]', null);
    const allCardsCount = get(cardsInfo, 'CardCount', 0);
    const openCardsCount = get(cardsInfo, 'CardHas', 0);

    let openedCardPercent = 0;
    if (allCardsCount !== 0) {
      openedCardPercent = Math.ceil((openCardsCount / allCardsCount) * 100 * 100) / 100;
    }

    return openedCardPercent;
  }

  function* getGameData(games, gameId) {
    const url = yield select(makeSelectApiUrl());

    let gamesShowcases = yield select(makeSelectGamesShowcases());

    // TODO: HOTFIX надо переделать чтобы без datapolling работало
    while (!gamesShowcases) {
      yield delay(100);

      gamesShowcases = yield select(makeSelectGamesShowcases());
    }

    const allGames = [...games, ...gamesShowcases];
    const currentGame = find(allGames, { GameID: gameId });

    const requestTimeURL = `${url}/SessionGetGameStatistic/${encodeURIComponent(gameId)}`;
    const requestGameInfoURL = `${url}/GameGetInfo/${encodeURIComponent(gameId)}`;

    const [timeRequest, requestGameInfo] = yield all([
      call(apiRequest, requestTimeURL),
      call(apiRequest, requestGameInfoURL),
    ]);

    const dealMaxSum = get(currentGame, 'OptionsValues.maxDealValue', 0);
    const maxSumma = parseInt(get(currentGame, 'OptionsValues.dealMaxSum', 0), 10);
    const betaAccess = parseInt(get(currentGame, 'OptionsValues.betaAccess', 0), 10) === 1;

    const opened = yield call(getOpenedCardPercent, gameId);

    return {
      id: gameId,
      data: {
        betaAccess,
        dealMaxSum,
        maxSumma,
        cardsOpenedPercent: opened,
        time: get(timeRequest, 'data.result[0].TotalTime', 0),
        canContinue: get(requestGameInfo, 'data.result[0].CanContinue', false),
      },
    };
  }

  function* getGameOptions(gameId) {
    const url = yield select(makeSelectApiUrl());

    try {
      const requestUrl = `${url}/GameOptionValueGetAll/${encodeURIComponent(gameId)}`;
      const request = yield call(apiRequest, requestUrl);

      const optionsArray = get(request, 'data.result[0].GameOptionValueList');

      return {
        id: gameId,
        data: map(optionsArray, option => ({
          name: get(option, 'GameTypeOptionName'),
          value: get(option, 'Value'),
        })),
      };
    } catch (err) {
      return false;
    }
  }

  function* getWidgetDescription(widgetId) {
    const url = yield select(makeSelectApiUrl());

    try {
      const requestUrl = `${url}/GameWidgetGetDescription/${encodeURIComponent(widgetId)}`;
      const request = yield call(apiRequest, requestUrl);

      const description = get(request, 'data.result[0].Description');

      return {
        widgetId,
        description,
      };
    } catch (err) {
      return false;
    }
  }

  function* getGames() {
    const url = yield select(makeSelectApiUrl());
    yield put(getProfileInfo());
    const requestURL = `${url}/GameGetAll/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const { data } = request;

      const isSuccess = get(data, 'result[0].Result', 'fail') === 'success';
      if (!isSuccess) {
        throw new Error('Can`t get game list. Network error.');
      }

      const games = get(data, 'result[0].GameList');

      const gamesOptions = yield all(
        games.map(game => call(getGameOptions, game.GameID)),
      );

      yield put(setGamesOptions(gamesOptions));

      const gamesData = yield all(
        games.map(game => call(getGameData, games, game.GameID)),
      );

      const gamesLicenses = {};

      yield put(getCart());
      yield put(getInvoices());
      yield put(getGameListSuccess(games, gamesData, gamesLicenses, null));
    } catch (err) {
      console.error(err);
      yield put(getGameListFail());
    }
  }

  function* getGamesShowcases() {
    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/GameGetAllFromShowCase/`;

    try {
      const [request] = yield all([
        call(apiRequest, requestURL),
      ]);

      const { data } = request;

      const isSuccess = get(data, 'result[0].Result', 'fail') === 'success';
      if (!isSuccess) {
        throw new Error('Can`t get game showcases. Network error.');
      }

      const games = get(data, 'result[0].GameList');

      // const gamesData = yield all(
      //   games.map(game => call(getGameData, game.GameID)),
      // );

      const gamesOptions = yield all(
        games.map(game => call(getGameOptions, game.GameID)),
      );

      yield put(setGamesOptionsForShowcaseGames(gamesOptions));
      yield put(getGamesShowcasesSuccess(games));
    } catch (err) {
      yield put(getGamesShowcasesFail());
    }
  }

  // Получить корзину
  function* getCartSaga() {
    const url = yield select(makeSelectApiUrl());
    const getCartRequestURL = `${url}/RecycleInvoiceGetForUser/`;

    try {
      const getCartRequestResult = yield call(apiRequest, getCartRequestURL);
      const isSuccess = get(getCartRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while get cart');
      }

      const cart = get(getCartRequestResult, 'data.result[0].Recycle');

      yield put(getCartSuccess(cart));
    } catch (err) {
      yield put(getCartFail());
    }
  }

  // Получить заказы
  function* getInvoicesSaga() {
    const url = yield select(makeSelectApiUrl());
    const getInvoicesRequestURL = `${url}/InvoiceGetAllForUser/`;

    try {
      const getInvoicesRequestResult = yield call(apiRequest, getInvoicesRequestURL);
      const isSuccess = get(getInvoicesRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while get invoices');
      }

      const invoices = get(getInvoicesRequestResult, 'data.result[0].InvoiceList');

      // 2 - заказ обрабатаывается
      const pendingInvoices = filter(invoices, item => item.InvoiceStatus === ORDER_PENDING_STATUS);

      yield put(getInvoicesSuccess(pendingInvoices));
    } catch (err) {
      yield put(getInvoicesFail());
    }
  }

  // Добавить товар в корзину
  function* addShowCaseToCart() {
    const url = yield select(makeSelectApiUrl());
    const showcaseId = yield select(makeSelectAddShowCase());

    const addToCartRequestURL = `${url}/RecycleItemAddForUser/${showcaseId}/`;

    try {
      const addToCartRequestResult = yield call(apiRequest, addToCartRequestURL);
      const isSuccess = get(addToCartRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while add showcase to cart');
      }
      yield put(addShowCaseToCartSuccess());
      yield put(cartCheckout());
    } catch (err) {
      yield put(addShowCaseToCartFail());
    }
  }

  // Купить товар в один клик
  function* buyShowCaseSaga(action) {
    const { payload: { nonFree } } = action;

    const url = yield select(makeSelectApiUrl());
    const showcaseId = yield select(makeSelectBuyShowCaseId());

    const buyRequestURL = `${url}/BuyShowcase/${showcaseId}/`;

    try {
      const buyRequestResult = yield call(apiRequest, buyRequestURL);
      const isSuccess = get(buyRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while add buy showcase');
      }

      if (nonFree) {
        const URLPayment = get(buyRequestResult, 'data.result[0].URLPayment', null);

        window.location.href = URLPayment;
        return;
      }

      yield getInvoicesSaga();
      yield put(buyShowCaseSuccess());
    } catch (err) {
      yield put(buyShowCaseFail());
    }
  }

  // Купить товар в один клик из демо-игры
  function* buyShowCaseFromDemoSaga() {
    const url = yield select(makeSelectApiUrl());
    const showcaseId = yield select(makeSelectBuyShowCaseFromDemoId());

    const buyRequestURL = `${url}/BuyShowcase/${showcaseId}/`;

    try {
      const buyRequestResult = yield call(apiRequest, buyRequestURL);
      const isSuccess = get(buyRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while add buy showcase');
      }

      const URLPayment = get(buyRequestResult, 'data.result[0].URLPayment', null);

      window.location.href = URLPayment;

      yield put(buyShowCaseFromDemoSuccess());
    } catch (err) {
      yield put(buyShowCaseFromDemoFail());
    }
  }

  // Оформить заказ
  function* cartCheckoutSaga() {
    const url = yield select(makeSelectApiUrl());
    const getCartRequestURL = `${url}/InvoiceMakeForUser/`;

    try {
      const getCartRequestResult = yield call(apiRequest, getCartRequestURL);
      const isSuccess = get(getCartRequestResult, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while checkout cart');
      }

      yield getGames();
      yield getGamesShowcases();

      yield put(cartCheckoutSuccess());
    } catch (err) {
      yield put(cartCheckoutFail());
    }
  }

  function* getKeysSaga() {
    const url = yield select(makeSelectApiUrl());

    const gameId = yield select(makeSelectActiveGameId());
    const requestURL = `${url}/QuestKeyInfo/${encodeURIComponent(gameId)}/`;

    try {
      const request = yield call(apiRequest, requestURL);
      const keysCount = get(request, 'data.result[0].KeySession', null);

      yield put(getKeysSuccess({
        keys: keysCount,
      }));
    } catch (err) {
      yield put(getKeysFail());
    }
  }

  function* getTipsSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gameSessionId = yield select(makeSelectSession());

    const requestURL = `${url}/QuestTipsGetValue/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const fiftyFiftyTip = request.data.result[0].VarTipFiftyFiftyValue;
      const highlightTrueTip = request.data.result[0].VarTipHighlightTrueValue;
      yield put(getTipsSuccess({
        fiftyFiftyTip,
        highlightTrueTip,
      }));
    } catch (err) {
      yield put(getTipsFail());
    }
  }

  function* getVarsSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gamesOptions = yield select(makeSelectGamesOptions());
    const gameSessionId = yield select(makeSelectSession());

    const myMoodVarName = getGameOptionValue(OPTION_GAME_MY_MOOD, gamesOptions, gameId);
    const clientMoodVarName = getGameOptionValue(OPTION_GAME_CLIENT_MOOD, gamesOptions, gameId);

    const requestVariablesURL = `${url}/VariableGetAllForGameSession/${encodeURIComponent(gameSessionId)}/`;

    try {
      const [statRequest] = yield all([
        call(apiRequest, requestVariablesURL),
      ]);

      const myMoodValue = parseInt(get(find(get(statRequest, 'data.result[0].VariableList'), { Name: myMoodVarName }, null), 'Value', 0), 10);
      const clientMoodValue = parseInt(get(find(get(statRequest, 'data.result[0].VariableList'), { Name: clientMoodVarName }, null), 'Value', 0), 10);

      let myMood;
      if (myMoodValue < -6) {
        myMood = 1;
      } else if (myMoodValue < 0) {
        myMood = 2;
      } else if (myMoodValue < 3) {
        myMood = 3;
      } else if (myMoodValue < 8) {
        myMood = 4;
      } else {
        myMood = 5;
      }

      let clientMood;
      if (clientMoodValue < 0) {
        clientMood = 4;
      } else if (clientMoodValue < 3) {
        clientMood = 1;
      } else if (clientMoodValue < 8) {
        clientMood = 2;
      } else {
        clientMood = 3;
      }

      const myMoodVarArrowVar = getGameOptionValue(OPTION_GAME_MY_MOOD_ARROW, gamesOptions, gameId);
      const clientMoodVarArrowVar = getGameOptionValue(OPTION_GAME_CLIENT_MOOD_ARROW, gamesOptions, gameId);

      let clientMoodState;
      const currentClientMood = yield select(makeSelectClientMoodAbsoluteValue());
      const clientMoodAbsoluteValue = parseInt(get(find(get(statRequest, 'data.result[0].VariableList'), { Name: clientMoodVarArrowVar }, null), 'Value', 0), 10);

      if (isNull(currentClientMood) || currentClientMood === clientMoodAbsoluteValue) {
        clientMoodState = null;
      } else if (currentClientMood > clientMoodAbsoluteValue) {
        clientMoodState = 'down';
      } else if (currentClientMood < clientMoodAbsoluteValue) {
        clientMoodState = 'up';
      }

      let myMoodState;
      const currentMyMood = yield select(makeSelectMyMoodAbsoluteValue());
      const myMoodAbsoluteValue = parseInt(get(find(get(statRequest, 'data.result[0].VariableList'), { Name: myMoodVarArrowVar }, null), 'Value', 0), 10);

      if (isNull(currentMyMood) || currentMyMood === myMoodAbsoluteValue) {
        myMoodState = null;
      } else if (currentMyMood > myMoodAbsoluteValue) {
        myMoodState = 'down';
      } else if (currentMyMood < myMoodAbsoluteValue) {
        myMoodState = 'up';
      }

      const dealCorridorFromVar = getGameOptionValue(OPTION_GAME_DEAL_CORRIDOR_FROM, gamesOptions, gameId);
      const dealCorridorToVar = getGameOptionValue(OPTION_GAME_DEAL_CORRIDOR_TO, gamesOptions, gameId);

      const contractFromRaw = get(find(get(statRequest, 'data.result[0].VariableList'), { Name: dealCorridorFromVar }, null), 'Value', null);
      const contractToRaw = get(find(get(statRequest, 'data.result[0].VariableList'), { Name: dealCorridorToVar }, null), 'Value', null);

      const contractFrom = contractFromRaw && parseInt(contractFromRaw, 10);
      const contractTo = contractToRaw && parseInt(contractToRaw, 10);

      const variables = {
        myMood,
        clientMood,
        contractFrom,
        contractTo,
      };

      const summaVar = getGameOptionValue(OPTION_GAME_SUM, gamesOptions, gameId);

      const result = {
        variables,
        myMoodAbsoluteValue,
        clientMoodAbsoluteValue,
        myMoodDirection: myMoodState,
        clientMoodDirection: clientMoodState,
        summa: get(find(get(statRequest, 'data.result[0].VariableList'), { Name: summaVar }, null), 'Value', 0),
        myMoodValue,
        clientMoodValue,
      };

      yield put(getVarsSuccess(result));

      const boosterBetVar = getGameOptionValue(OPTION_GAME_BOOSTER_BET, gamesOptions, gameId);

      const stavka = parseInt(get(find(get(statRequest, 'data.result[0].VariableList'), { Name: boosterBetVar }, null), 'Value', 0), 10);

      yield put(setStavkaState({ stavka }));
    } catch (err) {
      yield put(getVarsFail());
    }
  }

  function* checkAnswersSaga() {
    const sendAnswers = yield select(makeSelectSendAnswers());

    if (sendAnswers === true) {
      yield put(nextStep());
    }
  }

  // function* getFinishVarsSaga() {
  //   const url = yield select(makeSelectApiUrl());
  //   const gameSessionId = yield select(makeSelectSession());
  //
  //   const requestReceivedKeysURL = `${url}/CardKeySessionGet/${encodeURIComponent(gameSessionId)}`;
  //
  //   try {
  //     const [requestReceivedKeys] = yield all([
  //       call(apiRequest, requestReceivedKeysURL),
  //     ]);
  //
  //     const userKeySession = requestReceivedKeys.data.result[0].UserKeySession;
  //     const cardList = requestReceivedKeys.data.result[0].CardList;
  //
  //     yield put(getReceivedKeysRequestSuccess({ userKeySession, cardList }));
  //   } catch (e) {
  //     yield put(getFinishVarsFail());
  //   }
  // }

  function* getHighlightTipSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gameSessionId = yield select(makeSelectSession());
    const answers = yield select(makeSelectAnswers());

    const requestURL = `${url}/QuestTipHighlightTrue/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;

    try {
      const request = yield call(apiRequest, requestURL);
      const checkedAnswers = request.data.result[0].Answers.map(answer => answer.AnswerID);

      const newAnswers = sortBy(answers.map(
        answer => ({
          id: answer.id,
          text: answer.text,
          tipHighlight: checkedAnswers.indexOf(answer.id) === -1 ? 'wrong' : 'correct',
        }),
      ), 'tipHighlight');

      yield put(getHighlightTipSuccess({
        answers: newAnswers,
      }));
      yield put(getTips());
    } catch (err) {
      yield put(getHighlightTipFail());
    }
  }

  function* getFiftyFiftyTipSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gameSessionId = yield select(makeSelectSession());
    const answers = yield select(makeSelectAnswers());

    const requestURL = `${url}/QuestTipFiftyFifty/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const requestData = request.data.result[0];
      const FiftyFiftyAnswers = Object.keys(requestData)
        .map(answerID => requestData[answerID])
        .filter(answer => answer !== 'success');

      const newAnswers = sortBy(answers.map((item) => {
        const isFiftyFiftyAnswer = FiftyFiftyAnswers.indexOf(item.id) !== -1;

        return {
          ...item,
          tipFiftyFifty: isFiftyFiftyAnswer ? 'correct' : 'wrong',
        };
      }), 'tipFiftyFifty');

      yield put(getFiftyFiftyTipSuccess({
        answers: newAnswers,
      }));
      yield put(getTips());
    } catch (err) {
      yield put(getFiftyFiftyTipFail());
    }
  }

  function* getSaveSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gameSessionId = yield select(makeSelectSession());
    const requestURL = `${url}/QuestSave/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;

    try {
      yield call(apiRequest, requestURL);

      yield put(getSaveTipSuccess());
      yield put(getSaveCount());
    } catch (err) {
      yield put(getSaveTipFail());
    }
  }

  function* getTipsPriceSaga() {
    const url = yield select(makeSelectApiUrl());
    const activeGameId = yield select(makeSelectActiveGameId());

    try {
      const [tip1, tip2, tip3] = yield all([
        call(apiRequest, `${url}/ProductPriceGetAllForTipFiftyFifty/${activeGameId}`),
        call(apiRequest, `${url}/ProductPriceGetAllForHighlightTrue/${activeGameId}`),
        call(apiRequest, `${url}/ProductPriceGetAllForSaveGame/${activeGameId}`),
      ]);

      // TODO: FIXME берется первый элемент массива, это не правильно.
      const fiftyFiftyTip = {
        id: tip1.data.result[0].ProductPriceList[0].ProductPriceID,
        price: tip1.data.result[0].ProductPriceList[0].Price,
      };

      const highlightTip = {
        id: tip2.data.result[0].ProductPriceList[0].ProductPriceID,
        price: tip2.data.result[0].ProductPriceList[0].Price,
      };

      const saveTip = {
        id: tip3.data.result[0].ProductPriceList[0].ProductPriceID,
        price: tip3.data.result[0].ProductPriceList[0].Price,
      };

      yield put(getTipsPriceSuccess({
        fiftyFiftyTip,
        highlightTip,
        saveTip,
      }));
    } catch (err) {
      yield put(getTipsPriceFail());
    }
  }

  function* buyFiftyFiftyTipSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gameSessionId = yield select(makeSelectSession());
    const fiftyFiftyTip = yield select(makeSelectFiftyFiftyTip());

    try {
      const requestURL = `${url}/BuyTipFiftyFifty/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/${encodeURIComponent(fiftyFiftyTip.countForBuy)}/${encodeURIComponent(fiftyFiftyTip.id)}`;
      const request = yield call(apiRequest, requestURL);

      if (request.data.result[0].Result === 'fail') {
        yield put(buyFiftyFiftyTipFail());
      } else {
        yield put(buyFiftyFiftyTipSuccess());
        yield put(getTips());
        yield put(getCashInfo());
      }
    } catch (err) {
      yield put(buyFiftyFiftyTipFail());
    }
  }

  function* buyHighlightTipSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gameSessionId = yield select(makeSelectSession());
    const highlightTip = yield select(makeSelectHighlightTip());

    try {
      const requestURL = `${url}/BuyTipHighlightTrue/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/${encodeURIComponent(highlightTip.countForBuy)}/${encodeURIComponent(highlightTip.id)}`;
      const request = yield call(apiRequest, requestURL);

      if (request.data.result[0].Result === 'fail') {
        yield put(buyFiftyFiftyTipFail());
      } else {
        yield put(buyHighlightTipSuccess());
        yield put(getTips());
        yield put(getCashInfo());
      }
    } catch (err) {
      yield put(buyHighlightTipFail());
    }
  }

  function* buySaveSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const saveTip = yield select(makeSelectSaveTip());
    const gameSessionId = yield select(makeSelectGameSessionId());

    try {
      const requestURL = `${url}/BuySaveGame/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/${encodeURIComponent(saveTip.countForBuy)}/${encodeURIComponent(saveTip.id)}`;
      const request = yield call(apiRequest, requestURL);

      if (request.data.result[0].Result === 'fail') {
        yield put(buySaveGameFail());
      } else {
        yield put(buySaveGameSuccess());
        yield put(getSaveCount());
        yield put(getCashInfo());
      }
    } catch (err) {
      yield put(buyFiftyFiftyTipFail());
    }
  }

  function* getSaveCountSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());

    const requestURL = `${url}/SaveGetCount/${encodeURIComponent(gameId)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const saveCount = request.data.result[0].Value;

      yield put(getSaveCountSuccess({
        saveCount,
      }));
    } catch (err) {
      yield put(getSaveCountFail());
    }
  }

  function* continueGameSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const gamesOptions = yield select(makeSelectGamesOptions());
    const requestURL = `${url}/GameContinue/${encodeURIComponent(gameId)}`;

    try {
      yield put(showLoading());
      const request = yield call(apiRequest, requestURL);
      const isSuccess = get(request, 'data.result[0].Result', null) === 'success';

      if (!isSuccess) {
        yield put(continueGameFail());
        yield put(hideSpinner());
        return;
      }

      const stage = parseInt(get(request, 'data.result[0].StageValue', 0), 10);

      const widgetList = get(request, 'data.result[0].WidgetList');
      const widgetDescriptions = yield all(
        map(widgetList, (widget => call(getWidgetDescription, widget.GameWidgetID))),
      );
      yield put(setWidgetList(widgetList, widgetDescriptions));

      let stagesInfo = get(request, 'data.result[0].StageList');

      yield put(setActiveDealStage(stage));

      if (stagesInfo) {
        stagesInfo = sortBy(map(stagesInfo, (item, key) => ({
          ...item,
          key: key + 1,
          order: stagesInfo.length - key,
        })), 'order');
      }
      yield put(setActiveDealStageInfo(stagesInfo));

      const sessionId = request.data.result[0].SessionID;
      const getStepRequest = yield call(apiRequest, `${url}/VariableGetAllForGameSession/${encodeURIComponent(sessionId)}/`);

      const isSuperGameVar = getGameOptionValue(OPTION_GAME_SUPER_GAME, gamesOptions, gameId);

      const isSuperGame = parseInt(get(
        find(
          get(getStepRequest, 'data.result[0].VariableList'),
          { Name: isSuperGameVar },
        ), 'Value', 0,
      ), 10) === 1;

      yield put(getGameInfo());
      yield put(changeSupergame({
        superGame: isSuperGame,
      }));
      yield put(getCardsAll());
      yield put(continueGameSuccess(sessionId));

      const stavkaVar = getGameOptionValue(OPTION_GAME_BOOSTER_BET, gamesOptions, gameId);

      const stavka = parseInt(get(
        find(
          get(getStepRequest, 'data.result[0].VariableList'),
          { Name: stavkaVar },
        ), 'Value', 0,
      ), 10);

      yield put(changeStavka({ stavka }));
      yield put(nextStep());
    } catch (err) {
      yield put(continueGameFail());
      yield put(hideSpinner());
    } finally {
      yield put(hideLoading());
      yield put(hideSpinner());
    }
  }

  function* getAllCardsSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const requestURL = `${url}/CardGetAllByGame/${encodeURIComponent(gameId)}/true`;

    try {
      const request = yield call(apiRequest, requestURL);
      const cardsAll = get(request, 'data.result[0].GroupCardList', null);
      let allCardsCount = 0;
      let openCardsCount = 0;

      const mappedCards = cardsAll.map((cardGroup) => {
        const currentGroupOpenCount = cardGroup.CardList.filter(book => (book.KeyHas >= book.KeyCount)).length;
        allCardsCount += cardGroup.CardList.length;
        openCardsCount += currentGroupOpenCount;

        const tags = cardGroup.TagList;
        const isLifeHack = indexOf(tags, 'lifehack') !== -1;

        const imageRegex = /<img [a-z=":/.?0-9_ %]*>/ig;
        let cardList = cardGroup.CardList.map((cardItem) => {
          let data = cardItem.Data;
          if (imageRegex.test(data)) {
            data.match(imageRegex).map((image) => {
              const newImage = image.replace('src="', `src="${process.env.REACT_APP_API_URL}/`);
              data = data.replace(image, newImage);

              return newImage;
            });
          }

          let { Title2 } = cardItem;
          if (isLifeHack) {
            Title2 = parseInt(Title2, 10);
          }

          return {
            Question: data,
            ...cardItem,
            Title2,
          };
        });

        // Сортируем все карточки чтобы они у нас были в том порядке в котором указано на сервере.
        cardList = sortBy(cardList, 'RecordOrder');

        return {
          AllCount: cardGroup.CardList.length,
          OpenCount: currentGroupOpenCount,
          CardList: cardList,
          GroupCardID: cardGroup.GroupCardID,
          Name: cardGroup.Name,
          Title1: cardGroup.Title1,
          Title2: cardGroup.Title2,
          isLifeHack,
        };
      });

      const opened = allCardsCount !== 0 ? ((openCardsCount / allCardsCount) * 100).toFixed(2) : 0;

      yield put(getCardsAllSuccess({
        cards: mappedCards,
        allCardsCount,
        openCardsCount,
        opened,
      }));
    } catch (err) {
      console.error(err);
      yield put(getCardsAllFail());
    } finally {
      yield put(changeIsLoading(false));
      yield put(changeIsLoadingCards(false));
    }
  }

  function* getGameInfoSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const requestURL = `${url}/GameGetInfo/${encodeURIComponent(gameId)}`;

    const requestPriceURL = `${url}/ProductPriceGetAllForCardKey/${gameId}`;

    try {
      const [request, priceRequest] = yield all([
        call(apiRequest, requestURL),
        call(apiRequest, requestPriceURL),
      ]);

      const optionsValues = get(request, 'data.result[0].OptionsValues', null);

      yield put(getCommonGameHeadRating({ gameId }));

      yield getGames();
      yield getGamesShowcases();

      const isFirstGame = parseInt(get(optionsValues, 'isFirstGame', 1), 10) === 1;

      yield put(setIsFirstGame(isFirstGame));

      let lastSave = {
        id: null,
        name: null,
      };

      const sessionItemList = get(request, 'data.result[0].SessionItemList', null);

      if (!isEmpty(sessionItemList)) {
        const sessionItemListMapped = map(sessionItemList, (item) => {
          const d = item.Name.replace('[', '').replace(']', '').trim();
          const tmp = d.split(' ');
          const date = tmp[0];
          let time = tmp[1];
          if (time.length === 7) {
            time = `0${time}`;
          }

          const dateObj = dateAndTime.parse(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss');

          let timestamp = 0;
          if (dateObj instanceof Date && !isNaN(d)) {
            timestamp = dateObj.getTime();
          }
          return ({
            timestamp,
            id: item.SessionItemID,
            name: item.Name,
          });
        });

        lastSave = last(sortBy(sessionItemListMapped, 'timestamp'));
      }

      const gameProgressList = get(request, 'data.result[0].GameProgressList');
      const currentGameProgress = get(request, 'data.result[0].GameProgressCurrentStep');

      const isAvailableSuperGame = parseInt(get(optionsValues, 'isSuperGameAvailable', 0), 10) === 1;
      const canContinue = get(request, 'data.result[0].CanContinue', false);

      const result = {
        currentGameProgress,
        gameProgressList,
        canContinue,
        lastSave,
        isAvailableSuperGame,
      };

      const prices = get(priceRequest, 'data.result[0].ProductPriceList');
      const priceInVirtualCurrency = find(prices, { CurrencyIsVirtual: true });

      const price = {
        id: get(priceInVirtualCurrency, 'ProductPriceID', undefined),
        price: get(priceInVirtualCurrency, 'Price', undefined),
      };

      yield put(changeProductPrice(price));
      yield put(getGameList());
      yield put(getGameInfoSuccess(result));
      yield put(getCardsAll());
    } catch (err) {
      console.log('error', err);
      yield put(getCommonGameRatingFail({ gameId }));
      yield put(getGameInfoFail());
    }
  }

  function* cantContinueGameSaga() {
    yield put(push(URL_CONSTANTS.URLS.GAMES_STORE));
  }

  function* getNextStepSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const answers = yield select(makeSelectAnswers());
    const blockInstanceId = yield select(makeSelectBlockInstanceId());
    const methodType = yield select(makeSelectMethodType());
    const gameSessionId = yield select(makeSelectSession());
    const isContinueGame = yield select(makeSelectIsContinueGame());
    let requestURL = null;
    if (gameId && gameSessionId) {
      requestURL = `${url}/QuestNextStep/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}//`;
    }

    // Если выбран вариант ответа то отсылаем его.
    if (answers !== null && methodType !== null && blockInstanceId !== null) {
      let requestData = {
        BlockInstanceID: blockInstanceId,
        MethodType: methodType,
        Answers: answers.map(answer => (
          {
            AnswerID: answer.id,
            Checked: answer.additional,
            Text: escape(replace(answer.text, new RegExp('/', 'g'), '')),
          }
        )),
      };

      if (methodType === 'Two' || methodType === 'TwoEx') {
        requestData = {
          BlockInstanceID: blockInstanceId,
          MethodType: methodType,
          Answers: answers.map(answer => (
            {
              AnswerID: answer.id,
              Value: answer.additional,
              // TODO FIX ME https://readom.atlassian.net/browse/READOM-659
              // Text: escape(replace(answer.text, new RegExp('/', 'g'), '')),
            }
          )),
        };
      }

      requestURL = `${url}/QuestNextStep/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/${encodeURIComponent(JSON.stringify(requestData))}/`;
    }

    if (isContinueGame === true) {
      if (gameId && gameSessionId) {
        const requestNextStepOrReloadURL = `${url}/QuestCheckPrevStep/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;
        const requestNextStepOrReloadResult = yield call(apiRequest, requestNextStepOrReloadURL);
        const canReloadStep = get(requestNextStepOrReloadResult, 'data.result[0].ResultQuestCheckPrevStep') === true;

        if (canReloadStep) {
          requestURL = `${url}/QuestRepeatLastStep/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;
        } else {
          requestURL = `${url}/QuestNextStep/${encodeURIComponent(gameId)}/${encodeURIComponent(gameSessionId)}/`;
        }

        yield put(uncheckContinueGame());
      }
    }

    try {
      if (!requestURL) {
        return;
      }

      const request = yield call(apiRequest, requestURL);

      const isFinish = get(request, 'data.result[0].IsFinish', null);

      if (isFinish) {
        let games = yield select(makeSelectGames());
        let gamesShowcases = yield select(makeSelectGamesShowcases());

        // TODO: HOTFIX надо переделать чтобы без datapolling работало
        while (!games || !gamesShowcases) {
          yield delay(100);

          games = yield select(makeSelectGames());
          gamesShowcases = yield select(makeSelectGamesShowcases());
        }

        const allGames = [...games, ...gamesShowcases];
        const currentGameInfo = find(allGames, { GameID: gameId }, false);
        const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
          && !get(currentGameInfo, 'HasPermission', false);

        if (isTrialGame) {
          yield put(push(URL_CONSTANTS.URLS.DEMO_FINISH));
        } else {
          yield put(push(`${URL_CONSTANTS.URLS.GAME_FINISH}?gameId=${gameId}`));
        }
      } else {
        const requestData = parseQuestNextStep({ request });

        const stage = parseInt(get(request, 'data.result[0].StageValue', 0), 10);

        const widgetList = get(request, 'data.result[0].WidgetList');

        const prevWidgetList = yield select(makeSelectWidgetList());

        const widgetDescriptions = yield all(
          map(widgetList, (widget => call(getWidgetDescription, widget.GameWidgetID))),
        );

        const preparedWidgetList = map(widgetList, item => {
          const prev = find(prevWidgetList, { GameWidgetID: item.GameWidgetID });

          const prevTrend = prev && prev.TrendValue ? parseInt(prev.TrendValue, 10) : 0;

          if (prev && (prevTrend || prevTrend === 0)) {
            let state = null;

            if (prevTrend > item.TrendValue) {
              state = 'down';
            } else if (prevTrend < item.TrendValue) {
              state = 'up';
            }

            return {
              state,
              ...item,
            };
          }

          return item;
        });

        yield put(setWidgetList(preparedWidgetList, widgetDescriptions));

        yield put(setActiveDealStage(stage));

        yield getKeysSaga();
        yield getVarsSaga();
        yield getTipsSaga();
        yield getTipsPriceSaga();
        yield getSaveCountSaga();
        yield put(getCashInfo());
        yield put(nextStepSuccess(requestData));
      }
    } catch (err) {
      console.error(err);
      yield put(nextStepFail());
    }
  }

  function* startNewGameSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const requestURL = `${url}/GameStart/${encodeURIComponent(gameId)}`;

    try {
      yield put(showLoading());
      yield put(showSpinner());
      const startedTrialGameSessionId = yield select(makeSelectStartedTrialGameSessionId());
      yield put(setStartedTrialGameSessionId(null));

      let sessionId;
      if (startedTrialGameSessionId) {
        sessionId = startedTrialGameSessionId;
      } else {
        const request = yield call(apiRequest, requestURL);
        sessionId = request.data.result[0].SessionID;

        const stage = parseInt(get(request, 'data.result[0].StageValue', 0), 10);
        let stagesInfo = get(request, 'data.result[0].StageList');

        const widgetList = get(request, 'data.result[0].WidgetList');
        const widgetDescriptions = yield all(
          map(widgetList, (widget => call(getWidgetDescription, widget.GameWidgetID))),
        );
        yield put(setWidgetList(widgetList, widgetDescriptions));

        yield put(setActiveDealStage(stage));

        if (stagesInfo) {
          stagesInfo = sortBy(map(stagesInfo, (item, key) => ({
            ...item,
            key: key + 1,
            order: stagesInfo.length - key,
          })), 'order');
        }
        yield put(setActiveDealStageInfo(stagesInfo));
      }

      let gamesOptions = null;
      while (!gamesOptions) {
        gamesOptions = yield select(makeSelectGamesOptions());
      }

      const boosterStavkaVar = getGameOptionValue(OPTION_GAME_BOOSTER_BET, gamesOptions, gameId);
      const superGameVar = getGameOptionValue(OPTION_GAME_SUPER_GAME, gamesOptions, gameId);

      let games = yield select(makeSelectGames());
      let gamesShowcases = yield select(makeSelectGamesShowcases());

      // TODO: HOTFIX надо переделать чтобы без datapolling работало
      while (!games || !gamesShowcases) {
        yield delay(100);

        games = yield select(makeSelectGames());
        gamesShowcases = yield select(makeSelectGamesShowcases());
      }

      const allGames = [...games, ...gamesShowcases];

      const activeGameId = yield select(makeSelectActiveGameId());
      const currentGameInfo = find(allGames, { GameID: activeGameId }, false);

      const stavkaVal = yield select(makeSelectStavka());
      yield call(apiRequest, `${url}/VariableValueSetForGameSessionUser/${boosterStavkaVar}/${encodeURIComponent(gameId)}/${encodeURIComponent(sessionId)}/${encodeURIComponent(stavkaVal)}/`);

      const superGame = yield select(makeSelectSuperGame());
      if (superGame.isEnabled) {
        yield call(apiRequest, `${url}/VariableValueSetForGameSessionUser/${superGameVar}/${encodeURIComponent(gameId)}/${encodeURIComponent(sessionId)}/1/`);
      }

      yield put(newGameSuccess(sessionId));

      const gameShortName = get(currentGameInfo, 'NameSys');
      const playUrl = `${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/${gameShortName}`;
      const path = `${playUrl}?game_session_id=${sessionId}`;
      yield put(push(path));
      const isFG = yield select(makeSelectIsFirstGame());
      if (isFG) {
        yield put(startTrialGameSuccess(activeGameId));
      }

      yield put(getProfileInfo());
    } catch (err) {
      yield put(newGameFail());
      yield put(hideSpinner());
    } finally {
      yield put(hideLoading());
      yield put(hideSpinner());
    }
  }

  function* startTrialGameSaga(action) {
    const {
      payload: {
        email, gameSysName, name, phone, formik,
      },
    } = action;

    let setFieldError = null;
    let setStatus = null;
    let setSubmitting = null;

    if (formik) {
      setFieldError = formik.setFieldError;
      setStatus = formik.setStatus;
      setSubmitting = formik.setSubmitting;
    }

    let nameToRequest = '';
    if (isString(name)) {
      nameToRequest = name.trim();
    }

    let emailToRequest = '';
    if (isString(email)) {
      emailToRequest = email.trim();
    }

    const isTrialGameStarted = true;
    const url = yield select(makeSelectApiUrl());
    const surname = '';
    const middleName = '';

    let phoneToRequest = '';
    if (isString(phone)) {
      phoneToRequest = phone.trim();
    }

    const requestURL = `${url}/GameDemoStart/${encodeURIComponent(gameSysName)}/${encodeURIComponent(surname)}/${encodeURIComponent(nameToRequest)}/${encodeURIComponent(middleName)}/${encodeURIComponent(emailToRequest)}/${encodeURIComponent(phoneToRequest)}`;

    try {
      const request = yield call(apiRequestGuest, requestURL);

      const isSuccess = get(request, 'data.result[0].Result', null) === 'success';

      let startedTrialGameSessionId = null;

      if (isSuccess) {
        const sessionID = request.headers.pragma;
        const gameId = get(request, 'data.result[0].GameID', null);
        const maxAge = 60 * 60 * 24 * 365;

        cookies.set('demoGameId', gameId, { maxAge, path: '/' });

        if (isTrialGameStarted) {
          startedTrialGameSessionId = get(request, 'data.result[0].SessionID', null);
          yield put(setStartedTrialGameSessionId(startedTrialGameSessionId));
        }

        let sessIndx = sessionID.indexOf('dssession=');
        if (sessIndx > -1) {
          let commaIndx = sessionID.indexOf(',', sessIndx);
          commaIndx = commaIndx < 0 ? sessionID.length : commaIndx;
          sessIndx += 10;
          const sessionId = sessionID.substr(sessIndx, (commaIndx - sessIndx));

          let expiresIndx = sessionID.indexOf('dssessionexpires=');
          if (expiresIndx > -1) {
            commaIndx = sessionID.indexOf(',', expiresIndx);
            commaIndx = commaIndx < 0 ? sessionID.length : commaIndx;
            expiresIndx += 17;

            const cookieMaxAge = (parseInt(sessionID.substr(expiresIndx, (commaIndx - expiresIndx)), 10) / 1000);

            cookies.set('sessionID', sessionId, { path: '/' });
            cookies.set('sessionExpires', cookieMaxAge, { path: '/' });

            cookies.remove('guestSessionID', { path: '/' });
            cookies.remove('guestSessionExpires', { path: '/' });
          }
        }

        // Используется совой
        localStorage.setItem('DAdminSessionId', request.data.result[0].ServerSessionID);

        const demoGameId = cookies.get('demoGameId');

        if (demoGameId === '{FD821E62-DFFB-E545-8A40-FC75527465C4}') {
          // Добавляем сообщение совы
          yield put(receiveOwlMessage(`
          <STEPGROUP>
            <STEPGROUPNUMBER>start-million-trial</STEPGROUPNUMBER>
            <STEP>
              <TEXT>
                  Приветствую тебя!<br/>
                  Здорово, что ты&nbsp;открываешь для себя новое.<br/>
                  &laquo;Миллионы&nbsp;в&nbsp;твоём&nbsp;кармане&raquo;&nbsp;&mdash; это профессиональный инструмент, где ты&nbsp;сможешь пройти полный цикл сделки: от&nbsp;первой встречи до&nbsp;подписания крупного контракта.
                  А&nbsp;сейчас предлагаю познакомиться со&nbsp;всеми его возможностями.
              </TEXT>
              <BUTTON>Полетели</BUTTON>
            </STEP>
          </STEPGROUP>
        `));
        } else {
          yield put(receiveOwlMessage(`
          <STEPGROUP>
            <STEPGROUPNUMBER>start-million-trial</STEPGROUPNUMBER>
            <STEP>
              <TEXT>
                Приветствую тебя!<br/>
                Здорово, что ты&nbsp;открываешь для себя новое.<br/>
                Тренажеры Readom&nbsp;&mdash; это профессиональные инструменты, где ты&nbsp;сможешь освоить новые методики.<br/>
                А&nbsp;сейчас предлагаю познакомиться со&nbsp;всеми его возможностями.
              </TEXT>
              <BUTTON>Полетели</BUTTON>
            </STEP>
          </STEPGROUP>
        `));
        }

        yield put(setActiveGameId(gameId));
        if (formik) {
          if (setFieldError && setStatus && setSubmitting) {
            yield put(setStartTrialGameWithEmailPopupOpened(false));
          }
        }
        yield put(push(URL_CONSTANTS.URLS.GAMES_STORE));
        yield call(getAllCardsSaga);
      } else if (formik) {
        // Значит используем formik
        if (setFieldError && setStatus && setSubmitting) {
          const serverErrorMessage = get(request, 'data.result[0].Message', null);
          if (serverErrorMessage === 'Указанный почтовый адрес уже зарегистрирован у пользователя.') {
            setFieldError('email', 'Уже есть пользователь с таким e-mail');
          } else {
            setStatus({ serverErrorMessage: 'Демо версия временно недоступна. Повторите попытку позже.' });
          }
          setSubmitting(false);
          yield put(startTrialGameFail());
        } else {
          yield put(startTrialGameFail());
          // const errorMessage = get(request, 'data.result[0].Message', null);
          // if (errorMessage === 'Указанный почтовый адрес уже зарегистрирован у пользователя.') {
          yield put(push(URL_CONSTANTS.URLS.INDEX_PAGE));
          // }
        }
      }
    } catch (e) {
      yield put(startTrialGameFail());
      yield put(push(URL_CONSTANTS.URLS.LOGIN));
    }
  }

  function* startNewSuperGame() {
    yield put(changeSupergame({
      superGame: true,
    }));

    yield startNewGameSaga();
  }

  function* gameSaga() {
    yield takeLeading(CONTINUE_GAME_REQUEST, continueGameSaga);
    yield takeLeading(CONTINUE_GAME_REQUEST_FAIL, cantContinueGameSaga);
    yield takeLeading(START_NEW_SUPER_GAME, startNewSuperGame);
    yield takeLeading(NEXT_STEP_REQUEST, getNextStepSaga);
    yield takeLeading(CHECK_ANSWER, checkAnswersSaga);
    yield takeLeading(GET_VARS_REQUEST, getVarsSaga);
    yield takeLeading(GET_KEYS_REQUEST, getKeysSaga);
    yield takeLeading(GET_TIPS_REQUEST, getTipsSaga);
    yield takeLeading(GET_HIGHLIGHTTIP_REQUEST, getHighlightTipSaga);
    yield takeLeading(GET_FIFTY_FIFTY_REQUEST, getFiftyFiftyTipSaga);
    yield takeLeading(GET_SAVE_REQUEST, getSaveSaga);
    yield takeLeading(GET_TIPS_PRICE_REQUEST, getTipsPriceSaga);
    yield takeLeading(BUY_FIFTY_FIFTY_TIP_REQUEST, buyFiftyFiftyTipSaga);
    yield takeLeading(BUY_HIGHLIGHTTIP_TIP_REQUEST, buyHighlightTipSaga);
    yield takeLeading(BUY_SAVE_GAME_REQUEST, buySaveSaga);
    yield takeLeading(GET_SAVE_COUNT_REQUEST, getSaveCountSaga);
    yield takeLatest(GAME_INFO_REQUEST, getGameInfoSaga);
    yield takeLeading(GET_CARDS_ALL_REQUEST, getAllCardsSaga);
    yield takeLeading(START_TRIAL_GAME, startTrialGameSaga);
    yield takeLeading(NEW_GAME_REQUEST, startNewGameSaga);

    yield takeLeading(NEXT_STEP_REQUEST_SUCCESS, getTipsSaga);

    yield takeLeading(GET_ALL_GAMES_LIST, getGames);
    yield takeLeading(GET_CART, getCartSaga);
    yield takeLeading(GET_INVOICES, getInvoicesSaga);
    yield takeLeading(CART_CHECKOUT, cartCheckoutSaga);
    yield takeLeading(CART_CHECKOUT_SUCCESS, getGames);
    yield takeLeading(CART_CHECKOUT_SUCCESS, getCartSaga);
    yield takeLeading(ADD_SHOWCASE_TO_CART_SUCCESS, getCartSaga);
    yield takeLeading(ADD_SHOWCASE_TO_CART, addShowCaseToCart);
    yield takeLeading(BUY_SHOWCASE, buyShowCaseSaga);
    yield takeLeading(BUY_SHOWCASE_FROM_DEMO, buyShowCaseFromDemoSaga);
    yield takeLeading(GET_GAMES_SHOWCASE, getGamesShowcases);
  }

  return gameSaga;
}

export default build;
