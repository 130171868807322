import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import NormalText from '../../../../components/Text';
import SmallPopup from '../../../../components/SmallPopup';

const PopupText = styled(NormalText)`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

class BuyGamePopup extends React.PureComponent {
  onSubmit = () => {
    const { showCase } = this.props;
    const showcaseId = get(showCase, 'ShowcaseID');
    this.props.onSubmit(showcaseId);
  };

  render() {
    const { showCase } = this.props;
    const price = get(showCase, 'Price');
    const currencyNameShort = get(showCase, 'CurrencyNameShort');
    const gameName = get(showCase, 'LicGameGameName');

    const submitText = 'Оформить заявку и перейти к оплате';

    return (
      <SmallPopup
        small={this.props.small}
        title={`Покупка игры ${gameName}`}
        cancelText="Отмена"
        pending={this.props.pending}
        submitText={submitText}
        isActive={this.props.open}
        onClose={this.props.onClose}
        onSubmit={this.onSubmit}
      >
        <PopupText>
          <div>
            <b>Стоимость игры</b>
            {price && `: ${price.toLocaleString()} ${currencyNameShort}`}
            <br />
            <br />
            После оформления заявки на покупку вы будете перенаправлены на страницу оплаты игры.
          </div>
        </PopupText>
      </SmallPopup>
    );
  }
}

BuyGamePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  showCase: PropTypes.object,
  small: PropTypes.bool.isRequired,
};

BuyGamePopup.defaultProps = {
  showCase: null,
};

export default BuyGamePopup;
