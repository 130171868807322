import posed from 'react-pose';
import styled from 'styled-components';
import pure from 'recompose/pure';
import ArrowUp from '../../../assets/statusImages/arrow.svg';
import ArrowDown from '../../../assets/statusImages/arrowDown.svg';

const Wrapper = pure(styled.div`
  position: relative;
  margin-left: 15px;
  ${props => props.small && 'margin-left: 0;'}
`);

const MoodAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 600 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 100 },
  },
});

const Mood = pure(styled(MoodAnimation)`
  background-size: contain;
  cursor: pointer;
  height: 44px;
  width: 44px;
  ${props => props.width && `
    width: ${props.width}px;
  `}
  margin-right: 15px;
  background-image: url(${props => props.backgroundImage});
  ${props => props.small && `
    height: 32px;
    margin-right: 10px;
    width: 32px;
  `}
  
  ${props => props.small && props.clientMoodStatus === 4 && `
    height: 42px;
    width: 42px;
    position: relative;
    top: 3px;
  `}
  
  ${props => !props.small && props.clientMoodStatus === 4 && `
    width: 52px;
    height: 52px;
    position: relative;
    top: 4px;
  `}
`);

const TooltipAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Tooltip = styled(TooltipAnimation)`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: -48px;
  top: 61px;
  line-height: 15px;
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  width: 140px;

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: -12px;
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: -13px;
  }
`;

const sizeAnimation = {
  transition: {
    // duration: 600,
    scale: {
      type: 'keyframes',
      values: [1, 2, 1],
      times: [0, 0.5, 1],
      duration: 1200,
    },
    // x: {
    //   type: 'keyframes',
    //   values: [-20, -20, 0],
    //   times: [0, 0.9, 1],
    //   duration: 600,
    // },
  },
};

const ArrowUpAnimation = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
    ...sizeAnimation,
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: { duration: 100 },
  },
});

const ArrowDownAnimation = posed.div({
  enter: {
    opacity: 1,
    y: 20,
    x: 0,
    rotate: 180,
    scale: 1,
    ...sizeAnimation,
  },
  exit: {
    opacity: 0,
    y: 0,
    rotate: 180,
    transition: { duration: 100 },
  },
});

const Up = pure(styled(ArrowUpAnimation)`
  background-image: url(${ArrowUp});
  display: block;
  height: 11px;
  position: absolute;
  width: 10px;
  right: 3px;
  top: -4px;
`);

const Down = pure(styled(ArrowDownAnimation)`
  background-image: url(${ArrowDown});
  display: block;
  height: 11px;
  position: absolute;
  width: 10px;
  right: 3px;
  top: 11px;
  ${props => props.small && `
    top: 2px;
  `}
`);

export {
  Wrapper,
  Mood,
  Up,
  Down,
  Tooltip,
};
