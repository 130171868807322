import styled from 'styled-components';
import NormalContent from '../../../../../components/Content/index';

const Content = styled(NormalContent)`
  background-color: #F7F7F7;
  padding: ${props => (props.small && '0')};
  ${props => (props.isTrialGame && props.small && `
    padding-top: 15px;
    margin-top: 30px;
  `)};
`;

export default Content;
