import styled from 'styled-components';

const Result = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 80px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  
  @media (max-width: 500px) {
    font-size: 60px;
  }
  
  @media (max-width: 400px) {
    font-size: 50px;
  }
`;

export default Result;
