import styled from 'styled-components';

const PhoneText = styled.p`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: ${props => (props.bold ? 700 : 400)};
  line-height: 20px;
`;

export default PhoneText;
