import styled from 'styled-components';

const Label = styled.label`
  color: #1C1C1C;
  display: block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  margin-right: ${props => (props.marginRight ? props.marginRight : '65px')};
  text-align: ${props => (props.align ? props.align : 'right')};
  width: ${props => (props.width ? props.width : 'calc(50% - 195px)')};
`;

export default Label;
