import get from 'lodash/get';

const UNKNOWN_CONNECTION_TYPE = 'unknown';

const getConnectionType = () => {
  if (!navigator) {
    return UNKNOWN_CONNECTION_TYPE;
  }

  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  let connectionType = UNKNOWN_CONNECTION_TYPE;
  if (connection) {
    connectionType = get(connection, 'effectiveType', UNKNOWN_CONNECTION_TYPE);
  }

  return connectionType;
};

export default getConnectionType;
