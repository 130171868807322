import React from 'react';
import PropTypes from 'prop-types';
import { noun } from 'plural-ru';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isFinite from 'lodash/isFinite';
import { PoseGroup } from 'react-pose';

import TipImage from '../../assets/tips/highlight.svg';

import { makeSelectHighlightTipCount } from '../../redux/modules/myGame/selectors';
import { Img, Event } from './components';

class HighlightTipChangeCountEvent extends React.PureComponent {
  static defaultProps = {
    tipCount: null,
  };

  static propTypes = {
    tipCount: PropTypes.number,
  };

  state = {
    delta: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (
      isFinite(nextProps.tipCount)
      && isFinite(this.props.tipCount)
      && this.props.tipCount !== nextProps.tipCount
    ) {
      this.setState({
        delta: nextProps.tipCount - this.props.tipCount,
      });
    }
  }

  resetDelta = (pose) => {
    if (pose === 'enter') {
      this.setState({
        delta: 0,
      });
    }
  };

  render() {
    const { delta } = this.state;

    let text = '';
    if (delta > 0) {
      text = noun(
        delta,
        `приобретена ${delta} подсказка`,
        `приобретено ${delta} подсказки`,
        `приобретено ${delta} подсказок`,
      );
    }

    if (delta < 0) {
      text = 'использована подсказка';
    }

    return (
      <PoseGroup>
        {delta !== 0 && (
          <Event
            key="tipCount"
            onPoseComplete={this.resetDelta}
          >
            <Img
              src={TipImage}
              width={20}
              height={20}
              alt=""
            />
            <span>{text}</span>
          </Event>
        )}
      </PoseGroup>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  tipCount: makeSelectHighlightTipCount(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(HighlightTipChangeCountEvent);
