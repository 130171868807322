import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectShowLibraryPopup = () => createSelector(
  storeSlice,
  state => state.get('showLibraryPopup'),
);

const makeSelectActiveCardId = () => createSelector(
  storeSlice,
  state => state.get('activeCardId'),
);

const makeSelectActiveCardGroupId = () => createSelector(
  storeSlice,
  state => state.get('activeCardGroupId'),
);

const makeSelectActiveLibraryLink = () => createSelector(
  storeSlice,
  state => state.get('activeLibraryLink'),
);

export {
  makeSelectShowLibraryPopup,
  makeSelectActiveCardId,
  makeSelectActiveCardGroupId,
  makeSelectActiveLibraryLink,
};
