import React from 'react';

class ActiveGameDependedContainer extends React.PureComponent {
  render() {
    const { activeGameId } = this.props;

    if (!activeGameId) {
      return null;
    }

    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, { activeGameId })}
      </React.Fragment>
    );
  }
}

export default ActiveGameDependedContainer;
