import React from 'react';
import isFunction from 'lodash/isFunction';

class ScrollPopupContentToTop extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (
      this.props.containerRef.current
      && this.props.isActive !== prevProps.isActive
    ) {
      if (isFunction(this.props.containerRef.current.scrollTo)) {
        this.props.containerRef.current.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default ScrollPopupContentToTop;
