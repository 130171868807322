import { fromJS } from 'immutable';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';

import {
  ADD_CARD_TO_QUEUE,
  REMOVE_CARD_FROM_QUEUE,
  SEND_REQUEST_ITERATE_SUCCESS,
} from './constants';

const initialState = fromJS({
  queue: [],
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CARD_TO_QUEUE:
      return state
        .set('queue', uniq([
          ...state.get('queue'),
          ...[action.cardId],
        ]));
    case REMOVE_CARD_FROM_QUEUE:
      return state
        .set('queue', filter(state.get('queue'), id => action.cardId !== id));
    case SEND_REQUEST_ITERATE_SUCCESS:
      return state
        .set('queue', filter(state.get('queue'), id => action.cardId !== id));
    default:
      return state;
  }
}

export default reducer;
