import { fromJS } from 'immutable';

import {
  INVITE_SET_PASSWORD_REQUEST,
  INVITE_SET_PASSWORD_REQUEST_SUCCESS,
  INVITE_SET_PASSWORD_REQUEST_FAIL,
  INVITE_SET_PASSWORD_RESET_FORM,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_SUCCESS,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_FAIL,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST,
} from './constants';

const initialState = fromJS({
  isEmployee: null,
  isSubmitting: false,
  token: '',
  password: '',
  isSucceeded: false,
  isFail: false,
  isValidToken: null,
  isValidTokenPending: false,
  isTokenValidated: false,
});

function InviteSetPasswordFormReducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_SET_PASSWORD_REQUEST:
      return state
        .set('isEmployee', action.isEmployee)
        .set('isSubmitting', true)
        .set('isSucceeded', false)
        .set('isFail', false)
        .set('token', action.token)
        .set('password', action.password);
    case INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST:
      return state
        .set('isEmployee', action.isEmployee)
        .set('token', action.token)
        .set('password', action.password);
    case INVITE_SET_PASSWORD_REQUEST_SUCCESS:
      return state
        .set('isEmployee', null)
        .set('isSubmitting', false)
        .set('isSucceeded', true)
        .set('isFail', false)
        .set('token', '')
        .set('password', '');
    case INVITE_SET_PASSWORD_REQUEST_FAIL:
      return state
        .set('isSucceeded', false)
        .set('isFail', true)
        .set('isSubmitting', false)
        .set('token', '')
        .set('password', '');
    case INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_SUCCESS:
      return state
        .set('isValidTokenPending', false)
        .set('isTokenValidated', true)
        .set('isValidToken', action.isValidToken);
    case INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_FAIL:
      return state
        .set('isValidTokenPending', false)
        .set('isTokenValidated', true)
        .set('isValidToken', false);
    case INVITE_SET_PASSWORD_RESET_FORM:
      return initialState;
    default:
      return state;
  }
}

export default InviteSetPasswordFormReducer;
