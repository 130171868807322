import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import BalanceInPopup from '../BalanceInPopup';

import CloseIcon from './CloseIcon';
import HighlightIcon from './HighlightIcon';
import FiftyFiftyIcon from './FiftyFiftyIcon';
import SaveIcon from './SaveIcon';

const PopupWrapper = styled.div`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, .5)')};
  display: flex;
  left: 0;
  height: 100vh;
  opacity: ${props => (props.isActive ? '1' : '0')};
  overflow: auto;
  padding: 15px 0;
  position: fixed;
  top: 0;
  transition: opacity .3s ease-out;
  width: 100vw;
  z-index: ${props => (props.isActive ? '10' : '-1')};;
`;

const PopupInner = styled.div`
  background-color: #FFFFFF;
  border-radius: 15px;
  margin: 0;
  margin: auto;
  position: relative;
  width: 595px;
  z-index: 10;
  
  @media (max-width: 400px) {
    margin: 5px 10px;
    width: calc(100% - 20px);
    height: 100vh;
  }
`;

const PopupHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  justify-content: flex-start;
  height: 100px;
  margin: 0 30px;
`;

const PopupTitle = styled.h1`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
`;

const PopupBody = styled.div`
  border-bottom: ${props => (props.withoutBorder ? 'none' : '1px solid #DEDEDE')};
  margin: 0 30px;
  padding: ${props => (props.withoutPadding ? 'none' : '30px 0')};
`;

const PopupFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  
  @media (max-width: 400px) {
    padding: 15px 30px;
  }
`;

export const Button = styled.button`
  background-color: ${props => (props.isAlternative ? '#FFFFFF' : '#35BB9A')};
  border: 0;
  border-radius: 25px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.05), 0 1px 0 1px rgba(0,0,0,0.05), 0 4px 6px 0 rgba(0,0,0,0.10);
  color:  ${props => (props.isAlternative ? '#1C1C1C' : '#FFFFFF')};
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 50px;
  letter-spacing: 1px;
  margin-left: ${props => (props.allWidth ? '0' : '15px')};
  outline: none;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all .3s ease-out;
  width: ${props => (props.allWidth ? '100%' : 'inherit')};
  user-select: none;

  &:hover {
    background-color: ${props => (props.isAlternative ? '#FFFFFF' : darken(0.1, '#35BB9A'))};
    border: 0;
  }
`;

class Popup extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive) {
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('mousedown', this.handleClickOutsidePopup);
  }

  handleClickOutsidePopup = (e) => {
    if (!ReactDOM.findDOMNode(this.popupInner).contains(e.target)) { // eslint-disable-line react/no-find-dom-node
      this.props.onClose();
    }
  };

  render() {
    let headIcon = <div />;

    if (this.props.type === 'highlight') {
      headIcon = <HighlightIcon />;
    } else if (this.props.type === 'fiftyFifty') {
      headIcon = <FiftyFiftyIcon />;
    } else if (this.props.type === 'save') {
      headIcon = <SaveIcon />;
    }

    return (
      <PopupWrapper
        backgroundColor={this.props.backgroundColor}
        isActive={this.props.isActive}
      >
        <PopupInner
          className="popupInner"
          ref={(node) => {
            this.popupInner = node;
          }}
        >
          <PopupHeader>
            {headIcon}
            <PopupTitle>
              {this.props.title}
            </PopupTitle>
            <CloseIcon onClose={this.props.onClose} />
          </PopupHeader>
          <PopupBody
            withoutPadding={this.props.withoutPadding}
            withoutBorder={this.props.withoutBorder}
          >
            {this.props.children}
          </PopupBody>
          <PopupFooter>
            {(this.props.balance || this.props.balance === 0) && (
              <BalanceInPopup balance={this.props.balance} />
            )}
            {this.props.cancelText && (
              <Button type="button" isAlternative onClick={this.props.onClose}>
                {this.props.cancelText ? this.props.cancelText : 'Отмена'}
              </Button>
            )}
            {this.props.submitText && (
              <Button type="button" onClick={this.props.onSubmit}>
                {this.props.submitText ? this.props.submitText : 'Да'}
              </Button>
            )}
          </PopupFooter>
        </PopupInner>
      </PopupWrapper>
    );
  }
}

Popup.propTypes = {
  backgroundColor: PropTypes.string,
  balance: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.string,
  withoutPadding: PropTypes.bool,
  withoutBorder: PropTypes.bool,
};

export default Popup;
