import React from 'react';
import { EventWrapper } from './components';
import FiftyFiftyTipChangeCountEvent from './FiftyFiftyTipChangeCountEvent';
import HighlightTipBuyEvent from './HighlightTipChangeCountEvent';
import KeyReceiveEvent from './KeyReceiveEvent';
import SaveTipChangeCountEvent from './SaveTipChangeCountEvent';
import ScreenContentRefreshEvent from './ScreenContentRefreshEvent';

const GameEvents = () => (
  <EventWrapper>
    <HighlightTipBuyEvent />
    <FiftyFiftyTipChangeCountEvent />
    <SaveTipChangeCountEvent />
    <KeyReceiveEvent />
    <ScreenContentRefreshEvent />
  </EventWrapper>
);

export default GameEvents;
