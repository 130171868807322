import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import scrollLock from '../../utils/scrollLock';

import CloseIcon from './CloseIcon';

const PopupWrapper = styled.div`
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  left: 0;
  height: 100vh;
  opacity: ${props => (props.isActive ? '1' : '0')};
  overflow: auto;
  padding: ${props => (props.small ? '15px 10px' : '15px 5px')};
  position: fixed;
  top: 0;
  transition: opacity .3s ease-out;
  width: 100vw;
  z-index: ${props => (props.isActive ? '14000' : '-1')};;
  visibility: ${props => (props.isActive ? 'visible' : 'hidden')};;
`;

const PopupInner = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 0;
  margin: auto;
  position: relative;
  width: 595px;
  z-index: 10;
`;

const PopupHeader = styled.div`
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 90px;
  padding: 0 30px;
`;

const PopupTitle = styled.h1`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
`;

const PopupBody = styled.div`
  border-bottom: 1px solid #DEDEDE;
  margin: 0 30px;
  border-bottom: ${props => (props.withoutBorder ? 'none' : '1px solid #DEDEDE')};
`;

const PopupFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  ${props => props.withoutActions && 'padding: 15px;'}
`;

const Footer = styled.div`
  flex: 1;
`;

export const Button = styled.button`
  background-color: ${props => (props.isAlternative ? '#FFFFFF' : '#35BB9A')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${props => (props.isAlternative ? '1px solid rgba(0, 0, 0, .25)' : '')};
  border-radius: 25px;
  color:  ${props => (props.isAlternative ? '#1C1C1C' : '#FFFFFF')};
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 50px;
  letter-spacing: 1px;
  margin-left: ${props => (props.allWidth ? '0' : '15px')};
  outline: none;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all .3s ease-out;
  width: ${props => (props.allWidth ? '100%' : 'inherit')};
  user-select: none;

  &:hover {
    background-color: ${props => (props.isAlternative ? '#FFFFFF' : darken(0.1, '#35BB9A'))};
    border: ${props => (props.isAlternative ? '1px solid rgba(0, 0, 0, 1)' : '')};
  }
`;

class Popup extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    if (this.props.isActive) {
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
      scrollLock.on();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
      scrollLock.on();
    }

    if (this.props.isActive && !nextProps.isActive) {
      scrollLock.off();
      document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }
  }

  componentWillUnmount() {
    if (this.props.isActive) {
      scrollLock.off();
      document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }
  }

  handleClickOutsidePopup = (e) => {
    if (!ReactDOM.findDOMNode(this.popupInner).contains(e.target)) { // eslint-disable-line react/no-find-dom-node
      this.props.onClose();
    }
  };

  render() {
    const title = this.props.title ? this.props.title : 'Изменить данные';
    const cancel = this.props.cancel ? this.props.cancel : 'Отмена';
    const submit = this.props.submit ? this.props.submit : 'Сохранить';
    const { pending, disabled, withoutActions } = this.props;

    return (
      <PopupWrapper isActive={this.props.isActive}>
        <PopupInner
          className="popupInner"
          ref={(node) => {
            this.popupInner = node;
          }}
        >
          <PopupHeader>
            <PopupTitle>{title}</PopupTitle>
            <CloseIcon onClose={this.props.onClose} />
          </PopupHeader>
          <PopupBody withoutBorder>
            {this.props.children}
          </PopupBody>
          <PopupFooter withoutActions={withoutActions}>
            {this.props.footer && (
              <Footer>
                {this.props.footer}
              </Footer>
            )}
            {!withoutActions && (
              <>
                <Button type="button" isAlternative onClick={this.props.onClose} disabled={pending}>{cancel}</Button>
                <Button type="button" onClick={this.props.onSubmit} disabled={pending || disabled}>
                  {!pending && submit}
                  {pending && 'Загрузка...'}
                </Button>
              </>
            )}
          </PopupFooter>
        </PopupInner>
      </PopupWrapper>
    );
  }
}

Popup.propTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  disableScroll: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  footer: PropTypes.any,
  cancel: PropTypes.string,
  submit: PropTypes.string,
  withoutActions: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Popup.defaultProps = {
  withoutActions: false,
  pending: false,
  disabled: false,
  disableScroll: true,
  footer: null,
};

export default Popup;
