import styled from 'styled-components';

const FlexWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default FlexWrapper;
