import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectGetGamesPending = () => createSelector(
  storeSlice,
  state => state.get('pending'),
);

const makeSelectGames = () => createSelector(
  storeSlice,
  state => state.get('gamesList'),
);

const makeSelectSuccess = () => createSelector(
  storeSlice,
  state => state.get('success'),
);

const makeSelectFail = () => createSelector(
  storeSlice,
  state => state.get('fail'),
);

const makeSelectGamesData = () => createSelector(
  storeSlice,
  state => state.get('gamesData'),
);

const makeSelectGamesLicenses = () => createSelector(
  storeSlice,
  state => state.get('gamesLicenses'),
);

const makeSelectShowcaseList = () => createSelector(
  storeSlice,
  state => state.get('showcaseList'),
);

const makeSelectAddShowCase = () => createSelector(
  storeSlice,
  state => state.get('addShowCase'),
);

const makeSelectCart = () => createSelector(
  storeSlice,
  state => state.get('cart'),
);

const makeSelectBuyPending = () => createSelector(
  storeSlice,
  state => state.get('addShowCasePending') || state.get('cartCheckoutPending') || state.get('getInvoicesPending'),
);

const makeSelectCartCheckoutSuccess = () => createSelector(
  storeSlice,
  state => state.get('cartCheckoutSuccess'),
);

const makeSelectInvoices = () => createSelector(
  storeSlice,
  state => state.get('invoices'),
);

const makeSelectBuyShowCaseId = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseId'),
);

const makeSelectBuyShowCasePending = () => createSelector(
  storeSlice,
  state => state.get('buyShowCasePending'),
);

const makeSelectBuyShowCaseSuccess = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseSuccess'),
);

const makeSelectBuyShowCaseFail = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseFail'),
);

const makeSelectBuyShowCaseFromDemoId = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseFromDemoId'),
);

const makeSelectBuyShowCaseFromDemoPending = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseFromDemoPending'),
);

const makeSelectBuyShowCaseFromDemoSuccess = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseFromDemoSuccess'),
);

const makeSelectBuyShowCaseFromDemoFail = () => createSelector(
  storeSlice,
  state => state.get('buyShowCaseFromDemoFail'),
);

const makeSelectGamesShowcases = () => createSelector(
  storeSlice,
  state => state.get('gamesShowcases'),
);
const makeSelectGamesShowcasesPending = () => createSelector(
  storeSlice,
  state => state.get('gamesShowcasesPending'),
);

export {
  makeSelectBuyShowCaseFromDemoId,
  makeSelectBuyShowCaseFromDemoPending,
  makeSelectBuyShowCaseFromDemoSuccess,
  makeSelectBuyShowCaseFromDemoFail,
  makeSelectBuyShowCaseId,
  makeSelectBuyShowCasePending,
  makeSelectBuyShowCaseSuccess,
  makeSelectBuyShowCaseFail,
  makeSelectGames,
  makeSelectSuccess,
  makeSelectFail,
  makeSelectGetGamesPending,
  makeSelectGamesData,
  makeSelectGamesLicenses,
  makeSelectShowcaseList,
  makeSelectAddShowCase,
  makeSelectCart,
  makeSelectBuyPending,
  makeSelectCartCheckoutSuccess,
  makeSelectInvoices,
  makeSelectGamesShowcases,
  makeSelectGamesShowcasesPending,
};
