import { fromJS } from 'immutable';

import {
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_NICKNAME,
  CHANGE_EMAIL,
  CHANGE_DEPARTAMENT,
  CHANGE_POSITION,
  CHANGE_PATRONYMIC,
  PROFILE_REQUEST,
  PROFILE_REQUEST_SUCCESS,
  PROFILE_REQUEST_FAIL,
  PROFILE_REQUEST_CHANGE,
  PROFILE_REQUEST_CHANGE_SUCCESS,
  PROFILE_REQUEST_CHANGE_FAIL,
  PROFILE_CASH_REQUEST_SUCCESS,
  PROFILE_CASH_REQUEST_FAIL,
  PROFILE_RATING_REQUEST,
  PROFILE_RATING_REQUEST_SUCCESS,
  PROFILE_RATING_REQUEST_FAIL,
  USER_PHOTO_REQUEST,
  USER_PHOTO_REQUEST_FAIL, CHANGE_SHOW_ME_IN_RATING,
} from './constants';

const initialState = fromJS({
  profile: {
    canDebug: false,
    name: '',
    surname: '',
    middlename: '',
    nickname: '',
    email: '',
    photo: '',
    isEmployer: false,
    isWorkInvoice: false,
    company: '',
    companyId: '',
    department: '',
    position: '',
    currencyID: '',
    cash: 0,
    readoms: 0,
    virtualCash: null,
    rating: 0,
    maxsumma: 0,
    userID: null,
    employeeID: null,
    employeeEmail: null,
    userEmail: null,
    firstPhone: null,
    showMeInRating: true,
    canSeeCompanyRating: null,
    isLoading: false,
    acl: {
      canSeeCompanyRating: false,
      canSeeCompanyExtendedRating: false,
      canViewAllCompanyEmployee: false,
      canEditAllCompanyEmployee: false,
      canViewOwnDepartmentEmployee: false,
      canEditOwnDepartmentEmployee: false,
    },
  },
});

function homeReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_REQUEST_CHANGE:
      return state;
    case PROFILE_REQUEST_CHANGE_SUCCESS:
      return state
        .setIn(['profile', 'isLoading'], false);
    case PROFILE_REQUEST_CHANGE_FAIL:
      return state
        .setIn(['profile', 'isLoading'], false);
    case PROFILE_REQUEST:
      return state
        .setIn(['profile', 'isLoading'], true);
    case PROFILE_REQUEST_SUCCESS:
      return state
        .setIn(['profile', 'isLoading'], false)
        .setIn(['profile', 'canDebug'], action.profile.canDebug)
        .setIn(['profile', 'name'], action.profile.name)
        .setIn(['profile', 'surname'], action.profile.surname)
        .setIn(['profile', 'middlename'], action.profile.middlename)
        .setIn(['profile', 'email'], action.profile.email)
        .setIn(['profile', 'currencyID'], action.profile.currency)
        .setIn(['profile', 'photo'], action.profile.photo)
        .setIn(['profile', 'isEmployer'], action.profile.isEmployer)
        .setIn(['profile', 'isWorkInvoice'], action.profile.isWorkInvoice)
        .setIn(['profile', 'maxsumma'], action.profile.maxsumma)
        .setIn(['profile', 'userID'], action.profile.id)
        .setIn(['profile', 'employeeID'], action.profile.EmployeeID)
        .setIn(['profile', 'firstPhone'], action.profile.firstPhone)
        .setIn(['profile', 'company'], action.profile.company)
        .setIn(['profile', 'companyId'], action.profile.companyId)
        .setIn(['profile', 'department'], action.profile.department)
        .setIn(['profile', 'employeeEmail'], action.profile.employeeEmail)
        .setIn(['profile', 'userEmail'], action.profile.userEmail)
        .setIn(['profile', 'showMeInRating'], action.profile.showMeInRating)
        .setIn(['profile', 'canSeeCompanyRating'], action.profile.canSeeCompanyRating)
        .setIn(['profile', 'rating'], action.profile.rqRating)
        .setIn(['profile', 'acl', 'canSeeCompanyRating'], action.profile.acl.canSeeCompanyRating)
        .setIn(['profile', 'acl', 'canSeeCompanyExtendedRating'], action.profile.acl.canSeeCompanyExtendedRating)
        .setIn(['profile', 'acl', 'canViewAllCompanyEmployee'], action.profile.acl.canViewAllCompanyEmployee)
        .setIn(['profile', 'acl', 'canEditAllCompanyEmployee'], action.profile.acl.canEditAllCompanyEmployee)
        .setIn(['profile', 'acl', 'canViewOwnDepartmentEmployee'], action.profile.acl.canViewOwnDepartmentEmployee)
        .setIn(['profile', 'acl', 'canEditOwnDepartmentEmployee'], action.profile.acl.canEditOwnDepartmentEmployee);
    case PROFILE_REQUEST_FAIL:
      return state
        .setIn(['profile', 'isLoading'], false)
        .setIn(['profile', 'canDebug'], false)
        .setIn(['profile', 'name'], '')
        .setIn(['profile', 'surname'], '')
        .setIn(['profile', 'middlename'], '')
        .setIn(['profile', 'photo'], '')
        .setIn(['profile', 'nickname'], '')
        .setIn(['profile', 'email'], '')
        .setIn(['profile', 'company'], '')
        .setIn(['profile', 'companyId'], '')
        .setIn(['profile', 'department'], '')
        .setIn(['profile', 'position'], '')
        .setIn(['profile', 'currencyID'], '')
        .setIn(['profile', 'userID'], '')
        .setIn(['profile', 'firstPhone'], null)
        .setIn(['profile', 'isEmployer'], false)
        .setIn(['profile', 'isWorkInvoice'], false)
        .setIn(['profile', 'showMeInRating'], true)
        .setIn(['profile', 'maxsumma'], 0)
        .setIn(['profile', 'rating'], 0)
        .setIn(['profile', 'employeeEmail'], '')
        .setIn(['profile', 'canSeeCompanyRating'], null)
        .setIn(['profile', 'userEmail'], '')
        .setIn(['profile', 'acl', 'canSeeCompanyRating'], false)
        .setIn(['profile', 'acl', 'canSeeCompanyExtendedRating'], false)
        .setIn(['profile', 'acl', 'canViewAllCompanyEmployee'], false)
        .setIn(['profile', 'acl', 'canEditAllCompanyEmployee'], false)
        .setIn(['profile', 'acl', 'canViewOwnDepartmentEmployee'], false)
        .setIn(['profile', 'acl', 'canEditOwnDepartmentEmployee'], false)
    case CHANGE_NAME:
      return state
        .setIn(['profile', 'name'], action.name);
    case CHANGE_SHOW_ME_IN_RATING:
      return state
        .setIn(['profile', 'showMeInRating'], action.showMeInRating);
    case CHANGE_PATRONYMIC:
      return state
        .setIn(['profile', 'middlename'], action.patronymic);
    case CHANGE_SURNAME:
      return state
        .setIn(['profile', 'surname'], action.surname);
    case CHANGE_NICKNAME:
      return state
        .setIn(['profile', 'nickname'], action.nickname);
    case CHANGE_EMAIL:
      return state
        .setIn(['profile', 'email'], action.email);
    case CHANGE_DEPARTAMENT:
      return state
        .setIn(['profile', 'departament'], action.departament);
    case CHANGE_POSITION:
      return state
        .setIn(['profile', 'position'], action.position);
    case PROFILE_CASH_REQUEST_SUCCESS:
      return state
        .setIn(['profile', 'cash'], action.cash)
        .setIn(['profile', 'virtualCash'], action.virtualCash)
        .setIn(['profile', 'readoms'], action.readoms);
    case PROFILE_CASH_REQUEST_FAIL:
      return state
        .setIn(['profile', 'cash'], 0)
        .setIn(['profile', 'virtualCash'], null)
        .setIn(['profile', 'readoms'], 0);
    case PROFILE_RATING_REQUEST:
      return state.setIn(['profile', 'rating'], 0);
    case PROFILE_RATING_REQUEST_SUCCESS:
      return state.setIn(['profile', 'rating'], action.rating);
    case PROFILE_RATING_REQUEST_FAIL:
      return state.setIn(['profile', 'rating'], 0);
    case USER_PHOTO_REQUEST:
      return state.setIn(['profile', 'photo'], action.photo);
    case USER_PHOTO_REQUEST_FAIL:
      return state.setIn(['profile', 'photo'], '');
    default:
      return state;
  }
}

export default homeReducer;
