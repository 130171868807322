import {
  SET_TARGET,
  SET_VISIBLE,
} from './constants';

export function setTarget(target) {
  return {
    type: SET_TARGET,
    target,
  };
}

export function setVisible(visible) {
  return {
    type: SET_VISIBLE,
    visible,
  };
}
