export const CHANGE_LEFT_MENU_STATE = 'readom/App/CHANGE_LEFT_MENU_STATE';
export const CHANGE_RIGHT_GAME_MENU_STATE = 'readom/App/CHANGE_RIGHT_GAME_MENU_STATE';
export const CHANGE_TOP_MENU_STATE = 'readom/App/CHANGE_TOP_MENU_STATE';
export const CHANGE_POPUP_STATE = 'readom/App/CHANGE_POPUP_STATE';
export const CHANGE_DROPZONE_STATE = 'readom/App/CHANGE_DROPZONE_STATE';
export const CHANGE_LOGIN = 'readom/App/CHANGE_LOGIN';
export const CHANGE_PASSWORD = 'readom/App/CHANGE_PASSWORD';
export const CHANGE_IS_FAIL = 'readom/App/CHANGE_IS_FAIL';
export const CHANGE_RESET_LOGIN = 'readom/App/CHANGE_RESET_LOGIN';
export const CHANGE_RESET_IS_FAIL = 'readom/App/CHANGE_RESET_IS_FAIL';

export const LOGIN_REQUEST = 'readom/App/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'readom/App/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'readom/App/LOGIN_FAIL';

export const RESET_REQUEST = 'readom/App/RESET_REQUEST';
export const RESET_REQUEST_SUCCESS = 'readom/App/RESET_REQUEST_SUCCESS';
export const RESET_REQUEST_FAIL = 'readom/App/RESET_REQUEST_FAIL';

export const SHOW_LOADING = 'readom/App/SHOW_LOADING';
export const HIDE_LOADING = 'readom/App/HIDE_LOADING';

export const LOGOUT = 'readom/App/LOGOUT';
export const RESET_RESET_PASSWORD_STATE = 'readom/App/RESET_RESET_PASSWORD_STATE';

export const START_TRIAL_GAME = 'readom/App/START_TRIAL_GAME';
export const START_TRIAL_GAME_SUCCESS = 'readom/App/START_TRIAL_GAME_SUCCESS';
export const START_TRIAL_GAME_FAIL = 'readom/App/START_TRIAL_GAME_FAIL';

export const SET_ANALYTIC_PARAM_FOR_BACKEND = 'readom/App/SET_ANALYTIC_PARAM_FOR_BACKEND';
