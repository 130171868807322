import { fromJS } from 'immutable';
import {
  SET_ACTIVE_VIDEO,
} from './constants';

const initialState = fromJS({
  activeVideo: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_VIDEO:
      return state
        .set('activeVideo', action.payload.videoId);
    default:
      return state;
  }
}

export default reducer;
