import {
  call, put, select, takeLeading, delay,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import { ADD_EMPLOYEE_REQUEST } from '../../../modules/forms/addEmployeeForm/constants';

import { makeSelectApiUrl } from '../../../modules/global/selectors';

import {
  getDepartmentList,
  changeActiveTab,
} from '../../../modules/employees/actions';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* addEmployeeSaga({ payload }) {
    const url = yield select(makeSelectApiUrl());

    const { values, formik: { setFieldError, setStatus, setSubmitting } } = payload;

    const requestURL = `${url}/EmployeeAdd/${encodeURIComponent(values.departmentId)}/${encodeURIComponent(values.surname)}/${encodeURIComponent(values.name)}/${encodeURIComponent(values.patronymic)}/${encodeURIComponent(values.email)}`;

    try {
      setStatus({ submitSucceeded: undefined });
      setSubmitting(true);
      yield delay(500);
      const result = yield call(apiRequest, requestURL);

      const isSuccess = get(result, 'data.result[0].Result', null) === 'success';
      if (isSuccess) {
        yield put(getDepartmentList());
        yield put(changeActiveTab({
          activeTab: 'tabItem_1',
        }));
        setStatus({ submitSucceeded: true });
      } else {
        const errorMessage = get(result, 'data.result[0].Message', null);
        // TODO: HARDCODE DANGER_DATA_FLOW Тип ошибки определяется по тексту ошибки
        if (errorMessage === 'Уже существует сотрудник с таким email') {
          setFieldError('email', errorMessage);
        } else {
          throw new Error('Unknown error while add employee.');
        }
      }
    } catch (err) {
      setStatus({
        submitSucceeded: false,
        globalError: process.env.REACT_APP_ADD_EMPLOYEE_ERROR_MESSAGE,
      });
      yield put(getDepartmentList());
    } finally {
      setSubmitting(false);
    }
  }

  function* saga() {
    yield takeLeading(ADD_EMPLOYEE_REQUEST, addEmployeeSaga);
  }

  return saga;
}

export default build;
