import React from 'react';
import PropTypes from 'prop-types';

import BgImage from './library.svg';
import Tip from './Tip';

class Library extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <Tip
        onClick={this.props.onClick}
        backgroundImage={BgImage}
        small={this.props.small}
      >
        {this.props.children}
      </Tip>
    );
  }
}

Library.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Library;
