import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import Spinner from 'react-spinkit';
import renderPhoneInput from '../../FormikForms/inputs/renderPhoneInput';
import URL_CONSTANTS from '../../urlConstants';

import Button from '../useThis/Button/index';

import Error from '../Error/index';
import renderInput from '../../FormikForms/inputs/renderInput';

const FormLineInput = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5px;
  & > div {
    width: 100%;
  }
`;

const StyledA = styled.a`
  color: #8c8c8c;
`;
const Agrement = styled.p`
  font-size: 12px;
  color: #8c8c8c;
`;

const SpinnerWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & .spinner {
    width: 40px;
    height: 40px;
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  position: relative;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MemoButton = React.memo(Button);

const StartTrialGameWithPhoneForm = (
  {
    values,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    status,
  },
) => (
  <>
    {status && status.serverErrorMessage && (
      <Error>
        {status.serverErrorMessage}
      </Error>
    )}
    <FormLineInput>
      <Field
        name="name"
        type="text"
        required
        disabled={isSubmitting}
        placeholder="Ваше имя"
        component={renderInput}
      />
    </FormLineInput>
    <FormLineInput>
      <Field
        name="phone"
        type="text"
        required
        disabled={isSubmitting}
        placeholder="Ваш телефон"
        component={renderPhoneInput}
      />
    </FormLineInput>

    <ButtonContainer>
      {isSubmitting && (
        <SpinnerWrapper>
          <Spinner
            name="circle"
            fadeIn="none"
            className="spinner"
            color="#fff"
          />
        </SpinnerWrapper>
      )}
      <MemoButton allWidth type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        ПОЛУЧИТЬ ДЕМО ДОСТУП
      </MemoButton>
    </ButtonContainer>
    <Agrement>
      Даю согласие на&nbsp;обработку
      &nbsp;<StyledA href={URL_CONSTANTS.URLS.PROCESSING_PERSONAL_DATA} target="_blank">персональных данных</StyledA>
      &nbsp;и&nbsp;согласен
      с&nbsp;<StyledA href={URL_CONSTANTS.URLS.LICENCE} target="_blank">лицензионным соглашением</StyledA>
    </Agrement>
  </>
);

export default StartTrialGameWithPhoneForm;
