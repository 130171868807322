import PropTypes from 'prop-types';
import React from 'react';
import pure from 'recompose/pure';
import styled from 'styled-components';

const Root = pure(styled.div`
  display: block;
  text-decoration: none;
  width: 285px;
  min-width: 285px;;
  //height: 370px;
  //min-height: 370px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 16px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  transform: scale(1);
  transition: 0.3s transform;
  user-select: none;
`);

const Image = pure(styled.div`
  background-image: url(${props => props.src});
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
`);

const TextBlock = pure(styled.div`
  padding: 13px 15px;
  display: flex;
  flex-direction: column;
  //height: 220px;
`);

const Name = pure(styled.div`
  font-size: 20px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
`);

const Description = pure(styled.div`
  font-size: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 15px;
  color: #1c1c1ca0;
  padding-top: 10px;
`);

const Footer = pure(styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`);

class GameCard extends React.PureComponent {
  static defaultProps = {
    children: null,
    description: null,
    betaAccess: false,
    image: null,
  };

  static propTypes = {
    children: PropTypes.any,
    description: PropTypes.string,
    noFooter: PropTypes.bool,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
  };

  render() {
    const {
      name, description, image,
    } = this.props;

    return (
      <Root>
        <Image src={image} />
        <TextBlock>
          <Name>{name}</Name>
          <Description>{description}</Description>
          <Footer>
            {this.props.children}
          </Footer>
        </TextBlock>
      </Root>
    );
  }
}

export default GameCard;
