import {
  CONFIRM_PERSONAL_EMAIL_REQUEST,
  CONFIRM_PERSONAL_EMAIL_REQUEST_SUCCESS,
  CONFIRM_PERSONAL_EMAIL_REQUEST_FAIL,
} from './constants';

export function confirmPersonalEmailRequest(payload) {
  return {
    type: CONFIRM_PERSONAL_EMAIL_REQUEST,
    ...payload,
  };
}

export function confirmPersonalEmailRequestSuccess() {
  return {
    type: CONFIRM_PERSONAL_EMAIL_REQUEST_SUCCESS,
  };
}

export function confirmPersonalEmailRequestFail() {
  return {
    type: CONFIRM_PERSONAL_EMAIL_REQUEST_FAIL,
  };
}
