import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { device } from '../../../utils/mediaQueries';

const StatWrapper = styled.div`
  width: ${props => (props.wide ? '280px' : '230px')};
  text-align: center;
`;

const StatNumber = styled.div`
  color: #FFFFFF;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  font-size: 50px;
  @media ${device.mobileL} {
    font-size: 35px;
  }
`;

const Text = styled.div`
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
`;

class Stat extends React.PureComponent {
  state = {
    show: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.number !== this.props.number) {
      this.setState({
        show: true,
      });
    }
  }

  render() {
    return (
      <StatWrapper wide={this.props.wide}>
        <CSSTransition
          in={this.state.show}
          timeout={{
            enter: 1000,
            exit: 0,
          }}
          classNames="fadeIn"
          onEntered={() => {
            this.setState({
              show: false,
            });
          }}
        >
          <StatNumber>
            {this.props.number}
          </StatNumber>
        </CSSTransition>
        <Text>
          {this.props.text}
        </Text>
      </StatWrapper>
    );
  }
}

Stat.propTypes = {
  wide: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

Stat.defaultProps = {
  wide: false,
};

export default Stat;
