import animateScrollTo from 'animated-scroll-to';
import { connect } from 'react-redux';

import withHandlers from 'recompose/withHandlers';
import compose from 'recompose/compose';
import { createStructuredSelector } from 'reselect';
import { checkAnswer } from '../../../../../redux/modules/myGame/actions';
import {
  makeSelectAnswers, makeSelectFinalCupImage, makeSelectFinalCupImageTexts,
  makeSelectImage,
  makeSelectQuestion,
  makeSelectSendAnswerPending,
} from '../../../../../redux/modules/myGame/selectors';

const mapDispatchToProps = dispatch => ({
  sendAnswer: answer => dispatch(checkAnswer(answer)),
});

const mapStateToProps = createStructuredSelector({
  answers: makeSelectAnswers(),
  src: makeSelectFinalCupImage(),
  textParts: makeSelectFinalCupImageTexts(),
  question: makeSelectQuestion(),
  image: makeSelectImage(),
  sendAnswerPending: makeSelectSendAnswerPending(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
  withHandlers({
    checkAnswer: props => (id) => {
      props.sendAnswer({
        answers: props.answers.map(answer => ({
          id: answer.id,
          text: answer.text,
          isActive: answer.id === id,
          additional: answer.id === id,
        })),
        sendAnswers: true,
      });

      animateScrollTo(0);
    },
  }),
);

export default enhance;
