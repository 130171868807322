import { all, fork } from 'redux-saga/effects';

import authSagaBuilder from './auth/saga';
import guestGameBuySagaBuilder from './guestGameBuy/saga';
import profileSagaBuilder from './profile/saga';
import gameSagaBuilder from './myGame/gameSaga';
import employeesSagaBuilder from './employees/saga';

import myGameSagaBuilder from './myGame/myGameSaga';
import clickIdSagaBuilder from './clickId/saga';
import buyCardKeySagaBuilder from './buyCardKey/saga';
import analyticsSagaBuilder from './analytics/saga';

import employeeSetUserEmailFormBuilder from './form/employeeSetUserEmailForm/saga';
import profileFormSagaBuilder from './form/profileForm/saga';
import markCardAsViewedSagaBuilder from './markCardAsViewed/saga';
import departmentFormEditSagaBuilder from './form/departmentForm/editSaga';
import departmentFormAddSagaBuilder from './form/departmentForm/addSaga';
import employeeFormEditSagaBuilder from './form/employeeForm/editSaga';
import employeeFormAddSagaBuilder from './form/employeeForm/addSaga';
import inviteSetPasswordFormSagaBuilder from './form/inviteSetPasswordForm/saga';
import resetPasswordSagaBuilder from './resetPassword/saga';
import setPasswordFormSagaBuilder from './form/setPasswordForm/saga';
import registrationSagaBuilder from './form/auth/registrationSaga';
import owlExternalChannel from './owl/owlExternalChannel';
import buyGameSagaBuilder from './buyGame/saga';
import debugSagaBuilder from './debug/saga';
import ratingSagaBuilder from './rating/saga';
import getBookFragmentSaga from './getBookFragment/saga';
import finishPageSagaBuilder from './finishPage/saga';

export default ({ cookies, amplitude }) => function* sagas() {
  const profileSaga = profileSagaBuilder(cookies);
  const authSaga = authSagaBuilder(cookies);
  const guestGameBuySaga = guestGameBuySagaBuilder(cookies);
  const gameSaga = gameSagaBuilder(cookies);
  const employeesSaga = employeesSagaBuilder(cookies);
  const myGameSaga = myGameSagaBuilder(cookies);
  const markCardAsViewedSaga = markCardAsViewedSagaBuilder(cookies);
  const profileFormSaga = profileFormSagaBuilder(cookies);
  const resetPasswordSaga = resetPasswordSagaBuilder(cookies);
  const employeeSetUserEmailFormSaga = employeeSetUserEmailFormBuilder(cookies);
  const departmentFormEditSaga = departmentFormEditSagaBuilder(cookies);
  const departmentFormAddSaga = departmentFormAddSagaBuilder(cookies);
  const employeeFormEditSaga = employeeFormEditSagaBuilder(cookies);
  const employeeFormAddSaga = employeeFormAddSagaBuilder(cookies);
  const inviteSetPasswordFormSaga = inviteSetPasswordFormSagaBuilder(cookies);
  const setPasswordFormSaga = setPasswordFormSagaBuilder(cookies);
  const buyCardKeySaga = buyCardKeySagaBuilder(cookies);
  const analyticsSaga = analyticsSagaBuilder({ cookies, amplitude });
  const registrationSaga = registrationSagaBuilder(cookies);
  const clickIdSaga = clickIdSagaBuilder(cookies);
  const buyGameSaga = buyGameSagaBuilder(cookies);
  const debugSaga = debugSagaBuilder(cookies);
  const ratingSaga = ratingSagaBuilder(cookies);
  const finishPage = finishPageSagaBuilder(cookies);

  yield all([
    fork(authSaga),
    fork(clickIdSaga),
    fork(guestGameBuySaga),
    fork(buyCardKeySaga),
    fork(profileSaga),
    fork(employeesSaga),
    fork(gameSaga),
    fork(myGameSaga),
    fork(owlExternalChannel),
    fork(markCardAsViewedSaga),
    fork(profileFormSaga),
    fork(employeeSetUserEmailFormSaga),
    fork(departmentFormEditSaga),
    fork(departmentFormAddSaga),
    fork(resetPasswordSaga),
    fork(employeeFormEditSaga),
    fork(employeeFormAddSaga),
    fork(inviteSetPasswordFormSaga),
    fork(setPasswordFormSaga),
    fork(analyticsSaga),
    fork(registrationSaga),
    fork(buyGameSaga),
    fork(debugSaga),
    fork(ratingSaga),
    fork(finishPage),
    fork(getBookFragmentSaga),
  ]);
};
