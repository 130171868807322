import { fromJS } from 'immutable';

import {
  CONFIRM_PERSONAL_EMAIL_REQUEST,
  CONFIRM_PERSONAL_EMAIL_REQUEST_FAIL,
  CONFIRM_PERSONAL_EMAIL_REQUEST_SUCCESS,
} from './constants';

const initialState = fromJS({
  isSubmitting: false,
  token: '',
  password: '',
  isSucceeded: false,
  isFail: false,
});

function SetPasswordFormReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRM_PERSONAL_EMAIL_REQUEST:
      return state
        .set('isSubmitting', true)
        .set('isSucceeded', false)
        .set('isFail', false)
        .set('token', action.token)
        .set('password', action.password);
    case CONFIRM_PERSONAL_EMAIL_REQUEST_SUCCESS:
      return state
        .set('isSubmitting', false)
        .set('isSucceeded', true)
        .set('isFail', false)
        .set('token', '')
        .set('password', '');
    case CONFIRM_PERSONAL_EMAIL_REQUEST_FAIL:
      return state
        .set('isSucceeded', false)
        .set('isFail', true)
        .set('isSubmitting', false);
    default:
      return state;
  }
}

export default SetPasswordFormReducer;
