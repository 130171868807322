export const PASSWORD_INPUT_NAME = 'password';
export const PASSWORD_CONFIRM_INPUT_NAME = 'passwordConfirm';

export const PASSWORD_RESTORE_REQUEST = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_REQUEST';
export const PASSWORD_RESTORE_REQUEST_SUCCESS = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_REQUEST_SUCCESS';
export const PASSWORD_RESTORE_REQUEST_FAIL = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_REQUEST_FAIL';

export const PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST';
export const PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_SUCCESS = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_SUCCESS';
export const PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_FAIL = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST_FAIL';

export const PASSWORD_RESTORE_RESET_FORM = 'readom/reset/setPasswordForm/PASSWORD_RESTORE_RESET_FORM';
