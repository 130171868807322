import { createSelector } from 'reselect';

const form = state => state.get('employeeSetUserEmailForm');

const makeSelectIsSubmitting = () => createSelector(
  form,
  state => state.get('isSubmitting'),
);

const makeSelectPassword = () => createSelector(
  form,
  state => state.get('password'),
);

const makeSelectIsSucceeded = () => createSelector(
  form,
  state => state.get('isSucceeded'),
);

const makeSelectIsFail = () => createSelector(
  form,
  state => state.get('isFail'),
);

const makeSelectToken = () => createSelector(
  form,
  state => state.get('token'),
);

export {
  makeSelectToken,
  makeSelectIsSubmitting,
  makeSelectPassword,
  makeSelectIsSucceeded,
  makeSelectIsFail,
};
