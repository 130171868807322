import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import UserPhoto from '../UserPhoto';

const UserAvatar = ({ value }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  let image = new Image();
  if (value) {
    image.src = value;

    image.onload = () => {
      setImageLoaded(true);
      image = null;
    };

    image.onerror = () => {
      image = null;
    };
  }

  useEffect(() => () => {
    if (image) {
      image.onload = null;
      image.onerror = null;
      image = null;
    }
  });

  const urlParams = new URLSearchParams(value);
  const name = urlParams.get('SurName');
  const surname = urlParams.get('Name');

  return (
    <UserPhoto
      inRating
      src={imageLoaded ? value : null}
      name={name}
      surname={surname}
      alt={`${name} ${surname}`}
    />
  );
};

UserAvatar.propTypes = {
  value: PropTypes.string,
};

UserAvatar.defaultProps = {
  value: null,
};

export default UserAvatar;
