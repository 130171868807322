import styled from 'styled-components';
import DialogBg from './pipka.svg';

const Dialog = styled.div`
  user-select: none;
  background: #343642;
  border-radius: 20px;
  color: #FFFFFF;
  font-family: 'Neucha', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  opacity: 1;
  padding: 20px;
  position: fixed;
  transition: opacity .3s ease;
  z-index: 10;
  
  width: auto; 
  right: 20px;
  max-width: calc(100% - 40px);
  bottom: 134px;
  
  & input {
    user-select: all;
  }
  
  &::after {
    background-image: url(${DialogBg});
    content: '';
    display: block;
    height: 55px;
    position: absolute;
    width: 55px;
    bottom: -47px;
    right: 11px;
    transform: rotate(45deg);
  }
    
  @media (min-width: 500px) {
    max-width: 500px;
    left: auto;
    right: 20px;
  }
`;

export default Dialog;
