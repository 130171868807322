import styled from 'styled-components';
import pure from 'recompose/pure';

const AnswerWrapper = pure(styled.div`
  display: flex;
  backface-visibility: hidden;
  background-color: ${props => (props.isActive ? '#EBEBED' : '#FFFFFF')};
  border-radius: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .12);
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 16px;
  font-style: italic;
  line-height: 23px;
  padding: 20px 30px;
  transition: all .3s ease-out;
  width: 100%;
  user-select: none;
  ${props => props.crossedOut && 'background-color: rgba(200, 200, 200, 0.1);'}
  ${props => props.crossedOut && 'cursor: not-allowed;'}
  ${props => !props.crossedOut && (
    `cursor: pointer;
    
    @media (pointer: coarse) {
      &:hover {
        background-color: #FFFFFF;
      }
    }
    
    @media (pointer: fine) {
      &:hover {
        background-color: #EBEBED;
      }
    }
    `
  )}

  & p:last-child {
    width: 68%;
    margin-top: 10px;
    font-style: italic;
    text-align: right;
    font-size: 13px;
    line-height: 18px;
    color: #808080;
    ${props => props.crossedOut && 'opacity: 0.7;'}
    margin-left: auto;
  }

  & p:first-child {
    width: 100%;
    font-size: 16px;
    font-style: italic;
    text-align: left;
    line-height: 23px;
    color: inherit;
    margin-left: 0;
    margin-top: 0;
    ${props => props.crossedOut && 'opacity: 0.45;'}
  }
`);

export default AnswerWrapper;
