import React, { Children } from 'react';
import PropTypes from 'prop-types';

import A from './A';
import StyledButton from './StyledButton';
import StyledButtonLink from './StyledButtonLink';
import Wrapper from './Wrapper';

const Button = (props) => {
  let button = (
    <A
      to={props.to}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {Children.toArray(props.children)}
    </A>
  );

  // If the Button has a handleRoute prop, we want to render a button
  if (props.handleRoute) {
    button = (
      <StyledButton onClick={props.handleRoute}>
        {Children.toArray(props.children)}
      </StyledButton>
    );
  } else if (props.isButton) {
    button = (
      <StyledButtonLink to={props.to}>
        {Children.toArray(props.children)}
      </StyledButtonLink>
    );
  }

  return (
    <Wrapper>
      {button}
    </Wrapper>
  );
};

Button.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isButton: PropTypes.bool,
  handleRoute: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
