import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { withFormik } from 'formik';
import withHandlers from 'recompose/withHandlers';
import { startTrialGame } from '../../redux/modules/global/actions';
import StartTrialGameWithEmailValidateSchema from './StartTrialGameWithEmailValidateSchema';

const mapDispatchToProps = dispatch => ({
  startTrialGame: ({
    email, gameSysName, name, formik,
  }) => dispatch(startTrialGame({
    email, gameSysName, name, formik,
  })),
});

const withConnect = connect(null, mapDispatchToProps);

const withFormFormik = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: StartTrialGameWithEmailValidateSchema,
  handleSubmit: (values, { props, ...formik }) => {
    const email = values.email.trim().toLowerCase();

    props.onStartTrialGame({
      email,
      formik,
    });
  },
  displayName: 'StartTrialGameWithEmailPopupForm',
});

const withOnStartTrialGame = withHandlers({
  onStartTrialGame: props => (
    {
      email, formik,
    },
  ) => {
    props.startTrialGame({
      email,
      // TODO: HARDCODE_TYPE_2 Зашито системное наименование игры
      gameSysName: 'million',
      name: '',
      formik,
    });
  },
});

const enhance = compose(
  pure,
  withConnect,
  withOnStartTrialGame,
  withFormFormik,
);

export default enhance;
