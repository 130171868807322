import styled from 'styled-components';

const Tooltip = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: -63px;
  line-height: 15px;
  margin-top: 18px;
  opacity: ${props => (props.isOpened ? '1' : '0')};
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  transition: opacity .3s ease-out;
  width: ${props => (props.isBig ? '240px' : '140px')};

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: ${props => (props.isBig ? '-62px' : '-12px')};
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: ${props => (props.isBig ? '-63px' : '-13px')};
  }
`;

export default Tooltip;
