import styled from 'styled-components';

const FinishRecomendationStatusText = styled.div`
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  max-width: 620px;
  text-align: center;
  padding: 0 30px 17px;
`;

export default FinishRecomendationStatusText;
