import { fromJS } from 'immutable';
import {
  SET_BUY_GAME_POPUP_OPENED,
  SET_BUY_GAME_STAGES_3_6_POPUP_OPENED,
  SET_BUY_GAME_GUEST_POPUP_OPENED,
  SET_START_TRIAL_GAME_WITH_EMAIL_POPUP_OPENED,
  SET_START_TRIAL_GAME_WITH_PHONE_POPUP_OPENED,
  SET_BEFORE_DEMO_LEAVE_POPUP_OPENED,
  SET_THX_POPUP_OPENED,
} from './constants';

const initialState = fromJS({
  buyGamePopupOpened: false,
  buyGameStages36PopupOpened: false,
  buyGameGuestPopupOpened: false,
  startTrialGameWithEmailPopupOpened: false,
  startTrialGameWithPhonePopupOpened: false,
  beforeDemoLeavePopupOpened: false,
  thxPopupOpened: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_BUY_GAME_POPUP_OPENED:
      return state
        .set('buyGamePopupOpened', action.opened);
    case SET_BUY_GAME_STAGES_3_6_POPUP_OPENED:
      return state
        .set('buyGameStages36PopupOpened', action.opened);
    case SET_BUY_GAME_GUEST_POPUP_OPENED:
      return state
        .set('buyGameGuestPopupOpened', action.opened);
    case SET_START_TRIAL_GAME_WITH_EMAIL_POPUP_OPENED:
      return state
        .set('startTrialGameWithEmailPopupOpened', action.opened);
    case SET_START_TRIAL_GAME_WITH_PHONE_POPUP_OPENED:
      return state
        .set('startTrialGameWithPhonePopupOpened', action.opened);
    case SET_BEFORE_DEMO_LEAVE_POPUP_OPENED:
      return state
        .set('beforeDemoLeavePopupOpened', action.opened);
    case SET_THX_POPUP_OPENED:
      return state
        .set('thxPopupOpened', action.opened);
    default:
      return state;
  }
}

export default reducer;
