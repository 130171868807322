import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Cookies from 'universal-cookie';

import getAppVersion from './getAppVersion';
import getDeviceId from './getDeviceId';
import getUTMQueryParams from './getUTMQueryParams';
import getIsUserAuthenticated from './isUserAuthenticated';

const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID;

const initLogRocket = () => {
  if (LOG_ROCKET_APP_ID) {
    const cookies = new Cookies();

    console.info(`init LogRocket on ${LOG_ROCKET_APP_ID}`);
    LogRocket.init(LOG_ROCKET_APP_ID, {
      release: `readom@${getAppVersion()}`,
    });
    setupLogRocketReact(LogRocket);

    const utmParams = getUTMQueryParams(document.location.search);
    const propertiesToReport = {};
    for (const param in utmParams) {
      propertiesToReport[`utm_${param}`] = utmParams[param];
    }

    if (Object.getOwnPropertyNames(propertiesToReport).length !== 0) {
      const isUserAuthenticated = getIsUserAuthenticated(cookies);

      const deviceId = getDeviceId(cookies);
      const guestUserName = `guest__deviceId__${deviceId}`;

      const nameObject = {};
      if (!isUserAuthenticated) {
        nameObject.name = guestUserName;
      }

      LogRocket.identify(deviceId, {
        ...nameObject,
        ...propertiesToReport,
        device_id: deviceId,
      });
    }
  }
};

export default initLogRocket;
