/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectGlobal = state => state.get('global');

const selectRoute = state => state.get('router');

const makeSelectLeftMenuState = () => createSelector(
  selectGlobal,
  globalState => globalState.get('leftMenuIsOpened'),
);

const makeSelectTopMenuState = () => createSelector(
  selectGlobal,
  globalState => globalState.get('topMenuIsOpened'),
);

const makeSelectPopupState = () => createSelector(
  selectGlobal,
  globalState => globalState.get('popupIsOpened'),
);

const makeSelectDropzoneState = () => createSelector(
  selectGlobal,
  globalState => globalState.get('dropzoneIsOpened'),
);

const makeSelectApiUrl = () => createSelector(
  selectGlobal,
  globalState => globalState.get('apiUrl'),
);

const makeSelectSpinner = () => createSelector(
  selectGlobal,
  globalState => globalState.get('spinnerIsShowed'),
);

const makeSelectUserSession = () => createSelector(
  selectGlobal,
  globalState => globalState.get('userSession').toJS(),
);

const makeSelectLocation = () => createSelector(
  selectRoute,
  (routeState) => {
    const location = routeState.get('location');
    if (location) {
      return location.toJS();
    }

    return null;
  },
);

const makeSelectLogin = () => createSelector(
  selectGlobal,
  globalState => globalState.get('login'),
);

const makeSelectLoginPending = () => createSelector(
  selectGlobal,
  globalState => globalState.get('loginPending'),
);

const makeSelectPassword = () => createSelector(
  selectGlobal,
  globalState => globalState.get('password'),
);

const makeSelectIsFail = () => createSelector(
  selectGlobal,
  globalState => globalState.getIn(['userSession', 'isFail']),
);

const makeSelectResetLogin = () => createSelector(
  selectGlobal,
  globalState => globalState.get('resetLogin'),
);

const makeSelectResetWasSend = () => createSelector(
  selectGlobal,
  globalState => globalState.get('resetWasSend'),
);

const makeSelectResetWasLoading = () => createSelector(
  selectGlobal,
  globalState => globalState.get('resetLoading'),
);

const makeSelectRightGameMenuIsOpened = () => createSelector(
  selectGlobal,
  globalState => globalState.get('rightGameMenuIsOpened'),
);

const makeSelectLoginErrorMessage = () => createSelector(
  selectGlobal,
  globalState => globalState.get('loginErrorMessage'),
);

const makeSelectLoginErrorCode = () => createSelector(
  selectGlobal,
  globalState => globalState.get('loginErrorCode'),
);

const makeSelectStartTrialPending = () => createSelector(
  selectGlobal,
  globalState => globalState.get('startTrialPending'),
);

export {
  makeSelectStartTrialPending,
  makeSelectLoginErrorMessage,
  makeSelectLoginErrorCode,
  selectGlobal,
  makeSelectLeftMenuState,
  makeSelectTopMenuState,
  makeSelectPopupState,
  makeSelectDropzoneState,
  makeSelectUserSession,
  makeSelectApiUrl,
  makeSelectSpinner,
  makeSelectLocation,
  makeSelectLogin,
  makeSelectPassword,
  makeSelectIsFail,
  makeSelectResetLogin,
  makeSelectResetWasSend,
  makeSelectResetWasLoading,
  makeSelectLoginPending,
  makeSelectRightGameMenuIsOpened,
};
