import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';

const RATING_ADD_ANIMATION_DURATION = 1500;

const RatingPlusAnimation = posed.div({
  enter: {
    y: 20,
    opacity: 1,
    transition: {
      opacity: {
        type: 'keyframes',
        values: [0, 0.3, 0.9, 1, 0.9, 0.3, 0],
        duration: RATING_ADD_ANIMATION_DURATION,
      },
      y: {
        type: 'keyframes',
        values: [20, -20],
        duration: RATING_ADD_ANIMATION_DURATION,
      },
    },
  },
  exit: {
    y: -20,
    opacity: 0,
  },
});

const RatingPlusElement = styled(RatingPlusAnimation)`
  color: black;
  font-family: 'Merriweather',serif;
  font-style: italic;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: -19px;
  left: 10px;
  z-index: 4;
`;

class RatingPlus extends React.PureComponent {
  render() {
    return (
      <RatingPlusElement {...this.props} />
    );
  }
}

export default RatingPlus;
