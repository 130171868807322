import {
  SET_GAMES_OPTIONS,
  SET_GAMES_OPTIONS_FOR_SHOWCASE_GAMES,
} from './constants';

export function setGamesOptions(gamesOptions) {
  return {
    type: SET_GAMES_OPTIONS,
    payload: {
      gamesOptions,
    },
  };
}

export function setGamesOptionsForShowcaseGames(gamesOptions) {
  return {
    type: SET_GAMES_OPTIONS_FOR_SHOWCASE_GAMES,
    payload: {
      gamesOptions,
    },
  };
}
