import styled from 'styled-components';
import pure from 'recompose/pure';
import { device } from '../../utils/mediaQueries';

const FormLine = pure(styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  
  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
  }
`);

export default FormLine;
