import React from 'react';
import PropTypes from 'prop-types';
import { noun } from 'plural-ru';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isFinite from 'lodash/isFinite';
import { PoseGroup } from 'react-pose';

import KeyWhite from '../../assets/white-key.svg';
import { STEP_CONTENT } from '../../containers/other/OwlContainer/constats';
import getOwlContentType from '../../containers/other/OwlContainer/getOwlContentType';
import { makeSelectBrowser } from '../../redux/modules/browser/selectors';

import { makeSelectKeysCount } from '../../redux/modules/myGame/selectors';
import { makeSelectText } from '../../redux/modules/owl/selectors';
import { setTarget, setVisible } from '../../redux/modules/spotlight/actions';
import { Img, Event, KeyEventOwl } from './components';

class KeyReceiveEvent extends React.PureComponent {
  static defaultProps = {
    keysCount: null,
    owlText: null,
  };

  static propTypes = {
    keysCount: PropTypes.number,
    owlText: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      receivedKeysCount: 0,
    };

    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(this.onInterval, 100);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isFinite(nextProps.keysCount)
      && isFinite(this.props.keysCount)
      && this.props.keysCount !== nextProps.keysCount
    ) {
      const receivedKeysCount = nextProps.keysCount - this.props.keysCount;
      if (receivedKeysCount > 0) {
        this.setState({
          receivedKeysCount,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  hideReceiveKeyEvent = (pose) => {
    if (pose === 'enter') {
      this.setState({
        receivedKeysCount: 0,
      });
    }
  };

  onInterval = () => {
    const elem = document.getElementsByClassName('key-received-event')[0];
    if (elem) {
      if (elem.classList.contains('hidden-ui')) {
        this.setState({
          receivedKeysCount: 0,
        });
      }
    }
  };

  componentDidCatch(error, info) {
    console.error('error in key received');
  }

  render() {
    const { receivedKeysCount } = this.state;
    const { owlText, browser } = this.props;

    let owlEvent = false;
    if (getOwlContentType(owlText) === STEP_CONTENT) {
      owlEvent = true;
    }

    const text = noun(
      receivedKeysCount,
      `получен ${receivedKeysCount} ключ`,
      `получено ${receivedKeysCount} ключа`,
      `получено ${receivedKeysCount} ключей`,
    );

    const small = browser.is.extraSmall || browser.is.small;

    if (owlEvent && small) {
      if (!receivedKeysCount) {
        return null;
      }

      return (
        <KeyEventOwl
          className="key-received-event"
        >
          <Img
            src={KeyWhite}
            width={20}
            height={20}
            alt=""
          />
          <span>{text}</span>
        </KeyEventOwl>
      );
    }

    return (
      <PoseGroup>
        {receivedKeysCount && (
          <Event
            key="receivedKeysCount"
            className="key-received-event"
            onPoseComplete={this.hideReceiveKeyEvent}
          >
            <Img
              src={KeyWhite}
              width={20}
              height={20}
              alt=""
            />
            <span>{text}</span>
          </Event>
        )}
      </PoseGroup>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSpotlightTarget: target => dispatch(setTarget(target)),
  setSpotlightVisible: visible => dispatch(setVisible(visible)),
});

const mapStateToProps = createStructuredSelector({
  keysCount: makeSelectKeysCount(),
  owlText: makeSelectText(),
  browser: makeSelectBrowser(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(KeyReceiveEvent);
