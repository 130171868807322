import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-tiny-fab/dist/styles.min.css';
import '../../bitrix-chat.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useMedia } from 'the-platform';
import { mapDispatchToProps } from '../../containers/other/Header';
import {
  makeSelectLocation,
} from '../../redux/modules/global/selectors';
import { device } from '../../utils/mediaQueries';

import FabChatDesktop from '../FabChat/Desktop';
import FabChatMobile from '../FabChat/Mobile';

import './style.css';

const ReadomSupport = ({ isAuthenticated, routeState }) => {
  const isMobile = useMedia(device.tablet);
  const pathname = get(routeState, 'pathname', '/');

  const isGamePage = (/\/games\/.*/.test(pathname) && !/\/games\/overview\/.*/.test(pathname));

  return (
    <React.Fragment>
      {!isMobile && <FabChatDesktop isAuthenticated={isAuthenticated} />}
      {isMobile && !isGamePage && <FabChatMobile isAuthenticated={isAuthenticated} />}
    </React.Fragment>
  );
};

ReadomSupport.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  routeState: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  routeState: makeSelectLocation(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(ReadomSupport);
