import styled from 'styled-components';

const Text = styled.span`
  color: ${props => (props.grey ? '#808085' : '#1C1C1C')};
  display: ${props => (props.br ? 'block' : 'inline')};
  font-family: ${props => (props.bold ? '\'Merriweather\', serif' : '\'Helvetica Neue\', Helvetica, Arial, sans-serif')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};;
  font-size: 15px;
  line-height: ${props => (props.minHeight ? '17px' : '20px')};
`;

export default Text;
