export const CHANGE_NAME = 'readom/Profile/CHANGE_NAME';
export const CHANGE_SURNAME = 'readom/Profile/CHANGE_SURNAME';
export const CHANGE_NICKNAME = 'readom/Profile/CHANGE_NICKNAME';
export const CHANGE_EMAIL = 'readom/Profile/CHANGE_EMAIL';
export const CHANGE_DEPARTAMENT = 'readom/Profile/CHANGE_DEPARTAMENT';
export const CHANGE_POSITION = 'readom/Profile/CHANGE_POSITION';
export const CHANGE_PATRONYMIC = 'readom/Profile/CHANGE_PATRONYMIC';
export const CHANGE_SHOW_ME_IN_RATING = 'readom/Profile/CHANGE_SHOW_ME_IN_RATING';

export const PROFILE_REQUEST = 'readom/Profile/PROFILE_REQUEST';
export const PROFILE_REQUEST_SUCCESS = 'readom/Profile/PROFILE_REQUEST_SUCCESS';
export const PROFILE_REQUEST_FAIL = 'readom/Profile/PROFILE_REQUEST_FAIL';

export const PROFILE_REQUEST_CHANGE = 'readom/Profile/PROFILE_REQUEST_CHANGE';
export const PROFILE_REQUEST_CHANGE_SUCCESS = 'readom/Profile/PROFILE_REQUEST_CHANGE_SUCCESS';
export const PROFILE_REQUEST_CHANGE_FAIL = 'readom/Profile/PROFILE_REQUEST_CHANGE_FAIL';

export const PROFILE_CASH_REQUEST = 'readom/Profile/PROFILE_CASH_REQUEST';
export const PROFILE_CASH_REQUEST_SUCCESS = 'readom/Profile/PROFILE_CASH_REQUEST_SUCCESS';
export const PROFILE_CASH_REQUEST_FAIL = 'readom/Profile/PROFILE_CASH_REQUEST_FAIL';

export const PROFILE_RATING_REQUEST = 'readom/Profile/PROFILE_RATING_REQUEST';
export const PROFILE_RATING_REQUEST_SUCCESS = 'readom/Profile/PROFILE_RATING_REQUEST_SUCCESS';
export const PROFILE_RATING_REQUEST_FAIL = 'readom/Profile/PROFILE_RATING_REQUEST_FAIL';

export const USER_PHOTO_REQUEST = 'readom/Profile/USER_PHOTO_REQUEST';
export const USER_PHOTO_REQUEST_SUCCESS = 'readom/Profile/USER_PHOTO_REQUEST_SUCCESS';
export const USER_PHOTO_REQUEST_FAIL = 'readom/Profile/USER_PHOTO_REQUEST_FAIL';
