import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import pure from 'recompose/pure';
import styled from 'styled-components';
import VideoPlayer from '../../../../utils/VideoJS/index';
import WhitePlayButtonSVG from '../../images/play.svg';

const Root = pure(styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`);

const VideoOverlay = pure(styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #232323;
  opacity: 0.3;
  padding: 0;
  margin: 0;
`);

const VideoOverlayPlayButton = pure(styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  background: url(${WhitePlayButtonSVG});
  background-repeat: no-repeat;
  border: none;
  top: 0;
  left: 0;
  background-position: center;
  background-size: 200px;
  z-index: 1;
  padding: 0;
  margin: 0;
    
  @media (max-width: 768px) {
    background-size: 100px;
  }
`);

class Video extends PureComponent {
  static propTypes = {
    trackVideoClick: PropTypes.func.isRequired,
    where: PropTypes.string.isRequired,
  };

  state = {
    video: {
      src: 'https://readom.pro/landing-files/feedback.mp4',
      poster: 'https://readom.pro/video_bg.png',
    },
    showVideoOverlay: true,
  };

  player = {};

  isPlaying = false;

  onPlayerReady = (player) => {
    this.player = player;
    this.player.muted(true);
    this.player.volume(0.7);
    const playPromise = this.player.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        console.info('success start video on auto');
      }).catch(() => {
        console.info('error while start video on auto');
      });
    }

    player.on(['waiting', 'pause'], () => {
      this.isPlaying = false;
    });

    player.on('playing', () => {
      this.isPlaying = true;
    });
  };

  onPlayButtonClick = () => {
    this.setState({
      showVideoOverlay: false,
    });
    this.player.currentTime(0);
    this.player.muted(false);
    this.player.volume(0.7);
    this.player.controls(true);

    this.props.trackVideoClick(this.props.where);

    document.getElementsByClassName('promo__video')[0].classList.add('promo__video--activated');

    if (!this.isPlaying) {
      const playPromise = this.player.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          console.info('success start video on click');
        }).catch(() => {
          console.info('error while start video on click');
        });
      }
    }
  };

  render() {
    const { showVideoOverlay } = this.state;

    return (
      <Root>
        <VideoPlayer
          resizeManager={false}
          controls={false}
          src={this.state.video.src}
          poster={this.state.video.poster}
          dataSetup='{"fluid": true}'
          mutted
          hidePlaybackRates
          onReady={this.onPlayerReady}
        />
        {showVideoOverlay && (
          <>
            <VideoOverlay onClick={this.onPlayButtonClick} />
            <VideoOverlayPlayButton onClick={this.onPlayButtonClick} />
          </>
        )}
      </Root>
    );
  }
}

export default Video;
