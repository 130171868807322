import React from 'react';
import SimplePageBase from '../../PageBase/SimplePageBase';
import Card from '../../../components/Card';
import Content from '../../../components/Content';
import FlexWrapper from '../../../components/FlexWrapper';

class PaymentPage extends React.PureComponent {
  render() {
    const account = [
      { id: 1, title: 'Наименование', value: 'ПАО «Южные Железные Дороги Уругвая и всех прилегающих королевств»' },
      { id: 2, title: 'ИНН', value: '5433954020' },
      { id: 3, title: 'КПП', value: '540901001' },
      { id: 4, title: 'ОГРН', value: '1155476105498' },
      { id: 5, title: 'БИК', value: '045004641' },
      { id: 6, title: 'К/C', value: '30101810500000000641' },
      { id: 7, title: 'Р/С', value: '40702810644050012649' },
    ];

    const tariff = [
      { id: 1, title: 'Тарифный план', value: 'Гибкий' },
      { id: 2, title: 'Пользователи', value: '33' },
      { id: 3, title: 'Игры', value: '6' },
      { id: 4, title: 'Стоимость в мес., $', value: '320' },
      { id: 5, title: 'Дата истечения контракта', value: 'б/к' },
      { id: 6, title: 'Последнее изменение', value: '29.11.2017' },
    ];

    const transactions = [
      { id: 1, title: '01.11.2017', value: '320 $' },
      { id: 2, title: '01.10.2017', value: '320 $' },
      { id: 3, title: '01.09.2017', value: '320 $' },
      { id: 4, title: '01.08.2017', value: '320 $' },
      { id: 5, title: '01.07.2017', value: '320 $' },
      { id: 6, title: '01.06.2017', value: '320 $' },
    ];

    return (
      <SimplePageBase title="Платежная информация">
        <Content grey>
          <FlexWrapper>
            <Card
              items={account}
              title="Расчетный счет"
              to="/change-account-details"
            />
            <Card
              items={tariff}
              title="Тарифный план"
              to="/"
            />
            <Card
              items={transactions}
              title="Транзакции"
              to="/"
            />
          </FlexWrapper>
        </Content>
      </SimplePageBase>
    );
  }
}

export default PaymentPage;
