import styled from 'styled-components';

const PageTitle = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

export default PageTitle;
