import {
  SEND_REQUEST,
  ADD_CARD_TO_QUEUE,
  REMOVE_CARD_FROM_QUEUE,
  SEND_REQUEST_ITERATE_SUCCESS,
  SEND_REQUEST_ITERATE,
} from './constants';

export function addCardToQueue(cardId) {
  return {
    type: ADD_CARD_TO_QUEUE,
    cardId,
  };
}

export function removeCardFromQueue(cardId) {
  return {
    type: REMOVE_CARD_FROM_QUEUE,
    cardId,
  };
}

export function sendRequest() {
  return {
    type: SEND_REQUEST,
  };
}

export function sendRequestIterateSuccess(cardId) {
  return {
    type: SEND_REQUEST_ITERATE_SUCCESS,
    cardId,
  };
}

export function sendRequestIterate() {
  return {
    type: SEND_REQUEST_ITERATE,
  };
}
