import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import pure from 'recompose/pure';
import { NavLink } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { useMedia } from 'the-platform';
import URL_CONSTANTS from '../../urlConstants';
import { device } from '../../utils/mediaQueries';
import LoadingIndicator from '../LoadingIndicator';
import Logo from '../Logo';

import MyGameIcon from './my-game.svg';
import MyEmployersIcon from './my-employers.svg';
import RatingIcon from './rating.svg';
import WorldRatingIcon from './rating-world.svg';
import AboutRatingIcon from './about.svg';
import ArrowIcon from './arrow.svg';

const LeftMenuWrapperStyle = `
  background-color: #343642;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 71px);
  padding-top: 7px;
  position: relative;
  transition: width .3s ease-out;
  user-select: none;
  z-index: 10;
`;

const LeftMenuWrapperStickyBox = styled(StickyBox)`
  ${LeftMenuWrapperStyle}
  width: ${props => (props.isOpened ? '240px' : '65px')};
`;

const LeftMenuWrapper = styled.div`
  ${LeftMenuWrapperStyle}
  width: 280px;
  position: fixed;
  top: 60px;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  width: ${props => (props.isOpened ? '240px' : '0')};
`;

const MainMenu = pure(styled.div`
  background: #343642;
  padding-bottom: 25px;
  position: relative;
  z-index: 9;
`);

const activeClassName = 'left-menu-item-active';

const MenuItem = pure(styled(NavLink).attrs({
  activeClassName,
})`
  background-color: transparent;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  height: 60px;
  line-height: 60px;
  position: relative;
  transition: background .3s ease-out;
  text-decoration: none;
  
   &:visited {
    text-decoration: none;
  }

  &:hover {
    background: #282A33;
  }

  &::before {
    background-color: #24CBA2;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity .3s ease-out;
    width: 10px;
  }
  
   &.${activeClassName} {
    background-color: #282A33;
   }
   
   &.${activeClassName}::before {
    opacity: 1;
   }
`);

const StyledLinkText = pure(styled.div`
  color: #ffffff;
  display: flex;
  text-decoration: none;
  width: 100%;

  &:visited {
    color: #ffffff;
    display: flex;
    text-decoration: none;
  }
`);

const LastMenuItem = pure(styled.div`
  border-top: 1px solid #5B5D66;
  bottom: 0;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  height: 60px;
  position: fixed;
  background: #343641;
  width: ${props => (props.isOpened ? '240px' : '65px')};
  transition: background, width .3s ease-out;

  &:hover {
    background: #282A33;
  }
`);

const MenuItemTextAnimation = posed.span({
  open: {
    opacity: 1,
    applyAtStart: { display: 'inline' },
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
  },
});

const MenuItemText = pure(styled(MenuItemTextAnimation)`
  color: #ffffff;
  flex-shrink: 0;
  margin-left: 5px;
  position: relative;
  text-decoration: none;
  transition: opacity .3s ease-out;
  width: 170px;
`);

const Icon = pure(styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  width: 65px;
`);

const ToggleIcon = pure(styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  transform: ${props => (props.isOpened ? 'rotateY(0)' : 'rotateY(180deg)')};
  transform-style: preserve-3d;
  transition: transform .3s ease-out;
  width: 65px;
`);

const LoadingWrapper = pure(styled.div`
  width: ${props => (props.isOpened ? '240px' : '65px')};
`);

const LogoWrapper = pure(styled.div`
  position: relative;
  height: 70px;
  border-bottom: 1px solid #5B5D65;
  padding-left: 20px;
`);

const Version = pure(styled.span`
  color: #53545a;
  font-size: 13px;
  opacity: ${props => (props.shown ? 1 : 0)};
  transition: opacity .3s ease-out;
  white-space: nowrap;
  word-break: keep-all;
  width: 100px;
`);

const VersionOnDesktop = pure(styled(Version)`
  position: absolute;
  top: -25px;
  left: 23px;
`);

const VersionOnMobile = pure(styled(Version)`
  position: absolute;
  top: calc(var(--vh,1vh) * 100 - 90px);
  left: 23px;
  z-index: 10;
`);

const Overlay = pure(styled.div`
  z-index: 9;
  background-color: rgba(0, 0, 0, .5);
  left: 0;
  height: 100vh;
  opacity: 0.99;
  position: fixed;
  top: 0;
  width: ${props => (props.scrollbarWidth && props.scrollbarWidth > 0 ? `calc(100vw - ${props.scrollbarWidth}px)` : '100vw')};
`);

const LeftMenu = (props) => {
  const small = useMedia(device.toggleMenu);

  const onResize = () => {
    if (small && props.isOpened) {
      props.toggleLeftMenu();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
    }

    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
      }
    };
  });

  const onMainMenuClick = () => {
    const {
      isOpened,
      toggleLeftMenu,
    } = props;

    if (small && isOpened) {
      toggleLeftMenu();
    }
  };

  const {
    isOpened,
    isEmployer,
    isEmployerAdmin,
    isLoading,
    toggleLeftMenu,
    canViewRating,
  } = props;

  const overlayRef = React.createRef();

  const TopWrapper = small ? LeftMenuWrapper : LeftMenuWrapperStickyBox;
  const Wrapper = small ? React.Fragment : StickyBox;

  const WrapperProps = {};
  if (!small) {
    WrapperProps.offsetTop = 78;
  }

  return (
    <React.Fragment>
      {small && isOpened && <Overlay ref={overlayRef} onClick={toggleLeftMenu} />}
      <TopWrapper isOpened={isOpened} small={small}>
        <Wrapper {...WrapperProps}>
          <MainMenu onClick={onMainMenuClick}>
            {isLoading && <LoadingWrapper isOpened={isOpened}><LoadingIndicator /></LoadingWrapper>}
            {!isLoading && (
              <React.Fragment>
                {small && (
                  <LogoWrapper>
                    <Logo />
                  </LogoWrapper>
                )}
                <MenuItem
                  to={URL_CONSTANTS.URLS.GAMES_STORE}
                  exact
                >
                  <StyledLinkText>
                    <Icon>
                      <img src={MyGameIcon} alt="Мои игры" />
                    </Icon>
                    <MenuItemText pose={isOpened || small ? 'open' : 'hidden'}>
                      Мои игры
                    </MenuItemText>
                  </StyledLinkText>
                </MenuItem>
                {isEmployer && isEmployerAdmin && (
                  <MenuItem
                    to={URL_CONSTANTS.URLS.COMPANY_EMPLOYEES}
                  >
                    <StyledLinkText>
                      <Icon>
                        <img src={MyEmployersIcon} alt="Мои сотрудники" />
                      </Icon>
                      <MenuItemText pose={isOpened || small ? 'open' : 'hidden'}>
                        Мои сотрудники
                      </MenuItemText>
                    </StyledLinkText>
                  </MenuItem>
                )}
                {isEmployer && canViewRating && (
                  <MenuItem to="/rating">
                    <StyledLinkText>
                      <Icon>
                        <img src={RatingIcon} alt="Рейтинги в компании" />
                      </Icon>
                      <MenuItemText pose={isOpened || small ? 'open' : 'hidden'}>
                        Рейтинги в компании
                      </MenuItemText>
                    </StyledLinkText>
                  </MenuItem>
                )}
                <MenuItem to={URL_CONSTANTS.URLS.COMMON_RATING}>
                  <StyledLinkText>
                    <Icon>
                      <img src={WorldRatingIcon} alt="Рейтинги в мире" />
                    </Icon>
                    <MenuItemText pose={isOpened || small ? 'open' : 'hidden'}>
                      Рейтинги в мире
                    </MenuItemText>
                  </StyledLinkText>
                </MenuItem>
                <MenuItem to={URL_CONSTANTS.URLS.ABOUT}>
                  <StyledLinkText>
                    <Icon>
                      <img src={AboutRatingIcon} alt="О проекте" />
                    </Icon>
                    <MenuItemText pose={isOpened || small ? 'open' : 'hidden'}>
                      О проекте
                    </MenuItemText>
                  </StyledLinkText>
                </MenuItem>
              </React.Fragment>
            )}
          </MainMenu>
        </Wrapper>

        {!small && (
          <LastMenuItem
            isOpened={isOpened}
            onClick={toggleLeftMenu}
          >
            <VersionOnDesktop shown={isOpened}>{`0.${process.env.REACT_APP_VERSION}`}</VersionOnDesktop>
            <ToggleIcon isOpened={isOpened}>
              <img src={ArrowIcon} alt="" />
            </ToggleIcon>
          </LastMenuItem>
        )}
        {small && <VersionOnMobile shown>{`0.${process.env.REACT_APP_VERSION}`}</VersionOnMobile>}
      </TopWrapper>
    </React.Fragment>
  );
};

LeftMenu.propTypes = {
  canViewRating: PropTypes.bool.isRequired,
  isEmployer: PropTypes.bool.isRequired,
  isEmployerAdmin: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  toggleLeftMenu: PropTypes.func.isRequired,
};

LeftMenu.defaultProps = {
  isLoading: false,
};

export default LeftMenu;
