import React from 'react';
import PropTypes from 'prop-types';
import Typograf from 'typograf';
import scrollLock from '../../../../utils/scrollLock';

import Button from '../../../useThis/Button';
import ButtonText from '../styled/ButtonText';

import Text from '../styled/Text';
import Buttons from '../styled/Buttons';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

class OwlStepContent extends React.PureComponent {
  static defaultProps = {
    stepGroupNumber: null,
    steps: null,
    showcaseId: null,
  };

  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setOwlVisible: PropTypes.func.isRequired,
    receiveOwlMessage: PropTypes.func.isRequired,
    setSpotlightTarget: PropTypes.func.isRequired,
    setSpotlightVisible: PropTypes.func.isRequired,
    setStartTutorialTime: PropTypes.func.isRequired,
    onGameBuy: PropTypes.func.isRequired,
    trackBuyGameButtonClicked: PropTypes.func.isRequired,
    setShowLibraryPopup: PropTypes.func.isRequired,
    setActiveCardId: PropTypes.func.isRequired,
    setFinishTutorialTime: PropTypes.func.isRequired,
    setOwlCurrentMessageShown: PropTypes.func.isRequired,
    setShowActionButtonsOnMobile: PropTypes.func.isRequired,
    stepGroupNumber: PropTypes.string,
    steps: PropTypes.array,
    small: PropTypes.bool.isRequired,
    showcaseId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      finish: false,
    };

    const now = Math.round(new Date().getTime() / 1000);
    props.setStartTutorialTime(now);

    try {
      this.updateContent(this.state, props);
    } catch (e) {
      const {
        setSpotlightTarget, setSpotlightVisible, receiveOwlMessage, setOwlCurrentMessageShown, setOwlVisible,
      } = props;

      scrollLock.off();
      setOwlVisible(false);
      receiveOwlMessage('');
      setOwlCurrentMessageShown(true);
      setSpotlightTarget(null);
      setSpotlightVisible(false);
    }

    if (!props.isVisible) {
      props.setOwlVisible(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { small } = this.props;

    if (small && !nextProps.isVisible && this.props.isVisible) {
      setTimeout(() => {
        this.props.setShowActionButtonsOnMobile(false);
      }, 1000);
    }

    if (nextProps.stepGroupNumber !== this.props.stepGroupNumber) {
      this.setState({
        activeStep: 0,
        finish: false,
      });

      if (!this.props.isVisible) {
        this.props.setOwlVisible(true);
      }

      const now = Math.round(new Date().getTime() / 1000);
      this.props.setStartTutorialTime(now);
    }
  }

  componentDidUpdate() {
    try {
      this.updateContent(this.state, this.props);
    } catch (e) {
      const {
        setSpotlightTarget, setSpotlightVisible, receiveOwlMessage, setOwlCurrentMessageShown, setOwlVisible,
      } = this.props;

      scrollLock.off();
      setOwlVisible(false);
      receiveOwlMessage('');
      setOwlCurrentMessageShown(true);
      setSpotlightTarget(null);
      setSpotlightVisible(false);
    }
  }

  updateContent = (state, props) => {
    const { activeStep, finish } = state;
    const {
      setSpotlightTarget, setSpotlightVisible, stepGroupNumber, small,
    } = props;

    if (finish) {
      scrollLock.off();
      this.props.receiveOwlMessage('');
      props.setOwlCurrentMessageShown(true);
      setSpotlightTarget(null);
      setSpotlightVisible(false);
    }

    if (!finish && stepGroupNumber === 'start-million-trial') {
      if (activeStep === 0) {
        if (this.props.isVisible) {
          scrollLock.on();
        }

        const demoGameId = this.props.cookies.get('demoGameId');

        setSpotlightTarget(`#game-card-${demoGameId.replace('{', '').replace('}', '')}`);
        setSpotlightVisible(true);
        const startGameOnCard = (event) => {
          this.props.setActiveGameId(demoGameId);
          this.props.startNewGame();
          this.onButtonClick(event);
        };

        const startGameOnclick = function (event) {
          startGameOnCard(event);
          this.onclick = null;
        };

        let interval = null;
        interval = setInterval(() => {
          const spot = document.getElementsByClassName('SpotLightOverlay__spotlight')[0];
          if (spot) {
            spot.onclick = startGameOnclick;
            clearInterval(interval);
          }
        }, 100);
      }
    }

    if (finish && stepGroupNumber === 'start-million-trial') {
      if (this.props.isVisible) {
        scrollLock.off();
        const demoGameId = this.props.cookies.get('demoGameId');
        this.props.setActiveGameId(demoGameId);
        this.props.startNewGame();
      }
    }

    if (!finish && stepGroupNumber === '1-tutor-b2b') {
      if (activeStep === 0) {
        if (this.props.isVisible) {
          scrollLock.on();
        }
        document.querySelectorAll('[class^="tour-step"]').forEach((item) => {
          item.classList.add('hidden-ui');
        });

        setSpotlightTarget(null);
        setSpotlightVisible(true);
        if (document.querySelector('.on-game-mobile-toggle-button')) {
          document.querySelector('.on-game-mobile-toggle-button').classList.add('hidden-ui');
        }
      }

      if (activeStep === 1) {
        // показываем основной блок текста
        document.getElementsByClassName('tour-step-1')[0].classList.remove('hidden-ui');
        setSpotlightVisible(true);
        setSpotlightTarget('.tour-step-1');
      }

      if (activeStep === 2) {
        // показываем варианты ответов
        document.getElementsByClassName('tour-step-2')[0].classList.remove('hidden-ui');
        setSpotlightVisible(true);
        setSpotlightTarget('.tour-step-2');
      }

      if (activeStep === 3) {
        // показываем настрой на сделку
        document.getElementsByClassName('tour-step-mood')[0].classList.remove('hidden-ui');

        setSpotlightTarget('.tour-step-mood');
        setSpotlightVisible(true);
      }

      if (activeStep === 4) {
        // показываем корридор сделки
        document.getElementsByClassName('tour-step-contract-range')[0].classList.remove('hidden-ui');
        setSpotlightTarget('.tour-step-contract-range');
        setSpotlightVisible(true);
      }
    }

    if (!finish && stepGroupNumber === '2-tutor-b2b') {
      if (activeStep === 0) {
        setSpotlightVisible(true);
        // показываем ключики
        if (small) {
          if (document.getElementsByClassName('tour-step-keys-mobile')[0]) {
            document.getElementsByClassName('tour-step-keys-mobile')[0].classList.remove('hidden-ui');
          }
        } else if (document.getElementsByClassName('tour-step-keys-desktop')[0]) {
          document.getElementsByClassName('tour-step-keys-desktop')[0].classList.remove('hidden-ui');
        }

        if (small) {
          setSpotlightTarget('.key-received-event');
          setSpotlightVisible(true);
        } else {
          setSpotlightVisible(true);

          setTimeout(() => {
            setSpotlightTarget('.tour-step-keys-desktop');
          }, 1000);
        }
      }

      if (activeStep === 1) {
        // показываем библиотеку
        document.getElementsByClassName('tour-step-library')[0].classList.remove('hidden-ui');
        if (small) {
          if (document.getElementsByClassName('on-game-mobile-toggle-button')[0]) {
            document.getElementsByClassName('on-game-mobile-toggle-button')[0].classList.remove('hidden-ui');
          }
          setSpotlightVisible(true);
          setTimeout(() => {
            setSpotlightTarget('.on-game-mobile-toggle-button');
            const keysEvent = document.getElementsByClassName('key-received-event');
            if (keysEvent && keysEvent[0]) {
              keysEvent[0].classList.add('hidden-ui');
            }
          }, 500);

          setTimeout(() => {
            this.props.setShowActionButtonsOnMobile(true);
            setSpotlightTarget('.tour-step-library');
          }, 1600);
        } else {
          const keysEvent = document.getElementsByClassName('key-received-event');
          if (keysEvent && keysEvent[0]) {
            keysEvent[0].classList.add('hidden-ui');
          }
          setSpotlightTarget('.tour-step-library');
          setSpotlightVisible(true);
        }
      }
    }

    if (!finish && stepGroupNumber === '1-tutor-demo') {
      if (activeStep === 0) {
        if (this.props.isVisible) {
          scrollLock.on();
        }

        document.querySelectorAll('[class^="tour-step"]').forEach((item) => {
          item.classList.add('hidden-ui');
        });

        setSpotlightTarget(null);
        setSpotlightVisible(true);
        if (document.querySelector('.on-game-mobile-toggle-button')) {
          document.querySelector('.on-game-mobile-toggle-button').classList.add('hidden-ui');
        }
        // показываем основной блок текста
        setSpotlightVisible(true);

        if (document.getElementsByClassName('tour-step-1')[0]) {
          document.getElementsByClassName('tour-step-1')[0].classList.remove('hidden-ui');
          setSpotlightTarget('.tour-step-1');
        } else {
          const interval = setInterval(() => {
            if (document.getElementsByClassName('tour-step-1')[0]) {
              document.getElementsByClassName('tour-step-1')[0].classList.remove('hidden-ui');
              setSpotlightTarget('.tour-step-1');
              clearInterval(interval);
            }
          }, 100);
        }
      }

      if (activeStep === 1) {
        // показываем варианты ответов
        document.getElementsByClassName('tour-step-2')[0].classList.remove('hidden-ui');
        setSpotlightVisible(true);
        setSpotlightTarget('.tour-step-2');
      }
    }

    if (!finish && stepGroupNumber === '2-tutor-demo') {
      if (activeStep === 0) {
        // показываем настрой на сделку
        document.getElementsByClassName('tour-step-mood')[0].classList.remove('hidden-ui');

        setSpotlightTarget('.tour-step-mood');
        setSpotlightVisible(true);
      }

      if (activeStep === 1) {
        // показываем корридор сделки
        document.getElementsByClassName('tour-step-contract-range')[0].classList.remove('hidden-ui');
        setSpotlightTarget('.tour-step-contract-range');
        setSpotlightVisible(true);
      }
    }

    if (!finish && stepGroupNumber === '3-tutor-demo') {
      if (activeStep === 0) {
        setSpotlightTarget(null);
        setSpotlightVisible(true);
        // показываем библиотеку
        document.getElementsByClassName('tour-step-library')[0].classList.remove('hidden-ui');
        if (small) {
          if (document.getElementsByClassName('on-game-mobile-toggle-button')[0]) {
            document.getElementsByClassName('on-game-mobile-toggle-button')[0].classList.remove('hidden-ui');
          }
          document.getElementsByClassName('on-game-mobile-toggle-button')[0].classList.remove('hidden-ui');

          setTimeout(() => {
            setSpotlightTarget('.on-game-mobile-toggle-button');
          }, 100);

          setTimeout(() => {
            this.props.setShowActionButtonsOnMobile(true);
            setSpotlightTarget('.tour-step-library');
          }, 1100);
        } else {
          setSpotlightTarget('.tour-step-library');
        }

        this.props.getCardsAll();
      }

      if (activeStep === 1) {
        // показываем подсказки
        document.getElementsByClassName('tour-step-tips')[0].classList.remove('hidden-ui');
        setSpotlightTarget('.tour-step-tips');
      }
    }

    if (!finish && stepGroupNumber === '4-tutor-demo') {
      document.getElementsByClassName('tour-step-stages')[0].classList.remove('hidden-ui');
      // показываем шкалу стадии продаж
      if (small) {
        document.getElementsByClassName('tour-step-11')[0].classList.remove('hidden-ui');
        setSpotlightVisible(true);
        setTimeout(() => {
          setSpotlightTarget('.on-game-mobile-toggle-button');
        }, 100);

        setTimeout(() => {
          this.props.setShowActionButtonsOnMobile(true);
        }, 1000);

        setTimeout(() => {
          setSpotlightTarget('.tour-step-stages');
        }, 1500);
      } else {
        setSpotlightVisible(true);
        setSpotlightTarget('.tour-step-stages');
      }
    }

    if (!finish && stepGroupNumber === '3-tutor-b2b') {
      if (activeStep === 0) {
        setSpotlightTarget(null);
        setSpotlightVisible(true);
        // показываем подсказки
        document.getElementsByClassName('tour-step-tips')[0].classList.remove('hidden-ui');
        if (small) {
          if (document.getElementsByClassName('on-game-mobile-toggle-button')[0]) {
            document.getElementsByClassName('on-game-mobile-toggle-button')[0].classList.remove('hidden-ui');
          }
          document.getElementsByClassName('on-game-mobile-toggle-button')[0].classList.remove('hidden-ui');

          setTimeout(() => {
            setSpotlightTarget('.on-game-mobile-toggle-button');
          }, 100);

          setTimeout(() => {
            this.props.setShowActionButtonsOnMobile(true);
            setSpotlightTarget('.tour-step-tips');
          }, 1100);
        } else {
          setSpotlightTarget('.tour-step-tips');
        }

        this.props.getCardsAll();
      }
    }

    if (!finish && stepGroupNumber === '4-tutor-b2b') {
      // тут можно купить игру, сейчас вроде не актуально
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '1-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '2-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '3-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '4-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '5-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '5.1-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '6-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '7-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '8-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '9-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '11-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '12-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '13-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '14-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '15-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '16-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '17-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }
    if (!finish && stepGroupNumber === '18-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }
    if (!finish && stepGroupNumber === '19-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '20-utp') {
      setSpotlightTarget(null);
      setSpotlightVisible(true);
    }

    if (!finish && stepGroupNumber === '5-tutor-b2b') {
      document.getElementsByClassName('tour-step-stages')[0].classList.remove('hidden-ui');
      // показываем шкалу стадии продаж
      if (small) {
        document.getElementsByClassName('tour-step-11')[0].classList.remove('hidden-ui');
        setSpotlightVisible(true);
        setTimeout(() => {
          setSpotlightTarget('.on-game-mobile-toggle-button');
        }, 100);

        setTimeout(() => {
          this.props.setShowActionButtonsOnMobile(true);
        }, 1000);

        setTimeout(() => {
          setSpotlightTarget('.tour-step-stages');
        }, 1500);
      } else {
        setSpotlightVisible(true);
        setSpotlightTarget('.tour-step-stages');
      }
    }
  };

  onButtonClick = (event) => {
    const { activeStep } = this.state;
    const { steps } = this.props;
    if (activeStep + 1 < steps.length) {
      event.stopPropagation();
      this.setState({
        activeStep: activeStep + 1,
      });
    } else {
      const { setFinishTutorialTime, stepGroupNumber } = this.props;
      this.setState({
        finish: true,
      }, () => {
        if (stepGroupNumber === '4-tutor-demo') {
          this.props.setActiveCardId('{86D1B7A1-6990-D243-A9DB-BE0397DFC0C2}');
          this.props.setShowLibraryPopup(true);
        }

        const now = Math.round(new Date().getTime() / 1000);
        setFinishTutorialTime(now, stepGroupNumber);
        if (this.props.isVisible) {
          this.props.setOwlVisible(false);
          this.props.setOwlCurrentMessageShown(true);
        }
      });
    }
  };

  onGameBuyClick = (event) => {
    this.onButtonClick(event);
    this.props.trackBuyGameButtonClicked(null, 'owl-tour', true);
    this.props.onGameBuy();
  };

  renderButtons = (step, isLastStep) => {
    const { buttonText, buyGame } = step;

    if (!buttonText && !buyGame) {
      return null;
    }

    return (
      <Buttons>
        {buttonText && buttonText.length > 0 && (
          <Button
            key={buttonText}
            isWhite={isLastStep}
            onClick={this.onButtonClick}
          >
            <ButtonText>
              {buttonText}
            </ButtonText>
          </Button>
        )}
        {buyGame && buyGame.text && (
          <Button
            key={buyGame.text}
            onClick={this.onGameBuyClick}
          >
            <ButtonText>
              {buyGame.text}
            </ButtonText>
          </Button>
        )}
      </Buttons>
    );
  };

  render() {
    const { steps } = this.props;
    const { activeStep } = this.state;
    const isLastStep = steps.length - 1 === activeStep;
    const step = steps[activeStep];
    if (!step) {
      return null;
    }

    const { text } = step;

    return (
      <>
        {text && (
          <Text dangerouslySetInnerHTML={{ __html: tp.execute(text) }} />
        )}
        {this.renderButtons(step, isLastStep)}
      </>
    );
  }
}

export default OwlStepContent;
