import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import {
  EDIT_EMPLOYEE_REQUEST,
} from '../../../modules/employees/constants';

import { makeSelectApiUrl } from '../../../modules/global/selectors';
import {
  makeSelectEmployeeForEdit,
} from '../../../modules/employees/selectors';

import {
  getDepartmentList,
  changeEditEmployeePopupState,
  changeActiveTab,
  editEmployeeSuccess,
  editEmployeeFail,
} from '../../../modules/employees/actions';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* editEmployeeSaga() {
    const url = yield select(makeSelectApiUrl());
    const employee = yield select(makeSelectEmployeeForEdit());

    const requestURL = `${url}/EmployeeEdit/${encodeURIComponent(employee.id)}/${encodeURIComponent(employee.surname)}/${encodeURIComponent(employee.name)}/${encodeURIComponent(employee.patronymic)}/${encodeURIComponent(employee.email)}`;

    try {
      yield call(apiRequest, requestURL);

      yield put(editEmployeeSuccess());
    } catch (err) {
      yield put(editEmployeeFail());
    } finally {
      yield put(changeEditEmployeePopupState(false));
      yield put(getDepartmentList());
      yield put(changeActiveTab({
        activeTab: 'tabItem_1',
      }));
    }
  }

  function* saga() {
    yield takeLeading(EDIT_EMPLOYEE_REQUEST, editEmployeeSaga);
  }

  return saga;
}

export default build;
