import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isNull from 'lodash/isNull';
import FormLine from '../../../FormikForms/EmployeeForm/FormLine';
import { validator } from '../../../utils/validation';

import Error from '../../../components/Error';
import Input from '../../../components/Input';
import FormItem from '../templates/PopupForm/FormItem';
import Popup from '../../../components/Popup';

import {
  changeEmployeeForEdit,
  editEmployee,
} from '../../../redux/modules/employees/actions';
import {
  makeSelectEmployeeForEdit,
  makeSelectEmployeeEditPopup,
} from '../../../redux/modules/employees/selectors';

import Form from './Form';

class EditEmployeeForm extends React.PureComponent {
  state = {
    name: isNull(this.props.employee.name) ? '' : this.props.employee.name,
    nameValid: true,
    surname: isNull(this.props.employee.surname) ? '' : this.props.employee.surname,
    surnameValid: true,
    email: isNull(this.props.employee.email) ? '' : this.props.employee.email,
    emailValid: true,
    patronymic: isNull(this.props.employee.patronymic) ? '' : this.props.employee.patronymic,
    patronymicValid: true,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: isNull(nextProps.employee.name) ? '' : nextProps.employee.name,
      surname: isNull(nextProps.employee.surname) ? '' : nextProps.employee.surname,
      email: isNull(nextProps.employee.email) ? '' : nextProps.employee.email,
      patronymic: isNull(nextProps.employee.patronymic) ? '' : nextProps.employee.patronymic,
    });
  }

  changeName = (e) => {
    this.setState({
      name: e.target.value,
      nameValid: true,
    });
  };

  changeSurname = (e) => {
    this.setState({
      surname: e.target.value,
      surnameValid: true,
    });
  };

  changePatronymic = (e) => {
    this.setState({
      patronymic: e.target.value,
      patronymicValid: true,
    });
  };

  changeEmail = (e) => {
    this.setState({
      email: e.target.value,
      emailValid: true,
    });
  };

  handlerSubmitForm = () => {
    let isValid = true;

    if (!validator.required(this.state.name)) {
      this.setState({
        nameValid: false,
      });

      isValid = false;
    }

    if (!validator.required(this.state.surname)) {
      this.setState({
        surnameValid: false,
      });

      isValid = false;
    }

    if (!validator.email(this.state.email)) {
      this.setState({
        emailValid: false,
      });

      isValid = false;
    }

    if (isValid) {
      this.props.onChangeEmployee({
        id: this.props.employee.id,
        name: this.state.name,
        surname: this.state.surname,
        patronymic: this.state.patronymic,
        email: this.state.email,
      });

      this.props.onSubmitForm();
    }
  };

  cancelSubmitForm = () => {
    this.setState({
      name: isNull(this.props.employee.name) ? '' : this.props.employee.name,
      nameValid: true,
      surname: isNull(this.props.employee.surname) ? '' : this.props.employee.surname,
      surnameValid: true,
      email: isNull(this.props.employee.email) ? '' : this.props.employee.email,
      emailValid: true,
      patronymic: isNull(this.props.employee.patronymic) ? '' : this.props.employee.patronymic,
      patronymicValid: true,
    });

    this.props.onClose();
  };

  render() {
    const nameError = this.state.nameValid ? '' : 'Поле обязательно для заполнения';
    const emailError = this.state.emailValid ? '' : 'Убедитесь, что заполнено верно';
    const surnameError = this.state.surnameValid ? '' : 'Поле обязательно для заполнения';
    const patronymicError = this.state.patronymicValid ? '' : 'Поле обязательно для заполнения';

    return (
      <Popup
        isActive={this.props.popupState}
        onClose={this.cancelSubmitForm}
        onSubmit={this.handlerSubmitForm}
        title="Изменить сотрудника"
      >
        <Form>
          <FormLine>
            <FormItem>
              <Input
                isValid={this.state.emailValid}
                type="text"
                onChange={this.changeEmail}
                value={this.state.email}
                placeholder="Email"
              />
              <Error>{emailError}</Error>
            </FormItem>
            <FormItem>
              <Input
                isValid={this.state.surnameValid}
                type="text"
                onChange={this.changeSurname}
                value={this.state.surname}
                placeholder="Фамилия"
              />
              <Error>{surnameError}</Error>
            </FormItem>
          </FormLine>
          <FormLine>
            <FormItem>
              <Input
                isValid={this.state.nameValid}
                type="text"
                onChange={this.changeName}
                value={this.state.name}
                placeholder="Имя"
              />
              <Error>{nameError}</Error>
            </FormItem>
            <FormItem>
              <Input
                isValid={this.state.patronymicValid}
                type="text"
                onChange={this.changePatronymic}
                value={this.state.patronymic}
                placeholder="Отчество"
              />
              <Error>{patronymicError}</Error>
            </FormItem>
          </FormLine>
        </Form>
      </Popup>
    );
  }
}

EditEmployeeForm.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    patronymic: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onChangeEmployee: PropTypes.func,
  onSubmitForm: PropTypes.func,
  popupState: PropTypes.bool,
};

export function mapDispatchToProps(dispatch) {
  return {
    onChangeEmployee: result => dispatch(changeEmployeeForEdit(result)),
    onSubmitForm: () => dispatch(editEmployee()),
  };
}

const mapStateToProps = createStructuredSelector({
  popupState: makeSelectEmployeeEditPopup(),
  employee: makeSelectEmployeeForEdit(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(EditEmployeeForm);
