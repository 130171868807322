import styled from 'styled-components';

const BigText = styled.p`
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 30px;
  line-height: 35px;
  margin: 25px 0;
  padding: 25px 0;
  ${props => (props.small && `
    font-size: 20px;
    line-height: normal;
  `)};
`;

export default BigText;
