import { createSelector } from 'reselect';

const selectTutorialState = state => state.get('tutorial');

const makeSelectTutorialStartTime = () => createSelector(
  selectTutorialState,
  state => state.get('startTime'),
);

const makeSelectTutorialFinishTime = () => createSelector(
  selectTutorialState,
  state => state.get('finishTime'),
);

export {
  makeSelectTutorialStartTime,
  makeSelectTutorialFinishTime,
};
