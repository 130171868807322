export const STORE_NAME = 'buyGame';

export const BUY_GAME_STAGE_1 = 'readom/buyGame/BUY_GAME_STAGE_1';
export const BUY_GAME_STAGE_2 = 'readom/buyGame/BUY_GAME_STAGE_2';
export const BUY_GAME_STAGE_3_6 = 'readom/buyGame/BUY_GAME_STAGE_3_6';

export const BUY_GAME_STAGE_SO = 'readom/buyGame/BUY_GAME_STAGE_SO';

export const GET_GAME_STAGE = 'readom/buyGame/GET_GAME_STAGE';
export const GET_GAME_STAGE_SUCCESS = 'readom/buyGame/GET_GAME_STAGE_SUCCESS';
export const GET_GAME_STAGE_FAIL = 'readom/buyGame/GET_GAME_STAGE_FAIL';
