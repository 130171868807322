import {
  SET_SHOW_LIBRARY_POPUP,
  SET_ACTIVE_CARD_ID,
  SET_ACTIVE_CARD_GROUP_ID,
  SET_ACTIVE_LIBRARY_LINK,
} from './constants';

export function setShowLibraryPopup(showLibraryPopup) {
  return {
    type: SET_SHOW_LIBRARY_POPUP,
    payload: { showLibraryPopup },
  };
}

export function setActiveCardId(activeCardId) {
  return {
    type: SET_ACTIVE_CARD_ID,
    payload: { activeCardId },
  };
}

export function setActiveCardGroupId(activeCardGroupId) {
  return {
    type: SET_ACTIVE_CARD_GROUP_ID,
    payload: { activeCardGroupId },
  };
}

export function setActiveLibraryLink(activeLibraryLink) {
  return {
    type: SET_ACTIVE_LIBRARY_LINK,
    payload: { activeLibraryLink },
  };
}
