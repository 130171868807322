import {
  CREATE_CHANNEL_REQUEST,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_FAIL,
  CHANNEL_TEXT_REQUEST,
  CHANNEL_TEXT_SUCCESS,
  CHANNEL_TEXT_FAIL,
  SET_OWL_VISIBLE,
  SET_OWL_IN_CENTER,
  SET_OWL_CURRENT_MESSAGE_SHOWN,
  START_OWL_LISTEN,
  RECEIVE_OWL_MESSAGE,
} from './constants';

export function createChannel() {
  return {
    type: CREATE_CHANNEL_REQUEST,
  };
}

export function createChannelSuccess(payload) {
  return {
    type: CREATE_CHANNEL_SUCCESS,
    ...payload,
  };
}

export function createChannelFail() {
  return {
    type: CREATE_CHANNEL_FAIL,
  };
}

export function channelText() {
  return {
    type: CHANNEL_TEXT_REQUEST,
  };
}

export function startOwlListen() {
  return {
    type: START_OWL_LISTEN,
  };
}

export function receiveOwlMessage(text) {
  return {
    type: RECEIVE_OWL_MESSAGE,
    payload: {
      text,
    },
  };
}

export function channelTextSuccess(payload) {
  return {
    type: CHANNEL_TEXT_SUCCESS,
    ...payload,
  };
}

export function setOwlVisible(owlVisible) {
  return {
    type: SET_OWL_VISIBLE,
    owlVisible,
  };
}

export function setOwlInCenter(owlInCenter) {
  return {
    type: SET_OWL_IN_CENTER,
    owlInCenter,
  };
}

export function setOwlCurrentMessageShown(currentMessageShown) {
  return {
    type: SET_OWL_CURRENT_MESSAGE_SHOWN,
    currentMessageShown,
  };
}

export function channelTextFail() {
  return {
    type: CHANNEL_TEXT_FAIL,
  };
}
