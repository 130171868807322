import styled from 'styled-components';

const AdditionalRow = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 15px;
  margin: 10px;
  width: 10%;
`;

export default AdditionalRow;
