import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Formik, Field, ErrorMessage,
} from 'formik';
import Error from '../../../../components/Error';
import NormalText from '../../../../components/Text';
import SmallPopup from '../../../../components/SmallPopup';
import { validator } from '../../../../utils/validation';
import FormItem from '../../../forms/templates/PopupForm/FormItem';
import FormLine from '../../../forms/templates/PopupForm/FormLine';
import PopupForm from '../../../forms/templates/PopupForm/Form';
import Input from '../../../../components/Input';

const PopupText = styled(NormalText)`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

const ErrorWrapper = styled.div`
  position: absolute;
`;

const FormLineNoMarginBottom = styled(FormLine)`
  margin-bottom: 0;
`;

const renderField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const isValid = !errors[field.name];

  return (
    <FormItem>
      <Input isValid={isValid} {...field} {...props} />
      <ErrorWrapper>
        <Error>
          <ErrorMessage name={field.name} component="span" />
        </Error>
      </ErrorWrapper>
    </FormItem>
  );
};

const FormikSetUserEmailForm = (
  {
    values,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    ...rest
  },
) => (
  <SmallPopup
    small={rest.small}
    title="Укажите E-mail"
    cancelText="Отмена"
    pending={rest.pending}
    submitText="OK"
    isActive={rest.open}
    onClose={rest.onClose}
    onSubmit={handleSubmit}
  >
    <PopupText>
      <div>
        <p>
            Для покупки игр, необходимо указать персональный E-mail.
        </p>
        <PopupForm>
          <FormLineNoMarginBottom>
            <Field
              name="email"
              type="text"
              disabled={rest.pending}
              placeholder="E-mail"
              component={renderField}
            />
          </FormLineNoMarginBottom>
        </PopupForm>
      </div>
    </PopupText>
  </SmallPopup>
);

class SetUserEmailPopup extends React.PureComponent {
  onSubmit = (values, {
    setSubmitting, setErrors, setStatus, resetForm,
  }) => {
    const email = values.email.trim().toLowerCase();
    this.props.onSubmit(email);
    setSubmitting(false);
  };

  onValidate = (values) => {
    const errors = {};

    if (!values.email || values.email.trim() === '') {
      errors.email = 'Поле обязательно для заполнения';
    }

    if (values.email && !validator.email(values.email)) {
      errors.email = 'Убедитесь, что заполнено верно';
    }

    return errors;
  };

  render() {
    return (
      <Formik
        validate={this.onValidate}
        onSubmit={this.onSubmit}
      >
        {props => (
          <FormikSetUserEmailForm
            {...props}
            pending={this.props.pending}
            small={this.props.small}
            open={this.props.open}
            onClose={this.props.onClose}
          />
        )}
      </Formik>
    );
  }
}

SetUserEmailPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SetUserEmailPopup;
