import { fromJS } from 'immutable';

import {
  CREATE_CHANNEL_REQUEST,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_FAIL,
  CHANNEL_TEXT_REQUEST,
  CHANNEL_TEXT_SUCCESS,
  CHANNEL_TEXT_FAIL,
  SET_OWL_VISIBLE,
  SET_OWL_IN_CENTER,
  SET_OWL_CURRENT_MESSAGE_SHOWN, RECEIVE_OWL_MESSAGE,
} from './constants';

const initialState = fromJS({
  id: -1,
  loading: false,
  isVisible: false,
  currentMessageShown: false,
  owlInCenter: false,
  result: false,
  text: '',
});

function owlReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OWL_CURRENT_MESSAGE_SHOWN:
      return state.set('currentMessageShown', action.currentMessageShown);
    // case CREATE_CHANNEL_REQUEST:
    //   return state.set('loading', true);
    case SET_OWL_IN_CENTER:
      return state.set('owlInCenter', action.owlInCenter);
    case SET_OWL_VISIBLE:
      return state.set('isVisible', action.owlVisible);
    // case CREATE_CHANNEL_SUCCESS:
    //   return state
    //     .set('id', action.id)
    //     .set('result', true)
    //     .set('loading', false);
    // case CREATE_CHANNEL_FAIL:
    //   return state.set('loading', false);
    // case CHANNEL_TEXT_REQUEST:
    //   return state.set('loading', true);
    // case CHANNEL_TEXT_SUCCESS:
    //   return state
    //     .set('loading', false)
    //     .set('result', true)
    //     .set('isVisible', true)
    //     .set('currentMessageShown', false)
    //     .set('text', action.text);
    // case CHANNEL_TEXT_FAIL:
    //   return state
    //     .set('loading', false)
    //     .set('result', false);
    case RECEIVE_OWL_MESSAGE:
      return state
        .set('currentMessageShown', false)
        .set('text', action.payload.text);
    default:
      return state;
  }
}

export default owlReducer;
