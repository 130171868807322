import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import each from 'lodash/each';
import React from 'react';
import AnimatedNumber from 'react-animated-number';
import { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import canUseWebP from '../../utils/getCanSupportWebP';
import getFormattedFieldData from '../../utils/getFormattedFieldData';
import Info from '../Info';

import ContentWrapper from './ContentWrapper';
import GameBackground from './GameBackground';
import Stat from './Stat';
import H1 from './H1';
import H2 from './H2';
import TitleWrapper from './TitleWrapper';

const VALUE_ADD_ANIMATION_DURATION = 1500;

const ValueAdd = styled.span`
  color: #578E1F;
  font-family: 'Merriweather',serif;
  font-size: 25px;
  font-weight: bold;
  white-space: nowrap;
  width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${props => !props.small && `
    position: absolute;
    right: 5px;
    top: 92px;
  `}
  
  ${props => props.small && `
      position: static;
  `}
`;

const ValueAddMaterial = styled(ValueAdd)`
  transform: translate(0, 20px);
`;

class GameRating extends React.PureComponent {
  state = {
    finishAnimations: false,
  };

  timer = null;

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        finishAnimations: true,
      }, () => {

        const { gameId, fields, profile, data } = this.props;
        const { employeeID } = profile;

        const dataPrev = {};

        each(
          filter(fields, field => !field.IsSystem),
          field => dataPrev[field.FieldName] = data[field.FieldName],
        );

        const newPrevRating = {
          gameId,
          employeeId: employeeID,
          ...dataPrev,
        };

        this.props.setPrevRating(newPrevRating);
      });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    const { prevRating, small, gamesList, gameId, fields, data } = this.props;
    const { finishAnimations } = this.state;

    const initialValues = {};

    each(
      filter(fields, field => !field.IsSystem),
      field => {
        if (prevRating && typeof prevRating[field.FieldName] !== 'undefined') {
          initialValues[field.FieldName] = prevRating[field.FieldName];
        } else {
          initialValues[field.FieldName] = 0;
        }
      },
    );

    // if (prevRating) {
    //   initialValues.finishedSession = prevRating.finishedSession;
    //   initialValues.summa = prevRating.summa;
    //   initialValues.card = prevRating.card;
    //   initialValues.time = prevRating.time;
    // }

    const currentGameInfo = find(gamesList, { GameID: gameId }, false);

    const name = get(currentGameInfo, 'Name');
    const description = get(currentGameInfo, 'OptionsValues.shortSlogan', '');

    const gameOptionsValues = get(currentGameInfo, 'OptionsValues');

    const isCanUseWebP = canUseWebP();
    const darkImageWebP = gameOptionsValues.darkImage;
    const darkImageSimple = gameOptionsValues.darkImageWebP;
    const darkImage = isCanUseWebP && darkImageWebP ? darkImageWebP : darkImageSimple;

    return (
      <GameBackground small={small} image={darkImage}>
        <TitleWrapper>
          <H1>{name}</H1>
          <H2>{description}</H2>
        </TitleWrapper>
        <ContentWrapper flexWrap={small && 'wrap'} justify={small && 'center'}>
          {
            fields && fields
              .filter(field => !field.IsSystem)
              .map((field) => (
                <Stat
                  small={small}
                  number={(
                    <React.Fragment>
                      <span>
                        {getFormattedFieldData({
                          value: data[field.FieldName],
                          fieldType: field.ColumnType,
                        })}
                      </span>
                      <PoseGroup>
                        {!finishAnimations
                        && typeof initialValues[field.FieldName] !== 'undefined'
                        && initialValues[field.FieldName] !== data[field.FieldName]
                        && (
                          <ValueAdd key="valueAdd" small={small}>
                            {`+ `}{getFormattedFieldData({
                            value: Math.abs(data[field.FieldName] - initialValues[field.FieldName]),
                            fieldType: field.ColumnType,
                          })}
                          </ValueAdd>
                        )}
                      </PoseGroup>
                    </React.Fragment>
                  )}
                  text={
                    <>
                      {field.Name}
                      {field.Hint && (
                        <Info
                          textColor="#232323"
                          isBig
                          text={field.Hint}
                        />
                      )}
                    </>
                  }
                />
              ))
          }
          {/*<Stat*/}
          {/*  small={small}*/}
          {/*  number={(*/}
          {/*    <React.Fragment>*/}
          {/*      <AnimatedNumber*/}
          {/*        initialValue={initialValues.summa || 0}*/}
          {/*        component="span"*/}
          {/*        value={this.props.summa || 0}*/}
          {/*        stepPrecision={0}*/}
          {/*        duration={VALUE_ADD_ANIMATION_DURATION}*/}
          {/*        formatValue={n => (n / 1000000).toFixed(2).toString().replace('.', ',')}*/}
          {/*      />*/}
          {/*      <PoseGroup>*/}
          {/*        {!finishAnimations*/}
          {/*        && initialValues.summa > 0*/}
          {/*        && initialValues.summa !== this.props.summa*/}
          {/*        && (*/}
          {/*          <ValueAdd key="valueAdd" small={small}>*/}
          {/*            {`+ ${((Math.abs(this.props.summa - initialValues.summa)) / 1000000).toFixed(2).toString().replace('.', ',')} млн.`}*/}
          {/*          </ValueAdd>*/}
          {/*        )}*/}
          {/*      </PoseGroup>*/}
          {/*    </React.Fragment>*/}
          {/*  )}*/}
          {/*  text="Общая сумма сделок, млн"*/}
          {/*/>*/}
          {/*<Stat*/}
          {/*  small={small}*/}
          {/*  number={(*/}
          {/*    <React.Fragment>*/}
          {/*      <AnimatedNumber*/}
          {/*        initialValue={initialValues.finishedSession || 0}*/}
          {/*        component="span"*/}
          {/*        value={this.props.finishedSession || 0}*/}
          {/*        stepPrecision={0}*/}
          {/*        duration={1500}*/}
          {/*        formatValue={n => parseInt(n, 10)}*/}
          {/*      />*/}
          {/*      <PoseGroup>*/}
          {/*        {!finishAnimations*/}
          {/*        && initialValues.finishedSession > 0*/}
          {/*        && initialValues.finishedSession !== this.props.finishedSession*/}
          {/*        && (*/}
          {/*          <ValueAdd key="valueAdd" small={small}>*/}
          {/*            {`+ ${Math.abs(this.props.finishedSession - initialValues.finishedSession)}`}*/}
          {/*          </ValueAdd>*/}
          {/*        )}*/}
          {/*      </PoseGroup>*/}
          {/*    </React.Fragment>*/}
          {/*  )}*/}
          {/*  text="Совершено сделок"*/}
          {/*/>*/}
          {/*<Stat*/}
          {/*  small={small}*/}
          {/*  number={(*/}
          {/*    <React.Fragment>*/}
          {/*      <AnimatedNumber*/}
          {/*        initialValue={initialValues.summa || 0}*/}
          {/*        component="span"*/}
          {/*        value={this.props.summa || 0}*/}
          {/*        stepPrecision={0}*/}
          {/*        duration={VALUE_ADD_ANIMATION_DURATION}*/}
          {/*        formatValue={n => (n / 1000000).toFixed(2).toString().replace('.', ',')}*/}
          {/*      />*/}
          {/*      <PoseGroup>*/}
          {/*        {!finishAnimations*/}
          {/*        && initialValues.summa > 0*/}
          {/*        && initialValues.summa !== this.props.summa*/}
          {/*        && (*/}
          {/*          <ValueAdd key="valueAdd" small={small}>*/}
          {/*            {`+ ${((Math.abs(this.props.summa - initialValues.summa)) / 1000000).toFixed(2).toString().replace('.', ',')} млн.`}*/}
          {/*          </ValueAdd>*/}
          {/*        )}*/}
          {/*      </PoseGroup>*/}
          {/*    </React.Fragment>*/}
          {/*  )}*/}
          {/*  text="Общая сумма сделок, млн"*/}
          {/*/>*/}
          {/*<Stat*/}
          {/*  small={small}*/}
          {/*  number={(*/}
          {/*    <React.Fragment>*/}
          {/*      <AnimatedNumber*/}
          {/*        initialValue={initialValues.card || 0}*/}
          {/*        component="span"*/}
          {/*        value={this.props.card || 0}*/}
          {/*        stepPrecision={0}*/}
          {/*        duration={VALUE_ADD_ANIMATION_DURATION}*/}
          {/*        formatValue={n => parseInt(n, 10)}*/}
          {/*      />*/}
          {/*      <PoseGroup>*/}
          {/*        {!finishAnimations*/}
          {/*        && initialValues.card > 0*/}
          {/*        && initialValues.card !== this.props.card*/}
          {/*        && (*/}
          {/*          <ValueAddMaterial key="valueAdd" small={small}>*/}
          {/*            {`+ ${parseInt(Math.abs(this.props.card - initialValues.card), 10)}`}*/}
          {/*          </ValueAddMaterial>*/}
          {/*        )}*/}
          {/*      </PoseGroup>*/}
          {/*    </React.Fragment>*/}
          {/*  )}*/}
          {/*  text="Прочитано учебного материала"*/}
          {/*/>*/}
          {/*<Stat*/}
          {/*  small={small}*/}
          {/*  number={(*/}
          {/*    <React.Fragment>*/}
          {/*      <AnimatedNumber*/}
          {/*        initialValue={initialValues.time}*/}
          {/*        component="span"*/}
          {/*        value={this.props.time}*/}
          {/*        stepPrecision={0}*/}
          {/*        duration={VALUE_ADD_ANIMATION_DURATION}*/}
          {/*        formatValue={n => (parseInt((n / 3600) * 100, 10) / 100).toString().replace('.', ',')}*/}
          {/*      />*/}
          {/*      <PoseGroup>*/}
          {/*        {!finishAnimations*/}
          {/*        && initialValues.time > 0*/}
          {/*        && initialValues.time !== this.props.time*/}
          {/*        && (*/}
          {/*          <ValueAdd key="valueAdd" small={small}>*/}
          {/*            {`+ ${(parseInt(((Math.abs(this.props.time - initialValues.time)) / 3600) * 100, 10) / 100).toString().replace('.', ',')}`}*/}
          {/*          </ValueAdd>*/}
          {/*        )}*/}
          {/*      </PoseGroup>*/}
          {/*    </React.Fragment>*/}
          {/*  )}*/}
          {/*  text="Общее время в обучении, ч"*/}
          {/*/>*/}
        </ContentWrapper>
      </GameBackground>
    );
  }
}

export default GameRating;
