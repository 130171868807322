import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import pure from 'recompose/pure';
import Typograf from 'typograf';
import Img from '../Img';

import LogoIcon from './readom.svg';
import LogoGameIcon from './readom-game.svg';

const Wrapper = pure(styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: ${props => (props.isGameHeader ? 'calc(50% - 395px)' : 'auto')};
`);

const LogoWrapper = pure(styled(Link)`
  align-items: center;
  display: flex;
  height: 100%;
  text-decoration: none;
`);

const LogoText = pure(styled.span`
  color: ${props => (props.isGameHeader ? '#1C1C1C' : '#FFFFFF')};
  display: inline-block;
  font-family: 'Merriweather', serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: ${props => (props.isGame ? 'inherit' : '2px')};
  margin-left: 15px;
  text-transform: ${props => (props.isGame ? 'inherit' : 'uppercase')};
`);

const tp = new Typograf({ locale: ['ru', 'en-US'] });

class Logo extends React.PureComponent {
  render() {
    const { props } = this;

    return (
      <Wrapper
        isGameHeader={props.isGameHeader}
      >
        <LogoWrapper
          to="/"
        >
          <Img src={props.isGameHeader ? LogoGameIcon : LogoIcon} alt="Readom" />
          <LogoText
            isGame={props.text !== undefined}
            isGameHeader={props.isGameHeader}
          >
            {props.text !== undefined ? tp.execute(props.text) : 'Readom'}
          </LogoText>
        </LogoWrapper>
      </Wrapper>
    );
  }
}

Logo.propTypes = {
  isGameHeader: PropTypes.bool,
  text: PropTypes.string,
  withText: PropTypes.bool,
};

Logo.defaultProps = {
  withText: true,
};

export default Logo;
