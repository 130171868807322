import styled from 'styled-components';

const TabsContent = styled.div`
  background-color: #EBEBED;
  display: flex;
  min-height: calc(100vh - 131px);
  max-width: 100%;
  
  @media (max-width: 790px) {
    padding-top: 60px;
  }
`;

export default TabsContent;
