import React from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  withRouter, Switch, Route, Redirect,
} from 'react-router';
import { createStructuredSelector } from 'reselect';
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar';

import 'sanitize.css/sanitize.css';

import Offline from '../../components/Offline';
import ReadomSupport from '../../components/ReadomSupport';
import SpotLightOverlay from '../../components/SpotLightOverlay';
import { setActiveGameId } from '../../redux/modules/activeGame/actions';
import { makeSelectActiveGameId } from '../../redux/modules/activeGame/selectors';
import { makeSelectIsNetworkOffline, makeSelectIsRequestFail } from '../../redux/modules/error/selectors';
import { logout } from '../../redux/modules/global/actions';
import { startOwlListen } from '../../redux/modules/owl/actions';
import isUserAuthenticated from '../../utils/isUserAuthenticated';
import GlobalStyle from '../../global-styles';
import Loader from '../../components/Loader';
import Modals from '../Modals';

import URL_CONSTANTS from '../../urlConstants';
import Owl from '../../components/Owl';

import Header from '../other/Header';
import ActiveGameDependedRoute from '../route/ActiveGameDependedRoute';

import { makeSelectSpinner } from '../../redux/modules/global/selectors';
import { getProfileInfo } from '../../redux/modules/profile/actions';

import Pages from './Pages';
import AppWrapper from './AppWrapper';

const LoadingBarStyle = { backgroundColor: '#3DBA9A', position: 'fixed', zIndex: '100' };

const GUEST_PAGES_EXACT = [
  URL_CONSTANTS.URLS.LOGIN,
  URL_CONSTANTS.URLS.FORGOT_PASSWORD,
  URL_CONSTANTS.URLS.LICENCE,
  URL_CONSTANTS.URLS.ORGANIZATION,
  URL_CONSTANTS.URLS.PAYMENT_METHODS,
  URL_CONSTANTS.URLS.REGISTRATION,
  URL_CONSTANTS.URLS.PROCESSING_PERSONAL_DATA,
  URL_CONSTANTS.URLS.POLICY,
  URL_CONSTANTS.URLS.PROMO,
  URL_CONSTANTS.URLS.PURCHASE_TERMS,
  URL_CONSTANTS.URLS.PAYMENT_SUCCESS,
  URL_CONSTANTS.URLS.PAYMENT_FAIL,
];

const GUEST_PAGES_MATCH = [
  URL_CONSTANTS.PREFIXES.RESTORE_PASSWORD,
  URL_CONSTANTS.PREFIXES.SET_PASSWORD_FROM_INVITE,
  URL_CONSTANTS.PREFIXES.USER_ACTIVATION,
  URL_CONSTANTS.PREFIXES.START_TRIAL_GAME,
];

const RedirectToStore = () => (<Redirect to={URL_CONSTANTS.URLS.GAMES_STORE} />);

class App extends React.PureComponent {
  componentWillMount() {
    const { activeGameId, cookies } = this.props;
    const isAuthenticated = isUserAuthenticated(cookies);

    if (!isAuthenticated) {
      const { history } = this.props;
      const { location } = history;
      const { pathname } = location;
      if (
        !GUEST_PAGES_EXACT.includes(pathname)
        && GUEST_PAGES_MATCH.filter(route => pathname.indexOf(route) !== -1).length === 0
      ) {
        // TODO: Тут должен быть logout но почему-то он все вешает
        // this.props.logout();
        // return;
        localStorage.removeItem('DAdminSessionId');
        this.props.history.push(URL_CONSTANTS.URLS.INDEX_PAGE);
      }
    } else if (activeGameId) {
      this.props.startOwlListen();
      this.props.getProfile();
      return;
    }

    this.props.startOwlListen();
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeGameId && this.props.activeGameId !== prevProps.activeGameId) {
      this.props.getProfile();
    }
  }

  // TODO: LoadingBar похоже не работает. Чинить или убирать.
  render() {
    const {
      networkOffline,
      cookies,
      spinnerIsShowed,
      // requestFail,
    } = this.props;

    const isAuthenticated = isUserAuthenticated(cookies);

    // TODO: HARDCODE_TYPE_2 title шаблон зашит в код
    return (
      <>
        <GlobalStyle />
        <Helmet
          titleTemplate="%s - Readom"
          defaultTitle="Readom"
        />
        {networkOffline && <Offline />}
        {!networkOffline && (
          <>
            <LoadingBar style={LoadingBarStyle} />
            <Loader isActive={spinnerIsShowed} />
            <Header />
            <Switch>
              <Route exact path={URL_CONSTANTS.URLS.INDEX_PAGE} component={Pages.IndexPage} />

              <AppWrapper>
                <Route exact path={URL_CONSTANTS.URLS.PROFILE} component={Pages.ProfilePage} />
                <Route exact path={URL_CONSTANTS.URLS.LOGIN} component={Pages.LoginPage} />
                <Route exact path={URL_CONSTANTS.URLS.FORGOT_PASSWORD} component={Pages.ResetPage} />
                <Route exact path={URL_CONSTANTS.URLS.REGISTRATION} component={Pages.RegistrationPage} />
                <Route
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.RESTORE_PASSWORD}/:token/:isEmployeeFlag`}
                  component={Pages.RestorePasswordPage}
                />
                <Route
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.START_TRIAL_GAME}/:gameSysName/:phone?/:name?/:email?`}
                  component={Pages.StartTrialGamePage}
                />
                <Route
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.SET_PASSWORD_FROM_INVITE}/:token/:isEmployeeFlag`}
                  component={Pages.InviteSetPasswordPage}
                />
                <Route
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.SET_B2C_EMAIL_FROM_B2B}/:token`}
                  component={Pages.EmployeeSetUserEmailPage}
                />
                <Route
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.USER_ACTIVATION}/:token`}
                  component={Pages.UserActivationPage}
                />
                <Route exact path={URL_CONSTANTS.URLS.COMPANY_PAYMENT} component={Pages.PaymentPage} />
                <Route
                  path={URL_CONSTANTS.URLS.CHANGE_COMPANY_ACCOUNT_DETAILS}
                  component={Pages.ChangeAccountDetailsPage}
                />

                <ActiveGameDependedRoute
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.GAME_OVERVIEW}/:gameShortName`}
                  component={Pages.GameOverviewPage}
                />

                <Route
                  exact
                  path={`${URL_CONSTANTS.URLS.GET_FULL_ACCESS}`}
                  component={Pages.GetFullAccessPage}
                />

                <Route
                  exact
                  path={`${URL_CONSTANTS.URLS.DEMO_FINISH}`}
                  component={Pages.DemoFinishPage}
                />

                <ActiveGameDependedRoute
                  exact
                  path={`${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/:gameShortName`}
                  component={Pages.GamePage}
                />

                <Route
                  exact
                  path={URL_CONSTANTS.URLS.GAMES_STORE}
                  component={Pages.GamesPage}
                />

                <Route path={URL_CONSTANTS.URLS.GAME_FINISH} component={Pages.GameFinishPage} />
                <Route path={URL_CONSTANTS.URLS.PAYMENT_SUCCESS} component={Pages.SuccessPaymentPage} />
                <Route path={URL_CONSTANTS.URLS.PAYMENT_FAIL} component={Pages.FailPaymentPage} />
                <Route path={URL_CONSTANTS.URLS.COMPANY_GAME_RATING} component={Pages.RatingCompanyPage} />
                <Route path={URL_CONSTANTS.URLS.COMMON_RATING} component={Pages.RatingAllPage} />
                <Route path={URL_CONSTANTS.URLS.ABOUT} component={Pages.AboutPage} />
                <Route path={URL_CONSTANTS.URLS.LICENCE} component={Pages.LicencePage} />
                <Route path={URL_CONSTANTS.URLS.ORGANIZATION} component={Pages.OrganizationPage} />
                <Route path={URL_CONSTANTS.URLS.PAYMENT_METHODS} component={Pages.PaymentMethodsPage} />
                <Route path={URL_CONSTANTS.URLS.COMPANY_EMPLOYEES} component={Pages.EmployeesPage} />
                <Route path={URL_CONSTANTS.URLS.POLICY} component={Pages.PolicyPage} />
                <Route
                  path={URL_CONSTANTS.URLS.PROCESSING_PERSONAL_DATA}
                  component={Pages.ProcessingPersonalDataPage}
                />
                <Route path={URL_CONSTANTS.URLS.PURCHASE_TERMS} component={Pages.PurchaseTermsPage} />

                <Route
                  exact
                  path={URL_CONSTANTS.PREFIXES.SINGLE_GAME}
                  render={RedirectToStore}
                />
              </AppWrapper>

              <Redirect to={URL_CONSTANTS.URLS.INDEX_PAGE} />
            </Switch>
          </>
        )}
        <ReadomSupport isAuthenticated={isAuthenticated} />
        <Modals />
        <SpotLightOverlay />
        <Owl />
      </>
    );
  }
}

App.defaultProps = {
  activeGameId: null,
  spinnerIsShowed: false,
};

App.propTypes = {
  activeGameId: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getProfile: PropTypes.func.isRequired,
  history: PropTypes.object,
  logout: PropTypes.func.isRequired,
  networkOffline: PropTypes.any,
  spinnerIsShowed: PropTypes.bool,
  startOwlListen: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  getProfile: () => dispatch(getProfileInfo()),
  startOwlListen: () => dispatch(startOwlListen()),
  setActiveGameId: gameId => dispatch(setActiveGameId(gameId)),
});

const mapStateToProps = createStructuredSelector({
  spinnerIsShowed: makeSelectSpinner(),
  activeGameId: makeSelectActiveGameId(),
  networkOffline: makeSelectIsNetworkOffline(),
  requestFail: makeSelectIsRequestFail(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withCookies,
  withRouter,
  withConnect,
)(App);
