import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import urlapi from 'url';
import find from 'lodash/find';

const domain = process.env.REACT_APP_DOMAIN;
const protocol = process.env.REACT_APP_PROTOCOL;
const prefix = `${protocol}://${domain}`;
const apiUrl = process.env.REACT_APP_API_URL;

const Wrapper = styled.div`
  background-color: #000000;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;
`;

const Bg = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  background-image: url(${props => props.image});
  background-position: ${props => (props.bgPosition ? props.bgPosition : 'center')};
  background-size: cover;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  
  transition: 1s opacity ease-out;
  width: 100vw;
  z-index: 10;
`;

const MessageBg = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  background-image: url(${props => props.image});
  background-position: ${props => (props.bgPosition ? props.bgPosition : 'center')};
  background-size: cover;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  
  transition: 1s opacity ease-out;
  width: 100vw;
  z-index: 10;
  
  &:before {
    background-image: linear-gradient(rgba(0, 0, 25, .7), rgba(0, 0, 0, 0));
    content: '';
    display: block;
    height: 50vh;
    position: absolute;
    width: 100vw;
  }

  &:after {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .7));
    bottom: 0;
    content: '';
    display: block;
    height: 50vh;
    position: absolute;
    width: 100vw;
  }
`;

const Img = styled.img`
  display: none;
`;

const Canvas = styled.canvas`
  display: none;
`;

const Help = styled.div`
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 101;
  color: #FFFFFF;
  font-family: 'Merriweather', serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  user-select: none;
  
  @media (max-width: 790px) {
    display: none;
  }
  
  @media (max-width: 1024px) {
    width: 140px;
  }
`;

// TODO: Зашиты позиции фона картинки во весь экран
const bgPositions = [
  {
    path: `${apiUrl}/image?namesys=8.jpg`,
    position: '80% center',
  },
  {
    path: `${apiUrl}/image?namesys=9.jpg`,
    position: '80% center',
  },
  {
    path: `${apiUrl}/image?namesys=10.jpg`,
    position: '80% center',
  },
  {
    path: `${apiUrl}/image?namesys=11.jpg`,
    position: '80% center',
  },
  {
    path: `${apiUrl}/image?namesys=12.jpg`,
    position: '80% center',
  },
];

const defaultMessageBgPositions = {
  x: 877,
  y: 838,
};

const messageBgPositions = [
  {
    path: `${apiUrl}/image?namesys=8.jpg`,
    x: defaultMessageBgPositions.x,
    y: defaultMessageBgPositions.y,
  },
  {
    path: `${apiUrl}/image?namesys=9.jpg`,
    x: 860,
    y: defaultMessageBgPositions.y,
  },
  {
    path: `${apiUrl}/image?namesys=10.jpg`,
    x: 860,
    y: defaultMessageBgPositions.y,
  },
  {
    path: `${apiUrl}/image?namesys=11.jpg`,
    x: 860,
    y: defaultMessageBgPositions.y,
  },
  {
    path: `${apiUrl}/image?namesys=12.jpg`,
    x: defaultMessageBgPositions.x,
    y: defaultMessageBgPositions.y,
  },
];

class FinishCupImage extends React.PureComponent {
  state = {
    imageLoaded: false,
    clicked: false,
    messageImageLoaded: false,
    messageImage: null,
  };

  onImageClick = () => {
    const answer = this.props.answers[0];
    this.setState({
      clicked: true,
    });
    this.props.checkAnswer(answer.id);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.sendAnswerPending && !this.props.sendAnswerPending) {
      this.setState({
        clicked: false,
      });
    }
  };

  loadImage = () => {
    this.setState({
      imageLoaded: true,
    });

    this.drawText();
  };

  getBgPosition = () => {
    if (!this.props.src) {
      return null;
    }

    const url = urlapi.parse(this.props.src);

    if (url.path) {
      const item = find(bgPositions, { path: `${prefix}/${url.path}` });
      if (item) {
        return item.position;
      }
    }

    return null;
  };

  getMessageBgPosition = () => {
    const url = urlapi.parse(this.props.src);

    if (url.path) {
      const item = find(messageBgPositions, { path: `${prefix}${url.path}` });
      if (item) {
        return {
          x: item.x,
          y: item.y,
        };
      }
    }

    return {
      x: defaultMessageBgPositions.x,
      y: defaultMessageBgPositions.y,
    };
  };

  wrapText = (context, text, _x, _y, maxWidth, lineHeight) => {
    const x = _x;
    let y = _y + 10;
    const xPadding = 12;
    const yPadding = 2;
    const words = text.split(' ');
    let line = '';

    let n = 0;
    let lineCount = 0;

    for (n; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > (maxWidth - 10) && n > 0) {
        context.fillStyle = '#fff';
        lineCount += 1;
        context.fillText(line.trim(), x + xPadding, y + yPadding);
        line = `${words[n]} `;
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    context.fillStyle = '#fff';
    lineCount += 1;
    context.fillText(line.trim(), x + xPadding, y + yPadding);

    context.strokeStyle = '#4E81C6';
    context.fillStyle = '#4E81C6';
    context.globalCompositeOperation = 'destination-over';

    let rectWidth = maxWidth;
    if (lineCount === 1) {
      const m = context.measureText(line);
      rectWidth = m.width + xPadding * 2;
    }

    this.roundRect(context, _x, _y - 20, rectWidth, lineHeight * lineCount + 25, {
      tl: 0, tr: 10, br: 10, bl: 10,
    }, true);

    context.globalCompositeOperation = 'draw behind';

    return _y - 20 + lineHeight * lineCount + 40;
  };

  /**
   * Draws a rounded rectangle using the current state of the canvas.
   * If you omit the last three params, it will draw a rectangle
   * outline with a 5 pixel border radius
   * @param {CanvasRenderingContext2D} ctx
   * @param {Number} x The top left x coordinate
   * @param {Number} y The top left y coordinate
   * @param {Number} width The width of the rectangle
   * @param {Number} height The height of the rectangle
   * @param {Number} [radius = 5] The corner radius; It can also be an object
   *                 to specify different radii for corners
   * @param {Number} [radius.tl = 0] Top left
   * @param {Number} [radius.tr = 0] Top right
   * @param {Number} [radius.br = 0] Bottom right
   * @param {Number} [radius.bl = 0] Bottom left
   * @param {Boolean} [fill = false] Whether to fill the rectangle.
   * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
   */
  roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
    if (typeof stroke === 'undefined') {
      stroke = true;
    }
    if (typeof radius === 'undefined') {
      radius = 5;
    }
    if (typeof radius === 'number') {
      radius = {
        tl: radius, tr: radius, br: radius, bl: radius,
      };
    } else {
      const defaultRadius = {
        tl: 0, tr: 0, br: 0, bl: 0,
      };
      for (const side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  };

  drawText = () => {
    const bigCanvas = document.getElementById('finishCupCanvas');
    let destCtx = bigCanvas.getContext('2d');

    const messageCanvas = document.createElement('canvas');
    messageCanvas.id = 'messageCanvas';
    messageCanvas.width = 370;
    messageCanvas.height = 361;

    let context = messageCanvas.getContext('2d');
    const maxWidth = 343;
    const lineHeight = 30;
    const x = 0;
    const y = 20;

    context.font = '14pt HelveticaNeue, Helvetica Neue';
    const { textParts } = this.props;
    if (textParts && textParts.length) {
      const startY = this.wrapText(context, textParts[0], x, y, maxWidth, lineHeight);
      this.wrapText(context, textParts[1], x, startY + 20, maxWidth, lineHeight);

      const { x: X, y: Y } = this.getMessageBgPosition(this.props.messageImage);

      destCtx.drawImage(messageCanvas, X, Y);
      const data = bigCanvas.toDataURL();
      destCtx.restore();
      destCtx = null;

      this.setState({
        messageImageLoaded: true,
        messageImage: data,
      });
    } else {
      this.setState({
        messageImageLoaded: true,
        messageImage: null,
      });
    }

    context.restore();
    context = null;
  };

  render() {
    const bgPosition = this.getBgPosition();

    return (
      <Wrapper>
        <Transition
          in={this.state.imageLoaded && this.state.messageImageLoaded}
          timeout={0}
        >
          {status => (
            <Bg
              className={`fade fade-${status}`}
              show={this.state.imageLoaded && this.state.messageImageLoaded}
              image={this.props.src}
              onClick={this.onImageClick}
              bgPosition={bgPosition}
            >
              <MessageBg
                image={this.state.messageImage}
                show={this.state.imageLoaded && this.state.messageImageLoaded}
                bgPosition={bgPosition}
              />
              <Help>
                кликните для продолжения
              </Help>
              <Img
                src={this.props.src}
                onLoad={this.loadImage}
              />
              <Canvas id="finishCupCanvas" width="1920" height="2004" />
            </Bg>
          )}
        </Transition>
      </Wrapper>
    );
  }
}

FinishCupImage.propTypes = {
  src: PropTypes.string.isRequired,
  textParts: PropTypes.array.isRequired,
  checkAnswer: PropTypes.func,
  answers: PropTypes.array,
};

export default FinishCupImage;
