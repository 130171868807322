import {
  REGISTER_REQUEST,
} from './constants';

export function register({ values, formik }) {
  return {
    type: REGISTER_REQUEST,
    payload: {
      values,
      formik,
    },
  };
}
