import size from 'lodash/size';
import { PASSWORD_CONFIRM_INPUT_NAME, PASSWORD_INPUT_NAME } from '../../../../redux/modules/forms/inviteSetPasswordForm/constants';

const validate = (values) => {
  const errors = {};

  if (!values[PASSWORD_INPUT_NAME] || values[PASSWORD_INPUT_NAME].length === 0) {
    errors[PASSWORD_INPUT_NAME] = 'Укажите пароль';
  }

  if (!values[PASSWORD_CONFIRM_INPUT_NAME] || values[PASSWORD_CONFIRM_INPUT_NAME].length === 0) {
    errors[PASSWORD_CONFIRM_INPUT_NAME] = 'Укажите подтверждение пароля';
  }

  if (size(errors)) {
    return errors;
  }

  if (values[PASSWORD_INPUT_NAME] !== values[PASSWORD_CONFIRM_INPUT_NAME]) {
    errors[PASSWORD_INPUT_NAME] = 'Пароли не совпадают';
    errors[PASSWORD_CONFIRM_INPUT_NAME] = 'Пароли не совпадают';
  }

  return errors;
};

export default validate;
