import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import Button from '../../../components/RedButton';
import H1 from '../../../components/H1';
import Success from '../../../components/RoundMark/Success';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import PublicPageBase from '../../PageBase/PublicPageBase';
import SimplePageBase from '../../PageBase/SimplePageBase';
import Content from '../../../components/Content';
import DelayedRedirect from '../../route/DealyedRedirect';

import URL_CONSTANTS from '../../../urlConstants';

const StyledH1 = styled(H1)`
  width: 100%;
  min-width: 100%;
  text-align: center;
`;

const StyledContent = styled(Content)`
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

const REDIRECT_TIMEOUT = 5;

const SuccessPaymentPage = ({ cookies }) => {
  const isAuthenticated = isUserAuthenticated(cookies);
  const Wrapper = isAuthenticated ? SimplePageBase : PublicPageBase;

  return (
    <Wrapper title="Оплата успешна">
      <StyledContent>
        <StyledH1>Оплата прошла успешно</StyledH1>
        <Success />
        {isAuthenticated && <DelayedRedirect to={URL_CONSTANTS.URLS.GAMES_STORE} delay={REDIRECT_TIMEOUT} />}
        {!isAuthenticated && <DelayedRedirect to={URL_CONSTANTS.URLS.LOGIN} delay={REDIRECT_TIMEOUT} />}
        <ButtonWrapper>
          {isAuthenticated && (
            <Button isButton to={URL_CONSTANTS.URLS.GAMES_STORE}>
              Перейти в магазин
            </Button>
          )}
          {!isAuthenticated && (
            <Button isButton to={URL_CONSTANTS.URLS.LOGIN}>
              Войти в профиль
            </Button>
          )}
        </ButtonWrapper>
      </StyledContent>
    </Wrapper>
  );
};

SuccessPaymentPage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default withCookies(SuccessPaymentPage);
