import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size } from '../../utils/mediaQueries';

const Wrapper = styled.div`
  background-color: #3D3F4A;
  display: flex;
  height: 60px;
  width: 100%;
  
  @media (min-width: ${size.toggleMenu}) {
    ${({ sticky }) => sticky && `
      position: sticky;
      top: ${sticky}px;
    `}
  }
  
  @media (max-width: 790px) {
    position: fixed;
    top: 71px;
  }
  z-index: 2;
`;

const TabsWrapper = styled.div`
  display: flex;
  margin: 0 auto 0 auto;
  overflow-y: hidden;
  overflow-x: auto;
  height: 90px;
  
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar { 
    width: 0 !important 
  }
`;

const Tabs = props => (
  <Wrapper sticky={props.sticky}>
    <TabsWrapper>
      {props.children}
    </TabsWrapper>
  </Wrapper>
);

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sticky: PropTypes.number,
};

Tabs.defaultProps = {
  sticky: false,
};

export default Tabs;
