import React from 'react';
import PropTypes from 'prop-types';
import { noun } from 'plural-ru';
import map from 'lodash/map';
import styled from 'styled-components';
import Coin from '../../../../components/Coin';
import Button from '../../../../components/useThis/Button';
import TipFormButtonWrapper from './styled/TipFormButtonWrapper';
import TipFormLine from './styled/TipFormLine';
import TipFormTitle from './styled/TipFormTitle';

const tipAmounts = [1, 5, 10];

const NotEnoughMoneyMessage = styled.p`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Space = styled.span`
  width: 10px;
  display: inline-block;
`;

class TipBuyBody extends React.PureComponent {
  static defaultProps = {
    balance: 0,
  };

  static propTypes = {
    balance: PropTypes.number,
  };

  render() {
    const {
      price, onBuy, balance, tipPlural,
    } = this.props;

    return (
      <>
        {map(tipAmounts, (amount) => {
          const haveEnoughMoney = balance >= price * amount;

          return (
            <TipFormLine key={amount}>
              <TipFormTitle>{`${amount} ${noun(amount, ...tipPlural)}`}</TipFormTitle>
              <TipFormButtonWrapper>
                <Button
                  disabled={!haveEnoughMoney}
                  type="button"
                  onClick={onBuy(amount)}
                >
                  <Flex>
                    {`КУПИТЬ ЗА ${(price * amount).toLocaleString()}`}
                    <Space />
                    <Coin size={20} />
                  </Flex>
                </Button>
                {!haveEnoughMoney && (
                  <NotEnoughMoneyMessage>недостаточно средств</NotEnoughMoneyMessage>
                )}
              </TipFormButtonWrapper>
            </TipFormLine>
          );
        })}
      </>
    );
  }
}

export default TipBuyBody;
