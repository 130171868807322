import { createSelector } from 'reselect';

const MarkCardAsViewedState = state => state.get('markCardAsViewed');

const makeSelectQueue = () => createSelector(
  MarkCardAsViewedState,
  state => state.get('queue'),
);

export {
  makeSelectQueue,
};
