export const PASSWORD_INPUT_NAME = 'password';
export const PASSWORD_CONFIRM_INPUT_NAME = 'passwordConfirm';

export const INVITE_SET_PASSWORD_REQUEST = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_REQUEST';
export const INVITE_SET_PASSWORD_REQUEST_SUCCESS = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_REQUEST_SUCCESS';
export const INVITE_SET_PASSWORD_REQUEST_FAIL = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_REQUEST_FAIL';

export const INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST';
export const INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_SUCCESS = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_SUCCESS';
export const INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_FAIL = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_FAIL';

export const INVITE_SET_PASSWORD_RESET_FORM = 'readom/invite/setPasswordForm/INVITE_SET_PASSWORD_RESET_FORM';
