import styled from 'styled-components';

export const Img = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  height: 265px;
  width: 220px;
`;

export const Name = styled.div`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 0 30px;
  text-align: center;
`;

export const Part = styled.div`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
`;

export const PopupBodyHeader = styled.div`
  display: flex;
  ${props => (props.withBuyButton ? 'margin: 40px 0 0 0;' : 'margin: 40px 0;')};
  position: relative;
  ${props => (props.small && `
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `)}
`;

export const PopupText = styled.div`
  color: #1C1C1C;
  font-size: 16px;
  line-height: 22px;
`;

export const SingleBook = styled.div`
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => (props.small ? '100%' : 'calc(100% - 220px)')};
`;
