import {
  SET_STARTED_TRIAL_GAME_SESSION_ID,
} from './constants';

export function setStartedTrialGameSessionId(startedTrialGameSessionId) {
  return {
    type: SET_STARTED_TRIAL_GAME_SESSION_ID,
    startedTrialGameSessionId,
  };
}
