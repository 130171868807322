import React from 'react';
import styled from 'styled-components';

import EmployeeSetUserEmailForm from './EmployeeSetUserEmailForm';
import SimplePageBase from '../../PageBase/SimplePageBase';
import Content from '../../../components/Content';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  margin: auto;
  padding: 20px 0;
  width: 100%;
  flex-direction: column;
`;

class EmployeeSetUserEmailPage extends React.Component {
  render() {
    return (
      <SimplePageBase title="Профиль пользователя">
        <Content justify="center">
          <Wrapper>
            <EmployeeSetUserEmailForm />
          </Wrapper>
        </Content>
      </SimplePageBase>
    );
  }
}

export default EmployeeSetUserEmailPage;
