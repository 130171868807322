import React from 'react';
import pure from 'recompose/pure';
import styled from 'styled-components';

import { device } from '../../../utils/mediaQueries';

import SimplePageBase from '../../PageBase/SimplePageBase';

import Content from '../../../components/Content';
import GamesPageContent from '../../../components/GamesPageContent';

const CustomContent = pure(styled(Content)`
    @media ${device.mobileL} {
      justify-content: center;
    }
`);

const GamesPage = () => (
  <SimplePageBase title="Мои игры">
    <CustomContent flexWrap="wrap" grey>
      <GamesPageContent />
    </CustomContent>
  </SimplePageBase>
);

export default GamesPage;
