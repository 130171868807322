import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import onClickOutside from 'react-onclickoutside';

import ProfileIcon from './my-profile.svg';
import MessageIcon from './message.svg';
import LogoutIcon from './logout.svg';

import URL_CONSTANTS from '../../urlConstants';

const TopMenuWrapperAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const TopMenuWrapper = styled(TopMenuWrapperAnimation)`
  background-color: #343642;
  border: 1px solid #5B5D66;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  top: 70px;
  width: 240px;
  z-index: 100;

  &:after, &:before {
    bottom: 100%;
    left: 219px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #343642;
    border-width: 12px;
    margin-left: -12px;
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #5B5D66;
    border-width: 13px;
    margin-left: -13px;
  }
`;

const InfoWrapper = styled.div`
  margin: 9px 0 10px;
`;

const Text = styled.p`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #FCFBFC;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0;
`;

const ActiveMenuWrapper = styled.div``;

const ActiveMenuItem = styled(({ active, ...rest }) => <Link {...rest} />)`
  align-items: center;
  background-color: ${props => (props.active ? '#282A33' : '#343642')};
  cursor: pointer;
  display: flex;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  position: relative;
  text-decoration: none;

  &:hover {
    background-color: #282A33;
  }

  &:before {
    background-color: #35BB9A;
    content: '';
    display: ${props => (props.active ? 'block' : 'none')};
    height: 100%;
    left: -1px;
    position: absolute;
    width: 5px;
  }
`;

const Icon = styled.img``;

const onFeedBackButtonClick = () => {
  const feedBackButton = document.getElementsByClassName('b24-widget-button-crmform')[0];
  if (feedBackButton) {
    feedBackButton.click();
  }
};

class TopMenu extends React.PureComponent {
  handleClickOutside = (e) => {
    const { target } = e;
    const clickOnUserPicHeader = target.classList.contains('user-pic-header');
    if (clickOnUserPicHeader) {
      return;
    }

    const { isActive, closeTopMenu } = this.props;

    if (isActive) {
      closeTopMenu();
    }
  };

  render() {
    const {
      isActive, pathname,
      onExit,
    } = this.props;

    return (
      <React.Fragment>
        <PoseGroup>
          {isActive && (
            <TopMenuWrapper key="top-menu">
              <InfoWrapper />
              <ActiveMenuWrapper>
                <ActiveMenuItem
                  active={pathname === URL_CONSTANTS.URLS.PROFILE}
                  to={URL_CONSTANTS.URLS.PROFILE}
                  onClick={this.props.closeTopMenu}
                >
                  <Text>Мой профиль</Text>
                  <Icon src={ProfileIcon} alt="Мой профиль" />
                </ActiveMenuItem>
                <ActiveMenuItem
                  to="/"
                  onClick={onFeedBackButtonClick}
                >
                  <Text>Обратная связь</Text>
                  <Icon src={MessageIcon} alt="Обратная связь" />
                </ActiveMenuItem>
                <ActiveMenuItem
                  onClick={onExit}
                  to="/"
                >
                  <Text>Выход</Text>
                  <Icon src={LogoutIcon} alt="Выход" />
                </ActiveMenuItem>
              </ActiveMenuWrapper>
            </TopMenuWrapper>
          )}
        </PoseGroup>
      </React.Fragment>
    );
  }
}

TopMenu.propTypes = {
  isActive: PropTypes.bool,
  onExit: PropTypes.func,
  closeTopMenu: PropTypes.func,
  pathname: PropTypes.string,
};

TopMenu.defaultProps = {
  isActive: false,
  onExit: () => {},
  closeTopMenu: () => {},
  pathname: '',
};

export default onClickOutside(TopMenu);
