import {
  call, select, delay, all,
} from 'redux-saga/effects';
import get from 'lodash/get';
import find from 'lodash/find';
import { makeSelectGames, makeSelectGamesShowcases } from '../../modules/games/selectors';
import { makeSelectApiUrl } from '../../modules/global/selectors';

function* getCommonGameRating({ apiRequest, gameId }) {
  const url = yield select(makeSelectApiUrl());

  let games = yield select(makeSelectGames());
  let gamesShowcases = yield select(makeSelectGamesShowcases());

  // TODO: HOTFIX надо переделать чтобы без datapolling работало
  while (!games || !gamesShowcases) {
    yield delay(100);

    games = yield select(makeSelectGames());
    gamesShowcases = yield select(makeSelectGamesShowcases());
  }

  const allGames = [...games, ...gamesShowcases];
  const currentGame = find(allGames, { GameID: gameId });
  const viewName = get(currentGame, 'OptionsValues.worldRatingViewName');
  const userViewName = get(currentGame, 'OptionsValues.worldRatingUserViewName');

  if (!viewName || !userViewName) {
    throw new Error('получение рейтинга не реализовано для этой игры');
  }

  const requestURL = `${url}/ReportGet/${viewName}///0/0/world`;
  const requestURLUser = `${url}/ReportGet/${userViewName}///0/0/user`;

  const [request, requestUser] = yield all([
    call(apiRequest, requestURL),
    call(apiRequest, requestURLUser),
  ]);

  const userRating = get(requestUser, 'data.result[0].Data[0]');
  const isUserRatingEmpty = !get(userRating, 'userid', false);
  const data = get(request, 'data.result[0].Data');
  const fields = get(request, 'data.result[0].FieldList');

  if (!data) {
    throw new Error('ошибка при получении рейтинга');
  }

  // Ищем по полю userid пользователя
  const isUserInMainRating = !!get(find(data, { userid: get(userRating, 'userid') }), 'userid', false);

  if (!isUserRatingEmpty && !isUserInMainRating) {
    userRating.outTop = true;
    data.push(userRating);
  }

  return {
    data,
    fields,
  };
}

export default getCommonGameRating;
