import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { darken } from 'polished';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { makeSelectProfile } from '../../../../redux/modules/profile/selectors';
import { confirmPersonalEmailRequest } from '../../../../redux/modules/forms/employeeSetUserEmailForm/actions';
import {
  makeSelectIsFail,
  makeSelectIsSubmitting,
  makeSelectIsSucceeded,
} from '../../../../redux/modules/forms/employeeSetUserEmailForm/selectors';
import validate from './validate';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import NormalInput from '../../../../components/Input/index';
import {
  PASSWORD_INPUT_NAME,
} from '../../../../redux/modules/forms/employeeSetUserEmailForm/constants';

const A = styled.a`
  color: #41addd;

  &:hover {
    color: #6cc0e5;
  }
`;

const FormInner = styled.div`
  margin: 35px auto 0;
  width: 300px;
  
  @media (max-width: 400px) {
    margin: 15px auto 0;
  }
`;

const FormLine = styled.div`
  display: ${props => (props.noDisplay ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 0;
  width: 300px;

  &:last-child {
    margin-top: 25px;
  }
`;

const Button = styled.button`
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 27.5px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:hover {
    background-color: ${darken(0.1, '#35BB9A')};
    border-color: ${darken(0.1, '#35BB9A')};
  }
`;

const Input = styled(NormalInput)`
  border-color: ${props => (props.isValid ? 'rgba(0, 0, 0, .25)' : '#A01836')};
`;

const Error = styled.p`
  color: #A01836;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 25px;
`;

const Title = styled.div`
  color: #1B1B1B;
  font-size: 30px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 46px;
  text-align: center;
  
  @media (max-width: 400px) {
    margin-top: 20px;
    font-size: 23px;
    padding: 0 10px;
  }
`;

const Text = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #1B1B1B;
  letter-spacing: 0;
  line-height: 20px;
  margin: 21px auto 0;
  text-align: center;
  max-width: 500px;
  
  @media (max-width: 400px) {
    width: auto;
    padding: 0 10px;
    margin: 10px auto 0;
  }
`;

const ErrorText = styled.p`
  color: #A01836;
`;

const renderField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const isValid = !(submitCount > 0 && touched[field.name] && errors[field.name]);

  return (
    <FormLine>
      <Input isValid={isValid} {...field} {...props} />
      <Error>
        {!isValid && <ErrorMessage name={field.name} component="span" />}
      </Error>
    </FormLine>
  );
};

const initialValues = {
  [PASSWORD_INPUT_NAME]: '',
};

class EmployeeSetUserEmailForm extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.profile.userEmail !== '') {
      this.handleRedirect();
    }
  }

  onSubmit = (values) => {
    const password = values[PASSWORD_INPUT_NAME];
    const { token } = this.props.match.params;

    this.props.confirmPersonalEmailRequest(token, password);
  };

  handleRedirect = () => {
    this.props.history.replace('/');
  };

  render() {
    const { isSubmitting, isFail, isSucceeded } = this.props;

    return (
      <React.Fragment>
        <Title>
          {!isSucceeded && 'Чтобы подтвердить персональный E-mail введите текущий пароль.'}
          {isSucceeded && 'E-mail подтвержден'}
        </Title>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={this.onSubmit}
        >
          {() => (
            <Form>
              {(!isSubmitting && !isSucceeded) && (
                <div>
                  {isFail && (
                    <Text>
                      <ErrorText>Произошла непредвиденная ошибка.</ErrorText>
                      <br />
                      Повторите попытку позже или обратитесь
                      <br />
                      в службу технической поддержки
                      {' '}
                      <A href="mailto:trainer@readom.pro">trainer@readom.pro</A>
.
                    </Text>
                  )}
                  <FormInner>
                    <Field
                      name={PASSWORD_INPUT_NAME}
                      type="password"
                      placeholder="Текущий пароль"
                      component={renderField}
                    />
                    <FormLine>
                      <Button type="submit" disabled={isSubmitting}>Подтведить E-mail</Button>
                    </FormLine>
                  </FormInner>
                </div>)}
              {isSubmitting && (
                <div>
                  <Text>
                    <LoadingIndicator />
                  </Text>
                </div>
              )}

              {(!isSubmitting && isSucceeded) && (
                <div>
                  <Text>
                    Теперь вы можете приобретать игры персонально для себя.
                  </Text>
                  <FormInner>
                    <FormLine>
                      <Button type="button" onClick={this.handleRedirect}>ОК</Button>
                    </FormLine>
                  </FormInner>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    confirmPersonalEmailRequest: (token, password) => dispatch(confirmPersonalEmailRequest({
      token,
      password,
    })),
  };
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: makeSelectIsSubmitting(),
  isSucceeded: makeSelectIsSucceeded(),
  isFail: makeSelectIsFail(),
  profile: makeSelectProfile(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(EmployeeSetUserEmailForm);
