export const NEW_GAME_REQUEST = 'readom/MyGame/NEW_GAME_REQUEST';
export const NEW_GAME_REQUEST_SUCCESS = 'readom/MyGame/NEW_GAME_REQUEST_SUCCESS';
export const NEW_GAME_REQUEST_FAIL = 'readom/MyGame/NEW_GAME_REQUEST_FAIL';

export const CONTINUE_GAME_REQUEST = 'readom/MyGame/CONTINUE_GAME_REQUEST';
export const CONTINUE_GAME_REQUEST_SUCCESS = 'readom/MyGame/CONTINUE_GAME_REQUEST_SUCCESS';
export const CONTINUE_GAME_REQUEST_FAIL = 'readom/MyGame/CONTINUE_GAME_REQUEST_FAIL';

export const CONTINUE_GAME_FROM_SAVE_REQUEST = 'readom/MyGame/CONTINUE_GAME_FROM_SAVE_REQUEST';
export const CONTINUE_GAME_FROM_SAVE_REQUEST_SUCCESS = 'readom/MyGame/CONTINUE_GAME_FROM_SAVE_REQUEST_SUCCESS';
export const CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL = 'readom/MyGame/CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL';

export const GAME_INFO_REQUEST = 'readom/MyGame/GAME_INFO_REQUEST';
export const GAME_INFO_REQUEST_SUCCESS = 'readom/MyGame/GAME_INFO_REQUEST_SUCCESS';
export const GAME_INFO_REQUEST_FAIL = 'readom/MyGame/GAME_INFO_REQUEST_FAIL';

export const UNCHECK_CONTINUE_GAME = 'readom/MyGame/UNCHECK_CONTINUE_GAME';

export const START_NEW_SUPER_GAME = 'readom/MyGame/START_NEW_SUPER_GAME';
export const NEXT_STEP_REQUEST = 'readom/MyGame/NEXT_STEP_REQUEST';
export const NEXT_STEP_REQUEST_SUCCESS = 'readom/MyGame/NEXT_STEP_REQUEST_SUCCESS';
export const NEXT_STEP_REQUEST_FAIL = 'readom/MyGame/NEXT_STEP_REQUEST_FAIL';

export const CHECK_ANSWER = 'readom/MyGame/CHECK_ANSWER';

export const GET_VARS_REQUEST = 'readom/MyGame/GET_VARS_REQUEST';
export const GET_VARS_REQUEST_SUCCESS = 'readom/MyGame/GET_VARS_REQUEST_SUCCESS';
export const GET_VARS_REQUEST_FAIL = 'readom/MyGame/GET_VARS_REQUEST_FAIL';

export const GET_FINISH_VARS_REQUEST = 'readom/MyGame/GET_FINISH_VARS_REQUEST';
export const GET_FINISH_VARS_REQUEST_SUCCESS = 'readom/MyGame/GET_FINISH_VARS_REQUEST_SUCCESS';
export const GET_FINISH_VARS_REQUEST_FAIL = 'readom/MyGame/GET_FINISH_VARS_REQUEST_FAIL';

export const GET_RECEIVED_KEYS_REQUEST = 'readom/MyGame/GET_RECEIVED_KEYS_REQUEST';
export const GET_RECEIVED_KEYS_REQUEST_SUCCESS = 'readom/MyGame/GET_RECEIVED_KEYS_REQUEST_SUCCESS';
export const GET_RECEIVED_KEYS_REQUEST_FAIL = 'readom/MyGame/GET_RECEIVED_KEYS_REQUEST_FAIL';

export const GET_KEYS_REQUEST = 'readom/MyGame/GET_KEYS_REQUEST';
export const GET_KEYS_REQUEST_SUCCESS = 'readom/MyGame/GET_KEYS_REQUEST_SUCCESS';
export const GET_KEYS_REQUEST_FAIL = 'readom/MyGame/GET_KEYS_REQUEST_FAIL';

export const GET_TIPS_REQUEST = 'readom/MyGame/GET_TIPS_REQUEST';
export const GET_TIPS_REQUEST_SUCCESS = 'readom/MyGame/GET_TIPS_REQUEST_SUCCESS';
export const GET_TIPS_REQUEST_FAIL = 'readom/MyGame/GET_TIPS_REQUEST_FAIL';

export const GET_HIGHLIGHTTIP_REQUEST = 'readom/MyGame/GET_HIGHLIGHTTIP_REQUEST';
export const GET_HIGHLIGHTTIP_REQUEST_SUCCESS = 'readom/MyGame/GET_HIGHLIGHTTIP_REQUEST_SUCCESS';
export const GET_HIGHLIGHTTIP_REQUEST_FAIL = 'readom/MyGame/GET_HIGHLIGHTTIP_REQUEST_FAIL';

export const GET_FIFTY_FIFTY_REQUEST = 'readom/MyGame/GET_FIFTY_FIFTY_REQUEST';
export const GET_FIFTY_FIFTY_REQUEST_SUCCESS = 'readom/MyGame/GET_FIFTY_FIFTY_REQUEST_SUCCESS';
export const GET_FIFTY_FIFTY_REQUEST_FAIL = 'readom/MyGame/GET_FIFTY_FIFTY_REQUEST_FAIL';

export const GET_SAVE_REQUEST = 'readom/MyGame/GET_SAVE_REQUEST';
export const GET_SAVE_REQUEST_SUCCESS = 'readom/MyGame/GET_SAVE_REQUEST_SUCCESS';
export const GET_SAVE_REQUEST_FAIL = 'readom/MyGame/GET_SAVE_REQUEST_FAIL';

export const GET_TIPS_PRICE_REQUEST = 'readom/MyGame/GET_TIPS_PRICE_REQUEST';
export const GET_TIPS_PRICE_REQUEST_SUCCESS = 'readom/MyGame/GET_TIPS_PRICE_REQUEST_SUCCESS';
export const GET_TIPS_PRICE_REQUEST_FAIL = 'readom/MyGame/GET_TIPS_PRICE_REQUEST_FAIL';

export const BUY_FIFTY_FIFTY_TIP_REQUEST = 'readom/MyGame/BUY_FIFTY_FIFTY_TIP_REQUEST';
export const BUY_FIFTY_FIFTY_TIP_REQUEST_SUCCESS = 'readom/MyGame/BUY_FIFTY_FIFTY_TIP_REQUEST_SUCCESS';
export const BUY_FIFTY_FIFTY_TIP_REQUEST_FAIL = 'readom/MyGame/BUY_FIFTY_FIFTY_TIP_REQUEST_FAIL';

export const BUY_HIGHLIGHTTIP_TIP_REQUEST = 'readom/MyGame/BUY_HIGHLIGHTTIP_TIP_REQUEST';
export const BUY_HIGHLIGHTTIP_TIP_REQUEST_SUCCESS = 'readom/MyGame/BUY_HIGHLIGHTTIP_TIP_REQUEST_SUCCESS';
export const BUY_HIGHLIGHTTIP_TIP_REQUEST_FAIL = 'readom/MyGame/BUY_HIGHLIGHTTIP_TIP_REQUEST_FAIL';

export const BUY_CARD_KEY_REQUEST = 'readom/MyGame/BUY_CARD_KEY_REQUEST';
export const BUY_CARD_KEY_REQUEST_SUCCESS = 'readom/MyGame/BUY_CARD_KEY_REQUEST_SUCCESS';
export const BUY_CARD_KEY_REQUEST_FAIL = 'readom/MyGame/BUY_CARD_KEY_REQUEST_FAIL';

export const GET_CARDS_ALL_REQUEST = 'readom/MyGame/GET_CARDS_ALL_REQUEST';
export const GET_CARDS_ALL_REQUEST_SUCCESS = 'readom/MyGame/GET_CARDS_ALL_REQUEST_SUCCESS';
export const GET_CARDS_ALL_REQUEST_FAIL = 'readom/MyGame/GET_CARDS_ALL_REQUEST_FAIL';

export const GET_SAVE_COUNT_REQUEST = 'readom/MyGame/GET_SAVE_COUNT_REQUEST';
export const GET_SAVE_COUNT_REQUEST_SUCCESS = 'readom/MyGame/GET_SAVE_COUNT_REQUEST_SUCCESS';
export const GET_SAVE_COUNT_REQUEST_FAIL = 'readom/MyGame/GET_SAVE_COUNT_REQUEST_FAIL';

export const BUY_SAVE_GAME_REQUEST = 'readom/MyGame/BUY_SAVE_GAME_REQUEST';
export const BUY_SAVE_GAME_REQUEST_SUCCESS = 'readom/MyGame/BUY_SAVE_GAME_REQUEST_SUCCESS';
export const BUY_SAVE_GAME_REQUEST_FAIL = 'readom/MyGame/BUY_SAVE_GAME_REQUEST_FAIL';

export const CHANGE_STAVKA_STATE = 'readom/MyGame/CHANGE_STAVKA_STATE';
export const CHANGE_SUPERGAME_STATE = 'readom/MyGame/CHANGE_SUPERGAME_STATE';
export const CHANGE_IS_LOADING = 'readom/MyGame/CHANGE_IS_LOADING';
export const CHANGE_IS_LOADING_CARDS = 'readom/MyGame/CHANGE_IS_LOADING_CARDS';
export const CHANGE_PRODUCT_PRICE = 'readom/MyGame/CHANGE_PRODUCT_PRICE';

export const SET_IS_FIRST_GAME = 'readom/MyGame/SET_IS_FIRST_GAME';
export const SET_IS_FIRST_GAME_TOUR_WAS_SHOWN = 'readom/MyGame/SET_IS_FIRST_GAME_TOUR_WAS_SHOWN';

export const SET_USER_EMAIL_REQUEST = 'readom/MyGame/SET_USER_EMAIL_REQUEST';
export const SET_USER_EMAIL_REQUEST_SUCCESS = 'readom/MyGame/SET_USER_EMAIL_REQUEST_SUCCESS';
export const SET_USER_EMAIL_REQUEST_FAIL = 'readom/MyGame/SET_USER_EMAIL_REQUEST_FAIL';
export const SET_USER_EMAIL_RESET = 'readom/MyGame/SET_USER_EMAIL_RESET';

export const RESET_MY_GAME_STATE = 'readom/MyGame/RESET_MY_GAME_STATE';

export const SET_SHOW_ACTION_BUTTON_ON_MOBILE = 'readom/MyGame/SET_SHOW_ACTION_BUTTON_ON_MOBILE';

export const SET_ACTIVE_DEAL_STAGE = 'readom/MyGame/SET_ACTIVE_DEAL_STAGE';
export const SET_DEAL_STAGE_INFO = 'readom/MyGame/SET_DEAL_STAGE_INFO';

export const SET_WIDGET_LIST = 'readom/MyGame/SET_WIDGET_LIST';
