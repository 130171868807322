import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/browser';
import getConnectionType from './getConnectionType';
import isServer from './isServer';
import axios from './customAxios';
import URL_CONSTANTS from '../urlConstants';

import isUserAuthenticated from './isUserAuthenticated';

const auth = (cookies, onlyForAuthenticated) => new Promise((resolve, reject) => {
  if (!onlyForAuthenticated) {
    resolve();
    return;
  }

  if (!isUserAuthenticated(cookies)) {
    if (!isServer()) {
      if (
        window.location.pathname !== URL_CONSTANTS.URLS.LOGIN
        || window.location.pathname !== URL_CONSTANTS.URLS.REGISTRATION
      ) {
        console.info('navigate to login user is not authenticated');
        window.location = URL_CONSTANTS.URLS.LOGIN;
      }
    }

    reject();
  } else {
    resolve();
  }
});

const cookies = new Cookies();

// TODO: _cookies иногда не передаются, например на установлении пароля.

const getUrlParts = (url) => {
  if (!url) {
    return null;
  }

  const baseUrl = `${process.env.REACT_APP_API_URL}/datasnap/rest/TReadom/`;

  const s = url.replace(baseUrl, '');
  const n = s.indexOf('/');
  const methodName = s.substring(0, n !== -1 ? n : s.length);
  const params = s.replace(methodName, '');

  return {
    methodName,
    params,
  };
};

// eslint-disable-next-line max-len
const apiRequest = (_cookies, onlyForAuthenticated = true) => (url, options = {}) => auth(_cookies, onlyForAuthenticated)
  .then(() => {
    const editedOptions = options;
    let sessionID = cookies.get('sessionID');
    const guestSessionID = cookies.get('guestSessionID');

    if (sessionID && guestSessionID) {
      cookies.remove('guestSessionID', { path: '/' });
      cookies.remove('guestSessionExpires', { path: '/' });
    }

    if (!sessionID && guestSessionID) {
      sessionID = guestSessionID;
    }

    if (sessionID) {
      if (isUndefined(editedOptions.headers)) {
        editedOptions.headers = {
          Pragma: `dssession=${sessionID}`,
        };
      } else {
        editedOptions.headers.Pragma = `dssession=${sessionID}`;
      }
    }

    return axios(url, editedOptions).then((response) => {
      const isFail = get(response, 'data.result[0].Result', null) === 'fail';
      if (isFail) {
        const message = get(response, 'data.result[0].Message', '');
        console.groupCollapsed(`Result Fail on url ${url}.`);
        console.info(`Result Fail on url ${url}`);
        console.info('response');
        console.info(response);
        console.info('message');
        console.info(message);
        console.groupEnd();

        if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
          // const { methodName } = getUrlParts(get(response, 'config.url', null));

          Sentry.withScope((scope) => {
            const connectionType = getConnectionType();
            if (connectionType) {
              scope.setTag('connectionType', connectionType);
            }

            scope.setExtra('response', response);
            scope.setExtra('message', message);
            // scope.setTag('methodName', methodName);
            Sentry.captureMessage(`Result Fail on url ${url}.`);
          });
        }
      }

      return response;
    });
  }).catch((error) => {
    if (error) {
      if (error.code === 'ECONNABORTED') {
        // Запрос был отменен, это не ошибка.

        return false;
      }

      const { response } = error;

      if (response) {
        const { status } = error.response;
        if (status === 403) {
          cookies.remove('sessionID', { path: '/' });
          cookies.remove('sessionExpires', { path: '/' });
          cookies.remove('companyName', { path: '/' });
          cookies.remove('departmentName', { path: '/' });
          cookies.remove('guestSessionID', { path: '/' });
          cookies.remove('guestSessionExpires', { path: '/' });

          if (!isServer()) {
            setTimeout(() => {
              console.info('user redirect to login page on 403 error on ajax');
              window.location.href = '/';
              window.location.reload();
            }, 10);
          }

          return false;
        }
      }

      if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
        // const { methodName } = getUrlParts(get(response, 'config.url', null));

        Sentry.withScope((scope) => {
          scope.setExtra('response', response);
          // if (methodName) {
          //   scope.setTag('methodName', methodName);
          // }

          const connectionType = getConnectionType();
          if (connectionType) {
            scope.setTag('connectionType', connectionType);
          }

          Sentry.captureMessage(`NetworkError ${url}.`);
        });

        return false;
      }

      console.error(error);
      throw error;
    }

    // error EMPTY
    if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        const connectionType = getConnectionType();
        if (connectionType) {
          scope.setTag('connectionType', connectionType);
        }

        Sentry.captureMessage(`NetworkError ${url}. Empty error.`);
      });

      return error;
    }

    console.info('Try call apiRequest while guest');
    return error;
  });

export {
  apiRequest,
  auth,
};
