import React from 'react';
import { ErrorMessage } from 'formik';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import Error from '../../components/Error';

const renderPhoneInput = (
  {
    field,
    form: {
      errors, touched, setFieldTouched, setFieldValue, submitCount,
    },
    ...props
  },
) => {
  const isInvalid = errors[field.name] && (touched[field.name] || submitCount > 0);

  const onPhoneNumberChange = (value) => {
    setFieldValue(field.name, value);
  };

  const onPhoneNumberBlur = () => {
    setFieldTouched(field.name, true);
  };

  return (
    <div
      className={classNames([
        'r-phone-input',
        isInvalid && 'r-phone-input-error',
      ])}
    >
      <PhoneInput
        country="RU"
        value={field.value}
        name={field.name}
        onBlur={onPhoneNumberBlur}
        onChange={onPhoneNumberChange}
        {...props}
      />
      <Error>
        <ErrorMessage name={field.name} />
      </Error>
    </div>
  );
};

export default renderPhoneInput;
