import get from 'lodash/get';
import {
  call, select, put, takeLeading, delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import passwordHash from '../../../utils/passwordHash';
import { apiRequest as createApiRequest } from '../../../utils/request';

import { loginSuccess, loginFail } from '../../modules/global/actions';
import { makeSelectApiUrl, makeSelectLogin, makeSelectPassword } from '../../modules/global/selectors';
import {
  LOGOUT,
  LOGIN_REQUEST,
} from '../../modules/global/constants';
import { UNKNOWN_ERROR } from './errorCodes';
import getErrorMessageByErrorCode from './getErrorMessageByErrorCode';

import URL_CONSTANTS from '../../../urlConstants';

function build(cookies) {
  const apiRequest = createApiRequest(cookies, false);

  function* logoutSaga() {
    localStorage.removeItem('DAdminSessionId');
    cookies.remove('userID', { path: '/' });
    cookies.remove('sessionID', { path: '/' });
    cookies.remove('sessionExpires', { path: '/' });
    cookies.remove('companyName', { path: '/' });
    cookies.remove('departmentName', { path: '/' });
    cookies.remove('guestSessionID', { path: '/' });
    cookies.remove('guestSessionExpires', { path: '/' });

    yield delay(100);
    yield put(push(URL_CONSTANTS.URLS.LOGIN));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  function* loginSaga() {
    const url = yield select(makeSelectApiUrl());
    const usernameRaw = yield select(makeSelectLogin());
    const password = yield select(makeSelectPassword());
    let username = '';
    if (isString(usernameRaw)) {
      username = usernameRaw.trim();
    }
    const passwordHashValue = passwordHash(password);
    const requestURL = `${url}/Login/${encodeURIComponent(username)}/${encodeURIComponent(passwordHashValue)}`;

    try {
      const request = yield call(apiRequest, requestURL);

      const isSuccess = get(request, 'data.result[0].Result', null) === 'success';

      if (isSuccess) {
        const sessionID = request.headers.pragma;

        let sessIndx = sessionID.indexOf('dssession=');
        if (sessIndx > -1) {
          let commaIndx = sessionID.indexOf(',', sessIndx);
          commaIndx = commaIndx < 0 ? sessionID.length : commaIndx;
          sessIndx += 10;
          const sessionId = sessionID.substr(sessIndx, (commaIndx - sessIndx));

          let expiresIndx = sessionID.indexOf('dssessionexpires=');
          if (expiresIndx > -1) {
            commaIndx = sessionID.indexOf(',', expiresIndx);
            commaIndx = commaIndx < 0 ? sessionID.length : commaIndx;
            expiresIndx += 17;

            const cookieMaxAge = (parseInt(sessionID.substr(expiresIndx, (commaIndx - expiresIndx)), 10) / 1000);

            cookies.set('sessionID', sessionId, { maxAge: cookieMaxAge, path: '/' });
            cookies.set('sessionExpires', cookieMaxAge, { path: '/' });
            cookies.set('userID', request.data.result[0].UserID, { maxAge: cookieMaxAge, path: '/' });

            cookies.remove('guestSessionID', { path: '/' });
            cookies.remove('guestSessionExpires', { path: '/' });

            if (!isUndefined(request.data.result[0].CompanyName)) {
              cookies.set('companyName', request.data.result[0].CompanyName, {
                maxAge: cookieMaxAge,
                path: '/',
              });
            }

            if (!isUndefined(request.data.result[0].DepartmentName)) {
              cookies.set('departmentName', request.data.result[0].DepartmentName, {
                maxAge: cookieMaxAge,
                path: '/',
              });
            }
          }
        }

        localStorage.setItem('DAdminSessionId', request.data.result[0].ServerSessionID);

        const result = {
          id: request.data.result[0].UserID,
          isEmployee: request.data.result[0].IsEmployee,
        };

        yield put(loginSuccess(result));
        yield put(push(URL_CONSTANTS.URLS.INDEX_PAGE));
      } else {
        const errorCode = get(request, 'data.result[0].errorCode', null);
        const errorMessage = getErrorMessageByErrorCode(errorCode);
        yield put(loginFail({
          errorMessage,
          errorCode,
        }));
      }
    } catch (e) {
      const errorCode = UNKNOWN_ERROR;
      const errorMessage = getErrorMessageByErrorCode(errorCode);
      console.error(e);
      yield put(loginFail({
        errorMessage,
        errorCode,
      }));
    }
  }

  function* authSaga() {
    yield takeLeading(LOGIN_REQUEST, loginSaga);
    yield takeLeading(LOGOUT, logoutSaga);
  }

  return authSaga;
}

export default build;
