import styled from 'styled-components';
import pure from 'recompose/pure';

const AppWrapper = pure(styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    overflow: hidden;
  }
`);

export default AppWrapper;
