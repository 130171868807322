import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import pure from 'recompose/pure';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  setEmployeeListFilterDepartment,
  setEmployeeListFilterKeyword,
  setEmployeeListFilterStatus,
} from '../../../redux/modules/employees/actions';
import { device } from '../../../utils/mediaQueries';

import LensImage from './lens.svg';
import Select from '../../../components/Select';
import { makeSelectDepartments, makeSelectEmployeeFilterValues } from '../../../redux/modules/employees/selectors';

const Root = pure(styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`);

const FirstRow = pure(styled.div`
  display: flex;
  width: 100%;
  padding: 10px 15px;
`);

const SelectFilters = pure(styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  
  @media ${device.tablet} {
    flex-direction: column;
    & > div {
      margin-bottom: 10px;
    }
    
    & > div:last-child {
      margin-bottom: 0;
    }
  }
`);

const SearchRow = pure(styled.input`
  display: flex;
  width: 100%;
  border-top: 1px solid #DEDEDE;
  padding: 0 15px;
  outline: none;
  font-weight: bold;
  padding-left: 48px;
  height: 60px;
  background-image: url(${LensImage});
  background-repeat: no-repeat;
  background-position: 14px 19px;
`);

const FilterSelect = pure(styled(Select)`
  width: 240px;
  margin-right: 10px;
`);

const statuses = [
  {
    value: 0,
    label: 'Активный',
  },
  {
    value: 2,
    label: 'Требуется указать пароль',
  },
  {
    value: 1,
    label: 'Заблокированный',
  },
];

const Filter = (props) => {
  const onKeywordChange = (e) => {
    props.setKeyword(e.target.value);
  };

  const onDepartmentChange = (value) => {
    props.setDepartment(value);
  };

  const onStatusChange = (value) => {
    props.setStatus(value);
  };

  const { departments, filterValues } = props;

  const departmentFilterValue = get(filterValues, 'departmentId', null);
  const statusFilterValue = get(filterValues, 'status', null);
  const keyword = get(filterValues, 'keyword', '');

  let departmentFilterOptions = [];
  if (departments) {
    departmentFilterOptions = departments.map(department => ({
      value: department.id,
      label: department.name,
    }));
  }

  const { renderAddButton } = props;

  return (
    <Root>
      <FirstRow>
        <SelectFilters>
          <FilterSelect
            classNamePrefix="react-select"
            value={departmentFilterValue}
            onChange={onDepartmentChange}
            options={departmentFilterOptions}
            isSearchable={false}
            isClearable
            floatingLabel="Команда"
            placeholder="Все команды"
          />
          <FilterSelect
            classNamePrefix="react-select"
            value={statusFilterValue}
            onChange={onStatusChange}
            options={statuses}
            isSearchable={false}
            isClearable
            floatingLabel="Статус"
            placeholder="Все статусы"
          />
        </SelectFilters>
        {renderAddButton()}
      </FirstRow>
      <SearchRow
        value={keyword}
        onChange={onKeywordChange}
        placeholder="Имя, фамилия, E-mail сотрудника"
      />
    </Root>
  );
};

Filter.propTypes = {
  departments: PropTypes.any,
  filterValues: PropTypes.any,
  renderAddButton: PropTypes.func.isRequired,
  setKeyword: PropTypes.func.isRequired,
  setDepartment: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export function mapDispatchToProps(dispatch) {
  return {
    setKeyword: value => dispatch(setEmployeeListFilterKeyword(value)),
    setDepartment: value => dispatch(setEmployeeListFilterDepartment(value)),
    setStatus: value => dispatch(setEmployeeListFilterStatus(value)),
  };
}

const mapStateToProps = createStructuredSelector({
  departments: makeSelectDepartments(),
  filterValues: makeSelectEmployeeFilterValues(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Filter);
