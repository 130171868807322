import styled from 'styled-components';

const Text = styled.p`
  color: #FFFFFF;
  font-family: 'Neucha', sans-serif;
  font-size: 18px;
  text-align: justify;
`;

export default Text;
