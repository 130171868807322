import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import CrossSVG from './images/cross.svg';
import DarkCover from '../../assets/gameCover/million/dark.jpg';
import StartTrialGameWithPhoneForm from '../StartTrialGameWithPhoneForm';

const PopupWrapper = styled.div`
  display: flex;
  left: 0;
  height: 100vh;
  overflow: auto;
  padding: 15px 5px;
  position: fixed;
  top: 0;
  transition: opacity .5s ease-out;
  width: 100vw;
  z-index: 21;
  pointer-events: none;
`;

const PopupInner = styled.div`
  pointer-events: auto;
  background-color: #fff;
  border-radius: 5px;
  margin: 0;
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 500px;
  min-width: 320px;
  z-index: 10;

  @media (max-width: 600px) {
    max-width: 400px;
  }
`;

const PopupHeader = styled.div`
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 100px;
  padding: 0 25px;
  position: relative;
  background: url(${DarkCover});
  background-position: -11px -44px;
  background-size: 920px;
  background-repeat: no-repeat;
  
  @media (max-width: 600px) {
    height: 80px;
    padding: 0 45px 0 15px;
  }
`;

const PopupTitle = styled.h1`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  margin: 0;
`;

const PopupBody = styled.div`
  margin: 0 15px;
  padding: 15px 0;
  
  @media (max-width: 600px) {
    margin: 0 10px;
    padding: 10px 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CloseIcon = styled.div`
  background: url(${CrossSVG});
  background-size: 15px 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.15s;
  &:hover {
    opacity: 1;
  }
`;

const Title = styled.div`
  font-family: 'Merriweather', serif;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 0;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const MegaText = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: 'Merriweather', serif;
  line-height: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FormWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  position: relative;
`;

const Subtitle = styled.div`
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.50);;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 5px;
  
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 14px;
    padding-right: 30px;
    padding-top: 0;
  }
`;

class StartTrialGameWithPhonePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.any,
    subtitle: PropTypes.any,
  };

  handleClickOutsidePopup = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const {
      onClose, title, subtitle,
    } = this.props;

    return (
      <>
        <Overlay onClick={this.handleClickOutsidePopup} />
        <PopupWrapper>
          <PopupInner>
            <PopupHeader>
              <PopupTitle>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
              </PopupTitle>
              <CloseIcon onClick={onClose} />
            </PopupHeader>
            <PopupBody>
              <MegaText>
                Получить демо доступ к&nbsp;самой полной профессиональной модели продаж в&nbsp;России
              </MegaText>
              <FormWrapper>
                <StartTrialGameWithPhoneForm />
              </FormWrapper>
            </PopupBody>
          </PopupInner>
        </PopupWrapper>
      </>
    );
  }
}

export default StartTrialGameWithPhonePopup;
