import { push } from 'connected-react-router';
import find from 'lodash/find';
import get from 'lodash/get';
import {
  call, put, select, takeLeading, delay, all,
} from 'redux-saga/effects';
import URL_CONSTANTS from '../../../urlConstants';

import { apiRequest as createApiRequest } from '../../../utils/request';
import { makeSelectActiveGameId } from '../../modules/activeGame/selectors';
import { getFinishPageReportFail, getFinishPageReportSuccess } from '../../modules/finishPage/actions';
import { GET_FINISH_PAGE_REPORT } from '../../modules/finishPage/constants';
import { makeSelectGames, makeSelectGamesShowcases } from '../../modules/games/selectors';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import { getReceivedKeysRequestSuccess } from '../../modules/myGame/actions';
import { makeSelectSession } from '../../modules/myGame/selectors';


function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* getFinishPageReportSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameSessionId = yield select(makeSelectSession());

    const requestReceivedKeysURL = `${url}/CardKeySessionGet/${encodeURIComponent(gameSessionId)}`;

    try {
      const requestReceivedKeys = yield call(apiRequest, requestReceivedKeysURL);

      const userKeySession = requestReceivedKeys.data.result[0].UserKeySession;
      const cardList = requestReceivedKeys.data.result[0].CardList;

      yield put(getReceivedKeysRequestSuccess({ userKeySession, cardList }));
    } catch (e) {
      yield put(getFinishPageReportFail());
    }

    try {
      const activeGameId = yield select(makeSelectActiveGameId());

      let games = yield select(makeSelectGames());
      let gamesShowcases = yield select(makeSelectGamesShowcases());

      if (!activeGameId) {
        yield put(push(URL_CONSTANTS.URLS.GAMES_STORE));
        return;
      }

      // TODO: HOTFIX надо переделать чтобы без datapolling работало
      while (!games || !gamesShowcases) {
        yield delay(100);

        games = yield select(makeSelectGames());
        gamesShowcases = yield select(makeSelectGamesShowcases());
      }

      const allGames = [...games, ...gamesShowcases];

      const currentGame = find(allGames, { GameID: activeGameId });

      const viewNameTop1 = get(currentGame, 'OptionsValues.Report_FinishGame_Top1');
      const viewNameTop2 = get(currentGame, 'OptionsValues.Report_FinishGame_Top2');
      const viewNameBottom1 = get(currentGame, 'OptionsValues.Report_FinishGame_Bottom1');
      const viewNameBottom2 = get(currentGame, 'OptionsValues.Report_FinishGame_Bottom2');

      const requestURLtopOne = `${url}/ReportGet/${viewNameTop1}///0/1/user`;
      const requestURLtopTwo = `${url}/ReportGet/${viewNameTop2}///0/1/user`;
      const requestURLbottomOne = `${url}/ReportGet/${viewNameBottom1}///0/1/user`;
      const requestURLbottomTwo = `${url}/ReportGet/${viewNameBottom2}///0/1/user`;

      const [topOne, topTwo, bottomOne, bottomTwo] = yield all([
        call(apiRequest, requestURLtopOne),
        call(apiRequest, requestURLtopTwo),
        call(apiRequest, requestURLbottomOne),
        call(apiRequest, requestURLbottomTwo),
      ]);

      const dataTopOne = get(topOne, 'data.result[0].Data[0]');
      const fieldsTopOne = get(topOne, 'data.result[0].FieldList', null);

      const dataTopTwo = get(topTwo, 'data.result[0].Data[0]');
      const fieldsTopTwo = get(topTwo, 'data.result[0].FieldList', null);

      const dataBottomOne = get(bottomOne, 'data.result[0].Data[0]');
      const fieldsBottomOne = get(bottomOne, 'data.result[0].FieldList', null);

      const dataBottomTwo = get(bottomTwo, 'data.result[0].Data[0]');
      const fieldsBottomTwo = get(bottomTwo, 'data.result[0].FieldList', null);

      yield put(getFinishPageReportSuccess({
        dataTopOne,
        fieldsTopOne,
        dataTopTwo,
        fieldsTopTwo,
        dataBottomOne,
        fieldsBottomOne,
        dataBottomTwo,
        fieldsBottomTwo,
      }));
    } catch (err) {
      yield put(getFinishPageReportFail());
    }
  }

  function* finishPageSaga() {
    yield takeLeading(GET_FINISH_PAGE_REPORT, getFinishPageReportSaga);
  }

  return finishPageSaga;
}

export default build;
