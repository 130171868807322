import styled from 'styled-components';

const Buttons = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  & > button {
    margin-bottom: 10px;
  }
  
  & > button:last-child {
    margin-bottom: 0;
  }
`;

export default Buttons;
