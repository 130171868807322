import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  height: 48px;
  margin-right: 35px;
  width: 48px;
`;

const SaveIcon = () => (
  <IconWrapper>
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="popup1-copy-2" transform="translate(-454.000000, -246.000000)" fillRule="nonzero" stroke="#3D3F4A" strokeWidth="2">
          <g id="icon_save_big" transform="translate(454.000000, 246.000000)">
            <path d="M1,1 L1,47 L47,47 L47,9.41421356 L38.5857864,1 L1,1 Z" id="Path-3" />
            <circle id="Oval" cx="24" cy="30" r="8" />
            <polyline id="Path-4" points="12 0 12 14 36 14 36 0" />
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
);

export default SaveIcon;
