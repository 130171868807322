import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { device } from '../../utils/mediaQueries';

const StatWrapper = styled.div`
  width: ${props => (props.wide ? '280px' : '230px')};
  text-align: center;
`;

const StatNumber = styled.div`
  color: #FFFFFF;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  font-size: 50px;
  position: relative;
  @media ${device.mobileL} {
    font-size: 35px;
  }
`;

const Text = styled.div`
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
`;

class Stat extends React.PureComponent {
  static defaultProps = {
    small: false,
  };

  static propTypes = {
    number: PropTypes.any,
    small: PropTypes.bool,
    text: PropTypes.string.isRequired,
  };

  render() {
    const { wide } = this.props;

    return (
      <StatWrapper wide={wide}>
        <StatNumber>
          {this.props.number}
        </StatNumber>
        <Text>
          {this.props.text}
        </Text>
      </StatWrapper>
    );
  }
}

export default Stat;
