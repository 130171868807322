import posed from 'react-pose';
import styled from 'styled-components';

const TooltipAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Tooltip = styled(TooltipAnimation)`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: 69px;
  top: 10px;
  line-height: 15px;
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  width: 140px;
  
  &:after, &:before {
    top: 10px;
    left: -12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transform: rotate(-90deg);
  }
  
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: -12px;
  }
  
  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: -14px;
    top: 9px;
  }
`;

export default Tooltip;
