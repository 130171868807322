import {
  INVITE_SET_PASSWORD_REQUEST,
  INVITE_SET_PASSWORD_REQUEST_SUCCESS,
  INVITE_SET_PASSWORD_REQUEST_FAIL,
  INVITE_SET_PASSWORD_RESET_FORM,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_FAIL,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_SUCCESS,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST,
} from './constants';

export function sendPasswordSetRequest(payload) {
  return {
    type: INVITE_SET_PASSWORD_REQUEST,
    ...payload,
  };
}

export function sendPasswordSetRequestSuccess() {
  return {
    type: INVITE_SET_PASSWORD_REQUEST_SUCCESS,
  };
}

export function sendPasswordSetRequestFail() {
  return {
    type: INVITE_SET_PASSWORD_REQUEST_FAIL,
  };
}

export function resetInviteSetPasswordFormState() {
  return {
    type: INVITE_SET_PASSWORD_RESET_FORM,
  };
}

export function validateToken(payload) {
  return {
    type: INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST,
    ...payload,
  };
}

export function validateTokenSuccess(isValidToken) {
  return {
    type: INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_SUCCESS,
    isValidToken,
  };
}

export function validateTokenFail() {
  return {
    type: INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST_FAIL,
  };
}
