import find from 'lodash/find';
import get from 'lodash/get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import { makeSelectActiveGameId } from '../../../../../redux/modules/activeGame/selectors';
import { trackClickGetFullAccessLink } from '../../../../../redux/modules/analytics/actions';
import {
  makeSelectGames,
  makeSelectGamesShowcases,
} from '../../../../../redux/modules/games/selectors';

const mapStateToProps = createStructuredSelector({
  activeGameId: makeSelectActiveGameId(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
});

const mapDispatchToProps = dispatch => ({
  trackClickGetFullAccessLink: () => dispatch(trackClickGetFullAccessLink()),
});

const withAllGamesProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
  withAllGamesProps,
  withProps((props) => {
    const currentGameInfo = find(props.allGames, { GameID: props.activeGameId }, false);
    const isTrialGame = get(currentGameInfo, 'IsTrialPermission', false)
      && !get(currentGameInfo, 'HasPermission', false);

    return { isTrialGame };
  }),
);

export default enhance;
