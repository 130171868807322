import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { device } from '../../utils/mediaQueries';

import EditIcon from './edit.svg';
import DeleteIcon from './delete.svg';

const Wrapper = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-top: 15px;
  margin-right: 15px;
  min-width: 320px;
  width: calc((100% - 15px) / 2);
  
  &:nth-child(2n) {
    margin-right: 0;
  }

  @media (max-width: 790px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    margin-top: 0;
    max-width: 400px;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const HeaderBody = styled.div`
  width: 100%;
`;

const Title = styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const LeftBody = styled.div`
  width: 30%;
`;

const RightBody = styled.div`
  text-align: right;
`;

const Text = styled.p`
  color: #808085;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 25px;
`;


const Buttons = styled.div`
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: flex-end;
  
  @media ${device.tablet} {
    height: 50px;
  }
`;

const Edit = styled.a`
  background-image: url(${EditIcon});
  background-size: cover;
  cursor: pointer;
  height: 15px;
  display: inline-block;
  margin-right: 15px;
  transition: opacity .3s ease-out;
  width: 15px;

  &:hover {
    opacity: .75;
  }
  
  @media ${device.tablet} {
    background-size: 20px;
    width: 50px;
    height: 50px;
    background-position: center;
    background-color: #f5f5f5;
    border-radius: 100%;
  }
`;

const Delete = styled.a`
  background-image: url(${DeleteIcon});
  background-size: cover;
  cursor: pointer;
  height: 15px;
  display: inline-block;
  transition: opacity .3s ease-out;
  width: 11px;

  &:hover {
    opacity: .75;
  }
  
  @media ${device.tablet} {
    background-size: 20px;
    width: 50px;
    height: 50px;
    background-position: center;
    background-color: #f5f5f5;
    border-radius: 100%;
  }
`;
class DepartmentCard extends React.PureComponent {
  delete = () => {
    this.props.onDelete(this.props.id);
  };

  edit = () => {
    this.props.onEdit(this.props.id, this.props.name);
  };

  render() {
    return (
      <Wrapper>
        <Header>
          <HeaderBody>
            <Title>{this.props.name}</Title>
          </HeaderBody>
        </Header>
        <Body>
          <LeftBody>
            <Text>
              {this.props.count}
              {' '}
сотрудников
            </Text>
          </LeftBody>
          <RightBody>
            <Buttons>
              {this.props.isEditable
                && (
                <Edit
                  onClick={this.edit}
                />
                )
              }
              {this.props.isDeletable
                && (
                <Delete
                  onClick={this.delete}
                />
                )
              }
            </Buttons>
          </RightBody>
        </Body>
      </Wrapper>
    );
  }
}

DepartmentCard.defaultProps = {
  isEditable: true,
  isDeletable: true,
};

DepartmentCard.propTypes = {
  count: PropTypes.number,
  id: PropTypes.string,
  isEditable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default DepartmentCard;
