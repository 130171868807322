import React from 'react';
import styled from 'styled-components';
import dateAndTime from 'date-and-time';

import * as ReportColumnTypes from '../../reportColumnTypes';
import TimeDuration from '../TimeDuration';
import ChampionCupImage from './ChampionCupImage';
import UserAvatar from './UserAvatar';

import yesImage from '../../assets/yes.svg';
import noImage from '../../assets/no.svg';

const Yes = styled.div`
  background-image: url(${yesImage});
  width: 30px;
  height: 30px;
  background-size: contain;
`;

const No = styled.div`
  background-image: url(${noImage});
  width: 30px;
  height: 30px;
  background-size: contain;
`;

const TableCellData = ({ field, value }) => {
  switch (field.ColumnType) {
    case ReportColumnTypes.DATE_TIME_COLUMN_TYPE: {
      let date = null;
      try {
        // 2020-06-13T09:31:27.946+07:00
        const tmp = value.split('+');
        const tmp2 = tmp[0].split('T');
        const dateToParse = `${tmp2[0]} ${tmp2[1]}`;

        date = dateAndTime.parse(dateToParse, 'YYYY-MM-DD HH:mm:ss.SSS');
      } catch (e) {
        return <span>{value}</span>;
      }

      if (!date) {
        return <span>{value}</span>;
      }

      return <span>{dateAndTime.format(date, 'DD.MM.YYYY HH:mm')}</span>;
    }
    case ReportColumnTypes.STRING_COLUMN_TYPE: {
      return <span>{value}</span>;
    }
    case ReportColumnTypes.PHOTO_COLUMN_TYPE: {
      return <UserAvatar value={value} />;
    }
    case ReportColumnTypes.POSITION_COLUMN_TYPE: {
      if (value === 0) {
        return <ChampionCupImage />;
      }

      return <span>{value}</span>;
    }
    case ReportColumnTypes.INTEGER_COLUMN_TYPE: {
      return <span>{value && value.toLocaleString()}</span>;
    }
    case ReportColumnTypes.FLOAT_COLUMN_TYPE: {
      return (
        <span>
          {(Math.trunc(Math.round(value * 100)) / 100).toString().replace('.', ',').replace(',00', '')}
        </span>
      );
    }
    case ReportColumnTypes.TIME_IN_SECONDS_COLUMN_TYPE: {
      if (value === 0) {
        return <span>&mdash;</span>;
      }

      return <TimeDuration duration={value} hideSeconds />;
    }
    case ReportColumnTypes.BOOLEAN_COLUMN_TYPE: {
      if (value === true) {
        return <Yes />;
      }

      return <No />;
    }
    default:
      return <span>{value}</span>;
  }
};

export default TableCellData;
