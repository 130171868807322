import styled from 'styled-components';

const GameBackground = styled.div`
  background-size: cover;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => !props.small && 'height: calc(100vw * 260 / 1440);'}
  min-height: 260px;
  padding: 40px 0;
  width: 100%;
`;

export default GameBackground;
