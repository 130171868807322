import * as Sentry from '@sentry/browser/dist/index';
import isString from 'lodash/isString';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';

import getAppVersion from './getAppVersion';
import getConnectionType from './getConnectionType';

const initSentryListener = () => {
  if (process.env.REACT_APP_SENTRY_ENABLED !== 'true') {
    return;
  }

  const appVersion = process !== 'undefined' ? process.env.REACT_APP_VERSION : null;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `readom@${getAppVersion()}`,
  });

  const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID;
  if (LOG_ROCKET_APP_ID) {
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }

  Sentry.configureScope((scope) => {
    scope.setTag('version', appVersion);
    scope.setTag('domain', window.location.host);
    const userId = Cookies.get('userID') || 'guest';
    scope.setUser({ id: userId });
  });

  const vanillaConsoleError = window.console.error;

  function onConsoleError(err) {
    vanillaConsoleError(err);

    if (isString(err)) {
      Sentry.withScope((scope) => {
        const connectionType = getConnectionType();
        if (connectionType) {
          scope.setTag('connectionType', connectionType);
        }

        Sentry.captureMessage(err);
      });
    } else {
      Sentry.captureException(err);
    }
  }

  window.console.error = onConsoleError;
};

export default initSentryListener;
