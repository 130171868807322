export const SET_MESSAGE = 'readom/Support/SET_MESSAGE';
export const SET_IMAGE = 'readom/Support/SET_IMAGE';
export const SET_GAME_SESSION_ID = 'readom/Support/SET_GAME_SESSION_ID';

export const SEND_FEED_BACK = 'readom/Support/SEND_FEED_BACK';
export const SEND_FEED_BACK_SUCCESS = 'readom/Support/SEND_FEED_BACK_SUCCESS';
export const SEND_FEED_BACK_FAIL = 'readom/Support/SEND_FEED_BACK_FAIL';

export const SHOW_POPUP = 'readom/Support/SHOW_POPUP';
export const HIDE_POPUP = 'readom/Support/HIDE_POPUP';
