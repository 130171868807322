import {
  call, select, put, takeLeading,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { Base64 } from 'js-base64';
import passwordHash from '../../../../utils/passwordHash';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import {
  sendPasswordSetRequestFail,
  sendPasswordSetRequestSuccess,
  validateTokenFail,
  validateTokenSuccess,
} from '../../../modules/forms/inviteSetPasswordForm/actions';
import { makeSelectApiUrl } from '../../../modules/global/selectors';
import {
  makeSelectToken,
  makeSelectPassword,
  makeSelectIsEmployee,
} from '../../../modules/forms/inviteSetPasswordForm/selectors';
import {
  INVITE_SET_PASSWORD_REQUEST,
  INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST,
} from '../../../modules/forms/inviteSetPasswordForm/constants';

function build({ cookies }) {
  const apiRequest = createApiRequest(cookies, false);

  function* setPasswordSaga() {
    const url = yield select(makeSelectApiUrl());
    const tokenBase64 = yield select(makeSelectToken());
    const password = yield select(makeSelectPassword());
    const token = Base64.decode(tokenBase64);

    const passwordHashValue = passwordHash(password);
    const isEmployee = yield select(makeSelectIsEmployee());

    const requestURL = `${url}/SetPassword/${encodeURIComponent(token)}/${encodeURIComponent(isEmployee)}/${encodeURIComponent(passwordHashValue)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const success = get(request, 'data.result[0].Result', false);
      if (success === 'success') {
        yield put(sendPasswordSetRequestSuccess());
      } else {
        yield put(sendPasswordSetRequestFail());
      }
    } catch (e) {
      yield put(sendPasswordSetRequestFail());
    }
  }

  function* validateTokenSaga() {
    const url = yield select(makeSelectApiUrl());
    const tokenBase64 = yield select(makeSelectToken());
    const token = Base64.decode(tokenBase64);
    const isEmployee = yield select(makeSelectIsEmployee());

    const requestURL = `${url}/ValidateSetPassword/${encodeURIComponent(token)}/${encodeURIComponent(isEmployee)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const result = get(request, 'data.result[0].Result', 'fail');
      if (result === 'success') {
        const isValidToken = get(request, 'data.result[0].IsSetPassword', false);
        yield put(validateTokenSuccess(isValidToken));
      } else {
        yield put(validateTokenFail());
      }
    } catch (e) {
      yield put(validateTokenFail());
    }
  }

  function* saga() {
    yield takeLeading(INVITE_SET_PASSWORD_REQUEST, setPasswordSaga);
    yield takeLeading(INVITE_SET_PASSWORD_VALIDATE_TOKEN_REQUEST, validateTokenSaga);
  }

  return saga;
}

export default build;
