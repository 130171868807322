import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import posed, { PoseGroup } from 'react-pose';

import buttonPoseAnimation from '../../globalConstants';
import isIOS from '../../utils/isIOS';
import scrollLock from '../../utils/scrollLock';
import SpinnerInButton from '../SpinnerInButton';
import CloseIcon from './CloseIcon';

const PopupWrapper = styled.div`
  display: flex;
  left: 0;
  height: 100vh;
  overflow: auto;
  padding: ${props => (props.small ? '15px 10px' : '15px 5px')};
  position: fixed;
  top: 0;
  transition: opacity .3s ease-out;
  width: 100vw;
  z-index: ${props => (props.isActive ? '21' : '-1')};
  ${isIOS() && 'padding-bottom: 100px'}
`;

const PopupInnerAnimation = posed.div({
  show: {
    x: 0,
    opacity: 1,
    delay: 150,
    transition: {
      default: {
        duration: 300,
      },
    },
  },
  hide: {
    x: 50,
    opacity: 0,
    transition: {
      duration: 150,
    },
  },
});

const PopupInner = styled(PopupInnerAnimation)`
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 0;
  margin: auto;
  position: relative;
  width: ${props => (props.small ? '100vw' : '595px')};
  z-index: 10;
`;

const PopupHeader = styled.div`
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 90px;
  padding: 0 30px;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 30px;
    width: calc(100% - 60px);
    height: 1px;
    background: #DEDEDE;
    content: ''
  }
`;

const PopupTitle = styled.h1`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  margin: 0;
`;

const PopupBody = styled.div`
  border-bottom: ${props => (props.withoutBorder ? 'none' : '1px solid #DEDEDE')};
  margin: ${props => (props.small ? '0 15px;' : ' 0 30px;')};
  padding: ${props => (props.withoutPadding ? 'none' : '30px 0')};
`;

const PopupFooter = styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'flex-end')};
  padding: 30px;
  align-items: center;
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
`;

const ButtonPressedAnimation = posed.button(buttonPoseAnimation);

export const Button = styled(ButtonPressedAnimation)`
  background-color: ${props => (props.isAlternative ? '#FFFFFF' : '#35BB9A')};
  border: ${props => (props.isAlternative ? '1px solid rgba(0, 0, 0, .25)' : '')};
  border-radius: 25px;
  color:  ${props => (props.isAlternative ? '#1C1C1C' : '#FFFFFF')};
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 50px;
  letter-spacing: 1px;
  margin-left: ${props => (props.allWidth ? '0' : '15px')};
  outline: none;
  padding: 0 25px;
  text-transform: uppercase;
  transition: background-color, border .3s ease-out;
  width: ${props => (props.allWidth ? '100%' : 'inherit')};
  user-select: none;
  white-space: nowrap;

  &:hover:not(:disabled) {
    background-color: ${props => (props.isAlternative ? '#FFFFFF' : darken(0.1, '#35BB9A'))};
    border: ${props => (props.isAlternative ? '1px solid rgba(0, 0, 0, 1)' : '')};
  }
  
  &:disabled {
    cursor: progress;
  }
`;

const OverlayAnimation = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const Overlay = styled(OverlayAnimation)`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

class Popup extends React.PureComponent {
  componentDidMount() {
    if (this.props.isActive) {
      scrollLock.on();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive) {
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isActive && this.props.isActive) {
      scrollLock.on();
    }

    if (prevProps.isActive && !this.props.isActive) {
      scrollLock.off();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    if (this.props.isActive) {
      scrollLock.off();
    }
  }

  handleClickOutsidePopup = (event) => {
    const { id } = event.target;
    if (id === 'popup-overlay') {
      this.props.onClose();
    }
  };

  render() {
    const pose = this.props.isActive ? 'show' : 'hide';

    const { small } = this.props;

    return (
      <PopupWrapper
        small={small}
        backgroundColor={this.props.backgroundColor}
        isActive={this.props.isActive}
      >
        <PoseGroup>
          {[
            <Overlay key="overlay" id="popup-overlay" pose={pose} onClick={this.handleClickOutsidePopup} />,
            <PopupInner
              small={small}
              pose={pose}
              key="popupInner"
              left={this.props.left}
              className="popupInner"
            >
              <PopupHeader>
                <PopupTitle>
                  {this.props.title}
                </PopupTitle>
                <CloseIcon onClose={this.props.onClose} />
              </PopupHeader>
              <PopupBody
                small={small}
                withoutPadding={this.props.withoutPadding}
                withoutBorder={this.props.withoutBorder}
              >
                {this.props.children}
              </PopupBody>
              <PopupFooter flexWrap={small && 'wrap'} justify={small && 'center'}>
                {this.props.footer}
                {this.props.cancelText && (
                  <Button type="button" isAlternative onClick={this.props.onClose}>
                    {this.props.cancelText ? this.props.cancelText : 'Отмена'}
                  </Button>
                )}
                {this.props.submitText && (
                  <Button type="button" onClick={this.props.onSubmit} disabled={this.props.pending}>
                    {!this.props.pending && (this.props.submitText ? this.props.submitText : 'Да')}
                    {this.props.pending && (
                      <SpinnerInButton />
                    )}
                  </Button>
                )}
              </PopupFooter>
            </PopupInner>,
          ]}
        </PoseGroup>
      </PopupWrapper>
    );
  }
}

Popup.propTypes = {
  backgroundColor: PropTypes.string,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  left: PropTypes.number,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  pending: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withoutPadding: PropTypes.bool,
  withoutBorder: PropTypes.bool,
};

Popup.defaultProps = {
  pending: false,
};

export default Popup;
