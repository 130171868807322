import styled from 'styled-components';
import Bg from '../../../../assets/owl-mobile.png';

const Face = styled.div`
  background-image: url(${Bg});
  background-size: contain;
  bottom: 70px;
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: fixed;
  right: 15px;
  border-radius: 50px;
  z-index: 25;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10), 0 0 2px 0 rgba(0,0,0,0.10);
`;

export default Face;
