import styled from 'styled-components';

const HeaderWrapper = styled.header`
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .12);
  display: flex;
  height: 71px;
  justify-content: space-between;
  padding: 0 15px;
  position: fixed;
  width: 100%;
  z-index: 2;
  ${props => props.small && 'height: 60px;'}
`;

export default HeaderWrapper;
