import PropTypes from 'prop-types';
import React from 'react';
import { PoseGroup } from 'react-pose';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useMedia } from 'the-platform';
import {
  makeSelectClientMoodAbsoluteValue,
  makeSelectMyMoodAbsoluteValue,
  makeSelectMyMoodDirection,
  makeSelectMyMoodStatus,
  makeSelectMyMoodValue,
} from '../../../redux/modules/myGame/selectors';
import { device } from '../../../utils/mediaQueries';

import MyStatusDemotivation from '../../../assets/statusImages/myStatusDemotivation.svg';
import MyStatusUnsure from '../../../assets/statusImages/myStatusUnsure.svg';
import MyStatusNeutral from '../../../assets/statusImages/myStatusNeutral.svg';
import MyStatusSure from '../../../assets/statusImages/myStatusSure.svg';
import MyStatusSuccessful from '../../../assets/statusImages/myStatusSuccessful.svg';
import {
  Wrapper, Mood, Up, Down, Tooltip,
} from './elements';

class MyStatus extends React.PureComponent {
  static defaultProps = {
    myMoodDirection: null,
    myMoodStatus: null,
    myMoodValue: null,
  };

  static propTypes = {
    myMoodDirection: PropTypes.string,
    myMoodStatus: PropTypes.number,
    myMoodValue: PropTypes.number,
    onClick: PropTypes.func.isRequired,
  };

  state = {
    showTooltip: false,
  };

  onMouseOver = () => {
    this.setState({
      showTooltip: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      showTooltip: false,
    });
  };

  onClick = (e) => {
    const { onClick } = this.props;

    this.setState(
      {
        showTooltip: false,
      },
      () => onClick(e),
    );
  };

  render() {
    const {
      myMoodStatus, myMoodDirection, myMoodValue, myMoodAbsoluteValue,
    } = this.props;
    if (!myMoodStatus) {
      return null;
    }

    const { showTooltip } = this.state;

    let moodImage = MyStatusNeutral;

    // TODO: HARDCODE_TYPE_2 Зашиты возможные значения настроений игрока
    switch (myMoodStatus) {
      case 1:
        moodImage = MyStatusDemotivation;
        break;
      case 2:
        moodImage = MyStatusUnsure;
        break;
      case 3:
        moodImage = MyStatusNeutral;
        break;
      case 4:
        moodImage = MyStatusSuccessful;
        break;
      case 5:
        moodImage = MyStatusSure;
        break;
      default:
        moodImage = MyStatusNeutral;
        break;
    }

    return (
      <MyStatusComponent
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        moodImage={moodImage}
        myMoodStatus={myMoodStatus}
        myMoodAbsoluteValue={myMoodAbsoluteValue}
        myMoodDirection={myMoodDirection}
        myMoodValue={myMoodValue}
        showTooltip={showTooltip}
      />
    );
  }
}

export const MyStatusComponent = (
  {
    onClick, onMouseOver, onMouseLeave,
    showTooltip, moodImage,
    myMoodDirection, myMoodStatus, myMoodAbsoluteValue,
  },
) => {
  const small = useMedia(device.tablet);

  return (
    <Wrapper small={small}>
      <div
        onClick={onClick}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onBlur={onMouseLeave}
      >
        <PoseGroup>
          <Mood backgroundImage={moodImage} key={myMoodStatus} small={small} />
        </PoseGroup>
        <PoseGroup>
          {myMoodDirection === 'up' && <Up key={`${myMoodAbsoluteValue}-up`} />}
          {myMoodDirection === 'down' && <Down key={`${myMoodAbsoluteValue}-down`} small={small} />}
        </PoseGroup>
      </div>
      <PoseGroup>
        {showTooltip && (
          <Tooltip key="tooltip">
            Это твой настрой на{'\u00A0'}сделку. Каждый выбор влияет на{'\u00A0'}него. Следи за{'\u00A0'}стрелками, они
            показывают как меняется твоё состояние.
          </Tooltip>
        )}
      </PoseGroup>
    </Wrapper>
  );
};

MyStatusComponent.propTypes = {
  moodImage: PropTypes.string.isRequired,
  myMoodAbsoluteValue: PropTypes.number.isRequired,
  myMoodDirection: PropTypes.string,
  myMoodStatus: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};

MyStatusComponent.defaultProps = {
  myMoodDirection: null,
};

const mapStateToProps = createStructuredSelector({
  myMoodStatus: makeSelectMyMoodStatus(),
  myMoodDirection: makeSelectMyMoodDirection(),
  myMoodAbsoluteValue: makeSelectMyMoodAbsoluteValue(),
  clientMoodAbsoluteValue: makeSelectClientMoodAbsoluteValue(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(MyStatus);
