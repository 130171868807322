import {
  GET_ALL_GAMES_LIST_SUCCESS,
  GET_ALL_GAMES_LIST_FAIL,
  GET_ALL_GAMES_LIST,
  GET_CART,
  GET_CART_FAIL,
  GET_CART_SUCCESS,

  ADD_SHOWCASE_TO_CART,
  ADD_SHOWCASE_TO_CART_FAIL,
  ADD_SHOWCASE_TO_CART_SUCCESS,
  CART_CHECKOUT,
  CART_CHECKOUT_SUCCESS,
  CART_CHECKOUT_FAIL,
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  BUY_SHOWCASE,
  BUY_SHOWCASE_FAIL,
  BUY_SHOWCASE_SUCCESS,
  BUY_SHOWCASE_FROM_DEMO,
  BUY_SHOWCASE_FROM_DEMO_FAIL,
  BUY_SHOWCASE_FROM_DEMO_SUCCESS,

  GET_GAMES_SHOWCASE,
  GET_GAMES_SHOWCASE_FAIL,
  GET_GAMES_SHOWCASE_SUCCESS,
} from './constants';

export function getGameListSuccess(games, gameData, gamesLicenses, showcaseList) {
  return {
    type: GET_ALL_GAMES_LIST_SUCCESS,
    games,
    gameData,
    gamesLicenses,
    showcaseList,
  };
}

export function getGameListFail() {
  return {
    type: GET_ALL_GAMES_LIST_FAIL,
  };
}

export function getGameList() {
  return {
    type: GET_ALL_GAMES_LIST,
  };
}

export function getCart() {
  return {
    type: GET_CART,
  };
}

export function getCartFail() {
  return {
    type: GET_CART_FAIL,
  };
}

export function getCartSuccess(cart) {
  return {
    type: GET_CART_SUCCESS,
    cart,
  };
}

export function addShowCaseToCart(showcaseId) {
  return {
    type: ADD_SHOWCASE_TO_CART,
    showcaseId,
  };
}

export function addShowCaseToCartFail() {
  return {
    type: ADD_SHOWCASE_TO_CART_FAIL,
  };
}

export function addShowCaseToCartSuccess() {
  return {
    type: ADD_SHOWCASE_TO_CART_SUCCESS,
  };
}

export function buyShowCase(showcaseId, nonFree) {
  return {
    type: BUY_SHOWCASE,
    payload: {
      showcaseId,
      nonFree,
    },
  };
}

export function buyShowCaseFail() {
  return {
    type: BUY_SHOWCASE_FAIL,
  };
}

export function buyShowCaseSuccess() {
  return {
    type: BUY_SHOWCASE_SUCCESS,
  };
}
export function buyShowCaseFromDemo(showcaseId) {
  return {
    type: BUY_SHOWCASE_FROM_DEMO,
    showcaseId,
  };
}

export function buyShowCaseFromDemoFail() {
  return {
    type: BUY_SHOWCASE_FROM_DEMO_FAIL,
  };
}

export function buyShowCaseFromDemoSuccess() {
  return {
    type: BUY_SHOWCASE_FROM_DEMO_SUCCESS,
  };
}

export function cartCheckout() {
  return {
    type: CART_CHECKOUT,
  };
}

export function cartCheckoutFail() {
  return {
    type: CART_CHECKOUT_FAIL,
  };
}

export function cartCheckoutSuccess() {
  return {
    type: CART_CHECKOUT_SUCCESS,
  };
}

export function getInvoices() {
  return {
    type: GET_INVOICES,
  };
}

export function getInvoicesFail() {
  return {
    type: GET_INVOICES_FAIL,
  };
}

export function getInvoicesSuccess(invoices) {
  return {
    type: GET_INVOICES_SUCCESS,
    invoices,
  };
}

export function getGamesShowcases() {
  return {
    type: GET_GAMES_SHOWCASE,
  };
}

export function getGamesShowcasesFail() {
  return {
    type: GET_GAMES_SHOWCASE_FAIL,
  };
}

export function getGamesShowcasesSuccess(gamesShowcases) {
  return {
    type: GET_GAMES_SHOWCASE_SUCCESS,
    gamesShowcases,
  };
}