import get from 'lodash/get';
import decodeHtmlEntities from '../decodeHtmlEntities';

const parseQuestNextStep = ({ request }) => {
  const data = get(request, 'data.result[0]');

  const requestData = {
    question: data.ResultQuest.QuestionText,
    image: null,
    answers: data.ResultQuest.Answers.map(answer => ({
      id: answer.AnswerID,
      text: answer.AnswerText,
      isActive: false,
    })),
    blockInstanceId: data.ResultQuest.BlockInstanceID,
    methodType: data.ResultQuest.MethodType,
    questionName: data.ResultQuest.QuestionName,
    showHighlightTip: data.ResultQuest.FlagTipHighlightTrue,
    showFiftyFiftyTip: data.ResultQuest.FlagTipFiftyFifty,
  };

  const imageRegex = /&lt;IMAGE&gt;[a-zA-Z0-9а-яА-Я:/._?&=]*&lt;\/IMAGE&gt;/i;
  const finalCupImgRe = /&lt;IMAGE_FINISH_GAME_CUP&gt;[a-zA-Z0-9а-яА-Я:/._?&=]*&lt;\/IMAGE_FINISH_GAME_CUP&gt;/i;
  const textRegex = /&lt;TEXT&gt;[a-zA-Z0-9а-яА-ЯёЁ:/._\-…,!?\s<>«»&;()"'{}]*&lt;\/TEXT&gt;/i;
  let image = null;
  let finalCupImage = null;
  const finalCupImageTexts = [];
  let text = null;

  requestData.finalCupImage = null;
  requestData.finalCupImageTexts = [];
  requestData.image = null;

  if (finalCupImgRe.test(data.ResultQuest.QuestionText)) {
    requestData.question = null;
    // eslint-disable-next-line prefer-destructuring
    finalCupImage = data.ResultQuest.QuestionText.match(finalCupImgRe)[0];
    finalCupImage = finalCupImage.replace(/&lt;IMAGE_FINISH_GAME_CUP&gt;/g, '');
    finalCupImage = finalCupImage.replace(/&lt;\/IMAGE_FINISH_GAME_CUP&gt;/g, '');

    const string = get(request, 'data.result[0].ResultQuest.QuestionText', null);

    const html = document.createElement('html');
    html.innerHTML = decodeHtmlEntities(string);
    const textElements = html.getElementsByTagName('TEXT');
    const textElementsCount = textElements.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < textElementsCount; i++) {
      finalCupImageTexts.push(textElements[i].innerHTML);
    }

    requestData.finalCupImage = `${process.env.REACT_APP_API_URL}/${finalCupImage}`;
    requestData.finalCupImageTexts = finalCupImageTexts;
  } else if (imageRegex.test(data.ResultQuest.QuestionText)) {
    // eslint-disable-next-line prefer-destructuring
    image = data.ResultQuest.QuestionText.match(imageRegex)[0];
    image = image.replace(/&lt;IMAGE&gt;/g, '');
    image = image.replace(/&lt;\/IMAGE&gt;/g, '');

    if (textRegex.test(data.ResultQuest.QuestionText)) {
      // eslint-disable-next-line prefer-destructuring
      text = data.ResultQuest.QuestionText.match(textRegex)[0];
      text = text.replace(/&lt;TEXT&gt;/g, '');
      text = text.replace(/&lt;\/TEXT&gt;/g, '');
    }

    requestData.image = `${process.env.REACT_APP_API_URL}/${image}`;
    requestData.question = text;
  }

  return requestData;
};

export default parseQuestNextStep;
