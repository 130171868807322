import isNull from 'lodash/isNull';
import map from 'lodash/map';
import { connect } from 'react-redux';

import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import { makeSelectBrowser } from '../../redux/modules/browser/selectors';
import { makeSelectSpotlightTarget, makeSelectSpotlightVisible } from '../../redux/modules/spotlight/selectors';

const mapStateToProps = createStructuredSelector({
  target: makeSelectSpotlightTarget(),
  visible: makeSelectSpotlightVisible(),
  browser: makeSelectBrowser(),
});

const withConnect = connect(mapStateToProps);

const enhance = compose(
  withConnect,
  withProps((props) => {
    const small = props.browser.is.extraSmall || props.browser.is.small;

    return { small };
  }),
);

export default enhance;
