import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Text from '../Text';

import CardBody from './CardBody';
import CardHeader from './CardHeader';
import CardItem from './CardItem';
import CardLine from './CardLine';

const StyledText = styled(Text)`
  line-height: 25px;
  margin: 0;

  &:last-child {
    max-width: 200px;
    text-align: right;
  }
`;

const Card = (props) => {
  let content = <div />;

  if (props.items) {
    content = (
      <CardBody>
        {props.items.map(object => (
          <CardLine key={object.id}>
            <StyledText>{object.title}</StyledText>
            <StyledText>{object.value}</StyledText>
          </CardLine>
        ))}
      </CardBody>
    );
  }

  return (
    <CardItem>
      <CardHeader>
        {props.title}
        {props.to
          && <Button to={props.to} isButton>Изменить</Button>
        }
      </CardHeader>
      {content}
    </CardItem>
  );
};

Card.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default Card;
