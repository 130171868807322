import React from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import SimplePageBase from '../../PageBase/SimplePageBase';
import PublicPageBase from '../../PageBase/PublicPageBase';
import PaymentMethods from './PaymentMethods';

const title = 'Способы оплаты';

const PaymentMethodsPage = ({ cookies }) => {
  const isAuthenticated = isUserAuthenticated(cookies);
  const Wrapper = isAuthenticated ? SimplePageBase : PublicPageBase;

  return (
    <Wrapper title={title}>
      <PaymentMethods />
    </Wrapper>
  );
};

PaymentMethodsPage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default withCookies(PaymentMethodsPage);
