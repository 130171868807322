import {
  GET_DEPARTMENT_LIST_REQUEST,
  GET_DEPARTMENT_LIST_REQUEST_SUCCESS,
  GET_DEPARTMENT_LIST_REQUEST_FAIL,
  GET_EMPLOYEES_LIST_REQUEST,
  GET_EMPLOYEES_LIST_REQUEST_FAIL,
  GET_EMPLOYEES_LIST_REQUEST_SUCCESS,
  SET_NEW_DEPARTMENT_REQUEST,
  SET_NEW_DEPARTMENT_REQUEST_SUCCESS,
  SET_NEW_DEPARTMENT_REQUEST_FAIL,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_REQUEST_SUCCESS,
  DELETE_DEPARTMENT_REQUEST_FAIL,
  EDIT_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_REQUEST_SUCCESS,
  EDIT_DEPARTMENT_REQUEST_FAIL,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_REQUEST_SUCCESS,
  ADD_EMPLOYEE_REQUEST_FAIL,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_REQUEST_SUCCESS,
  DELETE_EMPLOYEE_REQUEST_FAIL,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_REQUEST_SUCCESS,
  EDIT_EMPLOYEE_REQUEST_FAIL,
  CHANGE_DEPARTMENT_COUNT,
  CHANGE_LOADING,
  CHANGE_NEW_DEPARTMENT,
  CHANGE_ACTIVE_TAB,
  CHANGE_DELETE_DEPARTMENT_POPUP_STATE,
  CHANGE_DEPARTMENT_FOR_EDIT,
  CHANGE_EDIT_DEPARTMENT_POPUP_STATE,
  CHANGE_ADD_EMPLOYEE_POPUP_STATE,
  CHANGE_NEW_EMPLOYEE,
  CHANGE_DELETE_EMPLOYEE_POPUP_STATE,
  CHANGE_EMPLOYEE_FOR_EDIT,
  CHANGE_EDIT_EMPLOYEE_POPUP_STATE,
  ADD_EMPLOYEE_REQUEST_SET_ERROR,
  SET_EMPLOYEE_LIST_FILTER_DEPARTMENT,
  SET_EMPLOYEE_LIST_FILTER_KEYWORD, SET_EMPLOYEE_LIST_FILTER_STATUS,
} from './constants';

export function getDepartmentList() {
  return {
    type: GET_DEPARTMENT_LIST_REQUEST,
  };
}

export function getDepartmentListSuccess(payload) {
  return {
    type: GET_DEPARTMENT_LIST_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getDepartmentListFail() {
  return {
    type: GET_DEPARTMENT_LIST_REQUEST_FAIL,
  };
}

export function getEmployeesList() {
  return {
    type: GET_EMPLOYEES_LIST_REQUEST,
  };
}

export function getEmployeesListSuccess(payload) {
  return {
    type: GET_EMPLOYEES_LIST_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getEmployeesListFail() {
  return {
    type: GET_EMPLOYEES_LIST_REQUEST_FAIL,
  };
}

export function changeDepartmentCount(payload) {
  return {
    type: CHANGE_DEPARTMENT_COUNT,
    ...payload,
  };
}

export function changeLoading(payload) {
  return {
    type: CHANGE_LOADING,
    ...payload,
  };
}

export function changeDepartmentName(payload) {
  return {
    type: CHANGE_NEW_DEPARTMENT,
    ...payload,
  };
}

export function setNewDepartment() {
  return {
    type: SET_NEW_DEPARTMENT_REQUEST,
  };
}

export function setNewDepartmentSuccess(payload) {
  return {
    type: SET_NEW_DEPARTMENT_REQUEST_SUCCESS,
    ...payload,
  };
}

export function setNewDepartmentFail() {
  return {
    type: SET_NEW_DEPARTMENT_REQUEST_FAIL,
  };
}

export function changeActiveTab(payload) {
  return {
    type: CHANGE_ACTIVE_TAB,
    ...payload,
  };
}

export function deleteDepartment(payload) {
  return {
    type: DELETE_DEPARTMENT_REQUEST,
    ...payload,
  };
}

export function deleteDepartmentSuccess() {
  return {
    type: DELETE_DEPARTMENT_REQUEST_SUCCESS,
  };
}

export function deleteDepartmentFail() {
  return {
    type: DELETE_DEPARTMENT_REQUEST_FAIL,
  };
}

export function changeDeleteDepartmentPopupState(payload) {
  return {
    type: CHANGE_DELETE_DEPARTMENT_POPUP_STATE,
    payload,
  };
}

export function changeDepartmentForEdit(payload) {
  return {
    type: CHANGE_DEPARTMENT_FOR_EDIT,
    ...payload,
  };
}

export function changeEditDepartmentPopupState(payload) {
  return {
    type: CHANGE_EDIT_DEPARTMENT_POPUP_STATE,
    payload,
  };
}

export function editDepartment() {
  return {
    type: EDIT_DEPARTMENT_REQUEST,
  };
}

export function editDepartmentSuccess() {
  return {
    type: EDIT_DEPARTMENT_REQUEST_SUCCESS,
  };
}

export function editDepartmentFail() {
  return {
    type: EDIT_DEPARTMENT_REQUEST_FAIL,
  };
}

export function changeAddEmployeePopupState(payload) {
  return {
    type: CHANGE_ADD_EMPLOYEE_POPUP_STATE,
    payload,
  };
}

export function changeEditEmployeePopupState(payload) {
  return {
    type: CHANGE_EDIT_EMPLOYEE_POPUP_STATE,
    payload,
  };
}

export function changeDeleteEmployeePopupState(payload) {
  return {
    type: CHANGE_DELETE_EMPLOYEE_POPUP_STATE,
    payload,
  };
}

export function deleteEmployee(payload) {
  return {
    type: DELETE_EMPLOYEE_REQUEST,
    ...payload,
  };
}

export function deleteEmployeeSuccess() {
  return {
    type: DELETE_EMPLOYEE_REQUEST_SUCCESS,
  };
}

export function deleteEmployeeFail() {
  return {
    type: DELETE_EMPLOYEE_REQUEST_FAIL,
  };
}

export function changeEmployeeForEdit(payload) {
  return {
    type: CHANGE_EMPLOYEE_FOR_EDIT,
    ...payload,
  };
}

export function editEmployee() {
  return {
    type: EDIT_EMPLOYEE_REQUEST,
  };
}

export function editEmployeeSuccess() {
  return {
    type: EDIT_EMPLOYEE_REQUEST_SUCCESS,
  };
}

export function editEmployeeFail() {
  return {
    type: EDIT_EMPLOYEE_REQUEST_FAIL,
  };
}

export function setEmployeeListFilterDepartment(value) {
  return {
    type: SET_EMPLOYEE_LIST_FILTER_DEPARTMENT,
    value,
  };
}

export function setEmployeeListFilterKeyword(value) {
  return {
    type: SET_EMPLOYEE_LIST_FILTER_KEYWORD,
    value,
  };
}

export function setEmployeeListFilterStatus(value) {
  return {
    type: SET_EMPLOYEE_LIST_FILTER_STATUS,
    value,
  };
}
