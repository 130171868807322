import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import find from 'lodash/find';

const SelectElement = styled(ReactSelect)`
  &.react-select-error {
    & .react-select__control {
      border: 1px solid #A01836 !important;
    }
  }
  
  & .react-select {
    &__control {
      align-items: center;
      background: #fff;
      border: 1px solid #BFBFBF;
      border-radius: 5px;
      box-shadow: none;
      color: #808085;
      cursor: pointer;
      display: flex;
      border-spacing: 0;
      border-collapse: separate;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 15px;
      height: 50px;
      outline: none;
      overflow: hidden;
      position: relative;
      justify-content: space-between;
      padding: 0 10px;
      width: 100%;

      &:hover {
        border-color: #BFBFBF
      }
      
      &--is-disabled {
        border: 1px dotted rgba(0, 0, 0, 0.25);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__placeholder {
      margin-left: 0;
      margin-right: 0;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding-right: 0;
    }

    &__menu {
      border-radius: 5px;
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #BFBFBF;
      box-sizing: border-box;
      margin-top: 5px;
      max-height: 200px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 2;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 0;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      box-sizing: border-box;
      background-color: #fff;
      border-bottom: 1px solid #BFBFBF;
      color: #1C1C1C;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 15px;
      cursor: pointer;
      display: block;
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      transition: background-color .3s ease-out;

      &:first-child {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
  
      &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom: none;
      }
  
      &--is-selected {
        background-color: #EBEBED;
        color: #1C1C1C;
        position: relative;
  
        &:before {
          content: '';
          width:  5px;
          height: 50px;
          background-color: #D91E47;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
  
      &--is-focused {
        background-color: #EBEBED;
        color: #1C1C1C;
      }
  
      &--is-disabled {
        color: #cccccc;
        cursor: default;
      }

      &:active {
        background-color: #EBEBED;
        color: #1C1C1C;
      }
    }
  }
`;

const Group = styled.div`
  position: relative;
  width: 100%;
`;

const Root = styled.div`
  padding: 6px 0 0 0;
`;

const Label = styled.label`
  font-family: 'Helvetica Neue', Helvetica,Arial, sans-serif;
  color: #1C1C1C;
  font-size: 12px;
  transform: translate(10px, -11px);
  display: block;
  left: 0;
  margin: 0;
  top: 0;
  position: absolute;
  transition: all 0.2s, background 0.2s linear 0.2s;
  pointer-events: none;
  user-select: none;
  opacity: 1;
  z-index: 1;
  background: #fff;
  padding: 0 10px;
`;

const RequiredWithFloatingLabel = styled.span`
    color: #da4949;
    padding-left: 0.2em;
`;

const Select = (props) => {
  const {
    value, floatingLabel, required, options, disabled,
  } = props;

  const selectValue = find(options, { value }, null);

  return (
    <Root>
      <Group>
        {floatingLabel && (
          <Label emptyValue={!value}>
            {floatingLabel}
            {required && (
              <RequiredWithFloatingLabel>*</RequiredWithFloatingLabel>
            )}
          </Label>
        )}
        <SelectElement
          {...props}
          isDisabled={disabled}
          value={selectValue}
        />
      </Group>
    </Root>
  );
};

Select.propTypes = {
  floatingLabel: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Select.defaultProps = {
  floatingLabel: null,
  value: null,
  disabled: false,
};

export default Select;
