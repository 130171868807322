import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Keys } from 'react-keydown';

import LibraryPopup from './includes/LibraryPopup';

const { ESC } = Keys;

const LibraryPopupWrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;

class LibraryInPopup extends React.PureComponent {
  componentWillReceiveProps({ keydown }) {
    if (keydown.event) {
      if (keydown.event.which === ESC) {
        this.props.onClose();
      }
    }
  }

  render() {
    const {
      libraryCards, price, open, onClose, stage, stagesInfo,
      onBuyCard, scrollToCard, small,
      activeCardId, activeCardGroupId, lastBuyCard,
      activeLibraryLink,
    } = this.props;

    return (
      <LibraryPopupWrapper show={open}>
        <LibraryPopup
          small={small}
          lastBuyCard={lastBuyCard}
          onClose={onClose}
          activeCardId={activeCardId}
          activeCardGroupId={activeCardGroupId}
          activeLibraryLink={activeLibraryLink}
          libraryCards={libraryCards}
          scrollToCard={scrollToCard}
          open={open}
          price={price.price}
          stage={stage}
          stagesInfo={stagesInfo}
          onBuyCard={onBuyCard}
        />
      </LibraryPopupWrapper>
    );
  }
}

LibraryInPopup.propTypes = {
  onBuyCard: PropTypes.func,
  activeCardId: PropTypes.string,
  activeCardGroupId: PropTypes.string,
  activeLibraryLink: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  scrollToCard: PropTypes.string,
  open: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  libraryCards: PropTypes.any,
  lastBuyCard: PropTypes.any,
  price: PropTypes.any,
  stage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default LibraryInPopup;
