import React from 'react';
import PropTypes from 'prop-types';
import {
  compose, setPropTypes, withStateHandlers, lifecycle, defaultProps,
} from 'recompose';
import posed from 'react-pose';

// Declare separate animation states
const poseProps = {
  hidden: {
    opacity: 0,
    staggerChildren: 100,
    // transition: { duration: 10000 },
    applyAtEnd: {
      display: 'none',
      zIndex: -1,
    },
  },
  visible: {
    applyAtStart: {
      display: 'block',
      zIndex: 21,
    },
    opacity: 1,
    // transition: { duration: 10000 },
  },
};

// Create animated wrapper
const PosedContainer = posed.div(poseProps);

// Component with Animation
const withEnterTransition = BaseComponent => (props) => {
  const { poseState, delay, ...rest } = props;

  return (
    <PosedContainer pose={poseState}>
      <BaseComponent poseState={poseState} delay={delay} {...rest} />
    </PosedContainer>
  );
};

// Create props and prop change handlers to manage Pose State
const withState = withStateHandlers(
  ({ poseState = 'hidden' }) => ({
    poseState,
  }),
  {
    updatePose: ({ poseState }) => nextPoseState => ({
      poseState: nextPoseState,
    }),
  },
);

// When the component mounts, after a timeout, update Pose state
const withLifecycle = lifecycle({
  componentDidUpdate() {
    if (this.props.poseState === 'hidden' && this.props.opened) {
      setTimeout(() => {
        this.props.updatePose('visible');
      }, this.props.delay);
    }

    if (this.props.poseState === 'visible' && !this.props.opened) {
      this.props.updatePose('hidden');
      setTimeout(() => {
        this.props.updatePose('hidden');
      }, this.props.delay);
    }
  },
});

// Declare PropTypes
const withPropTypes = setPropTypes({
  delay: PropTypes.number,
});

const withDefaultProps = defaultProps({
  delay: 300,
});

export default compose(
  withState,
  withLifecycle,
  withPropTypes,
  withDefaultProps,
  withEnterTransition,
);
