import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import StepCompletedSVG from '../../../../assets/step-completed.svg';
import './style.css';

const Round = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.completed ? '#5FB89C' : 'rgba(46, 48, 58, 0.67)')};
`;

const StepCompleted = styled.div`
  width: 40px;
  height: 40px;
  background: url(${StepCompletedSVG}) no-repeat center;
`;

const Number = styled.div`
  z-index: 1;
`;

const Root = styled.div`
  position: relative;
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const Text = styled.div`
  color: ${(props) => {
    if (props.dark) {
      return '#232323';
    }

    return (props.completed ? '#FFFFFF' : '#FFFFFF80');
  }};
  text-align: center;
  font-size: 14px;
`;

const Stage = ({
  text, number, completed, dark, isNextStep,
}) => (
  <Root
    className={classNames([
      'stage-root',
      completed && 'stage-root-completed',
      dark && 'stage-root-dark',
    ])}
  >
    <Round
      className={classNames([
        isNextStep && 'stage-round-next-step-modifer',
      ])}
      completed={completed}
    >
      {!completed && <Number>{number}</Number>}
      {completed && <StepCompleted />}
      {isNextStep && <div className="stage-round-next-step" />}
    </Round>
    <Text
      dark={dark}
      completed={completed}
    >
      {text}
    </Text>
  </Root>
);

Stage.defaultProps = {
  fullWidth: false,
};

Stage.propTypes = {
  completed: PropTypes.bool.isRequired,
  isNextStep: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  dark: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.node.isRequired,
  ]).isRequired,
};

export default Stage;
