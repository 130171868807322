import styled from 'styled-components';

const Note = styled.p`
  color: #808085;
  font-size: 13px;
  font-weight: normal;
  line-height: 15px;

  &:first-child {
    margin-top: 4px;
  }
`;

export default Note;
