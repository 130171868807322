import { fromJS } from 'immutable';
import {
  BUY_REQUEST,
  BUY_REQUEST_SUCCESS,
  BUY_REQUEST_FAIL,
} from './constants';

const initialState = fromJS({
  paymentURL: null,
  serverErrorMessage: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case BUY_REQUEST:
      return state
        .set('paymentURL', null)
        .set('serverErrorMessage', null);
    case BUY_REQUEST_SUCCESS:
      return state
        .set('paymentURL', action.payload.paymentURL)
        .set('serverErrorMessage', null);
    case BUY_REQUEST_FAIL:
      return state
        .set('paymentURL', null)
        .set('serverErrorMessage', action.payload.serverErrorMessage);
    default:
      return state;
  }
}

export default reducer;
