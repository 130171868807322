import {
  BUY_GAME_STAGE_1,
  BUY_GAME_STAGE_2,
  BUY_GAME_STAGE_3_6,
  BUY_GAME_STAGE_SO,
  GET_GAME_STAGE,
  GET_GAME_STAGE_SUCCESS,
  GET_GAME_STAGE_FAIL,
} from './constants';

export function buyStage1() {
  return {
    type: BUY_GAME_STAGE_1,
  };
}

export function buyStage2() {
  return {
    type: BUY_GAME_STAGE_2,
  };
}

export function buyStage36() {
  return {
    type: BUY_GAME_STAGE_3_6,
  };
}

export function buyStageSO() {
  return {
    type: BUY_GAME_STAGE_SO,
  };
}

export function getGameStage() {
  return {
    type: GET_GAME_STAGE,
  };
}

export function getGameStageSuccess(ownedStage) {
  return {
    type: GET_GAME_STAGE_SUCCESS,
    ownedStage,
  };
}

export function getGameStageFail() {
  return {
    type: GET_GAME_STAGE_FAIL,
  };
}
