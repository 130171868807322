import PropTypes from 'prop-types';
import React from 'react';
import { PoseGroup } from 'react-pose';
import { useMedia } from 'the-platform';
import { device } from '../../../utils/mediaQueries';

import {
  Wrapper, Mood, Up, Down, Tooltip,
} from './elements';

class SomeStatus extends React.PureComponent {
  static defaultProps = {
    trendValue: null,
    imageURL: null,
    hint: null,
    arrow: null,
  };

  static propTypes = {
    trendValue: PropTypes.number,
    imageURL: PropTypes.string,
    hint: PropTypes.string,
    arrow: PropTypes.string,
  };

  state = {
    showTooltip: false,
  };

  onMouseOver = () => {
    this.setState({
      showTooltip: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      showTooltip: false,
    });
  };

  onClick = (e) => {
    const { onClick } = this.props;

    this.setState(
      {
        showTooltip: false,
      },
      () => onClick(e),
    );
  };

  render() {
    const {
      trendValue, imageURL, hint, arrow,
    } = this.props;

    const { showTooltip } = this.state;

    return (
      <SomeStatusComponent
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        moodImage={imageURL}
        moodAbsoluteValue={trendValue}
        moodDirection={arrow}
        showTooltip={showTooltip}
        hint={hint}
      />
    );
  }
}

export const SomeStatusComponent = (
  {
    onClick, onMouseOver, onMouseLeave,
    showTooltip, moodImage,
    moodDirection, moodAbsoluteValue,
    hint,
  },
) => {
  const small = useMedia(device.tablet);

  return (
    <Wrapper small={small}>
      <div
        onClick={onClick}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onBlur={onMouseLeave}
      >
        <PoseGroup>
          <Mood backgroundImage={moodImage} key={moodImage} small={small} />
        </PoseGroup>
        <PoseGroup>
          {moodDirection === 'up' && <Up key={`${moodAbsoluteValue}-up`} />}
          {moodDirection === 'down' && <Down key={`${moodAbsoluteValue}-down`} small={small} />}
        </PoseGroup>
      </div>
      <PoseGroup>
        {showTooltip && (
          <Tooltip key="tooltip">{hint}</Tooltip>
        )}
      </PoseGroup>
    </Wrapper>
  );
};

export default SomeStatus;
