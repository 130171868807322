import { createGlobalStyle } from 'styled-components';
import pure from 'recompose/pure';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  
  #app {
      -webkit-overflow-scrolling: touch;
  }

  body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  body.fontLoaded {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: #EBEBED;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    margin: 0;
  }

  .fade {
      transition: opacity 1s ease-out;

      &-exited {
        opacity: 0;
      }

      &-entered {
          opacity: 1;
      }
  }

  .fadeIn {
    &-enter {
      opacity: 0;
  
      &.fadeIn-enter-active {
        opacity: 1;
        transition: opacity 1s ease-in;
      }
    }
  }

  .slideLeft {
    &-exit {
      transform: translate3d(0, 0, 0);
      transition: transform .5s;
    }

    &-exit-active {
      transform: translate3d(-100%, 0, 0);
      transition: transform .5s;
    }

    &-exit-done {
      height: 0 !important;
      transform: translate3d(-100%, 0, 0);
      overflow: hidden;
    }

    &-enter {
      transform: translate3d(-100%, 0, 0);
      transition: transform .5s;
    }

    &-enter-active {
      transform: translate3d(0, 0, 0);
      transition: transform .5s;
    }

    &-enter-done {
      transform: translate3d(0, 0, 0);
      transition: transform .5s;
    }
  }

  .slideRight {
    &-exit {
      transform: translate3d(-100%, 0, 0);
      transition: transform .5s;
    }

    &-exit-active {
      transform: translate3d(0, 0, 0);
      transition: transform .5s;
    }

    &-exit-done {
      transform: translate3d(0, 0, 0);
      transition: transform .5s;
      overflow: hidden;
    }

    &-enter {
      transform: translate3d(0, 0, 0);
      transition: transform .5s;
    }

    &-enter-active {
      transform: translate3d(-100%, 0, 0);
      transition: transform .5s;
    }

    &-enter-done {
      transform: translate3d(-100%, 0, 0);
      transition: transform .5s;
    }
  }
  
  * {
    -webkit-tap-highlight-color: transparent;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default pure(GlobalStyle);
