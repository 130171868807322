import animateScrollTo from 'animated-scroll-to';
import { connect } from 'react-redux';

import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import compose from 'recompose/compose';
import { createStructuredSelector } from 'reselect';
import { checkAnswer } from '../../../../../redux/modules/myGame/actions';
import {
  makeSelectAnswers,
  makeSelectImage,
  makeSelectQuestion,
  makeSelectSendAnswerPending,
} from '../../../../../redux/modules/myGame/selectors';
import scrollLock from '../../../../../utils/scrollLock';

const mapDispatchToProps = dispatch => ({
  sendAnswer: answer => dispatch(checkAnswer(answer)),
});

const mapStateToProps = createStructuredSelector({
  answers: makeSelectAnswers(),
  question: makeSelectQuestion(),
  src: makeSelectImage(),
  sendAnswerPending: makeSelectSendAnswerPending(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withLifecycle = lifecycle({
  componentDidMount() {
    scrollLock.on();
  },
  componentWillUnmount() {
    scrollLock.off();
  },
});

const enhance = compose(
  withLifecycle,
  withConnect,
  withHandlers({
    checkAnswer: props => (id) => {
      props.sendAnswer({
        answers: props.answers.map(answer => ({
          id: answer.id,
          text: answer.text,
          isActive: answer.id === id,
          additional: answer.id === id,
        })),
        sendAnswers: true,
      });

      animateScrollTo(0);
    },
  }),
);

export default enhance;
