import { fromJS } from 'immutable';

import {
  GET_DEPARTMENT_LIST_REQUEST,
  GET_DEPARTMENT_LIST_REQUEST_SUCCESS,
  GET_DEPARTMENT_LIST_REQUEST_FAIL,
  GET_EMPLOYEES_LIST_REQUEST,
  GET_EMPLOYEES_LIST_REQUEST_SUCCESS,
  GET_EMPLOYEES_LIST_REQUEST_FAIL,
  SET_NEW_DEPARTMENT_REQUEST,
  SET_NEW_DEPARTMENT_REQUEST_SUCCESS,
  SET_NEW_DEPARTMENT_REQUEST_FAIL,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_REQUEST_SUCCESS,
  DELETE_DEPARTMENT_REQUEST_FAIL,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_REQUEST_SUCCESS,
  DELETE_EMPLOYEE_REQUEST_FAIL,
  CHANGE_DEPARTMENT_COUNT,
  CHANGE_NEW_DEPARTMENT,
  CHANGE_LOADING,
  CHANGE_ACTIVE_TAB,
  CHANGE_DELETE_DEPARTMENT_POPUP_STATE,
  CHANGE_DEPARTMENT_FOR_EDIT,
  CHANGE_EDIT_DEPARTMENT_POPUP_STATE,
  CHANGE_ADD_EMPLOYEE_POPUP_STATE,
  CHANGE_DELETE_EMPLOYEE_POPUP_STATE,
  CHANGE_EDIT_EMPLOYEE_POPUP_STATE,
  CHANGE_EMPLOYEE_FOR_EDIT,
  SET_EMPLOYEE_LIST_FILTER_DEPARTMENT,
  SET_EMPLOYEE_LIST_FILTER_KEYWORD, SET_EMPLOYEE_LIST_FILTER_STATUS,
} from './constants';

const initialState = fromJS({
  activeTab: 'tabItem_1',
  departments: null,
  employees: null,
  newDepartmentName: null,
  isLoading: null,
  newDepartmentLoading: false,
  departmentForDelete: null,
  deleteDepartmentPopupIsOpened: false,
  departmentForEdit: {
    id: null,
    name: null,
  },
  editDepartmentPopupIsOpened: false,
  addEmployeePopupIsOpened: false,
  deleteEmployeePopupIsOpened: false,
  editEmployeePopupIsOpened: false,

  newEmployee: {
    email: null,
    name: null,
    surname: null,
    patronymic: null,
    department: null,
  },
  employeeForDelete: null,
  employeeForEdit: {
    id: null,
    email: null,
    name: null,
    surname: null,
    patronymic: null,
  },
  employeeFilter: {
    departmentId: null,
    keyword: '',
    status: null,
  },
});

function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENT_LIST_REQUEST:
      return state
        .set('departments', null)
        .set('isLoading', true);
    case SET_EMPLOYEE_LIST_FILTER_DEPARTMENT:
      return state
        .setIn(['employeeFilter', 'departmentId'], action.value);
    case SET_EMPLOYEE_LIST_FILTER_KEYWORD:
      return state
        .setIn(['employeeFilter', 'keyword'], action.value);
    case SET_EMPLOYEE_LIST_FILTER_STATUS:
      return state
        .setIn(['employeeFilter', 'status'], action.value);
    case GET_DEPARTMENT_LIST_REQUEST_SUCCESS:
      return state
        .set('departments', action.departments);
    case GET_DEPARTMENT_LIST_REQUEST_FAIL:
      return state
        .set('departments', null);
    case GET_EMPLOYEES_LIST_REQUEST:
      return state
        .set('employees', null)
        .set('isLoading', true);
    case GET_EMPLOYEES_LIST_REQUEST_SUCCESS:
      return state
        .set('employees', action.employees);
    case GET_EMPLOYEES_LIST_REQUEST_FAIL:
      return state
        .set('employees', null);
    case SET_NEW_DEPARTMENT_REQUEST:
      return state.set('newDepartmentLoading', true);
    case SET_NEW_DEPARTMENT_REQUEST_SUCCESS:
      return state
        .set('newDepartmentLoading', false)
        .set('newDepartmentName', null);
    case SET_NEW_DEPARTMENT_REQUEST_FAIL:
      return state.set('newDepartmentLoading', false);
    case DELETE_DEPARTMENT_REQUEST:
      return state.set('departmentForDelete', action.departmentId);
    case DELETE_DEPARTMENT_REQUEST_SUCCESS:
      return state.set('departmentForDelete', null);
    case DELETE_DEPARTMENT_REQUEST_FAIL:
      return state.set('departmentForDelete', null);
    case DELETE_EMPLOYEE_REQUEST:
      return state.set('employeeForDelete', action.employeeId);
    case DELETE_EMPLOYEE_REQUEST_SUCCESS:
      return state.set('employeeForDelete', null);
    case DELETE_EMPLOYEE_REQUEST_FAIL:
      return state.set('employeeForDelete', null);
    case CHANGE_DEPARTMENT_COUNT:
      return state.set('departments', action.departments);
    case CHANGE_LOADING:
      return state.set('isLoading', action.loading);
    case CHANGE_NEW_DEPARTMENT:
      return state.set('newDepartmentName', action.newDepartmentName);
    case CHANGE_ACTIVE_TAB:
      return state.set('activeTab', action.activeTab);
    case CHANGE_DELETE_DEPARTMENT_POPUP_STATE:
      return state.set('deleteDepartmentPopupIsOpened', action.payload);
    case CHANGE_DEPARTMENT_FOR_EDIT:
      return state
        .setIn(['departmentForEdit', 'id'], action.id)
        .setIn(['departmentForEdit', 'name'], action.name);
    case CHANGE_EDIT_DEPARTMENT_POPUP_STATE:
      return state.set('editDepartmentPopupIsOpened', action.payload);
    case CHANGE_ADD_EMPLOYEE_POPUP_STATE:
      return state.set('addEmployeePopupIsOpened', action.payload);
    case CHANGE_DELETE_EMPLOYEE_POPUP_STATE:
      return state.set('deleteEmployeePopupIsOpened', action.payload);
    case CHANGE_EMPLOYEE_FOR_EDIT:
      return state
        .setIn(['employeeForEdit', 'id'], action.id)
        .setIn(['employeeForEdit', 'email'], action.email)
        .setIn(['employeeForEdit', 'name'], action.name)
        .setIn(['employeeForEdit', 'surname'], action.surname)
        .setIn(['employeeForEdit', 'patronymic'], action.patronymic);
    case CHANGE_EDIT_EMPLOYEE_POPUP_STATE:
      return state.set('editEmployeePopupIsOpened', action.payload);
    default:
      return state;
  }
}

export default employeesReducer;
