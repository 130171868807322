import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import A from '../../../components/A';
import Button from '../../../components/Button';
import H1 from '../../../components/H1';
import Fail from '../../../components/RoundMark/Fail';
import URL_CONSTANTS from '../../../urlConstants';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import PublicPageBase from '../../PageBase/PublicPageBase';
import SimplePageBase from '../../PageBase/SimplePageBase';
import Content from '../../../components/Content';

const StyledH1 = styled(H1)`
  width: 100%;
  min-width: 100%;
  text-align: center;
`;

const StyledContent = styled(Content)`
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const CenterP = styled.p`
  text-align: center;
`;

const FailPaymentPage = ({ cookies }) => {
  const isAuthenticated = isUserAuthenticated(cookies);
  const Wrapper = isAuthenticated ? SimplePageBase : PublicPageBase;

  return (
    <Wrapper title="Ошибка оплаты">
      <StyledContent>
        <StyledH1>Ошибка оплаты</StyledH1>
        <Fail />
        <div>
          <p>
            Платежный шлюз сообщил о неудачной оплате.
          </p>
          <p>
            Возможные причины:
            <ul>
              <li>На карте недостаточно средств.</li>
              <li>Введены не правильные данные карты (номер карты, срок действия, CVC код).</li>
              <li>
                Если вы используется SMS подтверждение при оплате, то проверьте првильность введенного кода из СМС.
              </li>
              <li>Карта заблокирована.</li>
            </ul>
          </p>
          <p>
            Если ни одна из причин не подходит, то попробуйте произвести заказ повторно.
          </p>
          <br />
        </div>

        <ButtonWrapper>
          {isAuthenticated && (
            <Button isButton to={URL_CONSTANTS.URLS.GAMES_STORE}>
              Перейти в магазин
            </Button>
          )}
          {!isAuthenticated && (
            <Button isButton to={URL_CONSTANTS.URLS.LOGIN}>
              Войти в профиль
            </Button>
          )}
        </ButtonWrapper>

        <CenterP>
          Техническая поддержка
          <br />
          <A href="mailto:captain@readom.pro">captain@readom.pro</A>
          <br />
          <A href="tel:+79232472288">+7 (923) 247 22 88</A>
        </CenterP>

      </StyledContent>
    </Wrapper>
  );
};

FailPaymentPage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default FailPaymentPage;
