import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { createStructuredSelector } from 'reselect';
import withHandlers from 'recompose/withHandlers';
import { withCookies } from 'react-cookie';

import withEnterTransition from '../../enhancers/withEnterTransition';
import { setBeforeDemoLeavePopupOpened } from '../../redux/modules/popups/actions';
import {
  makeSelectBeforeDemoLeavePopupOpened,
} from '../../redux/modules/popups/selectors';
import scrollLock from '../../utils/scrollLock';

const mapStateToProps = createStructuredSelector({
  opened: makeSelectBeforeDemoLeavePopupOpened(),
});

const mapDispatchToProps = dispatch => ({
  setBeforeDemoLeavePopupOpened: opened => dispatch(setBeforeDemoLeavePopupOpened(opened)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentWillReceiveProps(nextProps) {
    if (!this.props.opened && nextProps.opened) {
      scrollLock.on();
    }

    if (this.props.opened && !nextProps.opened) {
      scrollLock.off();
    }
  },
});

const withOnClose = withHandlers({
  onClose: props => () => {
    props.setBeforeDemoLeavePopupOpened(false);
  },
});

const enhance = compose(
  withCookies,
  withConnect,
  withLifecycle,
  withOnClose,
  withEnterTransition,
);

export default enhance;
