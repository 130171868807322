import {
  ADD_EMPLOYEE_REQUEST,
} from './constants';

export function addEmployee({ values, formik }) {
  return {
    type: ADD_EMPLOYEE_REQUEST,
    payload: {
      values,
      formik,
    },
  };
}
