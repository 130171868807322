import * as Yup from 'yup';

import { isPossiblePhoneNumber } from 'react-phone-number-input';

const StartTrialGameWithPhoneValidateSchema = Yup.object().shape({
  phone: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .test('phone', 'Убедитесь, что поле заполнено верно', val => isPossiblePhoneNumber(val))
    .required('Поле обязательно для заполнения'),
  name: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .required('Поле обязательно для заполнения'),
});

export default StartTrialGameWithPhoneValidateSchema;
