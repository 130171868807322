import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import pure from 'recompose/pure';
import styled from 'styled-components';
import LoadingIndicator from '../LoadingIndicator';
import AdditionalHeader from './includes/AdditionalHeader';
import AdditionalRow from './includes/AdditionalRow';
import AdditionalWrapper from './includes/AdditionalWrapper';

import ExtendedRatingRowData from './includes/ExtendedRatingRowData';

const LoadingWrapper = pure(styled.div`
    height: 100px;
`);

const AdditionalScrollWrapper = styled.div`
  width: 100%;
  overflow: auto;
  background: #3D3F4A;
`;

const ExtendedRatingRow = ({ ratingForOneGameEmployee, isLoadingEmployeeRating, fields }) => (
  <AdditionalScrollWrapper>
    <AdditionalWrapper>
      {isLoadingEmployeeRating && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}

      {!isLoadingEmployeeRating && (
        <>
          <AdditionalHeader>
            {map(fields, (field) => {
              if (!field.IsSystem) {
                return (
                  <AdditionalRow>
                    {field.Name}
                  </AdditionalRow>
                );
              }

              return null;
            })}
          </AdditionalHeader>
          <ExtendedRatingRowData
            fields={fields}
            rating={ratingForOneGameEmployee}
          />
        </>
      )}
    </AdditionalWrapper>
  </AdditionalScrollWrapper>
);

ExtendedRatingRow.propTypes = {
  isLoadingEmployeeRating: PropTypes.bool.isRequired,
  ratingForOneGameEmployee: PropTypes.array,
};

ExtendedRatingRow.defaultProps = {
  ratingForOneGameEmployee: null,
};

export default ExtendedRatingRow;
