import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Text from './Text';

const Number = styled.span`
  width: 30px;
  margin-right: 20px;
`;

const Root = styled.span`
  display: flex;
`;

const ChildrenText = styled.span`
  width: 100%;
  ${props => props.center && 'text-align: center;'}
`;

class TextWithNumber extends React.PureComponent {
  static defaultProps = {
    center: false,
  };

  static propTypes = {
    center: PropTypes.bool,
    children: PropTypes.any,
    number: PropTypes.string.isRequired,
  };

  render() {
    const { number, children, center } = this.props;

    return (
      <Text>
        <Root>
          <Number>
            {number}
          </Number>
          <ChildrenText center={center}>
            {children}
          </ChildrenText>
        </Root>
      </Text>
    );
  }
}

export default TextWithNumber;
