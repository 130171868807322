import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GetBookPartToEmailForm from '../GetBookPartToEmailForm';

import CrossSVG from './images/cross.svg';
import CoverImage from '../../assets/gameCover/million/normal.jpg';

const PopupWrapper = styled.div`
  display: flex;
  left: 0;
  height: 100vh;
  overflow: auto;
  padding: 0;
  position: fixed;
  top: 0;
  transition: opacity .5s ease-out;
  width: 100vw;
  z-index: 21;
`;

const PopupInner = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin: 0;
  margin: auto;
  position: relative;
  max-width: 600px;
  width: 100%;
  z-index: 10;
`;

const PopupBody = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: -200px;
  z-index: 21;
`;

const CloseIcon = styled.div`
  background: url(${CrossSVG});
  background-size: 17px 17px;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.15s;
  background-color: #232323;
  border-radius: 30px;
  &:hover {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    background-color: #232323;
    border-radius: 30px;
    opacity: 1;
  }
`;

const Root = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 300px;
  min-width: 300px;
  background: url(${CoverImage}) no-repeat;
  border-radius: 5px 0 0 5px;
  background-size: cover;
  background-position: -400px;
  
  @media (max-width: 768px) {
    min-height: 200px;
    width: 100%;
    background-position: center;
  }
`;

const Right = styled.div`
  width: 300px;
  min-width: 300px;
  min-height: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 40px;
  
  > p {
    font-family: 'Merriweather', serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    min-height: initial;
  }
`;

class BeforeDemoLeavePopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  handleClickOutsidePopup = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { onClose } = this.props;

    // TODO: HARDCODE_TYPE_2 зашит текст попапа
    return (
      <>
        <Overlay onClick={this.handleClickOutsidePopup} />
        <PopupWrapper>
          <PopupInner>
            <PopupBody>
              <CloseIcon onClick={onClose} />
              <Root>
                <Left />
                <Right>
                  <p>
                    Получите в подарок бесплатный фрагмент книги из библиотеки крупных продаж
                  </p>
                  <GetBookPartToEmailForm />
                </Right>
              </Root>
            </PopupBody>
          </PopupInner>
        </PopupWrapper>
      </>
    );
  }
}

export default BeforeDemoLeavePopup;
