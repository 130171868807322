import styled from 'styled-components';

const Index = styled.section`
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: ${props => (props.noFlex ? 'column' : 'row')};
  flex-wrap: initial;
  justify-content: normal;
  margin: 0;
  width: 100%;
  @media (max-width: 1080px) {
    overflow: auto;
  }
`;

export default Index;
