import { fromJS } from 'immutable';

import {
  NEW_GAME_REQUEST,
  NEW_GAME_REQUEST_SUCCESS,
  NEW_GAME_REQUEST_FAIL,
  GAME_INFO_REQUEST,
  GAME_INFO_REQUEST_SUCCESS,
  GAME_INFO_REQUEST_FAIL,
  CONTINUE_GAME_REQUEST_SUCCESS,
  CONTINUE_GAME_REQUEST_FAIL,
  CONTINUE_GAME_FROM_SAVE_REQUEST_SUCCESS,
  CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL,
  UNCHECK_CONTINUE_GAME,
  NEXT_STEP_REQUEST_SUCCESS,
  NEXT_STEP_REQUEST_FAIL,
  CHECK_ANSWER,
  GET_VARS_REQUEST_SUCCESS,
  GET_VARS_REQUEST_FAIL,
  GET_KEYS_REQUEST_SUCCESS,
  GET_KEYS_REQUEST_FAIL,
  GET_TIPS_REQUEST_SUCCESS,
  GET_TIPS_REQUEST_FAIL,
  GET_HIGHLIGHTTIP_REQUEST_SUCCESS,
  GET_FIFTY_FIFTY_REQUEST_SUCCESS,
  GET_TIPS_PRICE_REQUEST_SUCCESS,
  GET_TIPS_PRICE_REQUEST_FAIL,
  BUY_FIFTY_FIFTY_TIP_REQUEST,
  BUY_FIFTY_FIFTY_TIP_REQUEST_SUCCESS,
  BUY_FIFTY_FIFTY_TIP_REQUEST_FAIL,
  BUY_HIGHLIGHTTIP_TIP_REQUEST,
  BUY_HIGHLIGHTTIP_TIP_REQUEST_SUCCESS,
  BUY_HIGHLIGHTTIP_TIP_REQUEST_FAIL,
  BUY_SAVE_GAME_REQUEST,
  BUY_CARD_KEY_REQUEST,
  GET_CARDS_ALL_REQUEST_SUCCESS,
  GET_CARDS_ALL_REQUEST_FAIL,
  GET_FINISH_VARS_REQUEST,
  GET_FINISH_VARS_REQUEST_SUCCESS,
  GET_FINISH_VARS_REQUEST_FAIL,
  CHANGE_STAVKA_STATE,
  CHANGE_SUPERGAME_STATE,
  CHANGE_IS_LOADING,
  CHANGE_IS_LOADING_CARDS,
  CHANGE_PRODUCT_PRICE,
  GET_RECEIVED_KEYS_REQUEST,
  GET_RECEIVED_KEYS_REQUEST_SUCCESS,
  GET_SAVE_COUNT_REQUEST_SUCCESS,
  GET_SAVE_COUNT_REQUEST_FAIL,
  SET_IS_FIRST_GAME,
  BUY_CARD_KEY_REQUEST_SUCCESS,
  BUY_CARD_KEY_REQUEST_FAIL, NEXT_STEP_REQUEST,
  SET_IS_FIRST_GAME_TOUR_WAS_SHOWN,
  SET_USER_EMAIL_REQUEST,
  SET_USER_EMAIL_REQUEST_SUCCESS,
  SET_USER_EMAIL_REQUEST_FAIL,
  SET_USER_EMAIL_RESET,
  RESET_MY_GAME_STATE,
  SET_SHOW_ACTION_BUTTON_ON_MOBILE,
  SET_ACTIVE_DEAL_STAGE,
  SET_DEAL_STAGE_INFO, SET_WIDGET_LIST,
} from './constants';

// TODO: HARDCODE
const initialState = fromJS({
  // id: null,
  id: '{FD821E62-DFFB-E545-8A40-FC75527465C4}', // TODO HARDCODE: Избавиться от этого
  showActionButtonsOnMobile: false, // высплавающие кнопки со стадией и подсказками на мобиле
  isFirstGame: null, // Является ли это первой игровой сессией.
  isFirstGameTourWasShown: false, // Показывали ли тур первой игры
  canContinue: false,
  sessionId: null, // Идентификатор игровой сессии
  image: null,
  question: null,
  answers: null,
  sendAnswers: false,
  blockInstanceId: null,
  methodType: null,
  questionName: null,
  variables: null,
  isContinueGame: false,
  keysCount: null,
  summa: 0,
  highlightTip: {
    id: null,
    count: null,
    showTip: false,
    price: undefined,
    countForBuy: 0,
  },
  fiftyFiftyTip: {
    id: null,
    count: null,
    showTip: false,
    price: undefined,
    countForBuy: 0,
    used: false,
  },
  saveTip: {
    id: null,
    count: null,
    showTip: false,
    price: undefined,
    lastSaveId: null,
    lastSaveName: null,
    countForBuy: 0,
  },
  stats: {
    opened: 0,
    maxMoney: 0,
    money: 0,
    percent: 0,
    percentBoosterFromCompanyEnabled: false,
    percentBoosterFromCompanyValue: 0,
    time: 0,
  },
  clientMoodDirection: null,
  myMoodDirection: null,
  clientMoodValue: null,
  myMoodValue: null,
  myMoodAbsoluteValue: null,
  clientMoodAbsoluteValue: null,
  cards: [],
  allCardsCount: 0,
  openCardsCount: 0,
  finishLoading: null,
  finishSum: 0,
  finishPaid: 0,
  finishSuccess: false,
  stavkaState: 0,
  superGame: {
    isAvailable: false,
    isEnabled: false,
  },
  stage: 0,
  stagesInfo: null,
  isLoading: false,
  isLoadingCards: false,
  keysCountForBuy: 0,
  cardIdForBuy: null,
  productPrice: {
    id: null,
    price: 0,
  },
  lastBuyCard: null, // Последняя купленная карточка
  cardBuyPending: false,
  initialized: false, // Получены ли данные необходимые для игры
  sendAnswerPending: false,
  receivedKeys: {
    userKeySession: null,
    cardList: null,
  },
  percentOfBestSeller: null,
  finishRecommendation: null,
  finishTitle: null,
  finishStatus: null,
  setUserEmail: null,
  setUserEmailPending: false,
  setUserEmailSuccess: false,
  setUserEmailFail: false,
  finalCupImage: null,
  finalCupImageTexts: [],
  currentGameProgress: null,
  gameProgressList: null,
  widgetList: null,
  widgetDescriptions: null,
});

function myGameReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_MY_GAME_STATE:
      return initialState;
    case SET_SHOW_ACTION_BUTTON_ON_MOBILE:
      return state
        .set('showActionButtonsOnMobile', action.payload.showActionButtonsOnMobile);
    case SET_USER_EMAIL_REQUEST:
      return state
        .set('setUserEmailPending', true)
        .set('setUserEmailSuccess', false)
        .set('setUserEmailFail', false)
        .set('setUserEmail', action.email);
    case SET_USER_EMAIL_REQUEST_FAIL:
      return state
        .set('setUserEmailPending', false)
        .set('setUserEmailSuccess', false)
        .set('setUserEmailFail', true)
        .set('setUserEmail', null);
    case SET_USER_EMAIL_REQUEST_SUCCESS:
      return state
        .set('setUserEmailPending', false)
        .set('setUserEmailSuccess', true)
        .set('setUserEmailFail', false);
    case SET_USER_EMAIL_RESET:
      return state
        .set('setUserEmailPending', false)
        .set('setUserEmailSuccess', false)
        .set('setUserEmail', null)
        .set('setUserEmailFail', false);
    case GET_RECEIVED_KEYS_REQUEST:
      return state
        .setIn(['receivedKeys', 'userKeySession'], null)
        .setIn(['receivedKeys', 'cardList'], null);
    case GET_RECEIVED_KEYS_REQUEST_SUCCESS:
      return state
        .setIn(['receivedKeys', 'userKeySession'], action.payload.userKeySession)
        .setIn(['receivedKeys', 'cardList'], action.payload.cardList);
    case NEW_GAME_REQUEST:
      return state
        .set('sessionId', null)
        .set('myMoodValue', null)
        .set('clientMoodValue', null)
        .set('myMoodAbsoluteValue', null)
        .set('clientMoodAbsoluteValue', null)
        .set('variables', null)
        .set('answers', null);
    case NEW_GAME_REQUEST_SUCCESS:
      return state
        .set('initialized', true)
        .set('sessionId', action.sessionId);
    case SET_WIDGET_LIST:
      return state
        .set('widgetList', action.widgetList)
        .set('widgetDescriptions', action.widgetDescriptions);
    case NEW_GAME_REQUEST_FAIL:
      return state.set('sessionId', null);
    case CONTINUE_GAME_REQUEST_SUCCESS:
      return state
        .set('sessionId', action.sessionId)
        .set('initialized', true)
        .set('isContinueGame', true);
    case CONTINUE_GAME_REQUEST_FAIL:
      return state
        .set('sessionId', null)
        .set('isContinueGame', false);
    case CONTINUE_GAME_FROM_SAVE_REQUEST_SUCCESS:
      return state.set('sessionId', action.sessionId);
    case CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL:
      return state.set('sessionId', null);
    case UNCHECK_CONTINUE_GAME:
      return state.set('isContinueGame', false);
    case NEXT_STEP_REQUEST:
      return state.set('sendAnswerPending', true);
    case NEXT_STEP_REQUEST_SUCCESS:
      return state
        .setIn(['fiftyFiftyTip', 'used'], false)
        .set('sendAnswerPending', false)
        .set('question', action.question)
        .set('image', action.image)
        .set('finalCupImage', action.finalCupImage)
        .set('finalCupImageTexts', action.finalCupImageTexts)
        .set('answers', action.answers)
        .set('blockInstanceId', action.blockInstanceId)
        .set('methodType', action.methodType)
        .set('questionName', action.questionName)
        .set('sendAnswers', false)
        .setIn(['highlightTip', 'showTip'], action.showHighlightTip)
        .setIn(['fiftyFiftyTip', 'showTip'], action.showFiftyFiftyTip);
    case NEXT_STEP_REQUEST_FAIL:
      return state
        .set('sendAnswerPending', false)
        .set('question', null)
        .set('answers', null)
        .set('finalCupMessages', [])
        .set('blockInstanceId', null)
        .set('methodType', null)
        .set('questionName', null)
        .set('sendAnswers', false)
        .setIn(['highlightTip', 'showTip'], false)
        .setIn(['fiftyFiftyTip', 'showTip'], false);
    case CHECK_ANSWER:
      return state
        .set('answers', action.answers)
        .set('sendAnswers', action.sendAnswers);
    case SET_ACTIVE_DEAL_STAGE:
      return state
        .set('stage', action.stage);
    case SET_DEAL_STAGE_INFO:
      return state
        .set('stagesInfo', action.stagesInfo);
    case GET_VARS_REQUEST_SUCCESS:
      return state
        .set('variables', action.variables)
        .set('myMoodAbsoluteValue', action.myMoodAbsoluteValue)
        .set('clientMoodAbsoluteValue', action.clientMoodAbsoluteValue)
        .set('clientMoodDirection', action.clientMoodDirection)
        .set('myMoodDirection', action.myMoodDirection)
        .set('clientMoodValue', action.clientMoodValue)
        .set('myMoodValue', action.myMoodValue)
        .set('summa', action.summa)
    case GET_VARS_REQUEST_FAIL:
      return state.set('variables', null);
    case GET_KEYS_REQUEST_SUCCESS:
      return state.set('keysCount', action.keys);
    case SET_IS_FIRST_GAME:
      return state.set('isFirstGame', action.isFirstGame);
    case SET_IS_FIRST_GAME_TOUR_WAS_SHOWN:
      return state.set('isFirstGameTourWasShown', action.isFirstGameTourWasShown);
    case GET_KEYS_REQUEST_FAIL:
      return state.set('keysCount', 0);
    case GET_TIPS_REQUEST_SUCCESS:
      return state
        .setIn(['fiftyFiftyTip', 'count'], action.fiftyFiftyTip)
        .setIn(['highlightTip', 'count'], action.highlightTrueTip);
    case GET_TIPS_REQUEST_FAIL:
      return state
        .setIn(['fiftyFiftyTip', 'count'], undefined)
        .setIn(['highlightTip', 'count'], undefined);
    case GET_HIGHLIGHTTIP_REQUEST_SUCCESS:
      return state.set('answers', action.answers);
    case GET_FIFTY_FIFTY_REQUEST_SUCCESS:
      return state
        .set('answers', action.answers)
        .setIn(['fiftyFiftyTip', 'used'], true);
    case GET_TIPS_PRICE_REQUEST_SUCCESS:
      return state
        .setIn(['highlightTip', 'id'], action.highlightTip.id)
        .setIn(['highlightTip', 'price'], action.highlightTip.price)
        .setIn(['fiftyFiftyTip', 'id'], action.fiftyFiftyTip.id)
        .setIn(['fiftyFiftyTip', 'price'], action.fiftyFiftyTip.price)
        .setIn(['saveTip', 'id'], action.saveTip.id)
        .setIn(['saveTip', 'price'], action.saveTip.price);
    case GET_TIPS_PRICE_REQUEST_FAIL:
      return state
        .setIn(['highlightTip', 'id'], null)
        .setIn(['highlightTip', 'price'], undefined)
        .setIn(['fiftyFiftyTip', 'id'], null)
        .setIn(['fiftyFiftyTip', 'price'], undefined)
        .setIn(['saveTip', 'id'], null)
        .setIn(['saveTip', 'price'], undefined);
    case GET_SAVE_COUNT_REQUEST_SUCCESS:
      return state
        .setIn(['saveTip', 'count'], action.saveCount);
    case GET_SAVE_COUNT_REQUEST_FAIL:
      return state
        .setIn(['saveTip', 'count'], 0);
    case BUY_FIFTY_FIFTY_TIP_REQUEST:
      return state.setIn(['fiftyFiftyTip', 'countForBuy'], action.payload);
    case BUY_FIFTY_FIFTY_TIP_REQUEST_SUCCESS:
      return state;
    case BUY_FIFTY_FIFTY_TIP_REQUEST_FAIL:
      return state;
    case BUY_HIGHLIGHTTIP_TIP_REQUEST:
      return state.setIn(['highlightTip', 'countForBuy'], action.payload);
    case BUY_HIGHLIGHTTIP_TIP_REQUEST_SUCCESS:
      return state;
    case BUY_HIGHLIGHTTIP_TIP_REQUEST_FAIL:
      return state;
    case BUY_SAVE_GAME_REQUEST:
      return state.setIn(['saveTip', 'countForBuy'], action.payload);
    case BUY_CARD_KEY_REQUEST:
      return state
        .set('keysCountForBuy', action.count)
        .set('cardIdForBuy', action.cardIdForBuy)
        .set('cardBuyPending', true)
        .set('lastBuyCard', null)
        .set('isLoadingCards', true);
    case BUY_CARD_KEY_REQUEST_FAIL:
      return state
        .set('cardBuyPending', false)
        .set('isLoadingCards', false);
    case BUY_CARD_KEY_REQUEST_SUCCESS:
      return state
        .set('keysCountForBuy', null)
        .set('cardIdForBuy', null)
        .set('lastBuyCard', state.get('cardIdForBuy'))
        .set('cardBuyPending', false);
    case CHANGE_IS_LOADING_CARDS:
      return state.set('isLoadingCards', action.payload);
    case GAME_INFO_REQUEST:
      return state
        .set('currentGameProgress', null)
        .set('gameProgressList', null)
        .set('isLoading', true);
    case GAME_INFO_REQUEST_SUCCESS:
      return state
        .set('currentGameProgress', action.currentGameProgress)
        .set('gameProgressList', action.gameProgressList)
        .set('canContinue', action.canContinue)
        .setIn(['saveTip', 'lastSaveId'], action.lastSave.id)
        .setIn(['superGame', 'isAvailable'], action.isAvailableSuperGame);
    case GAME_INFO_REQUEST_FAIL:
      return state
        .set('canContinue', false)
        .set('cards', [])
        .set('currentGameProgress', null)
        .set('gameProgressList', null)
        .setIn(['saveTip', 'lastSaveId'], null)
        .setIn(['saveTip', 'lastSaveName'], null)
        .setIn(['stats', 'money'], 0)
        .setIn(['stats', 'percent'], 0)
        .setIn(['stats', 'maxMoney'], 0)
        .setIn(['stats', 'percentBoosterFromCompanyEnabled'], false)
        .setIn(['stats', 'percentBoosterFromCompanyValue'], 0)
        .setIn(['stats', 'time'], 0)
        .setIn(['superGame', 'isAvailable'], false);
    case GET_CARDS_ALL_REQUEST_SUCCESS:
      return state
        .set('cards', action.cards)
        .set('allCardsCount', action.allCardsCount)
        .set('openCardsCount', action.openCardsCount)
        .setIn(['stats', 'opened'], action.opened);
    case GET_CARDS_ALL_REQUEST_FAIL:
      return state
        .set('cards', [])
        .set('allCardsCount', 0)
        .set('openCardsCount', 0)
        .setIn(['stats', 'opened'], 0);
    case GET_FINISH_VARS_REQUEST:
      return state
        .set('finishLoading', true)
        .set('finishSum', 0)
        .set('finishPaid', 0)
        .set('finishSuccess', false)
        .set('finishTitle', null)
        .set('finishRecommendation', null)
        .set('finishStatus', null);
    case GET_FINISH_VARS_REQUEST_SUCCESS:
      return state
        .set('finishLoading', false)
        .set('finishSum', action.finishSum)
        .set('percentOfBestSeller', action.percentOfBestSeller)
        .set('finishPaid', action.finishPaid)
        .set('finishSuccess', action.finishSuccess)
        .set('finishTitle', action.finishTitle)
        .set('finishRecommendation', action.finishRecommendation)
        .set('finishStatus', action.finishStatus)
        .setIn(['stats', 'percent'], action.stats.percent)
        .setIn(['stats', 'percentBoosterFromCompanyEnabled'], action.stats.percentBoosterFromCompanyEnabled)
        .setIn(['stats', 'percentBoosterFromCompanyValue'], action.stats.percentBoosterFromCompanyValue);
    case GET_FINISH_VARS_REQUEST_FAIL:
      return state
        .set('finishLoading', false)
        .set('finishSum', 0)
        .set('finishPaid', 0)
        .set('percentOfBestSeller', 0)
        .set('finishSuccess', false)
        .set('finishTitle', null)
        .set('finishRecommendation', null)
        .set('finishStatus', null);
    case CHANGE_STAVKA_STATE:
      return state
        .set('stavkaState', action.stavka);
    case CHANGE_SUPERGAME_STATE:
      return state
        .setIn(['superGame', 'isEnabled'], action.superGame);
    case CHANGE_IS_LOADING:
      return state.set('isLoading', action.payload);
    case CHANGE_PRODUCT_PRICE:
      return state
        .setIn(['productPrice', 'id'], action.id)
        .setIn(['productPrice', 'price'], action.price);
    default:
      return state;
  }
}

export default myGameReducer;
