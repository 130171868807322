import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SwitcherIcon from './roulette.svg';

const SwitcherWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  padding-top: 15px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const SwitcherLine = styled.div`
  background: #EBEBED;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .4);
  border: none;
  border-radius: 45px;
  display: flex;
  height: 10px;
  width: ${props => props.width}px;
`;

const SwitcherToggle = styled.div`
  background-color: ${props => props.background};
  background-image: url(${SwitcherIcon});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .3);
  margin-top: -15px;
  width: 40px;
  height: 40px;
  left: ${props => props.left}px;
  position: relative;
  transition: all .2s ease-in-out;
`;

const Label = styled.div`
  color: #35BB9A;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  width: 26px;
  user-select: none;
`;

class Switcher extends React.PureComponent {
  render() {
    let lineWidth = 58;
    if (this.props.states.length > 2) {
      lineWidth = 58 + ((this.props.states.length - 2) * 18);
    }

    let toggleLeft = 0;
    let backgroundColor = '#EBEBED';
    if (this.props.activeState > 0) {
      toggleLeft = this.props.activeState * 18;
      backgroundColor = '#35BB9A';
    }

    return (
      <SwitcherWrapper onClick={this.props.onClick} disabled={this.props.disabled}>
        <SwitcherLine
          width={lineWidth}
        >
          <SwitcherToggle
            background={backgroundColor}
            left={toggleLeft}
          />
        </SwitcherLine>
        <Label>
          {this.props.labels[this.props.activeState]}
        </Label>
      </SwitcherWrapper>
    );
  }
}

Switcher.propTypes = {
  activeState: PropTypes.number,
  states: PropTypes.array,
  labels: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Switcher;
