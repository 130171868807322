const onOnlineHelpButtonClick = () => {
  const chatButton = document.getElementsByClassName('b24-widget-button-openline_livechat')[0];
  if (chatButton) {
    chatButton.click();
  }
};

const onFeedBackButtonClick = ({ name, email, surname }) => () => {
  const feedBackButton = document.getElementsByClassName('b24-widget-button-crmform')[0];
  let intervalFill;
  if (feedBackButton) {
    window.addEventListener('message', (event) => {
      if (event.data === 'POPUP_LOADED') {
        // console.log('BITRIX POPUP_LOADED');
        // Значить popup загрузился, но в нем еще может быть не подгружена форма, поэтому пингуем пока битрикс не ответит нам своим postMessage FORM_PRE_FILLED
        const interval = setInterval(() => {
          const iframe = document.getElementById('bx_form_iframe_2');
          if (iframe) {
            if (interval) {
              clearInterval(interval);
            }
            intervalFill = setInterval(() => {
              const data = {
                event: 'PREFILL_FORM',
                name,
                email,
                surname,
              };

              iframe.contentWindow.postMessage(data, process.env.REACT_APP_BITRIX_DOMAIN);
            }, 100);
          }
        }, 100);
      }

      if (event.data === 'FORM_PRE_FILLED') {
        if (intervalFill) {
          clearInterval(intervalFill);
        }
      }
    });

    feedBackButton.click();
  }
};

const onWhatsAppButtonClick = () => {
  const win = window.open(process.env.REACT_APP_CLIENT_CHAT, '_blank');
  win.focus();
};

export {
  onOnlineHelpButtonClick,
  onFeedBackButtonClick,
  onWhatsAppButtonClick,
};
