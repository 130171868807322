import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { withFormik } from 'formik';
import withHandlers from 'recompose/withHandlers';
import { startTrialGame } from '../../redux/modules/global/actions';
import { setStartTrialGameWithEmailPopupOpened, setStartTrialGameWithPhonePopupOpened } from '../../redux/modules/popups/actions';
import StartTrialGameWithPhoneValidateSchema from './StartTrialGameWithPhoneValidateSchema';

const mapDispatchToProps = dispatch => ({
  startTrialGame: ({
    phone, gameSysName, name, formik,
  }) => dispatch(startTrialGame({
    phone, gameSysName, name, formik,
  })),
  setStartTrialGameWithEmailPopupOpened: opened => dispatch(setStartTrialGameWithEmailPopupOpened(opened)),
  setStartTrialGameWithPhonePopupOpened: opened => dispatch(setStartTrialGameWithPhonePopupOpened(opened)),
});

const withConnect = connect(null, mapDispatchToProps);

const withFormFormik = withFormik({
  mapPropsToValues: () => ({
    email: '',
    phone: '',
  }),
  validationSchema: StartTrialGameWithPhoneValidateSchema,
  handleSubmit: (values, { props, ...formik }) => {
    const phone = values.phone.trim().toLowerCase();
    const name = values.name.trim();

    props.onStartTrialGame({
      name,
      phone,
      formik,
    });
  },
  displayName: 'StartTrialGameWithPhoneForm',
});

const withOnStartTrialGame = withHandlers({
  onStartTrialGame: props => (
    {
      name, phone, formik,
    },
  ) => {
    props.startTrialGame({
      name,
      // TODO: HARDCODE_TYPE_2 Зашито системное наименование игры
      gameSysName: 'million',
      phone,
      formik,
    });

    props.setStartTrialGameWithEmailPopupOpened(false);
    props.setStartTrialGameWithPhonePopupOpened(false);
  },
});

const enhance = compose(
  pure,
  withConnect,
  withOnStartTrialGame,
  withFormFormik,
);

export default enhance;
