import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import SetPasswordForm from './SetPasswordForm';
import LogoIcon from './logo.svg';

import URL_CONSTANTS from '../../../urlConstants';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  margin: 0;
  margin: auto;
  min-height: 100vh;
  padding: 20px 0;
  overflow: hidden;
  width: 100%;
`;

const Logo = styled.div`
  background-image: url(${LogoIcon});
  background-position: center;
  background-size: 202px 139px;
  cursor: pointer;
  height: 139px;
  margin: 0 auto;
  width: 202px;
`;

const GlobalStyle = createGlobalStyle`
  #app {
    background-color: #FFFFFF !important;
  }
`;

class RestorePasswordPage extends React.Component {
  handleRedirect = () => {
    this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
  };

  render() {
    return (
      <Wrapper>
        <div>
          <GlobalStyle />
          <Logo onClick={this.handleRedirect} />
          <SetPasswordForm />
        </div>
      </Wrapper>
    );
  }
}

export default RestorePasswordPage;
