import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withCookies, Cookies } from 'react-cookie';
import isUserAuthenticated from '../../../../utils/isUserAuthenticated';
import SimplePageBase from '../../../PageBase/SimplePageBase/index';
import PublicPageBase from '../../../PageBase/PublicPageBase/index';
import Organization from './Organization';

const title = 'Организация';

const OrganizationPage = ({ cookies }) => {
  const isAuthenticated = isUserAuthenticated(cookies);
  const Wrapper = isAuthenticated ? SimplePageBase : PublicPageBase;

  return (
    <Wrapper title={title}>
      <Organization />
    </Wrapper>
  );
};

OrganizationPage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default compose(
  withCookies,
)(OrganizationPage);
