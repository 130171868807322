const getUTMQueryParams = (_qs) => {
  const qs = _qs.split('+').join(' ');
  const params = {};
  let tokens;
  const re = /\butm_([^=]+)=([^&]+)/g;

  while (tokens = re.exec(qs)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export default getUTMQueryParams;
