import PropTypes from 'prop-types';
import React from 'react';

import BgImage from './refresh.svg';
import Tip from './Tip';

function DebugTip({ small, onClick, children }) {
  return (
    <Tip
      small={small}
      backgroundImage={BgImage}
      onClick={onClick}
    >
      {children}
    </Tip>
  );
}

DebugTip.defaultProps = {
  children: null,
};

DebugTip.propTypes = {
  small: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DebugTip;
