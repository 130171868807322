import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectActiveGameId } from '../../redux/modules/activeGame/selectors';
import {
  makeSelectImage,
  makeSelectShowActionButtonsOnMobile,
} from '../../redux/modules/myGame/selectors';
import { makeSelectText } from '../../redux/modules/owl/selectors';

const mapStateToProps = createStructuredSelector({
  showButtonWrapper: makeSelectShowActionButtonsOnMobile(),
  activeGameId: makeSelectActiveGameId(),
  text: makeSelectText(),
  image: makeSelectImage(),
});

const withConnect = connect(mapStateToProps, null);

const enhance = compose(
  withConnect,
);

export default enhance;
