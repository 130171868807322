import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectQuestion,
} from '../../../../../redux/modules/myGame/selectors';

const mapStateToProps = createStructuredSelector({
  question: makeSelectQuestion(),
});

const withConnect = connect(mapStateToProps);

const enhance = compose(
  withConnect,
  withProps(({ question }) => {
    const html = document.createElement('html');
    html.innerHTML = question;
    const textElements = html.getElementsByTagName('TEXT');
    const owlMessage = textElements && textElements[0] && textElements[0].innerHTML;

    return {
      owlMessage,
    };
  }),
);

export default enhance;
