import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CheckedIcon from './checked.svg';

export const Container = styled.label`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #1C1C1C;
  letter-spacing: 0;

  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  
  ${props => props.disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `}

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & .checkmark {
    border: 2px solid rgba(0, 0, 0, .25);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    transition: background-color .3s ease-out;
  }
  
  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: #35BB9A;
    border: 0;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  & .checkmark:after {
    content: "";
    position: absolute;
    opacity: 0;
    left: 1px;
    top: 3px;
  }
  
  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  
  /* Style the checkmark/indicator */
  & .checkmark:after {
    background-image: url(${CheckedIcon});
    background-size: cover;
    height: 10px;
    left: 3px;
    top: 5px;
    width: 14px;
  }
`;

class Checkbox extends React.PureComponent {
  static defaultProps = {
    disabled: false,
  };

  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node.isRequired,
    ]).isRequired,
    value: PropTypes.bool.isRequired,
  };

  toggleChecked = () => {
    if (!this.props.disabled) {
      this.props.onChange(this.props.name, !this.props.value);
    }
  };

  render() {
    return (
      <Container
        htmlFor={this.props.name}
        onClick={this.toggleChecked}
        disabled={this.props.disabled}
      >
        {this.props.title}
        <input
          disabled={this.props.disabled}
          name={this.props.name}
          type="checkbox"
          checked={this.props.value}
          onChange={this.toggleChecked}
        />
        <span className="checkmark" />
      </Container>
    );
  }
}

export default Checkbox;
