import React from 'react';
import PropTypes from 'prop-types';
import SwitchNormal from 'react-toggle-switch';
import styled from 'styled-components';
import 'react-toggle-switch/dist/css/switch.min.css';

import SaveIcon from './icon.svg';
import SaveIconDisabled from './icon-disabled.svg';

const SwitchWrapper = styled.div`
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  height: 40px;
  padding-top: 15px;

  .equal-insurer {
    width: 58px;
    height: 10px;
    background: #EBEBED;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .4);
    border: none;
    border-radius: 45px;
    display: flex;
    margin-right: 23px;
    cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};

    &.on {
      background: #EBEBED;
    }

    & .switch-toggle {
      background-color: #EBEBED;
      background-image: ${props => (props.enabled ? `url(${SaveIcon})` : `url(${SaveIconDisabled})`)};
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      border: none;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .3);
      margin-top: -15px;
      width: 40px;
      height: 40px;
      transition: all .2s ease-in-out;
    }

    &.on .switch-toggle {
      background-color: #35BB9A;
      left: 18px;
    }
  }
`;

const SaveSwitcher = props => (
  <SwitchWrapper enabled={!props.disabled}>
    <SwitchNormal {...props} />
  </SwitchWrapper>
);

SaveSwitcher.propTypes = {
  disabled: PropTypes.bool,
};

export default SaveSwitcher;
