import styled from 'styled-components';
import pure from 'recompose/pure';
import NormalText from '../../../../components/Text';

const Label = pure(styled(NormalText)`
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  font-weight: bold;
  user-select: none;
`);

export default Label;
