import React from 'react';
import { ErrorMessage } from 'formik';
import classNames from 'classnames';
import Select from '../../components/Select';

import renderErrorMessage from './renderErrorMessage';

const renderInput = (
  {
    field: {
      onChange, ...fieldRest
    },
    form: {
      errors, touched, submitCount, setFieldValue,
    },
    ...props
  },
) => {
  const isInvalid = errors[fieldRest.name] && (touched[fieldRest.name] || submitCount > 0);

  const onSelectChange = ({ value }) => {
    setFieldValue(fieldRest.name, value);
  };

  return (
    <>
      <Select
        onChange={onSelectChange}
        className={classNames([
          isInvalid && 'react-select-error',
        ])}
        classNamePrefix="react-select"
        {...fieldRest}
        {...props}
      />
      <ErrorMessage name={fieldRest.name} render={renderErrorMessage} />
    </>
  );
};

export default renderInput;
