import { createSelector } from 'reselect';
import concat from 'lodash/concat';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectGamesOptions = () => createSelector(
  storeSlice,
  state => concat(state.get('options'), state.get('optionsShowcase')),
);

export {
  makeSelectGamesOptions,
};
