export default {
  PREFIXES: {
    RESTORE_PASSWORD: '/reset/set-password',
    START_TRIAL_GAME: '/start-demo-game',
    SET_PASSWORD_FROM_INVITE: '/invite/set-password',
    SET_B2C_EMAIL_FROM_B2B: '/request/set-user-email',
    USER_ACTIVATION: '/user-activation',
    GAME_OVERVIEW: '/games/overview',
    SINGLE_GAME: '/games',
  },
  URLS: {
    INDEX_PAGE: '/',
    LOGIN: '/login',
    PROFILE: '/profile',
    FORGOT_PASSWORD: '/reset',
    REGISTRATION: '/registration',
    COMPANY_PAYMENT: '/payment',
    CHANGE_COMPANY_ACCOUNT_DETAILS: '/change-account-details',
    GAMES_STORE: '/',
    GAME_FINISH: '/finish',
    PAYMENT_SUCCESS: '/payment/success',
    PAYMENT_FAIL: '/payment/fail',
    COMPANY_GAME_RATING: '/rating',
    COMMON_RATING: '/common-rating',
    ABOUT: '/about',
    LICENCE: '/licence',
    ORGANIZATION: '/organization',
    PAYMENT_METHODS: '/payment-methods',
    COMPANY_EMPLOYEES: '/employees',
    POLICY: '/policy',
    PROCESSING_PERSONAL_DATA: '/processing-personal-data',
    PROMO: '/',
    PURCHASE_TERMS: '/purchase-terms',
    GET_FULL_ACCESS: '/get-full-access',
    DEMO_FINISH: '/demo-finish',
  },
};
