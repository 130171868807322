import React from 'react';
import Spinner from 'react-spinkit';

import Wrapper from './Wrapper';

const LoadingIndicator = () => (
  <Wrapper>
    <Spinner
      name="circle"
      fadeIn="none"
      className="spinner"
      color="#999"
    />
  </Wrapper>
);

export default LoadingIndicator;
