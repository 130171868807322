import styled from 'styled-components';

const PhonesWrapper = styled.div`
  margin-top: 65px;
  width: calc(50% - 310px);
  
  ${props => props.small && `
    margin-top: 0;
    width: 100%;
  `}
  
  @media (max-width: 1200px) {
    margin-top: 0;
    width: 100%;
  }
`;

export default PhonesWrapper;
