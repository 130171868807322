import {
  GET_FINISH_PAGE_REPORT_FAIL,
  GET_FINISH_PAGE_REPORT_SUCCESS,
  GET_FINISH_PAGE_REPORT,
} from './constants';

export function getFinishPageReport() {
  return {
    type: GET_FINISH_PAGE_REPORT,
  };
}

export function getFinishPageReportSuccess(
  {
    dataTopOne,
    fieldsTopOne,
    dataTopTwo,
    fieldsTopTwo,
    dataBottomOne,
    fieldsBottomOne,
    dataBottomTwo,
    fieldsBottomTwo,
  },
) {
  return {
    type: GET_FINISH_PAGE_REPORT_SUCCESS,
    payload: {
      dataTopOne,
      fieldsTopOne,
      dataTopTwo,
      fieldsTopTwo,
      dataBottomOne,
      fieldsBottomOne,
      dataBottomTwo,
      fieldsBottomTwo,
    },
  };
}

export function getFinishPageReportFail() {
  return {
    type: GET_FINISH_PAGE_REPORT_FAIL,
  };
}
