import {
  CHANGE_NAME,
  CHANGE_SURNAME,
  CHANGE_NICKNAME,
  CHANGE_EMAIL,
  CHANGE_DEPARTAMENT,
  CHANGE_POSITION,
  PROFILE_REQUEST,
  PROFILE_REQUEST_SUCCESS,
  PROFILE_REQUEST_FAIL,
  PROFILE_REQUEST_CHANGE,
  PROFILE_REQUEST_CHANGE_SUCCESS,
  PROFILE_REQUEST_CHANGE_FAIL,
  PROFILE_CASH_REQUEST,
  PROFILE_CASH_REQUEST_SUCCESS,
  PROFILE_CASH_REQUEST_FAIL,
  PROFILE_RATING_REQUEST,
  PROFILE_RATING_REQUEST_SUCCESS,
  PROFILE_RATING_REQUEST_FAIL,
  USER_PHOTO_REQUEST,
  USER_PHOTO_REQUEST_SUCCESS,
  USER_PHOTO_REQUEST_FAIL,
  CHANGE_PATRONYMIC, CHANGE_SHOW_ME_IN_RATING,
} from './constants';

export function changeProfileInfoRequest() {
  return {
    type: PROFILE_REQUEST_CHANGE,
  };
}

export function changeProfileInfoRequestSuccess() {
  return {
    type: PROFILE_REQUEST_CHANGE_SUCCESS,
  };
}

export function changeProfileInfoRequestFail() {
  return {
    type: PROFILE_REQUEST_CHANGE_FAIL,
  };
}

export function getProfileInfo() {
  return {
    type: PROFILE_REQUEST,
  };
}

export function profileInfoSuccess(profile) {
  return {
    type: PROFILE_REQUEST_SUCCESS,
    profile,
  };
}

export function profileInfoFail() {
  return {
    type: PROFILE_REQUEST_FAIL,
  };
}

export function changeName(name) {
  return {
    type: CHANGE_NAME,
    name,
  };
}

export function changeShowMeInRating(showMeInRating) {
  return {
    type: CHANGE_SHOW_ME_IN_RATING,
    showMeInRating,
  };
}

export function changeSurname(surname) {
  return {
    type: CHANGE_SURNAME,
    surname,
  };
}

export function changePatronymic(patronymic) {
  return {
    type: CHANGE_PATRONYMIC,
    patronymic,
  };
}

export function changeNickname(nickname) {
  return {
    type: CHANGE_NICKNAME,
    nickname,
  };
}

export function changeEmail(email) {
  return {
    type: CHANGE_EMAIL,
    email,
  };
}

export function changeDepartament(departament) {
  return {
    type: CHANGE_DEPARTAMENT,
    departament,
  };
}

export function changePosition(position) {
  return {
    type: CHANGE_POSITION,
    position,
  };
}

export function getCashInfo() {
  return {
    type: PROFILE_CASH_REQUEST,
  };
}

export function getCashInfoSuccess(payload) {
  return {
    type: PROFILE_CASH_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getCashInfoFail() {
  return {
    type: PROFILE_CASH_REQUEST_FAIL,
  };
}

export function getRating() {
  return {
    type: PROFILE_RATING_REQUEST,
  };
}

export function getRatingSuccess(payload) {
  return {
    type: PROFILE_RATING_REQUEST_SUCCESS,
    ...payload,
  };
}

export function getRatingFail() {
  return {
    type: PROFILE_RATING_REQUEST_FAIL,
  };
}

export function setUserPhoto(payload) {
  return {
    type: USER_PHOTO_REQUEST,
    ...payload,
  };
}

export function setUserPhotoSuccess() {
  return {
    type: USER_PHOTO_REQUEST_SUCCESS,
  };
}

export function setUserPhotoFail() {
  return {
    type: USER_PHOTO_REQUEST_FAIL,
  };
}
