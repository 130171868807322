import styled from 'styled-components';

const MainWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEDEDE;
  border-radius: 50%;
`;

export default MainWrapper;
