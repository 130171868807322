import {
  call, select, put, takeLeading,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { Base64 } from 'js-base64';
import passwordHash from '../../../../utils/passwordHash';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import {
  sendPasswordRestoreRequestSuccess,
  sendPasswordRestoreRequestFail,
  validateTokenSuccess,
  validateTokenFail,
} from '../../../modules/forms/setPasswordForm/actions';
import { makeSelectApiUrl } from '../../../modules/global/selectors';
import {
  makeSelectToken,
  makeSelectPassword,
  makeSelectIsEmployee,
} from '../../../modules/forms/setPasswordForm/selectors';
import {
  PASSWORD_RESTORE_REQUEST,
  PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST,
} from '../../../modules/forms/setPasswordForm/constants';

function build({ cookies }) {
  const apiRequest = createApiRequest(cookies, false);

  function* setPasswordSaga() {
    const url = yield select(makeSelectApiUrl());
    const tokenBase64 = yield select(makeSelectToken());
    const password = yield select(makeSelectPassword());
    const token = Base64.decode(tokenBase64);

    const passwordHashValue = passwordHash(password);
    const isEmployee = yield select(makeSelectIsEmployee());

    const requestURL = `${url}/RemindPassword/${encodeURIComponent(token)}/${encodeURIComponent(isEmployee)}/${encodeURIComponent(passwordHashValue)}/`;

    const config = {
      method: 'post',
    };

    try {
      const request = yield call(apiRequest, requestURL, config);

      const success = get(request, 'data.result[0].Result', false);
      if (success === 'success') {
        yield put(sendPasswordRestoreRequestSuccess());
      } else {
        yield put(sendPasswordRestoreRequestFail());
      }
    } catch (e) {
      yield put(sendPasswordRestoreRequestFail());
    }
  }

  function* validateTokenSaga() {
    const url = yield select(makeSelectApiUrl());
    const tokenBase64 = yield select(makeSelectToken());
    const token = Base64.decode(tokenBase64);
    const isEmployee = yield select(makeSelectIsEmployee());

    const requestURL = `${url}/ValidateRemindPassword/${encodeURIComponent(token)}/${encodeURIComponent(isEmployee)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const result = get(request, 'data.result[0].Result', 'fail');
      if (result === 'success') {
        const isValidToken = get(request, 'data.result[0].IsRemindPassword', false);
        yield put(validateTokenSuccess(isValidToken));
      } else {
        yield put(validateTokenFail());
      }
    } catch (e) {
      yield put(validateTokenFail());
    }
  }

  function* saga() {
    yield takeLeading(PASSWORD_RESTORE_REQUEST, setPasswordSaga);
    yield takeLeading(PASSWORD_RESTORE_VALIDATE_TOKEN_REQUEST, validateTokenSaga);
  }

  return saga;
}

export default build;
