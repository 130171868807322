import React, { useState, useCallback } from 'react';
import map from 'lodash/map';
import pure from 'recompose/pure';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PoseGroup } from 'react-pose';
import { useMedia } from 'the-platform';
import { device } from '../../utils/mediaQueries';

import Tooltip from './Tooltip';

const Wrapper = pure(styled.div`
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1), 0 0 2px 0 rgba(0, 0, 0, .1);
  left: 20px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  user-select: none;
  cursor: pointer;
`);

const Item = pure(styled.div`
  align-items: center;
  background-color: ${props => (props.isActive ? '#3D3F4A' : '#EBEBED')};
  border-radius: 50%;
  color: ${props => (props.isActive ? '#FFFFFF' : '#3D3F4A')};
  display: flex;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  margin: 10px;
  width: 30px;
`);

const Stages = ({ activeStage, stagesInfo, onClick }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const showTooltipCallBack = useCallback((stage) => {
    setShowTooltip(stage);
  }, []);

  const hideTooltipCallBack = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const onClickCallback = useCallback((item) => () => {
    if (onClick) {
      onClick(item);
    }
  }, [onClick]);

  const small = useMedia(device.tablet);

  return (
    <Wrapper
      className="tour-step-stages"
    >
      {map(stagesInfo, item => (
        <Item
          key={item.key}
          isActive={activeStage === item.key}
          onMouseEnter={() => showTooltipCallBack(item.key)}
          onMouseLeave={hideTooltipCallBack}
          onClick={onClickCallback(item)}
        >
          {item.Name}
        </Item>
      ))}
      {!small && (
        <PoseGroup>
          {map(stagesInfo, (item) => {
            if (item.Hint && showTooltip === item.key) {
              return <Tooltip key={item.key} dangerouslySetInnerHTML={{ __html: item.Hint }} />;
            }

            return null;
          })}
        </PoseGroup>
      )}
    </Wrapper>
  );
};

Stages.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeStage: PropTypes.number.isRequired,
  tooltipText: PropTypes.string,
};

Stages.defaultProps = {
  tooltipText: null,
};

export default Stages;
