import find from 'lodash/find';
import get from 'lodash/get';
import {
  call, put, select, takeLeading, delay,
} from 'redux-saga/effects';

import { apiRequest as createApiRequest } from '../../../utils/request';
import { makeSelectGames, makeSelectGamesShowcases } from '../../modules/games/selectors';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import {
  getPersonalGameHeadRatingFail,
  getPersonalGameHeadRatingSuccess,
  getCommonGameRatingFail,
  getCommonGameRatingSuccess,
  getCompanyGameHeadRatingSuccess,
  getCompanyGameHeadRatingFail,
  getCompanyGameRatingSuccess,
  getCompanyGameRatingFail,
  getExtendedEmployeeGameRatingFail,
  getExtendedEmployeeGameRatingSuccess,
} from '../../modules/rating/actions';

import {
  GET_PERSONAL_GAME_HEAD_RATING,
  GET_COMMON_GAME_RATING,
  GET_COMPANY_GAME_HEAD_RATING,
  GET_COMPANY_GAME_RATING,
  GET_EXTENDED_EMPLOYEE_RATING,
} from '../../modules/rating/constants';
import getCommonGameRating from '../getters/getCommonGameRating';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* getPersonalGameHeadRatingSaga(action) {
    const { payload: { gameId } } = action;

    try {
      const url = yield select(makeSelectApiUrl());

      let games = yield select(makeSelectGames());
      let gamesShowcases = yield select(makeSelectGamesShowcases());

      // TODO: HOTFIX надо переделать чтобы без datapolling работало
      while (!games || !gamesShowcases) {
        yield delay(100);

        games = yield select(makeSelectGames());
        gamesShowcases = yield select(makeSelectGamesShowcases());
      }

      const allGames = [...games, ...gamesShowcases];
      const currentGame = find(allGames, { GameID: gameId });
      const viewName = get(currentGame, 'OptionsValues.headerViewName');

      if (!viewName) {
        throw new Error('получение рейтинга не реализовано для этой игры');
      }

      const requestURL = `${url}/ReportGet/${viewName}///0/1/user`;

      const request = yield call(apiRequest, requestURL);

      const data = get(request, 'data.result[0].Data[0]');
      const fields = get(request, 'data.result[0].FieldList', null);

      if (!data) {
        throw new Error('ошибка получения рейтинга игры');
      }

      yield put(getPersonalGameHeadRatingSuccess({ gameId, data, fields }));
    } catch (err) {
      yield put(getPersonalGameHeadRatingFail({ gameId }));
    }
  }

  function* getCommonGameRatingSaga(action) {
    const { payload: { gameId } } = action;

    try {
      const { data, fields } = yield getCommonGameRating({ gameId, apiRequest });

      yield put(getCommonGameRatingSuccess({ gameId, data, fields }));
    } catch (err) {
      yield put(getCommonGameRatingFail({ gameId }));
    }
  }

  function* getCompanyGameHeadRatingSaga(action) {
    const { payload: { gameId } } = action;

    try {
      const url = yield select(makeSelectApiUrl());

      let games = yield select(makeSelectGames());
      let gamesShowcases = yield select(makeSelectGamesShowcases());

      // TODO: HOTFIX надо переделать чтобы без datapolling работало
      while (!games || !gamesShowcases) {
        yield delay(100);

        games = yield select(makeSelectGames());
        gamesShowcases = yield select(makeSelectGamesShowcases());
      }

      const allGames = [...games, ...gamesShowcases];

      const currentGame = find(allGames, { GameID: gameId });
      const viewName = get(currentGame, 'OptionsValues.headerCompanyViewName');

      if (!viewName) {
        throw new Error('получение рейтинга не реализовано для этой игры');
      }

      const requestURL = `${url}/ReportGet/${viewName}///0/0/company`;

      const request = yield call(apiRequest, requestURL);

      const data = get(request, 'data.result[0].Data[0]', null);
      const fields = get(request, 'data.result[0].FieldList', null);

      if (!data) {
        throw new Error('ошибка получения рейтинга игры');
      }

      yield put(getCompanyGameHeadRatingSuccess({ gameId, data, fields }));
    } catch (err) {
      yield put(getCompanyGameHeadRatingFail({ gameId }));
    }
  }

  function* getCompanyGameRatingSaga(action) {
    const { payload: { gameId, isDirector } } = action;

    try {
      const url = yield select(makeSelectApiUrl());

      const games = yield select(makeSelectGames());
      const gamesShowcases = yield select(makeSelectGamesShowcases());
      const allGames = [...games, ...gamesShowcases];

      const currentGame = find(allGames, { GameID: gameId });
      let viewName = get(currentGame, 'OptionsValues.Report_Company');

      if (isDirector) {
        viewName = get(currentGame, 'OptionsValues.Report_CompanyEx');
      }

      if (!viewName) {
        throw new Error('получение рейтинга не реализовано для этой игры');
      }

      const requestURL = `${url}/ReportGet/${viewName}///0/0/company`;

      const request = yield call(apiRequest, requestURL);

      const data = get(request, 'data.result[0].Data', null);
      const fields = get(request, 'data.result[0].FieldList', null);

      if (!data) {
        throw new Error('ошибка получения рейтинга игры');
      }

      yield put(getCompanyGameRatingSuccess({ gameId, data, fields }));
    } catch (err) {
      yield put(getCompanyGameRatingFail({ gameId }));
    }
  }

  function* getExtendedEmployeeGameRatingSaga(action) {
    const { payload: { gameId, employeeId } } = action;

    try {
      const url = yield select(makeSelectApiUrl());

      const games = yield select(makeSelectGames());
      const gamesShowcases = yield select(makeSelectGamesShowcases());
      const allGames = [...games, ...gamesShowcases];
      const currentGame = find(allGames, { GameID: gameId });
      const viewName = get(currentGame, 'OptionsValues.employeeRatingViewName');

      if (!viewName) {
        throw new Error('получение рейтинга не реализовано для этой игры');
      }

      const requestURL = `${url}/ReportGet/${viewName}/${encodeURIComponent(employeeId)}//0/5/anyemployee/`;

      const request = yield call(apiRequest, requestURL);

      const data = get(request, 'data.result[0].Data', null);
      const fields = get(request, 'data.result[0].FieldList', null);
      if (!data) {
        throw new Error('ошибка получения рейтинга игры');
      }

      yield put(getExtendedEmployeeGameRatingSuccess({
        gameId,
        data,
        fields,
        employeeId,
      }));
    } catch (err) {
      yield put(getExtendedEmployeeGameRatingFail({ gameId, employeeId }));
    }
  }

  function* ratingSaga() {
    yield takeLeading(GET_PERSONAL_GAME_HEAD_RATING, getPersonalGameHeadRatingSaga);
    yield takeLeading(GET_COMMON_GAME_RATING, getCommonGameRatingSaga);
    yield takeLeading(GET_COMPANY_GAME_HEAD_RATING, getCompanyGameHeadRatingSaga);
    yield takeLeading(GET_COMPANY_GAME_RATING, getCompanyGameRatingSaga);
    yield takeLeading(GET_EXTENDED_EMPLOYEE_RATING, getExtendedEmployeeGameRatingSaga);
  }

  return ratingSaga;
}

export default build;
