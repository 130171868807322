import { fromJS } from 'immutable';
import {
  SET_SHOW_LIBRARY_POPUP,
  SET_ACTIVE_CARD_ID,
  SET_ACTIVE_CARD_GROUP_ID,
  SET_ACTIVE_LIBRARY_LINK,
} from './constants';

const initialState = fromJS({
  showLibraryPopup: false,
  activeCardId: null,
  activeCardGroupId: null,
  activeLibraryLink: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_LIBRARY_POPUP:
      return state
        .set('showLibraryPopup', action.payload.showLibraryPopup);
    case SET_ACTIVE_CARD_ID:
      return state
        .set('activeCardId', action.payload.activeCardId);
    case SET_ACTIVE_LIBRARY_LINK:
      return state
        .set('activeLibraryLink', action.payload.activeLibraryLink);
    case SET_ACTIVE_CARD_GROUP_ID:
      return state
        .set('activeCardGroupId', action.payload.activeCardGroupId);
    default:
      return state;
  }
}

export default reducer;
