import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import SupportFloatButton from '../Support/SupportFloatButton';
import DebugTip from './DebugTip';

import FiftyFiftyTip from './FiftyFiftyTip';
import HighlightTip from './HightlightTip';
import SaveTip from './SaveTip';
import TipCount from './TipCount';
import LibraryTip from './Library';

const TipsWrapper = styled.div`
  position: fixed;
  ${props => (props.small ? 'top: 60px' : 'top: 71px')};
  ${props => (props.small ? 'right: 11px' : 'right: 20px')};
  height: ${props => (props.small ? 'calc(var(--vh, 1vh) * 100 - 60px)' : 'calc(100vh - 81px)')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: none;
  
  @media (max-height: 500px) and (orientation: landscape) {
    left: 80px;
    width: calc(100% - 157px);
    flex-direction: row;
    height: 50px;
    bottom: 20px;
    right: initial;
    top: initial;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${props => (props.small ? 'calc(100% - 80px)' : '100%')};
  @media (max-height: 500px) and (orientation: landscape) {
    height: 80px;
    width: 100%;
    flex-direction: row;
  }
`;

const TopTipGroup = styled.div`
  height: 70px;
  pointer-events: auto;
  ${props => (!props.small && props.isTrialGame && `
    @media (min-height: 500px) and (orientation: landscape) {
        position: relative;
        top: 30px;
    }
  `)};
`;

const MiddleTipGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
  
  @media (max-height: 500px) and (orientation: landscape) {
    height: 80px;
    width: 100%;
    flex-direction: row;
    min-height: initial;
  }
`;

const BottomTipGroup = styled.div`
  height: 50px;
  
  @media (max-height: 500px) and (orientation: landscape) {
    position: relative;
    top: 10px;
  }
`;

const TipAnimation = posed.div({
  hidden: {
    x: 100,
    height: '100%',
  },
  visible: {
    x: 1,
    height: '100%',
  },
});

class Tips extends React.PureComponent {
  static propTypes = {
    fiftyFiftyClick: PropTypes.func,
    fiftyFiftyTipCount: PropTypes.number,
    highlightClick: PropTypes.func,
    highlightTipCount: PropTypes.number,
    libraryClick: PropTypes.func,
    saveClick: PropTypes.func,
    saveCount: PropTypes.number,
    showFiftyFiftyTip: PropTypes.bool,
    showHighlightTip: PropTypes.bool,
    canDebug: PropTypes.bool.isRequired,
    onDebugTipClick: PropTypes.func.isRequired,
    showSave: PropTypes.bool,
    small: PropTypes.bool,
    visible: PropTypes.bool,
    isTrialGame: PropTypes.bool,
  };

  render() {
    const highlightTipCount = this.props.highlightTipCount > 0 ? this.props.highlightTipCount : '+';
    const fiftyFiftyTipCount = this.props.fiftyFiftyTipCount > 0 ? this.props.fiftyFiftyTipCount : '+';
    const saveCount = this.props.saveCount > 0 ? this.props.saveCount : '+';

    const { small, visible } = this.props;

    let pose = 'visible';
    if (!visible) {
      pose = 'hidden';
    }

    return (
      <TipsWrapper small={small}>
        <Inner small={small}>
          <TopTipGroup className="tour-step-library" small={small} isTrialGame={this.props.isTrialGame}>
            {this.props.hasLibrary && (
              <PoseGroup>
                <TipAnimation key="LibraryTip" delay={0} initialPose="hidden" pose={pose}>
                  <LibraryTip
                    small={small}
                    onClick={this.props.libraryClick}
                  />
                </TipAnimation>
              </PoseGroup>
            )}
          </TopTipGroup>
          <MiddleTipGroup className="tour-step-tips">
            <PoseGroup>
              {this.props.showHighlightTip && (
                <TipAnimation key="HighlightTip" delay={100} initialPose="hidden" pose={pose}>
                  <HighlightTip
                    small={small}
                    onClick={this.props.highlightClick}
                  >
                    <TipCount>
                      {highlightTipCount}
                    </TipCount>
                  </HighlightTip>
                </TipAnimation>
              )}
              {this.props.showFiftyFiftyTip && (
                <TipAnimation key="FiftyFiftyTip" delay={300} initialPose="hidden" pose={pose}>
                  <FiftyFiftyTip
                    small={small}
                    onClick={this.props.fiftyFiftyClick}
                  >
                    <TipCount>
                      {fiftyFiftyTipCount}
                    </TipCount>
                  </FiftyFiftyTip>
                </TipAnimation>
              )}
              {this.props.showSave && (
                <TipAnimation key="SaveTip" delay={500} initialPose="hidden" pose={pose}>
                  <SaveTip
                    small={small}
                    onClick={this.props.saveClick}
                  >
                    <TipCount>
                      {saveCount}
                    </TipCount>
                  </SaveTip>
                </TipAnimation>
              )}
              {this.props.canDebug && (
                <TipAnimation key="DebugTip" delay={500} initialPose="hidden" pose={pose}>
                  <DebugTip
                    small={small}
                    onClick={this.props.onDebugTipClick}
                  />
                </TipAnimation>
              )}
            </PoseGroup>
          </MiddleTipGroup>
          <BottomTipGroup>
            <PoseGroup>
              {small && (
                <TipAnimation key="SupportFloatButton" delay={150} initialPose="hidden" pose={pose}>
                  <SupportFloatButton />
                </TipAnimation>
              )}
            </PoseGroup>
          </BottomTipGroup>
        </Inner>
      </TipsWrapper>
    );
  }
}

export default Tips;
