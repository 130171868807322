import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import pure from 'recompose/pure';
import Error from '../../../../components/Error';
import Success from '../../../../components/RoundMark/Success';
import SpinnerInButton from '../../../../components/SpinnerInButton';
import Button from '../../../../components/useThis/Button';
import EmployeeForm from '../../../../FormikForms/EmployeeForm';
import EmployeeFormValidateSchema from '../../../../FormikForms/EmployeeForm/EmployeeFormValidateSchema';
import Popup from '../../../../components/Popup';

const ButtonWrapper = pure(styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`);

const SuccessWrapper = pure(styled.div`
  display: flex;
  justify-content: center;
`);

class AddEmployeePopupForm extends React.PureComponent {
  static defaultProps = {
    departmentsList: [],
    serverErrorMessage: null,
    initialValues: {
      email: '',
      surname: '',
      name: '',
      patronymic: '',
      departmentId: null,
    },
  };

  static propTypes = {
    departmentsList: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ),
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      email: PropTypes.string,
      surname: PropTypes.string,
      name: PropTypes.string,
      patronymic: PropTypes.string,
      departmentId: PropTypes.number,
    }),
    pending: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    popupState: PropTypes.bool.isRequired,
    serverErrorMessage: PropTypes.string,
  };

  render() {
    const {
      pending, popupState, handleSubmit, initialValues, departmentsList, onClose,
    } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={EmployeeFormValidateSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const submitSucceeded = props.status && props.status.submitSucceeded;
          const popupTitle = submitSucceeded ? 'Сотрудник добавлен' : 'Добавить сотрудника';
          const resetFormAndClosePopup = () => {
            onClose();
            props.resetForm();
          };

          const onPopupClose = submitSucceeded ? resetFormAndClosePopup : onClose;

          return (
            <Popup
              pending={pending}
              isActive={popupState}
              onClose={onPopupClose}
              withoutActions
              title={popupTitle}
            >
              {submitSucceeded && (
                <>
                  <SuccessWrapper>
                    <Success />
                  </SuccessWrapper>
                  <div>
                    Выслано приглашение на e-mail
                    {' '}
                    <b>{props.values.email}</b>.
                  </div>
                  <ButtonWrapper>
                    <Button
                      isAlternative
                      onClick={resetFormAndClosePopup}
                    >
                      Закрыть
                    </Button>
                  </ButtonWrapper>
                </>
              )}

              {!submitSucceeded && (
                <Form>
                  {props.status && props.status.globalError && (
                    <Error>{props.status.globalError}</Error>
                  )}
                  <EmployeeForm
                    departmentsList={departmentsList}
                    {...props}
                  />
                  <div>
                    <i>После добавления, сотруднику будет отправлено приглашение на&nbsp;указанный вами e-mail.</i>
                  </div>
                  <ButtonWrapper>
                    <Button
                      isAlternative
                      onClick={onClose}
                      disabled={props.isSubmitting}
                    >
                      Отмена
                    </Button>
                    <Button
                      disabled={props.isSubmitting}
                    >
                      {!props.isSubmitting && 'Добавить'}
                      {props.isSubmitting && (
                        <SpinnerInButton />
                      )}
                    </Button>
                  </ButtonWrapper>
                </Form>
              )}
            </Popup>
          );
        }}
      </Formik>
    );
  }
}

export default AddEmployeePopupForm;
