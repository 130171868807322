import PropTypes from 'prop-types';
import React from 'react';
import { PoseGroup } from 'react-pose';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useMedia } from 'the-platform';
import styled from 'styled-components';
import {
  makeSelectClientMoodAbsoluteValue,
  makeSelectClientMoodDirection,
  makeSelectClientMoodStatus,
} from '../../../redux/modules/myGame/selectors';
import { device } from '../../../utils/mediaQueries';

import ClientStatusNeutral from '../../../assets/statusImages/clientStatusNeutral.svg';
import ClientStatusInterest from '../../../assets/statusImages/clientStatusInterest.svg';
import ClientStatusConfidence from '../../../assets/statusImages/clientStatusConfidence.svg';
import ClientStatusWatchfulness from '../../../assets/statusImages/clientStatusWatchfulness.svg';

import {
  Wrapper, Mood, Up, Down, Tooltip,
} from './elements';

const ClientWrapper = styled(Wrapper)`
  ${props => props.small && 'margin-left: 10px;'}
`;

class ClientStatusHOC extends React.PureComponent {
  static defaultProps = {
    clientMoodDirection: null,
    clientMoodStatus: null,
    clientMoodValue: null,
  };

  static propTypes = {
    clientMoodDirection: PropTypes.string,
    clientMoodStatus: PropTypes.number,
    clientMoodValue: PropTypes.number,
    onClick: PropTypes.func.isRequired,
  };

  state = {
    showTooltip: false,
  };

  onMouseOver = () => {
    this.setState({
      showTooltip: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      showTooltip: false,
    });
  };

  onClick = (e) => {
    const { onClick } = this.props;

    this.setState(
      {
        showTooltip: false,
      },
      () => onClick(e),
    );
  };

  render() {
    const {
      clientMoodStatus, clientMoodDirection, clientMoodValue, clientMoodAbsoluteValue,
    } = this.props;
    if (!clientMoodStatus) {
      return null;
    }

    const { showTooltip } = this.state;

    let moodImage = ClientStatusNeutral;

    // TODO: HARDCODE Зашиты возможные значения настроений клиента
    switch (clientMoodStatus) {
      case 1:
        moodImage = ClientStatusNeutral;
        break;
      case 2:
        moodImage = ClientStatusInterest;
        break;
      case 3:
        moodImage = ClientStatusConfidence;
        break;
      case 4:
        moodImage = ClientStatusWatchfulness;
        break;
      default:
        moodImage = ClientStatusNeutral;
        break;
    }

    return (
      <ClientStatusComponent
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        moodImage={moodImage}
        clientMoodStatus={clientMoodStatus}
        clientMoodDirection={clientMoodDirection}
        clientMoodValue={clientMoodValue}
        clientMoodAbsoluteValue={clientMoodAbsoluteValue}
        showTooltip={showTooltip}
      />
    );
  }
}

export const ClientStatusComponent = (
  {
    onClick, onMouseOver, onMouseLeave,
    showTooltip, moodImage,
    clientMoodDirection, clientMoodStatus,
    clientMoodAbsoluteValue,
  },
) => {
  const small = useMedia(device.tablet);

  return (
    <ClientWrapper small={small}>
      <div
        onClick={onClick}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onBlur={onMouseLeave}
      >
        <PoseGroup>
          <Mood backgroundImage={moodImage} clientMoodStatus={clientMoodStatus} key={clientMoodStatus} small={small} />
        </PoseGroup>
        <PoseGroup>
          {clientMoodDirection === 'up' && <Up key={`${clientMoodAbsoluteValue}-up`} />}
          {clientMoodDirection === 'down' && <Down key={`${clientMoodAbsoluteValue}-down`} />}
        </PoseGroup>
      </div>
      <PoseGroup>
        {showTooltip && (
          <Tooltip key="tooltip">
            Это настроение клиента. Оно меняется в{'\u00A0'}зависимости от{'\u00A0'}твоего выбора. Стрелки тебе помогут.
            Будь внимательнее.
          </Tooltip>
        )}
      </PoseGroup>
    </ClientWrapper>
  );
};

ClientStatusComponent.propTypes = {
  clientMoodDirection: PropTypes.string,
  clientMoodStatus: PropTypes.number.isRequired,
  clientMoodAbsoluteValue: PropTypes.number.isRequired,
  moodImage: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};

ClientStatusComponent.defaultProps = {
  clientMoodDirection: null,
};

const mapStateToProps = createStructuredSelector({
  clientMoodStatus: makeSelectClientMoodStatus(),
  clientMoodDirection: makeSelectClientMoodDirection(),
  clientMoodAbsoluteValue: makeSelectClientMoodAbsoluteValue(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(ClientStatusHOC);
