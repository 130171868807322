import { createSelector } from 'reselect';

const form = state => state.get('addEmployeeForm');

const makeSelectAddEmployeeError = () => createSelector(
  form,
  state => state.get('error'),
);

const makeSelectAddEmployeePending = () => createSelector(
  form,
  state => state.get('pending'),
);

export {
  makeSelectAddEmployeeError,
  makeSelectAddEmployeePending,
};
