import styled from 'styled-components';

const EmptyImage = styled.div`
  align-items: center;
  background-color: #808085;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: ${props => (props.inHeader ? '40px' : '150px')};
  justify-content: center;
  position: relative;
  width: ${props => (props.inHeader ? '40px' : '150px')};
  user-select: none;
`;

export default EmptyImage;
