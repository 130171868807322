import styled from 'styled-components';

const A = styled.a`
  color: #343641;
  text-decoration: none;

  &:hover {
    color: #5FB89C;
    text-decoration: underline;
  }
`;

export default A;
