import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import AnimatedNumber from 'react-animated-number';
import {
  makeSelectContractFrom,
  makeSelectContractTo,
} from '../../../redux/modules/myGame/selectors';

const TextCenter = styled.div`
  text-align: center;
`;

const ContractText = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 2px;
  text-align: center;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const ANIMATION_DURATION = 1500;

class ContractRangeHOC extends React.PureComponent {
  static defaultProps = {
    contractFrom: null,
    contractTo: null,
  };

  static propTypes = {
    contractFrom: PropTypes.number,
    contractTo: PropTypes.number,
  };

  state = {
    animationDuration: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { contractFrom, contractTo } = this.props;

    if (
      !isNull(contractFrom) && !isNull(contractTo)
      && (nextProps.contractFrom !== contractFrom || nextProps.contractTo !== contractTo)
    ) {
      this.setState({
        animationDuration: ANIMATION_DURATION,
      });
    }
  }

  render() {
    const { contractFrom, contractTo } = this.props;

    const { animationDuration } = this.state;

    return (
      <TextCenter className="tour-step-contract-range">
        {contractFrom && contractTo && (
          <ContractRangeComponent
            contractTo={contractTo}
            contractFrom={contractFrom}
            animationDuration={animationDuration}
          />
        )}
      </TextCenter>
    );
  }
}

export const ContractRangeComponent = (
  {
    contractFrom, contractTo, animationDuration,
  },
) => {
  if (isNaN(contractFrom) || isNaN(contractTo)) {
    return null;
  }

  const contractFromStyle = {
    width: String(contractFrom.toLocaleString()).length * 10,
  };

  const contractToStyle = {
    width: String(contractTo.toLocaleString()).length * 10,
  };

  return (
    <React.Fragment>
      <ContractText>
        <AnimatedNumber
          style={contractFromStyle}
          component="div"
          value={contractFrom}
          duration={animationDuration}
          stepPrecision={0}
          formatValue={n => n.toLocaleString()}
        />
        <div>&mdash;</div>
        <AnimatedNumber
          style={contractToStyle}
          component="div"
          value={contractTo}
          duration={animationDuration}
          stepPrecision={0}
          formatValue={n => n.toLocaleString()}
        />
      </ContractText>
    </React.Fragment>
  );
};

ContractRangeComponent.propTypes = {
  animationDuration: PropTypes.number.isRequired,
  contractFrom: PropTypes.number.isRequired,
  contractTo: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  contractFrom: makeSelectContractFrom(),
  contractTo: makeSelectContractTo(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
)(ContractRangeHOC);
