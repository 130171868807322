import PropTypes from "prop-types";
import React from "react";
import map from "lodash/map";
import pure from "recompose/pure";
import styled from "styled-components";
import GameCard from "../GameCard";

import { device } from "../../utils/mediaQueries";

import LoadingIndicator from "../LoadingIndicator";

import MillonFooter from "./includes/MillonFooter";

const Root = pure(styled.div`
  display: flex;
  padding: 0 29px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;

  @media ${device.mobileL} {
    justify-content: space-around;
    padding: 0;
  }
`);

const CardRoot = pure(styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;

  @media ${device.mobileL} {
    justify-content: space-around;
    padding: 0;
  }
`);

class GamesPageContent extends React.PureComponent {
  render() {
    const { pending, myGamesData, shopGamesData, haveOwnGames } = this.props;

    if (pending) {
      return (
        <Root>
          <LoadingIndicator />
        </Root>
      );
    }

    return (
      <Root>
        <h3>Мои игры</h3>
        {!haveOwnGames && (
          <div>
            <i>Здесь будут показаны ваши игры.</i>
          </div>
        )}
        {haveOwnGames && (
          <>
            <CardRoot>
              {map(myGamesData, myGameData => (
                <GameCard
                  gameId={myGameData.gameId}
                  name={myGameData.name}
                  description={myGameData.description}
                  betaAccess={myGameData.betaAccess}
                  ownedGame={myGameData.ownedGame}
                  key={myGameData.gameId}
                  image={myGameData.image}
                  url={myGameData.url}
                >
                  <MillonFooter
                    gameAccessExpired={myGameData.gameAccessExpired}
                    maxSumma={myGameData.maxSumma}
                    dealMaxSum={myGameData.dealMaxSum}
                    openedCardsPercent={myGameData.cardsOpenedPercent}
                  />
                </GameCard>
              ))}
              <a
                href="https://ags-17.vercel.app/"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                <img
                  src={"/AGS-17.png"}
                  width={285}
                  height={150}
                  style={{
                    position: "absolute",
                    zIndex: 10000,
                    margin: "0 8px",
                    objectFit: "cover"
                  }}
                />

                <GameCard
                  gameId={"0"}
                  name={"АГС-17 тренажер"}
                  description={""}
                  betaAccess={""}
                  ownedGame={true}
                  key={0}
                  url={""}
                ></GameCard>
              </a>
              <a
                href="https://ak-47-assembler.vercel.app/"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                <img
                  src={"/AK47.jpg"}
                  width={285}
                  height={150}
                  style={{
                    position: "absolute",
                    zIndex: 10000,
                    margin: "0 8px",
                    objectFit: "cover"
                  }}
                />

                <GameCard
                  gameId={"0"}
                  name={"Сборка - разборка АК-47"}
                  description={""}
                  betaAccess={""}
                  ownedGame={true}
                  key={0}
                  url={""}
                ></GameCard>
              </a>
            </CardRoot>
          </>
        )}
        <h3>Магазин</h3>

        <CardRoot>
          {shopGamesData.length > 0 && (
            <>
              {map(shopGamesData, shopGameData => (
                <GameCard
                  betaAccess={shopGameData.betaAccess}
                  ownedGame={shopGameData.ownedGame}
                  serie={shopGameData.serie}
                  inDevelop={shopGameData.inDevelop}
                  key={shopGameData.gameId}
                  gameId={shopGameData.gameId}
                  name={shopGameData.name}
                  image={shopGameData.image}
                  description={shopGameData.description}
                  url={shopGameData.url}
                >
                  <MillonFooter
                    maxSumma={shopGameData.maxSumma}
                    dealMaxSum={shopGameData.dealMaxSum}
                    openedCardsPercent={shopGameData.cardsOpenedPercent}
                  />
                </GameCard>
              ))}
            </>
          )}
          {!shopGamesData.length && (
            <div>
              <i>Здесь скоро будут новые игры.</i>
            </div>
          )}
        </CardRoot>
      </Root>
    );
  }
}

GamesPageContent.propTypes = {
  pending: PropTypes.bool.isRequired
};

export default GamesPageContent;
