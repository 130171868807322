import each from 'lodash/each';
import get from 'lodash/get';
import keys from 'lodash/keys';
import find from 'lodash/find';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import withProps from 'recompose/withProps';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import { createStructuredSelector } from 'reselect';
import { setActiveGameId } from '../../../redux/modules/activeGame/actions';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { getGameList, getGamesShowcases } from '../../../redux/modules/games/actions';
import { makeSelectGames, makeSelectGamesShowcases } from '../../../redux/modules/games/selectors';
import { setPrevRating } from '../../../redux/modules/prevCompanyGameRating/actions';
import { makeSelectPrevRating } from '../../../redux/modules/prevCompanyGameRating/selectors';
import { makeSelectProfile, makeSelectProfileAcl } from '../../../redux/modules/profile/selectors';
import { getCompanyGameHeadRating, setActiveRatingTab } from '../../../redux/modules/rating/actions';
import {
  makeSelectActiveRatingTab,
  makeSelectCompanyGameHeadRatingForActiveGame,
} from '../../../redux/modules/rating/selectors';

export function mapDispatchToProps(dispatch) {
  return {
    onLoad: ({ gameId }) => dispatch(getCompanyGameHeadRating({ gameId })),
    setPrevRating: payload => dispatch(setPrevRating(payload)),
    getGames: () => dispatch(getGameList()),
    getGamesShowcases: () => dispatch(getGamesShowcases()),
    setActiveRatingTab: ({ activeRatingTab }) => dispatch(setActiveRatingTab({ activeRatingTab })),
    setActiveGameId: gameId => dispatch(setActiveGameId(gameId)),
  };
}

const mapStateToProps = createStructuredSelector({
  prevRating: makeSelectPrevRating(),
  profile: makeSelectProfile(),
  acl: makeSelectProfileAcl(),
  browser: makeSelectBrowser(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
  activeTab: makeSelectActiveRatingTab(),
});

const mapStateToProps2 = createStructuredSelector({
  companyHeadRatingForActiveGame: makeSelectCompanyGameHeadRatingForActiveGame(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withConnect2 = connect(mapStateToProps2);

const withLifecycle = lifecycle({
  componentDidMount() {
    if (!this.props.allGames) {
      this.props.getGames();
      this.props.getGamesShowcases();
    }

    if (this.props.allGames && this.props.activeTab) {
      this.props.onLoad({ gameId: this.props.activeTab });
    }
  },
  componentDidUpdate(prevProps) {
    if (this.props.activeTab && this.props.activeTab !== prevProps.activeTab) {
      this.props.onLoad({ gameId: this.props.activeTab });
    }
  },
});

const withAllGamesProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const withActiveGameProps = withProps((props) => {
  const { games } = props;

  const gameIdForTabs = {};

  each(games, (item) => {
    const viewNameHeader = get(item, 'OptionsValues.headerCompanyViewName');
    const viewNameEmployee = get(item, 'OptionsValues.employeeRatingViewName');
    const viewNameCompany = get(item, 'OptionsValues.companyRatingViewName');

    const hasRating = viewNameHeader && viewNameEmployee && viewNameCompany;

    if (hasRating) {
      gameIdForTabs[item.GameID] = {
        id: item.GameID,
        name: item.Name,
        hasRating,
      };
    }
  });

  let activeGameId = null;
  if (keys(gameIdForTabs).length) {
    activeGameId = get(gameIdForTabs[props.activeTab], 'id');

    if (!activeGameId) {
      const firstGameInListKey = keys(gameIdForTabs)[0];
      if (firstGameInListKey) {
        activeGameId = gameIdForTabs[firstGameInListKey].id;
      }
    }

    if (activeGameId) {
      props.setActiveRatingTab({
        activeRatingTab: activeGameId,
      });
    }
  }

  return {
    activeGameId,
    gameIdForTabs,
    ...props,
  };
});

const withOwnProps = withProps((props) => {
  const { allGames } = props;

  const { activeGameId } = props;

  const { data, pending, fail, fields } = props.companyHeadRatingForActiveGame;

  const currentGameInfo = find(allGames, { GameID: activeGameId });

  return {
    currentGameInfo,
    ratingForAllGame: data,
    fields,
    isLoadingRating: pending || !allGames || !activeGameId,
    gameId: activeGameId,
    fail,
  };
});

const withOnTabChangeHandler = withHandlers({
  toggleActiveTab: props => (event) => {
    props.setActiveRatingTab({
      activeRatingTab: event.target.id,
    });
  },
});

const enhance = compose(
  pure,
  withConnect,
  withAllGamesProps,
  withActiveGameProps,
  withConnect2,
  withOwnProps,
  withLifecycle,
  withOnTabChangeHandler,
);

export default enhance;
