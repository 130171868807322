import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import Typograf from 'typograf';
import styled from 'styled-components';

import CompanyDirectorRating from '../../../components/CompanyDirectorRating';
import CompanySimpleRating from '../../../components/CompanySimpleRating';

import GameRating from '../../../components/GameRating';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Section from '../../../components/Section';
import SimplePageBase from '../../PageBase/SimplePageBase';

import TabsContent from '../RatingAllPage/includes/TabsContent';
import Tabs from '../../../components/Tabs';
import RatingContent from '../../../components/RatingContent';
import TabItem from '../../../components/Tabs/Tab';
import TabContent from '../RatingAllPage/includes/TabContent';
import TabBody from '../RatingAllPage/includes/TabBody';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

const NoRatingData = styled.div`
    padding: 40px;
    font-size: 16px;
    text-align: center;
    width: 100%;
`;

const CustomTabBody = styled(TabBody)`
  padding: 0;
`;

const RatingCompanyPage = (props) => {
  let ratingHeader = <LoadingIndicator />;
  const { prevRating } = props;

  const small = props.browser.is.extraSmall || props.browser.is.small;

  let currentGamePrevRating = null;
  if (
    prevRating
    && prevRating.gameId === props.gameId
    && prevRating.employeeId === props.profile.employeeID
  ) {
    currentGamePrevRating = prevRating;
  }

  if (!props.isLoadingRating && props.gameId && props.profile.employeeID) {
    ratingHeader = (
      <GameRating
        setPrevRating={props.setPrevRating}
        prevRating={currentGamePrevRating}
        small={small}
        profile={props.profile}
        gameId={props.gameId}
        gamesList={props.allGames}
        data={props.ratingForAllGame}
        fields={props.fields}
      />
    );
  }

  const { acl: { canSeeCompanyExtendedRating, canSeeCompanyRating }, gameId } = props;

  let rating = <div />;
  if (gameId) {
    if (canSeeCompanyExtendedRating) {
      rating = <CompanyDirectorRating />;
    } else if (canSeeCompanyRating) {
      rating = <CompanySimpleRating />;
    }
  }

  return (
    <SimplePageBase title="Рейтинг в компании">
      <Section>
        <Tabs sticky={60}>
          {map(props.gameIdForTabs, item => (
            <TabItem
              key={item.id}
              id={item.id}
              active={props.activeTab === item.id}
              onClick={props.toggleActiveTab}
            >
              {tp.execute(item.name)}
            </TabItem>
          ))}
        </Tabs>
        <TabsContent>
          {props.isLoadingRating && (
            <TabContent>
              <TabBody>
                <RatingContent>
                  <LoadingIndicator />
                </RatingContent>
              </TabBody>
            </TabContent>
          )}
          {!props.isLoadingRating && (
            <TabContent>
              <CustomTabBody>
                {props.isLoadingRating && (
                  <RatingContent>
                    <LoadingIndicator />
                  </RatingContent>
                )}
                {!props.fail && !props.isLoadingRating && (
                  <>
                    {ratingHeader}
                    {rating}
                  </>
                )}
                {props.fail && (
                  <RatingContent>
                    <NoRatingData>
                      👻 При получении рейтинга по этой игре произошла ошибка.
                    </NoRatingData>
                  </RatingContent>
                )}
              </CustomTabBody>
            </TabContent>
          )}
        </TabsContent>
      </Section>
    </SimplePageBase>
  );
};

RatingCompanyPage.defaultProps = {
  acl: {
    canSeeCompanyExtendedRating: false,
    canSeeCompanyRating: false,
  },
  ratingForAllGame: null,
  profile: {
    employeeID: null,
  },
  prevRating: null,
};

RatingCompanyPage.propTypes = {
  fail: PropTypes.bool,
  isLoadingRating: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  setPrevRating: PropTypes.func.isRequired,
  prevRating: PropTypes.object,
  browser: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    employeeID: PropTypes.string,
  }),
  gameId: PropTypes.string,
  ratingForAllGame: PropTypes.shape({
    time: PropTypes.number,
    card: PropTypes.number,
    activeSession: PropTypes.number,
    finishedSession: PropTypes.number,
    summa: PropTypes.number,
  }),
  acl: PropTypes.shape({
    canSeeCompanyExtendedRating: PropTypes.bool,
    canSeeCompanyRating: PropTypes.bool,
  }),
};

export default RatingCompanyPage;
