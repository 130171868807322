import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Field,
} from 'formik';
import pure from 'recompose/pure';
import styled from 'styled-components';
import renderCheckBox from '../inputs/renderCheckBox';
import URL_CONSTANTS from '../../urlConstants';
import FormItem from './FormItem';
import renderInput from '../inputs/renderInput';

import FormWrapper from './FormWrapper';
import FormLine from './FormLine';

const StyledLink = pure(styled(Link)`
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  color: #808085;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  margin: 5px auto;
  padding: 0;
  transition: all .3s ease-out;
  text-decoration: none;

  &:hover, &:active {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #1C1C1C;
  }
`);

const RegistrationForm = ({ isSubmitting }) => (
  <FormWrapper>
    <FormLine>
      <FormItem>
        <Field
          name="email"
          type="text"
          required
          placeholder="E-mail"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
    <FormLine>
      <FormItem>
        <Field
          name="password"
          type="password"
          required
          placeholder="Пароль"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
    <FormLine>
      <FormItem>
        <Field
          name="passwordConfirm"
          type="password"
          required
          placeholder="Подтверждение пароля"
          component={renderInput}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
    <FormLine>
      <FormItem>
        <Field
          name="personalProcessingDataAgreement"
          required
          title={(
            <span>
              Я согласен на обработку
              {' '}
              <StyledLink to={URL_CONSTANTS.URLS.PROCESSING_PERSONAL_DATA} target="_blank">
                персональных данных
              </StyledLink>
            </span>
          )}
          component={renderCheckBox}
          disabled={isSubmitting}
        />
      </FormItem>
    </FormLine>
  </FormWrapper>
);

RegistrationForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default RegistrationForm;
