import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link as RoutherLink } from 'react-router-dom';
import pure from 'recompose/pure';
import { createStructuredSelector } from 'reselect';
import SpinnerInButton from '../../../components/SpinnerInButton';

import { validator } from '../../../utils/validation';
import {
  changeLogin, changePassword, changeIsFail, loginRequest,
} from '../../../redux/modules/global/actions';
import {
  makeSelectIsFail, makeSelectLoginErrorCode,
  makeSelectLoginErrorMessage,
  makeSelectLoginPending,
} from '../../../redux/modules/global/selectors';
import NormalInput from '../../../components/Input';
import LogoIcon from '../ResetPage/logo.svg';
import PaykeeperImage from '../../../assets/paykeeper.svg';

import URL_CONSTANTS from '../../../urlConstants';

const Wrapper = pure(styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  margin: 0;
  margin: auto;
  min-height: 100vh;
  padding: 20px 0;
  overflow: hidden;
  width: 100%;
`);

const Form = pure(styled.form`
  width: 300px;
`);

const StyledRoutherLink = pure(styled(RoutherLink)`
  text-align: center;
`);

const Logo = pure(styled.div`
  background-image: url(${LogoIcon});
  background-position: center;
  background-size: 202px 139px;
  height: 139px;
  margin: 0 auto;
  width: 202px;
`);

const FormInner = pure(styled.div`
  margin-top: 35px;
`);

const FormLine = pure(styled.div`
  display: ${props => (props.noDisplay ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}px;
`);

const Link = pure(styled.button`
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  color: #808085;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  margin: 5px auto;
  padding: 0;
  transition: all .3s ease-out;

  &:hover, &:active {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #1C1C1C;
  }
`);

const Button = pure(styled.button`
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 27.5px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  ${props => (!props.disabled && `
    &:hover {
      background-color: ${darken(0.1, '#35BB9A')};
      border-color: ${darken(0.1, '#35BB9A')};
    };
  `)}
  
  ${props => (props.pending && `
    cursor: progress;
  `)}
`);

const Input = pure(styled(NormalInput)`
  border-color: ${props => (props.isValid ? 'rgba(0, 0, 0, .25)' : '#A01836')};
`);

const Error = pure(styled.p`
  color: #A01836;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  min-height: 25px;
  line-height: 15px;
`);

const GlobalStyle = pure(createGlobalStyle`
  #app {
    background-color: #FFFFFF !important;
  }
`);

const PaymentInfoContainer = pure(styled.div`
  @media (max-width: 425px) {
    display: none;
  }
`);

const PaymentLogo = pure(styled.img`
  height: 20px;
`);

class LoginPage extends React.PureComponent {
  state = {
    email: '',
    password: '',
    emailValid: true,
    passwordValid: true,
  };

  handleRedirect = () => {
    this.props.history.push(URL_CONSTANTS.URLS.INDEX_PAGE);
  };

  changeEmail = (event) => {
    this.setState({
      email: event.target.value,
      emailValid: true,
    });

    this.props.changeLogin({
      login: event.target.value,
    });

    if (this.props.isFail === true) {
      this.props.changeIsFail({
        isFail: false,
      });
    }
  };

  changePassword = (event) => {
    this.setState({
      password: event.target.value,
      passwordValid: true,
    });

    this.props.changePassword({
      password: event.target.value,
    });

    if (this.props.isFail === true) {
      this.props.changeIsFail({
        isFail: false,
      });
    }
  };

  handleForm = (event) => {
    event.preventDefault();

    const {
      email,
      password,
    } = this.state;

    let isValid = true;

    if (!validator.email(email.trim())) {
      this.setState({
        emailValid: false,
      });

      this.props.changeLogin({
        login: '',
      });

      isValid = false;
    }

    if (!validator.required(password)) {
      this.setState({
        passwordValid: false,
      });

      this.props.changePassword({
        password: '',
      });

      isValid = false;
    }

    if (isValid) {
      this.props.loginRequest();
    }
  };

  handleRegistration = () => {
    this.props.history.push(URL_CONSTANTS.URLS.REGISTRATION);
  };

  handleReset = () => {
    this.props.history.push(URL_CONSTANTS.URLS.FORGOT_PASSWORD);
  };

  handleLicence = () => {
    this.props.history.push(URL_CONSTANTS.URLS.LICENCE);
  };

  render() {
    const { loadingPending } = this.props;
    const emailError = this.state.emailValid ? '' : 'Убедитесь, что поле заполнено верно';
    let passwordError = this.state.passwordValid ? '' : 'Убедитесь, что поле заполнено верно';

    if (this.props.isFail) {
      passwordError = this.props.loadingErrorMessage;
    }

    return (
      <Wrapper>
        <GlobalStyle />
        <Form onSubmit={this.handleForm}>
          <Logo onClick={this.handleRedirect} />
          <FormInner>
            <FormLine>
              <Input
                disabled={loadingPending}
                isValid={this.state.emailValid}
                onChange={this.changeEmail}
                placeholder="E-mail"
                type="text"
                value={this.state.email}
              />
              <Error>
                {emailError}
              </Error>
            </FormLine>
            <FormLine>
              <Input
                disabled={loadingPending}
                isValid={this.state.passwordValid}
                onChange={this.changePassword}
                placeholder="Пароль"
                type="password"
                value={this.state.password}
              />
              <Error>
                {passwordError}
              </Error>
            </FormLine>
            <FormLine>
              <Link type="button" onClick={this.handleReset}>Забыли пароль?</Link>
            </FormLine>
            <FormLine marginTop={25}>
              <Button
                type="submit"
                disabled={loadingPending}
                pending={loadingPending}
              >
                {!loadingPending && 'Войти'}
                {loadingPending && (
                  <SpinnerInButton />
                )}
              </Button>
            </FormLine>
            <FormLine marginTop={20}>
              <Link type="button" onClick={this.handleRegistration}>Регистрация</Link>
            </FormLine>
            <PaymentInfoContainer>
              <FormLine marginTop={20}>
                <Link
                  type="button"
                  onClick={this.handleLicence}
                >
                  Лицензионное соглашение
                </Link>
              </FormLine>
              <FormLine marginTop={10}>
                <StyledRoutherLink to="/payment-methods">
                  <PaymentLogo src={PaykeeperImage} alt="" />
                </StyledRoutherLink>
              </FormLine>
            </PaymentInfoContainer>
          </FormInner>
        </Form>

      </Wrapper>
    );
  }
}

LoginPage.propTypes = {
  changeIsFail: PropTypes.func,
  changeLogin: PropTypes.func,
  changePassword: PropTypes.func,
  loginRequest: PropTypes.func,
  isFail: PropTypes.bool,
  history: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    changeLogin: login => dispatch(changeLogin(login)),
    changePassword: password => dispatch(changePassword(password)),
    changeIsFail: isFail => dispatch(changeIsFail(isFail)),
    loginRequest: () => dispatch(loginRequest()),
  };
}

const mapStateToProps = createStructuredSelector({
  isFail: makeSelectIsFail(),
  loadingPending: makeSelectLoginPending(),
  loadingErrorMessage: makeSelectLoginErrorMessage(),
  loginErrorCode: makeSelectLoginErrorCode(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(LoginPage);
