import styled from 'styled-components';

const Header = styled.h2`
  color: #FFFFFF;
  font-family: 'Neucha', sans-serif;
  font-size: 35px;
  width: 100%;
  text-align: center;
  padding-right: 15px;
  margin: 4px 0 21px;
`;

export default Header;
