import React from 'react';
import TimeDuration from '../components/TimeDuration';
import * as ReportColumnTypes from '../reportColumnTypes';

const getFormattedFieldData = ({ value, fieldType }) => {
  switch (fieldType) {
    case ReportColumnTypes.INTEGER_COLUMN_TYPE:
      return value.toLocaleString();
    case ReportColumnTypes.FLOAT_COLUMN_TYPE:
      return (Math.trunc(Math.round(value * 100)) / 100)
        .toString()
        .replace('.', ',')
        .replace(',00', '');
    case ReportColumnTypes.TIME_IN_SECONDS_COLUMN_TYPE:
      return <TimeDuration duration={value} hideSeconds />;
    default:
      return value;
  }
};

export default getFormattedFieldData;
