import styled from 'styled-components';
import EditBg from './edit-photo.svg';

const EditWrapper = styled.div`
  background-color: #ffffff;
  background-image: url(${EditBg});
  background-position: center;
  background-size: 15px;
  border: 1px solid #DEDEDE;
  border-radius: 50%;
  bottom: -14px;
  cursor: pointer;
  height: 30px;
  position: absolute;  
  width: 30px;
`;

export default EditWrapper;
