import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';

const LoadingWrapper = styled.div`
  position: relative;
  min-width: 60px;
  display: flex;
  justify-content: center;

  & .spinner {
    width: 22px;
    height: 22px;
  }
`;

const SpinnerInButton = () => (
  <LoadingWrapper>
    <Spinner
      name="circle"
      fadeIn="none"
      className="spinner"
      color="white"
    />
  </LoadingWrapper>
);

export default SpinnerInButton;
