import { fromJS } from 'immutable';

import {
  GET_FINISH_PAGE_REPORT,
  GET_FINISH_PAGE_REPORT_FAIL,
  GET_FINISH_PAGE_REPORT_SUCCESS,
} from './constants';

const initialState = fromJS({
  pending: true,
  fail: false,
  success: false,

  dataTopOne: null,
  fieldsTopOne: null,
  dataTopTwo: null,
  fieldsTopTwo: null,
  dataBottomOne: null,
  fieldsBottomOne: null,
  dataBottomTwo: null,
  fieldsBottomTwo: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FINISH_PAGE_REPORT: {
      return state
        .set('pending', true)
        .set('fail', false)
        .set('success', false)
        .set('dataTopOne', null)
        .set('fieldsTopOne', null)
        .set('dataTopTwo', null)
        .set('fieldsTopTwo', null)
        .set('dataBottomOne', null)
        .set('fieldsBottomOne', null)
        .set('dataBottomTwo', null)
        .set('fieldsBottomTwo', null);
    }
    case GET_FINISH_PAGE_REPORT_SUCCESS: {
      const {
        dataTopOne,
        fieldsTopOne,
        dataTopTwo,
        fieldsTopTwo,
        dataBottomOne,
        fieldsBottomOne,
        dataBottomTwo,
        fieldsBottomTwo,
      } = action.payload;

      return state
        .set('pending', false)
        .set('fail', false)
        .set('success', true)
        .set('dataTopOne', dataTopOne)
        .set('fieldsTopOne', fieldsTopOne)
        .set('dataTopTwo', dataTopTwo)
        .set('fieldsTopTwo', fieldsTopTwo)
        .set('dataBottomOne', dataBottomOne)
        .set('fieldsBottomOne', fieldsBottomOne)
        .set('dataBottomTwo', dataBottomTwo)
        .set('fieldsBottomTwo', fieldsBottomTwo);
    }
    case GET_FINISH_PAGE_REPORT_FAIL: {
      return state
        .set('pending', false)
        .set('fail', true)
        .set('success', false)
        .set('dataTopOne', null)
        .set('fieldsTopOne', null)
        .set('dataTopTwo', null)
        .set('fieldsTopTwo', null)
        .set('dataBottomOne', null)
        .set('fieldsBottomOne', null)
        .set('dataBottomTwo', null)
        .set('fieldsBottomTwo', null);
    }
    default:
      return state;
  }
}

export default reducer;
