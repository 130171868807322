import { createSelector } from 'reselect';

const form = state => state.get('inviteSetPasswordForm');

const makeSelectIsSubmitting = () => createSelector(
  form,
  state => state.get('isSubmitting'),
);

const makeSelectToken = () => createSelector(
  form,
  state => state.get('token'),
);

const makeSelectPassword = () => createSelector(
  form,
  state => state.get('password'),
);

const makeSelectIsSucceeded = () => createSelector(
  form,
  state => state.get('isSucceeded'),
);

const makeSelectIsFail = () => createSelector(
  form,
  state => state.get('isFail'),
);

const makeSelectIsEmployee = () => createSelector(
  form,
  state => state.get('isEmployee'),
);

const makeSelectIsTokenValid = () => createSelector(
  form,
  state => state.get('isValidToken'),
);

const makeSelectIsTokenValidated = () => createSelector(
  form,
  state => state.get('isTokenValidated'),
);

export {
  makeSelectIsSubmitting,
  makeSelectToken,
  makeSelectPassword,
  makeSelectIsSucceeded,
  makeSelectIsFail,
  makeSelectIsEmployee,
  makeSelectIsTokenValid,
  makeSelectIsTokenValidated,
};
