// Настроение
export const OPTION_GAME_MY_MOOD = 'myMoodVar';
export const OPTION_GAME_MY_MOOD_ARROW = 'myMoodVarArrow';
export const OPTION_GAME_CLIENT_MOOD = 'clientMoodVar';
export const OPTION_GAME_CLIENT_MOOD_ARROW = 'clientMoodVarArrow';

// Корридор сделки
export const OPTION_GAME_DEAL_CORRIDOR_FROM = 'dealCorridorFrom';
export const OPTION_GAME_DEAL_CORRIDOR_TO = 'dealCorridorTo';

export const OPTION_GAME_SUM = 'sum';
export const OPTION_GAME_BOOSTER_BET = 'boosterBet'; // MVK_Booster_stavka

export const OPTION_GAME_FINISH_SUCCESS = 'finishSuccess'; // MVK_uspesho
export const OPTION_GAME_RECOMMENDATION = 'recommendation'; // MVK_рекомендация
export const OPTION_GAME_PASS_PERCENT = 'passPercent'; // MVK_procent_prohojdeniya

export const OPTION_GAME_BOOSTER_COMPANY_ENABLED = 'boosterCompanyValue'; // MVK_Booster_ot_company

export const OPTION_GAME_STATUS_HEADER = 'statusHeader';
export const OPTION_GAME_STATUS_TEXT = 'statusText';
export const OPTION_GAME_PERCENT_FROM_DEAL = 'percentFromDeal';
export const OPTION_GAME_PAID = 'paid';
export const OPTION_GAME_SUPER_GAME = 'superGame';
