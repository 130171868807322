import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectStartedTrialGameSessionId = () => createSelector(
  storeSlice,
  state => state.get('startedTrialGameSessionId'),
);

export {
  makeSelectStartedTrialGameSessionId,
};
