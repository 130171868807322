import { combineReducers } from 'redux-immutable';

import { loadingBarReducer } from 'react-redux-loading-bar';
import { responsiveStateReducer } from 'redux-responsive';
import { connectRouter } from 'connected-react-router/immutable';

import activeGame from './activeGame/reducer';
import games from './games/reducer';
import error from './error/reducer';
import global from './global/reducer';
import markCardAsViewed from './markCardAsViewed/reducer';
import prevCompanyGameRating from './prevCompanyGameRating/reducer';
import profile from './profile/reducer';
import feedBackForm from './feedBackForm/reducer';
import myGame from './myGame/reducer';
import employees from './employees/reducer';
import allRating from './allRating/reducer';
import owl from './owl/reducer';
import employeeSetUserEmailForm from './forms/employeeSetUserEmailForm/reducer';
import addEmployeeForm from './forms/addEmployeeForm/reducer';
import inviteSetPasswordForm from './forms/inviteSetPasswordForm/reducer';
import setPasswordForm from './forms/setPasswordForm/reducer';
import tutorial from './tutorial/reducer';
import registrationForm from './forms/registrationForm/reducer';
import guestGameBuy from './guestGameBuy/reducer';
import spotlight from './spotlight/reducer';
import popups from './popups/reducer';
import buyGame from './buyGame/reducer';
import gamesOptions from './gamesOptions/reducer';
import library from './library/reducer';
import trialGame from './trialGame/reducer';
import video from './video/reducer';
import debug from './debug/reducer';
import rating from './rating/reducer';
import finishPage from './finishPage/reducer';

export default history => combineReducers({
  // shit reducers
  trialGame,
  tutorial,
  library,
  gamesOptions,
  buyGame,
  allRating,
  error,
  employees,
  feedBackForm,
  games,
  global,
  markCardAsViewed,
  myGame,
  owl,
  prevCompanyGameRating,
  profile,
  employeeSetUserEmailForm,
  registrationForm,
  inviteSetPasswordForm,
  setPasswordForm,
  addEmployeeForm,
  guestGameBuy,

  // nice reducers
  popups,
  spotlight,
  activeGame,
  rating,
  debug,
  video,
  finishPage,
  router: connectRouter(history),
  loadingBar: loadingBarReducer,
  browser: responsiveStateReducer,
});
