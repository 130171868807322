import { createBrowserHistory, createMemoryHistory } from 'history';
import Immutable from 'immutable';
import isServer from '../utils/isServer';
import createStore from './store';

export default (url = '/', cookies) => {
  const history = isServer()
    ? createMemoryHistory({
      initialEntries: [url],
    })
    : createBrowserHistory();

  let initialState;

  if (!isServer() && typeof window.__PRELOADED_STATE__ !== 'undefined') {
    initialState = Immutable.fromJS(window.__PRELOADED_STATE__);
  } else {
    initialState = Immutable.Map();
  }

  // Delete it once we have it stored in a variable
  if (!isServer() && typeof window.__PRELOADED_STATE__ !== 'undefined') {
    delete window.__PRELOADED_STATE__;
  }

  return createStore(initialState, history, cookies);
};
