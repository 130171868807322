import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
  ${props => (props.justify && `
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `)}
`;

export default TitleWrapper;
