import { call, takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import { trackDemoComebackFormSubmit } from '../../modules/analytics/actions';
import { GET_BOOK_FRAGMENT } from '../../modules/getBookFragment/constants';
import { setThxPopupOpened, setBeforeDemoLeavePopupOpened } from '../../modules/popups/actions';

function* getBookFragmentSaga(payload) {
  const { email, formik: { setSubmitting } } = payload;

  try {
    yield put(trackDemoComebackFormSubmit(email));
    setSubmitting(true);
    const source = window.location.href;
    // TODO: HARDCODE_TYPE_2 Зашит домен и адрес в целом.
    const requestUrl = `https://readom.pro/send/?email=${email}&source=${encodeURIComponent(source)}`;
    yield call(axios, requestUrl);
    yield put(setBeforeDemoLeavePopupOpened(false));
    yield put(setThxPopupOpened(true));
  } catch (err) {
    setSubmitting(false);
    console.error('Error while send book fragment');
  } finally {
    setSubmitting(false);
  }
}

function* sagas() {
  yield takeEvery(GET_BOOK_FRAGMENT, getBookFragmentSaga);
}

export default sagas;
