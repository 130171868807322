import React from 'react';
import Error from '../../components/Error';

const renderErrorMessage = message => (
  <Error>
    {message}
  </Error>
);

export default renderErrorMessage;
