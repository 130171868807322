import styled from 'styled-components';

const HeaderContent = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 650px;
`;

export default HeaderContent;
