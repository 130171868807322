import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';
import pure from 'recompose/pure';
import { Formik } from 'formik';
import Error from '../../../components/Error';
import Success from '../../../components/RoundMark/Success';
import SpinnerInButton from '../../../components/SpinnerInButton';

import EmployeeFormValidateSchema from '../../../FormikForms/RegistrationForm/RegistrationFormValidateSchema';
import RegistrationForm from '../../../FormikForms/RegistrationForm';

import URL_CONSTANTS from '../../../urlConstants';
import LogoIcon from './logo.svg';

const Link = pure(styled.button`
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  color: #808085;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  margin: 5px auto;
  padding: 0;
  transition: all .3s ease-out;

  &:hover, &:active {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #1C1C1C;
  }
`);

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  margin: 0;
  margin: auto;
  min-height: 100vh;
  padding: 20px 0;
  overflow: hidden;
  width: 100%;
`;

const Logo = styled.div`
  background-image: url(${LogoIcon});
  background-position: center;
  background-size: 202px 139px;
  cursor: pointer;
  height: 139px;
  margin: 0 auto;
  width: 202px;
`;

const FormLine = styled.div`
  display: ${props => (props.noDisplay ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 0;
  width: 300px;
  justify-content: center;

  &:last-child {
    margin-top: 25px;
  }
`;

const Button = styled.button`
  background-color: #35BB9A;
  border: 1px solid #35BB9A;
  border-radius: 27.5px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 0 1px rgba(0, 0, 0, .05), 0 4px 6px 0 rgba(0, 0, 0, .1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
  min-width: 180px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s ease-out;
  user-select: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:hover {
    background-color: ${darken(0.1, '#35BB9A')};
    border-color: ${darken(0.1, '#35BB9A')};
  }
`;

const Title = styled.div`
  color: #1B1B1B;
  font-size: 30px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 46px;
  text-align: center;
  
  @media (max-width: 400px) {
    margin-top: 20px;
    font-size: 23px;
    padding: 0 10px;
  }
`;

const GlobalStyle = createGlobalStyle`
  #app {
    background-color: #FFFFFF !important;
  }
`;

const ButtonWrapper = pure(styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`);

const SuccessWrapper = pure(styled.div`
  display: flex;
  justify-content: center;
`);

const Message = pure(styled.div`
  max-width: 400px;
`);

const Root = pure(styled.div`
  max-width: 300px;
`);

const initialValues = {
  email: '',
  password: '',
  passwordConfirm: '',
  personalProcessingDataAgreement: false,
};

class RegistrationPage extends React.PureComponent {
  handleRedirect = () => {
    this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
  };

  backToLoginPage = () => {
    this.props.history.push(URL_CONSTANTS.URLS.LOGIN);
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Wrapper>
        <GlobalStyle />
        <div>
          <Logo onClick={this.handleRedirect} />
          <Formik
            initialValues={initialValues}
            validationSchema={EmployeeFormValidateSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const submitSucceeded = props.status && props.status.submitSucceeded;

              const toLogin = () => {
                props.resetForm();
                this.backToLoginPage();
              };

              return (
                <>
                  {submitSucceeded && (
                    <>
                      <Title>
                        Аккаунт создан
                      </Title>
                      <SuccessWrapper>
                        <Success />
                      </SuccessWrapper>
                      <Message>
                        Мы&nbsp;выслали письмо на&nbsp;e-mail
                        {' '}
                        <b>{props.values.email}</b>,
                        {' '}
                        перейдите по&nbsp;ссылке из&nbsp;письма для&nbsp;активации аккаунта.
                      </Message>
                      <ButtonWrapper>
                        <Button
                          isAlternative
                          onClick={toLogin}
                        >
                          Понятно
                        </Button>
                      </ButtonWrapper>
                    </>
                  )}

                  {!submitSucceeded && (
                    <>
                      <Title>
                        Регистрация
                      </Title>
                      <Root>
                        {props.status && props.status.globalError && (
                          <Error>{props.status.globalError}</Error>
                        )}
                        <RegistrationForm
                          {...props}
                        />
                        <ButtonWrapper>
                          <Button
                            type="submit"
                            onClick={props.handleSubmit}
                            disabled={props.isSubmitting}
                          >
                            {!props.isSubmitting && 'Создать аккаунт'}
                            {props.isSubmitting && (
                              <SpinnerInButton />
                            )}
                          </Button>
                        </ButtonWrapper>
                        <FormLine>
                          <Link type="button" onClick={this.backToLoginPage}>вернуться к форме входа</Link>
                        </FormLine>
                      </Root>
                    </>
                  )}
                </>
              );
            }}
          </Formik>
        </div>
      </Wrapper>
    );
  }
}

export default RegistrationPage;
