import styled from 'styled-components';

const Question = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 16px;
  line-height: 23px;
  margin: 0 auto 30px;
  max-width: 650px;
  user-select: none;

  & p {
    margin-top: 9px;
  }
`;

export default Question;
