import React from 'react';
import isNull from 'lodash/isNull';
import { useMedia } from 'the-platform';

import OwlContainer from '../../containers/other/OwlContainer/index';
import getOwlContentType from '../../containers/other/OwlContainer/getOwlContentType';
import { STEP_CONTENT } from '../../containers/other/OwlContainer/constats';
import { device } from '../../utils/mediaQueries';

const Owl = ({ image, showButtonWrapper, text }) => {
  const small = useMedia(device.tablet);
  const contentType = getOwlContentType(text);

  const conditions = isNull(image) // не показываем когда шаг - картинка
    && !(small && showButtonWrapper && contentType !== STEP_CONTENT); // Если открыто меню с подсказками на мобиле, то сову скрыть, за исключением тутора STEP_CONTENT

  if (conditions) {
    return (
      <OwlContainer
        small={small}
      />
    );
  }

  return null;
};

export default Owl;
