import uuid from 'uuid/v4';

const getDeviceId = (cookies) => {
  const deviceIdInCookie = cookies.get('deviceId');
  if (deviceIdInCookie) {
    return deviceIdInCookie;
  }

  const deviceId = uuid();

  const cookieMaxAge = new Date().getTime() + (60 * 60 * 24 * 365 * 10);
  cookies.set('deviceId', deviceId, { maxAge: cookieMaxAge, path: '/' });

  return deviceId;
};

export default getDeviceId;
