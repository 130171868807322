import styled from 'styled-components';

const CardLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  padding: 0 5px;

  &:nth-child(even) {
    background-color: #EBEBED;
  }
`;

export default CardLine;
