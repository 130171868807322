import styled from 'styled-components';

const Tip = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-size: ${props => (props.backgroundSize ? props.backgroundSize : 'contain')};
  border-radius: 50%;
  cursor: pointer;
  height: 60px;
  ${props => (props.small ? 'margin: 0;' : 'margin: 10px 0;')};
  position: relative;
  width: 60px;
  pointer-events: auto;
`;

export default Tip;
