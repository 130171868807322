import styled from 'styled-components';

const PopupWrapper = styled.div`
  background-color: rgba(255, 255, 255, .98);
  display: ${props => (props.isActive ? 'flex' : 'none')};
  left: 0;
  opacity: ${props => (props.isActive ? '1' : '0')};
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity .3s ease-out;
  width: 100vw;
  height: 100vh;
  z-index: ${props => (props.isActive ? '1000' : '-1')};;
`;

export default PopupWrapper;
