import styled from 'styled-components';

const TabBody = styled.div`
  background-color: #EBEBED;
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: max-content;
  justify-content: flex-start;
  margin: 0;
  padding: 0 15px;
  width: 100%;
`;

export default TabBody;
