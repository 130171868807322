import {
  call, put, select, takeLeading, takeLatest,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import get from 'lodash/get';
import URL_CONSTANTS from '../../../urlConstants';
import { showSpinner, hideSpinner } from '../../modules/global/actions';
import { makeSelectApiUrl, makeSelectLocation } from '../../modules/global/selectors';

import { makeSelectActiveGameId } from '../../modules/activeGame/selectors';

import {
  continueGameFromSaveSuccess,
  continueGameFromSaveFail,
  setUserEmailRequestSuccess,
  setUserEmailRequestFail,
  resetMyGameState,
  nextStep,
} from '../../modules/myGame/actions';
import {
  CONTINUE_GAME_FROM_SAVE_REQUEST,
  CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL,
  SET_USER_EMAIL_REQUEST,
} from '../../modules/myGame/constants';
import {
  makeSelectSaveTip,
  makeSelectSetUserEmail,
} from '../../modules/myGame/selectors';

import { apiRequest as createApiRequest } from '../../../utils/request';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* continueGameFromSaveSaga() {
    const url = yield select(makeSelectApiUrl());
    const gameId = yield select(makeSelectActiveGameId());
    const saveTip = yield select(makeSelectSaveTip());
    const requestURL = `${url}/GameSnapshotContinue/${encodeURIComponent(gameId)}/${encodeURIComponent(saveTip.lastSaveId)}`;

    try {
      yield put(showLoading());
      yield put(showSpinner());

      const request = yield call(apiRequest, requestURL);
      const isSuccess = get(request, 'data.result[0].Result', null) === 'success';

      if (!isSuccess) {
        yield put(continueGameFromSaveFail());
        yield put(hideSpinner());

        return;
      }

      const sessionId = request.data.result[0].SessionID;
      yield put(continueGameFromSaveSuccess(sessionId));
      yield put(nextStep());
    } catch (err) {
      yield put(continueGameFromSaveFail());
      yield put(hideSpinner());
    } finally {
      yield put(hideSpinner());
      yield put(hideLoading());
    }
  }

  function* setUserEmailSaga() {
    const url = yield select(makeSelectApiUrl());
    const emailRaw = yield select(makeSelectSetUserEmail());
    const email = emailRaw.trim().toLowerCase();
    const requestURL = `${url}/EmployeeRequestSetUserEmail/${encodeURIComponent(email)}/`;

    try {
      const request = yield call(apiRequest, requestURL);
      const isSuccess = get(request, 'data.result[0].Result', null) === 'success';
      if (!isSuccess) {
        throw new Error('Error while employee set user E-mail');
      }
      yield put(setUserEmailRequestSuccess());
    } catch (err) {
      yield put(setUserEmailRequestFail());
    }
  }

  function* changeLocation() {
    const location = yield select(makeSelectLocation());
    if (
      location.pathname.indexOf(URL_CONSTANTS.PREFIXES.GAME_OVERVIEW) !== -1
      || location.pathname === URL_CONSTANTS.URLS.PROFILE
      || location.pathname === URL_CONSTANTS.URLS.GAMES_STORE
      || location.pathname === URL_CONSTANTS.URLS.GET_FULL_ACCESS
      || location.pathname === URL_CONSTANTS.URLS.DEMO_FINISH
    ) {
      console.info('Reset game state');
      yield put(resetMyGameState());
    }
  }

  function* cantLoadGameSaga() {
    yield put(push(URL_CONSTANTS.URLS.GAMES_STORE));
  }

  function* myGameSaga() {
    yield takeLatest(LOCATION_CHANGE, changeLocation);
    yield takeLeading(CONTINUE_GAME_FROM_SAVE_REQUEST, continueGameFromSaveSaga);
    yield takeLeading(CONTINUE_GAME_FROM_SAVE_REQUEST_FAIL, cantLoadGameSaga);
    yield takeLeading(SET_USER_EMAIL_REQUEST, setUserEmailSaga);
  }

  return myGameSaga;
}

export default build;
