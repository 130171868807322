import '@babel/polyfill';
import 'url-search-params-polyfill';
import 'intersection-observer';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Loadable from 'react-loadable';
import each from 'lodash/each';
import { CookiesProvider } from 'react-cookie';
import Cookies from 'universal-cookie';

import { calculateResponsiveState } from 'redux-responsive';

import configureStore from './redux/configureStore';
import App from './containers/App';
import { setAnalyticParamForBackend } from './redux/modules/global/actions';
import sagas from './redux/sagas';
import Amplitude from './utils/Aplitude/amplidude';
import getUTMQueryParams from './utils/getUTMQueryParams';
import initLogRocket from './utils/initLogRocket';

import initSentryListener from './utils/initSentryListener';
import isServer from './utils/isServer';
import ScrollToTop from './utils/ScrollToTop';
import vhCSSCustomProperty from './utils/vhCSSCustomProperty';

import './global.css';

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     include: [
//       /^PromoPageV3/,
//       // /^GamesPage/,
//       // /^Header/,
//     ],
//   });
//   // whyDidYouRender(React, { include: [/.+/] });
//   // whyDidYouRender(React);
// }

const cookies = new Cookies();

if (!isServer()) {
  initLogRocket();
  initSentryListener();
  vhCSSCustomProperty();

  const utmParams = getUTMQueryParams(document.location.search);
  let about = null;
  if (utmParams && utmParams.content) {
    const tmp = utmParams.content.split('|');
    if (tmp && tmp.length > 0) {
      each(tmp, (item, index) => {
        if (item.toLowerCase() === 'about') {
          if (tmp[index + 1]) {
            about = tmp[index + 1];
          }
        }
      });
    }
  }

  const maxAge = 60 * 60 * 24 * 365;

  if (about) {
    cookies.set('about', about, { maxAge, path: '/' });
  } else {
    const currentAbout = cookies.get('about');
    if (!currentAbout) {
      // 1 год
      cookies.set('about', 'book', { maxAge, path: '/' });
    }
  }
}

const { store, history } = configureStore(null, cookies);
const ampInstance = new Amplitude(cookies);
store.runSaga(sagas({ cookies, amplitude: ampInstance }));

const Application = (
  <CookiesProvider cookies={cookies}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>
);

const MOUNT_NODE = document.getElementById('app');

if (MOUNT_NODE.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, MOUNT_NODE);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, MOUNT_NODE);
}

store.dispatch(calculateResponsiveState(window));

const paramsForBackendAnalytic = ampInstance.getParamsForBackend();

store.dispatch(setAnalyticParamForBackend(paramsForBackendAnalytic));

// if (!isServer() && process.env.NODE_ENV === 'production') {
//   if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//       navigator.serviceWorker.register('/service-worker.js');
//     });
//   }
// }
//
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.getRegistrations().then(
//       registrations => {
//         for (let registration of registrations) {
//           registration.unregister();
//         }
//       });
//   });
// }
