import { createSelector } from 'reselect';

const form = state => state.get('registrationForm');

const makeSelectRegistrationError = () => createSelector(
  form,
  state => state.get('error'),
);

const makeSelectRegistrationPending = () => createSelector(
  form,
  state => state.get('pending'),
);

export {
  makeSelectRegistrationError,
  makeSelectRegistrationPending,
};
