import { fromJS } from 'immutable';
import {
  SET_ACTIVE_GAME_ID,
} from './constants';

const initialState = fromJS({
  gameId: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_GAME_ID:
      return state
        .set('gameId', action.gameId);
    default:
      return state;
  }
}

export default reducer;
