import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 35px;
  margin-right: 35px;
  width: 60px;
`;

const FiftyFiftyIcon = () => (
  <IconWrapper>
    <svg width="58px" height="20px" viewBox="0 0 58 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="popup1-copy" transform="translate(-454.000000, -264.000000)" fill="#1C1C1C">
          <path d="M454.8,281.82 C455.18,281.84 455.6,281.86 455.96,281.86 C458.76,281.86 460.92,280 460.92,277.62 C460.92,275.52 459.82,274.8 458.12,274.8 C457.2,274.8 456.04,275.08 455.36,275.4 L454.7,274.94 L455.66,267.56 L461.84,267.56 L462.04,267.18 L463.42,267.18 L463,269.68 L457.52,269.68 L456.7,273.84 C457.28,273.54 458.44,273.08 459.82,273.08 C462.54,273.08 464.04,274.56 464.04,277.14 C464.04,280.64 460.76,283.22 456.2,283.22 C455.76,283.22 455.26,283.18 454.8,283.08 L454.8,281.82 Z M471.58,280.2 C468.12,280.2 466.36,277.66 466.36,273.84 C466.38,269.54 469.02,267.4 472.08,267.4 C475.58,267.4 477.26,269.76 477.26,273.64 C477.24,277.92 474.7,280.22 471.58,280.2 Z M471.82,279 C473.52,279 474.26,277.22 474.26,273.86 C474.26,270.32 473.64,268.6 471.84,268.6 C470.16,268.6 469.36,270.32 469.36,273.62 C469.36,277.1 470.06,279 471.82,279 Z M486.32,264.38 L481.08,283.54 L479.06,283.54 L484.3,264.38 L486.32,264.38 Z M488.8,281.82 C489.18,281.84 489.6,281.86 489.96,281.86 C492.76,281.86 494.92,280 494.92,277.62 C494.92,275.52 493.82,274.8 492.12,274.8 C491.2,274.8 490.04,275.08 489.36,275.4 L488.7,274.94 L489.66,267.56 L495.84,267.56 L496.04,267.18 L497.42,267.18 L497,269.68 L491.52,269.68 L490.7,273.84 C491.28,273.54 492.44,273.08 493.82,273.08 C496.54,273.08 498.04,274.56 498.04,277.14 C498.04,280.64 494.76,283.22 490.2,283.22 C489.76,283.22 489.26,283.18 488.8,283.08 L488.8,281.82 Z M505.58,280.2 C502.12,280.2 500.36,277.66 500.36,273.84 C500.38,269.54 503.02,267.4 506.08,267.4 C509.58,267.4 511.26,269.76 511.26,273.64 C511.24,277.92 508.7,280.22 505.58,280.2 Z M505.82,279 C507.52,279 508.26,277.22 508.26,273.86 C508.26,270.32 507.64,268.6 505.84,268.6 C504.16,268.6 503.36,270.32 503.36,273.62 C503.36,277.1 504.06,279 505.82,279 Z" id="50/50" />
        </g>
      </g>
    </svg>
  </IconWrapper>
);

export default FiftyFiftyIcon;
