import React from 'react';
import BuyGameGuestPopup from '../../components/BuyGameGuestPopup';
// import BuyGamePopup from '../../components/BuyGamePopup';
// import BuyGameStages36Popup from '../../components/BuyGameStages36Popup';
import StartTrialGameWithPhonePopup from '../../components/StartTrialGameWithPhonePopup';
import StartTrialGameWithEmailPopup from '../../components/StartTrialGameWithEmailPopup';
import BeforeDemoLeavePopup from '../../components/BeforeDemoLeavePopup';
import THXPopup from '../../components/THXPopup';
import Modal from '../Modal';

const Modals = () => (
  <Modal>
    {/*<BuyGamePopup />*/}
    {/*<BuyGameStages36Popup />*/}
    <BuyGameGuestPopup />
    <StartTrialGameWithEmailPopup />
    <StartTrialGameWithPhonePopup />
    <BeforeDemoLeavePopup />
    <THXPopup />
  </Modal>
);

export default Modals;
