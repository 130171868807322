import { fromJS } from 'immutable';
import {
  REFRESH_SLIDE_CONTENT,
  REFRESH_SLIDE_CONTENT_FAIL,
  REFRESH_SLIDE_CONTENT_SUCCESS,
} from './constants';

const initialState = fromJS({
  pending: false,
  success: false,
  fail: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_SLIDE_CONTENT:
      return state
        .set('pending', true)
        .set('success', false)
        .set('fail', false);
    case REFRESH_SLIDE_CONTENT_SUCCESS:
      return state
        .set('pending', false)
        .set('success', true)
        .set('fail', false);
    case REFRESH_SLIDE_CONTENT_FAIL:
      return state
        .set('pending', false)
        .set('success', false)
        .set('fail', true);
    default:
      return state;
  }
}

export default reducer;
