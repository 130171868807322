import { fromJS } from 'immutable';

import {
  CHANGE_ACTIVE_TAB,
} from './constants';

const initialState = fromJS({
  activeTab: 'tabItem_1',
});

function allRatingReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ACTIVE_TAB:
      return state.set('activeTab', action.activeTab);
    default:
      return state;
  }
}

export default allRatingReducer;
