import React from 'react';
import Content from '../../../components/Content';
import Section from '../../../components/Section';
import H1 from './includes/H1';
import Text from './includes/Text';
import TextBlock from './includes/TextBlock';
import TextWrapper from './includes/TextWrapper';
import PlasticCardImage from '../../../assets/plastic-card.png';

const PaymentMethods = () => (
  <Section>
    <Content>
      <TextWrapper>
        <TextBlock>
          <H1>Способы оплаты</H1>
          <Text>
            К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide и МИР.
          </Text>
          <Text>
            Для оплаты товара банковской картой при оформлении заказа вы будуте направлены на страницу оплаты
            банковской картой.
          </Text>
          <Text>
            При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам
            необходимо ввести данные Вашей банковской карты:
          </Text>
          <Text>
            <img
              src={PlasticCardImage}
              alt=""
              style={{
                width: '100%',
                maxWidth: 443,
              }}
            />
          </Text>
          <Text>
            <ul>
              <li>тип карты</li>
              <li>номер карты</li>
              <li>срок действия карты (указан на лицевой стороне карты)</li>
              <li>Имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
              <li>CVC2/CVV2 код</li>
            </ul>
          </Text>
          <Text>
            Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка,
            выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной
            идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
          </Text>
          <Text>
            Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом
            безопасности PCI DSS. Передача информации происходит с применением технологии шифрования SSL. Эта информация
            недоступна посторонним лицам.
          </Text>
          <Text>
            Советы и рекомендации по необходимым мерам безопасности проведения платежей с использованием банковской
            карты:
          </Text>
          <Text>
            <ul>
              <li>берегите свои пластиковые карты так же, как бережете наличные деньги. Не забывайте их в машине,
                ресторане,
                магазине и т.д.
              </li>
              <li>никогда не передавайте полный номер своей кредитной карты по телефону каким-либо лицам или компаниям
              </li>
              <li>всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и в
                случае ее
              </li>
              <li>утраты немедленно свяжитесь с банком</li>
              <li>вводите реквизиты карты только при совершении покупки. Никогда не указывайте их по каким-то другим
                причинам.
              </li>
            </ul>
          </Text>
        </TextBlock>
      </TextWrapper>
    </Content>
  </Section>
);

export default PaymentMethods;
