import React, { Children } from 'react';
import styled from 'styled-components';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import posed, { PoseGroup } from 'react-pose';
import { setActiveLibraryLink, setShowLibraryPopup } from '../../../../redux/modules/library/actions';
import { makeSelectGameIsLoaded } from '../selectors';
import Loader from '../../../../components/Loader/index';

const LoaderAnimation = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      default: {
        duration: 300,
      },
    },
    delay: 100,
  },
});

const Container = pure(styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`);

const onClick = ({ setShowLibraryPopup, setActiveLibraryLink }) => (event) => {
  const { target } = event;

  if (target && target.classList && target.classList.contains('link_to_library')) {
    const { href } = target;
    const temp = href.split('#');
    if (temp.length === 2) {
      const url = temp[1];
      if (url) {
        event.preventDefault();
        setActiveLibraryLink(url);
        setShowLibraryPopup(true);
        return false;
      }
    }
  }
};

class GamePageWrapper extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('click', onClick({
      setShowLibraryPopup: this.props.setShowLibraryPopup,
      setActiveLibraryLink: this.props.setActiveLibraryLink,
    }));
  }

  componentWillUnmount() {
    document.removeEventListener('click', onClick({
      setShowLibraryPopup: this.props.setShowLibraryPopup,
      setActiveLibraryLink: this.props.setActiveLibraryLink,
    }));
  }

  render() {
    const loading = !this.props.gameIsLoaded;

    return (
      <>
        <Container show={!loading}>
          {Children.toArray(this.props.children)}
        </Container>
        <PoseGroup>
          {loading && (
            <LoaderAnimation key="loader">
              <Loader isActive />
            </LoaderAnimation>
          )}
        </PoseGroup>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setShowLibraryPopup: value => dispatch(setShowLibraryPopup(value)),
  setActiveLibraryLink: value => dispatch(setActiveLibraryLink(value)),
});

const mapStateToProps = createStructuredSelector({
  gameIsLoaded: makeSelectGameIsLoaded(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(GamePageWrapper);
