import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pure from 'recompose/pure';
import LogoImage from './logo.svg';

const Logo = pure(styled.div`
  background-image: url(${LogoImage});
  height: 30px;
  width: 30px;
`);

const LogoWrapper = pure(styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`);

const Text = pure(styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 9px;
`);

class ColoredLogo extends React.PureComponent {
  render() {
    return (
      <LogoWrapper>
        <Logo />
        {this.props.text && (
          <Text>{this.props.text}</Text>
        )}
      </LogoWrapper>
    );
  }
}

ColoredLogo.propTypes = {
  text: PropTypes.string,
};

export default ColoredLogo;
