import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Popup from '../../../components/Popup';

import RightGameMenu from '../../../components/RightGameMenu';
import { makeSelectActiveGameId } from '../../../redux/modules/activeGame/selectors';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { makeSelectGames } from '../../../redux/modules/games/selectors';

import {
  makeSelectTopMenuState,
  makeSelectLocation,
  makeSelectRightGameMenuIsOpened,
} from '../../../redux/modules/global/selectors';
import {
  makeSelectQuestionName,
  makeSelectKeysCount,
  makeSelectStavka,
  makeSelectSuperGame,
  makeSelectIsAllCardsOpened, makeSelectWidgetList, makeSelectWidgetDescriptions,
} from '../../../redux/modules/myGame/selectors';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';
import { changeRightGameMenuState, changeTopMenuState, logout } from '../../../redux/modules/global/actions';

import GameStatus from '../../../components/GameStatus';
import Logo from '../../../components/Logo';
import TopMenu from '../../../components/TopMenu';
import UserPhoto from '../../../components/UserPhoto';

import { showFeedBackPopup } from '../../../redux/modules/feedBackForm/actions';
import Modal from '../../Modal';

import Booster from './Booster';
import Div from './Div';
import HeaderWrapper from './HeaderWrapper';
import HeaderContent from './HeaderContent';
import Keys from './Keys';
import Stavka from './Stavka';

class GameHeader extends React.PureComponent {
  state = {
    showMyMoodPopup: false,
    showClientMoodPopup: false,
    showWidgetInfoPopup: false,
  };

  handleWidgetClick = (widgetId) => () => {
    this.setState((prevState) => {
      if (widgetId === prevState.showWidgetInfoPopup) {
        return {
          showWidgetInfoPopup: false,
        };
      }

      return {
        showWidgetInfoPopup: widgetId,
      };
    });
  };

  handleMyMoodPopup = () => {
    this.setState((prevState) => ({
      showMyMoodPopup: !prevState.showMyMoodPopup,
    }));
  };

  handleClientMoodPopup = () => {
    this.setState((prevState) => ({
      showClientMoodPopup: !prevState.showClientMoodPopup,
    }));
  };

  handleTopMenuState = () => {
    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;
    if (!small) {
      this.props.onTopMenuClick(!this.props.topMenuState);
    } else {
      this.props.changeRightGameMenuState(!this.props.rightGameMenuIsOpened);
    }
  };

  closeRightGameMenu = () => {
    this.props.changeRightGameMenuState(false);
  };

  closeTopMenu = () => {
    this.props.onTopMenuClick(false);
  };

  render() {
    const { pathname } = this.props.routeState;

    if (!/\/games\/.*/.test(pathname)) {
      return <div />;
    }

    let stavka = 1;
    if (this.props.stavkaState === 1) {
      stavka = 2;
    } else if (this.props.stavkaState === 2) {
      stavka = 3;
    } else if (this.props.stavkaState === 3) {
      stavka = 5;
    }

    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;

    const { activeGameId, gamesList } = this.props;
    const currentGameInfo = find(gamesList, { GameID: activeGameId }, false);
    const name = get(currentGameInfo, 'Name');

    // TODO: Описание к Stavka недописано...
    return (
      <HeaderWrapper small={small}>
        {!small && (
          <Logo
            isGameHeader
            text={name}
          />
        )}
        <HeaderContent>
          <GameStatus
            widgetList={this.props.widgetList}
            handleWidgetClick={this.handleWidgetClick}
          />
        </HeaderContent>
        <RightGameMenu
          open={this.props.rightGameMenuIsOpened}
          onClose={this.closeRightGameMenu}
          keysCount={this.props.keysCount}
          stavka={stavka}
          onFeedBackClick={this.props.onFeedBackClick}
        />
        <Div
          withBooster={!small && this.props.stavkaState !== 0}
        >
          <Booster>
            {!small && this.props.stavkaState !== 0 && (
              <Stavka text={`x${stavka}`} tooltip="Активирован бустер “двойная ставка на себя”. То есть… текст" />
            )}
            {!small && !this.props.isAllCardsOpened && (
              <Keys
                desktop
                keysCount={this.props.keysCount}
              />
            )}
          </Booster>
          <UserPhoto
            inHeader
            inGame
            small={small}
            src={this.props.profile.photo}
            name={this.props.profile.name}
            surname={this.props.profile.surname}
            alt={`${this.props.profile.name} ${this.props.profile.surname}`}
            handleTopMenuState={this.handleTopMenuState}
            nickname={this.props.profile.email}
          />
          <TopMenu
            isActive={this.props.topMenuState}
            toggleTopMenuState={this.handleTopMenuState}
            onFeedBackClick={this.props.onFeedBackClick}
            closeTopMenu={this.closeTopMenu}
            onExit={this.props.logout}
          />
        </Div>

        <Modal>
          {map(this.props.widgetList, (widget) => {
            const widgetDescription = find(this.props.widgetDescriptions, { widgetId: widget.GameWidgetID });

            if (!widgetDescription) {
              return null;
            }

            return (
              <Popup
                key={widgetDescription.widgetId }
                isActive={widgetDescription.widgetId === this.state.showWidgetInfoPopup}
                onClose={this.handleWidgetClick(widgetDescription.widgetId)}
                withoutActions
                title={widget.Name}
              >
                <div dangerouslySetInnerHTML={{ __html: widgetDescription.description }} />
              </Popup>
            );
          })}
        </Modal>

      </HeaderWrapper>
    );
  }
}

GameHeader.propTypes = {
  topMenuState: PropTypes.bool,
  routeState: PropTypes.object,
  onTopMenuClick: PropTypes.func,
  keysCount: PropTypes.number,
  profile: PropTypes.object,
  stavkaState: PropTypes.number,
  superGame: PropTypes.object,
  logout: PropTypes.func,
  onFeedBackClick: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onTopMenuClick: newMenuState => dispatch(changeTopMenuState(newMenuState)),
    changeRightGameMenuState: newMenuState => dispatch(changeRightGameMenuState(newMenuState)),
    logout: () => dispatch(logout()),
    onFeedBackClick: () => dispatch(showFeedBackPopup()),
  };
}

const mapStateToProps = createStructuredSelector({
  routeState: makeSelectLocation(),
  rightGameMenuIsOpened: makeSelectRightGameMenuIsOpened(),
  isAllCardsOpened: makeSelectIsAllCardsOpened(),
  topMenuState: makeSelectTopMenuState(),
  questionName: makeSelectQuestionName(),
  keysCount: makeSelectKeysCount(),
  profile: makeSelectProfile(),
  stavkaState: makeSelectStavka(),
  superGame: makeSelectSuperGame(),
  browser: makeSelectBrowser(),
  activeGameId: makeSelectActiveGameId(),
  gamesList: makeSelectGames(),
  widgetList: makeSelectWidgetList(),
  widgetDescriptions: makeSelectWidgetDescriptions(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(GameHeader);
