import {
  call, select, put, takeLeading,
} from 'redux-saga/effects';
import get from 'lodash/get';
import passwordHash from '../../../../utils/passwordHash';
import { apiRequest as createApiRequest } from '../../../../utils/request';
import {
  confirmPersonalEmailRequestSuccess,
  confirmPersonalEmailRequestFail,
} from '../../../modules/forms/employeeSetUserEmailForm/actions';
import { makeSelectApiUrl } from '../../../modules/global/selectors';
import { makeSelectPassword, makeSelectToken } from '../../../modules/forms/employeeSetUserEmailForm/selectors';
import { CONFIRM_PERSONAL_EMAIL_REQUEST } from '../../../modules/forms/employeeSetUserEmailForm/constants';

function build({ cookies }) {
  const apiRequest = createApiRequest(cookies, false);

  function* setPasswordSaga() {
    const url = yield select(makeSelectApiUrl());
    const password = yield select(makeSelectPassword());
    const token = yield select(makeSelectToken());
    const passwordHashValue = passwordHash(password);

    const requestURL = `${url}/EmployeeSetUserEmail/${encodeURIComponent(token)}/${encodeURIComponent(passwordHashValue)}/`;

    try {
      const request = yield call(apiRequest, requestURL);

      const success = get(request, 'data.result[0].Result', false);
      if (success === 'success') {
        yield put(confirmPersonalEmailRequestSuccess());
      } else {
        yield put(confirmPersonalEmailRequestFail());
      }
    } catch (e) {
      yield put(confirmPersonalEmailRequestFail());
    }
  }

  function* saga() {
    yield takeLeading(CONFIRM_PERSONAL_EMAIL_REQUEST, setPasswordSaga);
  }

  return saga;
}

export default build;
