export const GET_DEPARTMENT_LIST_REQUEST = 'readom/Employees/GET_DEPARTMENT_LIST_REQUEST';
export const GET_DEPARTMENT_LIST_REQUEST_SUCCESS = 'readom/Employees/GET_DEPARTMENT_LIST_REQUEST_SUCCESS';
export const GET_DEPARTMENT_LIST_REQUEST_FAIL = 'readom/Employees/GET_DEPARTMENT_LIST_REQUEST_FAIL';

export const GET_EMPLOYEES_LIST_REQUEST = 'readom/Employees/GET_EMPLOYEES_LIST_REQUEST';
export const GET_EMPLOYEES_LIST_REQUEST_SUCCESS = 'readom/Employees/GET_EMPLOYEES_LIST_REQUEST_SUCCESS';
export const GET_EMPLOYEES_LIST_REQUEST_FAIL = 'readom/Employees/GET_EMPLOYEES_LIST_REQUEST_FAIL';

export const SET_NEW_DEPARTMENT_REQUEST = 'readom/Employees/SET_NEW_DEPARTMENT_REQUEST';
export const SET_NEW_DEPARTMENT_REQUEST_SUCCESS = 'readom/Employees/SET_NEW_DEPARTMENT_REQUEST_SUCCESS';
export const SET_NEW_DEPARTMENT_REQUEST_FAIL = 'readom/Employees/SET_NEW_DEPARTMENT_REQUEST_FAIL';

export const DELETE_DEPARTMENT_REQUEST = 'readom/Employees/DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_REQUEST_SUCCESS = 'readom/Employees/DELETE_DEPARTMENT_REQUEST_SUCCESS';
export const DELETE_DEPARTMENT_REQUEST_FAIL = 'readom/Employees/DELETE_DEPARTMENT_REQUEST_FAIL';

export const EDIT_DEPARTMENT_REQUEST = 'readom/Employees/EDIT_DEPARTMENT_REQUEST';
export const EDIT_DEPARTMENT_REQUEST_SUCCESS = 'readom/Employees/EDIT_DEPARTMENT_REQUEST_SUCCESS';
export const EDIT_DEPARTMENT_REQUEST_FAIL = 'readom/Employees/EDIT_DEPARTMENT_REQUEST_FAIL';

export const SET_EMPLOYEE_LIST_FILTER_DEPARTMENT = 'readom/Employees/SET_EMPLOYEE_LIST_FILTER_DEPARTMENT';
export const SET_EMPLOYEE_LIST_FILTER_KEYWORD = 'readom/Employees/SET_EMPLOYEE_LIST_FILTER_KEYWORD';
export const SET_EMPLOYEE_LIST_FILTER_STATUS = 'readom/Employees/SET_EMPLOYEE_LIST_FILTER_STATUS';

export const EDIT_EMPLOYEE_REQUEST = 'readom/Employees/EDIT_EMPLOYEE_REQUEST';
export const EDIT_EMPLOYEE_REQUEST_SUCCESS = 'readom/Employees/EDIT_EMPLOYEE_REQUEST_SUCCESS';
export const EDIT_EMPLOYEE_REQUEST_FAIL = 'readom/Employees/EDIT_EMPLOYEE_REQUEST_FAIL';

export const DELETE_EMPLOYEE_REQUEST = 'readom/Employees/DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_REQUEST_SUCCESS = 'readom/Employees/DELETE_EMPLOYEE_REQUEST_SUCCESS';
export const DELETE_EMPLOYEE_REQUEST_FAIL = 'readom/Employees/DELETE_EMPLOYEE_REQUEST_FAIL';

export const CHANGE_LOADING = 'readom/Employees/CHANGE_LOADING';
export const CHANGE_DEPARTMENT_COUNT = 'readom/Employees/CHANGE_DEPARTMENT_COUNT';
export const CHANGE_NEW_DEPARTMENT = 'readom/Employees/CHANGE_NEW_DEPARTMENT';
export const CHANGE_ACTIVE_TAB = 'readom/Employees/CHANGE_ACTIVE_TAB';
export const CHANGE_DELETE_DEPARTMENT_POPUP_STATE = 'readom/Employees/CHANGE_DELETE_DEPARTMENT_POPUP_STATE';
export const CHANGE_DEPARTMENT_FOR_EDIT = 'readom/Employees/CHANGE_DEPARTMENT_FOR_EDIT';
export const CHANGE_EDIT_DEPARTMENT_POPUP_STATE = 'readom/Employees/CHANGE_EDIT_DEPARTMENT_POPUP_STATE';
export const CHANGE_ADD_EMPLOYEE_POPUP_STATE = 'readom/Employees/CHANGE_ADD_EMPLOYEE_POPUP_STATE';
export const CHANGE_EDIT_EMPLOYEE_POPUP_STATE = 'readom/Employees/CHANGE_EDIT_EMPLOYEE_POPUP_STATE';
export const CHANGE_EMPLOYEE_FOR_EDIT = 'readom/Employees/CHANGE_EMPLOYEE_FOR_EDIT';
export const CHANGE_DELETE_EMPLOYEE_POPUP_STATE = 'readom/Employees/CHANGE_DELETE_EMPLOYEE_POPUP_STATE';
