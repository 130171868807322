import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { connect } from 'react-redux';
import lifecycle from 'recompose/lifecycle';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { getGameList, getGamesShowcases } from '../../../redux/modules/games/actions';
import {
  makeSelectGames,
  makeSelectGamesData, makeSelectGetGamesPending,
} from '../../../redux/modules/games/selectors';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import URL_CONSTANTS from '../../../urlConstants';

import SimplePageBase from '../../PageBase/SimplePageBase';

import {
  makeSelectLeftMenuState,
  makeSelectPopupState,
  makeSelectUserSession,
  makeSelectDropzoneState,
} from '../../../redux/modules/global/selectors';
import {
  changeLeftMenuState,
  changePopupState,
  changeDropzoneState,
} from '../../../redux/modules/global/actions';

import Button from '../../../components/useThis/Button';
import Content from '../../../components/Content';
import H1 from '../../../components/H1';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Paragraph from '../../../components/Paragraphh';
import ProfileForm from '../../forms/ProfileForm';
import ShortGame from '../../../components/ShortGame';
import StatusBar from '../../../components/StatusBar';
import Text from '../../../components/Text';
import UserPhoto from '../../../components/UserPhoto';

import TitleWrapper from './TitleWrapper';

import { setUserPhoto, getProfileInfo } from '../../../redux/modules/profile/actions';
import { makeSelectProfile, makeSelectProfileIsLoading } from '../../../redux/modules/profile/selectors';

const Root = styled.div`
  ${props => !props.small && 'margin-left: 30px;'}
  width: 100%;
`;

class HomePage extends React.Component {
  state = {
    payIconHovered: false,
  };

  componentWillMount = () => {
    this.props.getProfileInfo();
  };

  togglePayIconHover = () => {
    this.setState({
      payIconHovered: !this.state.payIconHovered,
    });
  };

  togglePopup = () => {
    if (this.props.isLoading) {
      this.props.onPopupOpen(false);
    } else {
      this.props.onPopupOpen(!this.props.popupState);
    }
  };

  toggleDropzone = () => {
    this.props.onDropzoneClick(!this.props.dropzoneState);
  };

  uploadUserpic = (base64File) => {
    this.props.onDrop({ photo: base64File });
    this.toggleDropzone();
  };

  onContinueGame = url => () => {
    window.location.href = url;
    return false;
  };

  onStartNewGame = url => () => {
    window.location.href = url;
    return false;
  };

  render() {
    const profile = {
      name: this.props.profile.name,
      surname: this.props.profile.surname,
      nickname: this.props.profile.nickname,
      photo: this.props.profile.photo,
      idn: this.props.userSession.id,
      email: this.props.profile.email,
      company: this.props.profile.company,
      department: this.props.profile.department,
      position: this.props.profile.position,
      isEmployee: this.props.profile.isEmployer,
      readoms: this.props.profile.readoms,
      rating: this.props.profile.rating,
      maxsumma: this.props.profile.maxsumma,
      // TODO: FIXME
      canContinue: false,
      virtualCash: this.props.profile.virtualCash,
    };

    let content = <LoadingIndicator />;
    const small = this.props.browser.is.extraSmall || this.props.browser.is.small;
    const medium = this.props.browser.is.medium;

    const myGames = filter(this.props.games, (game) => {
      // const gameData = get(find(this.props.gamesData, { id: game.GameID }), 'data');

      return game.HasPermission;
    });

    if (!this.props.isLoading) {
      content = (
        <Content flexWrap={(small || medium) && 'wrap'} justify={(small || medium) && 'center'}>
          <UserPhoto
            src={profile.photo}
            name={profile.name}
            surname={profile.surname}
            alt={`${profile.name} ${profile.surname}`}
            toggleDropzone={this.toggleDropzone}
            isActiveDragPopup={this.props.dropzoneState}
            onDrop={this.uploadUserpic}
          />

          <Root small={(small || medium)}>
            <TitleWrapper flexWrap={(small || medium) && 'wrap'} justify={(small || medium) && 'center'}>
              <H1 align={(small || medium) && 'center'} fullWidth={(small || medium)}>
                {profile.name}
                {' '}
                {profile.surname}
              </H1>
              {/*{this.props.profile.isWorkInvoice && (*/}
                {/*<LinkButton*/}
                  {/*to="/payment"*/}
                  {/*onMouseEnter={this.togglePayIconHover}*/}
                  {/*onMouseLeave={this.togglePayIconHover}*/}
                {/*>*/}
                  {/*<PayIcon isHovered={this.state.payIconHovered} />*/}
                  {/*<LinkInner isHovered={this.state.payIconHovered}>*/}
                    {/*платежная информация*/}
                  {/*</LinkInner>*/}
                {/*</LinkButton>*/}
              {/*)}*/}
            </TitleWrapper>
            {profile.nickname && (
              <Paragraph textAlign={(small || medium) && 'center'}>
                <Text bold>{profile.nickname}</Text>
                <Text bold grey>/</Text>
              </Paragraph>
            )}
            {profile.isEmployee && (
              <Paragraph withMargin textAlign={(small || medium) && 'center'}>
                {profile.company && <Text bold minHeight>{`«${profile.company}»`}</Text>}
                {profile.department && <Text grey minHeight br>{profile.department}</Text>}
              </Paragraph>
            )}
            <Paragraph textAlign={(small || medium) && 'center'}>
              <Button onClick={this.togglePopup} isAlternative>Изменить данные</Button>
            </Paragraph>
            {this.props.popupState && (
              <ProfileForm
                isEmployer={this.props.profile.isEmployer}
                isCompanyDirector={this.props.profile.isCompanyDirector}
                onClose={this.togglePopup}
              />
            )}
            <StatusBar
              rating={profile.rating.toString()}
              readoms={profile.readoms.toLocaleString()}
            />
            {this.props.gamesPending && <LoadingIndicator />}
            {map(myGames, (game) => {
              const gameId = game.GameID;
              const gameData = get(find(this.props.gamesData, { id: gameId }), 'data');

              const virtualCashKey = get(game, 'OptionsValues.cashSysName', null);
              const image = get(game, 'OptionsValues.roundImage', null);

              const gameShortName = get(game, 'NameSys');

              const playUrl = `${URL_CONSTANTS.PREFIXES.SINGLE_GAME}/${gameShortName}`;
              const url = `${URL_CONSTANTS.PREFIXES.GAME_OVERVIEW}/${gameShortName}`;
              const cash = find(profile.virtualCash, { name: virtualCashKey });

              const cashValue = cash ? cash.balance.toLocaleString() : 0;

              return (
                <ShortGame
                  key={game.GameID}
                  image={image}
                  name={game.Name}
                  licenseExpired={!game.HasPermission}
                  description={game.Title1}
                  cash={cashValue || 0}
                  maxsumma={gameData.maxSumma || 0}
                  canContinue={gameData.canContinue}
                  gameOverviewUrl={url}
                  onContinue={this.onContinueGame(playUrl)}
                  onStartNewGame={this.onStartNewGame(url)}
                />
              );
            })}
          </Root>
        </Content>
      );
    }

    return (
      <SimplePageBase title="Профиль пользователя">
        {content}
      </SimplePageBase>
    );
  }
}

HomePage.defaultProps = {
  browser: {
    is: {
      extraSmall: false,
      small: false,
    },
  },
};

HomePage.propTypes = {
  popupState: PropTypes.bool,
  browser: PropTypes.object,
  leftMenuState: PropTypes.bool,
  dropzoneState: PropTypes.bool,
  profile: PropTypes.object,
  userSession: PropTypes.object,
  isLoading: PropTypes.bool,
  onLeftMenuClick: PropTypes.func,
  onPopupOpen: PropTypes.func,
  onDropzoneClick: PropTypes.func,
  onContinueGame: PropTypes.func,
  onDrop: PropTypes.func,
  getProfileInfo: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    getProfileInfo: () => dispatch(getProfileInfo()),
    onPopupOpen: newPopupState => dispatch(changePopupState(newPopupState)),
    onLeftMenuClick: newMenuState => dispatch(changeLeftMenuState(newMenuState)),
    onDropzoneClick: newDropzoneState => dispatch(changeDropzoneState(newDropzoneState)),
    onDrop: file => dispatch(setUserPhoto(file)),
    getGameList: () => dispatch(getGameList()),
    getGamesShowcases: () => dispatch(getGamesShowcases()),
  };
}

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  userSession: makeSelectUserSession(),
  popupState: makeSelectPopupState(),
  leftMenuState: makeSelectLeftMenuState(),
  dropzoneState: makeSelectDropzoneState(),
  isLoading: makeSelectProfileIsLoading(),
  browser: makeSelectBrowser(),
  games: makeSelectGames(),
  gamesData: makeSelectGamesData(),
  gamesPending: makeSelectGetGamesPending(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentWillMount() {
    this.props.getGameList();
    this.props.getGamesShowcases();
    this.props.getProfileInfo();
  },
});

export default compose(
  withConnect,
  withLifecycle,
)(HomePage);
