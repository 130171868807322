export const STORE_NAME = 'games';

export const GET_ALL_GAMES_LIST = 'readom/Games/GET_ALL_GAMES_LIST';
export const GET_ALL_GAMES_LIST_SUCCESS = 'readom/Games/GET_ALL_GAMES_LIST_SUCCESS';
export const GET_ALL_GAMES_LIST_FAIL = 'readom/Games/GET_ALL_GAMES_LIST_FAIL';

export const ADD_SHOWCASE_TO_CART = 'readom/Games/ADD_SHOWCASE_TO_CART';
export const ADD_SHOWCASE_TO_CART_SUCCESS = 'readom/Games/ADD_SHOWCASE_TO_CART_SUCCESS';
export const ADD_SHOWCASE_TO_CART_FAIL = 'readom/Games/ADD_SHOWCASE_TO_CART_FAIL';

export const BUY_SHOWCASE = 'readom/Games/BUY_SHOWCASE';
export const BUY_SHOWCASE_SUCCESS = 'readom/Games/BUY_SHOWCASE_SUCCESS';
export const BUY_SHOWCASE_FAIL = 'readom/Games/BUY_SHOWCASE_FAIL';

export const BUY_SHOWCASE_FROM_DEMO = 'readom/Games/BUY_SHOWCASE_FROM_DEMO';
export const BUY_SHOWCASE_FROM_DEMO_SUCCESS = 'readom/Games/BUY_SHOWCASE_FROM_DEMO_SUCCESS';
export const BUY_SHOWCASE_FROM_DEMO_FAIL = 'readom/Games/BUY_SHOWCASE_FROM_DEMO_FAIL';

export const GET_CART = 'readom/Games/GET_CART';
export const GET_CART_SUCCESS = 'readom/Games/GET_CART_SUCCESS';
export const GET_CART_FAIL = 'readom/Games/GET_CART_FAIL';

export const GET_INVOICES = 'readom/Games/GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'readom/Games/GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'readom/Games/GET_INVOICES_FAIL';

export const CART_CHECKOUT = 'readom/Games/CART_CHECKOUT';
export const CART_CHECKOUT_SUCCESS = 'readom/Games/CART_CHECKOUT_SUCCESS';
export const CART_CHECKOUT_FAIL = 'readom/Games/CART_CHECKOUT_FAIL';

export const GET_GAMES_SHOWCASE = 'readom/Games/GET_GAMES_SHOWCASE';
export const GET_GAMES_SHOWCASE_FAIL = 'readom/Games/GET_GAMES_SHOWCASE_FAIL';
export const GET_GAMES_SHOWCASE_SUCCESS = 'readom/Games/GET_GAMES_SHOWCASE_SUCCESS';
