import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { useMedia } from 'the-platform';
import { device } from '../../utils/mediaQueries';
import scrollLock from '../../utils/scrollLock';
import dartCross from './dark-cross.svg';

import InfoIcon from './icon.svg';

const InfoWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;
  position: relative;
`;

const Icon = styled.img`
  cursor: pointer;
  opacity: ${props => (props.isOpened ? '1' : '.5')};
  transition: opacity .3s ease-out;
`;

const TooltipAnimation = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Tooltip = styled(TooltipAnimation)`
  background-color: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  cursor: default;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  left: -63px;
  line-height: 15px;
  margin-top: 18px;
  padding: 7px 10px;
  position: absolute;
  text-align: left;
  width: ${props => (props.isBig ? '240px' : '140px')};
  z-index: 1000;

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #Ffffff;
    border-width: 12px;
    margin-left: ${props => (props.isBig ? '-62px' : '-12px')};
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #DEDEDE;
    border-width: 13px;
    margin-left: ${props => (props.isBig ? '-63px' : '-13px')};
  }
`;

const TextInTooltip = styled.span`
  ${props => props.textColor && `color: ${props.textColor};`}
`;

const Overlay = styled.div`
  height: 100vh; 
  pointer-events: auto;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed; 
  right: 0; 
  top: 0;
  z-index: 24; 
  background-color: rgba(0, 0, 0, 0.5); 
`;

const MobileTooltipBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
`;

const MobileTooltipContent = styled.div`
  width: 300px;
  background: #fff;
  border-radius: 5px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: 1px solid #DEDEDE;
  padding: 7px 10px;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
`;

const MobileTooltipClose = styled.div`
  position: absolute;
  right: -18px;
  top: -18px;
  width: 30px;
  height: 30px;
  opacity: 1;
  background-image: url(${dartCross});
  background-position: center;
  background-color: #fff;
  background-size: 12px;
  border-radius: 30px;
  border: 1px solid #232323;
  cursor: pointer;
`;

const MobileTooltip = ({ text, hideTooltip }) => {
  useEffect(() => {
    scrollLock.on();

    return () => {
      scrollLock.off();
    };
  }, []);

  return (
    <>
      <Overlay onClick={hideTooltip} />
      <MobileTooltipBody onClick={hideTooltip}>
        <MobileTooltipContent>
          <MobileTooltipClose onClick={hideTooltip} />
          {text}
        </MobileTooltipContent>
      </MobileTooltipBody>
    </>
  );
};

const Info = ({ text, isBig, textColor }) => {
  const [isOpened, setIsOpened] = useState(false);

  const small = useMedia(device.tablet);

  const showTooltip = () => {
    setIsOpened(true);
  };

  const hideTooltip = () => {
    setIsOpened(false);
  };

  const otherProps = {};

  if (!small) {
    otherProps.onMouseEnter = showTooltip;
    otherProps.onMouseLeave = hideTooltip;
  } else {
    otherProps.onClick = showTooltip;
  }

  return (
    <InfoWrapper>
      <Icon
        src={InfoIcon}
        isOpened={isOpened}
        {...otherProps}
      />
      <PoseGroup flipMove={false}>
        {isOpened && !small && (
          <Tooltip
            key="tooltip"
            isBig={isBig}
          >
            <TextInTooltip textColor={textColor}>
              {text}
            </TextInTooltip>
          </Tooltip>
        )}
      </PoseGroup>
      {isOpened && small && (
        <MobileTooltip
          key="tooltip-mobile"
          text={text}
          hideTooltip={hideTooltip}
        />
      )}
    </InfoWrapper>
  );
};

Info.defaultProps = {
  textColor: null,
  isBig: false,
};

Info.propTypes = {
  isBig: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};

export default Info;
