import styled from 'styled-components';

const TipFormButtonWrapper = styled.div`
  margin-left: auto;
  @media (max-width: 400px) {
    margin: 0 auto;
  }
`;

export default TipFormButtonWrapper;
