import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { darken } from 'polished';
import isIOS from '../../../../utils/isIOS';
import scrollLock from '../../../../utils/scrollLock';
import CloseIcon from './CloseIcon';
import PopupInner from './PopupInner';
import ScrollPopupContentToTop from './ScrollPopupContentToTop';

const PopupWrapper = styled.div`
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  left: 0;
  height: 100vh;
  opacity: ${props => (props.isActive ? '1' : '0')};
  overflow: auto;
  padding: ${props => (props.small ? '15px 5px 90px 5px;' : '15px 5px')};
  position: fixed;
  top: 0;
  transition: opacity .3s ease-out;
  width: 100vw;
  z-index: ${props => (props.isActive ? '14000' : '-1')};
  ${isIOS() && 'padding-bottom: 100px'}
`;

const PopupHeader = styled.div`
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 90px;
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const PopupTitle = styled.h1`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
  
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const PopupBody = styled.div`
  border-bottom: 1px solid #DEDEDE;
  margin: 0 30px;

  @media (max-width: 768px) {
    margin: 0 15px;
  }
  border-bottom: ${props => (props.withoutBorder ? 'none' : '1px solid #DEDEDE')};
`;

const PopupFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  ${props => props.withoutActions && 'padding: 15px;'}
`;

const Footer = styled.div`
  flex: 1;
`;

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button`
  background-color: ${props => (props.isAlternative ? '#FFFFFF' : '#35BB9A')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${props => (props.isAlternative ? '1px solid rgba(0, 0, 0, .25)' : '')};
  border-radius: 25px;
  color:  ${props => (props.isAlternative ? '#1C1C1C' : '#FFFFFF')};
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 50px;
  letter-spacing: 1px;
  margin-left: ${props => (props.allWidth ? '0' : '15px')};
  outline: none;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all .3s ease-out;
  width: ${props => (props.allWidth ? '100%' : 'inherit')};
  user-select: none;

  &:hover {
    background-color: ${props => (props.isAlternative ? '#FFFFFF' : darken(0.1, '#35BB9A'))};
    border: ${props => (props.isAlternative ? '1px solid rgba(0, 0, 0, 1)' : '')};
  }
`;

class Popup extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.html = document.querySelector('html');
  }

  componentDidMount() {
    if (this.props.isActive) {
      scrollLock.on();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isActive && this.props.isActive) {
      scrollLock.on();
    }

    if (prevProps.isActive && !this.props.isActive) {
      scrollLock.off();
    }
  }

  componentWillUnmount() {
    scrollLock.off();
  }

  render() {
    const submit = this.props.submit ? this.props.submit : null;
    const {
      pending, disabled, withoutActions, title,
    } = this.props;

    return (
      <PopupWrapper isActive={this.props.isActive} ref={this.wrapperRef}>
        <ScrollPopupContentToTop isActive={this.props.isActive} containerRef={this.wrapperRef}>
          <PopupInner isActive={this.props.isActive} onClose={this.props.onClose}>
            <PopupHeader>
              <PopupTitle>{title}</PopupTitle>
              <CloseIcon onClose={this.props.onClose} />
            </PopupHeader>
            <PopupBody withoutBorder>
              {this.props.children}
            </PopupBody>
            <PopupFooter withoutActions={withoutActions}>
              {this.props.footer && (
                <Footer>
                  {this.props.footer}
                </Footer>
              )}
              {!withoutActions && (
                <CenterFlex>
                  {this.props.onSubmit && submit && (
                    <Button type="button" allWidth onClick={this.props.onSubmit} disabled={pending || disabled}>
                      {!pending && submit}
                      {pending && 'Загрузка...'}
                    </Button>
                  )}
                </CenterFlex>
              )}
            </PopupFooter>
          </PopupInner>
        </ScrollPopupContentToTop>
      </PopupWrapper>
    );
  }
}

Popup.propTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  disableScroll: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  title: PropTypes.any,
  footer: PropTypes.any,
  cancel: PropTypes.string,
  submit: PropTypes.string,
  withoutActions: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Popup.defaultProps = {
  withoutActions: false,
  pending: false,
  disabled: false,
  disableScroll: true,
  footer: null,
};

export default Popup;
