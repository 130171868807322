import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  height: 53px;
  margin-right: 35px;
  width: 50px;
`;

const HighlightIcon = () => (
  <IconWrapper>
    <svg width="50px" height="53px" viewBox="0 0 50 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="popup1" transform="translate(-453.000000, -245.000000)" fill="#3D3F4A" fillRule="nonzero">
          <path d="M478,298 C474.517857,298 471.75,295.261667 471.75,291.816667 L471.75,284.396667 C465.857143,281.923333 461.928571,276.093333 461.928571,269.733333 C461.928571,260.988333 469.160714,253.833333 478,253.833333 C486.839286,253.833333 494.071429,260.988333 494.071429,269.733333 C494.071429,276.093333 490.142857,281.923333 484.25,284.396667 L484.25,291.816667 C484.25,295.261667 481.482143,298 478,298 Z M474.428571,294.466667 C476.214286,296.851667 479.785714,296.851667 481.571429,294.466667 L474.428571,294.466667 Z M473.625,292.7 L482.375,292.7 C482.553571,292.081667 482.464286,291.64 482.464286,290.933333 L473.535714,290.933333 C473.535714,291.64 473.535714,292.081667 473.625,292.7 Z M473.535714,289.166667 L482.464286,289.166667 L482.464286,287.4 L473.535714,287.4 L473.535714,289.166667 Z M473.535714,285.633333 L482.464286,285.633333 L482.464286,283.16 C498.178571,277.241667 493.714286,255.6 478,255.6 C462.196429,255.6 457.821429,277.33 473.535714,283.16 L473.535714,285.633333 Z M495.232143,287.135 L491.660714,283.601667 L492.910714,282.365 L496.482143,285.898333 L495.232143,287.135 Z M460.767857,287.135 L459.517857,285.898333 L463.089286,282.365 L464.339286,283.601667 L460.767857,287.135 Z M503,270.616667 L497.642857,270.616667 L497.642857,268.85 L503,268.85 L503,270.616667 Z M458.357143,270.616667 L453,270.616667 L453,268.85 L458.357143,268.85 L458.357143,270.616667 Z M468.178571,269.733333 L466.392857,269.733333 C466.392857,263.373333 471.571429,258.25 478,258.25 L478,260.016667 C472.553571,260.016667 468.178571,264.345 468.178571,269.733333 Z M492.910714,257.101667 L491.660714,255.865 L495.232143,252.331667 L496.482143,253.568333 L492.910714,257.101667 Z M463.089286,257.101667 L459.517857,253.568333 L460.767857,252.331667 L464.339286,255.865 L463.089286,257.101667 Z M478.892857,250.3 L477.107143,250.3 L477.107143,245 L478.892857,245 L478.892857,250.3 Z" id="lamp_big" />
        </g>
      </g>
    </svg>
  </IconWrapper>
);

export default HighlightIcon;
