import styled from 'styled-components';
import NormalH2 from '../H2';

const H2 = styled(NormalH2)`
  color: rgba(255, 255, 255, .5);
  font-weight: normal;
  text-align: center;
`;

export default H2;
