import { setNetworkOffline, setRequestFail } from '../modules/error/actions';
import { SET_REQUEST_FAIL } from '../modules/error/constants';

export const requestFailMiddleWare = store => next => (action) => {
  if (action.type !== SET_REQUEST_FAIL && action.type.indexOf('_FAIL') !== -1) {
    // if (typeof navigator !== 'undefined') {
    //   if (!navigator.onLine) {
    //     store.dispatch(setNetworkOffline(true));
    //   } else {
    //     store.dispatch(setRequestFail(true));
    //   }
    // }
  }

  next(action);
};
