import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import {
  SET_NEW_DEPARTMENT_REQUEST,
} from '../../../modules/employees/constants';

import { makeSelectApiUrl } from '../../../modules/global/selectors';
import {
  makeSelectNewDepartmentName,
} from '../../../modules/employees/selectors';

import { changePopupState } from '../../../modules/global/actions';
import {
  getDepartmentList,
  setNewDepartmentSuccess,
  setNewDepartmentFail,
  changeActiveTab,
} from '../../../modules/employees/actions';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* setNewDepartmentSaga() {
    const url = yield select(makeSelectApiUrl());
    const departmentName = yield select(makeSelectNewDepartmentName());

    const requestURL = `${url}/DepartmentAdd/${encodeURIComponent(departmentName)}/`;

    try {
      yield call(apiRequest, requestURL);

      yield put(setNewDepartmentSuccess());
    } catch (err) {
      yield put(setNewDepartmentFail());
    } finally {
      yield put(changePopupState(false));
      yield put(getDepartmentList());
      yield put(changeActiveTab({
        activeTab: 'tabItem_2',
      }));
    }
  }

  function* saga() {
    yield takeLeading(SET_NEW_DEPARTMENT_REQUEST, setNewDepartmentSaga);
  }

  return saga;
}

export default build;
