import PropTypes from 'prop-types';
import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import getBrowser from '../../utils/getBrowser';
import scrollLock from '../../utils/scrollLock';

const PADDING = 15;

const Overlay = styled.div`
  height: 100vh; 
  pointer-events: auto;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute; 
  right: 0; 
  top: 0;
  z-index: 24; 
  background-color: rgba(0, 0, 0, 0.5); 
  mix-blend-mode: hard-light;
`;

const OverlaySafari = styled.div`
  height: 100vh; 
  pointer-events: auto; 
  bottom: 0; 
  left: 0; 
  overflow: hidden; 
  position: absolute;
  right: 0;
  top: 0; 
  background-color: rgba(0, 0, 0, 0.5);
`;

const Animation = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 300,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'easeIn',
      duration: 150,
    },
  },
});

const Spot = styled(Animation)`
  height: ${props => props.targetRect.height}px;
  left: ${props => props.targetRect.left - props.padding}px;
  width: ${props => props.targetRect.width}px;
  top: ${props => props.targetRect.top - props.padding}px;
  padding: ${PADDING}px;
  border-radius: 15px;
  position: absolute;
  background-color: gray;
  opacity: 0;
  pointer-events: auto;
  transition: all 0.5s ease 0s;
  box-sizing: content-box;
`;

// safari
/*
<div className="react-joyride__overlay"
     style="cursor: pointer; height: 987px; pointer-events: auto; bottom: 0px; left: 0px; overflow: hidden; position: absolute;
      right: 0px; top: 0px; z-index: 100; mix-blend-mode: hard-light;">
  <div
    style="cursor: pointer; height: 987px; pointer-events: auto; bottom: 0px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; background-color: rgba(0, 0, 0, 0.498039);">
    <div className="react-joyride__spotlight"
         style="border-top-left-radius: 4px;
          border-top-right-radius: 4px;
           border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            position: absolute; background-color: gray;
            height: 320px; left: 498px; opacity: 1; pointer-events: auto; top: 393px; transition: opacity 0.2s; width: 670px;"></div>
  </div>
</div>
*/

class SpotLightOverlay extends React.Component {
  static defaultProps = {
    target: null,
  };

  static propTypes = {
    target: PropTypes.string,
    visible: PropTypes.bool.isRequired,
  };

  state = {
    timestamp: null,
  };

  refresher = null;

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    if (this.props.visible) {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      this.refresher = setInterval(() => {
        this.updateDimensions();
      }, 100);
      scrollLock.on();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      this.refresher = setInterval(() => {
        this.updateDimensions();
      }, 100);
      scrollLock.on();
    }

    if (prevProps.visible && !this.props.visible) {
      scrollLock.off();
      window.removeEventListener('resize', this.updateDimensions);
      clearInterval(this.refresher);
    }
  }

  componentWillUnmount() {
    if (this.props.visible) {
      scrollLock.off();
      window.removeEventListener('resize', this.updateDimensions);
      clearInterval(this.refresher);
    }
  }

  updateDimensions = () => {
    this.setState({
      timestamp: new Date().getTime(),
    });
  };

  render() {
    const { visible, target } = this.props;

    if (!visible || (!visible && !target)) {
      return null;
    }

    let targetRect;
    let targetFound = false;
    if (target) {
      const targetElement = document.querySelector(target);
      if (targetElement) {
        targetRect = targetElement.getBoundingClientRect();
      }

      if (targetRect) {
        targetFound = true;
      }
    }

    if (getBrowser() === 'safari') {
      return (
        <Overlay className="SpotLightOverlay__overlay">
          <OverlaySafari className="SpotLightOverlay__overlay_safari">
            <PoseGroup>
              {targetFound && (
                <Spot
                  className="SpotLightOverlay__spotlight"
                  key={targetFound}
                  targetRect={targetRect}
                  padding={PADDING}
                />
              )}
            </PoseGroup>
          </OverlaySafari>
        </Overlay>
      );
    }

    return (
      <Overlay className="SpotLightOverlay__overlay">
        <PoseGroup>
          {targetFound && (
            <Spot
              className="SpotLightOverlay__spotlight"
              key={targetFound}
              targetRect={targetRect}
              padding={PADDING}
            />
          )}
        </PoseGroup>
      </Overlay>
    );
  }
}

export default SpotLightOverlay;
