import get from 'lodash/get';
import isString from 'lodash/isString';
import {
  call, select, put, takeLeading,
} from 'redux-saga/effects';
import axios from 'axios';
import { trackErrorWhenBuyGameAsGuest, trackRedirectUserToPaymentGate } from '../../modules/analytics/actions';
import { buyGameSuccess, buyGameFail } from '../../modules/guestGameBuy/actions';
import { makeSelectApiUrl } from '../../modules/global/selectors';
import {
  BUY_REQUEST,
} from '../../modules/guestGameBuy/constants';
import { makeSelectProfile } from '../../modules/profile/selectors';

function build() {
  function* doGuestGameBuy({ payload }) {
    const { values, formik: { setFieldError, setStatus, setSubmitting } } = payload;

    const profile = yield select(makeSelectProfile());
    let phone = '';
    if (profile && profile.firstPhone && isString(profile.firstPhone)) {
      phone = profile.firstPhone;
    }

    const middleName = '';
    const url = yield select(makeSelectApiUrl());
    const requestURL = `${url}/BuyFromGameOptionAsGuest/${encodeURIComponent(values.email)}/${encodeURIComponent(values.surname)}/${encodeURIComponent(middleName)}/${encodeURIComponent(values.name)}/${encodeURIComponent(phone)}/million/so80products`;

    setSubmitting(true);

    try {
      const request = yield call(axios, requestURL);
      const isSuccess = get(request, 'data.result[0].Result', null) === 'success';

      if (isSuccess) {
        const URLPayment = get(request, 'data.result[0].URLPayment', null);
        yield put(buyGameSuccess());
        yield put(trackRedirectUserToPaymentGate());

        setTimeout(() => {
          window.location.href = URLPayment;
        }, 500);
      } else {
        const serverErrorMessage = get(request, 'data.result[0].Message', null);
        // TODO: HARDCODE_TYPE_2 Тип ошибки определяется по тексту ошибки с бека
        if (serverErrorMessage === 'Указанный почтовый адрес уже зарегистрирован у пользователя.') {
          // TODO: HARDCODE_TYPE_2 Текст ошибки зашит в код
          setFieldError('email', 'Уже есть пользователь с таким e-mail');
          yield put(trackErrorWhenBuyGameAsGuest('Уже есть пользователь с таким e-mail'));
        } else {
          // TODO: HARDCODE_TYPE_2 Текст ошибки зашит в код
          yield put(trackErrorWhenBuyGameAsGuest('Покупка игры временно недоступна. Повторите попытку позже.'));
          setStatus({ serverErrorMessage: 'Покупка игры временно недоступна. Повторите попытку позже.' });
        }
        yield put(buyGameFail());
        setSubmitting(false);
      }
    } catch (e) {
      // TODO: HARDCODE_TYPE_2 Текст ошибки зашит в код
      yield put(trackErrorWhenBuyGameAsGuest('WTF!!!'));
      yield put(buyGameFail());
      setSubmitting(false);
    }
  }

  function* saga() {
    yield takeLeading(BUY_REQUEST, doGuestGameBuy);
  }

  return saga;
}

export default build;
