import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';
import isServer from '../utils/isServer';
import createRootReducer from './modules';

import { requestFailMiddleWare } from './middleware/requestFail';

export default (initialState, history) => {
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();

  // Dev tools are helpful
  if (!isServer() && process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const responsiveStoreEnhancer = createResponsiveStoreEnhancer({ calculateInitialState: true });

  const middleware = [
    sagaMiddleware,
    requestFailMiddleWare,
    routerMiddleware(history),
  ];

  const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID;

  if (LOG_ROCKET_APP_ID) {
    middleware.push(LogRocket.reduxMiddleware());
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      responsiveStoreEnhancer,
      applyMiddleware(
        ...middleware,
      ),
      ...enhancers,
    ),
  );

  store.runSaga = sagaMiddleware.run;

  return {
    store,
    history,
  };
};
