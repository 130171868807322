import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectSuccessStateRefreshContent = () => createSelector(
  storeSlice,
  state => state.get('success'),
);

export {
  makeSelectSuccessStateRefreshContent,
};
