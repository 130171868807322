const buttonPoseAnimation = {
  pressable: true,
  hoverable: true,
  init: {
    scale: 1,
  },
  press: {
    scale: (props) => {
      if (props.notPressable || props.disabled) {
        return 1;
      }

      return 0.95;
    },
  },
  hover: {
    scale: (props) => {
      if (props.notPressable || props.disabled) {
        return 1;
      }

      return 1.05;
    },
  },
};

export default buttonPoseAnimation;
