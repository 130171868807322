import styled from 'styled-components';

const TipFormTitle = styled.div`
  color: #1C1C1C;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
  white-space: nowrap;
`;

export default TipFormTitle;
