import find from 'lodash/find';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import pure from 'recompose/pure';
import { connect } from 'react-redux';
import withProps from 'recompose/withProps';
import setDisplayName from 'recompose/setDisplayName';
import { createStructuredSelector } from 'reselect';
import { setActiveGameId } from '../../../../redux/modules/activeGame/actions';
import { makeSelectActiveGameId } from '../../../../redux/modules/activeGame/selectors';
import { getGameList, getGamesShowcases } from '../../../../redux/modules/games/actions';
import {
  makeSelectGames,
  makeSelectGamesShowcases,
} from '../../../../redux/modules/games/selectors';

const mapDispatchToProps = dispatch => ({
  setActiveGameId: gameId => dispatch(setActiveGameId(gameId)),
  getGameList: () => dispatch(getGameList()),
  getGamesShowcases: () => dispatch(getGamesShowcases()),
});

const mapStateToProps = createStructuredSelector({
  activeGameId: makeSelectActiveGameId(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const getGameId = ({ activeGameShortName, games = [] }) => {
  if (activeGameShortName) {
    const currentGameMeta = find(games, { NameSys: activeGameShortName });

    if (currentGameMeta && currentGameMeta.GameID) {
      return currentGameMeta.GameID;
    }

    return null;
  }

  return null;
};

const withLifecycle = lifecycle({
  componentDidMount() {
    if (!this.props.allGames) {
      this.props.getGameList();
      this.props.getGamesShowcases();
    }

    if (!this.props.activeGameId && this.props.allGames) {
      const activeGameShortName = this.props.match.params.gameShortName;
      const gameId = getGameId({ activeGameShortName, games: this.props.allGames });
      this.props.setActiveGameId(gameId);
    }
  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.gameShortName) {
      const activeGameShortName = nextProps.match.params.gameShortName;
      const gameId = getGameId({ activeGameShortName, games: nextProps.allGames });
      this.props.setActiveGameId(gameId);
    }

    if (!this.props.activeGameId && nextProps.allGames) {
      const activeGameShortName = this.props.match.params.gameShortName;
      const gameId = getGameId({ activeGameShortName, games: nextProps.allGames });
      this.props.setActiveGameId(gameId);
    }
  },
});

const withCustomProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const enhance = compose(
  setDisplayName('ActiveGameDependedContainer_Enhanced'),
  pure,
  withConnect,
  withRouter,
  withCustomProps,
  withLifecycle,
);

export default enhance;
