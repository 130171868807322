import {
  BUY_REQUEST,
  BUY_REQUEST_SUCCESS,
  BUY_REQUEST_FAIL,
} from './constants';

export function buyGame({ values, formik }) {
  return {
    type: BUY_REQUEST,
    payload: {
      values,
      formik,
    },
  };
}

export function buyGameSuccess(paymentURL) {
  return {
    type: BUY_REQUEST_SUCCESS,
    payload: {
      paymentURL,
    },
  };
}

export function buyGameFail(serverErrorMessage) {
  return {
    type: BUY_REQUEST_FAIL,
    payload: {
      serverErrorMessage,
    },
  };
}
