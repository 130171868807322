import React from 'react';
import { Link } from 'react-router-dom';

import Content from '../../../../components/Content/index';
import Section from '../../../../components/Section/index';
import H1 from './includes/H1';
import Text from './includes/Text';
import TextBlock from './includes/TextBlock';
import TextWithNumber from './includes/TextWithNumber';
import TextWrapper from './includes/TextWrapper';
import NoWrap from './includes/NoWrap';

const Licence = () => (
  <Section>
    <Content>
      <TextWrapper>
        <TextBlock>
          <Text>
            ВНИМАНИЕ! ЕСЛИ ВЫ ЗАГРУЖАЕТЕ, КОПИРУЕТЕ ЛЮБУЮ ЧАСТЬ ИГРЫ «Readom»,
            {' '}
            <NoWrap>И/ИЛИ</NoWrap>
            {' '}
            ИСПОЛЬЗУЕТЕ
            ЕЁ КАКИМ-ЛИБО
            ДРУГИМ СПОСОБОМ,
            {' '}
            <NoWrap>И/ИЛИ</NoWrap>
            {' '}
            ЕСЛИ ВЫ НАЧАЛИ УЧАСТВОВАТЬ В ИГРОВОМ ПРОЦЕССЕ В ИГРЕ «Readom»,
            ТО ЭТИМ ВЫ
            АКЦЕПТУЕТЕ НАСТОЯЩЕЕ ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ И ПОДТВЕРЖДАЕТЕ СВОЕ СОГЛАСИЕ СО ВСЕМИ ЕГО УСЛОВИЯМИ,
            КАСАЮЩИМИСЯ ИГРЫ, БЕЗ КАКИХ-ЛИБО ОГРАНИЧЕНИЙ.
          </Text>
        </TextBlock>
        <TextBlock>
          <H1>ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ</H1>
          <Text>
            Настоящее лицензионное соглашение (далее «Соглашение») регулирует отношения между
            {' '}
            <Link to="/organization">ИП Меликсетян Сергей Сейранович</Link>,
            {' '}
            именуемый в дальнейшем «Лицензиар» и Вами, Лицензиатом Игры (далее «Лицензиат»), в отношении Игры.
          </Text>
          <TextWithNumber number="1." center>
            Термины, используемые в настоящем Соглашении
          </TextWithNumber>
          <TextWithNumber number="1.1.">
            Игра — интерактивная компьютерная онлайн-игра «Readom», являющаяся программой для ЭВМ,
            представляющая собой совокупность данных, команд и порождаемых ею аудиовизуальных отображений (далее –
            данные и команды), активируемых последовательно для получения Лицензиатом определенного результата,
            предусмотренного сценарием Игры, без внесения платы (активированные данные и команды) или после
            внесения платы (неактивированные данные и команды). Права на использование данных и команд
            (активированных и неактивированных) передаются Лицензиаром Лицензиату на условиях, определенных
            настоящим Соглашением.
            Для обеспечения прав и обязанностей сторон, возникающих по настоящему Соглашению Лицензиат
            воспроизводит предопределенную Лицензиаром совокупность данных и команд на своей ЭВМ (‘клиентская
            часть Игры’, если клиентская часть Игры предусмотрена функционалом), тогда как совокупность иных
            данных и команд (включая неактивированные) или все данные и команды в отсутствие клиентской части Игры
            размещаются Лицензиаром на оперируемых им серверах.
          </TextWithNumber>
          <TextWithNumber number="1.2.">
            Интернет сайт Игры — Интернет сайт, расположенный по адресу https://www.readom.pro/, предоставляющий
            Лицензиату доступ к ресурсам Лицензиара, в том числе для использования Лицензиатом Игры. Лицензиар
            размещает информацию, обязательную для Лицензиатов, на Интернет сайте Игры.
          </TextWithNumber>
          <TextWithNumber number="1.3.">
            Игровые ресурсы (Ресурсы) — все сервера, любое программное обеспечение и/или базы данных, имеющие
            отношение к Игре, расположенные, в том числе, в домене readom.pro и его поддоменах.
          </TextWithNumber>
          <TextWithNumber number="1.4.">
            Лицензиар — Индивидуальный предприниматель Меликсетян Сергей Сейранович, который предоставляет право
            использования Игры на условиях неисключительной лицензии.
          </TextWithNumber>
          <TextWithNumber number="1.5.">
            Лицензиат — физическое лицо и/или юридическое лицо, обладающее необходимой дееспособностью для
            заключения настоящего Соглашения, которому в соответствии с настоящим Соглашением предоставляется
            право на использование Игры в предусмотренных настоящим Соглашением пределах. Лицензиат является
            Стороной настоящего Соглашения.
          </TextWithNumber>
          <TextWithNumber number="1.6.">
            Аккаунт Лицензиата (аккаунт) — учетная запись Лицензиата, создаваемая в момент регистрации в Игре,
            позволяющая Лицензиару учитывать каждого Лицензиата и предоставлять возможность авторизоваться
            (получить доступ) посредством уникального логина и пароля.
          </TextWithNumber>
          <TextWithNumber number="1.7.">
            Передача прав использования Игры — предоставление Лицензиаром Лицензиату прав использования Игры на
            условиях простой неисключительной лицензии, в порядке, определенном настоящим Соглашением.
          </TextWithNumber>
          <TextWithNumber number="1.8.">
            Права на неактивированные данные и команды — права, приобретаемые Лицензиатом за плату, позволяющие в
            Игре увеличить количество внутриигровых предметов. Внутриигровые предметы - внутриигровые ценности,
            включая внутриигровую валюту «Ридомы».
          </TextWithNumber>
          <TextWithNumber number="1.9.">
            Вознаграждение — плата Лицензиару за предоставление Лицензиату прав использования неактивированных
            данных и команд в пределах, установленных настоящим Соглашением. Размер вознаграждения определяется
            Лицензиаром, и зависит от объема неактивированнных данных и команд, право использования которых
            передается Лицензиату. Информация о Вознаграждении размещается Лицензиаром в сети Интернет по адресу
            https://www.readom.pro/store
          </TextWithNumber>
          <TextWithNumber number="1.10.">
            Клиентская часть Игры — программное обеспечение, необходимое для участия Лицензиата в Игре, подлежащее
            установке на ЭВМ Лицензиата. Клиентская часть Игры устанавливается Лицензиатом самостоятельно на
            персональном компьютере. Клиентская часть Игры может распространяться Лицензиаром и/или
            уполномоченными им лицами, как посредством сети Интернет, так и на материальных носителях. Клиентская
            часть Игры, распространяемая в сети Интернет, предоставляется Лицензиату безвозмездно, с правом на
            воспроизведение, если настоящим Соглашением не предусмотрено иное. Экземпляры Клиентской части Игры,
            распространяемые на материальных носителях, могут предоставляться Лицензиату за плату.
          </TextWithNumber>
          <TextWithNumber number="1.11.">
            Лицензионное соглашение — текст настоящего Соглашения, заключенного между Лицензиаром и Лицензиатом,
            содержащий все необходимые и существенные условия лицензионного договора о предоставлении прав
            использования Игры как программы для ЭВМ.
          </TextWithNumber>

          <TextWithNumber number="2." center>
            Условия присоединения к настоящему Соглашению
          </TextWithNumber>
          <TextWithNumber number="2.1.">
            Перед тем, как использовать Игру, Лицензиат обязан ознакомиться с настоящим Соглашением, а также со
            всеми применимыми к Игре Правилами и иными документами, которые размещены в свободном доступе на
            странице регистрации учетной записи (аккаунта) в сети Интернет по адресу: https://readom.pro/login/
          </TextWithNumber>
          <TextWithNumber number="2.2.">
            После заполнения обязательных полей и ознакомления с Соглашением Лицензиат присоединяется (принимает)
            настоящее Соглашение путем нажатия кнопки «Зарегистрироваться» или аналогичной, что по смыслу ст.ст.
            435 и 438 Гражданского кодекса РФ является принятием (акцептом) оферты Лицензиара, а равно заключением
            договора, порождающего у Лицензиата обязанности соблюдать условия Соглашения, в том числе применимых к
            Игре Правил. Фактическое использование Игры также является акцептом настоящего Соглашения.
          </TextWithNumber>
          <TextWithNumber number="2.3.">
            Лицо, авторизовавшееся в Игре, считается надлежащим пользователем аккаунта, доступ к использованию и
            управлению которого были получены в результате регистрации аккаунта, если отсутствуют сведения,
            подтверждающие иное.
          </TextWithNumber>

          <TextWithNumber number="3." center>
            Предмет Соглашения
          </TextWithNumber>
          <TextWithNumber number="3.1.">
            По настоящему Соглашению и при условии соблюдения Лицензиатом его соответствующих условий Лицензиар
            предоставляет Лицензиату на условиях простой неисключительной лицензии права использования Игры в
            пределах, определенных настоящим Соглашением.
          </TextWithNumber>
          <TextWithNumber number="3.2.">
            Регистрируя свой номер телефона в аккаунте Игры, Лицензиат соглашается получать от Лицензиара
            sms-сообщения информационного характера в отношении Игры. При этом Лицензиату в аккаунте
            предоставляется возможность осуществлять настройки получения таких sms-сообщений.
          </TextWithNumber>
          <TextWithNumber number="3.3.">
            Лицензиат получает право использования активированных и неактивированных данных и команд на условиях
            настоящего Соглашения.
          </TextWithNumber>
          <TextWithNumber number="3.4.">
            Обязательство Лицензиара по предоставлению прав использования неактивированных данных и команд
            считается исполненным Лицензиаром в момент отражения им внутриигровых ценностей в виде «Ридомов» на
            лицевом счете Лицензиата в Игре. С этого момента Лицензиат получает права использования объема
            неактивированных данных и команд, соответствующего количеству внутриигровых ценностей в виде
            «Ридомов», на условиях данного Соглашения. С момента исполнения такого обязательства любые требования
            Лицензиата в силу ст. 1062 Гражданского кодекса РФ не могут являться предметом судебной защиты.
          </TextWithNumber>
          <TextWithNumber number="3.5.">
            Соответствие объема неактивированных данных и команд количеству внутриигровых ценностей в виде
            «Ридомов» определяется Лицензиаром. Соответствие размера вознаграждения количеству внутриигровых
            ценностей в виде «Ридомов» определяется Лицензиаром.
          </TextWithNumber>
          <TextWithNumber number="3.6.">
            Уплата Вознаграждения не является необходимым условием участия Лицензиата в Игре и осуществляется по
            желанию Лицензиата.
          </TextWithNumber>

          <TextWithNumber number="4." center>
            Пределы использования Игры
          </TextWithNumber>

          <TextWithNumber number="4.1.">
            Лицензиат вправе использовать Игру следующими способами:
          </TextWithNumber>
          <TextWithNumber number="4.1.1.">
            воспроизвести Клиентскую часть Игры путем запуска игры на персональном компьютере, планшете или
            смартфоне с целью участия в Игре;
          </TextWithNumber>
          <TextWithNumber number="4.1.2.">
            использовать активированные данные и команды для достижения определенного сценарием Игры результата;
          </TextWithNumber>
          <TextWithNumber number="4.1.3.">
            активировать неактивированные данные и команды после уплаты Вознаграждения Лицензиару и использовать
            их в том числе для получения возможности достичь определенного сценарием Игры результата быстрее, чем
            при использовании права, указанного в п. 4.1.2;
          </TextWithNumber>
          <TextWithNumber number="4.1.4.">
            вносить изменения в персональные настройки Игры, предусмотренные разработчиком Игры.
          </TextWithNumber>

          <TextWithNumber number="4.2.">
            Лицензиат не вправе:
          </TextWithNumber>
          <TextWithNumber number="4.2.1.">
            переводить Игру на другие языки;
          </TextWithNumber>
          <TextWithNumber number="4.2.2.">
            распространять вне Игры в коммерческих целях аудиовизуальные
            отображения, присутствующие в Игре;
          </TextWithNumber>
          <TextWithNumber number="4.2.3.">
            распространять в коммерческих или некоммерческих целях, передавать
            третьим лицам право использования неактивированных данных и команд, предоставленных Лицензиату за
            Вознаграждение, игровой аккаунт и иной объект, прямо не разрешенный условиями настоящего Соглашения, а
            также распространять информацию о намерениях совершить такие действия;
          </TextWithNumber>
          <TextWithNumber number="4.2.4.">
            передавать предоставленные Лицензиату права использования Игры, как она
            определена в статье 1 настоящего Соглашения, другим Лицензиатам или третьим лицам посредством
            заключения сублицензионного договора или иным способом;
          </TextWithNumber>
          <TextWithNumber number="4.2.5.">
            использовать Игру иными способами, не предусмотренными настоящим
            Соглашением, Правилами Игры и выходящими за рамки обычного игрового процесса.
          </TextWithNumber>

          <TextWithNumber number="5." center>
            Обязанности Лицензиара
          </TextWithNumber>
          <TextWithNumber number="5.1.">
            Лицензиар принимает на себя следующие обязательства:
          </TextWithNumber>
          <TextWithNumber number="5.1.1">
            с учетом условий, изложенных в настоящем Соглашении, обеспечить возможность Лицензиату использовать
            права на Игру;
          </TextWithNumber>
          <TextWithNumber number="5.1.2">
            уведомлять Лицензиата путем опубликования информации на Интернет сайте Игры об изменениях условий
            настоящего Соглашения;
          </TextWithNumber>
          <TextWithNumber number="5.1.3">
            предоставить Лицензиату право безвозмездно использовать активированные данные и команды посредством
            сети Интернет с сайта Игры, если настоящим Соглашением не предусмотрено иное;
          </TextWithNumber>
          <TextWithNumber number="5.1.4">
            предоставить Лицензиату за Вознаграждение право использования неактивированных данных и команд.
          </TextWithNumber>

          <TextWithNumber number="6." center>
            Права Лицензиара
          </TextWithNumber>
          <TextWithNumber number="6.1.">
            Лицензиар имеет следующие права:
          </TextWithNumber>
          <TextWithNumber number="6.1.1.">
            в любое время, в одностороннем порядке ограничить, расширить, изменить содержание Игры как программы
            для ЭВМ без предварительного уведомления Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="6.1.2.">
            приостанавливать либо изменять Игру как программу для ЭВМ, изменять условия их лицензирования без
            предварительного уведомления Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="6.1.3.">
            в любое время изменить, удалить любую информацию, размещенную Лицензиатом на Ресурсах Лицензиара,
            включая высказывания, объявления Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="6.1.4.">
            в любое время приостановить, ограничить и/или прекратить действие настоящего Лицензионного соглашения
            в одностороннем порядке в отношении Игры как программы для ЭВМ для любого или всех Лицензиатов, в том
            числе при несоблюдении Лицензиатом условий настоящего Соглашения.
          </TextWithNumber>
          <TextWithNumber number="6.1.5.">
            в целях сбора статистических данных и идентификации Лицензиата устанавливать и сохранять информацию об
            IP-адресах Лицензиата, использовать файлы технической информации (cookies), размещаемые на
            персональном компьютере Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="6.1.6.">
            рассылать Лицензиатам сообщения информационного или технического характера, связанного с Игрой;
          </TextWithNumber>
          <TextWithNumber number="6.1.7.">
            во время использования Лицензиатами Игры, предупреждать, уведомлять, информировать о несоблюдении
            Лицензиатами условий лицензирования, либо иных условий настоящего Соглашения.
          </TextWithNumber>
          <TextWithNumber number="6.1.8.">
            в любое время изменять, дополнять, модифицировать Игру, любую из ее частей, без какого-либо
            предварительного уведомления Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="6.1.9.">
            предпринимать любые не запрещенные законом меры для защиты собственных интеллектуальных прав в
            отношении Игры.
          </TextWithNumber>

          <TextWithNumber number="7." center>
            Ограничение ответственности Лицензиара
          </TextWithNumber>
          <TextWithNumber number="7.1.">
            Лицензиат использует Ресурсы Лицензиара, Игру, на собственный страх и риск. Игра и права на ее
            использование передаются в состоянии «как есть».
          </TextWithNumber>
          <TextWithNumber number="7.2.">
            Лицензиар не несет ответственности за возможные противоправные действия Лицензиата или третьих лиц.
          </TextWithNumber>
          <TextWithNumber number="7.3.">
            Лицензиар не несет ответственности за отсутствие у Лицензиата доступа в Интернет, за качество услуг
            провайдеров связи сети Интернет, с которыми Лицензиатом заключены соглашения о предоставлении услуг по
            доступу к сети Интернет.
          </TextWithNumber>
          <TextWithNumber number="7.4.">
            Лицензиар не возмещает Лицензиату расходы, связанные с уплатой Лицензиатом Вознаграждения, в том числе
            в случае приостановления или прекращения настоящего Соглашения по любым основаниям, если иное прямо не
            предусмотрено применимым законодательством.
          </TextWithNumber>
          <TextWithNumber number="7.5.">
            Лицензиар не гарантирует, что:
          </TextWithNumber>
          <TextWithNumber number="7.5.1.">
            Игра будет удовлетворять субъективным требованиям и ожиданиям Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="7.5.2.">
            использование Игры будет протекать непрерывно, быстро, без технических сбоев, надежно и без ошибок;
          </TextWithNumber>
          <TextWithNumber number="7.5.3.">
            результаты, которые могут быть получены при использовании Игры, будут безошибочными;
          </TextWithNumber>
          <TextWithNumber number="7.5.4.">
            Игра как программа для ЭВМ будет соответствовать ожиданиям Лицензиата;
          </TextWithNumber>
          <TextWithNumber number="7.5.5.">
            Игра будет доступна и может использоваться круглосуточно, в какой-то конкретный момент времени или в
            течение какого-либо периода времени.
          </TextWithNumber>
          <TextWithNumber number="7.6.">
            Лицензиар не несет ответственности за возникновение прямого или косвенного ущерба Лицензиата либо иных
            третьих лиц, причиненного в результате:
          </TextWithNumber>
          <TextWithNumber number="7.6.1.">
            использования либо невозможности использования Ресурсов Лицензиара;
          </TextWithNumber>
          <TextWithNumber number="7.6.2.">
            несанкционированного доступа любых третьих лиц к личной информации Лицензиата, включая учетную запись
            Лицензиата, лицевой счет Лицензиата в Игре;
          </TextWithNumber>
          <TextWithNumber number="7.6.3.">
            заявления или поведения любого третьего лица на Ресурсах Лицензиара.
          </TextWithNumber>
          <TextWithNumber number="7.7.">
            При любых обстоятельствах ответственность Лицензиара перед Лицензиатом ограничена суммой в 3 000 (три
            тысячи) рублей, если иное прямо не предусмотрено применимым законодательством.
          </TextWithNumber>
          <TextWithNumber number="7.8.">
            Лицензиар не обязан предоставлять Лицензиату какие-либо доказательства, документы и прочее,
            свидетельствующие о нарушении Лицензиатом условий Соглашения, в результате которого действие
            настоящего Соглашения было приостановлено или прекращено.
          </TextWithNumber>
          <TextWithNumber number="7.9.">
            Лицензиат понимает, принимает и соглашается, что Игра может предусматривать различные звуковые и/или
            видео эффекты, которые, при определенных обстоятельствах, могут вызывать у лиц, склонных к
            эпилептическим или иным расстройствам нервного характера, обострение указанных состояний, и Лицензиат
            гарантирует, что указанными расстройствами он не страдает, или же обязуется Игру не использовать.
          </TextWithNumber>
          <TextWithNumber number="7.10.">
            Лицензиат, принимает и соглашается, что регулярное длительное (непрерывное) нахождение у персонального
            компьютера может вызывать различные осложнения физического состояния, в том числе ослабление зрения,
            сколиоз, различные формы неврозов и прочие негативные воздействия на организм. Лицензиат гарантирует,
            что он будет использовать Игру исключительно на протяжении разумного времени, с перерывами на отдых
            или иные мероприятия по профилактике физического состояния, если таковые Лицензиату рекомендованы или
            предписаны.
          </TextWithNumber>

          <TextWithNumber number="8." center>
            Обязанности Лицензиата
          </TextWithNumber>
          <TextWithNumber number="8.1.">
            Лицензиат обязан:
          </TextWithNumber>
          <TextWithNumber number="8.1.1.">
            соблюдать условия настоящего Соглашения, без каких-либо ограничений;
          </TextWithNumber>
          <TextWithNumber number="8.1.2.">
            в момент регистрации на Ресурсах Лицензиара указывать достоверную информацию;
          </TextWithNumber>
          <TextWithNumber number="8.1.3.">
            не превышать пределов использования Игры, установленных в разделе 2 настоящего Соглашения;
          </TextWithNumber>
          <TextWithNumber number="8.1.4.">
            не нарушать иным образом права интеллектуальной собственности Лицензиара в отношении Игры и/или
            каких-либо составляющих Ресурсов Лицензиара, в частности, Лицензиат не имеет права копировать,
            транслировать, рассылать, публиковать, и иным образом распространять и воспроизводить материалы
            (текстовые, графические, аудио-видео), находящиеся в составе Игровых ресурсов без письменного согласия
            Лицензиара;
          </TextWithNumber>
          <TextWithNumber number="8.1.5.">
            самостоятельно предпринимать должные меры, обеспечивающие безопасность его учетных записей в Игре и
            предотвращающие несанкционированное пользование третьими лицами этими учетными записями;
          </TextWithNumber>
          <TextWithNumber number="8.1.6.">
            выполнять указания Лицензиара, в частности, данные Лицензиаром Лицензиату или группе Лицензиатов в
            процессе использования Игры, в центре поддержки пользователей (Лицензиатов), в новостном разделе
            Интернет сайта Игры, на сайте Лицензиара. В случае невыполнения Лицензиатом таких указаний Лицензиар
            имеет право приостановить, ограничить, прекратить предоставление Лицензиату прав использования Игры;
          </TextWithNumber>
          <TextWithNumber number="8.1.7.">
            по требованию Лицензиара в связи с заключением и исполнением настоящего Соглашения подтвердить свои
            данные, включающие фамилию, имя, отчество, иные данные;
          </TextWithNumber>
          <TextWithNumber number="8.1.8.">
            соблюдать иные требования и выполнять иные обязательства, предусмотренные настоящим Соглашением.
          </TextWithNumber>
          <TextWithNumber number="8.2">
            Лицензиат гарантирует, что обладает всеми необходимыми полномочиями для заключения настоящего
            Соглашения. В случае, если Лицензиат не достиг возраста совершеннолетия (18 лет) или стал полностью
            недееспособным в силу наступления иного обстоятельства, предусмотренного действующим
            законодательством, он обязан самостоятельно получить необходимое разрешение в требуемой законом форме
            от своих родителей, либо иных законных представителей.
          </TextWithNumber>
          <TextWithNumber number="8.3">
            Иные обязательства Лицензиата предусмотрены в разделе 7 настоящего Соглашения.
          </TextWithNumber>

          <TextWithNumber number="9." center>
            Права использования неактивированных данных и команд
          </TextWithNumber>
          <TextWithNumber number="9.1">
            Настоящий раздел Соглашения регламентирует порядок и условия предоставления Лицензиаром Лицензиату
            прав использования неактивированных данных и команд.
          </TextWithNumber>
          <TextWithNumber number="9.2">
            По желанию Лицензиата Лицензиар предоставляет Лицензиату право использования неактивированных данных и
            команд.
          </TextWithNumber>
          <Text>
            Право использования неактивированных данных и команд предоставляется Лицензиату на возмездной основе
            (за Вознаграждение) на условиях простой неисключительной лицензии.
          </Text>
          <Text>
            Лицензиат получает права использования неактивированных данных и команд после внесения обусловленного
            Соглашением Вознаграждения, а именно после отражения Лицензиаром внутриигровых ценностей в виде
            «Ридомов» на лицевом счете Лицензиата в Игре.
          </Text>
          <Text>
            Право использования неактивированных данных и команд предоставляется Лицензиату на срок действия
            настоящего Соглашения, если такое право не прекращено ранее, в том числе в связи с реализацией
            сценария Игры.
          </Text>
          <Text>
            Размер Вознаграждения может быть изменен Лицензиаром в одностороннем порядке в любое время без
            предварительного уведомления Лицензиата. Лицензиат осознает, что в ходе использования Игры Лицензиар
            имеет право отказать Лицензиату в использовании прав, включая право использования неактивированных
            данных и команд, по любым причинам, и это не дает Лицензиату основание требовать от Лицензиара
            возврата ранее уплаченного Вознаграждения, если иное прямо не предусмотрено применимым
            законодательством.
          </Text>
          <TextWithNumber number="9.3.">
            При регистрации Лицензиата для осуществления использования Игры Лицензиар автоматически заводит
            лицевой счет Лицензиата, который привязан непосредственно к учетной записи Лицензиата.
          </TextWithNumber>
          <TextWithNumber number="9.4.">
            Порядок пополнения лицевого счета, а также способы и условия внесения Вознаграждения через платежные
            системы публикуются Лицензиаром на Интернет сайте Игры: https://www.readom.pro/store. Перечисление
            денежных средств осуществляется Лицензиатом через поддерживаемые платежные системы на расчетный счет
            Лицензиара. Список доступных для оплаты платежных систем приведен на Интернет сайте Лицензиара.
            Основные положения Соглашения о способах и условиях оплаты применимы также к оплате Подписки (при ее
            наличии).
          </TextWithNumber>
          <TextWithNumber number="9.5.">
            При оплате Вознаграждения Лицензиат обязуется следовать платежным инструкциям по порядку и способам
            оплаты, в том числе и по правилам ввода сообщения и номера коротких текстовых сообщений (SMS), включая
            порядок ввода заглавных и строчных букв, цифр и языка ввода. Лицензиар не несет ответственности за
            правильность выполнения Лицензиатом условий проведения оплаты. По вопросам правил и порядка
            использования платежных систем для осуществления пополнения лицевого счета Лицензиату надлежит
            обращаться к юридическим лицам — держателям таких платежных систем. Лицензиар не дает Лицензиату
            разъяснений по вопросам, связанным с правилами и порядком использования таких платежных систем, а
            также не уплачивает Лицензиату компенсацию денежных средств, уплаченных в качестве лицензионного
            Вознаграждения за право использования неактивированных данных и команд посредством платежных систем,
            если такие платежи были осуществлены с нарушениями правил, установленных платежными системами, в
            результате чего денежные средства не поступили Лицензиару.
          </TextWithNumber>
          <TextWithNumber number="9.6.">
            До получения подтверждения об уплате Вознаграждения Лицензиар вправе не предоставлять Лицензиату право
            использования неактивированных данных и команд, либо предоставлять такое право в ограниченном объеме.
          </TextWithNumber>
          <TextWithNumber number="9.7.">
            В том случае, если в результате технической ошибки, сбоя работы Игры, сознательных действий Лицензиата
            им была получена возможность использования неактивированных данных и команд не в установленном
            настоящим Соглашением порядке, Лицензиат обязуется сообщить об этом факте Лицензиару и уплатить
            Лицензиару Вознаграждение, либо устранить все последствия неправомерного использования
            неактивированных данных и команд. Лицензиар вправе самостоятельно без уведомления Лицензиата устранить
            такие последствия.
          </TextWithNumber>
          <TextWithNumber number="9.8.">
            Лицензиат обязан сохранять документы, подтверждающие уплату Вознаграждения, в течение всего времени
            использования Лицензиатом Игры, и по запросу Лицензиара предоставить ему такие документы, а также
            информацию об обстоятельствах совершения такого платежа Лицензиатом.
          </TextWithNumber>
          <TextWithNumber number="9.9.">
            Лицензиат обязан самостоятельно отслеживать состояние своего лицевого счета.
          </TextWithNumber>
          <TextWithNumber number="9.10.">
            Исключительное право распространения и использования Игры принадлежит Лицензиару, в связи с чем
            никакие предложения третьих лиц о предоставлении права использования неактивированных данных и команд
            не могут быть расценены Лицензиатом как предложения, исходящие от Лицензиара.
          </TextWithNumber>
          <Text>
            В случае возникновения спорных, неясных ситуаций, либо направления Лицензиату каких-либо предложений
            третьих лиц, связанных с уплатой Вознаграждения за право использования неактивированных данных и
            команд, либо размещения таких объявлений и предложений в сети Интернет, за исключением Ресурсов
            Лицензиара и размещенных от имени Лицензиара, Лицензиат обязан незамедлительно уведомить об этом
            Лицензиара.
          </Text>
          <Text>
            В случае, если Лицензиат в нарушение настоящего положения, произвел оплату по указанному объявлению с
            использованием реквизитов, указанных в таком объявлении, претензии Лицензиата Лицензиару по поводу
            отсутствия у Лицензиата права использования неактивированных данных и команд не принимаются, и
            Лицензиар не компенсирует Лицензиату денежные средства, потраченные Лицензиатом при таких
            обстоятельствах.
          </Text>
          <TextWithNumber number="9.11.">
            В случае, если Лицензиаром будет установлено, что Лицензиат получает возможность использования
            неактивированных данных и команд от третьих лиц, Лицензиар вправе по своему усмотрению либо
            приостановить, либо ограничить действие Соглашения.
          </TextWithNumber>
          <TextWithNumber number="9.12.">
            Получение Лицензиатом права использования неактивированных данных и команд не освобождает Лицензиата
            от соблюдения настоящего Соглашения и Правил Игры, и применения в отношении него любых мер, указанных
            в настоящем Соглашении или Правилах Игры.
          </TextWithNumber>
          <TextWithNumber number="9.13.">
            Лицензиат гарантирует Лицензиару, что он имеет право использовать выбранные им средства для уплаты
            Вознаграждения за право использования неактивированных данных и команд, не нарушая при этом
            законодательства РФ и/или законодательства иной страны, гражданином которой является Лицензиат, и прав
            третьих лиц. Лицензиар не несет ответственности за возможный ущерб третьим лицам и/или другим
            Лицензиатам, причиненный в результате использования Лицензиатом не принадлежащих ему средств оплаты.
          </TextWithNumber>
          <TextWithNumber number="9.14.">
            Лицензиар не несет ответственности за возможные противоправные действия Лицензиата при уплате
            Вознаграждения за право использования неактивированных данных и команд. Лицензиар оставляет за собой
            право в одностороннем порядке отозвать лицензию на Игру и (или) права использования неактивированных
            данных и команд, если существует подозрение на совершение Лицензиатом противозаконных действий, вплоть
            до выяснения обстоятельств.
          </TextWithNumber>
          <TextWithNumber number="9.15.">
            В случае если Лицензиар имеет основания полагать, что Лицензиат совершает противозаконные действия,
            связанные с уплатой Вознаграждения за право использования неактивированных данных и команд, Лицензиар
            имеет право передать соответствующую информацию в правоохранительные органы для проведения проверки по
            данному факту.
          </TextWithNumber>
          <TextWithNumber number="9.16.">
            Особенности уплаты Лицензиатом лицензионного Вознаграждения за право использования неактивированных
            данных и команд с помощью банковских карт.
          </TextWithNumber>
          <TextWithNumber number="9.16.1.">
            Операции по банковским картам совершаются держателем карты либо уполномоченным им лицом.
          </TextWithNumber>
          <TextWithNumber number="9.16.2.">
            Авторизация операций по банковским картам осуществляется банком. Если у банка есть основания полагать,
            что операция носит противоправный характер, то банк вправе отказать в осуществлении данной операции.
            Мошеннические операции с банковскими картами являются уголовным преступлением.
          </TextWithNumber>
          <TextWithNumber number="9.16.3.">
            Во избежание случаев мошенничества при оплате банковскими картами, платежи, оплаченные банковской
            картой, могут проверяться Лицензиаром. Лицензиат-держатель карты, оформивший такой платеж, обязан по
            запросу, поступившему от Лицензиара, предоставить копию необходимых Лицензиару документов для
            подтверждения правомерного использования банковской карты. В случае непредставления запрошенных
            документов Лицензиатом в течение 14 дней с даты оформления платежа или наличия сомнений в их
            подлинности, Лицензиар вправе приостановить предоставление Лицензиату прав использования Игры до
            выяснения обстоятельств.
          </TextWithNumber>
          <TextWithNumber number="9.17.">
            Лицензиат самостоятельно и за свой счет несет все расходы, связанные с перечислением денежных средств
            Лицензиару, включая различные сборы и комиссии банков и операторов платежных систем.
          </TextWithNumber>
          <TextWithNumber number="9.18.">
            Лицензиат соглашается, понимает и принимает то обстоятельство, что Игра не является азартной игрой,
            игрой на деньги, конкурсом, пари. Приобретение лицензии на право использования неактивированных данных
            и команд является реализацией его собственного волеизъявления и желания и не является необходимым или
            обязательным условием для использования Игры установленными данным Соглашением способами.
          </TextWithNumber>

          <TextWithNumber number="10." center>
            Территория и срок действия Соглашения
          </TextWithNumber>
          <TextWithNumber number="10.1.">
            Лицензиат вправе использовать Игру способами, описанными в настоящем Соглашении, на всей территории
            Российской Федерации, а также иных территориях, на которых она доступна в рамках обычного игрового
            процесса с использованием стандартных компьютерных средств и программ в рамках функционала Ресурсов
            Лицензиара.
          </TextWithNumber>
          <TextWithNumber number="10.2.">
            Настоящее Соглашение действует с момента принятия его условий Лицензиатом и действует в течение 1
            (одного) календарного года.
          </TextWithNumber>
          <TextWithNumber number="10.3.">
            Действие настоящего Соглашения автоматически продлевается еще на каждый последующий 1 (один) год, если
            до истечения указанного срока действия Соглашения:
          </TextWithNumber>
          <TextWithNumber number="10.3.1.">
            Лицензиар не примет решение об изменении положений настоящего Соглашения, о необходимости заключения с
            Лицензиатами нового соглашения, прекращении обслуживания Игры, прекращении настоящего Соглашения в
            отношении Лицензиата.
          </TextWithNumber>
          <TextWithNumber number="10.3.2.">
            Лицензиат не примет решение о прекращении использования Игры.
          </TextWithNumber>
          <TextWithNumber number="10.4.">
            Если иное прямо не предусмотрено применимым законодательством, Лицензиар вправе в любое время без
            уведомления Лицензиата и без объяснения причин прекратить настоящее Соглашение в одностороннем
            внесудебном порядке и без возмещения каких-либо затрат, убытков или возврата, полученного по
            Соглашению, в том числе в случае:
          </TextWithNumber>
          <TextWithNumber number="10.4.1.">
            закрытия Игры, т.е. прекращение ее оперирования Лицензиаром;
          </TextWithNumber>
          <TextWithNumber number="10.4.2.">
            любого, в том числе однократного, нарушения Лицензиатом условий настоящего Соглашения или Правил Игры.
          </TextWithNumber>
          <TextWithNumber number="10.5.">
            Лицензиар вправе в любое время без уведомления Лицензиата и без объяснения причин приостановить или
            прекратить действие настоящего Соглашения без возмещения каких-либо затрат, убытков или возврата,
            полученного по Соглашению, в том числе в случае любого, в том числе однократного, нарушения
            Лицензиатом условий настоящего Соглашения или Правил Игры, если иное прямо не предусмотрено применимым
            законодательством.
          </TextWithNumber>
          <TextWithNumber number="10.6.">
            Лицензиат вправе в любое время без уведомления Лицензиара и без объяснения причин прекратить настоящее
            Соглашение в одностороннем внесудебном порядке путем удаления игрового аккаунта.
          </TextWithNumber>
          <TextWithNumber number="10.7.">
            Лицензиат соглашается и полностью признает, что все исключительные права на локализованную
            (переведенную на соответствующий язык) Игру, включая игровых персонажей, игровые предметы и
            аксессуары, игровые монеты, внутриигровые ценности, графические изображения, фотографии, анимации,
            видеоизображения, видеоклипы, звуковые записи, звуковые эффекты, музыку, текстовое наполнение Игры и
            иные составляющие Игры, принадлежат Лицензиару, если иное в явном виде не указано в Соглашении, на
            Интернет сайте Игры или в самой Игре.
          </TextWithNumber>
          <TextWithNumber number="10.8.">
            Лицензиат не вправе использовать отдельные элементы Игры, вне Игры без письменного согласия
            Лицензиара.
          </TextWithNumber>
          <TextWithNumber number="10.9.">
            Лицензиат понимает, принимает и соглашается, что любой элемент Игры, в частности, любые игровые
            персонажи, являются составляющей частью Игры как программы для ЭВМ и охраняются авторским правом.
            Несмотря на то, что Лицензиату предоставляется право использования активированных данных и команд и
            может быть предоставлено право использования неактивированных данных и команд, и в процессе
            использования Игры разрешается управление такими игровыми персонажами, включая развитие таких
            персонажей по сценарию Игры, такое управление и развитие персонажа не является и не может быть
            расценено ни при каких обстоятельствах как передача и/или уступка исключительного права в отношении
            данного игрового персонажа от Лицензиара к Лицензиату. Такое управление и развитие персонажа также не
            является и не может расцениваться как авторство Лицензиата в отношении игрового персонажа и/или
            соавторство Лицензиата и Лицензиара в отношении игрового персонажа.
          </TextWithNumber>
          <TextWithNumber number="10.10.">
            Настоящее Соглашение не предусматривает уступку каких-либо исключительных прав или выдачу
            исключительной лицензии на любые составляющие Игры и/или Игровые Ресурсы от Лицензиара к Лицензиату,
            уступку Лицензиатом прав использования Игры.
          </TextWithNumber>
          <TextWithNumber number="10.11.">
            В случае, если Лицензиату в соответствии с законами его государства запрещено пользоваться
            компьютерными играми в режиме онлайн или существуют иные законодательные ограничения, включая
            ограничения по возрасту допуска к такому программному обеспечению, Лицензиат не вправе использовать
            Игру. В таком случае Лицензиат самостоятельно несет ответственность за использование Игры на
            территории своего государства в нарушение местного законодательства.
          </TextWithNumber>
          <TextWithNumber number="10.12.">
            Настоящее Соглашение может быть изменено Лицензиаром без какого-либо предварительного уведомления.
            Любые изменения в Соглашении, осуществленные Лицензиаром в одностороннем порядке вступают в силу в
            день, следующий за днем опубликования таких изменений на Интернет сайте Лицензиара. Лицензиат
            обязуется самостоятельно проверять Соглашение на предмет изменений. Неосуществление Лицензиатом
            действий по ознакомлению с Соглашением и/или измененной редакцией Соглашения не может служить
            основанием для неисполнения Лицензиатом своих обязательств и несоблюдения Лицензиатом ограничений,
            установленных Соглашением.
          </TextWithNumber>
          <TextWithNumber number="10.13.">
            Недействительность одного или нескольких положений Соглашения, признанная в установленном порядке,
            вступившим в силу решением суда, не влечет для Сторон недействительности соглашения в целом. В случае
            признания одного или нескольких положений Соглашения в установленном порядке недействительными,
            Стороны обязуются исполнять взятые на себя по Соглашению обязательства максимально близким к
            подразумеваемым Сторонами при заключении и/или согласованном изменении Соглашения способом.
          </TextWithNumber>
          <TextWithNumber number="10.14.">
            Настоящее Соглашение и взаимоотношения Сторон в связи с настоящим Соглашением и использованием Игры
            регулируются законодательством Российской Федерации.
          </TextWithNumber>
          <TextWithNumber number="10.15.">
            В отношении формы и способа заключения настоящего Соглашения применяются нормы Гражданского Кодекса РФ
            («ГК РФ»), регулирующие порядок и условия заключения договора путем акцепта публичной оферты.
          </TextWithNumber>
          <TextWithNumber number="10.16.">
            Все споры сторон по настоящему соглашению подлежат разрешению путем переписки и переговоров с
            использованием обязательного досудебного (претензионного) порядка. В случае невозможности достичь
            согласия между сторонами путем переговоров в течение 60 (шестидесяти) календарных дней с момента
            получения другой Стороной письменной претензии, рассмотрение спора должно быть передано любой
            заинтересованной стороной в суд общей юрисдикции по месту нахождения Лицензиара (с исключением
            подсудности дела любым иным судам), если иное прямо не предусмотрено действующим законодательством РФ.
          </TextWithNumber>
          <TextWithNumber number="10.17.">
            По вопросам, связанным с исполнением Соглашения, просьба обращаться по адресу местонахождения
            Лицензиара: Российская Федерация, 141435, Московская обл., г. Химки, Микрорайон Новогорск, ул.
            Заречная, д. 7, корп. 1, кв. 7. Почтовый адрес: 630004 г. Новосибирск, ул. Вокзальная магистраль, 1/1,
            офис 608.
          </TextWithNumber>
        </TextBlock>
      </TextWrapper>
    </Content>
  </Section>
);

export default Licence;
