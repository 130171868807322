import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';

const storeSlice = state => state.get(STORE_NAME);

const makeSelectSpotlightTarget = () => createSelector(
  storeSlice,
  state => state.get('target'),
);

const makeSelectSpotlightVisible = () => createSelector(
  storeSlice,
  state => state.get('visible'),
);

export {
  makeSelectSpotlightTarget,
  makeSelectSpotlightVisible,
};
