import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import { makeSelectProfile } from '../../../modules/profile/selectors';
import { apiRequest as createApiRequest } from '../../../../utils/request';

import { makeSelectApiUrl } from '../../../modules/global/selectors';

import { PROFILE_REQUEST_CHANGE } from '../../../modules/profile/constants';
import { changeProfileInfoRequestSuccess, changeProfileInfoRequestFail } from '../../../modules/profile/actions';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* changeProfile() {
    const url = yield select(makeSelectApiUrl());
    const profile = yield select(makeSelectProfile());
    try {
      if (profile.isEmployer) {
        const editEmployeeURL = `${url}/EmployeeEdit/${encodeURIComponent(profile.employeeID)}/${encodeURIComponent(profile.surname)}/${encodeURIComponent(profile.name)}/${encodeURIComponent(profile.middlename)}/${encodeURIComponent(profile.email)}/${encodeURIComponent(!profile.showMeInRating)}`;
        yield call(apiRequest, editEmployeeURL);
      } else {
        const requestURL = `${url}/UserProfile/${encodeURIComponent(profile.surname)}/${encodeURIComponent(profile.name)}/${encodeURIComponent(profile.middlename)}/${encodeURIComponent(profile.currencyID)}`;
        const config = {
          method: 'post',
        };
        yield call(apiRequest, requestURL, config);
      }
      // yield call(editEmployeeURL, requestURL);

      // function TReadom.EmployeeEdit(EmployeeID, SurName, Name, MiddleName, EMail: String; HideInRating: Boolean): TJSONObject;

      // const isEmployee = !!profile.employeeID;
      //
      // let departmentURL = false;
      // if (isEmployee) {
      //   departmentURL = `${url}/EmployeeDepartmentUpdate/${profile.employeeID}/${profile.departament}`;
      // }

      // function SendRequestUpdateEmail(Email, Password: String): TJSONObject;
      // { Изменить почтонвый ящик пользоватеял или сотрудника }
      // function UpdateEmail(ID: String; IsEmployee: Boolean; EMail, Password: String): TJSONObject;

      // SendRequestRemindPassword(EMail: String)
      // UpdateRemindPassword(ID: String; IsEmployee: Boolean; NewPassword: String)

      // if (isEmployee) {
      //   yield call(apiRequest, departmentURL);
      // }

      yield put(changeProfileInfoRequestSuccess());
    } catch (err) {
      yield put(changeProfileInfoRequestFail());
    }
  }

  function* saga() {
    yield takeLeading(PROFILE_REQUEST_CHANGE, changeProfile);
  }

  return saga;
}

export default build;
