import styled from 'styled-components';

const LineItem = styled.div`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 50px;
  font-weight: bold;
  margin: 8px 0 24px;
`;

export default LineItem;
