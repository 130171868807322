import { createSelector } from 'reselect';
import { STORE_NAME } from './constants';
import { emptyData } from './reducer';

const personalGameHeadRatingForActiveGame = (state, { activeGameId }) => (
  state.get(STORE_NAME).get('personalGameHeadRating').get(activeGameId)
);

const commonGameRatingForActiveGame = (state, { activeGameId }) => (
  state.get(STORE_NAME).get('commonGameRating').get(activeGameId)
);

const companyGameHeadRatingForActiveGame = (state, { activeGameId }) => (
  state.get(STORE_NAME).get('companyGameHeadRating').get(activeGameId)
);

const companyGameRatingForActiveGame = (state, { activeGameId }) => (
  state.get(STORE_NAME).get('companyGameRating').get(activeGameId)
);

const extendedEmployeeRatingForActiveGame = (state, { activeGameId, employeeId }) => {
  const tmp = state.get(STORE_NAME).get('extendedEmployeeRating').get(activeGameId);

  if (!tmp) {
    return null;
  }

  return tmp.get(employeeId);
};

const makeSelectPersonalGameHeadRatingForActiveGame = () => createSelector(
  personalGameHeadRatingForActiveGame,
  (slice) => {
    if (!slice) {
      return emptyData;
    }

    return slice.toJS();
  },
);

const makeSelectCommonGameRatingForActiveGame = () => createSelector(
  commonGameRatingForActiveGame,
  (slice) => {
    if (!slice) {
      return emptyData;
    }

    return slice.toJS();
  },
);

const makeSelectCompanyGameHeadRatingForActiveGame = () => createSelector(
  companyGameHeadRatingForActiveGame,
  (slice) => {
    if (!slice) {
      return emptyData;
    }

    return slice.toJS();
  },
);

const makeSelectCompanyGameRatingForActiveGame = () => createSelector(
  companyGameRatingForActiveGame,
  (slice) => {
    if (!slice) {
      return emptyData;
    }

    return slice.toJS();
  },
);

const makeSelectExtendedEmployeeRatingForActiveGame = () => createSelector(
  extendedEmployeeRatingForActiveGame,
  (slice) => {
    if (!slice) {
      return emptyData;
    }

    return slice.toJS();
  },
);

const storeSlice = state => state.get(STORE_NAME);

const makeSelectActiveRatingTab = () => createSelector(
  storeSlice,
  state => state.get('activeRatingTab'),
);

export {
  makeSelectPersonalGameHeadRatingForActiveGame,
  makeSelectCommonGameRatingForActiveGame,
  makeSelectCompanyGameHeadRatingForActiveGame,
  makeSelectCompanyGameRatingForActiveGame,
  makeSelectExtendedEmployeeRatingForActiveGame,
  makeSelectActiveRatingTab,
};
