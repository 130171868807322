import {
  GET_BOOK_FRAGMENT,
} from './constants';

export function getBookFragment({ email, formik }) {
  return {
    type: GET_BOOK_FRAGMENT,
    email,
    formik,
  };
}
