import find from 'lodash/find';
import get from 'lodash/get';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { connect } from 'react-redux';
import withProps from 'recompose/withProps';
import withHandlers from 'recompose/withHandlers';
import { createStructuredSelector } from 'reselect';
import { makeSelectActiveGameId } from '../../../redux/modules/activeGame/selectors';
import { trackBuyGameButtonClicked } from '../../../redux/modules/analytics/actions';
import { makeSelectBrowser } from '../../../redux/modules/browser/selectors';
import { makeSelectOwnedGameStage } from '../../../redux/modules/buyGame/selectors';
import { getFinishPageReport } from '../../../redux/modules/finishPage/actions';
import {
  makeSelectFinishPageReport, makeSelectFinishPageReportFail,
  makeSelectFinishPageReportPending,
} from '../../../redux/modules/finishPage/selectors';
import {
  makeSelectGames,
  makeSelectGamesShowcases,
} from '../../../redux/modules/games/selectors';
import {
  getGameInfo,
  startSuperGame,
  changeStavka,
  changeSupergame,
  startNewGame,
} from '../../../redux/modules/myGame/actions';
import {
  makeSelectReceivedKeysData,
  makeSelectSaveTip,
  makeSelectStavka,
} from '../../../redux/modules/myGame/selectors';
import { setBuyGamePopupOpened } from '../../../redux/modules/popups/actions';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';
import URL_CONSTANTS from '../../../urlConstants';

const mapDispatchToProps = dispatch => ({
  trackBuyGameButtonClicked: (showcaseId, where, inGame) => dispatch(trackBuyGameButtonClicked(showcaseId, where, inGame)),
  setBuyGamePopupOpened: opened => dispatch(setBuyGamePopupOpened(opened)),
  onStartNewGameClick: () => dispatch(startNewGame()),
  onChangeStavka: newStavkaState => dispatch(changeStavka(newStavkaState)),
  onChangeSupergame: newSupergameState => dispatch(changeSupergame(newSupergameState)),
  getGameInfo: () => dispatch(getGameInfo()),
  onStartNewSuperGame: () => dispatch(startSuperGame()),
  getFinishPageReport: () => dispatch(getFinishPageReport()),
});

const mapStateToProps = createStructuredSelector({
  activeGameId: makeSelectActiveGameId(),
  stavkaState: makeSelectStavka(),
  profile: makeSelectProfile(),
  ownedStage: makeSelectOwnedGameStage(),
  receivedKeysData: makeSelectReceivedKeysData(),
  browser: makeSelectBrowser(),
  saveTip: makeSelectSaveTip(),
  report: makeSelectFinishPageReport(),
  reportPending: makeSelectFinishPageReportPending(),
  reportFail: makeSelectFinishPageReportFail(),
  games: makeSelectGames(),
  gamesShowcases: makeSelectGamesShowcases(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withLifecycle = lifecycle({
  componentDidMount() {
    this.props.getGameInfo();
    this.props.getFinishPageReport();
  },
});

const withOnGameBuy = withHandlers({
  onGameBuy: props => () => {
    props.setBuyGamePopupOpened(true);
    props.trackBuyGameButtonClicked(null, 'trial-finish', true);
  },
});

const withAllGamesProps = withProps((props) => {
  const {
    games, gamesShowcases,
  } = props;

  let allGames = false;
  if (games && gamesShowcases) {
    allGames = [...games, ...gamesShowcases];
  }

  return {
    allGames,
  };
});

const withGameProps = withProps((props) => {
  const { activeGameId, allGames } = props;
  const currentGameMeta = find(allGames, { GameID: activeGameId }, { name: '', description: '' });

  const gameName = get(currentGameMeta, 'Name');
  const gameShortName = get(currentGameMeta, 'NameSys');
  let gameUrl = URL_CONSTANTS.URLS.GAMES_STORE;
  if (gameShortName) {
    gameUrl = `${URL_CONSTANTS.PREFIXES.GAME_OVERVIEW}/${gameShortName}`;
  }

  // Report_FinishGame_Bottom1: "readomreport.mvk_bottom_1"
  // Report_FinishGame_Bottom2: "readomreport.mvk_bottom_2"
  // Report_FinishGame_Top1: "readomreport.mvk_top_1"
  // Report_FinishGame_Top2: "readomreport.mvk_top_2"

  const isTrialGame = get(currentGameMeta, 'IsTrialPermission', false)
    && !get(currentGameMeta, 'HasPermission', false);

  const gameOptionsValues = get(currentGameMeta, 'OptionsValues');

  const superGameAvailable = !!get(gameOptionsValues, 'superGameAvailable');
  const showPresettingScreen = !!get(gameOptionsValues, 'showPresettingScreen');

  const loading = props.reportPending;

  return {
    loading,
    gameUrl,
    gameName,
    isTrialGame,
    superGameAvailable,
    showPresettingScreen,
  };
});

const enhance = compose(
  withConnect,
  withAllGamesProps,
  withOnGameBuy,
  withLifecycle,
  withGameProps,
);

export default enhance;
