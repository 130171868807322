import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';

const A = styled(Link)`
  color: #1C1C1C;
  display: block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: min-content;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s ease-out;

  &:hover {
    color: ${darken(0.1, '#35BB9A')};
  }
`;

export default A;
