import isNull from 'lodash/isNull';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import find from 'lodash/find';
import {
  Formik, Field, ErrorMessage,
} from 'formik';
import Error from '../../../components/Error';
import { validator } from '../../../utils/validation';
import { getDepartmentList } from '../../../redux/modules/employees/actions';
import { makeSelectDepartments } from '../../../redux/modules/employees/selectors';
import {
  changeName,
  changeSurname,
  changeNickname,
  changeEmail,
  changeDepartament,
  changePosition,
  changeProfileInfoRequest, changePatronymic, changeShowMeInRating,
} from '../../../redux/modules/profile/actions';
import { makeSelectPopupState } from '../../../redux/modules/global/selectors';
import { makeSelectProfile, makeSelectProfileAcl } from '../../../redux/modules/profile/selectors';

import Checkbox from '../../../components/Checkbox';
import PopupForm from '../templates/PopupForm/Form';
import FormColumnLine from '../templates/PopupForm/FormColumnLine';
import FormItem from '../templates/PopupForm/FormItem';
import FormLine from '../templates/PopupForm/FormLine';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Popup from '../../../components/Popup';

const ErrorWrapper = styled.div`
  position: absolute;
`;

const renderField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const isValid = !errors[field.name];

  return (
    <FormItem>
      <Input isValid={isValid} {...field} {...props} />
      <ErrorWrapper>
        <Error>
          <ErrorMessage name={field.name} component="span" />
        </Error>
      </ErrorWrapper>
    </FormItem>
  );
};

const renderCheckBox = ({
  field, form: {
    touched, errors, submitCount, setFieldValue,
  }, ...props
}) => {
  const isValid = !errors[field.name];

  return (
    <>
      <Checkbox
        isValid={isValid}
        {...field}
        {...props}
        onChange={setFieldValue}
      />
      <ErrorWrapper>
        <Error>
          <ErrorMessage name={field.name} component="span" />
        </Error>
      </ErrorWrapper>
    </>
  );
};

const renderSelectField = (
  {
    field: {
      onChange,
      value,
      ...fieldRest
    },
    form: {
      touched,
      errors,
      submitCount,
      setFieldValue,
    },
    ...props
  },
) => {
  const isValid = !errors[fieldRest.name];

  const onSelectChange = fieldName => v => setFieldValue(fieldName, v.value);

  const selectedDepartament = value && find(props.options, { id: value });

  return (
    <FormItem>
      <Select
        className={!isValid && 'react-select-error'}
        classNamePrefix="react-select"
        isSearchable={false}
        isClearable={false}
        value={selectedDepartament}
        onChange={onSelectChange(fieldRest.name)}
        {...fieldRest}
        {...props}
      />
      <ErrorWrapper>
        <Error>
          <ErrorMessage name={fieldRest.name} component="span" />
        </Error>
      </ErrorWrapper>
    </FormItem>
  );
};

// TODO: isCompanyDirector DEPRECATED
const FormikProfileEditForm = (
  {
    values,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    popupState,
    onPopupClose,
    isEmployer,
    canEditSelfViewInRating,
    departmentsList,
    initialValues,
  },
) => (
  <Popup
    isActive={popupState}
    onClose={onPopupClose}
    onSubmit={handleSubmit}
    pending={isSubmitting}
  >
    <PopupForm>
      <FormLine>
        <Field
          name="email"
          type="text"
          disabled
          placeholder="E-mail"
          component={renderField}
        />
        <Field
          name="surname"
          required
          type="text"
          disabled={isSubmitting}
          placeholder="Фамилия"
          component={renderField}
        />
      </FormLine>
      <FormLine>
        <Field
          name="name"
          type="text"
          required
          disabled={isSubmitting}
          placeholder="Имя"
          component={renderField}
        />
        <Field
          name="patronymic"
          type="text"
          disabled={isSubmitting}
          placeholder="Отчество"
          component={renderField}
        />
      </FormLine>
      {initialValues.email !== values.email && (
        <FormColumnLine>
          <div>Для изменения e-mail требуется указать ваш текущий пароль</div>
          <Field
            name="password"
            type="password"
            required
            disabled={isSubmitting}
            placeholder="Ваш пароль"
            component={renderField}
          />
        </FormColumnLine>
      )}
      {/*{isEmployer && (*/}
        {/*<FormLine>*/}
          {/*{canEditSelfViewInRating && (*/}
            {/*<Field*/}
              {/*name="departament"*/}
              {/*options={departmentsList}*/}
              {/*disabled={isSubmitting}*/}
              {/*placeholder="Отдел"*/}
              {/*component={renderSelectField}*/}
            {/*/>*/}
          {/*)}*/}
          {/*/!* <Field *!/*/}
          {/*/!* name="position" *!/*/}
          {/*/!* type="text" *!/*/}
          {/*/!* disabled={isSubmitting} *!/*/}
          {/*/!* placeholder="Должность" *!/*/}
          {/*/!* component={renderField} *!/*/}
          {/*/!* /> *!/*/}
        {/*</FormLine>*/}
      {/*)}*/}
      {canEditSelfViewInRating && (
        <FormLine>
          <Field
            name="hideMeInRating"
            title="Не показывать меня в рейтингах"
            disabled={isSubmitting}
            component={renderCheckBox}
          />
        </FormLine>
      )}
    </PopupForm>
  </Popup>
);

class ProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.onLoad();
  }

  cancelSubmitForm = () => {
    this.props.onClose();
  };

  onSubmit = (values, {
    setSubmitting, setErrors, setStatus, resetForm,
  }) => {
    this.props.onChangeName(values.name);
    this.props.onChangeSurname(values.surname);
    this.props.onChangeNickname(values.nickname);
    this.props.onChangeEmail(values.email);
    this.props.onChangeDepartament(values.departament);
    this.props.onChangePosition(values.position);
    this.props.changeShowMeInRating(!values.hideMeInRating);
    this.props.onChangePatronymic(values.patronymic);

    this.props.onSubmitForm();

    setTimeout(() => {
      resetForm({ ...values });
      setStatus({ success: true });
      this.props.onClose();
    }, 500);
  };

  onValidate = (values) => {
    const errors = {};

    if (!values.name || values.name.trim() === '') {
      errors.name = 'Поле обязательно для заполнения';
    }

    if (!values.surname || values.surname.trim() === '') {
      errors.surname = 'Поле обязательно для заполнения';
    }

    if (!values.email || values.email.trim() === '') {
      errors.email = 'Поле обязательно для заполнения';
    }

    if (values.email && !validator.email(values.email)) {
      errors.email = 'Убедитесь, что заполнено верно';
    }

    return errors;
  };

  render() {
    let departmentsList = [];
    if (!isNull(this.props.departments)) {
      departmentsList = this.props.departments.map(department => ({
        value: department.id,
        label: department.name,
      }));
    }

    const initialValues = {
      name: this.props.profile.name,
      surname: this.props.profile.surname,
      nickname: this.props.profile.nickname,
      patronymic: this.props.profile.middlename,
      idn: this.props.profile.idn,
      email: this.props.profile.email,
      company: this.props.profile.company,
      departament: this.props.profile.departament,
      position: this.props.profile.position,
      hideMeInRating: !this.props.profile.showMeInRating,
    };

    const canEditSelfViewInRating = this.props.acl.canEditAllCompanyEmployee
      || this.props.acl.canEditOwnDepartmentEmployee;

    return (
      <Formik
        initialValues={initialValues}
        validate={this.onValidate}
        onSubmit={this.onSubmit}
      >
        {props => (
          <FormikProfileEditForm
            popupState={this.props.popupState}
            onPopupClose={this.cancelSubmitForm}
            isEmployer={this.props.isEmployer}
            canEditSelfViewInRating={canEditSelfViewInRating}
            departmentsList={departmentsList}
            {...props}
          />
        )}
      </Formik>
    );
  }
}

ProfileForm.propTypes = {
  isCompanyDirector: PropTypes.bool,
  isEmployer: PropTypes.bool,
  profile: PropTypes.object,
  popupState: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeName: PropTypes.func,
  onChangeSurname: PropTypes.func,
  onChangeNickname: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onChangeDepartament: PropTypes.func,
  onChangePosition: PropTypes.func,
  onSubmitForm: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLoad: () => dispatch(getDepartmentList()),
    onChangeName: result => dispatch(changeName(result)),
    onChangeSurname: result => dispatch(changeSurname(result)),
    onChangeNickname: result => dispatch(changeNickname(result)),
    onChangeEmail: result => dispatch(changeEmail(result)),
    onChangeDepartament: result => dispatch(changeDepartament(result)),
    onChangePosition: result => dispatch(changePosition(result)),
    onChangePatronymic: result => dispatch(changePatronymic(result)),
    changeShowMeInRating: result => dispatch(changeShowMeInRating(result)),
    onSubmitForm: () => dispatch(changeProfileInfoRequest()),
  };
}

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  popupState: makeSelectPopupState(),
  departments: makeSelectDepartments(),
  acl: makeSelectProfileAcl(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(ProfileForm);
