import styled from 'styled-components';

const TipCount = styled.span`
  background-color: #D7244B;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, .2) 0 1px 1px 0;
  user-select: none;
  color: #FFFFFF;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  height: 24px;
  line-height: 18px;
  position: absolute;
  right: 2px;
  text-align: center;
  top: -2px;
  width: 24px;
`;

export default TipCount;
