import styled from 'styled-components';

const TipFormLine = styled.div`
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  padding: 30px 0;

  @media (min-width: 400px) {
    &:first-child {
      padding-top: 0;
    }
  
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  
  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px 0 20px 0;
  }
`;

export default TipFormLine;
