import { noun } from 'plural-ru';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMedia } from 'the-platform';
import { device } from '../../utils/mediaQueries';

import Info from '../Info';

const StatusBarWrapper = styled.section`
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  margin-top: 31px;
  padding: 10px 0 21px;
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
`;

const StatusItem = styled.div`
  text-align: center;
  width: ${props => (props.fullWidth ? '100%' : 'calc(100% / 2)')};
`;

const Number = styled.p`
  color: #1C1C1C;
  font-family: 'Merriweather', serif;
  font-size: 50px;
  font-weight: bold;
  line-height: 63px;
  margin: 0;
`;

const CenterNumberWrapper = styled.div`
  padding-left: 43px;
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  align-items: center;
  color: #1C1C1C;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 14px;
  margin: 3px 0;
`;

const StatusBar = ({ readoms, rating }) => {
  const small = useMedia(device.laptop);


  // TODO: Зашито опивание и наличие параметров.
  return (
    <StatusBarWrapper flexWrap={small && 'wrap'}>
      <StatusItem fullWidth={small}>
        <Number>{rating}</Number>
        <Text>
          Рейтинг RQ
          <Info
            text="Это рейтинг по&nbsp;всем играм в&nbsp;системе Ридом. Зависит он&nbsp;от&nbsp;успешности прохождения каждой отдельной игры. Максимум в&nbsp;одной игре можно набрать 10&nbsp;очков."
          />
        </Text>
      </StatusItem>
      <StatusItem fullWidth={small}>
        {!small && (
          <Number>
            {`${readoms} R`}
          </Number>
        )}
        {small && (
          <CenterNumberWrapper>
            <Number>
              {readoms}
            </Number>
            <Number>
              &nbsp;R
            </Number>
          </CenterNumberWrapper>
        )}
        <Text>
          {noun(readoms, 'Ридом', 'Ридома', 'Ридомов')}
          <Info text="Ридомы&nbsp;&mdash; универсальная валюта внутри системы Ридом для всех игр" />
        </Text>
      </StatusItem>
    </StatusBarWrapper>
  );
};

StatusBar.propTypes = {
  small: PropTypes.bool,
  readoms: PropTypes.string,
  cash: PropTypes.string,
  rating: PropTypes.string,
};

export default StatusBar;
