import { Link } from 'react-router-dom';
import styled from 'styled-components';
import pure from 'recompose/pure';
import ArrowIcon from './arrow.svg';

const BackButton = styled(Link)`
  align-items: center;
  border: 1px solid rgba(255, 255, 255, .25);
  border-radius: 25px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  height: 35px;
  margin-right: 30px;
  outline: 0;
  padding: 0 26px;
  text-decoration: none;
  text-transform: uppercase;
  transition: border-color .3s ease-out;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;

  &:before {
    background-image: url(${ArrowIcon});
    content: '';
    display: inline-block;
    height: 10px;
    margin-right: 7px;
    width: 5px;
  }

  &:hover {
    border-color: #FFFFFF;
  }
`;

export default pure(BackButton);
