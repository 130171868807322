import * as Yup from 'yup';

const GuestGameBuyValidateSchema = Yup.object().shape({
  email: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .email('Убедитесь, что поле заполнено верно')
    .required('Поле обязательно для заполнения'),
  surname: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .test('length', 'Поле обязательно для заполнения', val => !val || val.toString().length > 0)
    .required('Поле обязательно для заполнения'),
  name: Yup
    .string()
    .typeError('Убедитесь, что поле заполнено верно')
    .test('length', 'Поле обязательно для заполнения', val => !val || val.toString().length > 0)
    .required('Поле обязательно для заполнения'),
  personalProcessingDataAgreement: Yup
    .boolean()
    .typeError('Необходимо дать своё согласие на обработку персональных данных')
    .test('marked', 'Необходимо дать своё согласие на обработку персональных данных', val => !!val)
    .required('Необходимо дать своё согласие на обработку персональных данных'),
  gameLicenceAgreement: Yup
    .boolean()
    .typeError('Необходимо принять условия лицензии')
    .test('marked', 'Необходимо принять условия лицензии', val => !!val)
    .required('Необходимо принять условия лицензии'),
});

export default GuestGameBuyValidateSchema;
