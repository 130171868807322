import {
  call, takeEvery, select,
} from 'redux-saga/effects';
import get from 'lodash/get';
import isUserAuthenticated from '../../../utils/isUserAuthenticated';
import { apiRequest as createApiRequest } from '../../../utils/request';
import { CHECK_CLICK_ID } from '../../modules/clickId/constants';
import { makeSelectApiUrl } from '../../modules/global/selectors';

function build(cookies) {
  const apiRequest = createApiRequest(cookies);

  function* getClickId() {
    const url = yield select(makeSelectApiUrl());
    try {
      const requestUrl = `${url}/VariableValueGet/clickID`;
      const request = yield call(apiRequest, requestUrl);
      return get(request, 'data.result[0].Value');
    } catch (err) {
      console.error('Can`t get clickID');
      return false;
    }
  }

  function* setClickId(clickId) {
    const url = yield select(makeSelectApiUrl());
    try {
      const requestUrl = `${url}/VariableValueSet/clickID/${encodeURIComponent(clickId)}`;
      const request = yield call(apiRequest, requestUrl);
      return get(request, 'result[0].Value');
    } catch (err) {
      console.error('Can`t set clickID');
      return false;
    }
  }

  function* checkClickId() {
    const isAuthenticated = isUserAuthenticated(cookies);

    if (!isAuthenticated) {
      return false;
    }

    try {
      const clickIdInCookie = cookies.get('clickId');
      const clickIdInVars = yield call(getClickId);
      if (clickIdInVars && !clickIdInCookie) {
        cookies.set('clickId', clickIdInVars);
        return false;
      }

      if (!clickIdInVars && clickIdInCookie) {
        yield call(setClickId, clickIdInCookie);
      }
    } catch (err) {
      console.error('Ошибка clickId', err);
    }
  }

  function* sagas() {
    yield takeEvery(CHECK_CLICK_ID, checkClickId);
  }

  return sagas;
}

export default build;
