import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

class BurgerMenuIcon extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
  };

  render() {
    const { open } = this.props;

    return (
      <div
        className={classNames([
          'BurgerMenuIcon',
          open && 'open',
        ])}
      >
        <span />
        <span />
        <span />
        <span />
      </div>
    );
  }
}

BurgerMenuIcon.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default BurgerMenuIcon;
