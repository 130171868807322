import { fromJS } from 'immutable';
import {
  GET_ALL_GAMES_LIST,
  GET_ALL_GAMES_LIST_FAIL,
  GET_ALL_GAMES_LIST_SUCCESS,
  GET_CART,
  GET_CART_FAIL,
  GET_CART_SUCCESS,
  ADD_SHOWCASE_TO_CART,
  ADD_SHOWCASE_TO_CART_FAIL,
  ADD_SHOWCASE_TO_CART_SUCCESS,
  CART_CHECKOUT,
  CART_CHECKOUT_FAIL,
  CART_CHECKOUT_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  BUY_SHOWCASE,
  BUY_SHOWCASE_FAIL,
  BUY_SHOWCASE_SUCCESS,
  BUY_SHOWCASE_FROM_DEMO,
  BUY_SHOWCASE_FROM_DEMO_FAIL,
  BUY_SHOWCASE_FROM_DEMO_SUCCESS,
  GET_GAMES_SHOWCASE,
  GET_GAMES_SHOWCASE_FAIL,
  GET_GAMES_SHOWCASE_SUCCESS,
} from './constants';

const initialState = fromJS({
  pending: true, // Идет загрузка информации об играх
  gamesList: null,
  gamesData: null,
  gamesLicenses: null,
  showcaseList: null,
  success: false,
  fail: false,

  addShowCase: null,
  addShowCasePending: false,
  addShowCaseSuccess: true,
  addShowCaseFail: false,

  buyShowCaseId: null,
  buyShowCasePending: false,
  buyShowCaseSuccess: true,
  buyShowCaseFail: false,

  buyShowCaseFromDemoId: null,
  buyShowCaseFromDemoPending: false,
  buyShowCaseFromDemoSuccess: true,
  buyShowCaseFromDemoFail: false,

  cart: [],
  getCartSuccess: false,
  getCartPending: false,
  getCartFail: false,

  cartCheckoutSuccess: false,
  cartCheckoutPending: false,
  cartCheckoutFail: false,

  invoices: [],
  getInvoicesSuccess: false,
  getInvoicesPending: false,
  getInvoicesFail: false,

  gamesShowcases: null,
  gamesShowcasesSuccess: false,
  gamesShowcasesPending: false,
  gamesShowcasesFail: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case CART_CHECKOUT:
      return state
        .set('cartCheckoutPending', true)
        .set('cartCheckoutSuccess', false)
        .set('cartCheckoutFail', false);
    case CART_CHECKOUT_FAIL:
      return state
        .set('cartCheckoutPending', false)
        .set('cartCheckoutSuccess', false)
        .set('cartCheckoutFail', true);
    case CART_CHECKOUT_SUCCESS:
      return state
        .set('cartCheckoutPending', false)
        .set('cartCheckoutSuccess', true)
        .set('cartCheckoutFail', false);
    case BUY_SHOWCASE:
      return state
        .set('buyShowCasePending', true)
        .set('buyShowCaseId', action.payload.showcaseId)
        .set('buyShowCaseSuccess', false)
        .set('buyShowCaseFail', false);
    case BUY_SHOWCASE_FAIL:
      return state
        .set('buyShowCasePending', false)
        .set('buyShowCaseId', null)
        .set('buyShowCaseSuccess', false)
        .set('buyShowCaseFail', true);
    case BUY_SHOWCASE_SUCCESS:
      return state
        .set('buyShowCasePending', false)
        .set('buyShowCaseId', null)
        .set('buyShowCaseSuccess', true)
        .set('buyShowCaseFail', false);
    case BUY_SHOWCASE_FROM_DEMO:
      return state
        .set('buyShowCaseFromDemoPending', true)
        .set('buyShowCaseFromDemoId', action.showcaseId)
        .set('buyShowCaseFromDemoSuccess', false)
        .set('buyShowCaseFromDemoFail', false);
    case BUY_SHOWCASE_FROM_DEMO_SUCCESS:
      return state
        .set('buyShowCaseFromDemoPending', false)
        .set('buyShowCaseFromDemoId', null)
        .set('buyShowCaseFromDemoSuccess', false)
        .set('buyShowCaseFromDemoFail', true);
    case BUY_SHOWCASE_FROM_DEMO_FAIL:
      return state
        .set('buyShowCaseFromDemoPending', false)
        .set('buyShowCaseFromDemoId', null)
        .set('buyShowCaseFromDemoSuccess', true)
        .set('buyShowCaseFromDemoFail', false);
    case ADD_SHOWCASE_TO_CART:
      return state
        .set('addShowCasePending', true)
        .set('addShowCase', action.showcaseId)
        .set('addShowCaseSuccess', false)
        .set('addShowCaseFail', false);
    case ADD_SHOWCASE_TO_CART_FAIL:
      return state
        .set('addShowCasePending', false)
        .set('addShowCase', null)
        .set('addShowCaseSuccess', false)
        .set('addShowCaseFail', true);
    case ADD_SHOWCASE_TO_CART_SUCCESS:
      return state
        .set('addShowCasePending', false)
        .set('addShowCase', null)
        .set('addShowCaseSuccess', true)
        .set('addShowCaseFail', false);
    case GET_CART:
      return state
        .set('getCartPending', true)
        .set('cart', [])
        .set('getCartSuccess', false)
        .set('getCartFail', false);
    case GET_CART_FAIL:
      return state
        .set('getCartPending', false)
        .set('cart', [])
        .set('getCartSuccess', false)
        .set('getCartFail', true);
    case GET_CART_SUCCESS:
      return state
        .set('getCartPending', false)
        .set('cart', action.cart)
        .set('getCartSuccess', true)
        .set('getCartFail', false);
    case GET_INVOICES:
      return state
        .set('getInvoicesPending', true)
        .set('invoices', [])
        .set('getInvoicesSuccess', false)
        .set('getInvoicesFail', false);
    case GET_INVOICES_FAIL:
      return state
        .set('getInvoicesPending', false)
        .set('invoices', [])
        .set('getInvoicesSuccess', false)
        .set('getInvoicesFail', true);
    case GET_INVOICES_SUCCESS:
      return state
        .set('getInvoicesPending', false)
        .set('invoices', action.invoices)
        .set('getInvoicesSuccess', true)
        .set('getInvoicesFail', false);
    case GET_ALL_GAMES_LIST:
      return state
        .set('pending', true)
        .set('success', false)
        .set('fail', false);
    case GET_ALL_GAMES_LIST_FAIL:
      return state
        .set('pending', false)
        .set('gamesList', null)
        .set('gamesData', null)
        .set('gamesLicenses', null)
        .set('showcaseList', null)
        .set('success', false)
        .set('fail', true);
    case GET_ALL_GAMES_LIST_SUCCESS:
      return state
        .set('pending', false)
        .set('gamesList', action.games)
        .set('gamesData', action.gameData)
        .set('showcaseList', action.showcaseList)
        .set('success', true)
        .set('fail', false);
    case GET_GAMES_SHOWCASE:
      return state
        .set('gamesShowcasesPending', true)
        .set('gamesShowcases', null)
        .set('gamesShowcasesSuccess', false)
        .set('gamesShowcasesFail', false);
    case GET_GAMES_SHOWCASE_FAIL:
      return state
        .set('gamesShowcasesPending', false)
        .set('gamesShowcases', null)
        .set('gamesShowcasesSuccess', false)
        .set('gamesShowcasesFail', true);
    case GET_GAMES_SHOWCASE_SUCCESS:
      return state
        .set('gamesShowcasesPending', false)
        .set('gamesShowcases', action.gamesShowcases)
        .set('gamesShowcasesSuccess', true)
        .set('gamesShowcasesFail', false);
    default:
      return state;
  }
}

export default reducer;
