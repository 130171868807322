import styled from 'styled-components';

const Content = styled.section`
  background-color: ${props => (props.grey ? '#EBEBED' : '#FFFFFF')};
  border-radius: 5px;
  display: ${props => (props.isGame ? 'block' : 'flex')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'initial')};
  justify-content: ${props => (props.justify ? props.justify : 'normal')};
  margin: 15px;
  padding: ${props => (props.grey ? '0' : '30px')};
  width: calc(100% - 30px);
`;

export default Content;
