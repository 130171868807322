import { createSelector } from 'reselect';

const errorState = state => state.get('error');

const makeSelectIsNetworkOffline = () => createSelector(
  errorState,
  feedBackFormState => feedBackFormState.get('networkOffline'),
);

const makeSelectIsRequestFail = () => createSelector(
  errorState,
  feedBackFormState => feedBackFormState.get('requestFail'),
);

export {
  makeSelectIsNetworkOffline,
  makeSelectIsRequestFail,
};
