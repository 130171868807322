import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { withCookies } from 'react-cookie';
import { createStructuredSelector } from 'reselect';
import { trackBuyGameFail } from '../../../redux/modules/analytics/actions';
import { makeSelectProfile } from '../../../redux/modules/profile/selectors';

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
});

const mapDispatchToProps = dispatch => ({
  trackBuyGameFail: () => dispatch(trackBuyGameFail()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
  withConnect,
  withCookies,
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!prevProps.profile.userID && this.props.profile.userID) {
        // Данные профиля загрузились, занчит можно трекать событие.
        const { trackBuyGameFail } = this.props;
        trackBuyGameFail();
      }
    },
  }),
);

export default enhance;
